import { gql } from "@apollo/client";

export const PATIENT_QUERY = gql`
  query PatientQuery($patientId: Int!, $orgId: Int) {
    Me {
      roles
      state
      countryCode
      organizationId
      employee {
        id
        firstName
        defaultPracticeId
      }
    }
    AssignedPractices(id: $orgId) {
      id
      name
    }
    Brands(id: $orgId) {
      id
      name
      pairable
      haBrandId
    }
    Customer(id: $patientId) {
      id
      upid
      firstName
      lastName
      phone
      practiceId
      employeeId
      uuid
      brandDisplayId
      hearingDevices {
        leftDevice {
          id
          model
          manufacturer
          platform
        }
        rightDevice {
          id
          model
          manufacturer
          platform
        }
        hdPrograms {
          name
          type
          programNumber
        }
        persistentConfiguration
        teleCareVersion        
        capabilities {
          bluetooth
          appConnected
        }
      }
      cxxId      
    }
    HIDSetting(patientId: $patientId) {
      bluetoothDisabled
    }
  }
`

export const PATIENT_INFO_QUERY = gql`
query PatientInfo($id: ID!) {
  patientEdit(id: $id) @client{
   id
   practiceId
   employeeId
   leftModelId
   rightModelId
   }
 }
`