import React from 'react'

export const Item = ({ children, className = '' }) => {
  return (
    <article className={`panel__item ${className}`}>
      {children}
    </article>
  )
}

export const Base = ({ children, style = {}, className = '', modifier = '' }) => {
  return (
    <article className={`panel ${className} ${modifier ? `panel--${modifier}` : ''}`} style={style}>
      {children}
    </article>
  )
}
