import { gql } from "@apollo/client";

export const APP_INFO_QUERY = gql`
  query ($patientId: Int!) {
    Customer (id: $patientId) {
      id
      upid
      uuid
      hearingDevices {
        leftDevice {
          id
        }
        rightDevice {
          id
        }
        capabilities {
          appConnected
        }
      }
      appInfo {
        appName
        appVariant
        appVersion
        osType
        osVersion
      }
    }
  }
`