import _ from 'lodash'
import { graphQLClient } from '../graphql/client'
import { AUTHENTICATION_QUERY } from '../graphql/queries/AuthenticationQuery'
import { getAppVersion } from './TokenUtil'

const getMe = (state) => state.authentication.me || {}

export const getToken = (bothTokens = false) => {
  const { authentication } = graphQLClient.readQuery({
    query: AUTHENTICATION_QUERY
  })
  const item = localStorage.getItem(`LIGHTCLOUD${getAppVersion()}`)
  if (item && !_.isNil(authentication.auth)) {
    let data = {}
    data = JSON.parse(item)
    const { access_token } = data
    if (data) return bothTokens ? data : access_token
    else return data
  } else {
    return false
  }
}

export const isManager = state => {
  const me = getMe(state)
  return _.get(me, `employee.manager`, false)
}

export const isOrgManager = state => {
  const me = getMe(state)
  return _.get(me, `employee.orgManager`, false)
}

export const isAuthenticated = () => getToken()

export const isSwitchUser = state => state.authentication.auth.indexOf(null) !== -1

const permissionRegistry = {
  ADD_EMPLOYEE: ({ Me }) => {
    return _.get(Me, 'employee.manager', false)
  },
  ADD_PRACTICE: ({ Me }) => {
    return _.get(Me, 'employee.orgManager', false)
  },
  EDIT_EMPLOYEE: ({ Me, employee }) => {
    const employeeIsOrgManager = employee.orgManager
    const meIsManager = _.get(Me, 'employee.manager', false)
    const meIsOrgManager = _.get(Me, 'employee.orgManager', false)
    const isMe = _.get(Me, 'employee.id') === parseInt(employee.id, 10)

    if (employeeIsOrgManager && !meIsOrgManager) return false

    return meIsManager || isMe
  },
  EDIT_PRACTICE: ({ Me }) => {
    return _.get(Me, 'employee.manager', false)
  },
  DELETE_EMPLOYEE: ({ Me, employee }) => {
    const employeeIsOrgManager = employee.orgManager
    const meIsManager = _.get(Me, 'employee.manager', false)
    const meIsOrgManager = _.get(Me, 'employee.orgManager', false)
    const isMe = _.get(Me, 'employee.id') === parseInt(employee.id, 10)

    if (employeeIsOrgManager && !meIsOrgManager) return false

    return meIsManager && !isMe
  },
  DELETE_PRACTICE: ({ Me }) => {
    return _.get(Me, 'employee.orgManager', false)
  },
  ANALYTICS_READ: ({ Me }) => {
    return _.get(Me, 'employee.manager', false)
  },
  DELETE_PATIENT: ({ Me, patient }) => {
    return (
      _.get(Me, 'employee.id', '') === patient.employeeId || _.get(Me, 'employee.manager', false)
    )
  }
}

export const checkPermission = (action, options) => {
  return permissionRegistry[action](options)
}
