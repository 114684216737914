import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Field } from 'formik'
import { T } from 'core'
import SelectField from 'formfields/SelectField'
import MultiSelectField from './MultiSelectField'

class PracticesSelectField extends Component {
  onChange = async (name, value) => {
    await this.props.form.setFieldValue(name, value)

    let { assignedPracticeIds, defaultPracticeId } = this.props.field.value
    assignedPracticeIds = assignedPracticeIds || []
    const values = assignedPracticeIds.reduce((arr, item) => [...arr, parseInt(item.value, 10)], [])

    if (defaultPracticeId) {
      if (values.indexOf(parseInt(defaultPracticeId, 10)) === -1) {
        this.onChange('practices.defaultPracticeId', '')
      }
    }
  }

  render() {
    const {
      assignAllpractices,
      disabled = false,
      intl: { formatMessage },
      options,
      form: { values },
      field,
      labelClassName
    } = this.props

    const { practices: { assignedPracticeIds = [] } } = values

    return (
      <article style={{ marginBottom: '15px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div>
                <Field
                  name="practices.assignedPracticeIds"
                  label={formatMessage(T('form.assign-practices'))}
                  labelClassName={labelClassName}
                  component={MultiSelectField}
                  options={options}
                  disabled={disabled || assignAllpractices}
                  placeholder={formatMessage(T('practices.select_placeholder'))}
                  value={field.value.assignedPracticeIds}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Field
              name="practices.defaultPracticeId"
              component={SelectField}
              label={formatMessage(T('form.default-practice'))}
              disabled={assignedPracticeIds && assignedPracticeIds.length === 0 || disabled}
              className="form-control"
              labelClassName={labelClassName}
              placeholder={`${formatMessage(T('common.select'))}...`}
              options={
                assignedPracticeIds &&
                assignedPracticeIds.map(item => ({
                  value: item.value,
                  label: item.label
                }))
              }
            />
          </div>
        </div>
      </article>
    )
  }
}

export default injectIntl(PracticesSelectField)
