import { gql } from "@apollo/client"

export const QUERY_NOTIFICATIONS_FOR_LOGGED_IN_USER = gql`
query GetNotificationsQuery {
  GetNotifications {
    patientNotificationId
    patientFirstName
    patientLastName
    tcbeId
    notificationType
    modifiedDate
    isRead
  }
}
`