import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import _ from 'lodash'
import EmployeeSelfCompleteForm from './EmployeeSelfCompleteForm'
import { getLocales } from 'helper/constants'
import { LOCALE_QUERY } from './../../graphql/queries/LocaleQuery'
import { MUTATION_UPDATE_INVITED_EMPLOYEE, MUTATION_UPDATE_INVITED_EMPLOYEE_PHONE_VERIFICATION_STATUS } from './../../graphql/mutations/UpdateInvitedEmployeeMutation'
import { MUTATION_UPLOAD_AVATAR_SELF_COMPLETE } from '../../graphql/mutations/UploadAvatarSelfComplete'
import { QUERY_EMPLOYEE_SELF_INVITE } from './../../graphql/queries/EmployeeSelfCompleteQuery'
import { FETCH_ALL_COUNTRY_CALLING_CODES_QUERY } from '../../graphql/queries/CountryCallingCodesQuery'
import { withRouter } from "helper/withRouter";
import queryString from 'query-string'

const mapProps = ({ prepareDataFromEmployee,
  data,
  ownProps: { locale, params, intl }
}: any) => {
  const { refetch, loading, InvitedEmployee = [], Practices = [], NotificationTimeOptions, error } = data
  const id = parseInt(params.id, 10)
  const sortedLocales = getLocales()
  const locales = Object.keys(sortedLocales).map(countryCode => ({
    countryCode,
    value: sortedLocales[countryCode]
  }))
  return {
    refetch,
    loading,
    error,
    practices: Practices,
    employeeId: id,
    employee: InvitedEmployee,
    practicesOptions: Practices.map(item => ({
      label: item.name,
      value: item.id
    })),
    formTitle: 'employeeEdit.title',
    submitLabel: 'common.save',
    isManager: _.get(InvitedEmployee, `manager`, false),
    isOrgManager: _.get(InvitedEmployee, `orgManager`, false),
    isMe: _.get(InvitedEmployee, `id`, '') === id,
    countryCode: _.get(InvitedEmployee, 'countryCode', ''),
    locale,
    locales,
    intl,
    NotificationTimeOptions,
    organizationId: _.get(InvitedEmployee, `organizationId`, false)
  }
}

const options = (props): any => {
  const { location: { search } } = props
  const param = queryString.parse(search)
  return {
    notifyOnNetworkStatusChange: true,
    variables: param,
    fetchPolicy: 'network-only'
  }
}

const withData = compose(
  graphql(QUERY_EMPLOYEE_SELF_INVITE, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_UPDATE_INVITED_EMPLOYEE, {
    props: ({ mutate }): any => ({
      updateInvitedEmployee: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPDATE_INVITED_EMPLOYEE_PHONE_VERIFICATION_STATUS, {
    props: ({ mutate }) => ({
      updateInvitedEmployeePhoneVerificationStatus: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(LOCALE_QUERY, {
    props: ({ data: { locale } }: any) => ({
      locale: locale.locale
    })
  }),
  graphql(MUTATION_UPLOAD_AVATAR_SELF_COMPLETE, {
    props: ({ mutate }): any => ({
      uploadSelfCompleteAvatar: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(FETCH_ALL_COUNTRY_CALLING_CODES_QUERY, {
    props: ({ data: { CountryCallingCodes } }: any) => ({
      CountryCallingCodes: CountryCallingCodes
    })
  }),
)

const EmployeeSelfCompletePage = withRouter(withData(injectIntl(EmployeeSelfCompleteForm)))

export default EmployeeSelfCompletePage
