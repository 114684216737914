import styled from 'styled-components'
import { passwordStrongScore } from 'helper/constants'

export const PasswordExtraInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  text-align: right;
`
export const PasswordHint = styled.div`
  color: #adadad;
  padding-top: 4px;
`

const isAStrongPassword = score => {
  const passwordStrength = passwordStrongScore

  return score >= passwordStrength
}

export const PasswordStrength = styled.div`
  color: ${props => {
    return props.status === -1 ? '#000000' : isAStrongPassword(props.status) ? '#05b11e' : '#d80926'
  }};
`
export const MinimumNumberOfChars = styled.div`
  color: ${props => {
    return props.status === 'default' ? '#000000' : props.status !== 'valid' ? '#d80926' : '#05b11e'
  }};
`

export const PasswordFieldComponent = styled.div`
  .ReactPasswordStrength {
    border: 1px solid #9d9c9c;
    box-sizing: border-box;
    color: #090809;
    font-size: 14px;
    line-height: 1;
    position: relative;
    height: 38px;
    &:focus-within{
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    .form-control:focus {
      box-shadow: none;
    }
  }

  &.passwordReEnter {
    .help-block {
      padding-bottom: 0;
    }
  }

  &.has-error {
    > div {
      border-color: #d60925 !important;
    }
  }

  .ReactPasswordStrength-input {
    border: none;
    box-sizing: border-box;
    font-size: 16px;
    padding: 14px 0 12px 14px;
    width: 70%;
    height: 34px;
  }

  input:not(:focus).is-password-invalid {
    color: #d60925;
  }
  .ReactPasswordStrength-input:focus {
    outline: none;
  }

  .ReactPasswordStrength-strength-desc {
    color: transparent;
    font-weight: bold;
    padding: 11px 12px;
    position: absolute;
    top: 1px;
    right: 0;
    text-align: right;
    transition: color 250ms ease-in-out;
    width: 30%;
  }

  .ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
    color: #E1243F;
  }
  .ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
    color: #E1243F;
  }
  .ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
    color: #008858;
  }
  .ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
    color: #008858;
  }
  .ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
    color: #008858;
  }

  .ReactPasswordStrength-strength-bar {
    box-sizing: border-box;
    height: 2px;
    position: relative;
    top: 1px;
    right: 1px;
    transition: width 300ms ease-out;
    width: 0;
  }

  .ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar {
    background: #E1243F;
    width: 20%;
  }

  .ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
    background: #E1243F;
    width: 40%;
  }

  .ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
    background: #008858;
    width: 60%;
  }

  .ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
    background: #008858;
    width: 80%;
  }

  .ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
    background: #008858;
    width: calc(100% + 2px);
  }
`
