import React from "react"
import { compose } from 'lodash/fp'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from "@apollo/client"
import IndividualCalculation from './IndividualCalculation'
import { StandardCalculation } from './StandardCalculation'
import { RawTable } from "./RawTable"
import { T } from "core"
import { FormattedMessage } from "react-intl"
import { calculateAverageWearingTimePerDay, calculateAverageWearingTimeSinceFirstDate } from "./ducks"
import { getLocaleFromCache } from 'helper/LocaleHelper'
import { getCurrentDateAsPerLocale } from 'helper/DateUtils'
import Loading from 'components/Loading'
import { withRouter } from 'helper/withRouter'

const WearingTime = ({ wearingTimeRawData, loading }) => {

  const getRawTableData = (dataGroupedByDate) => {
    const groupArrays = Object.keys(dataGroupedByDate).map((date, index) => {
      return dataGroupedByDate[date][0]

    });
    return groupArrays;
  }

  const getStandardCalculationData = (dataGroupedByDate) => {
    let standardCalulation = {};

    standardCalulation.lastThreeDaysAverageHours = calculateAverageWearingTimePerDay(3, dataGroupedByDate);
    standardCalulation.lastSevenDaysAverageHours = calculateAverageWearingTimePerDay(7, dataGroupedByDate);
    standardCalulation.lastThirtyDaysAverageHours = calculateAverageWearingTimePerDay(30, dataGroupedByDate);
    standardCalulation.sinceFirstDayAverageHours = calculateAverageWearingTimeSinceFirstDate(dataGroupedByDate);

    return standardCalulation;
  }

  const getSortedData = (wearingTimeRawData) => {
    let data = {}

    const dataGroupedByDate = wearingTimeRawData?.reduce((dataGroupedByDate, value) => {
      const date = value.recordingTimeUtc.split('T')[0]
      if (!dataGroupedByDate[date]) {
        dataGroupedByDate[date] = []
      }
      dataGroupedByDate[date].push(value)
      return dataGroupedByDate
    }, {})

    data.rawTableData = getRawTableData(dataGroupedByDate)
    data.individualCalculationData = dataGroupedByDate;
    data.standardCalulationData = getStandardCalculationData(dataGroupedByDate)

    return data
  }

  const renderContent = ({ rawTableData, individualCalculationData, standardCalulationData }) => {
    return (
      <>
        <div className="row" style={{ "fontSize": "1rem", "fontWeight": "600", "gap": "0.5rem", "paddingLeft": "1rem" }} >
          <span><FormattedMessage {...T('SatisfactionChart.today')} /></span>
          <span>:</span>
          <span>{getCurrentDateAsPerLocale(getLocaleFromCache())}</span>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <IndividualCalculation individualCalculationData={individualCalculationData} />
            </div>
            <div className="row">
              <StandardCalculation StandardCalculation={standardCalulationData} />
            </div>
          </div>
          <div className="col-6">
            <RawTable wearingTimeRawData={rawTableData} />
          </div>
        </div>
      </>
    )
  }

  const renderNoContent = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <p className="warn-block">
            <i className="fas fa-fw fa-exclamation-triangle exclamation-icon" />&nbsp;
            <FormattedMessage {...T('wearingTime.noEntriesAvailable')} />
          </p>
        </div>
      </div>
    )
  }

  if (loading) {
    return <Loading isLoading={loading} />
  }
  else if (wearingTimeRawData && wearingTimeRawData.length > 0) {
    const data = getSortedData(wearingTimeRawData)
    return renderContent(data)
  }
  return renderNoContent()
}

const mapProps = ({ data: { WearingTimeRawData, loading } }) => {
  return {
    wearingTimeRawData: WearingTimeRawData,
    loading
  }
}

const options = (data) => {
  return {
    notifyOnNetworkStatusChange: true,
    variables: { customerId: parseInt(data?.params?.id) }
  }
}

const QUERY_WEARING_TIME_RAW_DATA = gql`
  query WearingTimeRawData($customerId: Int!) {
    WearingTimeRawData(customerId: $customerId) {
      recordingTimeUtc
      wearingTimeInHr
    }
  }
`

const withData = compose(
  graphql(QUERY_WEARING_TIME_RAW_DATA, {
    props: mapProps,
    options
  }),
)

export default withRouter(withData(WearingTime));