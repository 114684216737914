import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
const DEFAULT_TIMEOUT = 30 * 1000 // 30 seconds

export default class Loading extends Component {
  displayName = 'Loading'

  state = {
    hasTimedout: false
  }

  timeout = 0

  UNSAFE_componentWillMount() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({
        hasTimedout: Boolean(this.props.isLoading)
      })
      this.props.onTimeout && this.props.onTimeout()
    }, DEFAULT_TIMEOUT)
  }

  componentWillUnmount() {
    this.tearDown()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      this.tearDown()
    }
  }

  tearDown() {
    clearTimeout(this.timeout)
  }

  renderLoadingIndicator() {
    return (
      <div className={'loading ' + (this.props.isLoading ? 'visible' : 'invisible')}>
        <div className="wrapper">
          <div className="inner">
            <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" />
          </div>
        </div>
      </div>
    )
  }

  renderErrorMessage() {
    return (
      <div className={'loading visible'}>
        <div className="wrapper">
          <div className="inner">
            <FormattedMessage {...T(`${'errorCodes.error'}`)} />
          </div>
        </div>
      </div>
    )
  }
  render() {
    return this.state.hasTimedout ? this.renderErrorMessage() : this.renderLoadingIndicator()
  }
}
