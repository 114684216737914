import * as signalR from '@microsoft/signalr'

export const InitializeSignalrConnection = (signalRHubUrl, groupId) => {
  const singalRUrl = process.env.REACT_APP_SIGNALR_URL || 'https://signalrhub.cloudapi-dev.sivantos.com';
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(singalRUrl + signalRHubUrl)
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  console.log('connection to websocket group ' + groupId)

  connection.keepAliveIntervalInMilliseconds = 20000
  connection.serverTimeoutInMilliseconds = 40000
  connection.start().then(() => connection.invoke('JoinGroup', groupId?.toString()))

  connection.onreconnected(() => {
    connection.invoke('JoinGroup', groupId)
    console.log('RECONNECT to websocket group ' + groupId)
  })

  return connection
}
