import { graphql } from '@apollo/client/react/hoc';
import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { T, tracker } from 'core'
import * as Panel from 'components/_Profile/components/Panel'
import ConfirmationPopup from 'components/ConfirmationPopup'
import { compose } from 'lodash/fp'
import MarketingMessages from 'components/Marketing/Messages'
import Loading from 'components/Loading'
import { reduce, isEmpty } from 'lodash'
import { Alert } from 'react-bootstrap'
import {
  MarketingPracticeFilter,
  MarketingProviderFilter,
  MarketingPatientStatusFilter,
  MarketingPatientCreatedFilter,
  MarketingPatientClosedFilter,
  MarketingCountRecipients,
  Filters
} from 'components/Dropdowns/MarketingFilters'
import { AcceptButtonMarketingMessage } from 'styledComponents/Button'
import { H2 } from 'styledComponents/Heading'
import { SHOW_POPUP_QUERY, UPDATE_OVERLAY } from 'graphql/queries/ShowPopUpQuery'
import { CONFIRMATION_POPUP, SHOW_POPUP, getOverlayUpdateData }  from 'components/Popups/helper/OverlayUpdateData'
import { QUERY_RECIPIENTS_COUNTER } from 'graphql/queries/RecipientsCounterQuery'
import { MUTATION_SEND_MESSAGE } from 'graphql/mutations/MarketingMessageMutation'
import { mapProps, popupMapProps, mapRecipientsCounterProps, recipientsCounterQueryOptions, assignedPracticesOptions } from 'graphql/mapProps/MarketingMessagesMapProps'
import { QUERY_ASSIGNED_PRACTICES } from '../../../graphql/queries/PracticesQuery'
import { DataCollectionType} from 'helper/constants'
import { sendDataToDataCollection } from './../../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from '../../../helper/DataCollection/DataCollectionConstants'
import { MarketingMessageAnalyticsData } from '../../../helper/DataCollection/PayloadData'
export class MarketingMessagePage extends React.Component {
  static displayName = 'MarketingMessagesPage'

  state = {
    isSaving: false,
    hasErrors: false,
    showSuccessPopup: false,
    message: '',
    filters: {
      selectedPractices: [], //default all practices
      onlyMyPatients: false, // default all providers
      patientStatuses: {}, // default all journeyStatuses ['appointment-preparation', 'in-trial', 'closed-sold', 'closed-cancelled']
      patientCreatedDate: null /* { from: 'YYYY-MM-DD', to: 'YYYY-MM-DD' } */,
      patientClosedDate: null /* { from: 'YYYY-MM-DD', to: 'YYYY-MM-DD' } */
    },
    practicesFilterIsOpen: false,
    providersFilterIsOpen: false,
    patientStatusFilterIsOpen: false,
    patientCreatedDateFilterIsOpen: false,
    patientClosedDateFilterIsOpen: false,
    marketingRecipientsNumber: 0,
    marketingTotalNumber: 0,
    Filtercount: 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { recipients = 0, total = 0 } =
      nextProps.RecipientsCounterQuery.MarketingRecipientsCounter || {}

    this.setState({
      marketingRecipientsNumber: recipients,
      marketingTotalNumber: total
    })
  }

  onFilterChange = filter => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filter }
      },
      () => {
        // execute the query to get new counters
        const preparedFilters = this.preparedFilters(this.state.filters)

        this.props.RecipientsCounterQuery.refetch({
          filters: JSON.stringify(preparedFilters)
        })
      }
    )
  }

  updateCount = count => {
    this.state.count = count
  }
  onMessageChange = ({ message }) => {
    this.setState({
      message
    })
  }

  onSendMessage = () => {
    const canSendMessage = this.isMesageValid()
    const employeeId = this.props.employeeId
    if (canSendMessage) {
      const { message, filters = {} } = this.state
      const preparedFilters = this.preparedFilters(filters)
      const payloadData = {
        textLength : message.length,
        numberOfReciepients: this.state.marketingRecipientsNumber + ' of '+ this.state.marketingTotalNumber
      }   
      this.setState(
        {
          isSaving: true,
          hasError: false
        },
        async () => {
          try {
            await this.props.sendMarketingMessage({
              message,
              filters: JSON.stringify(preparedFilters),
              employeeId,
            })
            sendDataToDataCollection(DataCollectionType.MarketingMessage, 
              MarketingMessageAnalyticsData(payloadData.textLength, payloadData.numberOfReciepients),
              EventType.MarketingMessage)
            this.onMessageSent()
          } catch (error) {
            tracker.track('AppError on sending MarketingMessages', { error })
            this.setState({
              isSaving: false,
              hasErrors: true
            })
          }
        }
      )
    }
  }

  onCloseAllDropdowns = () => {
    this.setState({
      practicesFilterIsOpen: false,
      providersFilterIsOpen: false,
      patientStatusFilterIsOpen: false,
      patientCreatedDateFilterIsOpen: false,
      patientClosedDateFilterIsOpen: false
    })
  }

  onOpenFilter = filterType => isOpen => {
    this.setState({
      practicesFilterIsOpen: false,
      providersFilterIsOpen: false,
      patientStatusFilterIsOpen: false,
      patientCreatedDateFilterIsOpen: false,
      patientClosedDateFilterIsOpen: false,
    }, () => {
        this.setState({[filterType]: isOpen})
    })
  }

  onMessageSent = async () => {
    this.setState({
      isSaving: false,
      showSuccessPopup: true,
      filters: {
        selectedPractices: [], //default all practices
        onlyMyPatients: false, // default all providers
        patientStatuses: {}, // default all journeyStatuses ['appointment-preparation', 'in-trial', 'closed-sold', 'closed-cancelled']
        patientCreatedDate: null,
        patientClosedDate: null
      },
      message: '',
      practicesFilterIsOpen: false,
      providersFilterIsOpen: false,
      patientStatusFilterIsOpen: false,
      patientCreatedDateFilterIsOpen: false,
      patientClosedDateFilterIsOpen: false
    })
    const popup = getOverlayUpdateData(SHOW_POPUP, {
      type: CONFIRMATION_POPUP,
      payload: {
        title: 'ProfileNav.marketing.messages',
        message: 'groupMessage.note'
      }
    })
    this.props.updateOverlay(popup, null, true)
    await this.props.RecipientsCounterQuery.refetch({
        filters: '{}'
    });
  }

  isFilterOpen = filterType => {
    return this.state[filterType] || false
  }

  isMesageValid = () => {
    return this.state.message.trim().length > 0
  }

  popupClick = () => {
    this.props.updateOverlay(null, null, false)
    this.setState({ showSuccessPopup: false })
  }

  getPatientsStatusCode = patientStatuses => {
    const mapNameToStatus = {
      'in-trial': 'OPEN',
      'closed-sold': 'SOLD',
      'closed-cancelled': 'CANCELED'
    }
    return reduce(
      patientStatuses,
      (acc, isActive, name) => {
        if (isActive) {
          acc.push(mapNameToStatus[name])
        }
        return acc
      },
      []
    )
  }

  preparedFilters = filters => {
    const {
      selectedPractices = [],
      onlyMyPatients = false,
      patientStatuses = {},
      patientCreatedDate = {},
      patientClosedDate = {}
    } = filters
    let messageFilter = {}
    if (onlyMyPatients) {
        messageFilter = Object.assign({},{...messageFilter},{onlyMyPatient: onlyMyPatients})
    }
    const onlyPractices = selectedPractices.map(item => item.value)

    if (onlyPractices.length > 0) {
        messageFilter = Object.assign({}, {...messageFilter}, {practiceIds: onlyPractices})
    }

    const onlyPatientsWithStatus = this.getPatientsStatusCode(patientStatuses)
    if (onlyPatientsWithStatus.length > 0) {
        messageFilter = Object.assign({}, {...messageFilter}, {journeyStatus: [...onlyPatientsWithStatus]})
    }
    if (!isEmpty(patientCreatedDate)) {
        messageFilter = Object.assign({}, {...messageFilter}, {createdFrom: patientCreatedDate.from, createdTo: patientCreatedDate.to})
    }
    if (!isEmpty(patientClosedDate)) {
        messageFilter = Object.assign({}, {...messageFilter}, {closedFrom: patientClosedDate.from, closedTo: patientClosedDate.to})
    }

    return messageFilter
  }

  hideErrorAlert = () => {
    this.setState({
      hasErrors: false
    })
  }

  isPatientclosedDateFilterDisabled = () => {
    const {
      filters: {
        patientStatuses: {
          'closed-sold': closedSold = true,
          'closed-cancelled': closedCanceled = true
        }
      }
    } = this.state

    return !closedSold && !closedCanceled
  }

  render() {
    const { practices = [], overlay } = this.props
    const {
      showSuccessPopup,
      filters: {
        selectedPractices,
        onlyMyPatients,
        patientStatuses,
        patientCreatedDate,
        patientClosedDate
      },
      isSaving,
      hasErrors,
      message = '',
      marketingRecipientsNumber = 0,
      marketingTotalNumber = 0
    } = this.state
    
    if (isSaving) {
      return <Loading isLoading={isSaving} />
    }
    const isLoadingRecipientsCounter = this.props.loadingRecipientsCounter

    return (
      <Panel.Base className="marketingMessagesPage">
        <div className="container">
          <div className="row">
            {hasErrors &&
              <div className="col-12">
                <Alert variant="danger" onClick={this.hideErrorAlert}>
                  <FormattedMessage {...T('marketing.messages.error')} />
                </Alert>
              </div>}
            {overlay &&
              overlay.popup &&
              <ConfirmationPopup
                onClick={this.popupClick}
                {...overlay.popup.payload}
                showSuccessPopup={showSuccessPopup}
              />}

            <div className="col-12">
              <H2>
                <FormattedMessage {...T('marketing.messages.about')} />
              </H2>

              <Filters onClose={this.onCloseAllDropdowns}>
                <MarketingPracticeFilter
                  onChange={this.onFilterChange}
                  availablePractices={practices}
                  selectedPractices={selectedPractices}
                  onOpenFilter={this.onOpenFilter('practicesFilterIsOpen')}
                  isFilterOpen={this.isFilterOpen('practicesFilterIsOpen')}
                />
                <MarketingProviderFilter
                  onChange={this.onFilterChange}
                  onlyMyPatients={onlyMyPatients}
                  onOpenFilter={this.onOpenFilter('providersFilterIsOpen')}
                  isFilterOpen={this.isFilterOpen('providersFilterIsOpen')}
                />
                <MarketingPatientStatusFilter
                  onSelect={this.updateCount}
                  onChange={this.onFilterChange}
                  patientStatuses={patientStatuses}
                  onOpenFilter={this.onOpenFilter('patientStatusFilterIsOpen')}
                  isFilterOpen={this.isFilterOpen('patientStatusFilterIsOpen')}
                />
                <MarketingPatientCreatedFilter
                  patientCreatedDate={patientCreatedDate}
                  onChange={this.onFilterChange}
                  onOpenFilter={this.onOpenFilter('patientCreatedDateFilterIsOpen')}
                  isFilterOpen={this.isFilterOpen('patientCreatedDateFilterIsOpen')}
                />
                <MarketingPatientClosedFilter
                  patientClosedDate={patientClosedDate}
                  onChange={this.onFilterChange}
                  onOpenFilter={this.onOpenFilter('patientClosedDateFilterIsOpen')}
                  isFilterOpen={this.isFilterOpen('patientClosedDateFilterIsOpen')}
                  isFilterDisabled={this.isPatientclosedDateFilterDisabled()}
                />
                <MarketingCountRecipients
                  recipients={marketingRecipientsNumber}
                  totalRecipients={marketingTotalNumber}
                  loading={isLoadingRecipientsCounter}
                />
              </Filters>

              <MarketingMessages
                onMessageChange={this.onMessageChange}
                message={message}
                loading={this.props.loadingRecipientsCounter}
              />

              <AcceptButtonMarketingMessage
                primary
                disabled={!this.isMesageValid()}
                onClick={this.onSendMessage}
                className="btn--min-width"
              >
                <FormattedMessage {...T('common.send')} />
              </AcceptButtonMarketingMessage>
            </div>
          </div>
        </div>
      </Panel.Base>
    )
  }
}

/* eslint-disable no-unused-vars */

const withData = compose(
  graphql(MUTATION_SEND_MESSAGE, {
    props: ({ mutate }) => ({
      sendMarketingMessage: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(QUERY_RECIPIENTS_COUNTER, {
    name: 'RecipientsCounterQuery',
    props: mapRecipientsCounterProps,
    options: recipientsCounterQueryOptions
  }),
  graphql(QUERY_ASSIGNED_PRACTICES, {
    name: 'FiltersQuery',
    props: mapProps,
    options: assignedPracticesOptions
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: popupMapProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  injectIntl
)

export default withData(MarketingMessagePage)
