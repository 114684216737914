
export const getAutoReplyConfigurationForOrganization = modeType => {
    let helpText = ""
    const autoReplyConfig = { 
        autoReplyEnabled: false,
        autoReplyMode: modeType, 
        isMessageFieldVisible: false ,
        helpText   
    }
    switch(modeType){
        case undefined:
        case null:
        case "ENABLED":
            helpText = "orgManagement.autoReply.enabledHelpText"
            return { ...autoReplyConfig, helpText };
        case "DISABLED":
            helpText = "orgManagement.autoReply.disabledHelpText"
            return  { ...autoReplyConfig, helpText };
        case "ORG_MESSAGE_ONLY":
            helpText = "orgManagement.autoReply.orgMessageOnlyHelpText"
            return  { ...autoReplyConfig, isMessageFieldVisible:true,autoReplyEnabled:true, helpText };
        case "ORG_MESSAGE_DEFAULT":
            helpText = "orgManagement.autoReply.orgMessageDefaultHelpText"
            return  { ...autoReplyConfig,  isMessageFieldVisible:true,autoReplyEnabled:true, helpText };
    }
}

export const getAutoReplyConfigurationForPractice = ( practice={}, organizationConfig={}, isNewPratice = false ) => {
  
    let helpText = ""
    const autoReplyConfig = { 
        orgDefault: true,  
        isConfigVisible: true , 
        canEditAutoReply: false, 
        canEditMessage: false , 
        autoReplyEnabled: isNewPratice? organizationConfig.autoReplyEnabled : practice.autoReplyEnabled, 
        autoReplyMessage: isNewPratice? organizationConfig.autoReplyMessage  :  practice.autoReplyMessage ,
        helpText   
    }
    switch(organizationConfig.autoReplyMode){        
        case undefined:
        case null:    
        case "ENABLED": 
            helpText = "practice.autoReply.enabledHelpText"
            return { 
                ...autoReplyConfig, 
                orgDefault: false, 
                canEditAutoReply: true, 
                canEditMessage: true,
                helpText
            };
        case "DISABLED":
            helpText = "practice.autoReply.disabledHelpText"
            return { ...autoReplyConfig, isConfigVisible: false, helpText };
        case "ORG_MESSAGE_ONLY":
            helpText = "practice.autoReply.orgMessageOnlyHelpText"
            return { 
                ...autoReplyConfig,
                helpText 
            };
        case "ORG_MESSAGE_DEFAULT":
            helpText = "practice.autoReply.orgMessageDefaultHelpText"
            return { 
                ...autoReplyConfig,
                canEditMessage: true, 
                helpText 
            };
    }
}