import { ApolloProvider } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import DevTools from 'components/_Dev'
import 'whatwg-fetch'
import 'es6-promise'
import 'bootstrap'
import React from 'react'

import ReactDOM from "react-dom/client";
//eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { FormattedMessage, IntlProvider } from 'react-intl'

import '@formatjs/intl-pluralrules/polyfill'

import { ThemeProvider } from 'styled-components'
import defaultTheme from 'themes/default'
import { T, tracker, AnalyticsTrackerStore } from 'core'
import en from '@formatjs/intl-pluralrules/locale-data/en'

import App from 'containers/App'

import ConnectedIntlProvider from 'containers/ConnectedIntlProvider'
import fflags from 'helper/FFlags'
import { getToken } from 'helper/AuthHelper'
import { publicPaths, MaintenancePublicPaths } from 'helper/constants'
import LandingPage from 'containers/LandingPage'
import { configure, authorize } from 'helper/fetch-token-intercept/src'
import { interceptorConfiguration } from './helper/interceptor-config'
import './web.config'
import { ErrorBoundary } from 'ErrorBoundary'
import MaintenancePage from 'components/MaintenancePage'

import 'helper/CustomYupValidations'

if (!!window.TRACK_ENV) {
  const analyticsTracker = new AnalyticsTrackerStore()
  tracker.use(analyticsTracker.track)
}

configure(interceptorConfiguration)

import { graphQLClient } from './graphql/client'
import Autheticate from "./Autheticate";
import { AppRoutes } from "./appRoute";

window.graphQLClient = graphQLClient

const authRequired = pathname => {
  const tokens = getToken(true)
  const isLoggedIn = !!tokens.access_token
  if (isLoggedIn) authorize(tokens.refresh_token, tokens.access_token)
  const isPublicPath = publicPaths.indexOf(pathname) !== -1
  const isMaintenancepublicPaths = MaintenancePublicPaths.indexOf(pathname) !== -1
  if (!isMaintenancepublicPaths && fflags.variation('maintenance-mode', false)) {
    return <MaintenancePage />
  } else if (!isLoggedIn && !isPublicPath) {
    var path = '/login'
    if (pathname != '/') {
      path = path + '?returnurl=' + pathname
    }
    return <Navigate to={path} />
  } else return <App />
}



fflags.on('ready', async () => {
  if (window.localStorage.locale) {
    const { locale } = JSON.parse(window.localStorage.locale)
    const newLanguage = locale.split('-')[0]
    await import(`@formatjs/intl-pluralrules/locale-data/${newLanguage}`).then(lang => {
      new IntlProvider(lang)
    })
  }

  loadErrorMessages();
  loadDevMessages();
  // load the default language
  new IntlProvider(en)
  const root = ReactDOM.createRoot(document.getElementById("react-root"));
  root.render(
    <ApolloProvider client={graphQLClient}>
      <ConnectedIntlProvider>
        <ThemeProvider theme={defaultTheme}>
          <Router>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </ConnectedIntlProvider>
    </ApolloProvider>
  )


})

