// @flow
import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import messages from 'i18n/messages.json'
import CustomerRowOpen from './CustomerRowOpen'
import SortableTableHeader from 'components/SortableTableHeader'
import config from 'config'
import { StyledTable } from "styledComponents/Table/StyledTable";
import fflags from 'helper/FFlags'

type Props = {
  isFiltered: boolean,
  customers: Array<Object>,
  intl: Object,
  setSort: Function,
  showCustomerDetailsPage: Function,
  sort: string
}

export class CustomersOpenTable extends React.Component {
  props: Props

  showCustomerDetailsPage = (customer: Object): Function => () => {
    this.props.showCustomerDetailsPage(customer)
  }

  setSort = (sortField: string): Function => () => {
    this.props.setSort(sortField)
  }

  render() {
    const { isFiltered, customers, sort, intl: { formatMessage } } = this.props
    const isConnectDaToTelecareEnabled = fflags.variation(
      'enable-da-in-telecare',
      false
    )
    // used to show Staff column outside of China
    const showStaffColumn = config.variant !== 'china'

    return (
      <StyledTable className="table-condensed table--patients">
        <thead>
          <tr data-qa="open-table-headlines">
            {showStaffColumn &&
              <th className="text-center col-1">
                {formatMessage(messages.customerOverviewThFitter)}
              </th>}
            <SortableTableHeader
              className="col-2"
              name="firstName"
              onClick={this.setSort('firstName')}
              value={formatMessage(messages.commonFirstName)}
              sort={sort}
            />
            <SortableTableHeader
              className="col-2"
              name="lastName"
              onClick={this.setSort('lastName')}
              value={formatMessage(messages.commonLastName)}
              sort={sort}
            />
            <SortableTableHeader
              name="currentProgress"
              sort={sort}
              onClick={this.setSort('currentProgress')}
              value={formatMessage(messages.customerOverviewThTrainingStatus)}
              className="col-2"
            />
            <SortableTableHeader
              name="lastSatisfactionRating"
              sort={sort}
              onClick={this.setSort('lastSatisfactionRating')}
              value={formatMessage(messages.customerOverviewThSatisfaction)}
              className="text-center col-1"
            />
            {isConnectDaToTelecareEnabled &&           
              <SortableTableHeader
                name="assistant"
                onClick={this.setSort('assistantHistoryStatus')}
                sort={sort}
                className="text-center col-1"
                value={formatMessage(messages.customerOverviewThAssistant)} 
              />
            }
            <SortableTableHeader
              name="numberOfUnreadChatInteractions"
              sort={sort}
              onClick={this.setSort('numberOfUnreadChatInteractions')}
              value={formatMessage(messages.customerOverviewThMissedCalls)}
              className="text-center col-1"
            />
            <SortableTableHeader
              name="journeyStatus"
              onClick={this.setSort('journeyStatus')}
              sort={sort}
              className="text-left col-1"
              value={formatMessage(messages.customerOverviewThStatus)}
            />
            <SortableTableHeader
              name="journeyStartedOn"
              onClick={this.setSort('startingDate')}
              sort={sort}
              className="text-center col-1"
              value={formatMessage(messages.customerOverviewThDuration)}
            />
            <SortableTableHeader
              name="saleStatus"
              onClick={this.setSort('saleStatus')}
              sort={sort}
              className="text-center col-1"
              value={formatMessage(messages.customerOverviewThSale)}
            />
            <SortableTableHeader
              name="appConnected"
              onClick={this.setSort('appConnected')}
              sort={sort}
              className="text-center col-1"
              value={formatMessage(messages.customerOverviewThAppConnected)}
            />
          </tr>
        </thead>
        <tbody>
          {!customers.length &&
            <tr>
              <td colSpan="10" className="text-center" data-qa="no-open-customers">
                {isFiltered
                  ? <FormattedMessage {...messages.customerOverviewNothingFound} />
                  : <FormattedMessage {...messages.customerOverviewNoCustomersAvailable} />}
              </td>
            </tr>}
          {customers.map((customer, i) =>
            <CustomerRowOpen
              onClick={this.showCustomerDetailsPage(customer)}
              customer={customer}
              key={i}
              i={i}
            />
          )}
        </tbody>
      </StyledTable>
    )
  }
}

export default injectIntl(CustomersOpenTable)
