import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Wrapper } from 'styledComponents/Div/DivContainer/DivPatientList'
import _ from 'lodash'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

class SectionSelector extends React.Component {
  static defaultProps = {
    location: {}
  }

  render() {
    const { location, createNewElementData, intl: { formatMessage }, me={} } = this.props
    const { label, link, state, visible } = createNewElementData
    const employee = _.get(me, 'employee', {})
    const isOrgManagementEnabled =  employee.orgManager
    const orgId = me?.organizationId;
	  const orgLink = `/overview/orgManagement/organization/${orgId}/edit` 
    return (
      <>
      <Wrapper className="row">
        <div className="col-3">
          <StyledButtonLink
            $buttontertiarystyle
            data-qa="userManagement"
            to={`/overview/orgManagement/users`}
            className={`${location.pathname === '/overview/orgManagement/users' ? 'active' : ''}`}
          >
            <FormattedMessage {...T('orgManagement.users')} />
          </StyledButtonLink>
        </div>
        <div className="col-3">
          <StyledButtonLink
            $buttontertiarystyle
            data-qa="practiceManagement"
            to={`/overview/orgManagement/practices`}
            className={`${location.pathname === '/overview/orgManagement/practices' ? 'active' : ''}`}
          >
            <FormattedMessage {...T('orgManagement.practices')} />
          </StyledButtonLink>
        </div>
        <div className="col-3">
            <StyledButtonLink
              $buttontertiarystyle
              data-qa="manageLessonSetForSignia"
              to={`/overview/orgManagement/lessons`}
              className={`${location.pathname === '/overview/orgManagement/lessons' ? 'active' : ''}`}
            >
              <FormattedMessage {...T('common.hearingLessons')} />
            </StyledButtonLink>
        </div> 
        {visible && 
          <div className="col-3">
            <StyledButtonLink
              $buttonprimarystyle
              to={state != undefined ? { pathname: link, state: state } : link}
              data-qa="manage__add-user-button"
            >
              {formatMessage(T(label))}
            </StyledButtonLink>
          </div>}
      </Wrapper>
      {isOrgManagementEnabled &&<Wrapper className="row" style={{ marginTop: '25px'}}>
          <div className="col-3">
                <StyledButtonLink
                  $buttontertiarystyle
                  data-qa="organizationManagement"
                  to={orgLink}
                  className={`${location.pathname === orgLink ? 'active' : ''}`}
                >
                  <FormattedMessage {...T('navbar.organization')} />
                </StyledButtonLink>
              </div>          
        </Wrapper>
        }
      </>
    )
  }
}
export default injectIntl(SectionSelector)
