import { gql } from "@apollo/client";

export const QUERY_PATIENT_HIS = gql`
  query PatientHIsQuery($patientId: Int!) {
    HearingDevices(customerId: $patientId) {
      leftDevice {
        id
        model
        manufacturer
      }
      rightDevice {
        id
        model
        manufacturer
      }
    }
  }
`