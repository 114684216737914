import { PhoneNumberUtil } from 'google-libphonenumber'
import { isNil, isEmpty } from 'lodash'

const isRequired = (x = '') => !!x && !isNil(x.toString().trim()) && !isEmpty(x.toString().trim())

// true is valid
export const isPhone = (value, countryCode) => {
  if (!value) {
    return true
  }

  const util = PhoneNumberUtil.getInstance()
  try {
    const number = util.parseAndKeepRawInput(value, countryCode)

    if (!util.isValidNumber(number)) {
      throw new Error()
    }
  } catch (e) {
    return false
  }

  return true
}

export default {
  isRequired,
  isPhone
}
