import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment-timezone'
import DateBadge from './DateBadge'
import NewMessagesBadge from './NewMessagesBadge'
import Message from './Message'

const MessageGroup = ({ firstUnreadMessage, group }) => {
  const firstItem = group[0]
  const date = firstItem.createDate
  const isFirstUnread = interaction =>
    firstUnreadMessage && firstUnreadMessage.id === interaction.id

  return (
    <div>
      <DateBadge date={date} />
      {group.map(interaction =>
        <div key={interaction.id}>
          {isFirstUnread(interaction) && <NewMessagesBadge />}
          <Message interaction={interaction} timeZone={moment.tz.guess()}/>
        </div>
      )}
    </div>
  )
}

MessageGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  firstUnreadMessage: PropTypes.shape({})
}

export default MessageGroup
