import React from 'react'
import { Field } from 'formik'
import { T } from 'core'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import messages from 'i18n/messages.json'
import SelectField from 'formfields/SelectField'
import CheckboxField from 'formfields/CheckboxField'
import { StyledCheckboxLabel } from 'styledComponents/Label'
import { StyledCheckboxWrapper } from 'styledComponents/Div/DivComponent/DivComponent'
import InputField from 'formfields/InputField'
import TeCaImprovementProgramInformationModal from '../TeCaImprovementProgramInformationModal'
export default function PracticePage(props) {

  const isDisabled = props.isReviewPage || props.busy
  /**
   * Used to display anchor tag in privacy policy checkbox text
   */
  const options = {
    description: {
      key: 'registerAgreeText',
      values: {
        termsLink: (
          <a
            data-qa="terms-link"
            href={`/terms?country=${props.initialCountryCode.toLowerCase()}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage {...messages.commonTerms} />
          </a>
        ),
        privacyLink: (
          <a
            data-qa="privacy-link"
            href="https://www.sivantos.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage {...messages.commonPrivacy} />
          </a>
        )
      }
    }
  }
  const userDataConsentOptions = {
    description: {
      key: 'tcImprovementProgramLabel'
    }
  }

  const copyOrganizationDetails = () => {
    if (!props.isReviewPage) props.handleCopyDetails()
  }
  return (
    <div>
      <div>
        <h2 data-qa="title">
          <FormattedMessage {...T('register.shopDetailsTitle')} />
        </h2>

        {!props.isReviewPage &&
          <div className="row">
            <div className="col-md-6">
              <StyledCheckboxLabel data-qa={`HIDsettingLabel`} onClick={copyOrganizationDetails} >
                <StyledCheckboxWrapper defaultChecked={false} disabled={isDisabled}>
                  {props.copyDetails && <i className="fas fa-check" aria-hidden="true" />}
                </StyledCheckboxWrapper>
                <span>
                  <FormattedMessage {...T('register.sameAsOrganization')} />
                </span>
              </StyledCheckboxLabel>
            </div>
          </div>}
        <div className="row">
          <div className="col-md-6">
            <Field
              name="accountNumber"
              component={InputField}
              label={props.formatMessage('register.sivantosId')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="pracName"
              component={InputField}
              label={props.formatMessage('register.shopName')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="pracPhoneNumber"
              component={InputField}
              label={props.formatMessage('register.practicePhoneNumber')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="pracAddrStreet"
              component={InputField}
              label={props.formatMessage('common.street')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="pracAddrZipCode"
              component={InputField}
              label={props.formatMessage('common.zip')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="pracAddrCity"
              component={InputField}
              label={props.formatMessage('common.city')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="pracAddrCountryCode"
              component={SelectField}
              label={props.formatMessage('common.country')}
              labelClassName="required"
              options={props.sortedCountryCodesWithCountryNames.map(x => ({
                value: x.countryCode,
                label: x.countryName
              }))}

              disabled={isDisabled}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="pracDefaultTimeZone"
              component={SelectField}
              label={props.formatMessage('common.timezone')}
              labelClassName="required"
              options={moment.tz.names().map(timezone => ({
                value: timezone,
                label: timezone
              }))}

              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="pracDefaultLanguage"
              component={SelectField}
              label={props.formatMessage('hcpEdit.locale')}
              labelClassName="required"
              options={Object.entries(props.language).map(x => ({
                value: x[0],
                label: props.formatMessage('languageName.' + x[0])
              }))}

              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      {props.isReviewPage &&
        <>
          <div data-qa="user-data-consent">
            <Field
              name="userDataConsent"
              component={TeCaImprovementProgramInformationModal}
              label={props.formatMessage('TCImprovementProgram.label')}
              options={userDataConsentOptions}
            />
          </div>
          <div data-qa="root_agreeToTerms">
            <Field
              name="agreeToTerms"
              component={CheckboxField}
              label={props.formatMessage('hcpEdit.locale')}
              labelClassName="required"
              options={options}
            />
          </div>
        </>
      }

    </div>

  )
}