import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)((props) => {
  if (!props.type) return;

  const {
    theme: {
      colors: {
        fontAwesomeIcons: { [props.type]: icon },
      },
    },
  } = props;

  const { color, hover } = icon;

  const colorStyle = () => `color: ${color};`;
  const hoverStyle = () => `color: ${hover}; cursor: pointer;`;

  return css`
    ${color && colorStyle()}
    text-decoration: none;
    border-radius: 0;
    border-color: transparent;
    background-color: transparent;
    cursor: inherit;

    &:hover {
      ${hover && hoverStyle()}
      text-decoration: none;
    }

    &&.icon-lg {
      vertical-align: -0.0667em;
    }

    &&.app-connected {
      margin-right: 5px;
    }

    &.popup-icon {
      margin-top: 12px;
    }

    &.dropdown-toggle-icon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  `;
});
