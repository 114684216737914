import React from 'react'
import { injectIntl } from 'react-intl'
import Markdown from 'react-markdown'
import { T } from 'core'
import { Popup } from './Popup'
import { ActionButton } from 'styledComponents/Span'

const SafariPermissionsPopup = ({ intl: { formatMessage }, onPopupClose }) => {
  const message = (
    <div>
      <Markdown children={formatMessage(T(`browser.NotAllowedErrorSafari`))} />
    </div>
  )

  return (
    <Popup message={message} title={formatMessage(T('browser.no-media-access'))}>
      <ActionButton onClick={onPopupClose} className={`red hangup fas fa-phone`} />
    </Popup>
  )
}

export default injectIntl(SafariPermissionsPopup)
