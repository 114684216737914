import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { T } from 'core'
import { compose } from 'lodash/fp'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'styledComponents/Button'

export const Content = ({ title, message, saved, canceled, stayed }) => {
  return (
    <div data-qa="navigate-away" className="popup--navigate-away">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <StyledPopupFontAwesomeIcon icon={faEdit} type="popupIcon" fixedWidth size="2x" className="popup-icon" /> 
            <h1 data-qa="popup__title">
              <FormattedMessage {...T(title)} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div data-qa="navigate-away-message" className="message">
              <FormattedMessage {...T(message)} />
            </div>
          </div>
        </div>
        <div className="row navigate-away">
          <div className="col-sm-12 text-center">
            <div className="row">
              <div className="col-sm-4">
                <Button
                  tertiary
                  type="button"
                  data-qa="navigate-away-cancel-button"
                  className="btn-block"
                  onClick={stayed}
                >
                  <FormattedMessage {...T('common.stayOnPage')} />
                </Button>
              </div>
              <div className="col-sm-4">
                <Button
                  secondary
                  type="button"
                  data-qa="navigate-away-no-button"
                  className="btn-block"
                  onClick={canceled}
                >
                  <FormattedMessage {...T('journeyConfigurator.restart.dontSave')} />
                </Button>
              </div>
              <div className="col-sm-4">
                <Button
                  primary
                  type="button"
                  data-qa="navigate-away-yes-button"
                  className="btn-block"
                  onClick={saved}
                >
                  <FormattedMessage {...T('journeyConfigurator.restart.save')} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export class NavigateAwayPopup extends Component {
  render() {
    const { popup, isOpen, ...restProps } = this.props

    return (
      isOpen &&
      popup &&
      <div className="popup">
        <div className="popup-inner">
          <Content {...popup.payload} {...restProps} />
        </div>
      </div>
    )
  }
}

NavigateAwayPopup.propTypes = {
  message: PropTypes.string,
  isOpened: PropTypes.bool,
  saved: PropTypes.func,
  canceled: PropTypes.func,
  stayed: PropTypes.func
}
const mapProps = ({ data: { overlay } }) => {
  return { ...overlay }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  })
)
export default withData(NavigateAwayPopup)
