import _ from 'lodash'

export const getLeftRightDevice = (
  deviceType,
  deviceModelId,
  { deviceModelsLeft, deviceModelLeftId }
) => {
  // when position (left or right) is changed than the opposite position should be filtered
  // when changing are comming from the right brand the left devices should be prepopulated
  if (_.isNil(deviceModelId)) {
    return
  }

  // check if we have left device selected
  const isDeviceLeftSelected = !!deviceModelsLeft && deviceModelsLeft.length > 0

  // prepopulate the left device when right is selected
  return {
    ...(deviceType === 'deviceModelRightId'
      ? {
        deviceModelRightId: deviceModelId
      }
      : {}),
    ...(deviceType === 'deviceModelLeftId'
      ? {
        deviceModelLeftId: deviceModelId
      }
      : {})
  }
}
