import React from 'react'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import { ErrorMessage, getIn } from 'formik'
import { PasswordFieldComponent } from 'styledComponents/Div/DivComponent/DivPasswordStrength'

class PasswordReEnterField extends React.Component {
  render() {
    const {
      field,
      className,
      label = this.props.intl.formatMessage(T('common.confirm_password')),
      labelClassName,
      form: { touched, errors },
      required,
      disabled = false
    } = this.props
    const error = getIn(errors, field.name)
    const fieldTouched = getIn(touched, field.name)
    return (
      <PasswordFieldComponent
        className={`form-group passwordReEnter ${error && fieldTouched ? 'has-error' : ''}`}
      >
        <label
          htmlFor={field.name}
          className={`control-label ${required ? 'required' : ''} ${labelClassName || ''}`}
        >
          {label}
        </label>
        <input
          type="password"
          disabled={disabled}
          className={`form-control ${className || ''} ${error && fieldTouched
            ? 'is-password-invalid'
            : ''}`}
          {...field}
          autoComplete={this.props.autoComplete}
        />
        <ErrorMessage
          name={field.name}
          render={msg =>
            <div className="help-block" data-qa="input-error">
              {msg}
            </div>}
        />
      </PasswordFieldComponent>
    )
  }
}

export default injectIntl(PasswordReEnterField)
