
import { getToken } from 'helper/AuthHelper'
import { CorrelationContext, IcelinkLib, LiveswitchLib, videoCallStatus } from 'helper/constants'
import fflags from 'helper/FFlags'
import connector from 'helper/HttpConnector'

export const getVideoCallLibByAppVersion = (appVersion: string): string => {
    const isLiveswitchVideoCallEnabled = fflags.variation(
        'enable-live-switch-video-call-in-telecare',
        false
    ) as boolean;

    const liveswitchVideoRtAppSupportedVersion = fflags.variation(
        'liveswitch-video-rt-app-supported-version',
        null
    ) as string;

    if (isLiveswitchVideoCallEnabled && appVersion >= liveswitchVideoRtAppSupportedVersion) {
        return LiveswitchLib;
    }
    return IcelinkLib;
}

export const saveCallState = async (callType: string, customer: any, employee: any, status: string, supportId: number) => {
    const type = callType === videoCallStatus.audio ? videoCallStatus.audioCall : videoCallStatus.videoCall
    const recipientId = customer.id
    const senderId = employee && employee.id
    const callStatus = {
        type,
        recipientId,
        senderId,
        status
    }

    const correlationContextType = callType === videoCallStatus.audio ? 'AudioVideoCall_AUDIO' : 'AudioVideoCall_VIDEO'
    await connector.postWithCorrelation(getToken(), `hcp/api/v1/patient/${recipientId}/video-call/status`,
        CorrelationContext[correlationContextType] + supportId, callStatus)
}