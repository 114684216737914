import { ApolloClient, ApolloLink } from "@apollo/client";
import { makeExecutableSchema } from '@graphql-tools/schema'
import { SchemaLink } from "@apollo/client/link/schema"
import { onError } from "@apollo/client/link/error";

import schema from './schema'
import resolverMap from './resolvers'
import clientResolver from './apollo/resolvers'
import { apolloLocalCache } from './apollo/cache/apolloLocalCache'
import { defaultDataIdFromObject } from '@apollo/client';

const graphQLSchema = makeExecutableSchema({
  typeDefs: schema,
  resolvers: resolverMap
})



const middleWareLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }
  return forward(operation)
})

function dataIdFromObject(result) {
  console.log(result)
  if (result.__typename) {
    if (result.id !== undefined) {
      return `${result.__typename}:${result.id}`
    }
    if (result.__typename === 'Me') {
      return `${result.__typename}:${result.employee.id}`
    }
  }
  return defaultDataIdFromObject(result)
}

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
  overwrite: true
};

export const graphQLClient = new ApolloClient({
  dataIdFromObject,
  cache: apolloLocalCache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    middleWareLink,
    new SchemaLink({ schema: graphQLSchema })
  ]),
  resolvers: clientResolver,
  defaultOptions
})
