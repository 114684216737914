import { graphql } from '@apollo/client/react/hoc';
import {  gql } from "@apollo/client";
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { injectIntl } from 'react-intl'
import Loading from 'components/Loading'
import AccordionElement from '../../components/AccordionElement'

class LandingFaq extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { loading, Faq } = this.props
    return (
      <div className="landing-faq">
        {loading
          ? <Loading isLoading={loading} />
          : Faq &&
            Faq.map((data, index) => {
              return (
                <AccordionElement
                  allowMarkdown={true}
                  question={data.question}
                  answer={data.answer}
                  key={index}
                />
              )
            })}
      </div>
    )
  }
}

const options = ({ locale }) => ({
  notifyOnNetworkStatusChange: true,
  variables: { context: 'LandingPage', locale },
  fetchPolicy: 'network-only'
})
const mapProps = ({ data: { refetch, loading, Faq } }) => {
  return {
    refetch,
    loading,
    Faq
  }
}

const QUERY_ORDERED_FAQ_PAGE = gql`
  query FaqQuery($context: String, $locale: String) {
    Faq(context: $context, locale: $locale) {
      question
      answer
    }
  }
`
const withData = compose(
  graphql(QUERY_ORDERED_FAQ_PAGE, {
    props: mapProps,
    options
  }),
  injectIntl
)

export default withData(LandingFaq)
