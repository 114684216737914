import * as React from 'react'
import { compose } from 'lodash/fp'
import { WatchQueryFetchPolicy, gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { isEmpty, isNil, sortBy } from 'lodash'
import Loading from 'components/Loading'
import * as Panel from 'components/_Profile/components/Panel'
import { QUERY_LESSON_RATINGS_FOR_SIGNIA } from 'graphql/queries/SigniaLessonRatingsQuery'
import { LessonRatingDetails } from 'components/_Profile/Ratings/LessonRatingDetails'
import { Accordion } from 'react-accessible-accordion'
import CustomerProgress from 'components/_Profile/Ratings/CustomerProgress'
import { Props, State, IMapProps, ISigniaLessonRatings, ILessons } from 'typescript/interfaces/ILessonRatings'
import { isAppSupported } from '../../shared'

import { ActionButtonsEdit } from 'styledComponents/Div/DivComponent/DivPatient'
import LessonRatingsBanner from './LessonRatingsBanner'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'
import { Button } from 'styledComponents/Button'

export class LessonRatings extends React.Component<Props, State> {
  state = {
    lessons: [],
    signiaLessonRatings: [],
    trackRatings: [],
    selectedModuleIndex: 0
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { signiaLessonRatings } = props
    if (!(isNil(signiaLessonRatings) || isEmpty(signiaLessonRatings)) &&
      (signiaLessonRatings !== state.trackRatings)
    ) {
      const combinedLessonRatings = signiaLessonRatings.reduce(
        (acc, value: any) => acc.concat(value.lessons),
        []
      )
      const allLessonsRating = {
        lessonCategory: { description: <FormattedMessage {...T('common.all')} />, categoryName: 'All' },
        lessons: combinedLessonRatings
      }
      const lessonRatings = sortBy(signiaLessonRatings, ['lessonCategory.orderId'])
      lessonRatings.unshift(allLessonsRating)
      return {
        lessons: combinedLessonRatings,
        signiaLessonRatings: lessonRatings,
        trackRatings: signiaLessonRatings
      }
    }
    return null
  }

  onSelectedGroupChange = (lessons: object[], moduleIndex: number) => {
    this.setState({ lessons: lessons, selectedModuleIndex: moduleIndex })
  }

  calculateLessonProgress = () => {
    const signiaLessonRatings: ISigniaLessonRatings[] = this.state.signiaLessonRatings
    let allLessons: ILessons[]
    if (!isNil(signiaLessonRatings[0])) {
      allLessons = signiaLessonRatings[0].lessons
      let completedLessons = 0
      // allLessons contains all the Lessons assigned to patient
      const totalLessonsCount = allLessons.length
      allLessons.forEach((lesson: { ratingDetail: object }) => {
        if (lesson.ratingDetail) {
          completedLessons++
        }
      })
      return Math.floor(completedLessons / totalLessonsCount * 100)
    }

    return 0
  }

  onLessonRestart = async () => {
    await this.props.restartLesson({ patientGuid: this.props.customer.uuid })
    this.props.refetch()
    this.setState({ selectedModuleIndex: 0 })
  }

  render() {
    const { loading, customer } = this.props
    const { lessons, signiaLessonRatings, selectedModuleIndex } = this.state
    const currentProgress = this.calculateLessonProgress()
    const progressbar = { label: 'lessonRating.progressBar.label', currentProgress, barWidth: '26' }
    const IsRestartDisabled = currentProgress === 0 || !isAppSupported(customer)

    if (loading) {
      return <Loading isLoading={loading} />
    }

    return (
      <section className="ratings container">
        {isEmpty(signiaLessonRatings) ?
          <LessonRatingsBanner /> :
          <React.Fragment>
            <Panel.Base modifier="ratings">
              <Panel.Item className="row">
                <div className="col-md-6">
                  <h2 data-qa="ratings__title">
                    <FormattedMessage {...T('ratings.title')} />
                  </h2>
                </div>
              </Panel.Item>
              <CustomerProgress {...progressbar} />
              <Panel.Item className="row">
                {signiaLessonRatings.map((module: { lessons: object[], lessonCategory: { description: string } }, moduleIndex) =>
                  <div className="col-md-2 ratings__div-width" key={`${moduleIndex}-module-button`}>
                    <StyledButtonLink
                      $buttontertiarystyle
                      type="button"
                      className={`ratings__module ${moduleIndex ===
                        selectedModuleIndex
                        ? 'active'
                        : ''}`}
                      onClick={() => this.onSelectedGroupChange(module.lessons, moduleIndex)}
                    >
                      {module.lessonCategory.description}
                    </StyledButtonLink>
                  </div>
                )}
              </Panel.Item>

              {!isEmpty(lessons) &&
                <Accordion allowZeroExpanded={true}>
                  {lessons.map((data: { description: string }, index: number) =>
                    <LessonRatingDetails
                      key={`${index}-${selectedModuleIndex}-${data.description}`}
                      {...data}
                    />
                  )}
                </Accordion>}
            </Panel.Base>
            <div className="offset-md-9 col-md-3">
              <ActionButtonsEdit className="right">
                <Button
                  primary
                  type="button"
                  className="btn-block btn--min-width"
                  disabled={IsRestartDisabled}
                  onClick={this.onLessonRestart}
                  data-qa="lesson-restart-button" >
                  <FormattedMessage {...T(`lesson.restart.label`)} />
                </Button>
              </ActionButtonsEdit>
            </div>
          </React.Fragment>}
      </section>
    )
  }
}

const MUTATION_RESTART_LESSONS = gql`
  mutation restartLessonMutation($patientGuid: String!) {
    restartLesson(patientGuid: $patientGuid)
  }
`

const options = (data) => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
  variables: { patientGuid: data?.customer?.uuid }
})



const mapProps = ({ data: { refetch, loading, SigniaLessonRatings }, ownProps: { intl } }: IMapProps) => ({
  loading,
  refetch,
  signiaLessonRatings: SigniaLessonRatings || [],
  intl
})

const withData = compose(
  graphql(QUERY_LESSON_RATINGS_FOR_SIGNIA, {
    props: mapProps,
    options,
  }),
  graphql(MUTATION_RESTART_LESSONS, {
    props: ({ mutate }) => ({
      restartLesson: (patientGuid: string) =>
        mutate({
          variables: patientGuid
        })
    })
  })
)

export default withData(LessonRatings)
