import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'
import { withRouter } from 'helper/withRouter'
import { withQuery } from '../../helper/withQuery'

export class FinishPage extends Component {
  handleContinue = () => {
    this.props.navigate('/login')
  }

  render() {
    const pending = this.props.query.pending === 'true'
    return (
      <div data-qa="register-confirmation" className="registerPage">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-2">
              <h1 data-qa="title">
                <FormattedMessage
                  {...(pending ? messages.registerPendingTitle : messages.registerSuccessTitle)}
                />
              </h1>
            </div>
          </div>
          <div className="row padding-bottom">
            <div className="col-8 offset-2">
              <h3 data-qa="title-success">
                <FormattedMessage
                  {...(pending
                    ? messages.registerPendingHeadline
                    : messages.registerSuccessHeadline)}
                />
              </h3>
              <p data-qa="message-success">
                <FormattedMessage
                  {...(pending ? messages.registerPendingText : messages.registerSuccessText)}
                />
              </p>
              <br />
              <br />
              <button
                data-qa="login-button"
                className="btn btn-primary col-lg-4 offset-lg-4"
                onClick={this.handleContinue}
                type="submit"
              >
                <FormattedMessage {...messages.registerGotoLogin} />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withQuery(FinishPage))
