import React, { FC, ReactElement } from "react";
import { T } from "core";
import SortableTableHeader from "components/SortableTableHeader";
import { injectIntl } from "react-intl";
import Loading from "components/Loading";
import { StyledTable } from "styledComponents/Table/StyledTable";

const Table: FC<any> = (props): ReactElement => {
  const setSort = (sortField) => () => {
    props.setSort(sortField);
  };
  const {
    sort,
    patients = [],
    isLoading,
    intl: { formatMessage },
  } = props;

  return (
    <StyledTable className="table--users">
      <thead>
        <tr>
          <SortableTableHeader
            className="col-2"
            name="firstName"
            onClick={setSort("firstName")}
            value={formatMessage(T("common.firstName"))}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="lastName"
            onClick={setSort("lastName")}
            value={formatMessage(T("common.lastName"))}
            sort={sort}
          />
          <th id="employeeName" className="col-2">
            {formatMessage(T("register.shopName"))}
          </th>
          <th id="journeyStatus" className="col-2">
            {formatMessage(T("patientStatus"))}
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : patients.length ? (
          patients.map((item, index) => (
            <tr key={`${index}__manage-row`}>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{`${item.employeeFirstName} ${item.employeeLastName}`}</td>
              <td>{item.journeyStatus}</td>
            </tr>
          ))
        ) : (
            formatMessage(T("customer_overview.no_customers_available"))
        )}
      </tbody>
    </StyledTable>
  );
};

export default injectIntl(Table);
