import { Subject } from 'rxjs';

const subject = new Subject();

const fileUploadSubject = new Subject();

export const dataService = {
    setData: d => subject.next({ value: d }),
    clearData: () => subject.next(),
    getData: () => subject.asObservable()
};
export const fileUploadService = {
    setData: d => fileUploadSubject.next({ value: d }),
    clearData: () => fileUploadSubject.next(),
    getData: () => fileUploadSubject.asObservable()
};