import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { T } from 'core'
import Loading from 'components/Loading'
import SigniaLessonsTable from './SigniaLessonsTable'
import { QUERY_LESSONSET_FOR_SIGNIA } from 'graphql/queries/LessonSetQuery'
import { MUTATION_UPDATE_LESSON_SET } from 'graphql/mutations/LessonSetMutation'
import _ from 'lodash'
import { Banner } from 'styledComponents/Div/DivComponent/DivComponent'
import { STANDARD_LESSON_SET_NAME } from 'helper/constants'
import { getEmployeeFromCache } from 'helper/PracticeUtils'

export class ManageLessonSet extends Component {
  state = {
    isEditing: false,
    moduleLength: 0
  }

  setSort = (sortWith, order, data) => {
    const sortData = [...data]
    const standardLessonSet = sortData.find(
      standardLesson => standardLesson.lessonSetName == STANDARD_LESSON_SET_NAME
    )
    const sortDataWithoutStandardLessonSet = sortData.filter(
      withoutStandardLesson => withoutStandardLesson.lessonSetName != STANDARD_LESSON_SET_NAME
    )
    const reverseSortData = [...sortDataWithoutStandardLessonSet].reverse()
    sortDataWithoutStandardLessonSet.sort((a, b) => {
      const nameA = sortWith != 'createdDate' ? a[sortWith].toLowerCase() : new Date(a[sortWith])
      const nameB = sortWith != 'createdDate' ? b[sortWith].toLowerCase() : new Date(b[sortWith])
      if (
        nameA < nameB //sort string ascending
      )
        return -1
      if (nameA > nameB) return 1
      return 0
    })
    sortDataWithoutStandardLessonSet.unshift(standardLessonSet)
    reverseSortData.unshift(standardLessonSet)
    if (order == 'desc' || order == '') return reverseSortData
    if (order === 'asc') return sortDataWithoutStandardLessonSet
  }

  updateLessonSet = async (lessonSetId, lessonSet) => {
    lessonSet.isDefault = Boolean(lessonSet.isDefault)
    await this.props.updateLessonSetForSignia({
      hcpId: getEmployeeFromCache().id,
      lessonSetId: lessonSetId.toString(),
      lessonSet
    })
    await this.props.refetch()
  }

  formatDate = (date) => {
    return new Date(date).toLocaleDateString()
  }

  render() {
    const { loading, Me = {}, LessonSetForSignia = [], intl: { formatMessage } } = this.props
    const sortedLessonSetForSignia =
      LessonSetForSignia && this.setSort('lessonSetName', 'asc', LessonSetForSignia)
    return (
      <div>
        <div>
          <Banner>
            <div className="text-style">
              {formatMessage(T('journeyConfigurator.manageLessonSets.generalInfo'))}
            </div>
          </Banner>
          <div className="row">
            <div className="col-md-12">
              <Loading isLoading={loading} />
              {!loading &&
                LessonSetForSignia &&
                <SigniaLessonsTable
                  Me={Me}
                  lessonSet={sortedLessonSetForSignia}
                  saveAsDefaultLessonSet={this.updateLessonSet}
                  formatDate={this.formatDate}
                />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapProps = ({ data }) => {
  const { refetch, loading, LessonSetForSignia } = data
  return {
    refetch,
    loading,
    LessonSetForSignia
  }
}

const options = ({ employee }) => {
  return {
    variables: { hcpId: employee && employee.id ? parseInt(employee.id) : 0 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }
}

const withData = compose(
  graphql(QUERY_LESSONSET_FOR_SIGNIA, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_UPDATE_LESSON_SET, {
    props: ({ mutate }) => ({
      updateLessonSetForSignia: variables =>
        mutate({
          variables
        })
    })
  }),
  injectIntl
)

export default withData(ManageLessonSet)
