import styled from 'styled-components'

export const H2 = styled.h2`
  margin-bottom: 0;
`

export const Heading2 = styled.h2`
  font-weight: bold;
`

export const Heading3 = styled.h3`
  color: #d60925;
  margin: 0 auto;
  font-weight: bold;
`

export const LessonsTitle = styled.h3`
  margin-bottom: 15px;
`