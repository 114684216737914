import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import MaintenancePage from 'components/MaintenancePage'
import { getToken } from 'helper/AuthHelper'
import { publicPaths, MaintenancePublicPaths } from 'helper/constants'
import App from 'containers/App'
import fflags from 'helper/FFlags'
import { configure, authorize } from 'helper/fetch-token-intercept/src'
import { interceptorConfiguration } from './helper/interceptor-config'

function Autheticate() {
    const location = useLocation();
    const pathname = location.pathname;
    const tokens = getToken(true)
    const isLoggedIn = !!tokens.access_token
    if (isLoggedIn) {
        configure(interceptorConfiguration);
        authorize(tokens.refresh_token, tokens.access_token);
    }
    const isPublicPath = publicPaths.indexOf(pathname) !== -1
    const isMaintenancepublicPaths = MaintenancePublicPaths.indexOf(pathname) !== -1
    if (!isMaintenancepublicPaths && fflags.variation('maintenance-mode', false)) {
        return <MaintenancePage />
    }
    else if (!isLoggedIn && !isPublicPath) {
        var path = '/login'
        if (pathname != '/') {
            path = path + '?returnurl=' + pathname
        }
        return <Navigate to={path} />
    } else
        return <div>
            <App />
        </div>
}

export default Autheticate