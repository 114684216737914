import _ from 'lodash'

export const getLessonSetRequiredField = lessonSet => {
  return (
    lessonSet &&
    lessonSet.map(item => ({
      categoryName: item.lessonCategory.categoryName,
      description: item.lessonCategory.description,
      orderId: item.lessonCategory.orderId,
      id: item.lessonCategory.id,
      isChecked: false,
      lessons: {
        id: item.lessonId,
        title: item.title,
        isChecked: false,
        howTo: item.howTo,
        description: item.description,
        heading: item.heading
      }
    }))
  )
}

export const getLessonSetModules = lessonSet => {
  const groupedLessonSet = _.groupBy(getLessonSetRequiredField(lessonSet), item => item.description)
  const modules = Object.keys(groupedLessonSet).map(key => {
    return _.reduce(
      groupedLessonSet[key],
      function(o, item) {
        o.categoryName = item.categoryName
        o.description = item.description
        o.isChecked = false
        o.lessons.push(item.lessons)
        return o
      },
      { categoryName: '', description: '', isChecked: false, lessons: [] }
    )
  })

  return modules
}
