import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import moment from 'moment'
import _ from 'lodash'
import { T } from 'core'
import { SatisfactionDetails } from 'styledComponents/Div/DivComponent/DivProfile'
import { getLocaleFromCache } from 'helper/LocaleHelper'
import Loading from 'components/Loading'
import { SatisfactionList } from '../SatisfactionList'
import SatisfactionChart from '../SatisfactionChart'
import AssistantHistory from '../AssistantHistory'
import fflags from 'helper/FFlags'
import { withRouter } from "helper/withRouter"
import { DataCollectionType } from 'helper/constants'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { SatisfactionData } from '../../../../helper/DataCollection/PayloadData'

const dateRegEx = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(Z|([+\-])(\d{2}):(\d{2})|([+\-])(\d{4}))$/
const dateReviver = (key, value) => {
  if (typeof value === 'string') {
    if (dateRegEx.test(value)) {
      const x = moment(value)
      return x.toDate()
    }
  }
  return value
}

const SatisfactionDetail = ({ rating }) => {
  return (
    <SatisfactionDetails>
      <h2>Details</h2>
      <div className="row">
        {rating.feedback.map((detail, index) => {
          return (
            <div key={`${index}-details`} className="col-md-4 ">
              <p>
                {detail.question}
              </p>
              <p>
                {detail.feedback}
              </p>
            </div>
          )
        })}
      </div>
    </SatisfactionDetails>
  )
}

export class SatisfactionPage extends Component {
  state = {
    details: false,
    showDaHistory: false,
    selectedDate: new Date(),
    daHistoryRatingDate: null
  }
  createSatisfactionItemData(items) {
    return items.map(item => {
      const ratingCount = item.dailySatisfactions.length

      // NOTE: +1 is added for presentation purposes
      const sumOfRatings = ratingCount
        ? item.dailySatisfactions.reduce((prev, cur) => {
          if (prev) {
            return prev + cur.rating + 1
          }
          return cur.rating + 1
        }, false)
        : null
      const averageRating = ratingCount ? Number((sumOfRatings / ratingCount).toFixed(0)) : -1
      return {
        title: item.deviceName,
        averageRating: averageRating
      }
    })
  }
  getSelectedDateFromCalendar = selectedDate => {
    this.setState({ selectedDate })
  }
  onClickLessonRating = () => {
    const { navigate, customer } = this.props
    navigate({ pathname: `/profile/${customer.id}/ratings` })
  }

  onClickSatisfaction = (evt, dataobj) => {
    const { customer } = this.props
    const isConnectDaToTelecareEnabled = fflags.variation(
      'enable-da-in-telecare',
      false
    )
    // when rating with questionnaire flow
    if (dataobj && dataobj.feedback && dataobj.feedback.length > 0) {
      this.setState({
        details: dataobj,
        showDaHistory: false
      })
    } else if (isConnectDaToTelecareEnabled && dataobj && dataobj.feedback && dataobj.rating <= 2 && dataobj.feedback.length === 0) {
      // when [0,1,2] negative rating with Digital Assistant Flow
      this.setState({
        showDaHistory: true,
        details: false,
        daHistoryRatingDate: dataobj.date
      })
    }
    sendDataToDataCollection(DataCollectionType.PatientData, SatisfactionData(customer, dataobj), EventType.SatisfactionIconClick)
  }

  render() {
    const {
      loading,
      satisfactionRatings = [
        {
          deviceName: '',
          startDate: '',
          dailySatisfactions: []
        }
      ],
      intl: { formatMessage }
    } = this.props

    const { details, selectedDate, showDaHistory, daHistoryRatingDate } = this.state
    const data = JSON.parse(JSON.stringify(satisfactionRatings), dateReviver)
    const newData = data
    //(dsPart.rating - 1) as rating domain is [0,1,2,3,4] for the chart
    data.forEach(function (part, index) {
      part.dailySatisfactions.forEach(function (dsPart, dsIndex) {
        newData[index].dailySatisfactions[dsIndex].rating = dsPart.rating - 1
      })
    })
    // filter out prefitting patients
    const withoutPrefitingData = newData.filter(
      item => item.deviceName && item.dailySatisfactions.length > 0
    )
    // sets the date one month previous to selected date of calendar

    const fromDate = new Date(selectedDate)
    fromDate.setMonth(fromDate.getMonth() - 1)
    const filteredDataByDate = withoutPrefitingData.map(element => {
      const n = Object.assign({}, element, {
        dailySatisfactions: element.dailySatisfactions.filter(
          ratings => ratings.date > fromDate && ratings.date <= selectedDate
        )
      })
      return n
    })
    // filter out dailySatisfactions
    const filteredDataByDailySatisfactions = filteredDataByDate.filter(
      item => item.dailySatisfactions.length > 0
    )
    const items = this.createSatisfactionItemData(filteredDataByDailySatisfactions, formatMessage)
    const tickLabels = [
      formatMessage(T('SatisfactionChart.veryunsatisfied')), // very unsatisfied
      formatMessage(T('SatisfactionChart.unsatisfied')), // unsatisfied
      formatMessage(T('SatisfactionChart.neutral')), // neutral
      formatMessage(T('SatisfactionChart.satisfied')), // satisfied
      formatMessage(T('SatisfactionChart.verysatisfied')) // very satisfied
    ]
    return (
      <section className="row satisfactionPage">
        <Loading isLoading={loading} />
        <div className="col-md-12">
          <SatisfactionList items={items} />
        </div>
        <div className="col-md-10" style={{ display: 'flex' }}>
          <SatisfactionChart
            data={filteredDataByDailySatisfactions}
            tickLabels={tickLabels}
            onClickLessonRating={this.onClickLessonRating}
            onClickSatisfaction={this.onClickSatisfaction}
          />
        </div>

        {details &&
          <div className="col-md-12">
            <SatisfactionDetail rating={details} />
          </div>}
        {showDaHistory &&
          <div className="col-md-10">
            <SatisfactionDetails>
              <AssistantHistory
                customer={this.props.customer} Practice={this.props.Practice} ratingDate={daHistoryRatingDate} />
            </SatisfactionDetails>
          </div>}
      </section>
    )
  }
}

export const mapProps = ({
  data: { refetch, loading, SatisfactionRatings = [] },
  ownProps: { intl }
}) => {
  return {
    refetch,
    loading,
    satisfactionRatings: SatisfactionRatings,
    intl
  }
}

const QUERY_SATISFACTION = gql`
  query SatisfactionQuery($customerId: Int!, $locale: String!) {
    Me {
      employee {
        id
        manager
        orgManager
      }
    }
    SatisfactionRatings(customerId: $customerId, locale: $locale) {
      deviceName
      startDate
      dailySatisfactions {
        rating
        date
        feedback {
          question
          feedback
        }
      }
    }
  }
`

const options = (data) => {
  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      customerId: parseInt(data?.params?.id),
      locale: getLocaleFromCache()
    },
    fetchPolicy: 'network-only'
  }
}

const withData = compose(
  graphql(QUERY_SATISFACTION, {
    props: mapProps,
    options
  })
)

export default withRouter(withData(injectIntl(SatisfactionPage)))
