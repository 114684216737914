import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { T } from 'core'
import { LinkButton } from 'styledComponents/Button'
import UserGuideImg from 'assets/img/user_guide.png'
import TeleCareQuickGuideDoc from 'assets/docs/TeleCare_Quickstart_Guide.pdf'

export class TeleCareQuickGuide extends React.Component<any, any> {

  onQuickGuideButtonClick = (e) => {
    e.preventDefault();
    window.open(TeleCareQuickGuideDoc, '_blank');
  }

  render() {
    return (
      <div className="telecareQuickGuide">
        <img src={UserGuideImg} />
        <LinkButton className='linkButton' onClick={this.onQuickGuideButtonClick}>
          <FormattedMessage {...T("TCQuickGuide.label")} />
        </LinkButton>
      </div>
    );
  }
}

export default injectIntl(TeleCareQuickGuide)