import React from "react";
import { ErrorMessage, getIn } from "formik";
import { injectIntl } from "react-intl";
import { T } from "core";

const InputWithIconAndTextField = ({
  field,
  className,
  label,
  labelClassName,
  form: { touched, errors },
  isVerified,
  hideIconAndText,
  intl: { formatMessage },
  onChange,
  ...props
}) => {
  return (
    <div
      className={`form-group ${getIn(errors, field.name) && getIn(touched, field.name)
        ? "has-error"
        : ""
        }`}
    >
      {label && (
        <label
          htmlFor={field.name}
          className={`control-label ${labelClassName || ""}`}
        >
          {label}
        </label>
      )}
      <div className="form-input">
        <input
          onChange={onChange}
          {...field}
          {...props}
          className={`form-control inputWithTextIcon ${className || ""}        
          ${getIn(errors, field.name) && getIn(touched, field.name)
              ? "has-error"
              : ""
            }
          
        `
        }
          autoComplete={props.autoComplete}
        />
        <span className={hideIconAndText ? "displayNone" : "inputSpanText"}>
          {isVerified
            ? formatMessage(T("common.verified"))
            : formatMessage(T("common.unverified"))}
          <i
            className={`verifiedIcon fa ${isVerified
              ? "fa-check-circle colorVerified"
              : "fa-times-circle colorUnverified"
              }`}
            aria-hidden="true"
          />
        </span>
      </div>
      <ErrorMessage
        name={field.name}
        render={(msg) => (
          <div className="help-block" data-qa="input-error">
            {msg}
          </div>
        )}
      />
    </div>
  );
};

export default injectIntl(InputWithIconAndTextField);