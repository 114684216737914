import React from 'react'
import Item from './components/Item'
import { Subnavigation } from 'styledComponents/Div/DivComponent/DivDashboard'

const SubnavigationComponent = ({ items, location }) => {
  return (
    <Subnavigation className="row">
      {items.map((options, i) =>
        <div key={`subnavItem-${i}`} className="col-md-3">
          <Item {...{ ...options, location }} />
        </div>
      )}
    </Subnavigation>
  )
}

export default SubnavigationComponent
