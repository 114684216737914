import styled, { css } from "styled-components";

export const StyledChatMessage = styled.div((props) => {
  const {
    theme: {
      colors: {
        chatMessage: {
          messageOut: { backgroundColor: messageOutBackgroundColor },
          messageIn: { 
            backgroundColor: messageInBackgroundColor, 
            digitalAssistant: {
              backgroundColor: daMessageInBackgroundColor, 
              color: daMessageInFontColor
            }
          },
        },
      },
    },
  } = props;

  return css`
    position: relative;
    display: block;
    max-width: 40%;
    margin: 5px 0;
    padding: 7px 15px 12px;
    border-radius: 15px;
    white-space: pre-line;
    word-break: break-word;
    a {
      color: rgb(255, 255, 255);
    }

    &.message-in {
      color: #e1e1e1;
      background-color: ${messageInBackgroundColor};
      text-align: left;
      margin-left: 40px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: -2px;
        left: -10px;
        height: 15px;
      }

      &:before {
        border-right: 20px solid ${messageInBackgroundColor};
        background: transparent;
        border-bottom-right-radius: 80px 50px;
        transform: translate(0, -2px);
      }

      &:after {
        width: 20px;
        background: #ffffff;
        border-bottom-right-radius: 40px 50px;
        transform: translate(-10px, -2px);
      }
    }

    &.da-message-in {
      color: ${daMessageInFontColor};
      background-color: ${daMessageInBackgroundColor};
      text-align: left;
      margin-left: 45px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: -2px;
        left: -10px;
        height: 15px;
      }

      &:before {
        border-right: 20px solid ${daMessageInBackgroundColor};
        background: transparent;
        border-bottom-right-radius: 80px 50px;
        transform: translate(0, -2px);
      }

      &:after {
        width: 20px;
        background: #ffffff;
        border-bottom-right-radius: 40px 50px;
        transform: translate(-10px, -2px);
      }
    }

    &.message-out {
      color: #000000;
      background-color: ${messageOutBackgroundColor};
      text-align: left;
      margin: 10px 15px 10px auto;

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        bottom: -2px;
        height: 15px;
      }

      &:before {
        right: -10px;
        border-left: 20px solid ${messageOutBackgroundColor};
        background: transparent;
        border-bottom-left-radius: 80px 50px;
        transform: translate(0, -2px);
      }

      &:after {
        right: -40px;
        width: 30px;
        background: #ffffff;
        border-bottom-left-radius: 40px 50px;
        transform: translate(-10px, -2px);
      }
    }
  `;
});
