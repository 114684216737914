import { graphql } from '@apollo/client/react/hoc';
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

import _ from 'lodash'
import * as Panel from 'components/_Profile/components/Panel/index'
import { getProgramStatus, updateEqualizerSetting } from './backend-calls'
import Loading from 'components/Loading'
import { HearingAidStatus } from 'components/_Profile/components/HearingAidStatus'
import ProgramSelector from 'components/_Profile/components/ProgramSelector/index'
import SaveSection from 'components/_Profile/components/SaveSection/index'
import FourBandEqualizer from 'components/Equalizer/index'
import { check, flags } from 'helper/customerCapabilities'
import { customerTracking } from 'helper/customerTracking'

import { isAppSupported } from '../../shared'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { HEARING_DEVICES_QUERY } from 'graphql/queries/HearingDevicesQuery'
import { UPDATE_POPUP } from 'graphql/queries/ShowPopUpQuery'

import {
  P_SUCCESS,
  SHOW_POPUP,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'
import { compose } from 'lodash/fp'
import { HEARING_AID_STATUS } from './../../../graphql/queries/HearingAidStatusQuery'
import { EQUALIZER_SETTING_QUERY } from '../../../graphql/queries/EqualizerSettingQuery'
import { APP_INFO_QUERY } from '../../../graphql/queries/AppInfoQuery'

import { getSupportId } from '../../../helper/ApplicationInsightSupportId'
import { CorrelationContext } from 'helper/constants'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'
import { DataCollectionType } from 'helper/constants'
import { EqualizerPayload } from 'helper/DataCollection/PayloadData'
import { sendDataToDataCollection } from './../../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from './../../../helper/DataCollection/DataCollectionConstants'
import { withRouter } from 'helper/withRouter'

let SupportId = getSupportId()

export class ProfileTuning extends React.Component {
  constructor(...args) {
    super(...args)

    this.state = {
      isLoading: false,
      dirty: false,
      program: {}
    }
    this._bandComponent = null
  }

  UNSAFE_componentWillMount() {
    const { location = { pathname: '', search: '' } } = this.props
    FlushEventsToAppInsight('Navigated to Remote Tuning')
    customerTracking({
      ...location,
      action: 'btSnapshotDetailsTuningOpened'
    })
  }

  componentWillUnmount() {
    this._bandComponent = null
    FlushEventsToAppInsight('Navigated away from Remote Tuning')
    SupportId = getSupportId()
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.hearingDevices || nextProps.loading) return false
    const { program } = this.state
    const { hearingDevices: { hdPrograms } } = nextProps
    const { equalizerSetting } = nextProps
    const equalizerBandSettings = this.setEqualizerSettings(hdPrograms, equalizerSetting)
    // on programs are loaded for the first time
    if (_.isEmpty(program) && hdPrograms && equalizerSetting) {
      this.setState({ program: equalizerBandSettings[0] })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((!nextProps.loading && this.props.loading) || (!nextProps.hearingDeviceLoading && this.props.hearingDeviceLoading)) return true
    // if equalizer setting from graphql has arrived, update component!
    if (
      nextProps &&
      nextProps.equalizerSetting &&
      (!this.props.equalizerSetting ||
        !_.isEqual(this.props.equalizerSetting, nextProps.equalizerSetting))
    ) {
      return true
    } else if (_.isEqual(this.state, nextState)) {
      // else, don't update unless state changes!
      return false
    } else return true
  }

  /**
   * When program is updated status of latest interaction must be checked
   * @params (prevProps, prevState)
   */
  componentDidUpdate(prevProps, prevState) {
    const { program } = this.state
    if (prevState.program && program && prevState.program.programNumber !== program.programNumber) {
      this.reset()
    }
  }

  onProgramChange = program => {
    this.setState({ program })
  }

  setDirty = value => {
    this.setState({ dirty: value })
  }

  reset() {
    if (this._bandComponent) {
      this._bandComponent.reset()
    }
  }

  updateProgram = () => {
    console.log('Update program info')
  }

  onSaved() {
    this.reset()
    this.setState({ dirty: false })

    this.props
      .refetch() // refetches hearing devices.
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }))

    this.setState({ isLoading: false })
    const popup = getOverlayUpdateData(SHOW_POPUP, {
      type: P_SUCCESS,
      payload: {
        title: 'createPreset.successTitle',
        message: 'createPreset.successMessage'
      }
    })

    this.props.updatePopup({ ...popup })
  }

  save = () => {
    this.setState({ isLoading: true })
    const { customer } = this.props
    const { hdPreset } = this._bandComponent.state

    const newEqualizerSetting = {
      band1: hdPreset.equalizer1,
      band2: hdPreset.equalizer2,
      band3: hdPreset.equalizer3,
      band4: hdPreset.equalizer4,
      programSlot: hdPreset.programNumber,
      valid: true
    }

    const equalizerValues = {
      band1: hdPreset.equalizer1,
      band2: hdPreset.equalizer2,
      band3: hdPreset.equalizer3,
      band4: hdPreset.equalizer4
    }

    const equalizerPayload = {
      Equalizer: equalizerValues,
      ProgramName: hdPreset.name,
      uuid: customer.uuid
    }

    const program = hdPreset // persistent devices could change only program
    updateEqualizerSetting(customer.id, newEqualizerSetting, SupportId).then(
      payload => {
        this.props.refetch().then(() => {
          this.setState({
            program: {
              ...program,
              equalizer1: payload.band1,
              equalizer2: payload.band2,
              equalizer3: payload.band3,
              equalizer4: payload.band4,
              programNumber: payload.programSlot
            }
          })
          customerTracking({
            ...location,
            action: 'customerPresetSent'
          })
          sendDataToDataCollection(DataCollectionType.RemoteTuning, EqualizerPayload(customer, equalizerPayload), EventType.RemoteTuning)
          this.onSaved()
        })
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  discardChanges = () => {
    this.reset()
  }

  /**
   * Based on persistentConfigurationSupport, remoteFittingSupport, bluetooth properties detect the worst device
   * @returns device {*}
   */
  getLowestLevelDevice() {
    const { hearingDevices = {} } = this.props
    const { leftDevice = {}, rightDevice = {} } = hearingDevices
    const leftDeviceCopy = structuredClone(leftDevice);
    const rightDeviceCopy = structuredClone(rightDevice);

    return [leftDeviceCopy, rightDeviceCopy]
      .map(item => {
        if (!item) return

        const isPersistent = _.get(item, 'persistentConfigurationSupport', false)
        const supportFitting = _.get(item, 'remoteFittingSupport', false)
        const bluetooth = _.get(item, 'bluetooth', false)

        const features = [isPersistent, supportFitting, bluetooth]

        item.configurationPoints = 0

        features.forEach(isSupportedFeature => {
          isSupportedFeature ? item.configurationPoints++ : item.configurationPoints--
        })

        return item
      })
      .reduce(
        (device, item) =>
          !item || item.configurationPoints > device.configurationPoints ? device : item,
        rightDevice || leftDevice
      )
  }


  render() {
    const leastD11PlatformNumber = 21
    if (this.props.loading || this.props.hearingDeviceLoading)
      return <Loading isLoading={this.props.loading || this.props.hearingDeviceLoading} />
    const { customer, hearingDevices, hearingAidStatus, equalizerSetting } = this.props
    const { dirty, program = {}, isLoading } = this.state
    const remoteFittingSupport = _.get(this.getLowestLevelDevice(), 'remoteFittingSupport', false)
    if (!isLoading && !remoteFittingSupport) {
      return (
        <h2 data-qa="tuning__title--not-available" className="profile-tuning__title--not-available">
          <FormattedMessage {...T('tuning.not-available')} />
        </h2>
      )
    }

    const isD11 =
      (hearingDevices &&
        hearingDevices.leftDevice &&
        Number(hearingDevices.leftDevice.platform) >= leastD11PlatformNumber) ||
      (hearingDevices &&
        hearingDevices.rightDevice &&
        Number(hearingDevices.rightDevice.platform) >= leastD11PlatformNumber)
    return (
      <section data-qa="tuning" className="container">
        <Loading isLoading={isLoading} />
        <Panel.Base className="profile-tuning" modifier="tuning">
          <Loading isLoading={isLoading} />

          {hearingAidStatus &&
            <Panel.Item>
              <HearingAidStatus
                updateProgram={this.updateProgram}
                data={hearingAidStatus}
                devices={hearingDevices}
              />
            </Panel.Item>}
          <Panel.Item>
            <ProgramSelector
              equalizerSetting={equalizerSetting}
              programs={hearingDevices.hdPrograms}
              activeProgramNumber={program.programNumber}
              onChange={this.onProgramChange}
              isD11={isD11}
            />
          </Panel.Item>
          <Panel.Item>
            <h2>
              <FormattedMessage {...T('profile.tuning.title')} />
            </h2>
            <article data-qa="persistent-tuning" className="persistent-tuning">
              <FourBandEqualizer
                ref={c => (this._bandComponent = c)}
                dirty={dirty}
                setDirty={this.setDirty}
                program={program}
                reset={this.discardChanges}
              />
            </article>
          </Panel.Item>
          <Panel.Item>
            <SaveSection
              customer={customer}
              dirty={dirty}
              isAppSupported={isAppSupported(customer)}
              program={program}
              onSave={this.save}
              getProgramStatus={getProgramStatus}
            />
          </Panel.Item>
        </Panel.Base>
      </section>
    )
  }
  setEqualizerSettings(hdPrograms, equalizerSetting) {
    const hdProgramsCopy = structuredClone(hdPrograms);
    hdPrograms.forEach((_, i) => {
      if (equalizerSetting && equalizerSetting[i].valid) {
        hdProgramsCopy[i].equalizer1 = equalizerSetting[i].band1
        hdProgramsCopy[i].equalizer2 = equalizerSetting[i].band2
        hdProgramsCopy[i].equalizer3 = equalizerSetting[i].band3
        hdProgramsCopy[i].equalizer4 = equalizerSetting[i].band4
      } else {
        hdProgramsCopy[i].equalizer1 = 0
        hdProgramsCopy[i].equalizer2 = 0
        hdProgramsCopy[i].equalizer3 = 0
        hdProgramsCopy[i].equalizer4 = 0
      }
    })
    return hdProgramsCopy
  }
}

const mapProps = ({ data: { overlay } }) => {
  const overlays = { overlay: overlay }
  return overlays
}

const options = (data) => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    patientId: parseInt(data?.params?.id),
    skip: false,
    correlationContext: CorrelationContext['RemoteTuning_GetSettings'] + SupportId
  },
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  }),
  graphql(HEARING_DEVICES_QUERY, {
    props: ({ data }) => {
      return {
        hearingDevices: data.HearingDevices,
        refetch: data.refetch,
        loading: data.loading,
        hearingDeviceLoading: data.loading
      }
    },
    options
  }),
  graphql(EQUALIZER_SETTING_QUERY, {
    props: ({ data }) => {
      return {
        equalizerSetting: data.EqualizerSettings,
        loading: data.loading
      }
    },
    options
  }),
  graphql(APP_INFO_QUERY, {
    props: ({ data }) => {
      return {
        customer: data.Customer,
        loading: data.loading
      }
    },
    options
  }),
  graphql(HEARING_AID_STATUS, {
    props: ({ data, ownProps }) => {
      return {
        hearingAidStatus: data.HearingAidStatus
          ? data.HearingAidStatus.programName ? data.HearingAidStatus : null
          : null,
        loading: ownProps.loading || data.loading
      }
    },
    options: ({ customer, params }) => {
      const shouldSkip = !(
        check('bluetooth', customer, [flags.HEARING_DEVICE_SUPPORTED]) &&
        check('bluetooth', customer, [flags.MOBILE_DEVICE_SUPPORTED])
      )
      return {
        notifyOnNetworkStatusChange: true,
        variables: { patientId: parseInt(params?.id), skip: shouldSkip },
        fetchPolicy: 'network-only'
      }
    }
  }),
  graphql(UPDATE_POPUP, {
    props: ({ mutate }) => ({
      updatePopup: (popup, isOpen) => mutate({ variables: { popup, isOpen } })
    })
  })
)

export default withRouter(withData(ProfileTuning))
