import styled, { css } from "styled-components";
import { StyledFontAwesomeIcon } from "./StyledFontAwesomeIcon";
import React from "react";

const StyledDiv = styled.div((props) => {
  const {
    theme: {
      colors: {
        fontAwesomeIcons: {
          popupIcon: { backgroundColor: iconBackgroundColor },
        },
      },
    },
  } = props;

  return css`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: ${iconBackgroundColor};
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
  `;
});

export const StyledPopupFontAwesomeIcon = (props) => {
  return (
    <StyledDiv {...props}>
      <StyledFontAwesomeIcon {...props} />
    </StyledDiv>
  );
};
