import { connector } from 'helper/Connector'
import { getToken } from 'helper/AuthHelper'

export default {
  resetPassword(data) {
    return connector.postUrl(null, '/api/custom/hcp/reset-password', data)
  },

  requestPasswordReset(userName) {
    return connector.postUrl(null, '/api/custom/hcp/request-password-reset', {
      userName
    })
  }
}

export const closeCustomer = (patientId, status) => {
  const token = getToken()

  return connector.postUrl(token, '/api/custom/hcp/close-customer', {
    status,
    patientId
  })
}
