// @flow
import React from 'react'
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl'
import { T } from 'core'
import { get } from 'lodash'
import messages from 'i18n/messages.json'
import MissedCallComponent from '../MissedCallComponent'
import WarningComponent from '../WarningComponent'
import avatarPlaceholder from 'assets/img/avatar_placeholder.png'
import config from 'config'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { StyledSvg } from "styledComponents/Icon/StyledSvgIcon"
import { StyledProgressBar } from "styledComponents/ProgressBar/StyledProgressBar"
import veryNegativeIcon from "../../../assets/img/icons/smile-very-negative.svg";
import negativeIcon from "../../../assets/img/icons/smile-negative.svg";
import positiveIcon from "../../../assets/img/icons/smile-positive.svg";
import veryPositiveIcon from "../../../assets/img/icons/smile-very-positive.svg";
import neutralIcon from "../../../assets/img/icons/smile-neutral.svg";
import moment from 'moment'
import { Link } from 'react-router-dom'
import fflags from 'helper/FFlags'

export const CustomerProgress = ({
  currentProgress = 0,
  journeyStatus = 'OPEN'
}: {
  currentProgress: number,
  journeyStatus: string
}) => {
  const progress = currentProgress * 100
  return journeyStatus !== 'PREFITTING'
    ? <td>
      <StyledProgressBar currentProgress={progress} />
    </td>
    : <td>
      <FormattedMessage {...T(`PreFitting.patient-in-progess`)} />
    </td>
}

export const JourneyDuration = ({
  journeyDurationDays = 0,
  journeyStatus = 'OPEN'
}: {
  journeyDurationDays: number,
  journeyStatus: string
}) => {
  const progressCls = journeyDurationDays > 27 ? 'text-bold ' : 'text-bold text-primary'

  return journeyStatus !== 'PREFITTING'
    ? <td className="text-center">
      <span className={progressCls}>
        {journeyDurationDays}
        <FormattedMessage {...messages.commonDays} />
      </span>
    </td>
    : <td className="text-center">
      <FormattedMessage {...T(`PreFitting.patient-in-progess`)} />
    </td>
}

export const AppConnected = ({ appConnected, formatMessage }) => {
  return (
    <td className="text-center">
      <div className={`app-connected`}>
        {appConnected
          ? <i title={formatMessage(T('customer_overview.app_connected'))}>
            <StyledFontAwesomeIcon icon={faCheckCircle} fixedWidth type="success" className="app-connected" />
          </i>
          : <i title={formatMessage(T('customer_overview.app_not_connected'))}>
            <StyledFontAwesomeIcon icon={faTimesCircle} fixedWidth type="failure" className="app-connected" /> </i>}
      </div>
    </td>
  )
}

export const Assistant = ({ assistantHistoryStatus, id }) => {
  let indicator = <span>-</span>
  if (assistantHistoryStatus) {
    indicator = <div className="tooltip-cell-content">
      <Link to={`/profile/${id}/overview/assistant-history`}
        onClick={e => e.stopPropagation()}>
        <StyledFontAwesomeIcon icon={faCheckCircle} fixedWidth type="success" style={{ cursor: 'pointer' }} />
        <div className="tooltip-self"><FormattedMessage {...T(`common.click-for-details`)}></FormattedMessage></div>
      </Link>
    </div>
  } else if (assistantHistoryStatus === false) {
    indicator = <StyledFontAwesomeIcon icon={faTimesCircle} fixedWidth type="failure" />
  }
  return (
    <td className="text-center tooltip-cell">
      <div>
        {indicator}
      </div>
    </td>
  )
}

export const SaleStatus = ({ journeyStatus, formatMessage }) => {
  let indicator = <span>-</span>
  if (journeyStatus) {
    if (journeyStatus == 'SOLD') {
      indicator = <i title={formatMessage(T('common.sold'))}>
        <StyledFontAwesomeIcon icon={faCheckCircle} fixedWidth type="success" /></i >
    }
    else if (journeyStatus == 'CANCELED') {
      indicator = <i title={formatMessage(T('common.unsold'))}>
        <StyledFontAwesomeIcon icon={faTimesCircle} fixedWidth type="failure" /></i>
    }
  }

  return (
    <td className="text-center">
      <div className={`sale-status`}>
        {indicator}
      </div>
    </td>
  )
}

export const PatientStatus = ({ journeyStatus }) => {
  return (
    <td className="text-left">
      <div className={`patient-status`}>
        {journeyStatus && (journeyStatus === 'SOLD' || journeyStatus === 'CANCELED')
          ? <FormattedMessage {...T(`common.closed`)} />
          : <FormattedMessage {...T(`common.active`)} />}
      </div>
    </td>
  )
}

export const PaitientJourneyStatusDate = ({ journeyStatus, journeyStartedOn, journeyClosingDate }) => {
  return (
    <td className="text-left">
      {journeyStatus && (journeyStatus === 'SOLD' || journeyStatus === 'CANCELED')
        ? < FormattedDate value={moment(journeyClosingDate)} />
        : <FormattedDate value={moment(journeyStartedOn)} />}
    </td>
  )
}

const CustomerRowOpen = ({ customer, onClick, intl: { formatMessage } }) => {
  const { journeyDurationDays, lastSatisfactionRating, journeyStatus } = customer
  const hasPositiveLastRating =
    typeof lastSatisfactionRating === 'number' && lastSatisfactionRating >= 0
  const profileImg = customer.employeeImageUrl || avatarPlaceholder
  const lowWearingTimeHigherLimit = 4;
  const employeeInitials = `${get(customer, `employeeFirstName`, '')?.charAt(0)}${get(
    customer,
    `employeeLastName`,
    ''
  )?.charAt(0) ?? ''}`
  const warnings = []

  const isConnectDaToTelecareEnabled = fflags.variation(
    'enable-da-in-telecare',
    false
  )

  // used to show Staff column outside of China
  const showStaffColumn = config.variant !== 'china'

  if (customer.averageWearingTime && customer.averageWearingTime < lowWearingTimeHigherLimit) {
    warnings.push(
      <div key={`ws${customer.id}`} className="warning satisfaction">
        <p className="text-bold">
          <FormattedMessage {...T('customerProfile.warning.lowWearingTime')} />
        </p>
        <p className="text-primary">
          {customer.averageWearingTime}
        </p>
      </div>
    )
  }


  if (journeyDurationDays > 27 && (journeyStatus !== 'SOLD' && journeyStatus !== 'CANCELED')) {
    warnings.push(
      <div className="warning" key={`wd${customer.id}`}>
        <p className="text-bold">
          <FormattedMessage {...messages.customerOverviewLongProcess} />
        </p>
        <p className="text-primary">
          {journeyDurationDays} <FormattedMessage {...messages.commonDays} />
        </p>
      </div>
    )
  }

  const renderStyledSatisfactionIcon = () => {
    const rating = lastSatisfactionRating > 4 ? 4 : lastSatisfactionRating;

    switch (rating) {
      case 4:
        return (
          <StyledSvg
            src={veryPositiveIcon}
            type="veryPositiveRating"
            className="ratings-emoji-icon"
          />
        );
      case 3:
        return (
          <StyledSvg
            src={positiveIcon}
            type="positiveRating"
            className="ratings-emoji-icon"
          />
        );
      case 2:
        return (
          <StyledSvg
            src={neutralIcon}
            type="neutralRating"
            className="ratings-emoji-icon"
          />
        );
      case 1:
        return (
          <StyledSvg
            src={negativeIcon}
            type="negativeRating"
            className="ratings-emoji-icon"
          />
        );
      case 0:
        return (
          <StyledSvg
            src={veryNegativeIcon}
            type="veryNegativeRating"
            className="ratings-emoji-icon"
          />
        );
    }
  };

  return (
    <tr onClick={onClick} data-qa="row-open">
      {showStaffColumn &&
        <td className="text-center">
          <div className="fitter">
            <div className="profileImg" style={{ backgroundImage: `url('${profileImg}')` }} />
            <span className="initials">
              {employeeInitials}
            </span>
          </div>
        </td>}
      <td>
        <WarningComponent warnings={warnings} customer={customer} /> {customer.firstName}
      </td>
      <td>
        {customer.lastName}
      </td>
      <CustomerProgress {...customer} />
      <td className="text-center">
        <div className={`satisfaction-alignment`}>
          {hasPositiveLastRating ? renderStyledSatisfactionIcon() : "-"}
        </div>
      </td>
      {isConnectDaToTelecareEnabled &&
        <Assistant {...customer} />
      }
      <td className="text-center">
        <MissedCallComponent
          count={customer.numberOfUnreadChatInteractions}
          customerId={customer.id}
        />
      </td>
      <PatientStatus {...customer} />
      <PaitientJourneyStatusDate {...customer} />
      <SaleStatus {...customer} formatMessage={formatMessage} />
      <AppConnected {...customer} formatMessage={formatMessage} />
    </tr>
  );
}

export default injectIntl(CustomerRowOpen)
