import { tracker } from 'core'
import _ from 'lodash'
import { sendDataToDataCollection } from "../helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from "helper/constants"
import { EventType } from '../helper/DataCollection/DataCollectionConstants'
import { NavigationTrackingData } from './DataCollection/PayloadData'

let lastPage = window.location.hash.substring(1) || undefined;

export const routeChangeTracking = async (location, action, navigatingFrom = null) => {
  const { pathname = '', search = '', ...rest } = location   

  sanitizeLastPage(navigatingFrom)
  const currentPage = `${pathname}${search}` 
  if (pathname !== lastPage && !IsRootRouteRedirect(pathname,lastPage)) { 
    await sendRouteChangedDataCollection(lastPage,pathname)  
    tracker.track('pageViewChanged', {
      screen: { name: currentPage },
      pathname,
      search,
      ...rest
    })
    lastPage = pathname
  }
  return true;  
  
}
const sanitizeLastPage = (navigatingFrom) => {
  // if navigating from passed set it to last page
  if (navigatingFrom !== null) {
    lastPage = navigatingFrom
  }
 // if lastpage set after refresh remove hash from location
  if (lastPage !== undefined && lastPage.includes("#")) {
    lastPage = lastPage.substring(1)
  }
 // if last page conatins query paras remove them
  if (lastPage !== undefined && lastPage.includes("?")) {
    lastPage = lastPage.split("?")[0] || undefined
  }
}
const IsRootRouteRedirect = (path, lastvisited) => {
  if(lastvisited !==undefined && (path === "/" || path === "/overview")) return true
  return false;
}

const sendRouteChangedDataCollection = async (lastPage, path) => {
  const toPagePathWithoutId = removeIdfromPathIfPresent(path) 
  const fromPagePathWithoutId = removeIdfromPathIfPresent(lastPage) 
  const toPagePathObject = filterPath(RoutesWithoutId, ([name, route]) => route === toPagePathWithoutId );
  const fromPagePathObject = filterPath(RoutesWithoutId, ([name, route]) => route === fromPagePathWithoutId );
  
  if(_.isEmpty(toPagePathObject) || _.isEmpty(fromPagePathObject)){
    return false;
  }
  const toPagePathMap = mapPathValues(toPagePathObject, ([name, route]) => { return { pathname: name, pathroute: route} });
  const fromPagePathMap = mapPathValues(fromPagePathObject, ([name, route]) => { return { pathname: name, pathroute: route} });

  const payload = {
    FromPage: fromPagePathMap.pathname,
    ToPage: toPagePathMap.pathname

  }
  await sendDataToDataCollection(DataCollectionType.Navigation, NavigationTrackingData(payload), EventType.Navigation)
  return true;
}

const filterPath = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));
const mapPathValues = (obj,predicate) => Object.entries(obj).map(predicate)[0];

const removeIdfromPathIfPresent = (pathname) => {
 if(checkPathContainsId(pathname,/(\/\d+)/)){
  const removedPath= pathname.replace(/(\/\d+)/,'')
  return removedPath
 }
 return pathname
}

const checkPathContainsId = (pathname, regex) => {
  return _.isEmpty(pathname)|| pathname.match(regex) == null? false : true
}

const RoutesWithoutId = {  
  Help : "/help",
  HelpTutorial : "/help/tutorials",
  HelpSupportedDevices:"/help/supportedDevices",
  HelpFAQs : "/help/faq",
  HelpCommonFAQs : "/help/faq/commonfaq",
  HelpOrderedFAQs : "/help/faq/orderedfaq",
  PatientListOverview:"/overview/patients",
  MarketingMessagesOverview: "/overview/marketing/messages",
  ManagementOverview : "/overview/orgManagement",
  AnalyticsDashboard: "/overview/dashboard",
  PracticeAnalytics: "/overview/dashboard/practices",
  KPIsAnalytics: "/overview/dashboard/kpis",
  UserManagement : "/overview/orgManagement/users",
  PracticeManagement: "/overview/orgManagement/practices",
  LessonManagement: "/overview/orgManagement/lessons",
  OrgManagement: "/overview/orgManagement/organization/",
  OrgManagementEdit: "/overview/orgManagement/organization/edit",
  OrgManagementView: "/overview/orgManagement/organization/view",
  OrgManagementHearingLessons: "/overview/orgManagement/lessons",
  PatientProfileOverview: "/profile",
  PatienProfileOverviewWearingTime: "/profile/overview",
  PatientProfileOverviewSatisfaction: "/profile/overview/satisfaction",
  PatienProfileOverviewAssistantHistory: "/profile/overview/assistant-history",
  PatientProfileLessonRating : "/profile/ratings",
  PatientProfileMessages : "/profile/messages",
  PatientProfileRemoteTuning: "/profile/tuning",
  PatientProfileEdit: "/profile/settings",
  PatientProfileHearingAidsSettings: "/profile/settings/hearing",
  PatientProfileManageHearingLessons: "/profile/settings/lessons",
  PatientProfileConnectToTelecareApp: "/profile/settings/setup",
  PatientCreate: "/customer/create",
  PracticeCreate: "/practice/create",
  PracticeEdit: "/practice/edit",
  PracticeDelete: "/deletepractice/usersinpractice/delete",  
  PatientDeleteOnPracticeDelete: "/deletepractice/patientsinpractice/delete",
  PracticeView : "/practice/view",
  HearingLessonTemplateCreate : "/lessontemplate/create",
  HearingLessonTemplateEdit: "/lessontemplate/edit",
  EmployeeSendInvite :"/employee/invite",
  EmployeeSelfCompleteRegistration: "/employee/self-complete",
  EmployeeSelfProfile:"/employee",
  EmployeeCreate:"/employee/create",
  EmployeeEdit : "/employee/edit",
  EmployeeView : "/employee/view",
  OrgRegistrationSucess: "/register/success",
  OrgRegistrationStart: "/register",
  RequestPasswordReset: "/password/request-reset",
  ResetPassword : "/password/reset",
  PasswordExpired : "/password/expire"  
}
