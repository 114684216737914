import { InitializeSignalrConnection } from '../../signalr'
import { signalRHubUrl } from 'helper/constants'

export const invokeSignalRConnection = patient => {
  stopSignalrConnection(patient)
  patient.connection = InitializeSignalrConnection(signalRHubUrl.ChatHub, patient.groupId)
  patient.connection.on('Message', () => {
    patient.update()
  })
}

export const stopSignalrConnection = patient => {
  if (patient && patient.connection && patient.connection.connectionState == 'Connected') {
    patient.connection.invoke('LeaveGroup', patient?.groupId?.toString()).then(() => patient?.connection?.stop())
  }
}

export const stopSignalrPolling = patient => {
  if (patient) {
    stopSignalrConnection(patient)
    const { stopPolling } = patient.props.data
    stopPolling()
  }
}

