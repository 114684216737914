import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { withRouter } from 'helper/withRouter'
import _ from 'lodash'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

import { withQuery } from 'helper/withQuery'
import Loading from 'components/Loading'
import { getLessonSetModules } from 'helper/LessonsUtils'
import { Props, State } from 'typescript/interfaces/ISigniaManageLessons'
import { QUERY_LESSONSET_FOR_SIGNIA_PATIENT } from 'graphql/queries/SigniaManageLessonsQuery'
import { MUTATION_UPDATE_LESSONS_SIGNIA_PATIENT } from 'graphql/mutations/SigniaManageLessonsMutation'
import { mapProps, options } from 'graphql/mapProps/SigniaManageLessonsMapProps'
import { AccordionWithCheckbox } from 'components/AccordionWithCheckbox'
import { Button } from 'styledComponents/Button'
import {
  ActionButtonsRestartSection,
  ActionButtonsManageLessons
} from 'styledComponents/Div/DivComponent/DivProfile'
import Dropdown from 'components/Dropdown'
import { SHOW_POPUP_QUERY, UPDATE_OVERLAY } from 'graphql/queries/ShowPopUpQuery'
import { CONFIRMATION_POPUP, SHOW_POPUP, getOverlayUpdateData } from 'components/Popups/helper/OverlayUpdateData'
import { popupMapProps } from 'graphql/mapProps/PopupMapProps'
import ConfirmationPopup from 'components/ConfirmationPopup'
import NavigateAwayPopup from 'components/Popups/NavigateAwayPopup'
import { Banner } from 'styledComponents/Div/DivComponent/DivComponent'
import { isAppSupported } from '../../shared'

import { sendDataToDataCollection } from "helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from 'helper/constants'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData } from 'helper/DataCollection/PayloadData'
import { injectIntl } from 'react-intl';
import ReactRouterPrompt from 'react-router-prompt';
import Modal from 'react-bootstrap/Modal';

export class SigniaManageLessons extends Component<Props, State> {

  state: State = {
    lessonModules: [],
    lessonIdsOfPatient: [],
    selectedlessonIdsOfPatient: [],
    DefaultLessonSetForSignia: [],
    disableRestart: false,
    disableLessons: true,
    LessonSetForSignia: [],
    isMutating: false,
    showSuccessPopup: false,
    showNavigateAwayPopup: false,
    nextLocation: '',
    prompt: true
  }

  static getDerivedStateFromProps(props: Readonly<Props>, state: State) {
    const { lessonModules } = state
    const { DefaultLessonSetForSignia, LessonsForSigniaPatient, LessonSetForSignia } = props
    if (!_.isNil(DefaultLessonSetForSignia)
    ) {
      const modules = getLessonSetModules(DefaultLessonSetForSignia)
      let selectedlessonIdsOfPatient = [], lessonIdsOfPatient = []
      if (!_.isNil(LessonsForSigniaPatient)) {
        selectedlessonIdsOfPatient = _.map(LessonsForSigniaPatient, 'lessonId')
        lessonIdsOfPatient = _.map(LessonsForSigniaPatient, 'lessonId')
      }
      return {
        lessonModules: modules,
        DefaultLessonSetForSignia,
        selectedlessonIdsOfPatient,
        LessonSetForSignia,
        lessonIdsOfPatient
      }
    }
    return null
  }

  prepareLessonIds = () => {
    const { selectedlessonIdsOfPatient, lessonModules } = this.state
    const lessonIds = {}
    lessonModules.forEach(module => {
      lessonIds[module.categoryName] = true
      module.lessons.forEach(lesson => {
        if (!_.isEmpty(selectedlessonIdsOfPatient) && selectedlessonIdsOfPatient.includes(lesson.id))
          lessonIds[lesson.id] = true
        else {
          lessonIds[lesson.id] = false
          lessonIds[module.categoryName] = false
        }
      })
    })
    return lessonIds
  }

  restartModule = () => {
    this.setState({ disableRestart: true, disableLessons: false })
  }

  resetForm = formikProps => {
    formikProps.resetForm()
    this.setState({
      selectedlessonIdsOfPatient: this.state.lessonIdsOfPatient, disableRestart: false,
      disableLessons: true
    })
  }

  confirmationPopupClick = () => {
    const { nextLocation } = this.state
    this.setState({ showSuccessPopup: false })
    if (nextLocation) this.goToNextLocation()
  }

  goToNextLocation = () => {
    const { nextLocation } = this.state
    if (nextLocation) this.props.history.push(nextLocation)
  }

  navigateAwayPopupStay = (resetNextLocation = true) => {
    this.setState({
      showNavigateAwayPopup: false,
      nextLocation: resetNextLocation ? null : this.state.nextLocation
    }, () => this.props.updateOverlay(null, null, false))
  }

  navigateAwayPopupDontSave = () => {
    this.setState(
      {
        showNavigateAwayPopup: false,
        prompt: false
      },
      () => {
        this.props.updateOverlay(null, null, false)
        this.goToNextLocation()
      })
  }

  navigateAwayPopupSave = values => {
    this.navigateAwayPopupStay(false)
    this.handleSubmit(values)
  }

  showNavigateAwayConfirmation = ({ pathname }) => {
    this.setState({ showNavigateAwayPopup: true, nextLocation: pathname + this.props.location.search }, () => {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: CONFIRMATION_POPUP,
        payload: {
          title: 'journeyConfigurator.restart.navigateAwayTitle',
          message: 'journeyConfigurator.restart.navigateAway'
        }
      })
      this.props.updateOverlay(popup, null, true)
    })
    return false
  }

  showPopup = values => {
    const { overlay } = this.props
    const { showSuccessPopup, showNavigateAwayPopup } = this.state

    return overlay &&
      overlay.popup &&
      ((showSuccessPopup &&
        <ConfirmationPopup
          onClick={this.confirmationPopupClick}
          showSuccessPopup={overlay.isOpen}
          {...overlay.popup.payload}
        />) ||
        (showNavigateAwayPopup &&
          <NavigateAwayPopup
            isOpened={overlay.isOpen}
            saved={() => this.navigateAwayPopupSave(values)}
            canceled={this.navigateAwayPopupDontSave}
            stayed={this.navigateAwayPopupStay}
          />))
  }

  handleSubmit = async values => {
    const { customer: { uuid }, refetch } = this.props
    const result = Object.keys(values).map(key => {
      if (values[key])
        return Number(key)
    })
    const lessonIds = _.compact(result)
    const popup = getOverlayUpdateData(SHOW_POPUP, {
      type: CONFIRMATION_POPUP,
      payload: {
        title: 'common.success',
        message: 'journeyConfigurator.restart.successMessage'
      }
    })
    sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(this.props.customer), EventType.HearingLessonsChange)
    this.setState({ isMutating: true })
    await this.props.updateLessonsForSigniaPatient({ patientGuid: uuid, lessonIds }).then(() => {
      refetch()
      this.props.updateOverlay(popup, null, true)
      this.setState({ disableRestart: false, disableLessons: true, isMutating: false, showSuccessPopup: true })
    }).catch(err => {
      this.setState({ isMutating: false })
    })
  }

  onLessonTypeChange = lessonSetId => {
    const { LessonSetForSignia } = this.state
    let selectedlessonIdsOfPatient = []
    LessonSetForSignia.forEach(lessonSet => {
      if (lessonSet.id == lessonSetId) {
        selectedlessonIdsOfPatient = [...lessonSet.lessonIds]
      }
    })
    this.setState({ selectedlessonIdsOfPatient })
  }

  showLessonInfo = message => {
    return <Banner className="lesson-info text-style">
      {message}
    </Banner>
  }

  render() {
    const { loading, customer, intl: { formatMessage } } = this.props
    const { lessonModules, disableRestart, disableLessons, LessonSetForSignia, isMutating, prompt } = this.state
    const isAppNotSupported = !isAppSupported(customer)
    return (
      <section className="container">
        <Loading isLoading={loading || isMutating} />
        <div className="patient-lesson-info">{this.showLessonInfo(formatMessage(T('journeyConfigurator.editPatient.info')))}</div>
        {disableRestart && !isAppNotSupported &&
          <div className="row lessonsettype">
            <div className="col-md-6">
              <h2 data-qa="duration-title">
                <FormattedMessage {...T('customer_create.lessonSet')} />
              </h2>
              <p data-qa="duration-help-text">
                <i />
              </p>
              <Dropdown
                onSelect={this.onLessonTypeChange}
                options={
                  LessonSetForSignia &&
                  LessonSetForSignia.map(opt => ({
                    value: opt.id,
                    text:
                      opt.lessonSetName === 'Standard'
                        ? <FormattedMessage {...T(`${'customer_create.STANDARD'}`)} />
                        : opt.lessonSetName
                  }))
                }
                defaultDropdownTitle={<FormattedMessage {...T('common.select')} />}
                borderRadius="4px"
              />
            </div>
          </div>}
        {!loading && (
          <Formik
            onSubmit={(values, actions) => this.handleSubmit(values)}
            initialValues={this.prepareLessonIds()}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <h2>{formatMessage(T('journeyConfigurator.manageLessonSets.elementsHeader'))}</h2>
                      {this.showLessonInfo(formatMessage(T('journeyConfigurator.manageLessonSets.elementsInfo')))}
                    </div>
                    {lessonModules && lessonModules.map((module, index) => {
                      return <div className="col-md-6" key={module.categoryName}>
                        <AccordionWithCheckbox lessonModule={module} formikProps={formikProps} isDisabled={disableLessons || isAppNotSupported} isExpanded={true} />
                      </div>
                    })
                    }
                  </div>
                  <ActionButtonsRestartSection>
                    <label>
                      <FormattedMessage {...T('journeyConfigurator.restart.description')} />
                    </label>
                    <Button
                      id="restartButton"
                      type="button"
                      primary
                      onClick={this.restartModule}
                      disabled={disableRestart || isAppNotSupported}
                    >
                      <FormattedMessage {...T('journeyConfigurator.restart.label')} />
                    </Button>
                  </ActionButtonsRestartSection>
                  <ActionButtonsManageLessons>
                    <Button
                      type="button"
                      onClick={() => this.resetForm(formikProps)}
                      disabled={!disableRestart || isAppNotSupported}
                      className="btn--min-width"
                      secondary
                    >
                      <FormattedMessage {...T('common.cancel')} />
                    </Button>
                    <Button type="submit" className="btn--min-width" primary disabled={!disableRestart || isAppNotSupported} id="submitButton">
                      <FormattedMessage {...T('common.save')} />
                    </Button>
                  </ActionButtonsManageLessons>

                  {this.showPopup(formikProps.values)}
                </Form>
              )
            }}
          </Formik>
        )}
      </section>
    )
  }
}

const withData = compose(
  graphql(QUERY_LESSONSET_FOR_SIGNIA_PATIENT, {
    props: mapProps,
    options
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: popupMapProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }: any): any => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  graphql(MUTATION_UPDATE_LESSONS_SIGNIA_PATIENT, {
    props: ({ mutate }) => ({
      updateLessonsForSigniaPatient: (variables: any) =>
        mutate({
          variables
        })
    })
  })
)

export default withRouter(withQuery(withData(injectIntl(SigniaManageLessons))))
