import styled from 'styled-components'
import { ActionButtons } from '../'

export const ActionButtonsEdit = styled(ActionButtons)`
  > button {
    width: 100%;
    min-width: 80px;
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
`

export const StyledLessonSetting = styled.div`
  .formGroup {
    padding-left: 20px;
    &:first-of-type {
      padding-left: 0;
    }
    .dropdown {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`

export const OrgManagementPage = styled.div`
  table {
    margin-top: 30px;

    td,
    th {
      padding: 5px !important;
    }
  }
`
