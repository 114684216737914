export const CALL_STATES = {
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  JOINED: 'JOINED',
  REMOTE_JOINED: 'REMOTE_JOINED',
  CLOSED: 'CLOSED',
  CLOSING: 'CLOSING',
  RECONNECTING: 'RECONNECTING',
  STREAM_FAILURE: 'STREAM_FAILURE',
  CONNECTIVITY_ISSUES: 'CONNECTIVITY_ISSUES',
  TIMED_OUT: 'TIMED_OUT',
  ERROR: 'ERROR'
}

export const CallConstants = {
  "CALL_STATE": "callState",
  "CALL_LEAVE": "leave",
  "CALL_JOIN": "join",
  "CALL_DECLINED": "declined",
  "CALL_AUDIO_MUTED": "audioMuted",
  "CALL_VIDEO_MUTED": "videoMuted",
  "CALL_APPLY_SETTINGS": "applySettings",
  "CALL_ENDED": "ended",
  "CALL_ABORTED": "aborted",
  "CALL_TIMEOUT": "timeout"
}