import { graphql } from '@apollo/client/react/hoc';
import React from 'react'
import { StyledPagination } from 'styledComponents/Pagination/StyledPagination'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { get, pickBy } from 'lodash'

import { QUERY_ALL_PATIENTS } from 'graphql/queries/PatientsQuery'
import { APP_QUERY } from '../../../graphql/queries/AppQuery'
import Loading from 'components/Loading'
import CustomersOpenTable from './CustomersOpenTable'
import { paginationSize, DataCollectionType } from 'helper/constants'
import queryString from 'query-string'
import { invokeSignalRConnection, stopSignalrPolling } from '../SignalRConnection'
import { getOrganizationIdFromCache } from 'helper/PracticeUtils'
import { withQuery } from '../../../helper/withQuery';
import { withRouter } from 'helper/withRouter'

const OVERVIEW_REFRESH_TIME = 5 * 60 * 1000 // 5 min
const INITIAL_QUERY = {
  sort: 'startingDate,desc',
  page: 0,
  patientType: ''
}

export class PatientsPage extends React.Component {
  static displayName = 'PatientsPage'
  connection = null
  groupId = getOrganizationIdFromCache()

  UNSAFE_componentWillMount() {
    invokeSignalRConnection(this)
  }

  componentDidMount() {
    const { startPolling, PatientsList = [] } = this.props.data
    if (PatientsList.length > 0) startPolling(OVERVIEW_REFRESH_TIME)
  }

  update = () => {
    this.props.data.refetchPatientsList()
  }
  componentWillUnmount() {
    stopSignalrPolling(this)
  }

  shouldShowVideoList = () => {
    const { me, data: { PatientsList = [] }, query } = this.props
    let searchQueryChanged = false
    const defaultQuery = {
      page: '0',
      practiceId: `${me.employee.defaultPracticeId}`,
      sort: 'startingDate,desc'
    }
    for (const [key, value] of Object.entries(query)) {
      if (
        !Object.prototype.hasOwnProperty.call(defaultQuery, key) ||
        (Object.prototype.hasOwnProperty.call(defaultQuery, key) &&
          defaultQuery[key] &&
          defaultQuery[key] !== value)
      ) {
        searchQueryChanged = true
      }
    }
    return PatientsList.length == 0 && !searchQueryChanged ? true : false
  }
  render() {
    const { data, query, AppQueryloading } = this.props
    const { loading, PatientsList = [], pagination = {} } = data
    if (loading || AppQueryloading) {
      return <Loading isLoading={loading || AppQueryloading} />
    }

    const { sort, s, ...restFilters } = query || {}
    const searchQuery = s || ''
    const isFiltered = searchQuery || Object.keys(restFilters).length > 0
    const currentPage = get(pagination, 'pageNumber', 0) + 1
    const numberOfElements = get(pagination, 'numberOfElements', 0)
    const isPatientListEmpty = this.shouldShowVideoList(query)
    return (
      <div>
        {/* Commenting videos as they should not be visible until we get a brand neutral version of the videos. Will be readded later.
        {isPatientListEmpty &&
          <div className="row  justify-content-center">
            <div className="col-lg-12">
              <OnBoardingPage videosLink={getVideoListForNoPatient(this.props)} />
            </div>
          </div>} */}
        {!isPatientListEmpty &&
          <CustomersOpenTable
            customers={PatientsList}
            isFiltered={isFiltered}
            setSort={this.setSort}
            showCustomerDetailsPage={this.showCustomerDetailsPage}
            sort={sort}
          />}
        {numberOfElements > 0 &&
          <div className="d-flex justify-content-center">
            <StyledPagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage={paginationSize}
              totalItemsCount={get(pagination, 'totalElements', 0)}
              onChange={this.setPage}
            />
          </div>}
      </div>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, query, data: { loading } } = nextProps
    const currentSort = get(query, 'sort', INITIAL_QUERY.sort)
    const nextSort = get(query, 'sort', '')

    if (currentSort !== nextSort && !loading) {
      const search = pickBy({ ...INITIAL_QUERY, ...query })
      location.search = queryString.stringify(search)
      this.props.navigate(location)
    }

  }

  showCustomerDetailsPage = customer => {
    const path = `/profile/${customer.id}`
    this.props.navigate(path)
  }

  setSort = sortField => {
    const { query, location } = this.props
    const prevOrder = get(query, 'query.sort', '').split(',')[1] || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'
    const sort = [sortField, order].join(',')
    const currentQuery = { ...query, sort }
    location.search = queryString.stringify(currentQuery)
    this.props.navigate(location)
  }

  setPage = pageNumber => {
    const { query } = this.props
    const page = pageNumber - 1
    const searchQuery = { ...query, page }
    location.search = queryString.stringify(searchQuery)
    this.props.navigate(location)
  }
}

const mapQueryToVariables = ({
  sort,
  practiceId = null,
  employeeId = null,
  page = 0,
  search = null,
  patientType = null,
}) => ({
  practiceId: practiceId ? parseInt(practiceId) : null,
  employeeId: employeeId ? parseInt(employeeId) : null,
  sort: sort || INITIAL_QUERY.sort,
  search,
  page: parseInt(page, 10) || INITIAL_QUERY.page,
  patientType: patientType || INITIAL_QUERY.patientType
})

const options = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables(query),
  fetchPolicy: 'network-only'
})

const mapPropsPatients = ({
  PatientsListQuery: {
    loading,
    startPolling,
    stopPolling,
    refetch,
    PatientsList: { content, pagination } = []
  },
  PatientsListQuery,
  ownProps: { location }
}) =>
//data
{
  return {
    data: {
      PatientsListQuery,
      startPolling,
      stopPolling,
      loading,
      PatientsList: content,
      location,
      pagination,
      refetchPatientsList: refetch
    }
  }
}

const mapProps = ({
  AppQuery: { loading, refetch, Me },
  AppQuery,
  ownProps: { location, intl }
}) => {
  return {
    me: Me,
    AppQuery,
    refetch,
    loading,
    AppQueryloading: loading,
    location,
    intl
  }
}
const withData = compose(
  graphql(QUERY_ALL_PATIENTS, {
    name: 'PatientsListQuery',
    props: mapPropsPatients,
    options
  }),
  graphql(APP_QUERY, {
    name: 'AppQuery',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first'
    }),
    props: mapProps
  }),
  injectIntl
)

export default withRouter(withQuery(withData(PatientsPage)))
