import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { FormattedMessage, injectIntl } from 'react-intl'
import { T } from 'core'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { Button } from 'styledComponents/Button'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon" 

export class PopupSuccessComponent extends Component {  
  render() {
    return (
      <div data-qa="popup--success" className="popupNoConnection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <StyledPopupFontAwesomeIcon icon={faCheck} type="popupIcon" fixedWidth size="2x" className="popup-icon" />
              <h1 data-qa="popup__title">
                <FormattedMessage {...T(this.props.title)} />
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p data-qa="popup__message">
                <FormattedMessage {...T(this.props.message)} />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
                  <Button
                    secondary
                    data-qa="popup__button"
                    onClick={this.props.shouldHide.bind(this)}
                    type="submit"
                  >
                    <FormattedMessage {...T('common.ok')} />
                  </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PopupSuccessComponent.propTypes = {
  intl: PropTypes.object,
  shouldHide: PropTypes.func
}
const mapProps = ({ data: { refetch, loading, overlay } }) => {
  const visible = overlay.popup !== null
  return { refetch, loading, visible, ...overlay.popup.payload }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  }),
  injectIntl
)
export default withData(PopupSuccessComponent)
