import styled, { css } from "styled-components";
import { StyledLink } from "./StyledLink";

export const StyledButtonLink = styled(StyledLink)((props) => {
  const buttonStyleTheme = `
      text-decoration: none;
      border-radius: 20px;
      border: 1px solid transparent;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 10px;
      box-shadow: none;
      background-color: transparent;
      border-color: #474747;
      color: #474747;

      &:hover {
        background-color: rgba(71, 71, 71, 0.2)};
      }

      &.program-selector {
        margin: 5px 0;
        font-size: 14px;
      }
  `;

  const buttonPrimaryStyleTheme = (
    backgroundColorParam,
    buttonPrimaryHoverColorParam,
    buttonPrimaryFontColorParam
  ) => `
      background-color: ${backgroundColorParam};
      border-color: ${backgroundColorParam};
      height: initial;
      white-space: initial;

      && {
        color: ${buttonPrimaryFontColorParam};
      }

      &:hover {
        background-color: ${buttonPrimaryHoverColorParam}; 
        border-color: ${buttonPrimaryHoverColorParam};
      }

      &.active {
        background-color: ${backgroundColorParam};
        border-color: ${backgroundColorParam};
        &:hover {
          background-color: ${buttonPrimaryHoverColorParam};
          border-color: ${buttonPrimaryHoverColorParam};
        }
      }
  `;

  const buttonTertiaryStyleTheme = (
    backgroundColorParam,
    hoverColorParam,
    inactiveHoverBgColorParam,
    fontColorParam
  ) => `
      &:hover {
        background-color: ${inactiveHoverBgColorParam};
        color: #474747;
      }

      &.active {
        background-color: ${backgroundColorParam};
        border-color: ${backgroundColorParam};
        color: ${fontColorParam};
        &:hover {
          background-color: ${hoverColorParam};
          border-color: ${backgroundColorParam};
          color: ${fontColorParam};
        }
      }
  `;

  const {
    theme: {
      colors: {
        button: {
          primary: {
            active: buttonPrimaryBackgroundColor,
            hover: buttonPrimaryHoverColor,
            color: buttonPrimaryFontColor,
          },
          tertiary: {
            active: buttonTertiaryBackgroundColor,
            hover: buttonTertiaryHoverColor,
            hoverDisabled: buttonTertiaryInactiveHoverBgColor,
            color: buttonTertiaryFontColor
          },
        },
      },
    },
    $buttonprimarystyle,
    $buttontertiarystyle
  } = props;

  return css`
    ${buttonStyleTheme}

    ${$buttonprimarystyle &&
    buttonPrimaryStyleTheme(
      buttonPrimaryBackgroundColor,
      buttonPrimaryHoverColor,
      buttonPrimaryFontColor
    )}

    ${$buttontertiarystyle &&
      buttonTertiaryStyleTheme(
        buttonTertiaryBackgroundColor,
        buttonTertiaryHoverColor,
        buttonTertiaryInactiveHoverBgColor,
        buttonTertiaryFontColor
      )}
  `;
});
