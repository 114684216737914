import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment-timezone'
import { urlRegex } from 'helper/constants'
import { StyledChatMessage } from 'styledComponents/Chat/StyledChatMessage'
import { withTheme } from "styled-components";
import SimonFace from '../../../assets/img/simon.png'

const urlRegularExpression = new RegExp(urlRegex)

const parseLinkInMessage = message => {
  var linkParsedMessage = []
  var msgList = message.split(urlRegularExpression)
  msgList.forEach((msg, index) => {
    var hrefProtocol = msg.indexOf('www') === 0 ? 'https://' : ''
    msg.match(urlRegularExpression) !== null
      ? linkParsedMessage.push(
          <a
            className="chat__message-link"
            key={index}
            style={{ display: 'table-cell' }}
            href={hrefProtocol + msg}
            target="_blank"
          >
            {msg}
          </a>
        )
      : linkParsedMessage.push(msg)
  })
  return linkParsedMessage
}

export const Message = ({ interaction, timeZone, theme }) => {

  const isDAMessage =
    interaction.isFromCustomer &&
    interaction.messageType === "DigitalAssistant" &&
    theme.name === "signia";

  const cssClassName = `${
    interaction.isFromCustomer
      ? isDAMessage
        ? "da-message-in"
        : "message-in"
      : "message-out"
  }`;

  return (
    <div className="chat__message">
      {isDAMessage && (
        <img src={SimonFace} className="chat__image-da-message-in"/>
      )}
      <StyledChatMessage
        className={cssClassName}
      >
        {interaction.message && parseLinkInMessage(interaction.message)}
        <div className="chat__message-time">
          {moment(interaction.createDate).tz(timeZone).format("hh:mm A")}
        </div>
      </StyledChatMessage>
    </div>
  );
};

Message.propTypes = {
  interaction: PropTypes.shape({}).isRequired
}

export default withTheme(Message)
