import { gql } from "@apollo/client";

export const QUERY_USER_LIST = gql`
    query EmployeesQuery($practiceId: Int, $sort: String, $page: Int, $search: String, $accountState: String, $size: Int) {
      EmployeeList(
        practiceId: $practiceId
        search: $search
        sort: $sort
        page: $page
        accountState:$accountState
        size: $size
    ) {
        content {
            id
            firstName
            lastName
            email
            username
            phone
            manager
            orgManager
            tokenValidUntil
        }
        pagination {
            pageNumber
            pageSize
            numberOfElements
            totalElements
            totalPages
            }
      }
    }
  `

export const QUERY_EMPLOYEE_INFO = gql`
  query EmployeeInfoQuery($employeeId: Int!, $hasSelectedEmployee: Boolean!) {
    Employee(id: $employeeId) @include(if: $hasSelectedEmployee) {
        id
        assignedPracticeIds
        avatar {
            url
        }
        firstName
        lastName
      }
    }
  `
  
export const MUTATION_MOVE_PATIENTS = gql`
  mutation movePatients($movePatientsRequest: MovePatientsInput!) {
    movePatients(movePatientsRequest: $movePatientsRequest){
      success
      message 
      code
      status
    }
  } 
  `