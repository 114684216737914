import styled, { css } from 'styled-components'

export const CheckboxWrapper = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid ${props => props.theme.colors.black};
  box-shadow: 0 0 1px
    ${props => (props.disabled ? props.theme.colors.brandDanger : props.theme.colors.black)};
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 10px;
  background-color: ${props =>
    props.defaultChecked && !props.disabled
      ? props.theme.colors.brandDanger
      : props.theme.colors.white};

  input {
    margin-left: 5px;
    margin-right: 20px;
    opacity: 0;
  }
`

export const StyledCheckboxWrapper = styled(CheckboxWrapper)`
  border-radius: 2px;
  width: 20px;
  height: 20px;
  margin: 8px 10px 10px 0;
  background-color: ${props => props.theme.colors.white};
`

export const Divider = styled.div`
  border-top: 1px solid #ededed;
  margin: 20px 0;
  width: ${props => (props.width ? props.width : '100%')};
`

export const DropdownContainer = styled.div((props) => {

  const {
    theme: {
      colors: {
        dropdowns: {
          singleSelectDropdown: {
            caret: {
              active: caretBackgroundActive,
              disabled: caretBackgroundDisabled,
              arrow: caretArrowColor,
            },
            active: dropdownBackgroundActive,
            disabled: dropdownBackgroundDisabled,
            hoverActive: dropdownBackgroundActiveHover,
            hoverDisabled: dropdownBackgroundDisabledHover,
            borderColor: dropdownBorderColor,
            itemTextColor: dropDownItemTextColor,
          },
        },
      },
    },
  } = props;


  return css`
    .dropdown-menu {
      height: ${props => (props.height && props.height > 0 ? `${props.height}px` : 'auto')};
      overflow-y: scroll;
      transform: none !important;
      top: 100% !important;
    }

    .dropdown-toggle {
      height: 34px;
      font-size: 14px;
      width: 100%;
      background-color: ${(props) => props.disabled ? dropdownBackgroundDisabled : dropdownBackgroundActive};
      margin-bottom: 12px;
      padding: 0 12px;
      border: 1px solid ${dropdownBorderColor};
      border-radius: ${(props) => props.borderRadius};

      :hover {
        background-color: ${(props) => props.disabled ? dropdownBackgroundDisabledHover : dropdownBackgroundActiveHover};
      }

      &:first-child {
        text-indent: -30px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

      .caret {
        text-indent: 0;
        border: 0;
        color: ${caretArrowColor};
        background: ${(props) => props.disabled ? caretBackgroundDisabled : caretBackgroundActive};
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        padding: 6px;
        height: 100%;
        width: 30px;
        border-top-right-radius: ${(props) => props.borderRadius};
        border-bottom-right-radius: ${(props) => props.borderRadius};
        top: 0;
        right: 0;
        position: absolute;
        ::after {
          content: "\f0d7";
        }
      }
    }

    .dropdown-item {
      font-size: 14px;
      color: ${dropDownItemTextColor};
      width: 100%;
      border: none;
      background: transparent;
      outline: none;

      text-decoration: none;
      min-height: 30px;
      line-height: 2 !important;
      /* height: 100%; */

      :hover,
      :focus {
        text-decoration: none;
        color: #262626;
        background-color: #f5f5f5;
      }
    }
  `;
});

export const StyledForm = styled.div`
  display: flex;
  flex-direction: ${props => (props.alignment === 'vertical' ? 'column' : 'row')};
  justify-content: space-between;
`

export const StyledFormGroup = styled.div`
  width: ${props => (props.width ? props.width : '100%')};

  .formGroup--label {
    color: ${props => (props.isValid ? 'inherit' : '#d60925')};
    &:after {
      line-height: 16px;
      font-size: 12px;
      vertical-align: top;
      content: "${props => (props.required ? ' *' : '')}";
    }
  }

  .help-block {
    color: ${props => (props.isValid ? 'inherit' : '#d60925')};
    background-color: #fff;
  }
`

//TODO [RM] Theme should define how children are position relative to each other
export const FormGroupWrapper = styled.div`
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
`

export const KnobWithTitle = styled.div`
  width: ${props => (props.width ? props.width : '300px')};
  display: flex;
  height: ${props => (props.height ? props.height : '50px')};
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  div:nth-of-type(2) {
    cursor: pointer;
  }
`

export const Knob = styled.div`
  background: ${props => (props.active ? '#3399FF' : '#BCBCBC')};
  height: 20px;
  overflow: visible;
  border-radius: 10px;
  width: 50px;
  ::after {
    filter: drop-shadow(0px 0 4px grey);
    transition: left 1.3s;
    content: "";
    display: inline-block;
    position: relative;
    left: ${props => (props.active ? '20px' : '-4px')};
    top: -5px;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
`

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Selector = styled.div`
  flex: 1;
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

export const WrapSelector = styled.div`
  background-color: ${props =>
    props.disabled ? props.theme.colors.elementDisabled : props.theme.colors.white};
  margin-bottom: 12px;
  padding: 0 12px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &::after {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.gray};
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f0d7';
    padding: 8px 8px;
    position: absolute;
    width: 30px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: -1px;
    margin-left: -17px;
    pointer-events: none;
  }
`

export const Banner = styled.div`
  background-color: #f6f6f6;
  display: flex;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 10px;

  .text-style {
    margin-left: 10px;
  }

  .text-style span {
    vertical-align: middle;
  }
`
