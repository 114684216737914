import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'
import Loading from 'components/Loading'
import { T } from 'core'
import Subnavigation from './Subnavigation'
import { trackDashboardAction } from 'helper/DashboardUtils'
import { withRouter } from "helper/withRouter";
import { Outlet } from 'react-router-dom';
import DashBoardContext from './DashBoardContext';

class Dashboard extends Component {
  items = [
    {
      name: 'Dashboard.practices',
      href: '/overview/dashboard/practices'
    },
    {
      name: 'Dashboard.kpis',
      href: '/overview/dashboard/kpis'
    }
  ]

  componentDidUpdate() {
    trackDashboardAction({ ...this.props.kpis, action: 'analyticsSectionOpened' })
  }


  get headline() {
    const { location } = this.props

    const { name } = this.items.reduce((a, b) => {
      return a.href === location.pathname ? a : b
    })

    return <FormattedMessage {...T(name)} />
  }

  getDashBoardContextValue() {
    const { kpis } = this.props;
    return { kpis: kpis };
  }

  render() {
    const { location, loading } = this.props
    if (loading) {
      return <Loading isLoading={loading} />
    }

    return (
      <DashBoardContext.Provider value={this.getDashBoardContextValue()}>
        <section className="container Dashboard">
          <div className="row">
            <div className="col-md-12">
              <h2>
                {this.headline}
              </h2>
            </div>
          </div>
          <div className="row col-lg-12">
            <p className="help-block" data-qa="analytics-info-text">
              <FormattedMessage {...T('analytics.InfoText')} />
            </p>
          </div>
          <Subnavigation items={this.items} location={location} />
          <main>
            <Outlet />
          </main>
        </section>
      </DashBoardContext.Provider>
    )
  }
}

const mapProps = ({ data: { refetch, loading, Kpis } }) => {
  return {
    refetch,
    loading,
    kpis: Kpis
  }
}

const QUERY_KPIS_SETUP = gql`
  query {
    Kpis {
        kpis {
          type
          value
        }
        name
        totalNumberOfPatients
        type
    }
  }
`
const options = () => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_KPIS_SETUP, {
    props: mapProps,
    options
  })
)

export default withData(withRouter(Dashboard))
