import React, { FC, ReactElement } from "react";
import { T } from "core";
import SortableTableHeader from "components/SortableTableHeader";
import { injectIntl } from "react-intl";
import Loading from "components/Loading";
import { StyledTable } from "styledComponents/Table/StyledTable";

export const Table: FC<any> = (props): ReactElement => {
  const setSort = (sortField) => () => {
    props.setSort(sortField);
  };
  const {
    sort,
    employees = [],
    isLoading,
    intl: { formatMessage },
  } = props;

  const getEmployees = (employeeList) => {
    if (employeeList.length) {
      return employeeList.map((item, index) => (
        <tr key={`${index}__manage-row`}>
          <td>{item.firstName}</td>
          <td>{item.lastName}</td>
          <td>{item.email}</td>
        </tr>
      ));
    }
    return formatMessage(T("switch.empty-message"));
  };
  return (
    <StyledTable className="table--users">
      <thead>
        <tr>
          <SortableTableHeader
            className="col-2"
            name="firstName"
            onClick={setSort("firstName")}
            value={formatMessage(T("common.firstName"))}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="lastName"
            onClick={setSort("lastName")}
            value={formatMessage(T("common.lastName"))}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="email"
            onClick={setSort("email")}
            value={formatMessage(T("common.email"))}
            sort={sort}
          />
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          getEmployees(employees)
        )}
      </tbody>
    </StyledTable>
  );
};

export default injectIntl(Table);
