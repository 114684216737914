import styled from 'styled-components'
import { ActionButtons } from '../'

export const MenuToggle = styled.div`cursor: pointer;`

export const Counter = styled.div`
	text-align: right;
	margin-bottom: -10px;
	flex: 1;
	> span {
		font-weight: bold;
	}
	p {
		font-weight: normal;
	}
`

export const StyledSelect = styled.div`
	width: 280px;
	margin-left: 10px;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	div {
		cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	};
`

export const StyledSelect_MarketingMessages = styled.div`
	width: 280px;
	margin-left: 10px;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	div {
		cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	};
    min-height:110px;
`

export const ExtraInfo = styled.div`
	padding: 0 10px;
	margin: 0px;
`

export const StyledFilters = styled.div`
	display: flex;
	padding: 10px 0 10px 0;
	align-items: center;
`

export const ActionButtonsDropdown = styled(ActionButtons)`
	justify-content: space-between;
	margin-top: 20px;
	padding-left: 0;
	padding-right: 0;
	> button {
		font-size: 14px;
		height: 31px;
		width: 100px;
		min-width: 0;
		&:first-of-type {
			margin-left: 11px;
		}
		&:nth-of-type(2) {
			margin-left: 0px;
			margin-right: 11px;
			cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
		}
	}
`

export const CheckboxDiv = styled.li`
	padding: 5px 0px;
	label div {
		background-color: ${(props) =>
			props.defaultChecked && !props.disabled ? '#3399FF' : props.theme.colors.white};
		border: 6px solid #fff;
	}
`
export const StyledDateRange = styled.div`
	position: absolute;
	left: 261px;
	top: -1px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	border: 1px solid rgba(0, 0, 0, 0.15);
	.is-inRange,
	.is-selected {
		background: #3399FF !important;
		color: #fff !important;
	}
`

const FilterOn = styled.div`
	display: none;
	margin-right: 20px;
	:last-of-type {
		margin-right: 0;
	}

	.filterIsActive {
		> button,
		> button:active {
			background-color: black;
			color: white;
		}
		> button:hover {
			background-color: #404040;
		}
	}

	.dropdown-toggle {
		border: 1px solid grey;
		border-radius: 5px;
		width: 100%;
		font-size: 14px;
	}
`

export const FilterOnPatientClosedCreated = styled(FilterOn)`
	display: block;
	label {
		min-width: 250px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding-left: 10px;
		font-weight: bold;
	}
`

export const FilterOnBlock = styled(FilterOn)`display: block;`

export const FilterOnProvider = styled(FilterOn)`display: none;`
export const FilterOnPatientStatus = styled(FilterOn)`display: none;`
export const FilterOnCreatedDate = styled(FilterOn)`display: none;`
export const FilterOnClosedDate = styled(FilterOn)`display: none;`
export const FilterCounter = styled(FilterOn)`display: none;`
