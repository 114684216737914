import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { T } from 'core'
import { default as scrollTo } from 'scroll-to-element'
import 'moment-timezone/data/packed/latest.json'
import moment from 'moment-timezone'

import Loading from 'components/Loading'
import InputField from 'formfields/InputField'
import TextAreaField from 'formfields/TextAreaField'
import SelectField from 'formfields/SelectField'
import ImageUploadField from 'formfields/FileUpload/ImageUploadField'
import OpeningHoursFieldArray from 'formfields/OpeningHours/OpeningHoursFieldArray'
import CheckboxField from 'formfields/CheckboxField'

import { states } from 'helper/USStates'
import { getLocales, customMessageMaxLength, DataCollectionType } from 'helper/constants'
import { withQuery } from 'helper/withQuery'
import { shopTracking } from 'helper/shopTracking'
import { withRouter } from 'helper/withRouter'
import { getAutoReplyConfigurationForPractice } from 'helper/AutoReplyConfigHelper'
import { Button } from 'styledComponents/Button'
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'
import { getTenantId } from '../../helper/TokenUtil'
import { sendDataToDataCollection } from '../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from '../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../helper/DataCollection/DataCollectionConstants'

import { graphQLClient } from '../../graphql/client'
import { DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY } from '../../graphql/queries/PracticesQuery'


const autoReplyEnabledOption = {
  description: {
    key: 'hcpEnableChatAutoreply',
    values: {
    }
  }
}

const remainingCharacterKey = {
  autoReply: 'autoReply',
  outOfOffice: 'outOfOffice'
}

export class EditForm extends Component {
  constructor(...args) {
    super(...args)

    this.state = {
      remainingCharacter: { autoReply: 0, outOfOffice: 0 }
    }
  }

  selectedCountryCode = ""
  practiceValidationSchema = () => Yup.object().shape({
    name: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict(),
    email: Yup.string()
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict()
      .email(this.formatMessage('validator.email')),
    street: Yup.string().required(this.formatMessage('validator.required')),
    zipCode: Yup.string()
      .trim()
      .isValidZipCode(this.props.countries, this.selectedCountryCode, this.formatMessage('validator.regex'))
      .max(10, this.formatMessage('validator.zipCodeMaxLength'))
      .required(this.formatMessage('validator.required')),
    city: Yup.string().required(this.formatMessage('validator.required')),
    phone: Yup.string().isPhoneNoValid(),
    accountNumber: Yup.string()
      .required(this.formatMessage('validator.required'))
      .isValidSivantosId(),
    website: Yup.string().isUrlValid(this.formatMessage('validator.url')),
    locale: Yup.string().required(this.formatMessage('validator.required')),
    timeZone: Yup.string().required(this.formatMessage('validator.required')),
    image: Yup.object().uploadIsValid().uploadMaxFilesize(1000).uploadImageMinDimensions(150, 150)
  })

  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  setRemainingCharacter = (e, value) => {
    (e != undefined && e !== null) && this.setState(prevState => ({
      remainingCharacter: {
        ...prevState.remainingCharacter,
        [value]: e
      }
    }));
  };

  handleTextChange = (e, value) => {
    this.setRemainingCharacter(e.target.value.length, value)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps?.isNewPractice && nextProps.practice) {
      const { practice: { autoReplyMessage, outOfOfficeMessage } } = nextProps
      this.setRemainingCharacter(autoReplyMessage?.length, remainingCharacterKey.autoReply);
      this.setRemainingCharacter(outOfOfficeMessage?.length, remainingCharacterKey.outOfOffice)
    } else {
      this.setRemainingCharacter(nextProps?.OrganizationDetails?.autoReplyMessage?.length, remainingCharacterKey.autoReply)
    }
  }

  prepareOpeningHours = practice => {
    return practice.openingHours.reduce((acc, openingHourDay) => {
      const key = `${openingHourDay.openingTime}-${openingHourDay.closingTime}-${openingHourDay.comment
        ? openingHourDay.comment.replace(/ /g, '')
        : ''}`

      if (!acc[key]) {
        acc[key] = {
          days: [],
          from: openingHourDay.openingTime,
          to: openingHourDay.closingTime,
          comment: openingHourDay.comment
        }
      }

      acc[key].days.push(openingHourDay.dayOfWeek)
      return acc
    }, {})
  }

  checkAutoReply = (autoReplyOrgConfig) => {

    let practiceAutoReplyMessage = autoReplyOrgConfig.autoReplyMessage || ''
    let isPracticeAutoReplyChecked = autoReplyOrgConfig.autoReplyEnabled || false

    return {
      isPracticeAutoReplyChecked,
      practiceAutoReplyMessage
    }
  }

  prepareDataFromPractice = (practice, me, formatMessage, autoReplyOrgConfig = {}) => {
    const openingHours = practice.openingHours
      ? this.prepareOpeningHours(practice)
      : []

    const autoreply = this.checkAutoReply(autoReplyOrgConfig)
    if (practice.id) {
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Practice), EventType.Read)
    }

    return {
      name: practice.name || '',
      phone: practice.phone || '',
      email: practice.email || '',
      street: practice.street || '',
      zipCode: practice.zipCode || '',
      city: practice.city || '',
      state: practice.state || '',
      timeZone: practice.timeZone || moment.tz.guess(),
      country: practice.countryCode || me.countryCode,
      image: practice.imageUrl || '',
      openingHours: _.values(openingHours),
      formatMessage,
      locale: this.getMatchingLocale(practice.locale) || '',
      accountNumber: this.getPracticeAccountNumber(practice.accountNumber) || '',
      website: practice.website || '',
      autoReplyEnabled: autoreply.isPracticeAutoReplyChecked,
      autoReplyMessage: autoreply.practiceAutoReplyMessage,
      outOfOfficeMessage: practice.outOfOfficeMessage,
      defaultOutOfOfficeMessage: practice.defaultOutOfOfficeMessage,
      organizationId: parseInt(getOrganizationIdFromCache()),
      tenantId: parseInt(getTenantId())
    }
  }

  getMatchingLocale(locale) {
    if (!_.isNil(locale) && locale.length > 2) return locale
    const sortedLocales = getLocales()
    return _.findKey(sortedLocales, function (value, key) {
      if (key.includes(locale)) {
        return value
      }
    })
  }

  getPracticeAccountNumber(accountNumber) {
    if (accountNumber === ' ') return accountNumber.trim()
    return accountNumber
  }

  handleSubmit = async (data, { setErrors, setSubmitting }) => {
    const { image } = data
    if (_.isNil(data.locale)) {
      data.locale = ''
    }
    let practice
    try {
      practice = await this.save(data, setErrors)
    } catch (e) {
      setSubmitting(false)
      return
    }
    shopTracking({
      ...this.props.location,
      action: 'shopDetailsUpdated'
    })
    try {
      await this.proceedMediaItems(image, practice)
    } catch (e) {
      console.log('[Practice Page] Can not upload media item')
    } finally {
      this.props.updateLocation(this.props.location, null)
      this.props.navigate(`/overview/orgManagement/practices`)
    }
  }

  async uploadLogo(file, practiceId) {
    try {
      return await this.props.uploadPracticeLogo({ file, practiceId })
    } catch (e) {
      console.warn('[PRACTICE UPLOAD] Uploading Logo has failed.', e.message)
    }
  }

  async deleteLogo(practiceId) {
    try {
      return await this.props.deletePracticeLogo({ practiceId })
    } catch (e) {
      console.warn('[PRACTICE DELETE] Deleting Logo has failed.', e.message)
    }
  }

  async proceedMediaItems(image, practice) {
    if (Object.keys(image).length > 0) {
      const deleteImage = _.get(image, `shouldDelete`, false)
      if (!deleteImage) {
        !!image.data && (await this.uploadLogo(image.data, practice.id))
      } else {
        await this.deleteLogo(practice.id)
      }
    }
  }

  processData = async data => {
    const { openingHours = [] } = data
    const ids = this.props.openingHours.map(hour => hour.id)

    const hours = openingHours.reduce((total, group) => {
      const { days, from, to, comment } = group
      const timePerEachDay = days.map(dayName => {
        const id = ids.pop()
        const workDay = {
          dayOfWeek: dayName,
          openingTime: from,
          closingTime: to,
          comment: comment
        }

        if (id) {
          workDay.id = id
        }

        return workDay
      })

      return [...total, ...timePerEachDay]
    }, [])

    let practice = {
      name: data.name,
      street: data.street,
      zipCode: data.zipCode.trim(),
      city: data.city,
      state: data.state || '',
      phone: data.phone,
      countryCode: data.country,
      timeZone: data.timeZone,
      email: data.email,
      openingHours: hours,
      locale: data.locale,
      accountNumber: data.accountNumber.toUpperCase(),
      website: data.website,
      autoReplyEnabled: data.autoReplyEnabled,
      autoReplyMessage: data.autoReplyMessage,
      outOfOfficeMessage: data.outOfOfficeMessage,
      organizationId: parseInt(getOrganizationIdFromCache()),
      tenantId: parseInt(getTenantId())
    }

    if (_.get(data, 'image.shouldDelete', false)) {
      practice = { ...practice, logo: null }
    }

    return practice
  }

  save = async (data, setErrors) => {
    const { practiceId, intl: { formatMessage } } = this.props
    const practiceData = await this.processData(data)
    if (data.autoReplyEnabled && data.autoReplyMessage.trim() === '') {
      throw new setErrors({
        autoReplyMessage: formatMessage(T('hcp.valid.chatAutoReplyMessage'))
      })
    }

    let practice = {}

    if (practiceId) {
      const payload = await this.props
        .updatePractice({
          practiceId,
          practice: { ...practiceData, id: practiceId }
        }).then((result) => {
          if (result.errors) throw new Error(result.errors[0].message)
          return result
        })
        .catch((error) => {
          const code = error.toString().match('code: (.*),')[1]
          if (code.includes('ACCOUNT_NUMBER_IS_USED'))
            throw new setErrors({
              accountNumber: formatMessage(T('register.accountNumberAlreadyUsed'))
            })
          throw new setErrors({ update: 'Update Failed' })
        })
      practice = payload.data.updatePractice
      this.resetDefaultPracticeWithAccountTypeCacheForDataCollection()
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Practice), EventType.Update)
    } else {
      const payload = await this.props
        .createPractice({ practice: practiceData })
        .then((result) => {
          if (result.errors) throw new Error(result.errors[0].message)
          return result
        })
        .catch((error) => {
          const code = error.toString().match('code: (.*),')[1]
          if (code.includes('ACCOUNT_NUMBER_IS_USED'))
            throw new setErrors({
              accountNumber: formatMessage(T('register.accountNumberAlreadyUsed'))
            })
          throw new setErrors({ create: 'Creation Failed' })
        })
      practice = payload.data.createPractice
      shopTracking({
        ...this.props.location,
        action: 'practiceCreated'
      })
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Practice), EventType.Create)
    }

    return practice
  }

  onChatAutoReplyClick = (isChecked, setErrors, valueOfMessage) => {
    if (isChecked && valueOfMessage.trim() === '') {
      throw new setErrors({
        autoReplyMessage: formatMessage(T('hcp.valid.chatAutoReplyMessage'))
      })
    }
  }
  handleCancel = () => {
    const { navigate } = this.props
    this.props.updateLocation(this.props.location, null)
    navigate(-1)
  }

  renderStateField() {
    const { intl: { formatMessage }, isReadOnly } = this.props
    return (
      this.selectedCountryCode === 'en' &&
      <Field
        name="state"
        label={formatMessage(T('common.state'))}
        component={SelectField}
        disabled={isReadOnly}
        options={_.map(states, (state, stateCode) => ({
          value: stateCode,
          label: state
        }))}
      />
    )
  }

  onError = () => {
    if (window && window.document) {
      scrollTo('.has-error', {
        offset: -200,
        duration: 600
      })
    }
  }

  getSortedCountries = () => {
    const { countries, intl: { formatMessage } } = this.props
    const unsortedCountries = countries && countries.map(country => (
      {
        countryCode: country.countryCode,
        countryName: formatMessage(T('countryName' + country.countryCode))
      }
    ))
    const sortedCountries = _.orderBy(unsortedCountries, [country => country.countryName.toLowerCase()], ['asc'])
    return sortedCountries
  }

  resetDefaultPracticeWithAccountTypeCacheForDataCollection = () => {
    const data = {
      defaultPracticeWithAccountType: {
        __typename: 'defaultPracticeWithAccountType',
        defaultPracticeId: null,
        isAccountNumberStartsWithT: false
      }
    }

    graphQLClient.writeQuery({
      query: DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY,
      data
    })
  }

  render() {
    const {
      practice = {},
      practiceId,
      intl: { formatMessage },
      Me = {},
      error,
      loading,
      isReadOnly,
      isNewPractice,
      OrganizationDetailsLoading = false,
      OrganizationDetails = {}
    } = this.props
    const sortedCountryCodesWithCountryNames = this.getSortedCountries()
    const { name = '', organizationConfig = {} } = practice
    const titleKey = practiceId ? 'hcpEdit.title' : 'manage.add_new_practice'
    const sortedLocales = getLocales()
    if (isNewPractice) {
      organizationConfig.autoReplyMessage = OrganizationDetails.autoReplyMessage
      organizationConfig.autoReplyMode = OrganizationDetails.autoReplyMode
      organizationConfig.autoReplyEnabled = OrganizationDetails.autoReplyMode == "DISABLED"
        || OrganizationDetails.autoReplyMode == "ENABLED" ? false : true
    }
    const autoReplyConfig = getAutoReplyConfigurationForPractice(practice, organizationConfig, isNewPractice)
    return (
      <div className="shopDetailPage edit">
        <Loading isLoading={loading || OrganizationDetailsLoading} />
        {(!loading && !OrganizationDetailsLoading) &&
          <Formik
            onSubmit={this.handleSubmit}
            validationSchema={this.practiceValidationSchema}
            initialValues={this.prepareDataFromPractice(practice, Me, formatMessage, autoReplyConfig)}
          >
            {({ values, isSubmitting, setErrors }) => {
              this.selectedCountryCode = values.country
              return (
                <Form>
                  <Loading isLoading={isSubmitting} />
                  <div className="container">
                    <div className="col-lg-10 offset-lg-1">
                      <div className="shopDetails">
                        <div className="row">
                          <div className="col-12">
                            <h1 data-qa="title">
                              <FormattedMessage {...T(titleKey)} /> {name}
                            </h1>
                            <p data-qa="required-text">
                              <FormattedMessage
                                {...T('common.required_text')}
                                values={{ mark: <span className="text-primary">*</span> }}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <h2 data-qa="shop-title">
                              <FormattedMessage {...T('common.shop')} />
                            </h2>
                            <Field
                              disabled={isReadOnly}
                              name="accountNumber"
                              component={InputField}
                              label={formatMessage(T('register.sivantosId'))}
                              labelClassName="required"
                            />
                            <Field
                              disabled={isReadOnly}
                              name="name"
                              component={InputField}
                              label={formatMessage(T('common.name'))}
                              labelClassName="required"
                            />
                            <Field
                              name="image"
                              isReadOnly={isReadOnly}
                              component={ImageUploadField}
                              label={formatMessage(T('common.logo'))}
                              hint={`${formatMessage(T('hcpEdit.helpLogo'))} 
                              ${formatMessage(T('ImageUpload.max_allowed_size'))}`}
                            />
                          </div>
                          <div className="col-6">
                            <h2 data-qa="details-title">
                              <FormattedMessage {...T('common.details')} />
                            </h2>
                            <Field
                              name="street"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.street'))}
                              labelClassName="required"
                            />
                            <Field
                              name="zipCode"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.zip'))}
                              labelClassName="required"
                            />
                            <Field
                              name="city"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.city'))}
                              labelClassName="required"
                            />
                            {this.renderStateField()}
                            <Field
                              disabled={isReadOnly}
                              name="country"
                              component={SelectField}
                              label={formatMessage(T('common.country'))}
                              labelClassName="required"
                              options={sortedCountryCodesWithCountryNames.map(country => ({
                                value: country.countryCode,
                                label: country.countryName
                              }))}
                            />
                            <Field
                              name="phone"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.phone'))}
                            />
                            <Field
                              type="email"
                              name="email"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.email'))}
                            />
                            <Field
                              name="website"
                              disabled={isReadOnly}
                              component={InputField}
                              label={formatMessage(T('common.url'))}
                            />
                            <Field
                              name="locale"
                              disabled={isReadOnly}
                              component={SelectField}
                              label={formatMessage(T('PracticeEdit.locale'))}
                              labelClassName="required"
                              options={Object.keys(sortedLocales).map(countryCode => ({
                                value: countryCode,
                                label: formatMessage(T('languageName.' + countryCode))
                              }))}
                            />
                            <Field
                              name="timeZone"
                              disabled={isReadOnly}
                              component={SelectField}
                              label={formatMessage(T('common.timezone'))}
                              labelClassName="required"
                              options={moment.tz.names().map(timezone => ({
                                value: timezone,
                                label: timezone
                              }))}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="chatAutoReply">
                        <div className="row">
                          <div className="col-12">
                            <h2 data-qa="opening-hours-title">
                              <FormattedMessage {...T('hcp.chatAutoReplyConfiguration')} />
                            </h2>
                            <p className="help-block" data-qa="opening-hours-help">
                              <FormattedMessage {...T('hcp.helpChatAutoReplyConfiguration')} />
                            </p>
                            <p className="help-block" data-qa="auto-reply-config-help">
                              <FormattedMessage {...T(autoReplyConfig.helpText)} />
                            </p>
                          </div>
                        </div>
                        {autoReplyConfig.isConfigVisible &&
                          <>
                            <div className="row autoReplyEnabled">
                              <div data-qa="autoReplyEnabled">
                                <Field
                                  name="autoReplyEnabled"
                                  isDisabled={isReadOnly || !autoReplyConfig.canEditAutoReply}
                                  component={CheckboxField}
                                  label={formatMessage(T('hcp.chatAutoReplyMessage'))}
                                  labelClassName="required"
                                  options={autoReplyEnabledOption}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name="autoReplyMessage"
                                  maxLength={customMessageMaxLength}
                                  handleChange={(event) => { this.handleTextChange(event, remainingCharacterKey.autoReply) }}
                                  disabled={isReadOnly || !autoReplyConfig.canEditMessage}
                                  component={TextAreaField}
                                  label={formatMessage(T('hcp.chatAutoReplyMessage'))}
                                  parentClassName="mb-0"
                                />
                                <div className="float-right mb-4">
                                  {this.state.remainingCharacter.autoReply}/{customMessageMaxLength}
                                </div>
                              </div>
                            </div>
                          </>}
                      </div>

                      <div className="outOfOfficeMessage">
                        <div className="row">
                          <div className="col-12">
                            <h2 data-qa="ooo-message-title">
                              <FormattedMessage {...T('practice.outOfOfficeMessageTitle')} />
                            </h2>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <Field
                              name="outOfOfficeMessage"
                              maxLength={customMessageMaxLength}
                              component={TextAreaField}
                              handleChange={(event) => { this.handleTextChange(event, remainingCharacterKey.outOfOffice) }}
                              label={formatMessage(T('practice.outOfOfficeMessageHeader'))}
                              parentClassName="mb-0"
                              placeholder={practice.defaultOutOfOfficeMessage}
                            />
                            <div className="float-right mb-4">
                              {this.state.remainingCharacter.outOfOffice}/{customMessageMaxLength}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="openingHours">
                        <div className="row">
                          <div className="col-12">
                            <h2 data-qa="opening-hours-title">
                              <FormattedMessage {...T('common.openingHours')} />
                            </h2>
                            <p className="help-block" data-qa="opening-hours-help">
                              <FormattedMessage {...T('hcpEdit.helpOpeningHours')} />
                            </p>
                          </div>
                        </div>

                        <FieldArray
                          name="openingHours"
                          render={arrayHelpers =>
                            <OpeningHoursFieldArray {...arrayHelpers} isReadOnly={isReadOnly} />}
                        />
                      </div>

                      <div className="pageControls">
                        <div className="row">
                          {error &&
                            <div className="alert alert-danger">
                              <i className="fas fa-fw fa-exclamation-triangle" />{' '}
                              {formatMessage(T('hcpEdit.errorUpdateFailed'))}
                            </div>}
                          <div className="col-2 offset-8">
                            <Button
                              secondary
                              type="button"
                              onClick={this.handleCancel}
                              className="btn-block"
                              data-qa="cancel-button"
                            >
                              {formatMessage(T('common.cancel'))}
                            </Button>
                          </div>
                          <div className="col-2">
                            <Button
                              primary
                              type="submit"
                              className="btn-block"
                              data-qa="save-button"
                              disabled={isReadOnly}
                            >
                              {formatMessage(T('common.save'))}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>}
      </div>
    )
  }
}

EditForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  openingHours: PropTypes.array,
  location: PropTypes.shape({}).isRequired,
  locale: PropTypes.string
}

export default withRouter(withQuery(injectIntl(EditForm)))
