import { graphql } from '@apollo/client/react/hoc';
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import { T } from "core";
import { compose } from "lodash/fp";
import * as Yup from "yup";

import PasswordComponent from "formfields/Password";
import { MUTATION_PASSWORD_EXPIRE } from "../graphql/mutations/PasswordExpireMutation";
import { logout } from 'actions/authentication'

export const PasswordExpire = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
  }, [])


  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    const passwordPayload = {
      oldPassword: values.password.oldPassword,
      newPassword: values.password.passwordEnter,
    };
    try {
      await props.updateExpiredPassword({
        password: passwordPayload,
      });
      localStorage.removeItem('passwordExpired')
      setIsLoading(false);
      setShouldNavigate(true)
      logout()
    } catch ({ graphQLErrors = [] }) {
      setIsLoading(false);
      const { message } = graphQLErrors[0];
      if (message.includes("INVALID_PASSWORD")) {
        actions.setFieldError(
          "password.oldPassword",
          formatMessage("password.GENERAL_INVALID_INPUT")
        );
      }
      if (message.includes("PASSWORD_POLICY_ERROR")) {
        actions.setFieldError(
          "password.passwordEnter",
          formatMessage("password.notAcceptable")
        );
      }
    }
  };

  const passwordValidationSchema = () => {
    return Yup.object().shape({
      password: Yup.object().shape({
        oldPassword: Yup.string().required(formatMessage("validator.required")),
        passwordEnter: Yup.string()
          .isPasswordValid()
          .min(6, formatMessage("min.password"))
          .required(formatMessage("validator.required")),
        passwordReEnter: Yup.string()
          .required(formatMessage("validator.required"))
          .test(
            "passwords-match",
            formatMessage("validator.pinNotEqual"),
            function (value) {
              return this.parent.passwordEnter == value;
            }
          ),
      }),
    });
  };

  const formatMessage = (keyID) => {
    return props.intl.formatMessage(T(keyID));
  };

  const password = { oldPassword: "", passwordEnter: "", passwordReEnter: "" };

  return (
    <div>
      <Formik
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
        validationSchema={passwordValidationSchema}
        initialValues={{ password }}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form>
              <div className="container">
                <div className="col-lg-10 offset-lg-1">
                  <h1 data-qa="title">{<FormattedMessage {...T("password.UpdateExpiredPassword")} />}</h1>
                  <div className="row" data-qa="password">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Field
                          disabled={false}
                          name="password"
                          component={PasswordComponent}
                          oldPassword={true}
                          required={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 align-button">
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-primary btn-block"
                        data-qa="continue-button"
                      >
                        {<FormattedMessage {...T("common.save")} />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const withData = compose(
  graphql(MUTATION_PASSWORD_EXPIRE, {
    props: ({ mutate }) => ({
      updateExpiredPassword: (variables) =>
        mutate({
          variables,
        }),
    }),
  }),
  injectIntl
);

export default withData(PasswordExpire);
