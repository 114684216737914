import { SHOW_POPUP, getOverlayUpdateData, P_DEFAULT_POPUP } from './OverlayUpdateData'
export const showErrorPopup = (title, errorCode, updateOverlay) => {
  const popup = getOverlayUpdateData(SHOW_POPUP, {
    type: P_DEFAULT_POPUP,
    payload: {
      title,
      message: errorCode
    }
  })
  updateOverlay({ ...popup })
}
