import React from 'react'
export default function Stepper(props) {    
    const { steps } = props
    return (
        <>
        <div className="stepper-container">
          {
            steps.map((value, index) => {
              let isActive = index < props.currentStep  ? true : false;
              let iconClass = index == props.currentStep  ? 'stepper-icon-selected': 'stepper-inactive'
              let textClass = getTextClass(props.currentStep, index)
              let arrowClass = index <= props.currentStep ? 'stepper-active'
                              : 'stepper-inactive' 
              return (
              <div className={`stepper-items ${isActive ? 'active-stepper-item': ''}`} 
                key={`stepper-item-${index}`}
                onClick={() => props.handleStepChange(index , isActive)}
                >
                <div className="stepper-step-item">
                  <span className="stepper-step-icon-container">
                    { index > 0 &&
                      <div className={`stepper-arrow  ${arrowClass}`}/>
                    }
                   <span className="stepper-step-icon">
                      {isActive 
                        &&
                        <i className="fas fa-check stepper-icon-done"/>
                      }
                      {index >= props.currentStep
                        &&
                        <i className={`fas fa-circle ${iconClass}`}/>
                      }
                   </span>
                  </span>                  
                  <span className={`stepper-step-label ${textClass}`}>
                     {value}
                   </span>
                </div>
              </div>
            )
            })
          }
        </div>
      </>
      
    )
}

function getTextClass(currentStep, index){
  if(index < currentStep)  return 'stepper-active'
  if(index > currentStep) return 'stepper-inactive' 
  return 'stepper-icon-selected'
}