import React, { Component } from 'react'
import { pick } from 'lodash'
import { notEqual } from 'helper/Utils'
import Dropdown from 'components/Dropdown'
import FormGroup from 'components/FormGroup'
import Fieldset from 'components/Fieldset'
import messages from 'i18n/messages.json'
import { T } from 'core'
import { StyledDeviceModelList } from 'styledComponents/Div/DivComponent/DivPatient'

class DeviceModelSettings extends Component {
  static displayName = 'DeviceModelSettings'

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.deviceModelLeftId !== this.props.deviceModelLeftId ||
      nextProps.deviceModelRightId !== this.props.deviceModelRightId ||
      notEqual(
        pick(nextProps, ['deviceModelsLeft', 'deviceModelsRight']),
        pick(this.props, ['deviceModelsLeft', 'deviceModelsRight'])
      )
    )
  }

  onSelectDeviceModel = deviceType => deviceModelId => {
    this.props.onSelect({ deviceType, deviceModelId: deviceModelId.toString() })
  }

  render() {
    const {
      formatMessage,
      setFormRef,
      deviceModelsRight,
      deviceModelsLeft,
      deviceModelLeftId,
      deviceModelRightId,
      onValidationChange
    } = this.props

    const requirements = !!deviceModelLeftId || !!deviceModelRightId ? null : ['isRequired']

    return (
      <div>
        <StyledDeviceModelList>
          <Fieldset
            ref={form => {
              setFormRef({ name: 'formDeviceSettings', form })
            }}
            alignment="horizontal"
            onValidationChange={onValidationChange}
          >
            <FormGroup
              id="deviceModelRight"
              helpText={formatMessage(T('validator.deviceRequired'))}
            >
              <Dropdown
                defaultDropdownTitle={formatMessage(messages.hearingDeviceModel)}
                onSelect={this.onSelectDeviceModel('deviceModelRightId')}
                height={200}
                options={
                  deviceModelsRight &&
                  deviceModelsRight.map(opt => ({
                    value: opt.id,
                    text: opt.model
                  }))
                }
                selected={deviceModelRightId}
                requirements={requirements}
              />
            </FormGroup>

            <FormGroup id="deviceModelLeft" helpText={formatMessage(T('validator.deviceRequired'))}>
              <Dropdown
                defaultDropdownTitle={formatMessage(messages.hearingDeviceModel)}
                onSelect={this.onSelectDeviceModel('deviceModelLeftId')}
                height={200}
                options={
                  deviceModelsLeft &&
                  deviceModelsLeft.map(opt => ({
                    value: opt.id,
                    text: opt.model
                  }))
                }
                selected={deviceModelLeftId}
                requirements={requirements}
              />
            </FormGroup>
          </Fieldset>
        </StyledDeviceModelList>
      </div>
    )
  }
}
export default DeviceModelSettings
