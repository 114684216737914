import React from 'react'
import { Button } from 'styledComponents/Button'
import { T } from 'core'
import { FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'
export default function StepControl (props) {

    return (
        <div className="row stepper-buttons-row">
            {props.currentStep> 0 &&
             <div className="col-lg-3 stepper-buttons-column">
                <Button
                    primary
                    data-qa="registration-page-prev-button"
                    className={`btn--min-width pull-left ${props.busy ? 'busy' : ''}`}
                    type="button"
                    onClick={() => props.handlePrevPage()}
                    >
                    <FormattedMessage {...T('register.stepButtonBack')}/>
                    {props.busy && <i className="fas fa-circle-notch fa-spin" />}
                </Button>
            </div>}
            <div className="col-lg-3 stepper-buttons-column">
                <Button
                primary
                data-qa="registration-page-submit-button"
                className={`btn--min-width pull-right ${props.busy ? 'busy' : ''}`}
                type="submit"
                onClick={() => props.handleValidationOnSubmit()}
                >
                    { 
                        !props.isReviewPage &&
                        <FormattedMessage {...T('register.stepButtonNext')}/>
                    }
                    { 
                        props.isReviewPage &&
                        <FormattedMessage {...messages.registerRegister}/>
                    }
                    {props.busy && <i className="fas fa-circle-notch fa-spin" />}
                </Button>
            </div>
          </div>
    )
}