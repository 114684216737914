import TermsPage from 'containers/TermsPage'
import ImprintPage from 'containers/ImprintPage'
import DevTools from 'components/_Dev'
import VideoDebug from 'components/_VideoCall/Icelink/VideoDebugView'
import { Routes, Route, Navigate } from "react-router-dom"
import Autheticate from './Autheticate'
import fflags from 'helper/FFlags'
import LandingPage from 'containers/LandingPage'
import MaintenancePage from 'components/MaintenancePage'
import { FormattedMessage } from "react-intl"
import { T } from "core"

// AppRoutes
import Overview from "containers/Overview"
import PatientCreatePage from "components/_Patient/Create"

// OverviewRoutes
import PatientsList from "containers/PatientsList"
import MarketingMessagePage from "containers/Marketing/Message"
import Dashboard from "components/_Dashboard"
import OrgManagement from "components/OrgManagement"
import Profile from "components/_Profile"

// PatientsListRoutes
import PatientsPage from "containers/PatientsList/Patients"

// DashboardRoutes
import DashboardPractices from "components/_Dashboard/Practices"
import DashboardKpis from "components/_Dashboard/Kpis"

// OrgManagementRoutes
import ManageUsersPage from "components/OrgManagement/UsersPage"
import ManagePracticesPage from "components/OrgManagement/PracticesPage"
import LessonWrapper from "components/OrgManagement/LessonsPage/LessonWrapper"

// ProfileRoutes
import ProfileOverviewOfCxxPatient from "components/_Profile/cxxPatient"
import ProfileOverview from "components/_Profile/Overview"
import LessonRatingsWrapper from "components/_Profile/Ratings/LessonRatingsWrapper"
import ProfileMessages from "components/_Profile/Messages"
import ProfileTunigWrapper from "components/_Profile/Tuning/ProfileTunigWrapper"
import ProfileSetting from "./components/_Profile/Settings"

// ProfileOverviewRoutes
import ProfileOverviewWearingTimePage from "components/_Profile/Overview/WearingTimePage"
import ProfileOverviewSatisfactionPage from "components/_Profile/Overview/SatisfactionPage"
import ProfileOverviewAssistantHistoryPage from "components/_Profile/Overview/AssistantHistory"

// ProfileSettingsRoutes
import PatientEditPage from "components/_Patient/Edit"
import PatientSetupPage from "containers/PatientProfile/PatientSetupPage"
import PatientHearing from "components/_Patient/HearingInstrument"

// PracticeRoutes
import PracticeCreatePage from "containers/Practice/PracticeCreate"
import PracticeEditPage from "containers/Practice/PracticeEdit"
import PracticePage from "containers/Practice/PracticeDelete"

import CreateLessonTemplate from "containers/LessonSet/LessonCreate/CreateLessonTemplate"
import SigniaManageLessons from "components/_Profile/ManageLessons/SigniaManageLessons"
import { SigniaManageLessonsWrapper } from './components/_Profile/ManageLessons/SigniaManageLessonsWrapper'

import HelpCenter from "components/HelpCenter"
import Faq from "components/HelpCenter/Faq/Faq"
import CommonFaq from "components/HelpCenter/Faq/CommonFaq"
import OrderedFaq from "components/HelpCenter/Faq/OrderedFaq"
import Tutorials from "components/HelpCenter/Tutorials/Tutorials"
import SupportedDevices from "components/HelpCenter/SupportedDevices/SupportedDevices"

import HcpRegistrationPage from 'containers/Registration/HcpRegistrationPage'
import RegistrationFinishPage from 'containers/Registration/FinishPage'
import PasswordResetRequest from 'containers/PasswordResetRequest'
import PasswordReset from 'containers/PasswordReset'
import PasswordExpire from 'containers/PasswordExpire'
import DisclaimerPage from 'containers/DisclaimerPage'
import EmployeeCreatePage from "containers/Employee/EmployeeCreatePage"
import EmployeeEditPage from "containers/Employee/EmployeeEditPage"
import OrganizationEditPage from "containers/Organization/OrganizationEditPage"
import EmployeeSelfCompletePage from "containers/Employee/EmployeeSelfCompletePage"
const props = {};
export const AppRoutes = ({ Me }) =>
  <Routes>
    <Route path="/" element={<Autheticate />}>
      <Route exact path="/" element={<Navigate to="/overview" />} />
      <Route
        path="/overview"
        element={<Overview />}
        name={<FormattedMessage {...T("Dashboard.overview")} />}
      >
        <Route exact path="/overview" element={<Navigate to="/overview/patients" />} />
        <Route path="/overview/patients" element={<PatientsList />}>
          <Route path="/overview/patients" element={<PatientsPage />} />
        </Route>
        <Route
          path="/overview/marketing/messages"
          element={<MarketingMessagePage />}
          name={<FormattedMessage {...T("ProfileNav.marketing.messages")} />}
        />
        <Route path="/overview/dashboard" element={<Dashboard />} >
          <Route
            exact
            path="/overview/dashboard"
            element={<Navigate to="/overview/dashboard/practices" />}
          />
          <Route
            path="/overview/dashboard/practices"
            element={<DashboardPractices />}
            name={<FormattedMessage {...T("Dashboard.analytics")} />}
          />
          <Route
            path="/overview/dashboard/kpis"
            element={<DashboardKpis />}
            name={<FormattedMessage {...T("Dashboard.analytics")} />}
          />
        </Route>
        <Route
          path="/overview/orgManagement"
          element={<OrgManagement />}
          name={<FormattedMessage {...T("common.manage")} />}
        >
          <Route
            exact
            path="/overview/orgManagement"
            element={<Navigate to="/overview/orgManagement/users" />}
          />
          <Route
            path="/overview/orgManagement/users"
            element={<ManageUsersPage />}
            name={<FormattedMessage {...T("orgManagement.users")} />}
          />
          <Route
            path="/overview/orgManagement/practices"
            element={<ManagePracticesPage />}
            name={<FormattedMessage {...T("orgManagement.practices")} />}
          />
          <Route
            exact
            path="/overview/orgManagement/organization/:id/edit"
            element={<OrganizationEditPage Me={Me} />}
            name={<FormattedMessage {...T("common.edit")} />}
          />
          <Route
            exact
            path="/overview/orgManagement/organization/:id/view"
            element={<OrganizationEditPage isReadOnly={true} />}
            name={<FormattedMessage {...T("common.view")} />}
          />
          <Route
            path="/overview/orgManagement/lessons"
            element={<LessonWrapper name={"Hearing Lessons"} />}
            name={"Hearing Lessons"}
          />
        </Route>
      </Route>
      <Route
        path="/help"
        element={<HelpCenter />}
        name={<FormattedMessage {...T("helpCenter.header")} />}
      >
        <Route exact path="/help" element={<Navigate to="/help/faq" />} />
        <Route
          path="/help/faq"
          element={<Faq />}
          name={<FormattedMessage {...T("helpCenter.faq")} />}
        >
          <Route
            exact
            path="/help/faq"
            element={<Navigate to={"/help/faq/commonfaq"} />}
          />
          <Route
            path="/help/faq/commonfaq"
            element={<CommonFaq />}
            name={<FormattedMessage {...T("helpCenter.faq")} />}
          />
          <Route
            path="/help/faq/orderedfaq"
            element={<OrderedFaq />}
            name={<FormattedMessage {...T("helpCenter.faq")} />}
          />
        </Route>
        <Route
          path="/help/tutorials"
          element={<Tutorials />}
          name={<FormattedMessage {...T("helpCenter.tutorials")} />}
        />
        <Route
          path="/help/supportedDevices"
          element={<SupportedDevices />}
          name={<FormattedMessage {...T("helpCenter.supportedDevices")} />}
        />
      </Route>
      <Route
        exact
        path="/profile/cxx/:id"
        element={<ProfileOverviewOfCxxPatient />}
      />
      <Route
        path="/profile/:id"
        element={<Profile />}
        name={<FormattedMessage {...T("navbar.profile")} />}>
        <Route
          name="excluded"
          exact
          path="/profile/:id"
          element={<Navigate to="overview" replace />}
        />
        <Route
          name={<FormattedMessage {...T("ProfileNav.overview")} />}
          path="/profile/:id/overview"
          element={<ProfileOverview />}
        >
          <Route
            name={<FormattedMessage {...T("ProfileNav.overview")} />}
            exact
            path="/profile/:id/overview"
            element={<ProfileOverviewWearingTimePage />}
          />
          <Route
            name={<FormattedMessage {...T("customerProfile.satisfaction")} />}
            path="/profile/:id/overview/satisfaction"
            element={<ProfileOverviewSatisfactionPage />}
          />

          <Route
            name={<FormattedMessage {...T("customerProfile.assistantHistory")} />}
            path="/profile/:id/overview/assistant-history"
            element={<ProfileOverviewAssistantHistoryPage {...props} />}
          />
        </Route>
        <Route
          name={<FormattedMessage {...T("ProfileNav.ratings")} />}
          path="/profile/:id/ratings"
          element={<LessonRatingsWrapper />}
        />
        <Route
          name={<FormattedMessage {...T("ProfileNav.messages")} />}
          path="/profile/:id/messages"
          element={<ProfileMessages {...props} />}
        />
        <Route
          name={<FormattedMessage {...T("ProfileNav.remote-fine-tuning")} />}
          path="/profile/:id/tuning"
          element={<ProfileTunigWrapper />}
        />
        <Route
          name={<FormattedMessage {...T("ProfileNav.patient-settings")} />}
          path="/profile/:id/settings"
          element={<ProfileSetting {...props} />}>
          <Route
            exact
            path="/profile/:id/settings"
            element={<PatientEditPage {...props} />}
          />
          <Route
            name={<FormattedMessage {...T("patientData.hearingInstrument")} />}
            path="/profile/:id/settings/hearing"
            element={<PatientHearing {...props} />}
          />
          <Route
            name={<FormattedMessage {...T("dropdown.manage-lessons")} />}
            path="/profile/:id/settings/lessons"
            element={<SigniaManageLessonsWrapper {...props} />}
          />
          <Route
            name={<FormattedMessage {...T("customerDetail.reconnect")} />}
            path="/profile/:id/settings/setup"
            element={<PatientSetupPage {...props} />}
          />
        </Route>
      </Route>
      <Route
        path="/customer/create/:defaultPracticeId"
        element={<PatientCreatePage />}
        name={<FormattedMessage {...T("customer_create.new_customer")} />}
      />
      <Route
        exact
        path="/practice/create"
        element={<PracticeCreatePage />}
        name={<FormattedMessage {...T("manage.add_new_practice")} />}
      />
      <Route
        exact
        path="/practice/:id/edit"
        element={<PracticeEditPage />}
        name={<FormattedMessage {...T("common.edit")} />}
      />
      <Route
        name="excluded"
        exact
        path="/deletepractice/:id"
        element={<Navigate to="/deletepractice/usersinpractice/:id/delete" />}
      />
      <Route
        exact
        path="/deletepractice/usersinpractice/:id/delete"
        element={<PracticePage {...Me} />}
        name={<FormattedMessage {...T("common.delete")} />}
      />
      <Route
        exact
        path="/deletepractice/patientsinpractice/:id/delete"
        element={<PracticePage {...Me} />}
        name={<FormattedMessage {...T("common.delete")} />}
      />
      <Route
        exact
        path="/practice/:id/view"
        element={<PracticeEditPage isReadOnly={true} />}
        name={<FormattedMessage {...T("common.view")} />}
      />
      <Route
        exact
        path="/lessontemplate/create"
        element={<CreateLessonTemplate />}
        name={
          <FormattedMessage
            {...T("journeyConfigurator.manageLessonSets.CreateSet")}
          />
        }
      />
      <Route
        exact
        path="/lessontemplate/:id/edit"
        element={<LessonWrapper />}
        name={
          <FormattedMessage
            {...T("journeyConfigurator.manageLessonSets.EditSet.Header")}
          />
        }
      />
      <Route
        exact
        path="/employee/invite"
        element={<EmployeeCreatePage />}
        name={<FormattedMessage {...T("userselector.add_user")} />}
      />
      <Route
        exact
        path="/employee/self-complete"
        element={<EmployeeSelfCompletePage />}
        name={<FormattedMessage {...T("userselector.add_user")} />}
      />
      <Route
        exact
        path="/employee/create"
        element={<EmployeeCreatePage />}
        name={<FormattedMessage {...T("userselector.add_user")} />}
      />
      <Route
        exact
        path="/employee/:id"
        element={<EmployeeEditPage />}
        name={<FormattedMessage {...T("navbar.user")} />}
      />
      <Route
        exact
        path="/employee/:id/edit"
        element={<EmployeeEditPage />}
        name={<FormattedMessage {...T("common.edit")} />}
      />
      <Route
        exact
        path="/employee/:id/view"
        element={<EmployeeEditPage isReadOnly={true} />}
        name={<FormattedMessage {...T("common.view")} />}
      />
      <Route
        exact
        path="/register/success"
        element={<RegistrationFinishPage />}
        name={<FormattedMessage {...T("login.register")} />}
      />
      <Route
        excat
        path="/register"
        element={<HcpRegistrationPage />}
        name={<FormattedMessage {...T('login.register')} />}
      />
      <Route
        exact
        path="/password/request-reset"
        element={<PasswordResetRequest />}
        name={<FormattedMessage {...T("reset.title")} />}
      />
      <Route
        exact
        path="/password/reset"
        element={<PasswordReset />}
        name={<FormattedMessage {...T("reset.title")} />}
      />
      <Route
        exact
        path="/password/expire"
        element={<PasswordExpire />}
        name={<FormattedMessage {...T("reset.title")} />}
      />
      <Route
        exact
        path="/disclaimer"
        element={<DisclaimerPage />}
        name={<FormattedMessage {...T("footer.disclaimer")} />}
      />
      <Route exact path="/imprint" element={<ImprintPage />} />
      <Route path="/devtools" element={<DevTools />} />
      <Route path="/videodev" element={<VideoDebug />} />
      <Route
        exact
        path="/terms"
        element={<TermsPage />}
        name={<FormattedMessage {...T("common.terms")} />}
      />
    </Route>

    <Route
      path="/login"
      Component={
        fflags.variation('maintenance-mode', false) ? MaintenancePage : LandingPage
      }
    />

  </Routes>
