import React from 'react'
import ReactSlider from 'react-slider'

const Slider = ({ value, min, max, step, onChange }) => {
  if (!onChange) {
    onChange = () => {}
  }

  const onLocalChange = newValue => {
    onChange(newValue, value)
  }

  return (
    <div className="strip">
      <ReactSlider
        orientation="vertical"
        className="strip__line"
        value={value}
        min={min}
        max={max}
        invert={true}
        step={step}
        onChange={onLocalChange}
        thumbClassName="strip__handler removeOutline"
        renderThumb={(props) => <div {...props}>
        <i className="fas fa-minus" />
      </div>}
      />
    </div>
  )
}

export default Slider
