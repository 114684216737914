import styled from 'styled-components'
import MainHeader from '../../../assets/img/landing_page_main_header.png'
import RegisterNowImage from '../../../assets/img/register_now_background.png'
import WaveBackgroundImage from '../../../assets/img/wave_background.png'

export const HeaderContainer = styled.div`
  background: #ffffff url(${MainHeader});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top center;
  margin: auto;
`

export const RegisterNowContainer = styled.div`
  background: #ffffff url(${RegisterNowImage});
  width: 100%;
  background-size: cover;
  margin: auto;
  height: 300px;
  padding: 45px 0 20px 0;
  background-repeat: no-repeat;
  border-radius: 15px;
`

export const HeadLineDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
`

export const HeadLineContentDiv = styled.div`
  margin-bottom: 3%;
  font-size: 1.2em;
`

export const StyledContainer = styled.div`
  margin-top: -130px;
  overflowX: 'hidden';
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;  
`

export const TeleCareDescription = styled.div`
  background: url(${WaveBackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 5% 0 10% 0;
`