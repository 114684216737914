import styled from 'styled-components'
import { ActionButtons } from '../'

export const PracticeManagementPage = styled.div`
  table {
    margin-top: 30px;

    td,
    th {
      padding: 5px !important;
    }
  }
`