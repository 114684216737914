import { getLocale } from 'helper/LocaleHelper'
import querystring from 'querystring-es3'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'
import { getAppVersion } from './TokenUtil';
/* global __API_URL__ */
class Connector {
  getBaseUrl() {
    let baseUrl = process.env.REACT_APP_API_URL || 'https://hcpportalapi.cloudapi-dev.sivantos.com';
    // fallback
    if (window && window.location.search) {
      const q = querystring.parse(window.location.search.substring(1))
      if (q.setBaseUrl && window.localStorage) {
        window.localStorage.setItem('baseUrl', q.setBaseUrl)
      }

      if (q.clearBaseUrl && window.localStorage) {
        window.localStorage.removeItem('baseUrl')
      }
    }

    if (window.localStorage) {
      const savedBaseurl = localStorage.getItem('baseUrl')
      if (savedBaseurl !== null && savedBaseurl !== "null") {
        return savedBaseurl
      }
    }
    return baseUrl
  }

  url(str = '') {
    if (str.charAt(0) !== '/') str = '/' + str
    return this.getBaseUrl() + str
  }

  apiUrl(str) {
    return `${this.getBaseUrl()}/api/rest${str}`
  }

  getEntities(token, entityType, page, size) {
    return new Promise((resolve, reject) => {
      if (page == null) {
        page = 0
      }
      if (size == null) {
        size = 1000
      }
      fetch(this.apiUrl(`/${entityType}?page=${page}&size=${size}`), {
        method: 'get',
        headers: this.generateHeader(token)
      }).then(result => {
        if (result.status == 200) {
          // Examine the text in the response
          result.json().then(data => {
            const res = data
            resolve(res)
          })
        } else {
          reject(result)
        }
      })
    })
  }

  getEntity(token, entityType, id) {
    return new Promise((resolve, reject) => {
      fetch(this.apiUrl(`/${entityType}/${id}`), {
        method: 'get',
        headers: this.generateHeader(token)
      })
        .then(result => {
          if (result.status === 200) {
            // Examine the text in the response
            result.json().then(data => {
              resolve(data)
            })
          } else {
            reject(result)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  createEntity(token, entityType, data) {
    return new Promise((resolve, reject) => {
      fetch(this.apiUrl(`/${entityType}`), {
        method: 'post',
        headers: this.generateHeader(token, 'application/json'),
        body: JSON.stringify(data)
      })
        .then(result => {
          if (result.status == 201) {
            // Examine the text in the response
            result.json().then(value => {
              resolve(value)
            })
          } else {
            reject({
              status: result.status,
              error: result.statusText
            })
          }
        })
        .catch(err => {
          reject(err)
          console.warn(err)
        })
    })
  }

  doLogin(username, password) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('grant_type', 'password')
      formData.append('username', username)
      formData.append('password', password)
      formData.append('client_id', 'mobile-application')

      return fetch(this.url('/oauth/token'), {
        method: 'post',
        body: formData
      })
        .then(result => {
          if (result.status === 200) {
            resolve(result.json())
          } else {
            reject(result)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  // no usage
  registerShop(data) {
    return new Promise((resolve, reject) => {
      fetch('/api/custom/hcp/register-hcp', {
        method: 'post',
        headers: this.generateHeader(null, 'application/json'),
        body: JSON.stringify(data)
      })
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result.json().then(value => {
              resolve(value)
            })
          } else {
            result.json().then(value => {
              reject(
                {
                  ...value
                },
                error => {
                  console.error(error)
                  reject({
                    ...value
                  })
                }
              )
            })
          }
        })
        .catch(err => {
          reject(err)
          console.warn(err)
        })
    })
  }

  doLogout() {
    return fetch('./auth?action=logout', {
      method: 'get',
      credentials: 'same-origin'
    }).then(result => {
      if (result.status == 200) {
        return result.json()
      } else {
        throw new Error('Logout failed!')
      }
    })
  }

  requestUrl(token, url, method = 'get') {
    const headers = this.generateHeader(token)
    const data = { method, headers, cache: 'no-store' }
    url = this.url(url)
    return new Promise((resolve, reject) => {
      fetch(url, data)
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            if (result.status == 200) {
              const contentType = result.headers.get('content-type')
              let responsePromise
              if (contentType && contentType.indexOf('application/json') !== -1) {
                responsePromise = result.json()
              } else {
                responsePromise = result.text()
              }

              responsePromise
                .then(value => {
                  resolve(value)
                })
                .catch(error => {
                  console.log(error)
                  resolve(true)
                })
            } else {
              resolve(result)
            }
          } else {
            reject(result)
          }
        })
        .catch(err => {
          console.warn(err)
          reject(err)
        })
    })
  }

  generateOptions(token, method, data) {
    const headers = this.generateHeader(token, 'application/json')

    if (method === 'get') {
      return {
        method,
        headers,
        body: data
      }
    } else {
      return {
        method,
        headers,
        body: JSON.stringify(data)
      }
    }
  }

  putUrl(token, url, data) {
    return new Promise((resolve, reject) => {
      const method = 'put'
      return fetch(this.url(url), this.generateOptions(token, method, data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code
                })
              })
              .catch(() => {
                return reject(false)
                // reject({
                //   status: result.status,
                //   code: 'JSON_PARSE'
                // })
              })
          }
        })
        .catch(() => {
          reject({
            code: 'ERROR'
          })
        })
    })
  }

  postUrl(token, url, data) {
    return new Promise((resolve, reject) => {
      const method = 'post'
      return fetch(this.url(url), this.generateOptions(token, method, data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(() => {
          reject({
            code: 'ERROR'
          })
        })
    })
  }

  requestLinkUpdate(token, url, data) {
    return new Promise((resolve, reject) => {
      fetch(this.url(url), {
        method: 'PUT',
        headers: this.generateHeader(token, 'text/uri-list'),
        body: data
      })
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            resolve({})
          } else {
            reject({
              status: result.status,
              error: result.statusText
            })
          }
        })
        .catch(err => {
          reject(err)
          console.warn(err)
        })
    })
  }

  uploadAvatar(token, url, data) {
    const formData = new FormData()

    Object.keys(data).forEach(field => {
      formData.append(field, data[field])
    })

    return fetch(this.url(url), {
      method: 'post',
      headers: this.generateHeader(token),
      body: formData
    })
  }

  uploadSelfCompleteAvatar(url, data) {
    const formData = new FormData()

    Object.keys(data).forEach(field => {
      formData.append(field, data[field])
    })

    return fetch(this.url(url), {
      method: 'post',
      headers: this.generateHeader(),
      body: formData
    })
  }

  uploadFile(token, url, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      Object.keys(data).forEach(field => {
        formData.append(field, data[field])
      })

      return fetch(this.url(url), {
        method: 'post',
        headers: this.generateHeader(token),
        body: formData
      }).then(result => {
        if (result.status === 200) {
          resolve('')
        } else {
          reject(result)
        }
      })
    })
  }

  generateHeader(token, contentType) {
    const headers = {}
    headers.Accept = 'application/json'
    headers['Accept-Language'] = getLocale()
    headers['Client-Version'] = getAppVersion();

    if (contentType) {
      headers['Content-Type'] = contentType
    }
    return new Headers(headers)
  }

  get(token, endpoint, data = {}) {
    const query =
      Object.keys(data).length > 0 ? `?${decodeURIComponent(querystring.stringify(data))}` : ''

    return new Promise((resolve, reject) => {
      return fetch(this.url(`${endpoint}${query}`), this.generateOptions(token, 'get'))
        .then(result => {
          this.processGetResult(result, resolve, reject)
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
    })
  }
  generateHeaderWithCorrelation(token, contentType, correlation) {
    const headers = {}
    headers.Accept = 'application/json'
    headers['Accept-Language'] = getLocale()

    if (contentType) {
      headers['Content-Type'] = contentType
    }
    if (correlation) {
      headers['Correlation-Context'] = correlation
    }
    return new Headers(headers)
  }

  generateOptionsWithCorrelation(token, method, data, correlation) {
    const headers = this.generateHeaderWithCorrelation(token, 'application/json', correlation)
    if (method === 'get') {
      return {
        method,
        headers
      }
    } else {
      return {
        method,
        headers,
        body: JSON.stringify(data)
      }
    }
  }

  processErrorResponse(result, reject) {
    result
      .json()
      .then(value => {
        reject({
          status: result.status,
          code: value.error_code,
          message: value.message
        })
      })
      .catch(() => {
        return reject(false)
      })
  }

  processGetResult(result, resolve, reject) {
    if (result.status >= 200 && result.status < 300) {
      // Examine the text in the response
      result
        .json()
        .then(value => {
          resolve(value)
        })
        .catch(error => {
          console.log(error)
          resolve(true)
        })
    } else {
      this.processErrorResponse(result, reject)
    }
  }
  getWithCorrelation(token, endpoint, correlationContext, data = {}) {
    FlushEventsToAppInsight('Calling Backend Api with context : ' + correlationContext)

    const query =
      Object.keys(data).length > 0 ? `?${decodeURIComponent(querystring.stringify(data))}` : ''
    return new Promise((resolve, reject) => {
      return fetch(
        this.url(`${endpoint}${query}`),
        this.generateOptionsWithCorrelation(token, 'get', data, correlationContext)
      )
        .then(result => {
          this.processGetResult(result, resolve, reject)
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
    })
  }

  processPostResult(result, resolve, reject) {
    if (result.status >= 200 && result.status < 300) {
      // Examine the text in the response
      const contentType = result.headers.get('content-type')
      let responsePromise
      if (contentType && contentType.indexOf('application/json') !== -1) {
        responsePromise = result.json()
      } else {
        responsePromise = result.text()
      }

      responsePromise
        .then(value => {
          resolve(value)
        })
        .catch(error => {
          console.log(error)
          resolve(true)
        })
    } else {
      this.processErrorResponse(result, reject)
    }
  }

  postWithCorrelation(token, endpoint, correlationContext, data = {}) {
    FlushEventsToAppInsight('Calling Post Backend Api with context : ' + correlationContext)
    return new Promise((resolve, reject) => {
      return fetch(
        this.url(endpoint),
        this.generateOptionsWithCorrelation(token, 'post', data, correlationContext)
      )
        .then(result => {
          this.processPostResult(result, resolve, reject)
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
    })
  }

  post(token, endpoint, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(this.url(endpoint), this.generateOptions(token, 'post', data))
        .then(result => {
          this.processPostResult(result, resolve, reject)
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
    })
  }

  processPutResult(result, resolve, reject) {
    if (result.status >= 200 && result.status < 300) {
      // Examine the text in the response
      result
        .json()
        .then(value => {
          resolve(value)
        })
        .catch(error => {
          console.log(error)
          resolve(true)
        })
    } else {
      this.processErrorResponse(result, reject)
    }
  }
  putWithCorrelation(token, endpoint, correlationContext, data = {}) {
    FlushEventsToAppInsight('Calling Put Backend Api with context : ' + correlationContext)
    return new Promise((resolve, reject) => {
      return fetch(
        this.url(endpoint),
        this.generateOptionsWithCorrelation(token, 'put', data, correlationContext)
      )
        .then(result => {
          this.processPutResult(result, resolve, reject)
        })
        .catch(error => console.warn(error))
    })
  }

  put(token, endpoint, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(this.url(endpoint), this.generateOptions(token, 'put', data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message || value.description
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }

  delete(token, endpoint, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(this.url(endpoint), this.generateOptions(token, 'delete', data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(() => {
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }

  getRequest(token, endpoint, baseurl, data = {}) {
    const query =
      Object.keys(data).length > 0 ? `?${decodeURIComponent(querystring.stringify(data))}` : ''

    return new Promise((resolve, reject) => {
      return fetch(baseurl + `${endpoint}${query}`, this.generateOptions(token, 'get'))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }
  postRequest(token, endpoint, baseurl, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(baseurl + `${endpoint}`, this.generateOptions(token, 'post', data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            const contentType = result.headers.get('content-type')
            let responsePromise
            if (contentType && contentType.indexOf('application/json') !== -1) {
              responsePromise = result.json()
            } else {
              responsePromise = result.text()
            }

            responsePromise
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }
  putRequest(token, endpoint, baseurl, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(baseurl + `${endpoint}`, this.generateOptions(token, 'put', data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(error => {
                console.log(error)
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }
  deleteRequest(token, endpoint, baseurl, data = {}) {
    return new Promise((resolve, reject) => {
      return fetch(baseurl + `${endpoint}`, this.generateOptions(token, 'delete', data))
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            result
              .json()
              .then(value => {
                resolve(value)
              })
              .catch(() => {
                resolve(true)
              })
          } else {
            result
              .json()
              .then(value => {
                reject({
                  status: result.status,
                  code: value.error_code,
                  message: value.message
                })
              })
              .catch(() => {
                return reject(false)
              })
          }
        })
        .catch(error => console.warn(error))
    })
  }
}

export default new Connector()
