import React from 'react'
import * as Yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { checkUsername, checkOrgEmailExists, checkSivantosIdUnique } from 'actions/authentication'
import { PhoneNumberUtil } from 'google-libphonenumber'
import zxcvbn from 'zxcvbn'
import { urlRegex } from 'helper/constants'

const util = PhoneNumberUtil.getInstance()

Yup.addMethod(Yup.string, 'isValidSivantosId', function (message) {
  const msg = message || <FormattedMessage { ...T('validator.regex') } />
  return this.test('isValidSivantosIdTest', msg, function (value) {
    if (value) {
      const sivantosIdRegEx = /^[a-z0-9]{9}$/i
      return sivantosIdRegEx.test(value)
    }
  })
})

Yup.addMethod(Yup.string, 'isSivantosIdUnique', function (shouldValidate, currentValue, message) {
  const msg = message || <FormattedMessage { ...T('register.accountNumberAlreadyUsed') } />
  return this.test('isValidSivantosIdTest', msg, async function (value) {
    if (!shouldValidate)
      return true
    if (value) {
      if (value !== currentValue) {
        try {
          await checkSivantosIdUnique(value)
        } catch (error) {
          return false
        }
      }
      return true
    }
  })
})

Yup.addMethod(Yup.object, 'uploadIsValid', function (message) {
  const msg = message || <FormattedMessage { ...T('validator.fileuploadInvalid') } />
  return this.test('uploadIsValidTest', msg, function (value) {
    if (value) {
      return !value.hasError
    }
  })
})

Yup.addMethod(Yup.object, 'uploadMaxFilesize', function (fileSizeKb, message) {
  const msg =
    message ||
    <FormattedMessage
    { ...T('validator.fileuploadSize') }
  values = {{
    size: fileSizeKb
  }
}
  />
  return this.test('uploadMaxFilesizeTest', msg, function (value) {
    if (value) {
      return !value.size || value.size / 1000 <= fileSizeKb
    }
  })
})

Yup.addMethod(Yup.object, 'uploadImageMinDimensions', function (width, height, message) {
  const msg =
    message ||
    <FormattedMessage
    { ...T('validator.fileuploadImageMinDimensions') }
  values = {{
    width,
      height
  }
}
  />
  return this.test('uploadImageMinDimensionsTest', msg, function (value) {
    if (value) {
      return !value.image || (value.image.width >= width && value.image.height >= height)
    }
  })
})

Yup.addMethod(Yup.string, 'isValidZipCode', function (countries, countryCode, message) {
  const msg = message || <FormattedMessage { ...T('validator.regex') } />
  return this.test('isValidZipCodeTest', msg, function (value) {
    if (value) {
      const country = countries.find(c => c.countryCode === countryCode)
      const zipCodeRegex = (country && country.zipCodeValidation) || '.*'
      const zipRegex = new RegExp(zipCodeRegex)
      return zipRegex.test(value)
    }
  })
})

Yup.addMethod(Yup.string, 'orgEmailIsUnique', function (shouldValidate, currentValue, message) {
  const msg = message || <FormattedMessage { ...T('error_messages.EMAIL_ALREADY_EXISTS_EMPLOYEE') } />
  return this.test('orgEmailIsUniqueTest', msg, async value => {
    if (!shouldValidate)
      return true
    if (value) {
      if (value !== currentValue) {
        try {
          await checkOrgEmailExists(value)
        } catch (error) {
          return false
        }
      }
      return true
    }
  })
})


Yup.addMethod(Yup.string, 'usernameIsUnique', function (currentValue, message, shouldValidate = true) {
  const msg = message || <FormattedMessage { ...T('error_messages.USERNAME_ALREADY_EXISTS') } />  
return this.test('usernameIsUniqueTest', msg, async function (value) {
    if (!shouldValidate)
      return true
    if (value) {
      if (value !== currentValue) {
        try {
          await checkUsername(value)
        } catch (error) {
          return false
        }
      }
      return true
    }
  })
})

Yup.addMethod(Yup.string, 'lessonSetNameIsUnique', function (
  currentLessonSetName,
  existingLessonSets,
  message
) {
  const msg = message || <FormattedMessage { ...T('remoteTuning.existingPresets') } />
  return this.test('lessonSetNameIsUniqueTest', msg, function (value) {
    if (value) {
      if (value !== currentLessonSetName) {
        const lessonSet = existingLessonSets.find(l => l.name.toUpperCase() == value.toUpperCase())
        if (lessonSet) {
          return false
        }
      }
      return true
    }
  })
})

Yup.addMethod(Yup.string, 'equalTo', function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function (value) {
      return value === this.resolve(ref)
    }
  })
})

Yup.addMethod(Yup.string, 'isPasswordValid', function (message) {
  const msg = message || <FormattedMessage { ...T('min.strength') } />
  return this.test('isPasswordValidTest', msg, function (value) {
    if (value) {
      const score = zxcvbn(value).score
      if (parseInt(score) < 2) {
        return false
      }
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'isPhoneNoValidForRegion', function (ref, message) {
  const msg = message || <FormattedMessage { ...T('validator.phone') } />
  return this.test('isPhoneNoValidForRegionTest', msg, function (value) {
    const countryCode = this.resolve(ref)
    if (value && value !== '') {
      try {
        const number = util.parseAndKeepRawInput(value, countryCode)
        if (!util.isValidNumberForRegion(number, countryCode)) {
          throw new Error()
        }
      } catch (e) {
        return false
      }
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'isPhoneNoValid', function (message) {
  const msg = message || <FormattedMessage { ...T('validator.phone') } />
  return this.test('isPhoneNoValiForRegionTest', msg, function (value) {
    if (value && value !== '') {
      try {
        const number = util.parseAndKeepRawInput(value)
        if (!util.isValidNumber(number)) {
          throw new Error()
        }
      } catch (e) {
        return false
      }
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'isUrlValid', function (message) {
  const msg = message || <FormattedMessage { ...T('validator.url') } />
  return this.test('isUrlValidTest', msg, function (value) {
    if (!value || value == '') {
      return true
    }
    let regex = new RegExp(urlRegex)
    return regex.test(value)
  })
})

Yup.addMethod(Yup.mixed, 'isMobileNumberEmpty', function isMobileNumberEmpty(ref, msg = "Mobile number can not be empty") {
  return this.test('isMobileNumberEmpty', msg, function (value) {
    return !(!this.resolve(ref) && value === 'SMS');
  })
})