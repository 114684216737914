import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import { generateRandomFileName } from 'helper/Utils'

const FILE_VALUE_DEFAULT = {
  hasUserSelection: false, // file selected by user
  hasInitialSelection: false, // file by previous input value
  initialSelection: null, // previous value from input
  shouldDelete: false, // if the user wants to remove the uploaded file
  hasError: false // is file invalid
}

class FileUploadField extends Component {
  componentDidMount() {
    // Felix: setTimeout is a necessary workaround (https://github.com/jaredpalmer/formik/issues/930)
    setTimeout(() => {
      this.handleInputValue(this.props.field)
    }, 0)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.field.value && !this.props.field.value) {
      // Felix: setTimeout is a necessary workaround (https://github.com/jaredpalmer/formik/issues/930)
      setTimeout(() => {
        this.handleInputValue(nextProps.field)
      }, 0)
    }
  }

  handleFileDrop = event => {
    event.stopPropagation()
    event.preventDefault()

    const isReadOnly = this.props.isReadOnly
    if (isReadOnly) {
      return
    }
    const files = event.dataTransfer && event.dataTransfer.files
    if (files) this.processFiles(files)
  }

  handleFileSelect = event => {
    const files = event.target && event.target.files
    this.processFiles(files)
  }

  handleOnClick = () => {
    const isReadOnly = this.props.isReadOnly
    if (isReadOnly) {
      return
    }
    this.fileinput.click()
  }

  handleDelete = event => {
    event.stopPropagation()
    event.preventDefault()

    const {
      field: { value: { hasUserSelection, hasInitialSelection, initialSelection } }
    } = this.props

    // otherwise no "onChange" is triggered
    this.fileinput.value = ''

    // if the user has made a selection, deleting will revert to initial selection
    // if the user has made no selection and an initial selection exists, mark for delete
    const fileInfo = {
      ...FILE_VALUE_DEFAULT,
      initialSelection,
      hasInitialSelection: hasUserSelection && initialSelection,
      shouldDelete: hasInitialSelection
    }

    this.props.form.setFieldValue(this.props.field.name, fileInfo)
  }

  handleInputValue(field) {
    if (!field) return
    this.props.form.setFieldValue(field.name, {
      ...FILE_VALUE_DEFAULT,
      data: field.value.data || null,
      hasError: field.value.hasError || false,
      initialSelection:
        typeof field.value == 'string' && field.value.length > 0 ? field.value : null,
      hasInitialSelection: typeof field.value === 'string' ? !!field.value : false,
      hasUserSelection: field.value.hasUserSelection
    })
  }

  readFile(file) {
    const { allowedMimeTypes = [], allowedExtensions = [], preprocessors = [] } = this.props

    const mimeIsAllowed = mimeType => file.type.match(mimeType)

    const extensionIsAllowed = extension =>
      file.name.split('.').pop().toLowerCase() === extension.toLowerCase()

    let fileInfo = this.props.field || {}

    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      if (
        (allowedMimeTypes.length && !_.filter(allowedMimeTypes, mimeIsAllowed).length) ||
        (allowedExtensions.length && !_.filter(allowedExtensions, extensionIsAllowed).length)
      ) {
        reject()
      } else {
        reader.readAsDataURL(file)
        reader.onload = () => {
          fileInfo.hasUserSelection = true
          fileInfo.hasError = false

          fileInfo.data = reader.result

          if (preprocessors.length) {
            Promise.all(preprocessors.map(preprocessor => preprocessor(fileInfo)))
              .then(preprocessVals => {
                preprocessVals.forEach(val => {
                  fileInfo = {
                    ...fileInfo,
                    ...val
                  }
                })
                resolve(fileInfo)
              })
              .catch(() => {
                reject('parseError')
              })
          } else {
            resolve(fileInfo)
          }
        }

        reader.onerror = () => {
          reject('invalidFormat')
        }

        reader.onabort = () => {
          reject('canceledUpload')
        }
      }
    })
  }
  processFiles(files = []) {
    const { field: { name, value }, form: { setFieldValue } } = this.props
    // initializes to the current value or to the default object structure
    let fileInfo = {
      ...(_.isPlainObject(value) ? value : FILE_VALUE_DEFAULT)
    }

    if (files.length) {
      fileInfo.hasUserSelection = true
      fileInfo.shouldDelete = false
      fileInfo.hasError = false
      fileInfo.errorKey = null

      if (files.length === 1) {
        const file = files[0]
        fileInfo.size = file.size || file.fileSize
        fileInfo.name = generateRandomFileName(file.name, 5)
        fileInfo.mimeType = file.type

        this.readFile(file)
          .then(newfileInfo => {
            fileInfo = {
              ...fileInfo,
              ...newfileInfo
            }
            setFieldValue(name, fileInfo)
          })
          .catch(errorKey => {
            setFieldValue(name, {
              ...fileInfo,
              errorKey,
              hasError: true
            })
          })
        return
      }
      // multiple files selected
      fileInfo.hasError = true
      fileInfo.errorKey = 'multipleFiles'
    }

    setFieldValue(name, fileInfo)
  }

  shouldRenderEmptyArea() {
    const {
      field: { value: { hasError, hasUserSelection, hasInitialSelection, data, initialSelection } }
    } = this.props
    return (!data || !initialSelection) && !hasError && !hasUserSelection && !hasInitialSelection
  }

  shouldRenderInvalidArea() {
    const { field: { value: { hasError, hasUserSelection } } } = this.props
    return hasError && hasUserSelection
  }

  shouldRenderPreviewData() {
    const { field: { value: { hasError, hasUserSelection, hasInitialSelection } } } = this.props
    return !hasError && (hasUserSelection || hasInitialSelection)
  }

  shouldRenderDeleteButton() {
    const {
      field: { value: { hasUserSelection, hasInitialSelection } },
      disableDelete,
      isReadOnly
    } = this.props
    return !disableDelete && !isReadOnly && (hasUserSelection || hasInitialSelection)
  }

  shouldRenderStatusMessage() {
    const {
      isReadOnly,
      field: { value: { hasError, hasUserSelection, hasInitialSelection } }
    } = this.props

    return !hasError && !isReadOnly && (hasUserSelection || hasInitialSelection)
  }

  renderInvalidArea() {
    const { invalidIconClass, invalidMessage } = this.props
    return (
      <div className="invalid">
        <i className={invalidIconClass} />
        <p>
          {invalidMessage}
        </p>
      </div>
    )
  }

  renderEmptyArea() {
    const { emptyIconClass, emptyMessage } = this.props
    return (
      <div className="empty">
        <i className={emptyIconClass} />
        <p>
          {emptyMessage}
        </p>
      </div>
    )
  }

  renderPreview() {
    const {
      previewIcon,
      canDisplayInitialValue,
      canDisplayParsedValue,
      field: { name, value: { data, initialSelection, hasUserSelection, hasInitialSelection } }
    } = this.props
    let previewUrl
    if (hasUserSelection && canDisplayParsedValue) {
      // parsed filedata can be displayed
      previewUrl = data
    } else if (hasInitialSelection && canDisplayInitialValue) {
      // previous input value can be displayed
      previewUrl = initialSelection
    }

    if (name === 'video') {
      return (
        <div className="preview">
          <video src={initialSelection} width="100%" height="100%" />
          <i
            className={!previewUrl && previewIcon}
            style={{ position: 'relative', top: '-100px' }}
          />
        </div>
      )
    }
    if (previewUrl) {
      return (
        <div className="preview" style={{ backgroundImage: `url(${previewUrl})` }}>
          <i className={!previewUrl || previewIcon} />
        </div>
      )
    }
  }

  render() {
    const { field, label, hint, form: { errors }, accept, validMessage, isReadOnly } = this.props
    return (
      <div className={`form-group ${errors[field.name] ? 'has-error' : ''}`}>
        <label htmlFor={field.name} className="control-label">
          {label}
        </label>

        <div
          className={`dndzone ${isReadOnly ? 'disabled' : ''}`}
          onDrop={this.handleFileDrop}
          onDragOver={event => {
            if (!isReadOnly) {
              event.dataTransfer.dropEffect = 'copy'
            }
          }}
          onClick={this.handleOnClick}
        >
          {this.shouldRenderEmptyArea() && this.renderEmptyArea()}
          {this.shouldRenderInvalidArea() && this.renderInvalidArea()}
          {this.shouldRenderPreviewData() && this.renderPreview()}
          {this.shouldRenderStatusMessage() &&
            <p className="statusMessage">
              {validMessage}
            </p>}
          {this.shouldRenderDeleteButton() &&
            <button type="button" className="deleteButton" onClick={this.handleDelete}>
              <i className="fas fa-trash fa-2x" />
            </button>}
          <input
            type="file"
            name={field.name}
            ref={c => {
              this.fileinput = c
            }}
            accept={accept}
            onChange={this.handleFileSelect}
          />
        </div>
        {(errors[field.name] || hint) &&
          <div className="help-block" data-qa={field.name + '-help-text'}>
            {errors[field.name] || hint}
          </div>}
      </div>
    )
  }
}

FileUploadField.propTypes = {
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  preprocessors: PropTypes.arrayOf(PropTypes.func),
  label: PropTypes.string,
  hint: PropTypes.string,
  emptyIconClass: PropTypes.string,
  emptyMessage: PropTypes.node,
  invalidIconClass: PropTypes.string,
  invalidMessage: PropTypes.node,
  previewIcon: PropTypes.string,
  validMessage: PropTypes.node, // Statusmessage for valid upload
  accept: PropTypes.string,
  disableDelete: PropTypes.bool,
  canDisplayInitialValue: PropTypes.bool, // can display the initial value as an image url
  canDisplayParsedValue: PropTypes.bool // can display base64 parsed value of fileupload as image url
}

export default FileUploadField
