import styled from 'styled-components'

export const CheckboxLabel = styled.label`
  cursor: pointer;
  margin-right: 10px;
  color: ${props =>
    props.disabled
      ? props.theme.colors.elementDisabled
      : props.theme.colors.black};
  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

export const StyledCheckboxLabel = styled(CheckboxLabel)`
  font-weight: bold;
`
