import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

export class HearingInstrumentHeadLine extends Component {
  render() {
  const { error } = this.props
    return (
      <div className="row">
        <div className="col-lg-12">
          {error &&
            <div className="alert alert-danger" role="alert">
              {error}
            </div>}
          <h1 data-qa="title">
            <FormattedMessage {...T(`${'patientData.hearingInstrument'}`)} />
          </h1>
        </div>
      </div>
    )
  }
}