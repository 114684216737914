import { gql } from "@apollo/client";

export const QUERY_HDMODELS = gql`
  query DeviceModelsQuery($brand: String!, $hasBrand: Boolean!) {
    HDModels(brand: $brand) @include(if: $hasBrand) {
      id
      manufacturer
      model
    }
  }
`