import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { T } from 'core'
import SortableTableHeader from 'components/SortableTableHeader'
import { injectIntl } from 'react-intl'
import { checkPermission } from 'helper/AuthHelper'
import { compose } from 'lodash/fp'
import { shopTracking } from 'helper/shopTracking'
import ConfirmDeletionPopup from 'components/Popups/ConfirmDeletionPopup'
import { UPDATE_OVERLAY, SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { EMPLOYEES_QUERY } from 'graphql/queries/EmployeesQuery'
import Tooltip from 'react-tooltip'
import {
  SHOW_POPUP,
  DELETE_PATIENT_POPUP,
  P_DEFAULT_POPUP,
  P_SUCCESS,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'
import { MUTATION_RESEND_INVITE_EMPLOYEE } from 'graphql/mutations/InviteEmployeeMutation'
import { CorrelationContext } from 'helper/constants'
import { showErrorPopup } from 'components/Popups/helper/ShowErrorPopup'
import { getSupportId } from 'helper/ApplicationInsightSupportId'
import Loading from 'components/Loading'
import { StyledTable } from "styledComponents/Table/StyledTable";
import { faInfoCircle, faTrash, faRedo } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'
import { sendDataToDataCollection } from '../../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from '../../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../../helper/DataCollection/DataCollectionConstants'
import { DataCollectionType } from 'helper/constants'

let SupportId = getSupportId()

export class InvitationTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  setSort = sortField => () => {
    this.props.setSort(sortField)
  }

  closeConfirmationPopup = () => {
    this.props.updateOverlay(null, null, false)
  }

  handleDelete = employeeId => {
    this.closeConfirmationPopup()
    this.props
      .deleteEmployee({ employeeId })
      .then((result) => {
        if (result.errors) {
          const { message } = result.errors[0]
          const errorCode = message.match('code: (.*),')
          if (errorCode) {
            const popup = getOverlayUpdateData(SHOW_POPUP, {
              type: P_DEFAULT_POPUP,
              payload: {
                title: 'employeeEdit.errorDeleteFailed',
                message: errorCode[1].replace(/\"/g, "")
              }
            })
            this.props.updateOverlay({ ...popup }, true, true)
          }
        }
        shopTracking({
          ...this.props.location,
          action: 'userDeleted'
        })
        sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Employee), EventType.Delete)
        this.props.refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  showConfirmationPopup = id => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: DELETE_PATIENT_POPUP + id,
        payload: {
          title: 'employeeEdit.confirmDeletionHeader',
          message: 'employeeEdit.confirmDelete'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }

  showInvitationSuccessPopup = () => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: P_SUCCESS,
        payload: {
          title: 'popup_success.title',
          message: 'employeeInvitation.inviteSuccessful'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }

  showInvitationFailedPopup = () => {
    showErrorPopup(
      'popup_fail.title',
      'employeeInvitation.inviteFailed',
      this.props.updateOverlay
    )
  }

  onResendInvite = (email) => {
    this.setState({ loading: true })
    this.props.resendInviteEmployee({ email }).then(result => {
      let { data: { resendInviteEmployee: { success } } } = result
      success ? this.showInvitationSuccessPopup() : this.showInvitationFailedPopup()
    }).finally(() => {
      this.props.refetch()
      this.setState({ loading: false })
    })
  }

  render() {
    const { sort, employees = [], Me, intl: { formatMessage } } = this.props

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <StyledTable className="table--users">
          <thead>
            <tr>
              <SortableTableHeader
                className="col-4"
                name="email"
                onClick={this.setSort('email')}
                value={formatMessage(T('common.email'))}
                sort={sort}
              />
              <th name="role" className="col-4">
                <FormattedMessage {...T('employeeInvitation.expiryDate')} />
              </th>

              <th name="role" className="col-4">
                <FormattedMessage {...T('common.role')} />
              </th>
              <th className="text-center col-1">&nbsp;</th>
              <th className="text-center col-1">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((item, index) => {
              return <tr key={`${index}__manage-row`}>
                <td>
                  {item.email || item.username}
                </td>
                <td>
                  {item.tokenValidUntil && (new Date(item.tokenValidUntil)).toLocaleDateString()}
                </td>
                <td>
                  {item.orgManager
                    ? <FormattedMessage {...T('form.org-manager')} />
                    : item.manager ? <FormattedMessage {...T('common.manager')} /> : ` `}
                </td>
                <td className="text-center">
                  {checkPermission('ADD_EMPLOYEE', { Me, employee: item }) ?
                    <StyledFontAwesomeIcon
                      icon={faRedo}
                      fixedWidth
                      type="redo"
                      onClick={() => this.onResendInvite(item.email)}
                      data-qa="manage__resend-button"
                      data-tip
                      data-for={`tooltip-resend-${item.id}`}
                    />

                    : <Link
                      data-qa="manage__view-button"
                      to={`/employee/${item.id}/view`}
                      data-tip
                      data-for={`tooltip-view-${item.id}`}
                    >
                      <StyledFontAwesomeIcon icon={faInfoCircle} type="info" />
                    </Link>
                  }
                  <Tooltip id={`tooltip-resend-${item.id}`}>
                    {`${formatMessage(T('employeeInvitePage.sendButton'))} ${item.email ?? ''}`}
                  </Tooltip>

                </td>
                <td className="text-center">
                  {checkPermission('DELETE_EMPLOYEE', { Me, employee: item }) &&
                    <ConfirmDeletionPopup
                      openByClickOn={(onClick) =>
                        <StyledFontAwesomeIcon
                          icon={faTrash}
                          fixedWidth
                          type="delete"
                          data-qa="manage__delete-button"
                          data-tip
                          data-for={`tooltip-delete-${item.id}`}
                          onClick={onClick}
                        />
                      }
                      removePatient={() => this.handleDelete(item.id)}
                      onOpen={() => this.showConfirmationPopup(item.id)}
                      onClose={this.closeConfirmationPopup}
                      id={item.id}
                    />}
                  <Tooltip id={`tooltip-delete-${item.id}`}>
                    {`${formatMessage(T('common.delete'))} ${item.email ?? ''}`}
                  </Tooltip>
                </td>
              </tr>
            }
            )}
          </tbody>
        </StyledTable>
      </>
    )
  }
}

const MUTATION_EMPLOYEE_DELETE = gql`
  mutation deleteEmployeeMutation($employeeId: Int!) {
    deleteEmployee(employeeId: $employeeId)
  }
`

const mapPopupProps = ({ data: { overlay } }) => {
  return { overlay }
}

export const options = () => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    correlationContext: CorrelationContext['Employee_Invite'] + SupportId,
  }
})

const withData = compose(
  graphql(MUTATION_EMPLOYEE_DELETE, {
    props: ({ mutate }) => ({
      deleteEmployee: employeeId =>
        mutate({
          variables: employeeId,
          refetchQueries: [
            {
              query: EMPLOYEES_QUERY,
              variables: { practiceId: employeeId, orgId: parseInt(getOrganizationIdFromCache()) }
            }
          ]
        })
    })
  }),
  graphql(MUTATION_RESEND_INVITE_EMPLOYEE, {
    props: ({ mutate }) => ({
      resendInviteEmployee: variables =>
        mutate({
          variables
        })
    }),
    options
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: mapPopupProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  injectIntl
)

export default withData(InvitationTable)
