import React from "react";
import styled, { css } from "styled-components";
import Pagination from "react-js-pagination";

const StyledDiv = styled.div((props) => {
    const {
      theme: {
        colors: {
          pagination: {
            active: paginationBackgroundActive,
            default: paginationBackgroundDefault,
            color: paginationFontColorActive,
            defaultColor: paginationFontColorDefault,
          },
        },
      },
    } = props;
    return css`
      .pagination {
        .active {
          a {
            z-index: 3;
            color: ${paginationFontColorActive};
            background-color: ${paginationBackgroundActive};
            border-color: ${paginationBackgroundActive};
            cursor: default;
          }
        }

        li {
          display: flex;
          a {
            position: relative;
            padding: 6px 12px;
            line-height: 1.42857143;
            text-decoration: none;
            color: ${paginationFontColorDefault};
            background-color: ${paginationBackgroundDefault};
            border-color: ${paginationBackgroundDefault};
            border: 1px solid #ddd;
            margin-left: -1px;
          }
        }
      }
    `;
  });
export const StyledPagination = (paginationProps) => {
  return (
    <StyledDiv>
      <Pagination {...paginationProps} />
    </StyledDiv>
  );
};
