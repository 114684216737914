import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import Loading from 'components/Loading'
import ReconnectCode from 'components/ReconnectCode'
import { T } from 'core'
import { parsePhone } from 'helper/FormHelper'
import { customerTracking } from 'helper/customerTracking'
import { get, isNull } from 'lodash'
import React from 'react'
import { compose } from 'lodash/fp'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Button, LinkButton } from 'styledComponents/Button'
import { UPDATE_POPUP } from 'graphql/queries/ShowPopUpQuery'
import InputField from 'formfields/InputField'
import { FETCH_ALL_COUNTRY_CALLING_CODES_QUERY } from '../../../graphql/queries/CountryCallingCodesQuery'
import {
  P_SUCCESS,
  SHOW_POPUP,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'
import { getToken } from 'helper/AuthHelper'
import { connector } from 'helper/Connector'
import { withRouter } from "helper/withRouter";
import { withQuery } from './../../../helper/withQuery'
import { getSupportId } from '../../../helper/ApplicationInsightSupportId'
import { CorrelationContext, DataCollectionType, PhoneNumberLengthForValidaton } from 'helper/constants'
import { sendDataToDataCollection } from "../../../helper/DataCollection/SendDataToDataCollectionUtil"
import { EventType } from '../../../helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData } from '../../../helper/DataCollection/PayloadData'
import DataContext from '../../DataContext';
;
let SupportId = getSupportId()


export class CustomerSetupPage extends React.Component {
  static displayName = 'CustomerSetupPage'
  static contextType = DataContext

  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      isCountryCodeBlocked: false
    }
  }

  componentDidUpdate() {
    const { loading } = this.props;
    const { initialized } = this.state
    if (!initialized && !loading) {
      const phone = isNull(this.props.Customer.phone) ? '' : this.props.Customer.phone
      this.setState({
        initialized: true,
        isCountryCodeBlocked: this.isCountryCodeBlocked(phone)
      })
    }
  }

  componentWillUnmount() {
    SupportId = getSupportId()
  }

  resendInvitation(patientId, phone) {
    const token = getToken()
    return connector.postWithCorrelation(
      token,
      `/hcp/api/v1/patients/${patientId}/webcode/send-sms`,
      CorrelationContext['ConnectToTelecareApp_SendWebCode'] + SupportId,
      { phone }
    )
  }

  onSendLinkSuccess() {
    const { intl: { formatMessage } } = this.props
    const title = formatMessage(T('common.success'))
    const message = formatMessage(T('customerSetupPage.linkIsSent'))
    const popup = getOverlayUpdateData(SHOW_POPUP, {
      type: P_SUCCESS,
      payload: { title, message }
    })
    this.props.updatePopup({ ...popup })
    sendDataToDataCollection(DataCollectionType.PatientData, PatientPayloadData(this.context.customer), EventType.SendWebCodeSms)
  }

  handleSendInvitationLink = values => {
    const { patientId, me } = this.props
    const countryCode = get(me, 'countryCode', '')
    const phone = parsePhone(values.phone, countryCode)

    this.sendInvite(patientId, phone)
      .then(() => {
        const { Customer, location } = this.props
        customerTracking({
          ...location,
          activeCustomer: Customer,
          action: 'customerConnectionCodeSent'
        })
        sendDataToDataCollection(DataCollectionType.PatientData, PatientPayloadData(this.context.customer), EventType.SendWebCodeSms)
        return Promise.resolve()
      })
      .then(() => this.onSendLinkSuccess())
  }

  sendInvite(patientId, phone) {
    return this.resendInvitation(patientId, phone)
  }

  customerSetupPageValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(this.props.intl.formatMessage(T('validator.required')))
      .isPhoneNoValid()
  })

  isCountryCodeBlocked(number) {
    if (!number) {
      return false;
    }
    const { CountryCallingCodes } = this.props;
    let validate = false;
    for (let element of CountryCallingCodes) {
      if (number.startsWith(element.countryCallingCode)) {
        validate = element.isCallingCodeBlocked;
        break;
      }
    }
    return validate;
  }

  handlePhoneNoFieldKeyUp = (value) => {
    if (value?.length > PhoneNumberLengthForValidaton) {
      this.setState({
        isCountryCodeBlocked: this.isCountryCodeBlocked(value)
      })
    }
    else {
      this.setState({
        isCountryCodeBlocked: false
      })
    }
  }

  renderResendLink(webCode) {
    const phone = isNull(this.props.Customer.phone) ? '' : this.props.Customer.phone
    const isInitialValid = phone ? true : false
    return (
      <Formik
        onSubmit={this.handleSendInvitationLink}
        validationSchema={this.customerSetupPageValidationSchema}
        initialValues={{ phone }}
        isInitialValid={isInitialValid}
      >
        <Form>
          <div className="row">
            <div className="col-lg-4">
              <Field
                name="phone"
                component={InputField}
                label={<FormattedMessage {...T('common.mobileNumber')} />}
                onKeyUp={(e) => {
                  this.handlePhoneNoFieldKeyUp(e.target.value)
                }}
              />
              {this.state.isCountryCodeBlocked &&
                <span className="help-block notification-warning-span" data-qa="notify-countrycode-blocked">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;<FormattedMessage
                    {...T('countryCallingCodeIsBlocked.note')}
                  />
                </span>}
            </div>
            <div className="col-lg-4">
              <ReconnectCode webCode={webCode} />
            </div>
            <div className="col-lg-3">
              <label>&nbsp;</label>
              <Button primary className="btn-block btn--min-width"
                type="submit" data-qa="send-sms-button" disabled={this.state.isCountryCodeBlocked}>
                <FormattedMessage {...T('common.send')} />
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    )
  }

  onConnectLinkClick = (link, e) => {
    e.preventDefault();
    window.open(link, "_blank");
  };

  render() {
    const { Customer = {}, loading, webCode } = this.props
    const { firstName, lastName } = Customer
    const connectLink = {
      href: 'http://signia.to/signia-app',
      text: 'signia.to/signia-app'
    }
    return (
      <div className="customerSetupPage" data-customerid={Customer.id}>
        <Loading isLoading={loading} />
        {!loading &&
          <div>
            <div className="row">
              <div className="col-10 offset-1 col-md-12 offset-md-0 text-center text-md-left">
                <h1 data-qa="title">
                  <FormattedMessage
                    {...T('customerSetup.title')}
                    values={{ 0: firstName, 1: lastName }}
                  />
                </h1>
                <h2 data-qa="install-app-header">
                  <FormattedMessage {...T('customerSetup.text1')} />
                </h2>
                <p data-qa="install-app-text">
                  <FormattedMessage {...T('customerSetup.text7')} />
                </p>
              </div>
            </div>
            <div className="setup-send-link border-bottom light">
              <div className="row">
                <div className="col-12">
                  <h2 data-qa="resend-title">
                    <FormattedMessage {...T('customerSetup.resend')} />
                  </h2>
                  <p data-qa="resend-text">
                    <FormattedMessage {...T('customerSetup.text2')} />
                  </p>
                </div>
              </div>
              {this.renderResendLink(webCode)}
            </div>
            <div className="setup-manual-installation border-bottom-light">
              <div className="row">
                <div className="col-12">
                  <h2 data-qa="manual-installation-title">
                    <FormattedMessage {...T('customerSetup.manualInstallation')} />
                  </h2>
                  <h3 data-qa="manual-installation-step1-title">
                    <FormattedMessage {...T('customerSetup.step1_install')} />
                  </h3>
                  <p data-qa="manual-installation-step1-text1">
                    <FormattedMessage
                      {...T('customerSetup.text3')}
                      values={{
                        link: (
                          <LinkButton rel="noreferrer noopener" data-qa="connect-link" onClick={(e) => this.onConnectLinkClick(connectLink.href, e)}>
                            {connectLink.text}
                          </LinkButton>
                        ),
                      }}
                    />
                  </p>
                  <p data-qa="manual-installation-step1-text2">
                    <FormattedMessage {...T('customerSetup.text4')} />
                  </p>
                  <h3 data-qa="manual-installation-step2-title">
                    <FormattedMessage {...T('customerSetup.step2_connect')} />
                  </h3>
                  <p data-qa="manual-installation-step2-text1">
                    <FormattedMessage {...T('customerSetup.text5')} />
                  </p>
                </div>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

const mapProps = ({
  data: { refetch, loading, Me, ReconnectPatient },
  ownProps: { params, location, intl }
}) => {
  return {
    me: Me,
    refetch,
    loading,
    Customer: ReconnectPatient,
    patientId: parseInt(params.id, 10),
    webCode: get(ReconnectPatient, 'webCode', ''),
    isFetching: loading,
    location,
    intl
  }
}

const QUERY_PATIENT_SETUP = gql`
  query PateintSetupDetailQuery($id: Int!, $correlationContext: String!) {
    Me {
      state
      countryCode
      employee {
        id
        firstName
        defaultPracticeId
      }
    }
    ReconnectPatient(patientId: $id, correlationContext: $correlationContext) {      
      id
      firstName
      lastName
      phone
      email
      webCode
    }
  }
`

const options = ({ params }) => ({
  variables: {
    id: parseInt(params.id),
    correlationContext: CorrelationContext['ConnectToTelecareApp_GetWebCode'] + SupportId
  },
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_PATIENT_SETUP, {
    props: mapProps,
    options
  }),
  graphql(FETCH_ALL_COUNTRY_CALLING_CODES_QUERY, {
    props: ({ data }) => {
      return {
        CountryCallingCodes: data.CountryCallingCodes
      }
    },
  }),
  graphql(UPDATE_POPUP, {
    props: ({ mutate }) => ({
      updatePopup: (popup, isOpen) => mutate({ variables: { popup, isOpen } })
    })
  }),
  injectIntl
)

export default withRouter(withQuery(withData(CustomerSetupPage)))
