import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import Markdown from 'react-markdown'
import { T } from 'core'
import { StyledBootstrapButton } from 'styledComponents/Button'
import { compose } from 'lodash/fp'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon" 


export const Content = ({
  icon = faEnvelope,
  iconType = "popupIcon",
  title,
  message,
  intl: { formatMessage },
  onClick
}) => {
  return (
    <div data-qa="popup" className="popup--release-notes">
      <StyledPopupFontAwesomeIcon className="popup-icon" icon={icon} type={iconType} fixedWidth size="2x" data-qa="popup__icon" />
      <h1 className="popup__title" data-qa="popup__title">
        <FormattedMessage {...T(title)} />
      </h1>
      <div data-qa="popup__message">
        <Markdown children={formatMessage(T(message))} />
      </div>
      <div className="row">
        <div className="col-4 offset-4">
          <StyledBootstrapButton
            primary
            className="btn-block"
            variant="default"
            onClick={onClick}
            type="submit"
            data-qa="popup__close-button"
          >
            <FormattedMessage {...T('common.ok')} />
          </StyledBootstrapButton>
        </div>
      </div>
    </div>
  )
}

export class MarkdownPopupModal extends Component {
  render() {
    const { isOpen, popup, ...restProps } = this.props
    return (
        isOpen &&
        popup &&
        <div className="popup">
          <div className="popup-inner">
            <Content {...restProps} {...popup.payload} />
          </div>
        </div>
      )
  }
}

MarkdownPopupModal.propTypes = {
  intl: PropTypes.object,
  message: PropTypes.string,
  title: PropTypes.string,
  overlay: PropTypes.object
}
const mapProps = ({ data: { overlay } }) => {
  return { ...overlay }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  })
)
export default withData(injectIntl(MarkdownPopupModal))
