// Quickfix for CR535300. File and its references and related code should be removed after this issue is fixed on TCBE.
// Map extended for CR602566.
export const d10_d11_conversion = new Map([
  // Signia Programs
  ['OFF', 'OFF'], 
  ['UNIVERSAL', 'UNIVERSAL'],
  ['NOISY_ENV', 'NOISY_ENV'],
  ['PHONE_ACOUSTIC','PHONE_ACOUSTIC'],
  ['PHONE_MIDMIC_T', 'REV_ROOM'],
  ['PHONE_BLUETOOTH', 'OUTDOOR_SPORTS'],
  ['MUSIC', 'STROLL'],
  ['TV', 'TV'],
  ['OUTDOOR_SPORTS', 'PRIVACY'],
  ['PRIVACY', 'MUSIC'],
  ['TINNITUS', 'MUSIC_RECORD'],
  ['INDUCTION_LOOP_MT', 'MUSIC_LIVE'],
  ['TEK_MINITEK','MUSIC_MUSICIAN'],
  ['MINITEK_FM','HD_MUSIC'],
  ['MINITEK_TELECOIL','TINNITUS'],
  ['AUDIO_INPUT','PHONE_TWIN'],
  ['STROLL','PHONE_MIDMIC_T'],
  ['STREAM','PHONE_MIC_T'],
  ['EASYTEK','PHONE_T'],
  ['PHONE_MIC_T','INDUCTION_LOOP_MT'],
  ['PHONE_T','INDUCTION_LOOP_T'],
  // Rexton / Costco / Specsavers / Amplifon
  ['REXTON_AUTOMATIC', 'REXTON_AUTOMATIC'],
  ['REXTON_NOISE_PARTY', 'REXTON_NOISE_PARTY'],
  ['REXTON_TELEPHONE_ACOUSTIC_', 'REXTON_TELEPHONE_ACOUSTIC_'],
  ['REXTON_TELECOIL_T', 'REXTON_ECHO_STOP'],
  ['REXTON_BLUETOOTH_PHONE_FOR_VOICE_ONLY', 'REXTON_OUTDOOR_TRAFFIC'],
  ['REXTON_MUSIC', 'REXTON_FOCUS_360'],
  ['REXTON_TELEVISION', 'REXTON_TELEVISION'],
  ['REXTON_OUTDOOR_TRAFFIC', 'REXTON_PRIVACY'], // Privacy is new in D11.
  ['REXTON_MUSIC_D11', 'REXTON_MUSIC'], // Enum number 9 is created in Backend for "Music".
  ['REXTON_TINNITUS_FUNCTION', 'REXTON_MUSIC_LISTENING_WITH_SPEAKERS'],
  ['REXTON_EARPROTECTION', 'REXTON_MUSIC_LISTENING_AT_A_LIVE_VENUE'],
  ['REXTON_MUSIC_PLAYING_AN_INSTRUMENT_D11', 'REXTON_MUSIC_PLAYING_AN_INSTRUMENT'], // Enum number 12 is created in backend for "Playing an instrument".
  ['REXTON_MUSIC_ENHANCER', 'REXTON_MUSIC_PLUS_HD'], // Enum number 13 is created in backend for "Music Enhancer".
  ['REXTON_TINNITUS_FUNCTION_D11', 'REXTON_TINNITUS_FUNCTION'], // Enum number 14 is created in backend for "Tinnitus Signal".
  ['REXTON_AUDIO_INPUT', 'REXTON_XPHONE'],
  ['REXTON_FOCUS_360', 'REXTON_TELECOIL_MT_23'],
  ['REXTON_STREAMING_AUDIO', 'REXTON_TELECOIL_MT'],
  ['REXTON_SMART_CONNECT_FM', 'REXTON_TELECOIL_T'],
  ['REXTON_TELECOIL_MT', 'REXTON_INDUCTION_LOOP_MT'], // "Induction Loop mT" is new in D11.
  ['REXTON_INDUCTION_LOOP_T', 'REXTON_INDUCTION_LOOP_T'], // "Induction Loop T" is new in D11, enum with number 20 is created in Backend.
  // Audio Service / Afflelou / Hoerluchs / Meditrend / OuieZen / OptifitII
  ['AUDIOSERVICE_01', 'AUDIOSERVICE_01'],  // Automatic
  ['AUDIOSERVICE_02', 'AUDIOSERVICE_02'],  // Noise / Party
  ['AUDIOSERVICE_03', 'AUDIOSERVICE_03'],  // Telephone (acoustic)
  ['AUDIOSERVICE_04', 'AUDIOSERVICE_30'],  // EchoClear
  ['AUDIOSERVICE_05', 'AUDIOSERVICE_08'],  // Outdoor / Traffic
  ['AUDIOSERVICE_06', 'AUDIOSERVICE_16'],  // AudioFocus 360 / Speech 360
  ['AUDIOSERVICE_07', 'AUDIOSERVICE_07'],  // Television
  ['AUDIOSERVICE_08', 'AUDIOSERVICE_09'],  // Privacy
  ['AUDIOSERVICE_09', 'AUDIOSERVICE_06'],  // Music
  ['AUDIOSERVICE_10', 'AUDIOSERVICE_28'],  // Listening with speakers
  ['AUDIOSERVICE_11', 'AUDIOSERVICE_25'],  // Listening at a live venue
  ['AUDIOSERVICE_12', 'AUDIOSERVICE_26'],  // Playing an instrument
  ['AUDIOSERVICE_13', 'AUDIOSERVICE_27'],  // MusicSelect
  ['AUDIOSERVICE_14', 'AUDIOSERVICE_10'],  // Tinnitus Signal
  ['AUDIOSERVICE_15', 'AUDIOSERVICE_29'],  // 2earPhone
  ['AUDIOSERVICE_16', 'AUDIOSERVICE_23'],  // Telecoil (mT)
  ['AUDIOSERVICE_17', 'AUDIOSERVICE_24'],  // Telecoil (MT)
  ['AUDIOSERVICE_18', 'AUDIOSERVICE_04'],  // Telecoil (T)
  ['AUDIOSERVICE_19', 'AUDIOSERVICE_31'],  // Induction Loop mT (new in D11)
  ['AUDIOSERVICE_20', 'AUDIOSERVICE_32']   // Induction Loop T (new in D11)
])