import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl,FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'
import { localeToCountry } from 'helper/constants'
import config from 'config'
import { LOCALE_QUERY } from './../graphql/queries/LocaleQuery'
import { compose } from 'lodash/fp'
import { getEnviroment } from '../helper/TokenUtil';

/* global __VERSION__, __ENV__ */

export class MaintenanceFooter extends React.PureComponent {
  render() {
    const corporateInformationLink = '/#/imprint'
    const isChina = config.variant === 'china'
    const disclaimerLink = `/#/disclaimer?country=${this.props.country}`

    return (
      <div className="footer">
        <div className="container-fluid">
          <div className="row noMargin">
            <div className="col-12 text-center">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    href={corporateInformationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-qa="corporate-information-link"
                  >
                    <FormattedMessage {...messages.footerCorporateInformation} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`/#/terms?country=${this.props.country}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-qa="terms-link"
                  >
                    <FormattedMessage {...messages.commonTerms} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`/#/privacy?country=${this.props.country}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-qa="privacy-link"
                  >
                    <FormattedMessage {...messages.commonPrivacy} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={disclaimerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-qa="disclaimer-link"
                  >
                    <FormattedMessage {...messages.footerDisclaimer} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row noMargin">
            <div className="col-12 text-center" data-qa="copyright">
              © 2016-2018 Sivantos Pte. Ltd. - Manufactured by Sivantos GmbH - TeleCare Portal v{__VERSION__}{' '}
              {getEnviroment() === 'development' ? ' (DEV)' : null}
            </div>
            {isChina &&
              <div className="col-12 text-center" data-qa="copyright">
                ICP-Code:{' '}
                <a href="http://www.miibeian.gov.cn" target="_blank" data-qa="icp-link">
                  苏ICP备15039846号-1
                </a>
              </div>}
          </div>
        </div>
      </div>
    )
  }
}

MaintenanceFooter.propTypes = {
  locale: PropTypes.string
}

const mapCacheToProps = ({ data: { locale } }) => {
  const localeProp = locale ? locale.locale : 'en-US'
  const country = localeToCountry[localeProp] || 'US'

  return {
    locale: localeProp,
    country: country.toLowerCase()
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: mapCacheToProps
  }),
  injectIntl
)

export default withData(MaintenanceFooter)
