import React from 'react'
import VideoGallery from './VideoGallery'
import TelecareQuickGuide from './TeleCareQuickGuide'
import { injectIntl } from 'react-intl'
import { Props, State } from 'typescript/interfaces/IOnBoardingPage'
import { T } from 'core'

export class OnBoardingPage extends React.Component<Props, State> {

  state = {
    selectedVideoIndex: 0
  }

  /*setSelectedVideo = index => {
    this.setState({ selectedVideoIndex: index })
  }
  */

  render() {
    return (
      <div className="onboarding-container">
        <div className="row justify-content-center">
          <TelecareQuickGuide />
        </div>
        {
        /* Commenting for now and not deleting because we need to re-enable this as soon as brand agnostic videos are ready!!!!

        <div className="row">
          <div className="col-lg-10 offset-lg-1 ">
            <iframe className ="video-player"  src={this.props.videosLink[this.state.selectedVideoIndex].url} frameBorder="0" allowFullScreen />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col text-center font-weight-bold video-player-title">
            { this.props.videosLink[this.state.selectedVideoIndex].title} 
          </div>
        </div>
        <div className="row justify-content-center gallery-container ">
          <div className ="offset-lg-1 "/>
          <div className="col-lg-10 gallery">
            <div className="row videos-list flex-wrap ">
              {
                this.props.videosLink && this.props.videosLink.map(video =>{
                  return(<div className={ this.props.isHelpVideos? 'align-items-center col-sm-3 ': ' align-items-center col-sm-4'} key={`nopatient_video_${video.index}_div`}>
                    <VideoGallery 
                        key={`nopatient_video_${video.index}`}
                        source={video.url}
                        title={video.title}
                        onClickHandler={() =>this.setSelectedVideo(video.index)}
                        selectedCardIndex = {this.state.selectedVideoIndex}
                        index={video.index}
                    />
                </div>)
                })
              }
              </div>
            </div>
          <div className ="offset-lg-1"/>
        </div> */}
      </div>
    )
  }
}

const getVideosList = (props) => {
  const videosUrl = [
    'noPatient.tutorialVideo1',
    'noPatient.tutorialVideo2',
    'noPatient.tutorialVideo3',
    'Help.tutorialVideo1',
    'Help.tutorialVideo2',
    'Help.tutorialVideo3'
  ]
  const videosLink = []

  videosUrl.forEach((url, index) => {
    videosLink.push(
      {
        url: props.intl.formatMessage(T(url)),
        title: props.intl.formatMessage(T(`${url}.label`)),
        index: index
      })
  })

  return videosLink
}

export const getVideoListForNoPatient = (props) => {
  return getVideosList(props).slice(0, 3)
}

export const getVideoListForHelpCenter = (props) => {
  return getVideosList(props)
}

export default injectIntl(OnBoardingPage)