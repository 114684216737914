import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import _ from 'lodash'

import EmployeeInviteForm from './EmployeeInviteForm'
import { getLocales, CorrelationContext } from 'helper/constants'
import { LOCALE_QUERY } from '../../graphql/queries/LocaleQuery'
import { MUTATION_INVITE_EMPLOYEE, MUTATION_RESEND_INVITE_EMPLOYEE } from '../../graphql/mutations/InviteEmployeeMutation'
import { QUERY_INVITE_EMPLOYEE } from '../../graphql/queries/EmployeeInvitePageQuery'
import { withRouter } from "helper/withRouter";
import { getSupportId } from 'helper/ApplicationInsightSupportId'
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'

let SupportId = getSupportId()

const mapProps = ({
  data: { refetch, loading, Me, AssignedPractices = [] },
  ownProps: { locale, intl }
}) => {
  const sortedLocales = getLocales()
  const locales = Object.keys(sortedLocales).map(countryCode => ({
    countryCode,
    value: sortedLocales[countryCode]
  }))

  return {
    refetch,
    loading,
    practices: AssignedPractices,
    practicesOptions: AssignedPractices.map(item => ({
      label: item.name,
      value: item.id
    })),
    formTitle: 'employeeInvitePage.title',
    submitLabel: 'employeeInvitePage.sendButton',
    isManager: _.get(Me, `employee.manager`, false),
    isOrgManager: _.get(Me, `employee.orgManager`, false),
    countryCode: _.get(Me, ['countryCode'], ''),
    locale,
    locales,
    intl,
  }
}

export const options = () => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    correlationContext: CorrelationContext['Employee_Invite'] + SupportId,
    orgId: parseInt(getOrganizationIdFromCache())
  }
})

const withData = compose(
  graphql(QUERY_INVITE_EMPLOYEE, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_INVITE_EMPLOYEE, {
    props: ({ mutate }) => ({
      inviteEmployee: variables =>
        mutate({
          variables
        })
    }),
    options
  }),
  graphql(MUTATION_RESEND_INVITE_EMPLOYEE, {
    props: ({ mutate }) => ({
      resendInviteEmployee: variables =>
        mutate({
          variables
        })
    }),
    options
  }),
  graphql(LOCALE_QUERY, {
    props: ({ data: { locale } }) => ({
      locale: locale.locale
    })
  })
)

const EmployeeCreatePage = withRouter(withData(injectIntl(EmployeeInviteForm)))

export default EmployeeCreatePage
