const Colors = {
  brandPrimary: '#d60925',
  brandSecondary: '#90061a',

  brandDanger: '#E1243F',
  brandSuccess: '#008858',
  brandNeutral: '#808080',
  brandDisabled: '#333333',
  horizontalLine: '#b4b8bb',
  elementDisabled: '#f0f0f0',
  borderColor: '#9d9c9c',
  kpiHeader: '#e6e6e6',
  elementDisabledGray: '#a0a0a0',

  buttonSecondary: '#454545',
  buttonSecondaryHover: '#2b2b2b',
  buttonSecondaryActiveHover: '#1f1f1f',
  buttonPrimary: '#d60925',
  buttonPrimaryHover: '#a5071d',
  buttonPrimaryActiveHover: '#830617',

  white: '#fff',
  gray: '#454545',
  black: '#000',

  fontAwesomeIcons: {
    angleDown: {
      color: "#515151",
    },
  },
}

export default Colors
