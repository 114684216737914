
import React, { useContext } from 'react'
import DataContext from 'containers/DataContext'
import SigniaManageLessons from './SigniaManageLessons'

export const SigniaManageLessonsWrapper = () => {
    const { customer, me } = useContext(DataContext);
    return (
        <SigniaManageLessons customer={customer} Me={me} />
    )
}
