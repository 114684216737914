import React, { Component } from 'react'
import _ from 'lodash'
import { compose, pick, map } from 'lodash/fp'
import { StyledForm } from 'styledComponents/Div/DivComponent/DivComponent'

const resetFormField = field => {
  field.resetField()
  return {
    ...field,
    isDirty: false,
    isBlurred: false
  }
}

class Fieldset extends Component {
  static defaultProps = {
    // children: [],
    onDirtyChange: _.identity,
    onValidationChange: _.identity,
    alignment: 'vertical' // vertical layout or horizontal layout of the form
  }

  constructor(props) {
    const timeUntilAllInputsAreRegistered = 300 // each input register itself for this form. We need to wait for sanity checks
    super(props)
    this.state = {}
    setTimeout(() => {
      props.onValidationChange(this.isFormValid())
      props.onDirtyChange(false)
    }, timeUntilAllInputsAreRegistered)
  }

  // each input register itself and provides a reference to this form to be handled
  // this way children components acts as presentatinal components and states is managed in this form
  onRegister = (ref, isValid, resetField, setValue = _.identity) => {
    ref.id &&
      this.setState({
        [ref.id]: {
          element: ref,
          isValid,
          isDirty: false,
          isBlurred: true,
          resetField,
          setValue
        }
      })
  }

  /* eslint-disable no-param-reassign */
  getValues() {
    return _.transform(this.state, (result, field, key) => {
      const value = _.get(field, ['element', 'value'], '')

      if (_.get(field, ['element', 'type']) === 'checkbox') {
        result[key] = _.get(field, ['element', 'checked'], false)
        // can not just return: if the return value is false, iteration will stop.
      } else {
        result[key] = value
      }
    })
  }
  /* eslint-enable */

  getValueById = id => {
    if (_.get(this.state, [id, 'element', 'type']) === 'checkbox') {
      return _.get(this.state, [id, 'element', 'checked'], false)
    }

    return _.get(this.state[id], ['element', 'value'], '')
  }

  resetFieldValuesByIds = ids =>
    this.setState(compose(map(resetFormField), pick(ids))(this.state))

  resetAllFields = () => this.setState(map(resetFormField, this.state))

  setFieldValueById = (value, id) => this.state[id].setValue(value)

  isFormValid() {
    return !_.chain(this.state).filter(field => !field.isValid).size().value()
  }

  setDirty() {
    return this.setState(
      map(field => ({ ...field, isDirty: true, isBlurred: true }), this.state)
    )
  }
  /* eslint-disable no-param-reassign */

  getFieldsValidationState() {
    return _.transform(
      this.state,
      (result, { isValid, isDirty, isBlurred }, key) => {
        result[key] = { isValid, isDirty, isBlurred }
      }
    )
  }
  /* eslint-enable */

  isFieldValid = id => _.get(this.getFieldsValidationState(), [id, 'isValid'])
  isFieldDirty = id => _.get(this.getFieldsValidationState(), [id, 'isDirty'])
  isFieldBlurred = id =>
    _.get(this.getFieldsValidationState(), [id, 'isBlurred'])

  handleValidationChange = (id, isValid) => {
    this.setState({ [id]: { ...this.state[id], isValid } }, () =>
      this.props.onValidationChange(this.isFormValid())
    )
  }

  handleBlurredChange = id => {
    if (!id || this.state[id].isBlurred) return
    this.setState({ [id]: { ...this.state[id], isBlurred: true } })
  }

  handleDirtyChange = id => {
    // if we have already marked this as dirty, do nothing
    if (!id || _.get(this.state, [id, 'isDirty'])) return

    this.setState({ [id]: { ...this.state[id], isDirty: true } })
    this.props.onDirtyChange(true)
  }

  markFieldsAsPristine = () => {
    this.setState(
      map(
        field => ({ ...field, isDirty: false, isBlurred: false }),
        this.state
      ),
      () => this.props.onDirtyChange(false)
    )
  }

  render() {
    const { children, alignment } = this.props
    // render children as form eleemnts
    return (
      <StyledForm alignment={alignment}>
        {React.Children.map(_.filter(children, _.identity), (child, key) => {
          const elems = React.cloneElement(child, {
            key,
            isBlurred: this.isFieldBlurred(child.props.id),
            isDirty: this.isFieldDirty(child.props.id),
            isValid: this.isFieldValid(child.props.id),
            onDirty: _.debounce(this.handleDirtyChange, 100),
            onRegister: this.onRegister,
            onValidation: this.handleValidationChange,
            onBlurred: this.handleBlurredChange
          })
          return elems
        })}
      </StyledForm>
    )
  }
}

export default Fieldset
