import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { DateFormatOfDatePicker } from 'helper/constants'
import {
  cs,
  da,
  de,
  enUS,
  enGB,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nb,
  nl,
  pl,
  pt,
  ru,
  sv,
  th,
  tr,
  zhCN,
  zhTW
} from 'date-fns/locale'

registerLocale('cs-CZ', cs)
registerLocale('da-DK', da)
registerLocale('de-DE', de)
registerLocale('en-US', enUS)
registerLocale('en-GB', enGB)
registerLocale('es-ES', es)
registerLocale('fi-FI', fi)
registerLocale('fr-FR', fr)
registerLocale('hu-HU', hu)
registerLocale('it-IT', it)
registerLocale('ja-JP', ja)
registerLocale('ko-KR', ko)
registerLocale('nb-NO', nb)
registerLocale('nl-NL', nl)
registerLocale('pl-PL', pl)
registerLocale('pt-BR', pt)
registerLocale('sv-SE', sv)
registerLocale('th-TH', th)
registerLocale('tr-TR', tr)
registerLocale('zh-Hans', zhCN)
registerLocale('zh-Hant', zhTW)

const DateSelector = ({
  onChange,
  placeholderText,
  includeDates,
  maxDate,
  minDate,
  locale
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const handleChange = (date) => {
    setSelectedDate(date)
    onChange(date)
  }

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        locale={locale}
        dateFormat={DateFormatOfDatePicker}
        placeholderText={placeholderText}
        includeDates={includeDates}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  )
}

export default DateSelector