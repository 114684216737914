import { graphQLClient } from '../graphql/client'
import { LOCALE_QUERY } from '../graphql/queries/LocaleQuery'

export const getLocale = () => {
  const { localStorage } = window
  if (localStorage) {
    const locale = localStorage.getItem('locale')
    if (locale) {
      return JSON.parse(locale).locale
    }
  }
  return 'en-US'
}

export const getLocaleFromCache = () => {
  const { locale } = graphQLClient.readQuery({
    query: LOCALE_QUERY
  })
  return locale.locale
}
