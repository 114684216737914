import React from 'react'
import { Loader } from 'styledComponents/Div/DivComponent/DivVideoCall'

export default () => {
  return (
    <Loader>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </Loader>
  )
}
