import * as React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Props, State } from "typescript/interfaces/IAccordionWithCheckbox"
import Tooltip from 'react-tooltip'
import { Field } from "formik"
import CheckboxField from 'formfields/CheckboxField'

const accordionId = ['Group', 'One_To_One', 'Surroundings', 'Lifestyle']

export class AccordionWithCheckbox extends React.Component<Props, State> {

  getNumberOfSelectedLessons = () => {
    const { formikProps: { values }, lessonModule: { lessons } } = this.props
    const keys = Object.keys(values).filter(key => values[key] === true)
    let selectedLesson = 0
    lessons.forEach(lesson => {
      if (keys.includes(lesson.id.toString()))
        selectedLesson++
    })
    return selectedLesson
  }

  validateEachModuleSelection = () => {
    let error
    if (this.getNumberOfSelectedLessons() == 0) {
      error = <FormattedMessage {...T('journeyConfigurator.manageLessonSets.selectOne')} />
    }
    return error;
  }

  getLessonIds = () => {
    const { lessonModule: { lessons } } = this.props
    const lessonIds = []
    lessons.forEach(lesson => lessonIds.push(lesson.id))
    return lessonIds
  }

  handleAccordionCheckboxes = (name, value, categoryName) => {
    const { formikProps } = this.props
    const lessonIds = this.getLessonIds()
    const category = ['Group', 'One_To_One', 'Surroundings', 'Lifestyle']
    if (formikProps && category.includes(name)) {
      lessonIds.forEach(id => formikProps.setFieldValue(id, !value))
      formikProps.setFieldValue(name, !value)
    } else {
      formikProps.setFieldValue(name, !value)
      if (!_.isNil(categoryName) && value === true) {
        formikProps.setFieldValue(categoryName, !value)
      }
      let uncheckCount = 0
      let uncheckId = ''
      lessonIds.forEach(id => {
        if (!formikProps.values[id]) {
          uncheckCount++
          uncheckId = id
        }
      })
      if (uncheckCount == 1 && uncheckId == name) {
        formikProps.setFieldValue(categoryName, !value)
      }
    }
    // Touching parent field deliberately as touching of child checkboxes didn't trigger touching of parent checkbox
    formikProps.setFieldTouched(categoryName, true, true);
  }

  render() {
    const { lessonModule: { categoryName, description, lessons }, isDisabled, formikProps, isExpanded = false } = this.props
    return (
      <Accordion className="accordion-container" allowZeroExpanded={true} preExpanded={accordionId}>
        <AccordionItem uuid={categoryName}>
          <AccordionItemHeading>
            <AccordionItemButton className="accordion-heading">
              <div className="checkbox-header">
                <Field
                  name={categoryName}
                  component={CheckboxField}
                  isDisabled={isDisabled}
                  validate={this.validateEachModuleSelection}
                  formikProps={formikProps}
                  lessonIds={this.getLessonIds()}
                  handleAccordionCheckboxes={this.handleAccordionCheckboxes}
                  checked={formikProps.values[categoryName]}
                ><b>{description + " (" + this.getNumberOfSelectedLessons() + "/" + lessons.length + ")"}</b>
                </Field>
                <div className="accordion__arrow " />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-panel">
            {this.props.lessonModule.lessons.map((lesson, index) => {
              return <div className="accordion-item" key={index}>
                <Field
                  name={lesson.id}
                  component={CheckboxField}
                  isDisabled={isDisabled}
                  formikProps={formikProps}
                  categoryName={categoryName}
                  checked={formikProps.values[lesson.id]}
                  handleAccordionCheckboxes={this.handleAccordionCheckboxes}
                  lessonIds={this.getLessonIds()}
                >{lesson.title}
                </Field>
                <div className="info-icon tooltip-on-hover" data-tip={`<b>${lesson.description}</b><br/>${lesson.howTo}`} data-html={true} >
                  <i className="fas fa-fw fa-info-circle fa-2x" data-qa="lesson-info"></i>
                </div>
              </div>
            })}
            <Tooltip className="tooltip-lesson-icon" />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}
