import React, { useContext } from 'react'
import DataContext from '../../../containers/DataContext'
import ManageLessonSet from "components/OrgManagement/LessonsPage"
import { withRouter } from 'helper/withRouter'
import EditLessonTemplate from "containers/LessonSet/LessonEdit/EditLessonTemplate"

const LessonWrapper = ({ location }) => {
  const { me } = useContext(DataContext);
  const lessonEditRegex = /^\/lessontemplate\/\d+\/edit$/;
  if (lessonEditRegex.test(location.pathname)) {
    return <EditLessonTemplate {...me} />
  }
  else {
    return (
      <ManageLessonSet  {...me} />
    )
  }

}

export default withRouter(LessonWrapper)