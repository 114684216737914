import React, { useState } from "react"

import { StyledTable } from "styledComponents/Table/StyledTable"
import SortableTableHeader from './../../../SortableTableHeader'
import { injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import messages from 'i18n/messages.json'
import moment from 'moment'
import _ from 'lodash'

export function AssistantTable(props) {
  const { assistantHistory, intl: { formatMessage } } = props
  const [sort, setSort] = useState('date,desc')
  const [listOfModifications, setListOfModifications] = useState(assistantHistory?.listOfModifications);

  const sortCol = (colName) => {
    const prevOrder = sort.split(',')[1] || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'

    setListOfModifications(_.orderBy(listOfModifications, [modification => modification[colName]], [order]))
    const sortValue = [colName, order].join(',')
    setSort(sortValue)
  }

  return <div style={{ marginTop: '30px' }}>
    <StyledTable className="table--assistant">
      <thead>
        <tr data-qa="open-table-headlines">
          <SortableTableHeader
            className="col-2"
            name="date"
            onClick={sortCol}
            value={formatMessage(messages.customerOverviewDaThDate)}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="soundSource"
            onClick={sortCol}
            value={formatMessage(messages.customerOverviewDaThProblem)}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="soundAttribute"
            onClick={sortCol}
            value={formatMessage(messages.customerOverviewDaThDescription)}
            sort={sort}
          />
          <SortableTableHeader
            className="col-2"
            name="change"
            onClick={sortCol}
            value={formatMessage(messages.customerOverviewDaThChangedSetting)}
            sort={sort}
          />
        </tr>
      </thead>
      <tbody>
        {listOfModifications && listOfModifications.map((modification, i) =>
          <tr key={i} className={modification.cssClass}>
            <td>
              {modification.date &&
                <div>
                  <FormattedDate value={moment(modification.date)} /> {'  '}
                  <FormattedTime value={moment(modification.date)} />
                </div>
              }
            </td>
            <td>
              {modification.soundSource}
            </td>
            <td>
              {modification.soundAttribute}
            </td>
            <td>
              {modification.change}
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  </div>
}

export default injectIntl(AssistantTable)