import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { injectIntl } from 'react-intl'
import Loading from 'components/Loading'
import AccordionElement from '../../AccordionElement'
import { PortalContext } from 'helper/constants'
import DataContext from "containers/DataContext"
import { withRouter } from "helper/withRouter";
import { withQuery } from '../../../helper/withQuery'

class CommonFaq extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = { locale: context.locale }
  }

  static contextType = DataContext;

  UNSAFE_componentWillUpdate(prevProps) {
    const { locale } = this.context
    const { query } = this.props;
    query.locale = locale
    if (this.state.locale !== prevProps.query.locale) {
      this.setState({ locale })
      this.props.navigate({ pathname: `/help/faq/commonfaq`, search: `?locale=${locale}` })
    }
  }

  render() {
    const { loading, Faq } = this.props
    return (
      <div>
        {loading
          ? <Loading isLoading={loading} />
          : Faq &&
          Faq.map((data, index) => {
            return <AccordionElement question={data.question} answer={data.answer} allowMarkdown={true} key={index} />
          })}
      </div>
    )
  }
}

const mapQueryToVariables = ({ locale }, context = '') => ({
  context,
  locale
})

const options = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables(query, PortalContext),
  fetchPolicy: 'network-only'
})
const mapProps = ({ data: { refetch, loading, Faq } }) => {
  return {
    refetch,
    loading,
    Faq
  }
}

const QUERY_ORDERED_FAQ_PAGE = gql`
  query FaqQuery($context: String, $locale: String) {
    Faq(context: $context, locale: $locale) {
      question
      answer
    }
  }
`
const withData = compose(
  graphql(QUERY_ORDERED_FAQ_PAGE, {
    props: mapProps,
    options
  }),
  injectIntl
)

export default withRouter(withQuery(withData(CommonFaq)))
