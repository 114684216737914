import React from "react";
import { ErrorMessage, getIn } from "formik";
import Downshift from "downshift";

const AutoCompleteField = ({
  field: { name: fieldName, value: fieldValue, onBlur, onChange },
  className,
  label,
  labelClassName,
  form: { touched, errors },
  items,
  onItemSelect,
  onTextChange,
  placeholder,
  objectKey,
  ...props
}) => {
  const inputFieldProps = () => {
    return {
      name: fieldName,
      value: fieldValue,
      placeholder,
      ...props,
      className: `form-control ${className || ""} ${
        getIn(errors, fieldName) && getIn(touched, fieldName) ? "has-error" : ""
      } `,
      onBlur,
      onChange,
    };
  };

  const filteredItems = (inputValue) => {
    let filteredList = items.filter((item) => {
      return item[objectKey]
        .toLowerCase()
        .startsWith(inputValue?.toLowerCase());
    });
    filteredList = filteredList.length > 1 ? [] : filteredList;
    return filteredList;
  };

  return (
    <Downshift
      onInputValueChange={onTextChange}
      itemToString={(item) => (item ? item[objectKey] : "")}
      resultCount={1}
      onSelect={onItemSelect}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => {
        return (
          <div
            className={`form-group ${
              getIn(errors, fieldName) && getIn(touched, fieldName)
                ? "has-error"
                : ""
            }`}
          >
            {label && (
              <label
                htmlFor={fieldName}
                className={`control-label ${labelClassName || ""}`}
              >
                {label}
              </label>
            )}
            <input {...getInputProps({ ...inputFieldProps() })} />
            {isOpen ? (
              <div className="autocomplete-items overflow-hidden">
                {filteredItems(inputValue).map((item, index) => {
                  return (
                    <div
                      {...getItemProps({ item, index })}
                      key={index}
                      style={{
                        color: "black",
                        backgroundColor:
                          highlightedIndex === index ? "#e3e3e3" : "white",
                        fontWeight:
                          (selectedItem &&
                            selectedItem[objectKey] === item[objectKey]) ||
                          fieldValue === item[objectKey]
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {item[objectKey]}
                    </div>
                  );
                })}
              </div>
            ) : null}
            <ErrorMessage
              name={fieldName}
              render={(msg) => (
                <div className="help-block" data-qa="input-error">
                  {msg}
                </div>
              )}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

export default AutoCompleteField;
