import PropTypes from 'prop-types'
import React from 'react'
import { ErrorMessage, getIn } from 'formik'
import { FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'

const CheckboxField = ({
  options,
  field,
  children,
  className,
  form: { touched, errors },
  isDisabled = false,
  formikProps,
  checked,
  categoryName,
  handleAccordionCheckboxes
}) => {
  const { description } = options || { description: null }
  const { value } = { ...field }
  return (
    <div
      className={`checkbox-group ${className || ''} ${getIn(errors, field.name) &&
      getIn(touched, field.name)
        ? 'has-error'
        : ''}`}
      data-qa="checkbox"
    >
      <label
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <input
          {...field}
          type="checkbox"
          onClick={e => {
            e.stopPropagation()
            field.onChange(e)
            field.onBlur(e)
            if (formikProps) {
              handleAccordionCheckboxes(field.name, value, categoryName)
            }
          }}
          disabled={isDisabled}
          checked={checked || value}
          className={`checkbox ${className || ''}        
          ${getIn(errors, field.name) && getIn(touched, field.name) ? 'has-error' : ''}
        `}
        />

        {description != null
          ? <FormattedMessage {...messages[description.key]} values={description.values} />
          : children}
      </label>
      <ErrorMessage
        name={field.name}
        render={msg =>
          <div className="help-block" data-qa="input-error">
            {msg}
          </div>}
      />
    </div>
  )
}

CheckboxField.propTypes = {
  field: PropTypes.shape({}),
  className: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.shape({})
}

export default CheckboxField
