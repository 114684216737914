import { gql } from "@apollo/client";

export const MUTATION_UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($employeeId: Int!, $employee: EmployeeInputWithUserDataConsent!) {
    updateEmployee(employeeId: $employeeId, employee: $employee) {
      id
      inactiveFeatures
      firstName
      lastName
      locale
      phone
      phoneVerified
      email
      emailVerified
      manager
      orgManager
      assignedPracticeIds
      defaultPracticeId
      notificationTime
      userDataConsent
    }
  }
`

export const MUTATION_UPDATE_EMPLOYEE_PHONE_VERIFICATION_STATUS = gql`
  mutation updateEmployeePhoneVerificationStatus($employeeId: Int!, $empPhoneUpdateRequest: EmpPhoneUpdateRequest!) {   
    updateEmployeePhoneVerificationStatus(employeeId: $employeeId, empPhoneUpdateRequest: $empPhoneUpdateRequest) {
      phone
      phoneVerified
    } 
  }
`

export const MUTATION_UPDATE_EMPLOYEE_EMAIL_VERIFICATION_STATUS = gql`
  mutation updateEmployeeEmailVerificationStatus($employeeId: Int!, $empEmailUpdateRequest: EmpEmailUpdateRequest!) {   
    updateEmployeeEmailVerificationStatus(employeeId: $employeeId, empEmailUpdateRequest: $empEmailUpdateRequest) {
      email
      emailVerified
    }
  }
`