import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledLink } from 'styledComponents/Navigation/StyledLink'
import { StyledMenu } from 'styledComponents/Navigation/StyledList'
import { T } from 'core'
import { withRouter } from "helper/withRouter";


export const Navigation = ({ style, items = [], location }) => {
  return (
    <article className="patient-navigation" style={style}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <StyledMenu data-qa="patient-navigation_links" className="row">
              {items.map((menu, index) => {
                return (
                  <div key={`menu-${index}`} className="col-md-3">
                    <StyledLink
                      data-qa={menu.dataqa}
                      to={menu.href}
                      className={`${location.pathname.indexOf(menu.href) !== -1 ? 'active' : ''}`}
                    >
                      <FormattedMessage {...T(menu.name)} />
                    </StyledLink>
                  </div>
                )
              })}
            </StyledMenu>
          </div>
        </div>
      </div>
    </article>
  )
}

export default withRouter(Navigation)
