import { gql } from "@apollo/client"

export const MUTATION_CLEAR_ALL_NOTIFICATIONS = gql`
  mutation clearAllNotifications($notificationType: Int!) {
    clearAllNotifications(notificationType:$notificationType) 
  }
`
export const MUTATION_DELETE_NOTIFICATION = gql`
  mutation deleteNotification($patientNotificationId: String!) {
    deleteNotification(patientNotificationId:$patientNotificationId) 
  }
`
export const MUTATION_UPDATE_NOTIFICATION_READ_DETAIL = gql`
  mutation updateNotificationReadDetail($patientNotificationId: String!) {
    updateNotificationReadDetail(patientNotificationId:$patientNotificationId) 
  }
`