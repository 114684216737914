import { USER_ACTIVITY_THROTTLER_TIME, INACTIVE_USER_TIME_THRESHOLD } from 'helper/constants'

let userActivityTimeout = null;
let userActivityThrottlerTimeout = null;
let isInactive = false;

const userActivityThrottler = () => {
  if (isInactive) {
    isInactive = false;
    resetUserActivityTimeout();
  }

  if (!userActivityThrottlerTimeout) {
    userActivityThrottlerTimeout = setTimeout(() => {
      resetUserActivityTimeout();
      clearTimeout(userActivityThrottlerTimeout);
      userActivityThrottlerTimeout = null
    }, USER_ACTIVITY_THROTTLER_TIME);
  }
}

const resetUserActivityTimeout = () => {
  clearTimeout(userActivityTimeout);

  userActivityTimeout = setTimeout(() => {
    userActivityThrottler();
    inactiveUserAction();
  }, INACTIVE_USER_TIME_THRESHOLD);
}

const inactiveUserAction = () => {
  isInactive = true;
  localStorage.clear();
  window.location.reload()
}

const activateActivityTracker = () => {
  window.addEventListener("mousemove", userActivityThrottler);
  window.addEventListener("scroll", userActivityThrottler);
  window.addEventListener("keydown", userActivityThrottler);
  window.addEventListener("resize", userActivityThrottler);
  window.addEventListener("beforeunload", deactivateActivityTracker);
}

const deactivateActivityTracker = () => {
  window.removeEventListener("mousemove", userActivityThrottler);
  window.removeEventListener("scroll", userActivityThrottler);
  window.removeEventListener("keydown", userActivityThrottler);
  window.removeEventListener("resize", userActivityThrottler);
  window.removeEventListener("beforeunload", deactivateActivityTracker);

  clearTimeout(userActivityTimeout)
  clearTimeout(userActivityThrottlerTimeout)
}

export { activateActivityTracker, userActivityThrottler, deactivateActivityTracker, inactiveUserAction, resetUserActivityTimeout }