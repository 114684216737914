import { graphQLClient } from '../graphql/client'
import { PATIENT_INFO_QUERY } from '../graphql/queries/PatientQuery'
import { getItemFromLocalStorage, setItemInLocalStorage } from "./BrowserStorageUtil"

const PatientInfoDataAnonymizedkey = "p1l$m3k1j90h$er4t"

export const getPatientEditInfoFromCache = (id) => {
  const patientEdit = graphQLClient.readQuery({
    query: PATIENT_INFO_QUERY,
    variables: {
      id: id,
    },
  })
  if (!patientEdit) {
    return getItemFromLocalStorage(PatientInfoDataAnonymizedkey);
  }
  return patientEdit.patientEdit;
}


export const setPatientEditInfoInCache = (patientInfoData) => {
  graphQLClient.writeQuery({
    query: PATIENT_INFO_QUERY,
    data: {
      // Contains the data to write 
      patientEdit: {
        __typename: "PatientData",
        id: patientInfoData.id,
        practiceId: patientInfoData.practiceId,
        employeeId: patientInfoData.employeeId,
        leftModelId: patientInfoData.leftModelId,
        rightModelId: patientInfoData.rightModelId,
      },
    },
    variables: {
      id: patientInfoData.id
    }
  });
  setItemInLocalStorage(PatientInfoDataAnonymizedkey, patientInfoData);
}
