import { getTrackerForm } from '../DataCollectorUtil'
import { getGuidForUserDataConsentFromCache } from 'helper/UserDataConsentUtils'
import connector from 'helper/HttpConnector'
import { ProductSource } from 'helper/constants'
import { isVariationFlagEnabled } from 'helper/FFlags'
import { getAppVersion, getToken } from '../TokenUtil'
import _ from 'lodash'
import moment from 'moment-timezone'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'

const DC_DATA_KEY_LEGACY = "DC_DATA_LEGACY"
const DC_DATA_ENDPOINT_LEGACY = "/api/datacollection"

const DC_ANONYMOUS_PATIENT_DATA_KEY = "DC_ANONYMOUS_PATIENT_DATA"
const DC_ANONYMOUS_PATIENT_DATA_ENDPOINT = "/api/datacollection/send-anonymous"

const DC_LEGITIMATE_DATA_KEY = "DC_LEGITIMATE_DATA"
const DC_LEGITIMATE_DATA_ENDPOINT = "/api/datacollection/send-anonymous"
const DC_PATIENT_DATA_KEY = "DC_DATA"
const DC_PATIENT_DATA_ENDPOINT = "/api/datacollection/{id}/send"

const Storages = {
  [DC_ANONYMOUS_PATIENT_DATA_KEY]: DC_ANONYMOUS_PATIENT_DATA_ENDPOINT,
  [DC_DATA_KEY_LEGACY]: DC_DATA_ENDPOINT_LEGACY,
  [DC_LEGITIMATE_DATA_KEY]: DC_LEGITIMATE_DATA_ENDPOINT,
  [DC_PATIENT_DATA_KEY]: DC_PATIENT_DATA_ENDPOINT
}

const checkFeatureFlag = () => isVariationFlagEnabled('enable-x-cloud-data-collection')

const pushDataToLocalStorage = (payloadArray) => {
  payloadArray.forEach(payload => {
    pushDataToLocalStorageSection(payload.LegacyRecord, DC_DATA_KEY_LEGACY, payload.LegacyRecord?.Meta?.DataCollectionEmployeeID)

    if (checkFeatureFlag()) {
      pushDataToLocalStorageSection(payload.LegitimateInterestRecord, DC_LEGITIMATE_DATA_KEY)
      pushDataToLocalStorageSection(payload.FullRecord, DC_PATIENT_DATA_KEY, payload.upid)
      pushDataToLocalStorageSection(payload.NoCustomerConsentRecord, DC_ANONYMOUS_PATIENT_DATA_KEY)
    }
  })
}

const pushDataToLocalStorageSection = (record, localStorageKey, clientId) => {
  if (record == null)
    return;

  const storageItem = getDataCollectionPayloads(localStorageKey)
  storageItem.DataStorage.Payload.push(record)
  storageItem.ClientId = clientId

  localStorage.setItem(localStorageKey, JSON.stringify(storageItem))
}

export const makeEndpoint = (localStorageKey, id) => {
  const endpointTemplate = Storages[localStorageKey]
  return endpointTemplate.replace('{id}', id)
}

const pushDataTodataCollection = async (localStorageKey) => {
  try {
    const { DataStorage: payload, ClientId: clientId } = getDataCollectionPayloads(localStorageKey)
    if (payload && payload.Payload.length > 0) {

      payload.ClientID = clientId

      const endpoint = makeEndpoint(localStorageKey, clientId)
      await connector.post(getToken(), endpoint, payload)

      if (payload.Payload[0]?.EventType == 'Platform') {
        FlushEventsToAppInsight("Platform Information: " + JSON.stringify(payload.Payload[0]?.Fields));
      }
      localStorage.removeItem(localStorageKey)
    }
  } catch (e) {
    console.log("Push to data collection failed", e)
  }
}

const getDataCollectionPayloads = (localStorageKey) => {
  const payload = localStorage.getItem(localStorageKey)
  if (payload) return JSON.parse(payload)
  return initializeNewDcDataSet()
}

const initializeNewDcDataSet = () => {
  return {
    DataStorage: {
      ClientID: null,
      TimeStamp: moment.utc(new Date()),
      ProductSource,
      ProductSourceVersion: getAppVersion(),
      Payload: []
    },
    Upid: null
  }
}

export const sendDataCollectionDebounced = _.debounce(() => {
  if (checkFeatureFlag()) {
    Object.keys(Storages).forEach(storageKey => pushDataTodataCollection(storageKey))
  } else {
    pushDataTodataCollection(DC_DATA_KEY_LEGACY)
  }
},
  5000,
  { 'maxWait': 10000 })

export const sendDataToDataCollection = async (documentType, payloadStructure, eventType) => {
  const processedPayloadStructure = await getTrackerForm(documentType, payloadStructure, eventType)

  pushDataToLocalStorage(processedPayloadStructure);
  sendDataCollectionDebounced();
}
