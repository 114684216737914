import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { Field } from 'formik'
import messages from 'i18n/messages.json'
import InputField from 'formfields/InputField'
import SelectField from 'formfields/SelectField'
import ButtonSelectField from 'formfields/ButtonSelectField'
import { weekdays } from 'helper/constants'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"

export const OpeningHoursField = ({ field, intl: { formatMessage, formatTime }, onDelete, isReadOnly }) => {
  const dayButtons = {}

  weekdays.forEach(day => {
    dayButtons[day] = formatMessage(messages[`common${_.capitalize(day)}Abbreviation`]).substring(0, 3);
  })

  const timeSelector = []
  const start = moment('07:00:00', 'HH:mm:ss')

  for (let i = 0; i <= 60; i++) {
    const convertedTime = start.clone().add(i * 15, 'minutes')

    timeSelector.push({ value: convertedTime.format('HH:mm:ss'), label: formatTime(convertedTime) })
  }

  return (
    <div className="row" data-qa="opening-hours-entry">
      <div className="col-10 offset-1 col-md-5 offset-md-0">
        <label className="control-label" data-qa="day-buttons-title">
          <FormattedMessage {...messages.hcpEditWeekdays} />
        </label>
        <Field
          isReadOnly={isReadOnly}
          name={`${field.name}.days`}
          component={ButtonSelectField}
          className="btn-group-sm"
          buttons={dayButtons}
          data-qa="days-buttons"
        />
      </div>
      <div className="col-10 pl-0 offset-1 col-md-2 offset-md-0">
        <Field
          disabled={isReadOnly}
          name={`${field.name}.from`}
          label={formatMessage(messages.hcpEditOpenedFrom)}
          component={SelectField}
          data-qa="opened-from-select"
          options={timeSelector}
        />
      </div>
      <div className="col-10 pl-0 offset-1 col-md-2 offset-md-0">
        <Field
          disabled={isReadOnly}
          name={`${field.name}.to`}
          label={formatMessage(messages.hcpEditOpenedUntil)}
          component={SelectField}
          data-qa="opened-until-select"
          options={timeSelector}
        />
      </div>
      <div className="col-10 pl-0 offset-1 col-md-2 offset-md-0">
        <Field
          disabled={isReadOnly}
          name={`${field.name}.comment`}
          label={formatMessage(messages.hcpEditComment)}
          component={InputField}
          data-qa="comment-input"
        />
      </div>
      <div className="col-10 col-md-1 p-0">
        <StyledFontAwesomeIcon
          className="trashButton"
          disabled={isReadOnly}
          icon={faTrash}
          type="delete"
          data-qa="delete-opening-hours-entry"
          onClick={onDelete}
        />
      </div>
    </div>
  )
}

OpeningHoursField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired
}

export default injectIntl(OpeningHoursField)
