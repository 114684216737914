import { graphQLClient } from '../graphql/client'
import { DEFAULT_PRACTICE_QUERY, DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY } from '../graphql/queries/PracticesQuery'
import { ME_QUERY } from '../graphql/queries/MeQuery'
import { getToken } from './TokenUtil'
import { connector } from './Connector'

export const getDefaultPracticeId = () => {
  const { Me } = graphQLClient.readQuery({
    query: ME_QUERY
  })
  if (!Me) return null
  return Me.employee.defaultPracticeId
}

export const getEmployeeFromCache = () => {
  const { Me } = graphQLClient.readQuery({
    query: ME_QUERY
  })
  if (!Me) return null
  return Me.employee
}

export const getPracticeTimeZone = () => {
  const { defaultPractice } = graphQLClient.readQuery({
    query: DEFAULT_PRACTICE_QUERY
  })
  return defaultPractice.timeZone
}

export const getOrganizationIdFromCache = () => {
  const { Me } = graphQLClient.readQuery({
    query: ME_QUERY
  })
  if (!Me) return null
  return Me.organizationId
}

export const getPracticeDetailsFromCache = () => {
  const { defaultPractice } = graphQLClient.readQuery({
    query: DEFAULT_PRACTICE_QUERY
  })
  return defaultPractice
}

export const isAccountNumberStartsWithT = async () => {
  const defaultPracticeID = parseInt(getDefaultPracticeId())

  let cacheObj;
  try {
    const { defaultPracticeWithAccountType = {} } = graphQLClient.readQuery({
      query: DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY
    })
    cacheObj = defaultPracticeWithAccountType
  } catch (error) {
    cacheObj = null
  }

  if (cacheObj && cacheObj.defaultPracticeId !== defaultPracticeID || cacheObj === null) {
    const url = `/api/practices/${defaultPracticeID}`
    const response = await connector.get(getToken(), url)
    const data = {
      defaultPracticeWithAccountType: {
        __typename: 'defaultPracticeWithAccountType',
        defaultPracticeId: response.id,
        isAccountNumberStartsWithT: response.accountNumber.toUpperCase().startsWith("T")
      }
    }
    graphQLClient.writeQuery({
      query: DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY,
      data
    })
    return data.defaultPracticeWithAccountType?.isAccountNumberStartsWithT
  }
  return cacheObj?.isAccountNumberStartsWithT
}
