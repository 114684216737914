import styled from 'styled-components'

export const NavigationBarItem = styled.li`
	> a:hover {
		background-color: #e1e1e1 !important;
		border-radius: 15px;
	}

	> a {
		padding: 5px 18px !important;
		display: block;
	}
`
