import * as React from 'react'

class ImprintPage extends React.Component<Object, Object> {
  render() {
    return (
      <div className="container">
        <h2>Copyright</h2>
        <p>
          Signia TeleCare Portal <br />
          &copy; Sivantos Pte. Ltd. 2016-{new Date().getFullYear()} All Rights Reserved
        </p>
        _______________________________________________
        <h2>Corporate Information </h2>
        <p>
          <strong>Sivantos Pte. Ltd.</strong>
        </p>
        <strong>Managing Directors</strong>
        <p>
        Eric Bernard<br />
        Henrik Bender
        </p>
        <strong>Official Address</strong>
        <p>
          Sivantos Pte. Ltd.<br />
          18 Tai Seng Street, #08-08<br />
          Singapore 539775<br />
        </p>
        <strong>Contact</strong>
        <p>
          Phone:+65 6370 9666<br />
          Fax:+65 6773 8070<br />
          apps@sivantos.com<br />
        </p>
        <strong>Company Registration Number</strong>
        <p>RCB No. 198600657G</p>
        <h2>Legal Manufacturer of the app</h2>
        <p>
          <strong>Sivantos GmbH</strong>
        </p>
        <strong>Managing Directors</strong>
        <p>
          Christian Honsig<br />
          Stefan Menzl
        </p>
        <strong>Address</strong>
        <p>
          Henri-Dunant-Str. 100<br />
          D-91058 Erlangen<br />
          Germany
        </p>
        <strong>Contact</strong>
        <p>
          Phone: +49 9131 308-0<br />
          Fax: +49 9131 308-3226<br />
          info.de@sivantos.com
        </p>
        <strong>Commercial Register</strong>
        <p>Registergericht Fuerth, Register Nr. HRB 3825</p>
        <strong>VAT ID</strong>
        <p>USt.ID: DE811148402</p>
        <p>
          <strong>Made in Germany</strong>
        </p>
      </div>
    )
  }
}

export default ImprintPage
