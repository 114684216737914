import React, { Component } from 'react'
import LatestHearingAid from 'components/_Profile/components/LatestHearingAid'

export class HearingAidStatus extends Component {
  render() {
    const { hearingAidStatusTitle, data, updateProgram, modifier = '' } = this.props
    return (
      <article
        className={`hearing-aid-status ${modifier ? `hearing-aid-status--${modifier}` : ''}`}
      >
        <div className="hearing-aid-status__inner">
          <LatestHearingAid title={hearingAidStatusTitle} program={data} update={updateProgram} />
        </div>
      </article>
    )
  }
}
