/* eslint-disable */
import { assign } from 'lodash'

const yellow200 = '#FFD500'
const deepOrange600 = '#EF7D00'
const lime300 = '#78B928'
const lightGreen500 = '#8BC34A'
const teal700 = '#00796B'
const cyan900 = '#006064'

const deepRed = '#A00F43'
const green = '#78B928'
const lightblue = '#00ACE9'
const purple = '#5C2483'
const yellow = '#FFD500'
const orange = '#EF7D00'

const colors = [yellow, orange, green, lightblue, purple, deepRed]

const charcoal = '#252525'
// *
// * Typography
// *
const baseFont = 'Montserrat Regular'
const letterSpacing = 'normal'
const fontSize = 14
// *
// * Layout
// *
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: colors
}
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: baseFont,
  fontSize,
  letterSpacing,
  padding: 10,
  fill: charcoal,
  stroke: 'transparent'
}

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)
// *
// * Strokes
// *
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export default {
  area: assign(
    {
      style: {
        data: {
          fill: charcoal
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  axis: assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding: 25
        }),
        grid: {
          fill: 'transparent',
          stroke: 'transparent',
          pointerEvents: 'none'
        },
        ticks: {
          fill: 'transparent',
          size: 1,
          stroke: 'transparent'
        },
        tickLabels: baseLabelStyles
      }
    },
    baseProps
  ),
  bar: assign(
    {
      style: {
        data: {
          fill: charcoal,
          padding: 10,
          strokeWidth: 0,
          width: 8
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  candlestick: assign(
    {
      style: {
        data: {
          stroke: charcoal,
          strokeWidth: 1
        },
        labels: centeredLabelStyles
      },
      candleColors: {
        positive: '#ffffff',
        negative: charcoal
      }
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 2
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  group: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  line: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 2
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: 'transparent',
        strokeWidth: 1
      },
      labels: assign({}, baseLabelStyles, { padding: 20 })
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50
  },
  scatter: assign(
    {
      style: {
        data: {
          fill: charcoal,
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  stack: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  tooltip: {
    style: assign({}, centeredLabelStyles, { padding: 5, pointerEvents: 'none', fill: '#f0f0f0' }),
    flyoutStyle: {
      stroke: charcoal,
      strokeWidth: 0,
      fill: '#f0f0f0',
      pointerEvents: 'none'
    },
    cornerRadius: 5,
    pointerLength: 10
  },
  voronoi: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: assign({}, centeredLabelStyles, { padding: 5, pointerEvents: 'none' }),
        flyout: {
          stroke: charcoal,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none'
        }
      }
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    style: {
      data: {
        type: 'circle'
      },
      labels: baseLabelStyles
    }
  }
}
