import { gql } from "@apollo/client";
import { graphQLClient } from 'graphql/client'
import { trim } from 'lodash'

export const validatePhoneNumber = async ({ countryCode = '', phoneNumber = '' }) => {
  if (!!trim(phoneNumber)) {
    try {
      const result = await graphQLClient.query({
        query: QUERY,
        variables: {
          countryCode,
          phoneNumber,
          hasInput: !!phoneNumber
        }
      })

      const { data: { PhoneValidation: { canonicalPhoneNumber = null } } } = result
      return canonicalPhoneNumber
    } catch (e) {
      console.log(e)
      throw e
    }
  } else {
    throw new Error('Invalid Phone Number')
  }
}

export const QUERY = gql`
  query PhoneValidationQuery($countryCode: String!, $phoneNumber: String!, $hasInput: Boolean!) {
    PhoneValidation(countryCode: $countryCode, phoneNumber: $phoneNumber) @include(if: $hasInput) {
      canonicalPhoneNumber
    }
  }
`
