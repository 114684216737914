import React from 'react'
import { StyledLink } from '../../../styledComponents/Navigation/StyledLink'
import { StyledMenu, StyledMenuItem } from '../../../styledComponents/Navigation/StyledList'

export const PatientNavigationItem = ({
  active,
  href,
  label,
  className = '',
  dataqa = ''
}) => {
  className = className || 'col-md-2'

  //may be super bad regex - but in the hurry now
  const lastIndex = href.indexOf('?') > -1 ? href.indexOf('?') : href.length
  const isActive = active.search(href.substr(0, lastIndex)) > -1

  return (
    <StyledMenuItem key={href} className={`${className} ${isActive ? 'active' : ''}`}>
      <StyledLink
        data-qa={dataqa}
        to={href}
      >
        {label}
      </StyledLink>
    </StyledMenuItem>
  )
}

export const PatientNavigation = ({ children }) => {
  return (
    <article className="patient-navigation">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <StyledMenu data-qa="patient-navigation_links" className="row profilePage">
              {children}
            </StyledMenu>
          </div>
        </div>
      </div>
    </article>
  )
}

export default PatientNavigation
