import React from 'react'
import Tooltip from 'react-tooltip'

const TooltipFunction = ({ title, subtitle, id, place = 'bottom' }) => (
	<Tooltip className="custom-tooltip" effect="solid" place={place} id={id}>
		<div className="title">{title}</div>
		<div className="subtitle">{subtitle}</div>
	</Tooltip>
)

export default Tooltip
