import styled, { css } from "styled-components";
import React from "react";
import chatIcon from "../../assets/img/icon_chat.png";

const ChatMessage = styled.div`
  background-image: url(${chatIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  margin-top: 5px;
`;

const MessageCount = styled.div((props) => {
  const {
    theme: {
      colors: {
        icons: {
          chatMessageCount: { color: chatMessageCountBackgroundColor },
        },
      },
    },
  } = props;

  return css`
    font-weight: bold;
    color: white;
    background: ${chatMessageCountBackgroundColor};
    width: 18px;
    height: 18px;
    position: relative;
    top: -8px;
    left: 10px;
    border: 1px solid white;
    font-size: 12px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
  `;
});

export const StyledChatMessageIcon = (props) => {
  return (
    <ChatMessage>
      <MessageCount {...props} />
    </ChatMessage>
  );
};
