import React from 'react'

const ChannelStepper = ({ values, onClick }) => {
  if (!onClick) {
    onClick = () => {}
  }

  return (
    <div className="channel-stepper">
      <button
        type="button"
        data-qa="channel-stepper__handler increase"
        className="channel-stepper__handler"
        onClick={() => {
          onClick('increase')
        }}
      >
        <i className="fas fa-chevron-up" />
      </button>
      <div data-qa="channel-stepper__value" className="row">
        {values.map((value, index) =>
          <div key={`${index}-channel-stepper`} className={`col-md-${12 / values.length}`}>
            {value}
          </div>
        )}
      </div>
      <button
        type="button"
        data-qa="channel-stepper__handler decrease"
        className="channel-stepper__handler"
        onClick={() => {
          onClick('decrease')
        }}
      >
        <i className="fas fa-chevron-down" />
      </button>
    </div>
  )
}

export default ChannelStepper
