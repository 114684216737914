import { connector } from 'helper/Connector'
import { getToken } from 'helper/AuthHelper'
import { CorrelationContext } from 'helper/constants'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'

export const updateEqualizerSetting = (customerId, equalizerSetting, SupportId) => {
  FlushEventsToAppInsight('Save and Send Remote Tuning')
  return connector
    .postWithCorrelation(
      getToken(),
      `/api/patients/${customerId}/equalizer-settings`,
      CorrelationContext['RemoteTuning_SaveSettings'] + SupportId,
      equalizerSetting
    )
    .then(response => response)
}

export const getProgramStatus = (customer, programNumber) => {
  const token = getToken()
  const endpoint = `/hcp/api/v1/customers/${ customer && customer.id}/programstatus`

  return connector.get(token, endpoint, { programNumber })
    .then(payload => payload.applied)
    .catch(() => '')
}