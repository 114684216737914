import React, { FC, ReactElement } from "react";
import { StyledCardContainer } from "styledComponents/Div/DivContainer/DivStyledCard";
import { FormattedMessage } from "react-intl";
import { T } from "core";
import { getLocaleFromCache } from "helper/LocaleHelper";

const WidthHigherBound: number = 8;
const WidthMidBound: number = 4;
const WidthLowerBound: number = 0;

interface IStandardCalculationProps {
  lastThreeDaysAverageHours: number,
  lastSevenDaysAverageHours: number,
  lastThirtyDaysAverageHours: number,
  sinceFirstDayAverageHours: number
}

interface IProps {
  StandardCalculation: IStandardCalculationProps
}
const checkAllStandardCalculationIsAvailable = (lastThreeDaysAverageHours: number, lastSevenDaysAverageHours: number, lastThirtyDaysAverageHours: number,
  sinceFirstDayAverageHours: number) => {
  if (lastThreeDaysAverageHours > 0 || lastSevenDaysAverageHours > 0 || lastThirtyDaysAverageHours > 0 ||
    sinceFirstDayAverageHours > 0) {
    return true;
  }
  return false;
}

const checkAnyStandardCalculationIsNotAvailable = (lastThreeDaysAverageHours: number, lastSevenDaysAverageHours: number, lastThirtyDaysAverageHours: number,
  sinceFirstDayAverageHours: number) => {
  if (lastThreeDaysAverageHours < 0 || lastSevenDaysAverageHours < 0 || lastThirtyDaysAverageHours < 0 ||
    sinceFirstDayAverageHours < 0) {
    return true;
  }
  return false;
}

export const StandardCalculation: FC<any> = ({ StandardCalculation }: IProps): ReactElement => {
  return <div className="standard-calculation">
    <StyledCardContainer>
      <div className="practice">
        <div className="practice__header">
          <div className="practice__text">
            <FormattedMessage {...T("wearingTime.StandardCalculation.Header")} />
          </div>
          <div >
            <FormattedMessage {...T("wearingTime.StandardCalculation.AverageHoursPerDay")} />
          </div>
        </div>
        <div className="practice__body">
          {ProgressBarComponent(StandardCalculation.lastThreeDaysAverageHours, "wearingTime.StandardCalculation.Last3Days")}
          {ProgressBarComponent(StandardCalculation.lastSevenDaysAverageHours, "wearingTime.StandardCalculation.Last7Days")}
          {ProgressBarComponent(StandardCalculation.lastThirtyDaysAverageHours, "wearingTime.StandardCalculation.Last30Days")}
          {ProgressBarComponent(StandardCalculation.sinceFirstDayAverageHours, "wearingTime.StandardCalculation.SinceFirstDay")}
          {checkAllStandardCalculationIsAvailable(StandardCalculation.lastThreeDaysAverageHours,
            StandardCalculation.lastThreeDaysAverageHours, StandardCalculation.lastThirtyDaysAverageHours,
            StandardCalculation.sinceFirstDayAverageHours) && <div className="progress-indicator">
              8 h
            </div>}
          {checkAnyStandardCalculationIsNotAvailable(StandardCalculation.lastThreeDaysAverageHours,
            StandardCalculation.lastThreeDaysAverageHours, StandardCalculation.lastThirtyDaysAverageHours,
            StandardCalculation.sinceFirstDayAverageHours) && <div className="not-available-note help-block">
              <FormattedMessage {...T("wearingTime.StandardCalculation.NotAvailableReason")} />
            </div>}
        </div>
      </div>
    </StyledCardContainer>
  </div>
}

const getWidthPercentage = (hours: number): number => {
  if (hours < 0) return 0;
  return Math.round((hours / WidthHigherBound * 100))
}

const getClassForSpecificHours = (hours: number): string => {
  if (hours > WidthLowerBound && hours < WidthMidBound) {
    return "progress-bar-danger"
  }
  else if (hours >= WidthMidBound && hours < WidthHigherBound) {
    return "progress-bar-warning"
  }
  else if (hours >= WidthHigherBound) {
    return "progress-bar-success"
  }
}

const ProgressBarComponent = (hours: number, daysHeaderKey: string) => {
  const width = getWidthPercentage(hours);
  const classForSpecificHours = getClassForSpecificHours(hours)
  const locale = getLocaleFromCache();
  return (
    <div className="progress-wrapper-div">
      <div className="progress-bar-text"><FormattedMessage {...T(daysHeaderKey)} /></div>
      {hours > 0 ? <div className="standard-calculation-progress-bar">
        <div className="progress">
          <div className={`progress-bar ${classForSpecificHours}`} role="progressbar" style={{ width: `${width}%` }}
            aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}></div>
        </div>
      </div> :
        <div className="progress-no-data">
          <FormattedMessage {...T("wearingTime.StandardCalculation.NotAvailable")} />
        </div>
      }
      <div className="progress-bar-time">
        {hours > 0 ? <>{hours.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <FormattedMessage {...T("WearingTimeChart.hours")} /></> : <></>}
      </div>
    </div>
  )
}