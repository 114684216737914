//@flow

import React from 'react'
import { identity } from 'lodash'
import validate from 'helper/validate'
import { Input } from 'styledComponents/Input'

type Props = {
  dataQA: string,
  defaultValue: string,
  id: string,
  isValid: Boolean,
  onBlurred: Function,
  onDirty: Function,
  onRegister: Function,
  onValidation: Function,
  placeholder: string,
  requirements: Array<any>,
  required: ?Boolean,
  type: 'password' | 'text' | 'number',
  width: string
}
class Text extends React.Component {
  props: Props

  static defaultProps = {
    onBlurred: identity,
    onDirty: identity,
    onValidation: identity,
    onRegister: identity,
    type: 'text',
    dataQA: '' // usefull for QA testing
  }

  input: Object = {}
  isValid = false
  handleBlurred = identity
  handleDirty = identity

  constructor(props: Object): any {
    super(props)
    this.isValid = props.isValid
    this.handleDirty = props.onDirty.bind(null, props.id)
    this.handleBlurred = props.onBlurred.bind(null, props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Object) {
    if (this.input && nextProps.defaultValue !== this.props.defaultValue) {
      this.input.value = nextProps.defaultValue
      this.isValid = nextProps.isValid
    }
  }

  handleRegister = (ref: ?Object) => {
    if (!ref) return
    this.input = ref
    this.props.onRegister(ref, this.isValid, this.resetField, this.setValue)
  }

  checkValidationState = () => {
    this.updateIsValid(this.input.value)
    this.handleDirty()
  }

  updateIsValid(value: string) {
    const { id, onValidation, requirements = [], required = false } = this.props

    if (required) {
      requirements.unshift('isRequired')
    }
    const isValid = validate(value, requirements)

    onValidation(id, isValid)
    this.isValid = isValid
  }

  resetField = () => this.setValue('')

  setValue: Function = value => {
    this.input.value = value
    this.checkValidationState()
    this.forceUpdate()
  }

  render() {
    const { defaultValue, id, placeholder, type, width, dataQA } = this.props
    return (
      <Input
        width={width}
        isValid={this.isValid}
        ref={this.handleRegister}
        type={type}
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onBlur={this.handleBlurred}
        onChange={this.checkValidationState}
        data-qa={dataQA}
        {...(type === 'number' ? { min: 0 } : {})}
      />
    )
  }
}

export default Text
