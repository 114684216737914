import React from 'react'
import { Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Container } from 'styledComponents/Div/DivComponent/DivProfile'

const getRatingTextAndColor = rating => {
  switch (rating) {
    case 1:
      return {
        text: <FormattedMessage {...T('SatisfactionChart.veryunsatisfied')} />,
        style: { color: 'rgb(225,36,63)' }
      }
    case 2:
      return {
        text: <FormattedMessage {...T('SatisfactionChart.unsatisfied')} />,
        style: { color: 'rgb(202, 131, 0)' }
      }
    case 3:
      return {
        text: <FormattedMessage {...T('SatisfactionChart.neutral')} />,
        style: { color: 'rgb(255,190,66)' }
      }
    case 4:
      return {
        text: <FormattedMessage {...T('SatisfactionChart.satisfied')} />,
        style: { color: 'rgb(0,190,127)' }
      }
    case 5:
      return {
        text: <FormattedMessage {...T('SatisfactionChart.verysatisfied')} />,
        style: { color: 'rgb(0,136,88)' }
      }
    default:
      return {
        text: <FormattedMessage {...T('Dashboard.n/a')} />,
        style: { color: '#000000' }
      }
  }
}

export const SatisfactionItem = ({ title, averageRating }) => {
  title = title || <FormattedMessage {...T('customerProfile.wearingTime.overAll')} />
  const rating = getRatingTextAndColor(averageRating)

  return (
    <div>
      <h2>
        {title}
      </h2>
      <div>
        <span>
          <FormattedMessage {...T('customerProfile.wearingTime.average')} />:{' '}
        </span>
        <span style={rating.style}>
          <b>
            {rating.text}
          </b>
        </span>
      </div>
    </div>
  )
}

export const SatisfactionList = ({ items = [] }) => {
  return (
    <Container>
      {items.map((item, index) => {
        return (
          <Col key={`satisfactionitem-${index}`}>
            <SatisfactionItem {...item} />
          </Col>
        )
      })}
    </Container>
  )
}
