import { gql } from "@apollo/client";

export const HEARING_AID_STATUS = gql`
  query ($patientId: Int!, $skip: Boolean!) {
    HearingAidStatus(customerId: $patientId) @skip(if: $skip) {
      acousticEnvironmentData {
        bar {
          chat
          sound
          wind
        }
        entries {
          car
          music
          noise
          quiet
          speechInNoise
          speechInQuiet
        }
      }
      programName
      soundBalance {
        max
        value
      }
      updatedDate
      volume {
        max
        value
      }
    }
  }
`