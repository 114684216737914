import React from 'react'
import { identity, find, isNil } from 'lodash'
import validate from 'helper/validate'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { DropdownContainer } from 'styledComponents/Div/DivComponent/DivComponent'

class DropDown extends React.Component {
  static defaultProps = {
    defaultDropdownTitle: null,
    id: null,
    isDisabled: false,
    onBlurred: identity,
    onDirty: identity,
    onRegister: identity,
    onSelect: identity,
    onValidation: identity,
    options: [], // a list of options having value and text as a property
    requirements: [], // a list of requirements this component should fulfill to be valid
    selected: 0, // it can be number or string
    scrollable: false,
    height: 'auto',
    dataQA: '' // usefull for QA testing
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.selected || '',
      valueType:
        !props.options[0] || props.selected !== 0
          ? typeof props.selected
          : typeof props.options[0].value
    }
    this.handleDirty = props.onDirty.bind(null, props.id)
    this.handleBlurred = props.onBlurred.bind(null, props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requirements = [] } = this.props

    if (nextProps.selected !== this.props.selected || nextProps.requirements !== requirements) {
      this.setState({ value: nextProps.selected || '' }, () =>
        this.updateIsValid(nextProps.selected)
      )
    }
  }

  // todo: think about naming
  updateIsValid(value) {
    const { id, onValidation, requirements = [], required = false } = this.props

    if (required) {
      requirements.unshift('isRequired')
    }

    const isValid = validate(value, requirements)

    if (this.isValid !== isValid) {
      onValidation(id, isValid)
      this.isValid = isValid
    }
  }

  handleRegister = ref => {
    if (!ref) return
    this.isValid = validate(ref.value, this.props.requirements)
    this.input = ref
    this.props.onRegister(ref, this.isValid, this.resetField, this.setValue)
  }

  checkValidationState = () => {
    const { value } = this.input

    this.updateIsValid(value)
    this.handleDirty()
  }

  getDropDownTitle = (selectedOption, defaultTitle) =>
    selectedOption.text
      ? <div>
        <span>
          {selectedOption.text}
        </span>
        <span className="caret" />
      </div>
      : <div>
        <span className="defaultTitle">
          {defaultTitle}
        </span>
        <span className="caret" />
      </div>

  renderOptions = (options, selected) =>
    options.map((option, index) =>
      <Dropdown.Item
        active={option.value === selected}
        disabled={option.disabled}
        eventKey={option.value}
        key={`option-${option.value}-${index}`}
        data-qa={`${this.props.id}--${option.value}`}
      >
        {option.text}
      </Dropdown.Item>
    )

  setValue = value => {
    const selectedValue = isNil(value) ? '' : value
    let convertedValue = selectedValue
    if (this.state.valueType === 'string') {
      convertedValue = String(selectedValue)
    } else if (this.state.valueType === 'number') {
      convertedValue = Number(selectedValue)
    }
    this.setState({ value: convertedValue }, () => {
      this.checkValidationState()
      this.props.onSelect(convertedValue)
    })
  }

  resetField = () => this.setValue('')

  render() {
    const { defaultDropdownTitle, isDisabled, options, id, scrollable, height, dataQA, borderRadius } = this.props
    const selectedOption = find(options, ['value', this.state.value]) || {}
    return (
      <DropdownContainer scrollable={scrollable} height={height} borderRadius={borderRadius || '20px'} disabled={isDisabled || options.length === 0}>
        <DropdownButton
          variant={'default'}
          id={`${id}-dropdown`}
          disabled={isDisabled || options.length === 0}
          onSelect={this.setValue}
          title={this.getDropDownTitle(selectedOption, defaultDropdownTitle)}
          onBlur={this.handleBlurred}
          data-qa={`${id}-dropdown`}
        >
          <input
            id={id}
            type="hidden"
            ref={this.handleRegister}
            value={this.state.value}
            data-qa={dataQA}
          />
          {this.renderOptions(options)}
        </DropdownButton>
      </DropdownContainer>
    )
  }
}

export default DropDown
