import React from 'react'
import { FormattedNumber } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import isNil from 'lodash/isNil'
import { T } from 'core'
import { PracticeKpi } from 'styledComponents/Div/DivContainer/DivPractice'
import Tooltip from 'components/Tooltip'

class PracticeKpiComponent extends React.Component {
	render() {
		const { type, value, tooltip, isMarketAverage, id, formatMessage } = this.props

		let indicator = 'neutral'

		if (type === 'patientsGained' && value === 0) {
			indicator = 'negative'
		}

		if (isMarketAverage) {
			indicator = type === 'patientsGained' ? 'neutral' : 'positive'
		}

		if (isNil(value)) {
			indicator = 'inactive'
		}

		return (
			<PracticeKpi>
				<div
					className={`practice-kpi practice-kpi__indicator--${indicator}`}
					data-tip
					data-for={id}
				>
					<KpiValue value={value} type={type} />
					<Tooltip
						id={id}
						title={formatMessage(T(tooltip.title.props.id))}
						subtitle={formatMessage(T(tooltip.subtitle.props.id))}
					/>
				</div>
			</PracticeKpi>
		)
	}
}

const KpiValue = ({ value, type }) => {
	let prefix = ''
	let suffix = ''

	switch (type) {
		case 'processDuration':
			suffix = <FormattedMessage {...T('common.days.d')} />
			break
		case 'patientsGained':
			prefix = '+'
			break
		case 'successRate':
			suffix = '%'
			break
	}

	const renderValue = isNil(value) ? (
		<FormattedMessage {...T('Dashboard.n/a')} />
	) : (
		<span>
			{prefix}
			<FormattedNumber value={value.toFixed(2)} />
			{suffix}
		</span>
	)

	return (
		<div>
			<div className="practice-kpi__icon">
				<div className={`practice-kpi__icon--${type}`} />
			</div>
			<div className="practice-kpi__value">{renderValue}</div>
		</div>
	)
}

export default PracticeKpiComponent
