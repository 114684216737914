import styled from 'styled-components'
import MainHeader from '../../../assets/img/maintenance_page.jpg'

export const HeaderContainer = styled.div`
  margin: auto;
  display: block;
  position: relative;

  :: before {
    content: '';
    position: absolute;
    opacity: 0.04;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: url(${MainHeader});
    background-repeat: no-repeat;
    background-position: center;
  }
`
export const StyledContainer = styled.div`
  margin-top: -150px;
  overflowX: 'hidden';
`

export const FlexRow = styled.div`
  margin-top: 15%;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`
