import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { FormattedDate, FormattedTime } from 'react-intl'
import { T } from 'core'

import SoundStatus from 'components/_Profile/components/SoundStatus'

const LatestHearingAid = ({ title = '', program }) => {
  program = program || { programName: '' }
  const { soundBalance = {}, volume = {}, updatedDate } = program

  const renderTitle = () => {
    return (
      <h2 data-qa="latest-hearing-aid__title" className="latest-hearing-aid__title">
        <FormattedMessage {...T('hearing-aid.latest-status-title')} />
        {updatedDate &&
          <span className="latest-hearing-aid__title-lead">
            {` (`}
            <FormattedMessage {...T('hearing-aid.latest-status-title.lead')} />
            {` `}
            <FormattedDate value={moment(updatedDate)} />
            {`, `}
            <FormattedTime value={moment(updatedDate)} />
            {`) `}
          </span>}
      </h2>
    )
  }

  const renderNoDate = (
    <div className="row">
      <div className="col-lg-12">
        <h2 data-qa="latest-hearing-aid__no-data" className="latest-hearing-aid__no-data">
          <FormattedMessage {...T('ratings.status')} />
        </h2>
      </div>
    </div>
  )

  const render = (
    <div className="latest-hearing-aid">
      <div className="row">
        <div className="col-lg-9">
          {title
            ? <h2 data-qa="latest-hearing-aid__title" className="latest-hearing-aid__title">
                {title}
              </h2>
            : renderTitle()}
        </div>
        <div className="col-4">
          <h3 data-qa="latest-hearing-aid__type-title" className="latest-hearing-aid__type-title">
            <FormattedMessage {...T('hearing-aid.latest-status-program')} />
          </h3>
          <p data-qa="latest-hearing-aid__type-lead" className="latest-hearing-aid__type-lead">
            <b>
              <FormattedMessage {...T(`hearing_programs.${program.programName.toLowerCase()}`)} />
            </b>
          </p>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-lg-6">
              <SoundStatus
                max={volume.max}
                label={<FormattedMessage {...T('volume_control.volume')} />}
                value={volume.value}
              />
            </div>
            <div className="col-lg-6">
              <SoundStatus
                max={soundBalance.max}
                label={<FormattedMessage {...T('volume_control.sound-balance')} />}
                value={soundBalance.value}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return Object.keys(program).length ? render : renderNoDate
}

export default LatestHearingAid
