import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import PracticeKpi from '../PracticeKpi'
import { Practice } from 'styledComponents/Div/DivContainer/DivPractice'
import Tooltip from 'components/Tooltip'

const PracticeComponent = ({
	name,
	patients,
	items,
	modifier,
	isMarketAverage = false,
	formatMessage
}) => {
	return (
		<Practice>
			<div className={`practice ${modifier ? `practice--${modifier}` : ''}`}>
				<div className="practice__header">
					<div className="practice__text--truncate">{name}</div>
					<PracticePatientsOverall patients={patients} formatMessage={formatMessage} />
				</div>
				<div className="practice__body">
					{items.map((item, i) => (
						<PracticeKpi
							key={`practice-kpi-${i}`}
							type={item.type}
							value={item.value}
							invert={item.invert}
							tooltip={item.tooltip}
							isMarketAverage={isMarketAverage}
							id={`${name}-${i}`}
							formatMessage={formatMessage}
						/>
					))}
				</div>
			</div>
		</Practice>
	)
}

const PracticePatientsOverall = ({ patients, formatMessage }) => {
	if (patients === null || patients === undefined) {
		patients = <FormattedMessage {...T('Dashboard.n/a')} />
	}

	return (
		<div className="practice__patients-overall">
			<div data-tip data-for="kpi-tt">
				<span className="practice__patients-overall-value">{patients}</span>
				<span className="fas fa-users fa-lg"/>
				<Tooltip
					id="kpi-tt"
					title={formatMessage(T('Dashboard.patients.overall'))}
					subtitle={formatMessage(T('Dashboard.patients.overall.info'))}
				/>
			</div>
		</div>
	)
}

export default PracticeComponent
