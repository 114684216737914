import React, { FC, ReactElement, useEffect, useState } from "react"
import { StyledCardContainer } from 'styledComponents/Div/DivContainer/DivStyledCard'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { getLocaleFromCache } from 'helper/LocaleHelper'
import DateSelector from 'components/DateSelector'
import AverageIcon from 'assets/img/icons/icon_average.svg'
import { calculateAverageToTwoDecimalPlaces } from 'helper/Utils'
import { ConvertToFormattedDate, getTotalDaysBetweenTwoDate } from "helper/DateUtils";

const IndividualCalculation: FC<any> = ({ individualCalculationData, ...props }): ReactElement => {

  const { intl: { formatMessage } } = props
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [averageWearingHours, setAverageWearingHours] = useState('-')
  const [includeDates, setIncludeDates] = useState([])

  const locale = getLocaleFromCache()

  useEffect(() => {
    let dates = []

    individualCalculationData && Object.keys(individualCalculationData).map((entry) => {
      dates.push(new Date(entry))
    })

    setIncludeDates(dates)
  }, [individualCalculationData])

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const start = ConvertToFormattedDate(startDate)
      const end = ConvertToFormattedDate(endDate)

      const matchingEntryStartDate = individualCalculationData[start];
      const matchingEntryEndDate = individualCalculationData[end];

      const oldestWearingHours = matchingEntryStartDate[1] == null
        ? matchingEntryStartDate[0].wearingTimeInHr
        : matchingEntryStartDate[1].wearingTimeInHr

      const latestWearingHours = matchingEntryEndDate[0].wearingTimeInHr

      const numberOfDays = getTotalDaysBetweenTwoDate(startDate, endDate) + 1 // since both dates are inclusive, hence +1

      const wearingHours = calculateAverageToTwoDecimalPlaces(latestWearingHours, oldestWearingHours, numberOfDays)
      setAverageWearingHours(wearingHours.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    }
  }, [startDate, endDate])

  return (
    <StyledCardContainer>
      <div className="practice individualCalculation">
        <div className="practice__header">
          <div className="practice__text--truncate">
            <FormattedMessage {...T("wearingTime.IndividualCalculation.Header")} />
          </div>
          <div>
            <span className="span-align-items">
              <img src={AverageIcon} style={{ 'height': '1.3rem' }} />
              <span >{averageWearingHours}</span>
              <span>
                <FormattedMessage {...T("wearingTime.IndividualCalculation.HoursPerDay")} />
              </span>
            </span>
          </div>
        </div>
        <div className="practice__body">
          <div className="body">
            <div className="row align-items-center justify-content-center">
              <div className="col-4 label-position">
                <FormattedMessage {...T("wearingTime.IndividualCalculation.StartDate")} />
              </div>
              <div className="col date-position">
                <DateSelector
                  onChange={setStartDate}
                  placeholderText={formatMessage(T('wearingTime.IndividualCalculation.SelectStartDate'))}
                  includeDates={includeDates}
                  maxDate={endDate}
                  locale={locale}
                />
              </div>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-4 label-position">
                <FormattedMessage {...T("wearingTime.IndividualCalculation.EndDate")} />
              </div>
              <div className="col date-position">
                <DateSelector
                  onChange={setEndDate}
                  placeholderText={formatMessage(T('wearingTime.IndividualCalculation.SelectEndDate'))}
                  includeDates={includeDates}
                  minDate={startDate}
                  locale={locale}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledCardContainer>
  )
}

export default injectIntl(IndividualCalculation)