import { DataCollectionType } from 'helper/constants'
import * as payloads from './DataCollection/Payloads'

export const getTrackerForm = async (documentType, payloadStructure, eventType) => {
  return getPayload(documentType, payloadStructure, eventType)
}

const getPayload = async (documentType, payloadStructure, eventType) => {
  switch (documentType) {
    case DataCollectionType.ChatData:
      return payloads.getChatPayload(payloadStructure)
    case DataCollectionType.OrgData:
      return payloads.getOrgPayload(payloadStructure)
    case DataCollectionType.LanguageData:
    case DataCollectionType.LogOut:
    case DataCollectionType.Navigation:
    case DataCollectionType.Crud:
    case DataCollectionType.MarketingMessage:
    case DataCollectionType.RemoteTuning:
    case DataCollectionType.PlatformData:
    case DataCollectionType.PatientData:
    case DataCollectionType.Notifications:
      return payloads.getPayload(payloadStructure, eventType)
    default:
      return {}
  }
}


