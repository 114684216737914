import { gql } from "@apollo/client";

export const QUERY_LESSONSET_FOR_SIGNIA_PATIENT = gql`
  query LessonsForSigniaPatient($patientGuid: String, $hcpId: Int) {
    LessonsForSigniaPatient(patientGuid: $patientGuid) {
      lessonId
      heading
      description
      howTo
      title
      lessonCategory {
        categoryName
        description
        orderId
      }
    }
    DefaultLessonSetForSignia {
      lessonId
      heading
      description
      howTo
      title
      lessonCategory {
        categoryName
        description
        orderId
      }
    }
    LessonSetForSignia(hcpId: $hcpId) {
      id
      lessonSetName
      lessonIds
      comment
      isDefault
      createdDate
    }
  }
`
