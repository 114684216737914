import React from 'react'
import FormGroup from 'components/FormGroup'
import Text from 'components/Text'

const InputText = props => {
  const { label, defaultValue, placeholder, id, requirements, required = false, ...rest } = props

  return (
    <FormGroup label={label} id={id} required={required} {...rest}>
      <Text
        placeholder={placeholder}
        defaultValue={defaultValue}
        required={required}
        requirements={requirements}
      />
    </FormGroup>
  )
}
export default InputText
