import { gql } from "@apollo/client";

export const QUERY_HDMODELS_FEATURES = gql`
  query DeviceModelsFeaturesQuery(
    $leftId: String!
    $rightId: String!
    $hasSelectedModels: Boolean!
  ) {
    HDModelsFeatures(leftModelId: $leftId, rightModelId: $rightId)
      @include(if: $hasSelectedModels) {
      availablePrograms
      bluetoothSupported
      defaultPrograms
      availableTeleCareVersions
      programSlots
      remoteFittingSupported
      defaultAvailableTeleCareVersion
    }
  }
`