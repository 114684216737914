import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import Navigation from './Navigation'
import * as Panel from 'components/_Profile/components/Panel'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { customerTracking } from 'helper/customerTracking'
import { getDefaultPracticeId } from 'helper/PracticeUtils'
import { compose } from 'lodash/fp'
import { QUERY_PRACTICE_DETAILS } from 'graphql/queries/PracticesQuery'
import { Outlet } from 'react-router-dom';
import { withRouter } from "helper/withRouter"
import DataContext from "containers/DataContext"

class OverViewPage extends React.Component {
  static contextType = DataContext
  componentDidMount() {
    customerTracking({
      ...this.props.location,
      action: 'customerProfileOpened'
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.Practice != nextProps.Practice) {
      this.setDefaultPractice(nextProps.Practice)
    }
  }

  setDefaultPractice(practice) {
    this.props.setDefaultPractice({ defaultPractice: { ...practice } })
    return true
  }

  render() {
    const { loading, location, params } = this.props

    return (
      <section className="ratings container">
        {!loading &&
          <Panel.Base modifier="ratings">
            <Panel.Item className="row">
              <div className="col-lg-12">
                <h2>
                  <FormattedMessage {...T('CustomerProfile.DailyEvaluation')} />
                </h2>
              </div>
            </Panel.Item>
            <Panel.Item className="row">
              <div className="col-lg-9">
                <Navigation
                  active={location.pathname}
                  customerId={params.id}
                  customer={this.context.customer}
                />
              </div>
            </Panel.Item>
            <Panel.Item className="row">
              <div className="col-lg-12">
                <main>
                  <Outlet />
                </main>
              </div>
            </Panel.Item>
          </Panel.Base>
        }
      </section>
    )
  }
}

const defaultPracticeQuery = gql`
  mutation setDefaultPracticeMutation($defaultPractice: defaultPractice!) @client {
    setDefaultPractice(defaultPractice: $defaultPractice) {
      defaultPractice
    }
  }
`

const mapProps = ({ data: { refetch, loading, Practice } }) => {
  return {
    refetch,
    loading,
    Practice
  }
}
const options = ({ params }) => ({
  variables: { practiceId: parseInt(getDefaultPracticeId()) },
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_PRACTICE_DETAILS, {
    props: mapProps,
    options
  }),
  graphql(defaultPracticeQuery, {
    props: ({ mutate }) => ({
      setDefaultPractice: defaultPractice => mutate({ variables: { defaultPractice } })
    })
  })
)
export default withData(withRouter(OverViewPage))