import { gql } from "@apollo/client";

export const MUTATION_SEND_MESSAGE = gql`
  mutation sendMarketingMessage($message: String!, $filters: String, $employeeId: Int) {
    sendMarketingMessage(
      message: $message
      filters: $filters
      employeeId: $employeeId
    )
  }
`;
