import React from 'react'

const SoundStatus = ({ max = 10, label, value }) => {
  const progress = Math.round(Math.abs(value) / max * 100)

  return (
    <div className="sound-level" data-qa="sound-level">
      {label &&
        <h3 className="sound-level__title">
          {label}
        </h3>}
      <div className="row">
        <h3 className="col-md-2 sound-level__value">{`${value > 0 ? `+${value}` : value}dB`}</h3>
        <div className="col-md-10">
          <div className="progress-base">
            <div className="progress progress--sound" />
            <div
              className={`progress__status ${value > 0
                ? 'progress__status--right'
                : 'progress__status--left'}`}
              style={{ width: `${progress / 2}%` }} // divided by 2 cuz progress bar starts from middle
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoundStatus
