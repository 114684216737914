import { AES, enc } from 'crypto-js';

const secret: string = "zafp12$werpo0"

const encyptMessage = (secret: string, message: string): string => {
    const cipherText = AES.encrypt(message, secret);
    return cipherText.toString();
}

const decryptMessage = (secret: string, encryptMessage: string): string => {
    const bytes = AES.decrypt(encryptMessage, secret);
    const decrypted: string = bytes.toString(enc.Utf8);
    return decrypted;
}

export const getItemFromLocalStorage = (key: string): Object => {
    const data = localStorage.getItem(key);
    if (!data) return null
    const decryptedData = decryptMessage(secret, data);
    return JSON.parse(decryptedData);
}

export const setItemInLocalStorage = (key: string, data: Object): void => {
    const encryptedData: string = encyptMessage(secret, JSON.stringify(data));
    localStorage.setItem(key, encryptedData);
}