import { gql } from "@apollo/client";

export const ME_QUERY = gql`
  query MeQuery {
    Me {
      organizationId
      employee {
        defaultPracticeId
        id
      }
    }
  }
`;
