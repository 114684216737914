import React from "react";
import { injectIntl } from "react-intl";
import { Field } from "formik";
import { pick, valuesIn } from "lodash/fp";
import SelectField from "formfields/SelectField";

const SearchableSelectorField = ({
  name,
  placeholder,
  label,
  onChange,
  options,
  labelClassName,
  toString,
}) => {
  const optionsToString = (item) => {
    const { value: optionsLabel } = toString;
    let optionsToDisplay = optionsLabel.split(",");
    if (optionsToDisplay.length === 1) {
      return item[optionsLabel];
    }
    return valuesIn(pick(optionsToDisplay, item))?.join(" ");
  };
  return (
    <Field
      name={name}
      component={SelectField}
      onSelect={onChange}
      disabled={options.length === 0}
      clearable
      placeholder={placeholder || "..."}
      options={options.map((item) => ({
        value: item[toString?.id],
        label: optionsToString(item),
      }))}
      label={label}
      labelClassName={labelClassName}
    />
  );
};

export default injectIntl(SearchableSelectorField);
