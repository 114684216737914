import React from 'react'
import { injectIntl } from 'react-intl'

import {
  ChromePermissionsPopup,
  ExplorerPermissionsPopup,
  SafariPermissionsPopup,
  FirefoxPermissionsPopup,
  IEdgePermissionsPopup,
  DefaultErrorPopup
} from './BrowserPopups'

export class ErrorPopup extends React.Component {
  render() {
    const { error, onPopupClose } = this.props

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const isIE =
      navigator.appName == 'Netscape' || navigator.appName == 'Microsoft Internet Explorer'
    const isFirefox = /firefox/i.test(navigator.userAgent)
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

    let popup = ''

    switch (error.name) {
      case 'PermissionDeniedError':
        if (isChrome) {
          popup = <ChromePermissionsPopup error={error} onPopupClose={onPopupClose} />
        } else if (isIE) {
          popup = <IEdgePermissionsPopup error={error} onPopupClose={onPopupClose} />
        } else {
          popup = <DefaultErrorPopup error={error} onPopupClose={onPopupClose} />
        }
        break
      case 'NotAllowedError':
        if (isSafari) {
          popup = <SafariPermissionsPopup error={error} onPopupClose={onPopupClose} />
        } else if (isFirefox) {
          popup = <FirefoxPermissionsPopup error={error} onPopupClose={onPopupClose} />
        }
        break
      case 'Exception':
        popup = isIE
          ? <ExplorerPermissionsPopup error={error} onPopupClose={onPopupClose} />
          : <DefaultErrorPopup error={error} onPopupClose={onPopupClose} />
        break
      default:
        popup = <DefaultErrorPopup error={error} onPopupClose={onPopupClose} />
    }

    return popup
  }
}

export default injectIntl(ErrorPopup)
