import React from 'react'
import { InputText, PhoneInput } from 'components/_Patient/components'
import Fieldset from 'components/Fieldset'
import { identity } from 'lodash'
import { Field } from 'formik'
import { T } from 'core'
import { StyledPatientPersonalInfo } from 'styledComponents/Div/DivComponent/DivPatient'
import InputField from 'formfields/InputField'

export const FormikPatientPersonalInfo = props => {
  const { formatMessage } = props // rest contains parent Form methods
  return (
    <div className="row">
      <div className="col-lg-12">
        <h2 data-qa="personal-title">
          {formatMessage(T('common.personal'))}
        </h2>
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="firstname"
              component={InputField}
              label={formatMessage(T('common.firstNameAlias'))}
              labelClassName="required"
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="lastname"
              component={InputField}
              label={formatMessage(T('common.lastName'))}
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="phone"
              component={InputField}
              label={formatMessage(T('common.mobileNumber'))}
            />
          </div>
        </div>
        <p className="help-block" data-qa="help-text">
          {formatMessage(T('common.visible_to_you'))}
        </p>
      </div>
    </div>
  )
}

const PatientPersonalInfo = props => {
  const {
    countryCode,
    formatMessage,
    onValidationChange = identity,
    onDirtyChange = identity,
    setFormRef,
    firstName = null,
    lastName = null,
    phone = null
  } = props // rest contains parent Form methods
  return (
    <div className="row">
      <div className="col-lg-12">
        <h2 data-qa="personal-title">
          {formatMessage(T('common.personal'))}
        </h2>
        <StyledPatientPersonalInfo>
          <Fieldset
            ref={form => {
              setFormRef({ name: 'formPersonalInfo', form })
            }}
            alignment="horizontal"
            onValidationChange={onValidationChange}
            onDirtyChange={onDirtyChange}
          >
            <InputText
              id="firstName"
              label={formatMessage(T('common.firstNameAlias'))}
              defaultValue={firstName}
              placeholder={formatMessage(T('common.firstNameAlias'))}
              helpText={formatMessage(T('validator.required'))}
              required
            />
            <InputText
              id="lastName"
              label={formatMessage(T('common.lastName'))}
              defaultValue={lastName}
              placeholder={formatMessage(T('common.lastName'))}
            />
            <PhoneInput id="phone" countryCode={countryCode} defaultValue={phone} />
          </Fieldset>
        </StyledPatientPersonalInfo>
        <p className="help-block" data-qa="help-text">
          {formatMessage(T('common.visible_to_you'))}
        </p>
      </div>
    </div>
  )
}
export default PatientPersonalInfo
