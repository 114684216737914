import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import _ from 'lodash'
import compose from 'lodash/fp/compose'
import React from 'react'

export const notEqual = compose(_.negate(_.isEqual))

export const sortBy = (field, reverse, primer) => {
  const key = primer ? x => primer(x[field]) : x => x[field]

  reverse = !reverse ? 1 : -1

  return (a, b) => {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
  }
}

export const parsePhoneNumber = (number, country, format = PhoneNumberFormat.E164) => {
  const util = PhoneNumberUtil.getInstance()

  if (!number) return ''

  try {
    return util.format(util.parse(number, country), format)
  } catch (e) {
    return ''
  }
}

export const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // construct a Blob of the image data
  const array = []
  for (let i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i))
  }

  return new Blob([new Uint8Array(array)], { type: mimeString })

  // return new Blob([ia], {type:mimeString});
}

export const changeColorShade = (color, percent) => {
  if (!color) return
  const f = parseInt(color.slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff
  return `#${(0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B))
    .toString(16)
    .slice(1)}`
}

export const findBaseColorFromArray = (currentColor, colors) => {
  let foundColor
  colors.forEach(color => {
    _.range(-0.4, 0.4, 0.05).reverse().forEach(colorShadePercent => {
      const colorShade = changeColorShade(color, colorShadePercent)

      if (colorShade === currentColor) {
        foundColor = color
        return false
      }
    })

    if (foundColor) return false
  })

  return foundColor
}

export const Defer = ({ chunkSize, children }) => {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize)

  const childrenArray = React.useMemo(() => React.Children.toArray(children), [
    children
  ])

  React.useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      window.requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length)
          )
        },
        { timeout: 200 }
      )
    }
  }, [renderedItemsCount, childrenArray.length, chunkSize])

  return childrenArray.slice(0, renderedItemsCount)
};

export const generateRandomFileName = (currentName, length) => {
  var randomString = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  let name = currentName.substring(0, currentName.lastIndexOf("."));
  let stringExtension = currentName.slice(
    ((currentName.lastIndexOf(".") - 1) >>> 0) + 2
  );
  let generatedName = `${name}_${randomString}.${stringExtension}`;
  return generatedName;
};

export const calculateAverageToTwoDecimalPlaces = (high, low, count) => {
  const average = Math.round((((high - low) / count) + Number.EPSILON) * 100) / 100
  return average
}