import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0;
  align-items: center;
  > div {
    min-width: 160px;
    padding-right: 20px;
  }
`
