import React, { FC, ReactElement } from "react";
import { StyledCardContainer } from "styledComponents/Div/DivContainer/DivStyledCard";
import { FormattedMessage } from "react-intl";
import { T } from "core";
import { getLocaleFromCache } from "helper/LocaleHelper";
import { ConvertdDateTimeToLocalizeformatting } from "helper/DateUtils";
import { DateTimeZone } from "helper/constants";

const renderTableRow = (data: [any]) => {
  const locale = getLocaleFromCache();
  return data.map((x) => (
    <tr key={x.recordingTimeUtc}>
      <td>
        {ConvertdDateTimeToLocalizeformatting(
          x.recordingTimeUtc,
          locale,
          DateTimeZone.UtcTimeZone
        )}
      </td>
      <td>{x.wearingTimeInHr.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
    </tr>
  ));
};

interface IRawDataProps {
  wearingTimeRawData: [any];
}

export const RawTable: FC<any> = (props: IRawDataProps): ReactElement => {
  return (
    <div className="raw-table-wearingTime">
      <StyledCardContainer>
        <div className="practice">
          <div className="practice__header">
            <div className="practice__text--truncate">
              <FormattedMessage {...T("wearingTime.RawData.Header")} />
            </div>
          </div>
          <div className="practice__body">
            <table className="table table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">
                    <FormattedMessage {...T("wearingTime.RawData.DateHeader")} />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      {...T("wearingTime.RawData.TotalWearingTimeHeader")}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableRow(props.wearingTimeRawData)}</tbody>
            </table>
          </div>
        </div>
      </StyledCardContainer>
    </div>
  );
};
