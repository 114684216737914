import * as React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { default as scrollTo } from 'scroll-to-element'
import _ from 'lodash'
import { T } from 'core'
import { TRIAL_DURATIONS, PhoneNumberLengthForValidaton } from 'helper/constants'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Loading from 'components/Loading'
import { shopTracking } from 'helper/shopTracking'
import { EmployeeFormContainer } from 'styledComponents/Div/DivContainer/DivEmployee'
import Tooltip from 'components/Tooltip'
import { withQuery } from 'helper/withQuery'
import { withRouter } from "helper/withRouter";
import InputField from 'formfields/InputField'
import SelectField from 'formfields/SelectField'
import PasswordComponent from 'formfields/Password'
import TeCaImprovementProgramInformationModal from '../TeCaImprovementProgramInformationModal'
import { isUserOptedForDataConsent } from 'helper/UserDataConsentUtils'
import { Props, State } from 'typescript/interfaces/IEmployeeSelfComplete'
import RadioButtonGroupField from 'formfields/EditUser/RadioButtonGroupField'
import queryString from 'query-string'
import ImageDropField from 'formfields/FileUpload/ImageDropField'
import { Button } from 'styledComponents/Button'
import OtpVerificationModal from '../../components/Popups/OtpVerificationModal';
import InputWithIconAndTextField from 'formfields/InputWithIconAndTextField';
import { notificationOptionNameConst } from '../../helper/constants'
import { HorizontalRow } from 'styledComponents/HorizontalRule'




const EMPLOYEE = { value: 'employee', label: <FormattedMessage {...T('form.employee')} /> }
const MANAGER = {
  value: 'manager',
  label: <FormattedMessage {...T('form.practice-manager')} />
}
const ORG_MANAGER = {
  value: 'orgManager',
  label: <FormattedMessage {...T('form.org-manager')} />
}

const ROLES_MAP = {
  orgManager: [EMPLOYEE, MANAGER, ORG_MANAGER],
  manager: [EMPLOYEE, MANAGER],
  employee: [EMPLOYEE]
}

const notificationsOptions = [notificationOptionNameConst.off, notificationOptionNameConst.email, notificationOptionNameConst.sms];
const notificationsLabels = [
  <FormattedMessage {...T('common.off')} />,
  <FormattedMessage {...T('common.messagesEmail')} />,
  <FormattedMessage {...T('common.messageText')} />
]
const userDataConsentOptions = {
  description: {
    key: 'tcImprovementProgramLabel'
  }
}

const InvalidLinkError = ({ message }: { message: string }) => <div className="container invalid-key-error">
  {message}
</div>
let disableNotificationOptionName = [];

export class EmployeeSelfCompleteForm extends React.Component<Props, State> {
  state = {
    initialized: false,
    usernameAvailable: true,
    openOtpVerificationModal: false,
    isCountryCodeBlocked: false,
  }

  componentDidUpdate() {
    const { loading, employee = {} } = this.props
    const { initialized } = this.state

    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', '')
    if (!initialized && !loading) {
      const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'

      this.setState({
        initialized: true,
        usernameAvailable: role === 'employee'
      })
    }
    this.setDisableNotificationOptionName(employee);
  }

  prepareDataFromEmployee(employee) {
    const { locale, params } = this.props
    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', '')
    const defaultModuleLength = _.get(employee, 'defaultModuleLength') || TRIAL_DURATIONS[0]
    const notificationTime = _.get(employee, 'notificationTime') || '07:00:00'

    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'
    const { inactiveFeatures = [] } = employee
    const inactiveFeaturesArray = [...inactiveFeatures];
    const enableTraining = inactiveFeaturesArray.indexOf('LESSON_ACTIVE_JOURNEY') < 0 ? 'YES' : 'NO'

    if (_.isNil(params.id)) {
      inactiveFeaturesArray.push('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION')
      inactiveFeaturesArray.push('NEGATIVE_LESSON_EMAIL_NOTIFICATION')
    }

    let enableNewMessage = 'OFF'
    if (inactiveFeatures.indexOf('DISABLE_SMS_CHAT_NOTIFICATION') !== -1) enableNewMessage = 'SMS'
    if (inactiveFeatures.indexOf('DISABLE_EMAIL_CHAT_NOTIFICATION') !== -1)
      enableNewMessage = 'EMAIL'

    let enableSatisfactionRating = 'OFF'
    if (inactiveFeatures.indexOf('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION') !== -1)
      enableSatisfactionRating = 'EMAIL'
    if (inactiveFeatures.indexOf('NEGATIVE_SATISFACTION_SMS_NOTIFICATION') !== -1)
      enableSatisfactionRating = 'SMS'

    let enableLessonRating = 'OFF'
    if (inactiveFeatures.indexOf('NEGATIVE_LESSON_EMAIL_NOTIFICATION') !== -1)
      enableLessonRating = 'EMAIL'
    if (inactiveFeatures.indexOf('NEGATIVE_LESSON_SMS_NOTIFICATION') !== -1)
      enableLessonRating = 'SMS'

    const password = { oldPassword: '', passwordEnter: '', passwordReEnter: '' }

    return {
      firstName: employee.firstName || '',
      lastName: employee.lastName || '',
      email: employee.email || '',
      emailVerified: employee.emailVerified,
      username: '',
      phone: employee.phone || '',
      phoneVerified: employee.phoneVerified,
      role,
      manager: Boolean(isManager),
      orgManager: Boolean(isOrgManager),
      enableNewMessage,
      enableSatisfactionRating,
      enableLessonRating,
      locale: employee.locale || locale,
      enableTraining,
      defaultModuleLength,
      notificationTime,
      password,
      userDataConsent: this.isUserDataConsentCheckboxEnabled()
    }
  }

  setDisableNotificationOptionName(employee, values = undefined) {
    disableNotificationOptionName = [];
    if (!employee.emailVerified || (values !== undefined && employee.email !== values.email)) {
      disableNotificationOptionName.push(notificationOptionNameConst.email);
    }
    if (!employee.phoneVerified || (values !== undefined && employee.phone !== values.phone)) {
      disableNotificationOptionName.push(notificationOptionNameConst.sms);
    }
  }

  isUserDataConsentCheckboxEnabled = () => {
    return isUserOptedForDataConsent() || false
  }

  createDataQaEntry(name, options, index) {
    return name + '_' + options[index]
  }

  getInactiveFeatures(data) {
    const { enableTraining, enableNewMessage, enableSatisfactionRating, enableLessonRating } = data

    const inactiveFeatures = []
    if (enableNewMessage === 'SMS') inactiveFeatures.push('DISABLE_SMS_CHAT_NOTIFICATION')
    if (enableNewMessage === 'EMAIL') inactiveFeatures.push('DISABLE_EMAIL_CHAT_NOTIFICATION')
    if (enableSatisfactionRating === 'SMS')
      inactiveFeatures.push('NEGATIVE_SATISFACTION_SMS_NOTIFICATION')
    if (enableSatisfactionRating === 'EMAIL')
      inactiveFeatures.push('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION')
    if (enableLessonRating === 'SMS') inactiveFeatures.push('NEGATIVE_LESSON_SMS_NOTIFICATION')
    if (enableLessonRating === 'EMAIL') inactiveFeatures.push('NEGATIVE_LESSON_EMAIL_NOTIFICATION')

    if (!enableTraining || enableTraining === 'NO') {
      inactiveFeatures.push('LESSON_ACTIVE_JOURNEY')
    }

    return inactiveFeatures
  }

  async prepareData(employeeData) {
    const data = _.omit(employeeData, ['username', 'enableTraining', 'enableSatisfactionRating', 'enableNewMessage', 'enableLessonRating', 'avatar', 'password', 'role', 'practices', 'organizationId'])
    const isEmailVerified = this.props.employee && this.props.employee.emailVerified;
    const isPhoneVerified = this.props.employee && this.props.employee.phone === employeeData.phone;

    if (!this.props.isMe) {
      data.password = employeeData.password.passwordEnter
    }

    return {
      ...data,
      emailVerified: isEmailVerified,
      phoneVerified: isPhoneVerified,
      assignedPracticeIds: this.props.employee.assignedPracticeIds,
      defaultPracticeId: this.props.employee.defaultPracticeId,
      inactiveFeatures: this.getInactiveFeatures(employeeData),
    }
  }

  isCountryCodeBlocked(number: string) {
    if (!number) {
      return false;
    }
    const { CountryCallingCodes } = this.props;
    let validate = false;
    for (let element of CountryCallingCodes) {
      if (number.startsWith(element.countryCallingCode)) {
        validate = element.isCallingCodeBlocked;
        break;
      }
    }
    return validate;
  }

  employeeValidationSchema = () => Yup.object().shape(
    {
      firstName: Yup.string().trim().required(this.formatMessage('validator.required')),
      email: Yup.string()
        .required(this.formatMessage('validator.required'))
        .email(this.formatMessage('validator.email'))
        .usernameIsUnique(
          this.props.employee ? this.props.employee.email : '',
          this.formatMessage('error_messages.EMAIL_ALREADY_EXISTS_EMPLOYEE')
        ),
      phone: Yup.string().isPhoneNoValid(),
      password: Yup.object().shape({
        passwordEnter: Yup.string()
          .isPasswordValid()
          .min(6, this.formatMessage('min.password'))
          .required(this.formatMessage('validator.required')),
        passwordReEnter: Yup.string()
          .required(this.formatMessage('validator.required'))
          .test('passwords-match', this.formatMessage('validator.pinNotEqual'), function (
            value
          ) {
            return this.parent.passwordEnter == value
          })
      }),
      enableNewMessage: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber')),
      enableLessonRating: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber')),
      enableSatisfactionRating: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber'))
    }
  )

  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  async uploadAvatar({ employeeId, orgId, key, tenantId, avatar }) {
    try {
      await this.props.uploadSelfCompleteAvatar({ file: avatar, employeeId: employeeId, orgId: orgId, key: key, tenantId: tenantId })
    } catch (error) {
      const e =
        console.error('Uploading avatar is failed', error)
    }
  }

  handleSubmit = async (employeeData, setSubmitting, setFieldError) => {
    const { employeeId, orgId, key, tenantId }: any = queryString.parse(this.props.location.search)
    const data = await this.prepareData(employeeData)
    setSubmitting(true)

    try {
      const response = await this.props.updateInvitedEmployee(
        { employeeId: parseInt(employeeId), orgId: parseInt(orgId), key: key, tenantId: parseInt(tenantId), employee: { ...data } })

      if (response.errors) {
        const error = response.errors[0]
        if (error && error.message.toLowerCase().includes('password')) {
          setFieldError("password.passwordEnter", this.formatMessage("password.notAcceptable"))
          setSubmitting(false)
          return
        }
      }

      const { avatar } = employeeData
      if (avatar) {
        await this.uploadAvatar({ employeeId: parseInt(employeeId), orgId: parseInt(orgId), key: key, tenantId: parseInt(tenantId), avatar: avatar })
      }

      setSubmitting(false)
      shopTracking({ ...this.props.location, action: 'employeeUpdated' })

      this.props.navigate({ pathname: '/login' })
    } catch {
      setSubmitting(false)
    }
  }

  onError() {
    if (window && window.document) {
      scrollTo('.has-error', {
        offset: -200,
        duration: 600
      })
    }
  }

  handleShowOtpVerificationModal = (e: any) => {
    e.preventDefault();
    this.setState({ openOtpVerificationModal: true });
  }

  handleCloseOtpVerificationModal = async ({ isVerified, contactInfo }: any) => {
    this.setState({ openOtpVerificationModal: false });

    if (isVerified) {
      await this.updatePhoneVerificationStatus(contactInfo, isVerified)
    }
  }

  updatePhoneVerificationStatus = async (contactInfo: string, isVerified: boolean) => {
    const { employeeId, orgId, key, tenantId }: any = queryString.parse(this.props.location.search);

    const response = await this.props.updateInvitedEmployeePhoneVerificationStatus(
      {
        employeeId: parseInt(employeeId),
        orgId: parseInt(orgId),
        key: key,
        tenantId: parseInt(tenantId),
        empPhoneUpdateRequest: { phone: contactInfo, phoneVerified: isVerified }
      });

    response.data.updateInvitedEmployeePhoneVerificationStatus && this.props.refetch();
  }

  isEmailOrPhoneNotVerified(employee, values) {
    return !employee.emailVerified || !employee.phoneVerified || employee.email !== values.email ||
      employee.phone !== values.phone
  }

  private isPhoneNotEmptyOrNotVerified(values: { firstName: any; lastName: any; email: any; emailVerified: any; username: string; phone: any; phoneVerified: any; role: string; manager: boolean; orgManager: boolean; enableNewMessage: string; enableSatisfactionRating: string; enableLessonRating: string; locale: any; enableTraining: string; defaultModuleLength: any; notificationTime: any; password: { oldPassword: string; passwordEnter: string; passwordReEnter: string }; userDataConsent: any }, employee: any) {
    return !_.isEmpty(values.phone) && (!employee.phoneVerified || employee.phone !== values.phone)
  }

  handlePhoneNoFieldKeyUp = (value: string) => {
    if (value?.length > PhoneNumberLengthForValidaton) {
      this.setState({
        isCountryCodeBlocked: this.isCountryCodeBlocked(value)
      })
    }
    else {
      this.setState({
        isCountryCodeBlocked: false
      })
    }
  }


  render() {
    const {
      loading,
      isMe,
      isManager,
      isOrgManager,
      employee,
      locales,
      formTitle,
      intl: { formatMessage },
      NotificationTimeOptions,
      isReadOnly,
      error
    } = this.props
    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'

    const roles = ROLES_MAP[role].map(item => item.value)
    const rolesLabel = ROLES_MAP[role].map(item => item.label)
    return error && error.graphQLErrors[0].message.includes('INVALID_OR_EXPIRED_PASSWORD_TOKEN') ? <InvalidLinkError
      message={this.formatMessage('employeeInvitation.linkExpired')}
    ></InvalidLinkError> :
      (
        <EmployeeFormContainer>
          <Loading isLoading={loading} />
          {!loading &&
            <Formik
              onSubmit={(values, actions) => { this.handleSubmit(values, actions.setSubmitting, actions.setFieldError) }}
              validationSchema={this.employeeValidationSchema}
              initialValues={this.prepareDataFromEmployee(employee)}
              enableReinitialize={true}
            >
              {({ values, isSubmitting, errors }) => {
                return (
                  <Form>
                    <Loading isLoading={isSubmitting} />
                    <div className="container">
                      <div className="col-lg-10 offset-lg-1">
                        <h1 data-qa="title">
                          <FormattedMessage {...T(formTitle)} />
                        </h1>
                        <p data-qa="required-text">
                          <FormattedMessage
                            {...T('common.required_text')}
                            values={{ mark: <span className="text-primary">*</span> }}
                          />
                        </p>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group profilePicture" data-qa="avatar">
                              <Field name="avatar" isReadOnly={isReadOnly} component={ImageDropField} />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group" data-qa="employee">
                              <div className="row">
                                <div className="col-lg-12">
                                  <Field
                                    disabled={isReadOnly}
                                    name="firstName"
                                    component={InputField}
                                    label={formatMessage(T('common.firstName'))}
                                    labelClassName="required"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <Field
                                    disabled={isReadOnly}
                                    name="lastName"
                                    component={InputField}
                                    label={formatMessage(T('common.lastName'))}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <Field
                              disabled={true}
                              name="email"
                              component={InputWithIconAndTextField}
                              label={formatMessage(T('common.email'))}
                              labelClassName="required"
                              isVerified={employee.emailVerified}
                            />
                          </div>
                          <div className="col-lg-6">
                            <Field
                              disabled={isReadOnly}
                              name="phone"
                              component={InputWithIconAndTextField}
                              label={formatMessage(T('common.mobileNumber'))}
                              isVerified={employee.phoneVerified && employee.phone === values.phone}
                              hideIconAndText={_.isEmpty(values.phone)}
                              onChange={this.setDisableNotificationOptionName(employee, values)}
                              onKeyUp={(e) => {
                                this.handlePhoneNoFieldKeyUp(e.target.value)
                              }}
                            />
                            {this.state.isCountryCodeBlocked &&
                              <span className="help-block notification-warning-span" data-qa="notify-countrycode-blocked">
                                <i className="fa fa-exclamation-triangle"></i>
                                &nbsp;<FormattedMessage
                                  {...T('countryCallingCodeIsBlocked.note')}
                                />
                              </span>}
                            <div className='col-6 ml-auto p-0'>
                              {this.isPhoneNotEmptyOrNotVerified(values, employee) &&
                                !this.state.isCountryCodeBlocked &&
                                <Button
                                  secondary
                                  disabled={isReadOnly || errors.phone || _.isEmpty(values.phone)}
                                  type="button"
                                  className="btn-block"
                                  data-qa="verify-number"
                                  onClick={(e: any) => this.handleShowOtpVerificationModal(e)} >
                                  <FormattedMessage {...T('common.verifyNumber')} />
                                </Button>
                              }
                            </div>
                          </div>
                        </div>

                        <>
                          {this.state.openOtpVerificationModal &&
                            <OtpVerificationModal
                              show={this.state.openOtpVerificationModal}
                              onHide={this.handleCloseOtpVerificationModal}
                              contactInfo={values.phone}
                              isPhoneRequest={true}
                              locale={this.props.locale}
                            />
                          }
                        </>

                        <div className="row" style={{ "marginTop": "2%" }}>
                          <div className="col-lg-12">
                            <p className="help-block" data-qa="details-help-text">
                              <FormattedMessage {...T('employeeCreate.note')} />
                            </p>
                          </div>
                        </div>

                        <div className="form-group" data-qa="alerts">
                          <div className='notification-header-div'>
                            <h2>
                              <FormattedMessage {...T('notifications.header')} />
                            </h2>
                            {this.isEmailOrPhoneNotVerified(employee, values) &&
                              <div >
                                <span className="help-block notification-warning-span" data-qa="notify-email-phoneNumber-notVerified">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  &nbsp;<FormattedMessage
                                    {...T('employeeEdit.notifyEmailPhoneNumberNotVerified')}
                                  />
                                </span>
                              </div>}
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="enableNewMessage"
                                component={RadioButtonGroupField}
                                header={<FormattedMessage {...T('notifications.newMessage')} />}
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="locale"
                                component={SelectField}
                                label={formatMessage(T('employeeEdit.locale'))}
                                data-qa="locale"
                                options={locales.map(country => ({
                                  value: country.countryCode,
                                  label: formatMessage(T('languageName.' + country.countryCode))
                                }))}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="enableLessonRating"
                                component={RadioButtonGroupField}
                                header={
                                  <FormattedMessage {...T('notifications.negativeLessionRating')} />
                                }
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>
                            <div className='col=lg-6'>
                              {(values.enableSatisfactionRating !== 'OFF' ||
                                values.enableLessonRating !== 'OFF') &&
                                NotificationTimeOptions &&

                                <Field
                                  disabled={isReadOnly}
                                  name="notificationTime"
                                  component={SelectField}
                                  label={formatMessage(T('notification_timestamp_label'))}
                                  data-qa="locale"
                                  value={values.notificationTime}
                                  options={NotificationTimeOptions.timeSet.map(time => ({
                                    value: `${time + ':00'}`,
                                    label: time
                                  }))}
                                />
                              }
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="enableSatisfactionRating"
                                component={RadioButtonGroupField}
                                header={
                                  <FormattedMessage
                                    {...T('notifications.negativeSatisfactionRating')}
                                  />
                                }
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>
                          </div>

                        </div>
                        <HorizontalRow />
                        <div className="form-group" data-qa="alerts">
                          <div className="row">
                            <div className="col-lg-6  margin-child-0">
                              <Field
                                disabled={isReadOnly}
                                name="enableTraining"
                                component={RadioButtonGroupField}
                                header={<FormattedMessage {...T('common.hearingLessons')} />}
                                options={['YES', 'NO']}
                                labelArray={[
                                  <FormattedMessage {...T('common.yes')} />,
                                  <FormattedMessage {...T('common.no')} />
                                ]}
                                createDataQaEntry={this.createDataQaEntry}
                              />
                            </div>
                            {values.enableTraining === 'NO' &&
                              <div className="col-lg-12">
                                <p className="help-block" data-qa="notify-disable-hearing-lessons">
                                  <FormattedMessage
                                    {...T('employeeEdit.disablingHearingLessonsNotification')}
                                  />
                                </p>
                              </div>}
                          </div>
                        </div>
                        <HorizontalRow />
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group" data-qa="roles">
                              <div data-tip data-for="roles">
                                <Field
                                  name="role"
                                  component={RadioButtonGroupField}
                                  header={<FormattedMessage {...T('employee.roles')} />}
                                  options={roles}
                                  labelArray={rolesLabel}
                                  createDataQaEntry={this.createDataQaEntry}
                                  disabled={true}
                                />
                              </div>
                              <Tooltip
                                id="roles"
                                subtitle={formatMessage(T('multistore.error.role'))}
                                place="top"
                              />
                            </div>
                          </div>
                        </div>
                        <HorizontalRow />
                        <div className="row" data-qa="">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <Field
                                disabled={isReadOnly}
                                name="userDataConsent"
                                component={TeCaImprovementProgramInformationModal}
                                label={formatMessage(T('TCImprovementProgram.label'))}
                                options={userDataConsentOptions}
                              />
                            </div>
                          </div>
                        </div>
                        <HorizontalRow />
                        <div className="row" data-qa="password">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <Field
                                disabled={isReadOnly}
                                name="password"
                                component={PasswordComponent}
                                oldPassword={isMe}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 offset-lg-6">
                            <Button
                              secondary
                              type="button"
                              className="btn-block"
                              onClick={() => this.props.navigate(-1)}
                              data-qa="cancel-button"
                            >
                              <FormattedMessage {...T('common.cancel')} />
                            </Button>
                          </div>
                          <div className="col-lg-3">
                            <Button
                              primary
                              disabled={isReadOnly}
                              type="submit"
                              className="btn-block"
                              data-qa="continue-button"
                            >
                              <FormattedMessage {...T(this.props.submitLabel)} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>}
        </EmployeeFormContainer>
      )
  }
}

export default withRouter(withQuery(injectIntl(EmployeeSelfCompleteForm)))
