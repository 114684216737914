import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import moment from 'moment-timezone'
import _ from 'lodash'


import EditForm from '../EditForm'
import { REMOVE_QUERY_PARAM } from './../../../graphql/mutations/UpdateLocationMutation'
import { withRouter } from 'helper/withRouter'
import { countries as countryConstants } from 'helper/constants'

const mapProps = ({
  data: { refetch, Me, loading, Countries = [], Practice },
  ownProps: { params }
}) => {
  const practiceId = parseInt(params.id, 10)

  const countries = Countries.filter(item => item.enableHcpRegistration)

  const practice = Practice
  const openingHours = _.get(practice, 'openingHours', [])
  const hours = {}

  openingHours.forEach(openingHour => {
    const key = `${openingHour.openingTime}-${openingHour.closingTime}-${openingHour.comment
      ? openingHour.comment.replace(/ /g, '')
      : ''}`
    const from = openingHour.openingTime
      ? openingHour.openingTime
      : moment(openingHour.openingTime).format('HH:mm')
    const to = openingHour.closingTime
      ? openingHour.closingTime
      : moment(openingHour.closingTime).format('HH:mm')

    if (!hours[key]) {
      hours[key] = {
        days: [],
        comment: openingHour.comment,
        from,
        to
      }
    }

    hours[key].days.push(openingHour.dayOfWeek)
  })

  return {
    refetch,
    loading,
    Me,
    practiceId,
    countries,
    practice,
    openingHours,
    hours: _.values(hours),
    isNewPractice: false
  }
}

const QUERY_PRACTICE_CREATE = gql`
  query PracticeDetailsQuery($id: Int!) {
    Me {
      employee {
        id
        locale
        email
      }
      countryCode
    }
    Practice(id: $id) {
      id
      organizationId
      tenantId
      name
      accountNumber
      email
      zipCode
      timeZone
      street
      city
      state
      countryCode
      phone
      locale
      website
      autoReplyEnabled
      autoReplyMessage
      imageUrl
      outOfOfficeMessage
      defaultOutOfOfficeMessage
      openingHours {
        dayOfWeek
        comment
        openingTime
        closingTime
      }
      inactiveFeatures
      organizationConfig {
        organizationId
        autoReplyMode
        autoReplyMessage
        isPasswordExpirationPolicyEnabled
        daysToExpirePassword
      }
    }
    Countries {
      countryCode
      enableHcpRegistration
      zipCodeValidation
    }    
  }
`

const MUTATION_UPDATE_PRACTICE = gql`
  mutation UpdatePracticeMutation($practiceId: Int!, $practice: PracticeInput!) {
    updatePractice(practiceId: $practiceId, practice: $practice) 
    {
      id
    }
  }
`

const MUTATION_DELETE_IMAGE_PRACTICE = gql`
  mutation DeletePracticeImageMutation($practiceId: Int) {
    deletePracticeLogo(practiceId: $practiceId)
  }
`
const MUTATION_UPLOAD_IMAGE_PRACTICE = gql`
  mutation UploadPracticeImageMutation($file: String, $practiceId: Int) {
    uploadPracticeLogo(file: $file, practiceId: $practiceId)
  }
`

const options = ({ params }) => ({
  variables: { id: parseInt(params.id) },
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_PRACTICE_CREATE, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_UPDATE_PRACTICE, {
    props: ({ mutate }) => ({
      updatePractice: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_DELETE_IMAGE_PRACTICE, {
    props: ({ mutate }) => ({
      deletePracticeLogo: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPLOAD_IMAGE_PRACTICE, {
    props: ({ mutate }) => ({
      uploadPracticeLogo: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(REMOVE_QUERY_PARAM, {
    props: ({ mutate }) => ({
      updateLocation: (location, key) => mutate({ variables: { location, key } })
    })
  }),
  injectIntl
)

export default withRouter(withData(EditForm))
