import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import { Link } from 'react-router-dom'
import { T } from 'core'
import SortableTableHeader from 'components/SortableTableHeader'
import { checkPermission } from 'helper/AuthHelper'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { shopTracking } from 'helper/shopTracking'
import ConfirmDeletionPopup from 'components/Popups/ConfirmDeletionPopup'
import { UPDATE_OVERLAY, SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import Tooltip from 'react-tooltip'
import {
  SHOW_POPUP,
  DELETE_PRACTICE_POPUP,
  P_VIEWMORE_POPUP,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'

import { PRACTICES_QUERY } from 'graphql/queries/PracticesQuery'
import { withRouter } from "helper/withRouter";
import { StyledTable } from "styledComponents/Table/StyledTable";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"

import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'
import { sendDataToDataCollection } from '../../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from '../../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../../helper/DataCollection/DataCollectionConstants'
import { DataCollectionType } from 'helper/constants'
export class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: '', order: ''
    }
  }

  setSort = sortField => () => {
    this.props.setSort(sortField)
  }

  closeConfirmationPopup = () => {
    this.props.updateOverlay(null, null, false)
  }

  handleDelete = practiceId => {
    this.closeConfirmationPopup()
    this.props
      .deletePractice({ practiceId })
      .then((result) => {
        if (result.errors) {
          const { message } = result.errors[0]
          const errorCode = message.match('code: (.*),')
          const errorMessage = errorCode[1].substring(1, errorCode[1].length - 1)
          const popup = getOverlayUpdateData(SHOW_POPUP, {
            type: P_VIEWMORE_POPUP,
            payload: {
              title: 'common.error',
              message: errorMessage, //errorCode[1].replace(/\"/g, ""),
              datum: {
                practiceId: practiceId,
                goToURL: `/deletepractice/${practiceId}`
              },
            }
          })
          this.props.updateOverlay({ ...popup }, true, true)
        }
        else {
          shopTracking({
            ...this.props.location,
            action: 'practiceDeleted'
          })
          this.props.refetch()
          sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Practice), EventType.Delete)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  showConfirmationPopup = id => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: DELETE_PRACTICE_POPUP + id,
        payload: {
          title: 'multistore.practice.deletionHeader',
          message: 'multistore.practice.deletion'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }

  renderViewOrEdit(isEditAllowed, item, formatMessage) {
    return isEditAllowed
      ? <td className="text-center">
        <Link
          data-qa="manage__edit-button"
          to={`/practice/${item.id}/edit`}
          data-tip
          data-for={`tooltip-edit-${item.id}`}
        >
          <StyledFontAwesomeIcon icon={faEdit} fixedWidth type="edit" />
        </Link>
        <Tooltip id={`tooltip-edit-${item.id}`}>
          {`${formatMessage(T('common.edit'))} ${item.name}`}
        </Tooltip>
      </td>
      : <td className="text-center">
        <Link
          data-qa="manage__view-button"
          to={`/practice/${item.id}/view`}
          data-tip
          data-for={`tooltip-view-${item.id}`}
        >
          <i className="fas fa-info-circle" />
        </Link>
        <Tooltip id={`tooltip-view-${item.id}`}>
          {`${formatMessage(T('journeyConfigurator.manageLessonSets.view'))} ${item.name}`}
        </Tooltip>
      </td>
  }

  render() {
    const { sort, practices = [], Me, intl: { formatMessage } } = this.props
    const isEditAllowed = checkPermission('EDIT_PRACTICE', { Me })
    const isDeleteAllowed = checkPermission('DELETE_PRACTICE', { Me })
    return (
      <StyledTable className="table--practices">
        <thead>
          <tr>
            <SortableTableHeader
              className="col-4"
              name="name"
              onClick={this.setSort('name')}
              value={formatMessage(T('common.name'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-4"
              name="street"
              onClick={this.setSort('street')}
              value={formatMessage(T('common.street'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-2"
              name="city"
              onClick={this.setSort('city')}
              value={formatMessage(T('common.city'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-2"
              name="phone"
              onClick={this.setSort('phone')}
              value={formatMessage(T('common.phone'))}
              sort={sort}
            />
            <th className="text-center col-1">&nbsp;</th>
            <th className="text-center col-1">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {practices.map((item, index) => {
            const { name, city, phone, street } = item

            return (
              <tr key={`${item.id}-${index}`}>
                <td>
                  {name}
                </td>
                <td>
                  {street}
                </td>
                <td>
                  {city}
                </td>
                <td>
                  {phone}
                </td>
                {this.renderViewOrEdit(isEditAllowed, item, formatMessage)}
                <td className="text-center">
                  {isDeleteAllowed &&
                    <ConfirmDeletionPopup
                      openByClickOn={(onClick) =>
                        <StyledFontAwesomeIcon
                          icon={faTrash}
                          fixedWidth
                          type="delete"
                          data-qa="manage__delete-button"
                          data-tip
                          data-for={`tooltip-delete-${item.id}`}
                          onClick={onClick}
                        />
                      }
                      removePatient={() => this.handleDelete(item.id)}
                      onOpen={() => this.showConfirmationPopup(item.id)}
                      onClose={this.closeConfirmationPopup}
                      id={item.id}
                    />}
                  <Tooltip id={`tooltip-delete-${item.id}`}>
                    {`${formatMessage(T('common.delete'))} ${name}`}
                  </Tooltip>
                </td>
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    )
  }
}

const MUTATION_PRACTICE_DELETE = gql`
  mutation deletePracticeMutation($practiceId: Int!) {
    deletePractice(practiceId: $practiceId)
  }
`
const mapPopupProps = ({ data: { overlay } }) => {
  return { overlay }
}

const withData = compose(
  graphql(MUTATION_PRACTICE_DELETE, {
    props: ({ mutate }) => ({
      deletePractice: practiceId =>
        mutate({
          variables: practiceId,
          refetchQueries: [
            {
              query: PRACTICES_QUERY,
              variables: { orgId: parseInt(getOrganizationIdFromCache()) }
            }
          ]
        })
    })
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: mapPopupProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  injectIntl
)

export default withRouter(withData(Table))
