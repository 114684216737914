import styled from 'styled-components'

export const KpiIcon = styled.span`
  &:before {
    color: ${props => props.theme.colors.brandNeutral};
    content: ${props => (props.icon.type ? props.icon.type : '\f118')};
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 24px;
    position: absolute;
    left: -20px;
  }
`

export const LessonChangedIndication = styled.span`
  flex: 1;
  margin: 0 5px;

  &.active {
    color: red;
  }
`

export const CallButtonBase = styled.span`
  color: #333333;
  height: 30px;
  width: 30px;
  line-height: 30px;
  margin: 0px 3px;
  font-size: 1.4em;
  text-align: center;
  border-radius: 50%;
  box-shadow: inset 0 0 1px #000000;
  cursor: pointer;
  transition-duration: 0.25s;

  &:hover {
    color: white;
    background-color:#3399FF;
  }

  &.disabled {
    color: #333333;
    background-color: #dddddd;
  }
`

export const ActionButton = styled.span`
  color: white;
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 0px 8px;
  font-size: 1.4em;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 0.25s;
  pointer-events: all;

  background-color:#3399FF;

  &.red {
    background-color: #e81123;
  }
`

export const BetaIcon = styled.span`
  margin-right: 2px;
`

export const BatterySpan = styled.span`
  margin: 0 10px;
  font-family: "Montserrat Bold", sans-serif;  
`

export const Underlined = styled.span`  
  border-style: solid;
  border-color: ${props => props.color};
  border-width: 0 0 4px 0;  
  margin-left: ${props => props.marginLeft};
`