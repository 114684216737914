import { gql } from "@apollo/client";

export const QUERY_ALL_PATIENTS = gql`
  query AllPatientQuery($practiceId: Int, $employeeId: Int, $sort: String, $page: Int, $search: String, $patientType: String) {
    PatientsList(
      practiceId: $practiceId
      employeeId: $employeeId
      sort: $sort
      page: $page
      search: $search
      patientType: $patientType
    ) {
      content {
        id
        appConnected
        currentProgress
        employeeFirstName
        employeeImageUrl
        employeeLastName
        firstName
        lastName
        journeyClosingDate
        journeyStartedOn
        journeyDurationDays
        journeyStatus
        lastSatisfactionDate
        lastSatisfactionRating
        lastWeeksProgress
        numberOfUnreadChatInteractions
        telecareVersion
        assistantHistoryStatus
        averageWearingTime
      }
      pagination {
        pageNumber
        pageSize
        numberOfElements
        totalElements
        totalPages
      }
    }
  }
`