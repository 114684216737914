import React from 'react'

import { FormattedMessage } from 'react-intl'
import FileUploadField from 'formfields/FileUpload/FileUploadField'

import messages from 'i18n/messages.json'

const toImage = file =>
  new Promise((resolve, reject) => {
    if (file && file.data) {
      const image = new Image()
      image.onload = () => {
        resolve({ image })
      }

      image.onerror = err => {
        reject(err)
      }
      image.src = file.data
    }
  })

const ImageUploadField = props =>
  <FileUploadField
    {...props}
    allowedMimeTypes={['image.*']}
    emptyIconClass="far fa-image fa-fw fa-2x"
    emptyMessage={<FormattedMessage {...messages.fileUploadChangePhoto} />}
    invalidIconClass="fas fa-times fa-fw fa-2x"
    invalidMessage={<FormattedMessage {...messages.fileUploadNotSupported} />}
    validMessage={<FormattedMessage {...messages.fileUploadChangePhoto} />}
    preprocessors={[toImage]}
    canDisplayInitialValue
    canDisplayParsedValue
  />

export default ImageUploadField
