import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import {
	RegisterNowContainer,
	HeadLineDiv,
	HeadLineContentDiv
} from 'styledComponents/Div/DivContainer/DivLandingPage'

export default class RegisterNow extends Component {
	render() {
		return (
			<RegisterNowContainer>
				<div className="row">
					<div className="col-lg-12 text-center register-wrapper">
						<HeadLineDiv>
							<FormattedMessage {...T('landingpage.register.content_1')} />
						</HeadLineDiv>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 text-center register-wrapper">
						<HeadLineContentDiv>
							<FormattedMessage {...T('landingpage.register.content_2')} />
						</HeadLineContentDiv>
					</div>
				</div>
				<div className="row">
					<Link
						to="/register"
						className="btn btn-block registerOrgButton"
						data-qa="registration-link"
					>
						<FormattedMessage {...T('login.register.organization')} />
					</Link>
				</div>
			</RegisterNowContainer>
		)
	}
}
