import React from 'react'
import Loader from '../Loader'
import {
  PopupBase,
  PopupHead,
  PopupContent,
  PopupActions,
  VideoInfoBase
} from 'styledComponents/Div/DivComponent/DivVideoCall'

export const Popup = ({ children, title, message, loading = false }) => {
  return (
    <PopupBase>
      <PopupHead>
        {title}
      </PopupHead>
      <PopupContent>
        {loading && <Loader />}
        {message}
      </PopupContent>
      <PopupActions>
        {children}
      </PopupActions>
    </PopupBase>
  )
}

export const VideoInfoOverlay = ({
  children,
  message,
  loading = false,
  className
}) => {
  return (
    <VideoInfoBase className={className}>
      <PopupContent>
        {loading && <Loader />}
        {message}
      </PopupContent>
      <PopupActions>
        {children}
      </PopupActions>
    </VideoInfoBase>
  )
}
