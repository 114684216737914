import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Button } from 'styledComponents/Button'

const NOT_SENT = 'NOT_SENT'
const SENT = 'SENT'
const APPLIED = 'APPLIED'
const TIMER = 10 * 1000

class Save extends Component {
  constructor(...args) {
    super(...args)

    this.state = {
      initialized: false,
      status: ''
    }

    this._timer = 0
  }

  UNSAFE_componentWillMount() {
    const { program } = this.props

    if (Object.keys(program).length > 0) {
      this.update()
    }
  }

  componentDidUpdate(prevProps) {
    const { status, initialized } = this.state
    const { program = {} } = this.props

    if (Object.keys(program).length === 0) {
      return
    }

    const programChanged = program.programNumber !== prevProps.program.programNumber
    
    if (prevProps.dirty !== this.props.dirty || !initialized || programChanged) {
      this.setState({ initialized: true })
      this.update()
    }

    if (status === SENT) {
      if (this._timer === 0) {
        this._timer = setInterval(() => this.update(), TIMER)
      }
    } else {
      clearInterval(this._timer)
      this._timer = 0
    }
  }

  componentWillUnmount() {
    clearInterval(this._timer)
  }

  update() {
    const { dirty } = this.props

    const newStatus = new Promise(resolve => {
      if (dirty) {
        resolve(NOT_SENT)
      } else {
        this.fetchStatus().then(status => resolve(status ? APPLIED : SENT)).catch(() => resolve(''))
      }
    })

    newStatus.then(status => this.setState({ status }))
  }

  isDefaultProgram(program = {}) {
    const { equalizer1, equalizer2, equalizer3, equalizer4, balance, volume } = program
    const defaultOnOldFirmware = balance === 0 && volume === 8
    const defaultOnNewFirmware = equalizer1 + equalizer2 + equalizer3 + equalizer4 === 0

    return defaultOnNewFirmware && defaultOnOldFirmware
  }
  
  fetchStatus() {
    const { customer, program = {} } = this.props
    const isDefaultSettings = this.isDefaultProgram(program)
    const persistentChanged = !isDefaultSettings && Object.keys(program).length > 0

    if (persistentChanged) {
      return this.props.getProgramStatus(customer, program.programNumber)
    } else {
      return Promise.reject('')
    }
  }

  onChange(e) {
    const { value } = e.target

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  onSave = () => {
    this.props.onSave()
  }

  render() {
    const { dirty, isAppSupported } = this.props

    return (
      <article>
        <h2 className="">
          <FormattedMessage {...T('profile.save-title')} />
        </h2>
        <div className="profile-tuning__save row">
          <div className="col-4">
            <Button
              primary
              disabled={!dirty || !isAppSupported}
              type="button"
              data-qa="save-and-send-update"
              onClick={this.onSave}
              className="btn-block"
            >
              <FormattedMessage {...T('profile.save-and-send')} />
            </Button>
          </div>
        </div>
      </article>
    )
  }
}

export default Save
