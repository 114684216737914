import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Formik, Form, Field } from 'formik'

import Loading from 'components/Loading'
import AccordionElement from '../../AccordionElement'
import { PortalContext } from 'helper/constants'
import SelectField from 'formfields/SelectField'

import { withRouter } from "helper/withRouter";
import { withQuery } from '../../../helper/withQuery'
import queryString from 'query-string'
import DataContext from "containers/DataContext"
export class OrderedFaq extends Component {
  static contextType = DataContext;
  constructor(props) {
    super(props)
    this.state = { locale: props.query.locale }
  }

  UNSAFE_componentWillUpdate() {
    const locale =
      (localStorage.getItem('locale') && JSON.parse(localStorage.getItem('locale')).locale) ||
      'en-US'
    if (this.state.locale !== locale) {
      const { Category } = this.props
      const { query } = props.location;
      query.locale = locale
      if (Category && Category[0]) {
        const defaultCategoryId = Category[0].categoryId
        query.category = defaultCategoryId
      }
      this.setState({ locale })
      this.props.navigate({
        pathname: `/help/faq/orderedfaq`,
        search: queryString.stringify(query)
      })
    }
  }

  onChange = categoryId => {
    const { query } = this.props;
    query.category = categoryId
    this.props.navigate({
      pathname: `/help/faq/orderedfaq`,
      search: queryString.stringify(query)
    })
  }

  render() {
    const { loading, Faq, Category, intl: { formatMessage } } = this.props
    return (
      <div>
        <h2>
          <FormattedMessage {...T('FAQ.selectcategory')} />
        </h2>
        {Category &&
          <div className="row">
            <div className="col-5">
              <Formik
                initialValues={{
                  category: null
                }}
              >
                <Form>
                  <Field
                    name="category"
                    component={SelectField}
                    onSelect={this.onChange}
                    options={Category.map(item => ({
                      value: item.categoryId,
                      label: item.translatedText
                    }))}
                    placeholder={`${formatMessage(T('common.select'))}...`}
                    clearable={true}
                    searchable={false}
                  />
                </Form>
              </Formik>
            </div>
          </div>}
        {loading
          ? <Loading isLoading={loading} />
          : Faq &&
          Faq.map((data, index) => {
            return <AccordionElement question={data.question} answer={data.answer} allowMarkdown={true} key={index} />
          })}
      </div>
    )
  }
}

const mapQueryToVariables = ({ category, locale }, context = '') => ({
  context,
  category: parseInt(category),
  locale
})

const options = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables(query, PortalContext),
  fetchPolicy: 'network-only'
})

const mapProps = ({ data: { refetch, loading, Faq } }) => {
  return {
    refetch,
    loading,
    Faq
  }
}

const mapCategoryProps = ({ data: { Category } }) => {
  return {
    Category
  }
}

const QUERY_ORDERED_FAQ_PAGE = gql`
  query FaqQuestionQuery($context: String, $category: Int, $locale: String) {
    Faq(context: $context, category: $category, locale: $locale) {
      question
      answer
    }
  }
`
const QUERY_ORDERED_OPTION = gql`
  query FaqCategoryQuery($locale: String) {
    Category(locale: $locale) {
      categoryId
      translatedText
    }
  }
`
const withData = compose(
  graphql(QUERY_ORDERED_FAQ_PAGE, {
    props: mapProps,
    options
  }),
  graphql(QUERY_ORDERED_OPTION, {
    props: mapCategoryProps,
    options
  }),
  injectIntl
)
export default withRouter(withQuery(withData(OrderedFaq)))
