import React from 'react'
import identity from 'lodash/identity'
import { FormattedNumber } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import isNil from 'lodash/isNil'
import { T } from 'core'
import {
	Kpi,
	KpiBarLegend,
	KpiBarLabel,
	KpiBarValue,
	KpiBarBar,
	KpiBar
} from 'styledComponents/Div/DivComponent/DivDashboard'
import { KpiIcon } from 'styledComponents/Span'
import Tooltip from 'components/Tooltip'
import { Defer } from 'helper/Utils'

const getTransformedAverage = ({ type, average }) => {
	switch (type) {
		case 'processDuration':
			return Math.round(average)
		case 'successRate':
			// use percentage
			return average * 100
		default:
			return average
	}
}
const getIconType = (type) => {
	switch (type) {
		case 'patientsGained':
			return { type: '"\f234"' }
		case 'patientSatisfaction"':
			return { type: '"\f118"' }
		case 'processDuration':
			return { type: '"\f017"' } // take default value
		case 'successRate':
			return { type: '"\f091"' }
		case 'patientsOverall':
			return { type: '"\f0c0"' }
		default:
			return { type: '"\f118"' }
	}
}
class KpiComponent extends React.Component {
	render() {
		const {
			type,
			name,
			suffix,
			average,
			invert,
			legend,
			tooltip,
			threshold,
			defaultValue,
			maxValue,
			items,
			fnPercent,
			transformValue = identity,
			formatMessage
		} = this.props

		const transformedAverage = getTransformedAverage({ type, average })

		return (
			<Kpi className={`kpi kpi--${type}`}>
				<div className="kpi__header">
					<div className="kpi__text--truncate">{name}</div>
					<div className="kpi__stats">
						<KpiStatsValue
							average={transformedAverage}
							suffix={suffix}
							tooltip={tooltip}
							icon={getIconType(type)}
						/>
					</div>
				</div>
				<div className="kpi__body">
					<Defer chunkSize={10}>
						{items.map((item, i, arr) => {
							let label = ''
							let prefix = ''

							switch (item.type) {
								case 'organizationAverage':
									label = <FormattedMessage {...T('Dashboard.organization')} />
									prefix = ' ' + String.fromCharCode(216)
									break

								case 'marketAverage':
									label = <FormattedMessage {...T('Dashboard.market')} />
									prefix = ' ' + String.fromCharCode(216)
									break

								default:
									label = item.name
							}

							let indicator = ''

							if (item.value && item.value < threshold.value) {
								indicator = [ 'negative', 'positive' ][+invert]
							}

							if (item.value && item.value >= threshold.value) {
								indicator = [ 'positive', 'negative' ][+invert]
							}

							if (item === threshold) {
								indicator = 'average'
							}

							if (arr.length === 1) {
								indicator = 'neutral'
							}

							const kpiBarValue = !isNil(item.value) ? transformValue(item.value) : defaultValue
							const percent = !isNil(item.value) ? fnPercent(item.value, maxValue) : 0

							return (
								<KpiBarComponent
									key={`kpibar-${i}`}
									label={label}
									prefix={prefix}
									value={kpiBarValue}
									suffix={suffix}
									percent={percent}
									indicator={indicator}
									cx={item.cx || 0}
								/>
							)
						})}
					</Defer>					
					<KpiBarLegendComponent legend={legend} />
				</div>
			</Kpi>
		)
	}
}

const KpiStatsValue = ({ average, suffix, tooltip, icon }) => {
	let averageRender = <FormattedMessage {...T('Dashboard.n/a')} />

	if (average !== null && average !== undefined) {
		averageRender = (
			<span>
				<FormattedNumber value={average.toFixed(2)} />
				{suffix}
			</span>
		)
	}

	return (
		<div className="kpi__stats-value" data-tip data-for={`${tooltip.title.key}`}>
			<KpiIcon icon={icon} />
			{averageRender}
			<Tooltip id={`${tooltip.title.key}`} title={tooltip.title} subtitle={tooltip.subtitle} />
		</div>
	)
}

const KpiBarComponent = ({ label, prefix, value, suffix, percent, indicator, cx }) => {
	const renderValue =
		isNil(value) || isNil(percent) ? (
			<FormattedMessage {...T('Dashboard.n/a')} />
		) : (
			<span>
				<FormattedNumber value={value.toFixed(2)} />
				{suffix}
			</span>
		)

	return (
		<KpiBar className={`kpi-bar kpi-bar--${indicator}`}>
			<div className="kpi-bar-line">
				<KpiBarLabel title={label} indicator={indicator} className="kpi-bar__text--truncate">
					{label}
					{prefix}
				</KpiBarLabel>
				<KpiBarBar indicator={indicator}>
					<div className="outer">
						<div
							className={`inner kpi-bar__inner--opacity-${cx}`}
							style={{ width: `${percent}%` }}
						/>
					</div>
				</KpiBarBar>
				<KpiBarValue>{renderValue}</KpiBarValue>
			</div>
		</KpiBar>
	)
}

const KpiBarLegendComponent = ({ legend }) => {
	if (legend.hasOwnProperty('from') && legend.hasOwnProperty('to')) {
		return (
			<KpiBarLegend>
				<div />
				<div>
					<div>{legend.from}</div>
					<div>{legend.to}</div>
				</div>
				<div />
			</KpiBarLegend>
		)
	}

	return (
		<KpiBarLegend>
			<div />
			<div className="text--truncate align-right">{legend}</div>
			<div />
		</KpiBarLegend>
	)
}

export default KpiComponent
