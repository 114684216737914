import { getToken } from './TokenUtil'
import { connector } from './Connector'
import { isNil } from 'lodash'

export const getHdDeviceModelsFeatures = (leftModelId = '0', rightModelId = '0') => {
  rightModelId = isNil(rightModelId) || rightModelId.trim() =='' ? '0' : rightModelId
  leftModelId = isNil(leftModelId) || leftModelId.trim()=='' ?  '0' :  leftModelId

  const params = { leftModelId, rightModelId }
  try {
    return connector.get(getToken(), `/api/hearing-aids/features`, params).then(response => {
      return response
    })
  } catch (e) {
    console.log('error occured while fetching hdModelfeatures', e)
  }
}
