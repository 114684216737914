import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import Tooltip from 'react-tooltip'
import { Link, Outlet } from 'react-router-dom'
import { withRouter } from "helper/withRouter";
import { FormattedMessage, injectIntl } from 'react-intl'
import { T } from 'core'
import { compose } from 'lodash/fp'
import { LOCALE_QUERY } from './../graphql/queries/LocaleQuery'
import { LOCALE_MUTATION } from './../graphql/mutations/LocaleMutation'
import { AUTHENTICATION_QUERY } from './../graphql/queries/AuthenticationQuery'

import { routeChangeTracking } from 'helper/routeChangeTracking'

import { logout } from 'actions/authentication'
import { isAuthenticated as isAuthenticatedHelper, getToken } from 'helper/AuthHelper'

import Popup from 'containers/Popup'
import Footer from 'components/Footer.js'
import VideoCallManager from 'components/_VideoCall/Icelink/VideoCallManager'
import AudioVideoCallManager from 'components/_VideoCall/Icelink/AudioVideoCallManager'
import VideoOverlay from 'components/_VideoCall/Icelink/VideoOverlay'
import NavigationBar from './NavigationBar'
import fflags from 'helper/FFlags'

import '../scss/styles.scss'
import { APP_QUERY } from './../graphql/queries/AppQuery'
import { setIntervalTogetGuidForUserDataConsent } from 'helper/UserDataConsentUtils'
import { activateActivityTracker } from 'helper/UserActivityTracker'
import { isPasswordExpiryPage } from 'helper/PasswordExpiryCheck'
import { FETCH_CONFIGURATION } from 'graphql/queries/ConfigurationQuery'
import { dataService, fileUploadService } from "helper/rxjsUtil"
import DataContext from "./DataContext"
import { ThemeSwitcher } from './ThemeSwitcher'
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { sendDataCollectionDebounced, sendDataToDataCollection } from './../helper/DataCollection/SendDataToDataCollectionUtil'
import { DataCollectionType } from 'helper/constants'
import { EventType } from '../helper/DataCollection/DataCollectionConstants'
import { LogoutData } from '../helper/DataCollection/PayloadData';

import AudioVideoCall from '../components/_VideoCall/Liveswitch/AudioVideoCall';
import LiveswitchVideoCallManager from '../components/_VideoCall/Liveswitch/LiveswitchVideoCallManager'
import { ClearSessionStorage } from '../helper/StorageUtils'
import { getAppVersion, getEnviroment } from '../helper/TokenUtil';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      theme: { mode: "theme-neutral" },
      tourGuideIsDisplayed: false,
    }
  }
  isSetIntervalTogetGuidCalled = false

  UNSAFE_componentWillMount() {
    if (localStorage.getItem('passwordExpired')) {
      this.props.navigate('/password/expire')
    }

    //this.props.history.listen(routeChangeTracking)
    const isAudioVideoCallForSigniaFeatureFlagEnabled = fflags.variation(
      'hcp-portal-enable-audio-video-calls-for-signia-app-patients',
      false
    )
    if (isAudioVideoCallForSigniaFeatureFlagEnabled) {
      this.videoCallManager = new AudioVideoCallManager()
      this.liveswitchVideoCallManager = new LiveswitchVideoCallManager()
    } else {
      this.videoCallManager = new VideoCallManager()
    }
    window.videoCallManager = this.videoCallManager
    window.liveswitchVideoCallManager = this.liveswitchVideoCallManager
    this.videoCallManager.on('change', this.onVideoManagerChange)
    this.liveswitchVideoCallManager.on('change', this.onVideoManagerChange)
  }

  componentDidMount() {
    if (isAuthenticatedHelper()) {
      activateActivityTracker()
    }
    dataService.getData().subscribe(message => {
      this.props.RefetchConfiguration({ orgId: JSON.parse(localStorage.getItem(`LIGHTCLOUD${getAppVersion()}`))?.organizationId })
    });
    fileUploadService.getData().subscribe(message => {
      this.props.data.refetch();
    })
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.data.Me && nextProps.isAuthenticated && !this.isSetIntervalTogetGuidCalled) {
      setIntervalTogetGuidForUserDataConsent(nextProps.data.Me)
      this.isSetIntervalTogetGuidCalled = true
    }
  }

  getContextValues() {
    return {
      me: this.props.data.Me,
      videoCallManager: this.videoCallManager,
      liveswitchVideoCallManager: this.liveswitchVideoCallManager,
      token: this.props.token,
      locale: this.props.locale,
      intl: this.props.intl,
      displayTourGuide: this.state.tourGuideIsDisplayed,
      toggleDisplayTourGuide: () => { this.setState((prevState) => ({ tourGuideIsDisplayed: !prevState.tourGuideIsDisplayed })); },
      Configuration: this.props.Configuration
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.props.data.refetch()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.checkTimer)
    this.videoCallManager.off('change', this.onVideoManagerChange)
  }

  onVideoManagerChange = () => {
    this.setState({})
  }

  enableTranslation = () => {
    window.location.href = '#'
  }

  HandleLogout = async () => {
    await sendDataToDataCollection(DataCollectionType.LogOut, LogoutData({}), EventType.LogOut)
    await sendDataCollectionDebounced.flush();
    ClearSessionStorage()
    this.props.logout()
  }

  RenderDevelopmentFeature() {
    return (
      <div className="btn-debug-set">
        {getEnviroment() === 'development' &&
          <button
            className="btn-debug"
            onClick={() => {
              const baseUrl = localStorage.getItem('baseUrl')
              localStorage.clear()
              localStorage.setItem('baseUrl', baseUrl)
              window.location.href = '/'
            }}
            type="submit"
          >
            <i className="fas fa-fw fa-2x fa-trash" />
            Clear Cache
          </button>}
        {getEnviroment() === 'development' &&
          <button
            className="btn-debug"
            onClick={() => {
              window.location.hash = '/devtools'
            }}
            type="submit"
          >
            <i className="fas fa-fw fa-2x fa-wrench" />
            DevTools
          </button>}
      </div>
    )
  }

  render() {
    /* global __ENV__ */
    const { isAuthenticated, location, data: { loading, Me }, Configuration } = this.props
    return (
      <DataContext.Provider value={this.getContextValues()}>
        <section>
          <VideoOverlay />
          <AudioVideoCall />
          <ThemeSwitcher brandId={Configuration?.brandId} mode="light">
            <NavigationBar
              locale={this.props.locale}
              isAuthenticated={isAuthenticated}
              setLocale={this.props.setLocale}
              logout={this.HandleLogout}
              loading={loading}
              router={this.props.router}
              location={location}
              Me={Me}
              Configuration={Configuration}
            />
            {isAuthenticated && !isPasswordExpiryPage(location.pathname)
              ? <div className="container bread-crumbs-container">
                <div className="row">
                  <div className="col-12">
                    <div className="bread-crumbs">
                      <Link key="homepage" to="/">
                        <StyledFontAwesomeIcon icon={faHome} size="lg" type="home" className="icon-lg" /> &nbsp;
                        <FormattedMessage {...T("common.home")} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {!loading && <main>
              <Outlet />
            </main>}
            <Popup />
          </ThemeSwitcher>
          <div className='footerContainer'>
            <Footer />
          </div>
          {this.RenderDevelopmentFeature()}
          <Tooltip delayShow={1000} />
        </section>
      </DataContext.Provider>
    );
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: ({ data }) => {
      return { locale: data.locale.locale };
    },
  }),
  graphql(LOCALE_MUTATION, {
    props: ({ mutate }) => {
      return { setLocale: (locale) => mutate({ variables: { locale } }) };
    },
  }),
  graphql(APP_QUERY, {
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }),
  }),
  graphql(AUTHENTICATION_QUERY, {
    props: () => {
      return {
        isAuthenticated: isAuthenticatedHelper(),
        token: getToken(),
        logout: logout,
      };
    },
  }),
  graphql(FETCH_CONFIGURATION, {
    props: ({ data: { Configuration, refetch } }) => {
      return {
        Configuration,
        RefetchConfiguration: refetch
      };
    },
    options: () => {
      const orgId = JSON.parse(localStorage.getItem(`LIGHTCLOUD${getAppVersion()}`))?.organizationId
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          orgId,
        },
        fetchPolicy: "network-only",
      };
    },
  })
);

export default withRouter(injectIntl(withData(App)))
