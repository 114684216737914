export default `
  scalar Date

  type ActiveProgram {
    deviceId: Int!
    daysInUse: Int
    deviceName: String
    entries: [ActiveProgramEntry]
  }

  type ActiveProgramEntry {
    name: String
    value: Int
  }

  type Assignment {
    lessonId: Int!
    assignmentId: Int!
    deviceName: String
    name: String
    description: String
    rating: Int
    date: Date
    hearingAidStatus: HearingAidStatus
    educational: Boolean
    bonus: Boolean
    completed: Boolean
    feedback: [SatisfactionFeedback]
  }

  type MediaItem {
    id: Int
    mediaType: String
    url: String
  }

  type HACapabilities {
    appConnected: Boolean
    bluetooth: [String]
    mobileOS: String
  }

  type Capability {
    appConnected: Boolean
    bluetooth: [String]
    teleCareVersion: String
  }

  type ChatMessage {
    id: String!
    createDate: Date
    readOnDate: Date
    message: String
    recipient: ChatUser
    sender: ChatUser
    messageType: String
    payload: ChatMessagePayload
  }

  type ChatMessagePayload {
    settingUuid: String
    programSlot: Int
  }

  type Countries {
    id: String!
    countryCode: String
    enableHcpRegistration: Boolean
    zipCodeValidation: String
  }

  type CountryCallingCodes{
    countryCallingCode: String
    isCallingCodeBlocked: Boolean
  }

  type OrganizationBrands {
    id: Int
    brandName: String
    brandLogoUrl: String
  }

  type Configuration {
    brandId: Int
    brandName: String
    brandLogoUrl: String
  }

  type Customer {
    id: Int!
    firstName: String
    lastName: String
    dateOfBirth: Date
    country: String
    email: String
    experience: String
    gender: String
    language: String
    phone: String
    employeeId: Int
    mobileOs: String
    practiceId: Int
    timeZone: String
    webCode: String
    journeyStatus: String
    journeyStartedOn: Date
    hearingDevices: HearingDevices
    moduleLength: Int
    appInfo: AppInfo
    uuid: String
    digitalAssistantId: String
    digitalAssistantConsent: Boolean
    brandDisplayId: Int
    cxxId: String
    upid: String
  }

  type AppInfo {
    appName: String
    appVariant: String
    appVersion: String
    osType: String
    osVersion: String
  }

  type Employee {
    id: Int!
    avatar: MediaItem
    email: String
    emailVerified: Boolean
    firstName: String
    lastName: String
    gender: String
    imageUrl: String
    inactiveFeatures: [String]
    manager: Boolean
    orgManager: Boolean
    locale: String
    migrated: Boolean
    phone: String
    phoneVerified: Boolean
    securityUserId: Int
    unreadMessages: Int
    username: String
    videoCallsEnables: Boolean
    weeklyMailSummary: Boolean
    assignedPracticeIds: [Int]
    defaultPracticeId: Int
    practice: Practice
    defaultModuleLength: Int
    notificationTime: String
    tokenValidUntil: Date
    userDataConsent: Boolean
  }

  type Environment {
    deviceId: Int!
    daysInUse: Int
    deviceName: String
    entries: [EnvironmentEntry]
  }

  type EnvironmentEntry {
    name: String
    value: Int
  }

  type EqualizerSetting {
    programSlot: Int
    band1: Int
    band2: Int
    band3: Int
    band4: Int
    valid: Boolean
  }

  input EqualizerSettingInput {
    programSlot: Int
    band1: Int
    band2: Int
    band3: Int
    band4: Int
  }

  type HDModel {
    active: Boolean
    amplificationRange: String
    bluetooth: Boolean
    brand: String
    companyCode: String
    companyNo: Int
    id: String!
    intelliSideSupport: Boolean
    intelliZoomSupport: Boolean
    manualURL: String      
    manufacturer: String
    manufacturerURL: String      
    model: String
    performance: String
    persistentConfigurationSupport: Boolean
    platform: String
    remoteFittingSupport: Boolean
    series: String
    type: String
    hiTypeUri: String
  }

  type HDModelsFeatures {
    availablePrograms: [ String ]
    bluetoothSupported: Boolean
    defaultPrograms: [ String ]
    availableTeleCareVersions: [ String ]
    programSlots: Int
    remoteFittingSupported: Boolean
    defaultAvailableTeleCareVersion: String
  }

  type Brand {
    id: String
    name: String
    pairable: [String]
    haBrandId: Int
  }

  type brandProgram {
    value: String
    required: Boolean
  }

  type HDPreset {
    id: Int
    programNumber: Int
  }

  type HDProgram {
    balance: Int
    enableTinitusMasker: Boolean
    equalizer1: Int
    equalizer2: Int
    equalizer3: Int
    equalizer4: Int
    hdPresets: [HDPreset]
    id: Int
    intelliSideDirection: String
    name: String
    programNumber: Int
    spatial: Int
    tinitusVolume: Int            
    type: String
    volume: Int
  }

  type HearingAidStatus {
    programName: String
    updatedDate: Date
    volume: Volume
    soundBalance: Volume
    acousticEnvironmentData: AcousticEnvironmentData
  }

  type Volume {
    max: Int
    value: Int
  }

  type AcousticEnvironmentData {
    bar: Bar
    entries: AcousticEnvironmenEntries
  }

  type Bar {
    wind: Int
    sound: Int
    chat: Int
  }

  type AcousticEnvironmenEntries {
    speechInQuiet: Int
    speechInNoise: Int
    noise: Int
    music: Int
    car: Int
    quiet: Int
  }

  type HearingDevices {
    uuid: String
    hdPrograms: [HDProgram]
    leftDevice: HDModel
    rightDevice: HDModel
    persistentConfiguration: Boolean
    teleCareVersion: String    
    capabilities: HACapabilities
  }

  type Interaction {
    id: Int!
  }

  type Interactions {
    settingInteractions: [SettingInteraction]
    satisfactionInteractions: [SatisfactionInteraction]
    chatMessages: [ChatMessage]
  }

  type ChatUser {
    id: Int!
    firstName: String
    lastName: String
  }

  type Journey {
    id: Int!
    closingDate: Date
    createDate: Date
    moduleLength: Int
    open: Boolean
    startedOn: Date
    status: String
    updateDate: Date
    modules: [Module]
  }

  type LessonOption {
    id: Int!
  }

  type Pagination {
    pageNumber: Int
    pageSize: Int
    numberOfElements: Int
    totalElements: Int
    totalPages: Int
  }

  type PatientsList {
    id: Int!
      appConnected: Boolean
      currentProgress: Float
      employeeFirstName: String
      employeeImageUrl: String
      employeeLastName: String
      firstName: String
      lastName: String
      journeyDurationDays: Int
      journeyStatus: String
      journeyClosingDate: Date
      journeyStartedOn: Date
      lastSatisfactionDate: Date
      lastSatisfactionRating: Int
      lastWeeksProgress: Float
      numberOfUnreadChatInteractions: Int
      telecareVersion: String
      assistantHistoryStatus: Boolean
      averageWearingTime:Float
  }

  type Kpis {
    name: String
    type: String
    totalNumberOfPatients: Int
    kpis: [KpisPracticeKpi]
  }

  type KpisPracticeKpi {
    type: String
    value: Float
  }

  type Me {
    roles: [String]
    state: String
    organizationId: Int
    countryCode: String
    employee: MeEmployee
    organization: Organization
  }

  type Organization {
      inactiveFeatures: [String]
  }

  type OrganizationDetail {
    organizationId: Int
    name: String
    email: String 
    phone: String 
    website: String 
    language: String 
    timeZone: String
    street: String  
    city: String 
    state: String 
    zipCode: String 
    countryCode: String  
    isPasswordExpirationPolicyEnabled: Boolean
    daysToExpirePassword: Int
    autoReplyMode: String
    autoReplyMessage: String
    brandId: Int
    brandName: String
    brandLogoUrl: String
  }

  type MeEmployee {
    id: Int!
    avatar: MediaItem
    email: String
    emailVerified: Boolean
    firstName: String
    lastName: String
    gender: String
    imageUrl: String
    inactiveFeatures: [String]
    manager: Boolean
    orgManager: Boolean
    locale: String
    migrated: Boolean
    phone: String
    phoneVerified: Boolean
    securityUserId: Int
    unreadMessages: Int
    username: String
    videoCallsEnables: Boolean
    weeklyMailSummary: Boolean
    assignedPracticeIds: [Int]
    defaultPracticeId: String
    practice: Practice
    defaultModuleLength: Int      
    userDataConsent: Boolean
  }

  type MobileDevice {
    id: Int!
    appBrandingType: String
    createDate: Date
    mobileOs: String
    timeZone: String
    token: String
    updateDate: Date
  }

  type Module {
    id: Int!
    createDate: Date
    order: Int
    startedOn: Date
    updateDate: Date
  }

  type OpeningHour {
    dayOfWeek: String
    comment: String
    openingTime: String
    closingTime: String
  }

  type OrganizationConfig {
    organizationId: Int,
    autoReplyMode: String,
    autoReplyMessage: String,
    isPasswordExpirationPolicyEnabled: Boolean,
    daysToExpirePassword: Int
    }

  type Practice {
    id: Int!
    organizationId: Int
    tenantId: Int
    imageUrl: String
    name: String
    accountNumber: String
    email: String
    zipCode: String
    timeZone: String
    street: String
    city: String
    state: String
    countryCode: String
    phone: String
    openingHours: [OpeningHour]
    inactiveFeatures: [String]
    locale: String
    website: String
    autoReplyEnabled: Boolean
    autoReplyMessage: String,
    outOfOfficeMessage: String,
    defaultOutOfOfficeMessage: String,
    organizationConfig: OrganizationConfig
  }

  type AssignedPractice {
    id: Int!
    name: String!
  }

  type ReconnectPatient {
    id: Int
    webCode: String
    email: String
    phone: String
    firstName: String
    lastName: String
  }

  type SatisfactionEntry {
    rating: Int
    date: Date
    feedback: [SatisfactionFeedback]
  }

  type SatisfactionFeedback {
    question: String
    feedback: String
  }

  type SatisfactionRating {
    deviceName: String
    startDate: String
    dailySatisfactions: [SatisfactionEntry]
  }

  type SettingInteraction {
    id: Int!
    createDate: Date
    updateDate: Date
    readOn: String
  }

  type SatisfactionInteraction {
    id: Int!
    createDate: Date
    updateDate: Date
    readOn: String
    rating: Int
  }

  type WearingTimeRawData{
    recordingTimeUtc: Date
    wearingTimeInHr: Float
  }

  type PhoneValidation {
    canonicalPhoneNumber: String
  }

  type PasswordValidation {
    passwordStrengthName: String!
    passwordStrengthScore: Int!
  }

  type PatientWithPagination {
    content:[PatientsList]
    pagination: Pagination
  }

  type PracticeWithPagination {
    content:[Practice]
    pagination: Pagination
  }

  type EmployeeWithPagination {
    content:[Employee]
    pagination: Pagination
  }

  type MarketingRecipientsCounter {
    recipients: Int
    total: Int
  }
  
  type Faq {
      question: String
      answer: String
  }

  type Category {
    categoryId: Int
    translatedText: String
  }

  type LessonSetModelForSignia {
  id: Int   
  lessonSetName: String
  comment: String
  isDefault: String
  createdDate: String
  lessonIds: [Int]
  }

  type LessonCategoryForSignia {
  categoryName: String
  description: String
  orderId: Int
  }

  type DefaultLessonSetModelForSignia {
  lessonId: Int
  heading: String
  description: String
  howTo: String
  title: String
  lessonCategory: LessonCategoryForSignia
  }

  type UpdateLessonSetResponseForSignia {
  lessonSetName: String
  comment: String
  isDefault: String
  createdDate: String
  modifiedDate: String
  lessonIds: [Int]
  }

  type NotificationTimeOptions {
  timeSet: [String]
  }

  type HIDSetting {
  bluetoothDisabled: Boolean
  }

  type LessonCategory {
  categoryName: String
  description: String
  orderId: Int
  }

  type Rating {
  rating: Int
  ratingDate: String
  answerText: String
  }

  type Lesson {
  description: String
  howTo: String
  title: String
  leftHearingDevice: String
  rightHearingDevice: String
  ratingDetail: Rating
  }

  type SigniaRating {
  lessonCategory: LessonCategory
  lessons: [Lesson]
  }

  type VideoThumbnailDetail {
  thumbnail_width: Int,
  thumbnail_url: String,
  thumbnail_height: Int
  }

  type AssistantHistory {
  ownVoiceProcessingCount: Int,
  dynamicSoundscapeProcessingCount: Int,
  compressionCount: Int,
  audioStreamingCount: Int,
  numberOfModifications: Int,
  ownVoiceProcessingName: String,
  dynamicSoundscapeProcessingName: String,
  compressionName: String,
  audioStreamingName: String,
  listOfModifications: [Modification]
  }

  type Modification {
  date: Date,
  soundSource: String,
  soundAttribute: String,
  change: String
  cssClass: String
  }

  type Notification {
    patientNotificationId: String
    patientFirstName: String
    patientLastName: String
    tcbeId: Int
    notificationType: Int
    modifiedDate: Date
    isRead: Boolean
  }

  input OpeningHourInput {
    dayOfWeek: String
    comment: String
    openingTime: String
    closingTime: String
  }

  input HDProgramSetInput {
    hdPrograms: [HDProgramInput]
    persistentConfiguration: Boolean
    uuid: String
  }

  input HDProgramInput {
    balance: Int
    enableTinitusMasker: Boolean
    equalizer1: Int
    equalizer2: Int
    equalizer3: Int
    equalizer4: Int
    id: Int
    intelliSideDirection: String
    name: String
    programNumber: Int
    spatial: Int
    tinitusVolume: Int
    type: String
    volume: Int
  }

  input PracticeInput {
    id: Int
    organizationId: Int
    tenantId: Int
    name: String
    phone: String
    street: String
    zipCode: String
    logo: Boolean,
    city: String
    state: String
    countryCode: String
    timeZone: String
    email: String
    openingHours: [OpeningHourInput]
    locale: String
    accountNumber: String
    website: String
    autoReplyEnabled: Boolean,
    autoReplyMessage: String,
    outOfOfficeMessage: String
  }
      
  input EmployeeInviteInput {
    email: String
    manager: Boolean
    orgManager: Boolean
    locale: String
    assignedPracticeIds: [Int]
    defaultPracticeId: Int
    username: String
    password: String
  }
      
  input EmployeeInputWithUserDataConsent {
    id: Int
    email: String
    emailVerified: Boolean
    firstName: String
    lastName: String
    gender: String
    inactiveFeatures: [String]
    manager: Boolean
    orgManager: Boolean
    locale: String
    migrated: Boolean
    phone: String
    phoneVerified: Boolean
    securityUserId: Int
    unreadMessages: Int
    username: String
    enableNewMessage: String
    enableSatisfactionRating: String
    enableLessonRating: String
    videoCallsEnables: Boolean
    weeklyMailSummary: Boolean
    assignedPracticeIds: [Int]
    defaultPracticeId: Int
    enableTraining: String
    password: String
    receiveAlertMessages: String
    defaultModuleLength: Int
    notificationTime: String
    userDataConsent: Boolean
    organizationId: Int
  }

  input InvitedEmployeeInputWithUserDataConsent {
    email: String
    emailVerified: String
    firstName: String
    lastName: String
    gender: String
    inactiveFeatures: [String]
    manager: Boolean
    orgManager: Boolean
    locale: String
    phone: String
    phoneVerified: Boolean
    username: String
    assignedPracticeIds: [Int]
    defaultPracticeId: Int
    enableTraining: String
    password: String
    defaultModuleLength: Int
    notificationTime: String
    userDataConsent: Boolean
  }

  input PatientInput {
    firstName: String
    lastName: String
    phone: String
    leftDevice: Int
    rightDevice: Int
    lessons: [[Int]]
    programs: [String]
    persistentConfiguration: Boolean
    teleCareVersion: String
    moduleLength: Int
    practiceId: Int
    employeeId: Int
  }

  input PrefitingPatientInput {
    firstName: String!
    lastName: String
    phone: String
    practiceId: Int!
    employeeId: Int!
  }

  input MessageInput {
    employeeId: Int
    patientId: Int
    message: String
  }

  type Payload{
    title: String
    message: String
    icon: String
  }

  type PopUp {
    type: String
    payload: Payload
  }
  type Overlay {
    isOpen: Boolean
    modal: Boolean
    popup: PopUp
  }

  type PasswordChangeStatus{
    success : Boolean
    message : String
    code : String
    status : Int
  }

  type EmployeeInviteStatus{
    success : Boolean
    message : String
    code : String
    status : Int
  }

  type GenericUpdateStatus{
    success : Boolean
    message : String
    code : String
    status : Int
  }

  input LessonsInput {
    category: String
    description: String
    heading: String
    title: String
    howTo: String
    lessonId: Int
    lessonType: String
    order: Int
    summary: String
    week: Int

  }

  input ModulesInput {
    lessons: [LessonsInput]
    moduleNumber: Int
    name: String
  }

  input LessonSetInput {
    category: String
    description: String
    hcpId: Int
    isDefault: Boolean
    modules: [ModulesInput]
    name: String
    createDate: Date
    updateDate: Date
    moduleLength: Int
  }

  input LessonSetUpdateInput {
    id: String
    category: String
    description: String
    hcpId: Int
    moduleLength: Int
    isDefault: Boolean
    modules: [ModulesInput]
    name: String
    createDate: Date
    updateDate: Date
  }

  input HIDSettingInput {
    patientId: Int
    bluetoothDisabled: Boolean
  }

  input PasswordInput { 
    oldPassword: String
    newPassword: String
  }

  input LocationInput { 
    pathname: String
    search: String
    hash: String
    action: String
    key: String
    isQueryActive: Boolean
    state: String      
    query: URLQueryInput
  }    

  input URLQueryInput {
    query: String
  }
  
  input MetaInput {
    Id: String
    GroupID: String
    DataCollectionPOSOrgID: String
    DataCollectionPOSOrgName: String
    DataCollectionPOSID: String
    DataCollectionEmployeeID: String
    DataCollectionHIWID: String
    TestPOS: Boolean
    TestAccountEmployee: Boolean
    TestAccountHIW: Boolean
    AnonHIIDLeftL: String
    AnonHIIDRight: String
  }

  input FieldsInput {
    CommunicationType: String
    Direction: String
    Country: String
    City: String
    isEnabled: Boolean
    validityPeriodDays: String
    autoReplyMode: String
    autoReplyMessage: String
    brandName: String
    oldLocale: String
    newLocale: String
    TextLength: Int
  }

  input payloadInput {
    TimeStamp: Date
    EventType: String
    Meta: MetaInput
    Fields: FieldsInput
  }

  input DataCollectionModel {
    ClientID: String 
    TimeStamp: Date
    ProductSource: String
    ProductSourceVersion: String
    Payload: [payloadInput]
  }

  input SigniaLessonSetInput {
      lessonSetName: String
      comment: String
      isDefault: Boolean
      lessonIds: [Int]
  }

  input AuthenticationToken {
    access_token: String!
    token_type: String!
    refresh_token: String!
    expires_in: Int!
    scope: String!
    organizationId: Int!
    patientUuId: String
    employeeId: Int!
    tenantId: Int
    securityUserId: Int!
    jti: String!
    refresh_token_expires_in: Int!
  }

  input Defaultpractice {
    id: Int 
    name: String 
    timeZone: String 
    city: String 
    countryCode: String
    accountNumber: String
  }

  input defaultPractice { 
    defaultPractice: Defaultpractice
  }

  input PopupPayloadInput {
    message: String
    title: String
  }

  input popup {
    payload: PopupPayloadInput
    type: String
  }

  input OrgnizationDetailsInput {
    organizationId: Int! 
    name: String
    email: String 
    phone: String 
    website: String 
    language: String 
    timeZone: String
    street: String  
    city: String 
    state: String 
    zipCode: String 
    countryCode: String 
    isPasswordExpirationPolicyEnabled: Boolean
    daysToExpirePassword: Int
    autoReplyMode: String
    autoReplyMessage: String
    brandId: Int
  }

  input MovePatientsInput {
    orgId: Int!
    newEmployeeId: Int!
    oldEmployeeId: Int!
    employeeFirstName: String
    employeeLastName: String
    employeeImageUrl: String
  }

  input ContactPhoneEntity {
    phone: String!
    locale: String!
  }

  input ContactEmailEntity {
    email: String!
    locale: String!
  }

  input ContactVerifyPhoneEntity {
    phone: String!
    code: String!
  }

  input ContactVerifyEmailEntity {
    email: String!
    code: String!
  }

  input EmpPhoneUpdateRequest {
    phone: String!
    phoneVerified: Boolean!
  }

  input EmpEmailUpdateRequest {
    email: String!
    emailVerified: Boolean!
  }

  type Query {
    ActiveProgram(customerId: Int!): [ActiveProgram]
    Countries: [Countries]
    CountryCallingCodes:[CountryCallingCodes]
    OrganizationBrands: [OrganizationBrands]
    Configuration (orgId: Int): Configuration
    Customer (id: Int!): Customer
    Customers: [Customer]
    InvitedEmployee(email: String, key:String, orgId:String, tenantId:String, employeeId: String): Employee
    Employee(id: Int!): Employee
    Employees( practiceId: Int): [Employee]
    Environment(customerId: Int!): [Environment]
    EqualizerSettings(customerId: Int!, correlationContext: String): [EqualizerSetting]
    HDModels(brand: String): [HDModel]
    HDModelsFeatures( leftModelId: String!, rightModelId: String! ): HDModelsFeatures
    Brands(id: Int!): [Brand]
    HearingAidStatus(customerId: Int!): HearingAidStatus
    HearingDevices(customerId: Int!): HearingDevices
    Journey(journeyId: Int!): Journey
    Kpis: [Kpis]
    Me: Me
    Messages(patientId: Int!, pageNumber: Int, size: Int, correlationContext: String): [ChatMessage]
    PatientsList(
      employeeId: Int
      page: Int
      practiceId: Int
      search: String
      sort: String
      patientType: String
    ): PatientWithPagination
    Practice(id: Int!): Practice
    Practices: [Practice]
    AssignedPractices(id: Int!): [AssignedPractice]
    PracticesList(
      page: Int
      search: String
      sort: String
      order: String
      orgId: Int
    ): PracticeWithPagination
    AssistantHistoryList(
      digitalAssistantId: String!
      timeZone: String
      brandId: Int
      language: String
      recordsCount: Int
      date: String
    ): AssistantHistory
    EmployeeList(
      practiceId: Int
      page: Int
      search: String
      sort: String
      accountState: String
      size: Int
    ): EmployeeWithPagination
    PhoneValidation(countryCode: String!, phoneNumber: String!): PhoneValidation
    PasswordValidation(password: String!): PasswordValidation
    ReconnectPatient(patientId: Int!, correlationContext: String): ReconnectPatient
    SatisfactionRatings(customerId: Int!, locale: String!): [SatisfactionRating]
    WearingTimeRawData(customerId: Int!):[WearingTimeRawData]
    MarketingRecipientsCounter(filters: String): MarketingRecipientsCounter
    Faq(
      context: String
      category: Int
      locale: String
    ): [Faq]
    Category(locale: String
    ): [Category]
    NotificationTimeOptions : NotificationTimeOptions
    HIDSetting(patientId: Int!): HIDSetting
    PatientFromCxxId(cxxId: String!): Customer
    SigniaLessonRatings(patientGuid: String!): [SigniaRating]
    LessonSetForSignia(hcpId: Int):[LessonSetModelForSignia]
    DefaultLessonSetForSignia:[DefaultLessonSetModelForSignia]
    VideoThumbnailDetail(videoUrl: String) : VideoThumbnailDetail
    LessonsForSigniaPatient(patientGuid: String!): [DefaultLessonSetModelForSignia]
    OrganizationDetails(orgId: Int!): OrganizationDetail
    GetNotifications: [Notification]
  }

  type Mutation {
    createPractice(practice: PracticeInput!): Practice,
    updatePractice(practiceId: Int!, practice: PracticeInput!): Practice
    deletePractice(practiceId: Int!): Boolean
    inviteEmployee(employee: EmployeeInviteInput!, correlationContext: String): EmployeeInviteStatus
    resendInviteEmployee(email: String!, correlationContext: String): EmployeeInviteStatus
    updateEmployee(employeeId: Int!, employee: EmployeeInputWithUserDataConsent!): Employee
    updateInvitedEmployee(employeeId: Int!, orgId: Int!, key: String!, tenantId: Int!, employee: InvitedEmployeeInputWithUserDataConsent!): Employee
    deleteEmployee(employeeId: Int!): Boolean
    createPatient(patient: PatientInput!): Customer
    updatePatient(patientId: Int!,  patient: PatientInput!): Customer
    createPrefitingPatient(patient: PrefitingPatientInput!): Customer,
    updatePrefitingPatient(patientId: Int!,  patient: PrefitingPatientInput!): Customer
    deletePatient(patientId: Int!): Boolean
    uploadEmployeeAvatar(file: String, employeeId: Int): Boolean
    uploadSelfCompleteAvatar(file: String, employeeId: Int!, orgId: Int!, key: String!, tenantId: Int! ): Boolean
    deleteEmployeeAvatar(employeeId: Int): Boolean
    uploadPracticeLogo(file: String, practiceId: Int): Boolean
    deletePracticeLogo(practiceId: Int): Boolean
    sendMessage(data: MessageInput!, correlationContext: String): Boolean
    markMessageAsRead(ids: [String]!, correlationContext: String): Boolean
    resetUnreadMessageCount(patientId: Int!, correlationContext: String): Boolean
    patientStartTrial(patientId: Int!,  patient: PatientInput!): Customer
    sendMarketingMessage(message: String!, filters: String, employeeId: Int): Boolean
    updateEqualizerSetting(patientId: Int!, equalizerSetting: EqualizerSettingInput!): EqualizerSetting
    updateHIDSetting( hidSetting: HIDSettingInput! ): Boolean
    changePassword( employeeId: Int!,passwords :PasswordInput! ) : PasswordChangeStatus
    sendToDataCollection(dataCollectionModel: DataCollectionModel!): Boolean
    restartLesson(patientGuid: String!): String
    createLessonSetForSignia( hcpId: Int!, lessonSet :SigniaLessonSetInput! ): LessonSetModelForSignia
    updateLessonSetForSignia( hcpId: Int!, lessonSetId: String!, lessonSet :SigniaLessonSetInput! ): UpdateLessonSetResponseForSignia
    updateLessonsForSigniaPatient(patientGuid: String!, lessonIds: [Int]): [Int]
    updateExpiredPassword(password: PasswordInput!): Boolean
    updateOrganization(orgId: Int!, organizationDetail: OrgnizationDetailsInput!): Boolean
    movePatients(movePatientsRequest: MovePatientsInput!): GenericUpdateStatus
    generateOtpForPhoneVerification(contactPhoneEntity: ContactPhoneEntity!): Boolean
    generateOtpForEmailVerification(contactEmailEntity: ContactEmailEntity!): Boolean
    verifyOtpForPhoneVerification(contactVerifyPhoneEntity: ContactVerifyPhoneEntity!): Boolean
    verifyOtpForEmailVerification(contactVerifyEmailEntity: ContactVerifyEmailEntity!): Boolean
    updateEmployeePhoneVerificationStatus(employeeId: Int!, empPhoneUpdateRequest: EmpPhoneUpdateRequest): Boolean
    updateEmployeeEmailVerificationStatus(employeeId: Int!, empEmailUpdateRequest: EmpEmailUpdateRequest): Boolean
    updateInvitedEmployeePhoneVerificationStatus(employeeId: Int!, orgId: Int!, key: String!, tenantId: Int!, empPhoneUpdateRequest: EmpPhoneUpdateRequest!): Boolean
    clearAllNotifications(notificationType: Int!):Boolean
    deleteNotification(patientNotificationId: String!):Boolean
    updateNotificationReadDetail(patientNotificationId: String!):Boolean
  }

  schema {
    query: Query
    mutation: Mutation
  }
`
