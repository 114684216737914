import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'
import { localeToCountry } from 'helper/constants'
import { LOCALE_QUERY } from './../graphql/queries/LocaleQuery'
import { compose } from 'lodash/fp'
import { isPasswordExpiryPage } from '../helper/PasswordExpiryCheck'
import { withRouter } from 'helper/withRouter'
import { getAppVersion } from '../helper/TokenUtil';

/* global __VERSION__, __ENV__ */

export class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 600
      });
    }, false);
  }
  render() {
    const { location } = this.props
    const corporateInformationLink = '/#/imprint'
    const disclaimerLink = `/#/disclaimer?country=${this.props.country}`

    return (
      <div className={`footer row ${isPasswordExpiryPage(location.pathname) ? 'disabled-div' : ''}`}>
        <div className="container-fluid">
          <div className="row text-right">
            <div className='offset-1 col-3 font-footer vertical-center'>
              <span className='font-bold-footer'>TeleCare</span> Portal
            </div>
            <div className="vl"></div>
            <div className={`${this.state.isMobile ? 'row' : 'col-7'} text-left-footer vertical-center`}>
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    href={corporateInformationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-qa="corporate-information-link"
                  >
                    <FormattedMessage {...messages.footerCorporateInformation} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`/#/terms?country=${this.props.country}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    data-qa="terms-link"
                  >
                    <FormattedMessage {...messages.commonTerms} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href='https://www.sivantos.com/privacy-policy/'
                    rel="noopener noreferrer"
                    target="_blank"
                    data-qa="privacy-link"
                  >
                    <FormattedMessage {...messages.commonPrivacy} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={disclaimerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-qa="disclaimer-link"
                  >
                    <FormattedMessage {...messages.footerDisclaimer} />
                  </a>
                </li>
              </ul>
              <div data-qa="copyright">
                © 2016-{new Date(Date.now()).getFullYear()} Sivantos Pte. Ltd. - Manufactured by
                Sivantos GmbH - TeleCare Portal v{getAppVersion()}
                {/* {__ENV__ === 'development' ? ' (DEV)' : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  locale: PropTypes.string
}

const mapCacheToProps = ({ data: { locale } }) => {
  const localeProp = locale ? locale.locale : 'en-US'
  let country = localeToCountry[localeProp] || 'US'

  return {
    locale: localeProp,
    country: country.toLowerCase()
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: mapCacheToProps
  }),
  injectIntl
)

export default withRouter(withData(Footer))
