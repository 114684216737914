import { connector } from 'helper/Connector'
import { STORAGE_KEY, StorageKeyHcpContactVerificationInfoPopup } from 'helper/constants'
import { clear } from 'helper/fetch-token-intercept/src'

export const logout = () => {
  if (window.localStorage) {
    window.localStorage.removeItem(STORAGE_KEY)
    //make it work for every user
    window.localStorage.removeItem('profile2.0-popup-shown')
    window.localStorage.removeItem('displayBrowserSupportedbanner')
  }
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(StorageKeyHcpContactVerificationInfoPopup)
  }
  clear()
  location.href = '/'
}

export const checkUsername = username => {
  return new Promise((resolve, reject) => {
    const endpoint = `/api/custom/unique-username?username=${encodeURIComponent(username)}`

    return connector.requestUrl(null, endpoint).then(() => resolve(true)).catch(response => {
      const notUnique = response.status === 409

      if (notUnique) {
        reject({ code: 'NOT_UNIQUE' })
      } else {
        console.log(response.error)
        reject()
      }
    })
  })
}

export const checkSivantosIdUnique = sivantosId => {
  return new Promise((resolve, reject) => {
    const endpoint = `api/hcp/registration/validation/account-number/${encodeURIComponent(sivantosId)}`

    return connector.requestUrl(null, endpoint).then(() => resolve(true)).catch(response => {
      const notUnique = response.status === 400
      if (notUnique) {
        reject({ code: 'NOT_UNIQUE' })
      } else {
        reject()
      }
    })
  })
}

export const checkOrgEmailExists = email => {
  return new Promise((resolve, reject) => {
    const endpoint = `api/hcp/registration/${encodeURIComponent(email)}`

    return connector.requestUrl(null, endpoint).then(response => {
      const notUnique = response.status === 200
      if (notUnique) {
        reject({ code: 'NOT_UNIQUE' })
      } else {
        reject()
      }
    }).catch(response => {
      const notUnique = response.status === 404
      if (notUnique) {
        resolve(true)
      } else {
        reject()
      }
    })
  })
}
