import React, { Component } from 'react'
import { identity } from 'lodash'
import validate from 'helper/validate'
import { CheckboxWrapper } from 'styledComponents/Div/DivComponent/DivComponent'
import { CheckboxLabel } from 'styledComponents/Label'

class Checkbox extends Component {
  static defaultProps = {
    align: 'horizontal', //oneOf(['horizontal', 'vertical'])
    dataQA: '', // usefull for QA testing
    defaultChecked: '',
    disabled: false,
    id: '',
    label: null,
    onClick: identity,
    onDirty: identity,
    onRegister: identity,
    onValidation: identity,
    required: false,
    requirements: [] // array of strings
  }

  constructor(props) {
    super(props)
    this.handleDirty = props.onDirty.bind(null, props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.input && nextProps.defaultChecked !== this.props.defaultChecked) {
      this.input.checked = nextProps.defaultChecked
    }
  }

  handleRegister = ref => {
    if (!ref) return
    this.isValid = validate(ref.checked, this.props.requirements)
    this.input = ref
    this.props.onRegister(ref, this.isValid, this.resetField, this.setValue)
    this.forceUpdate()
  }

  checkValidationState = () => {
    const { checked } = this.input

    this.updateIsValid(checked)
  }

  updateIsValid(value) {
    const { id, onValidation, requirements = [], required = false } = this.props

    if (required) {
      requirements.unshift('isRequired')
    }

    const isValid = validate(value, requirements)

    if (this.isValid !== isValid) {
      onValidation(id, isValid)
      this.isValid = isValid
    }
  }

  handleClick = () => {
    if (this.props.disabled) return
    this.input.checked = !this.input.checked
    this.props.onClick(this.props.id, this.input.checked)
    this.checkValidationState()
    this.handleDirty()
    this.forceUpdate()
  }

  resetField = () => {
    this.setValue(this.props.defaultChecked)
  }

  setValue = value => {
    this.input.checked = value
    this.checkValidationState()
    this.forceUpdate()
  }

  render() {
    const { align, defaultChecked, disabled, id, label = null, dataQA = null } = this.props

    return (
      <CheckboxLabel data-qa={`${dataQA}--label`} disabled={disabled}>
        <CheckboxWrapper
          onClick={this.handleClick}
          align={align}
          disabled={disabled}
          defaultChecked={defaultChecked}
        >
          <input
            id={id}
            type="checkbox"
            ref={this.handleRegister}
            defaultChecked={defaultChecked}
            data-qa={dataQA}
          />
        </CheckboxWrapper>
        {typeof label === 'string'
          ? <span>
              {label}
            </span>
          : label}
      </CheckboxLabel>
    )
  }
}

export default Checkbox
