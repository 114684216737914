import { gql } from "@apollo/client";

export const EQUALIZER_SETTING_QUERY = gql`
  query EqualizerSettingsQuery($patientId: Int!, $correlationContext: String!) {
    EqualizerSettings(customerId: $patientId, correlationContext: $correlationContext) {
      programSlot
      band1
      band2
      band3
      band4
      valid
    }
  }
`