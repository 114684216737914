import { graphql } from '@apollo/client/react/hoc';

import React, { Component } from 'react'
import SuccessPopup from 'components/Popups/SuccessPopup'
import DefaultPopup from 'components/Popups/DefaultPopup'

import ViewMorePopup from 'components/Popups/ViewMorePopup'
import { compose } from 'lodash/fp'

import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { UPDATE_POPUP } from 'graphql/queries/ShowPopUpQuery'
import { P_SUCCESS, P_DEFAULT_POPUP, HIDE_POPUP, getOverlayUpdateData, P_VIEWMORE_POPUP } from 'components/Popups/helper/OverlayUpdateData'

export class Popup extends Component {
  shouldHide = () => {
    const overlayData = getOverlayUpdateData(HIDE_POPUP)
    this.props.updatePopup(overlayData.popup, overlayData.isOpen)
  }

  render() {        
    const typeMap = {
      [P_SUCCESS]: <SuccessPopup shouldHide={() => this.shouldHide()} {...this.props.overlayReturnObject.payload} />,
      [P_DEFAULT_POPUP]: (
        <DefaultPopup shouldHide={() => this.shouldHide()} {...this.props.overlayReturnObject.payload} />
      ),
      [P_VIEWMORE_POPUP]: (
        <ViewMorePopup shouldHide={() => this.shouldHide()} {...this.props.overlayReturnObject.payload} />
      )
    }
    const popup = typeMap[this.props.overlayReturnObject.type]

    if (this.props.visible && popup) {
      return (
        <div className="popup">
          <div className="popup-inner">
            {popup}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export const mapProps = ({ data: { refetch, loading, overlay } }) => {
  const visible = overlay && overlay.popup !== null
  let overlayReturnObject = null
  if(overlay) {
    overlayReturnObject = { ...overlay.popup }
  }
  return { refetch, loading, visible, overlayReturnObject }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  }),
  graphql(UPDATE_POPUP, {
    props: ({ mutate }) => ({
      updatePopup: (popup, isOpen) => mutate({ variables: { popup, isOpen } })
    })
  })
)

export default withData(Popup)
