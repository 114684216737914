import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Field, Formik, Form } from 'formik'
import { compose } from 'lodash/fp'
import { T } from 'core'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../helper/withQuery'
import SelectField from 'formfields/SelectField'
import { countries as countryConstants, countryToLocale } from 'helper/constants'
import _ from 'lodash'
class DisclaimerPage extends Component {
  state = {
    contents: '<div />'
  }

  componentDidMount() {
    this.fetchContents(this.props.legalCountry)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.legalCountry !== this.props.legalCountry) {
      this.fetchContents(nextProps.legalCountry)
    }
  }

  fetchContents(countryCode) {
    countryCode = countryCode.toLowerCase()
    if (countryCode !== this.props.queryCountry) {
      this.props.navigate({
        pathname: '/disclaimer',
        search: `?country=${countryCode}`
      })
    }

    this.setState(
      {
        contents: '<div />' //reset contents before fetching new
      },
      () => {
        const locale = countryToLocale[countryCode.toUpperCase()] || 'en'
        fetch(`/assets/legal/disclaimer/${locale}.html`).then(response => {
          if (response.status === 404) {
            return this.props.navigate(-1)
          }
          response.text().then(data => {
            this.setState({
              contents: data
            })
          })
        })
      }
    )
  }

  onCountryChange = value => {
    const { pathname } = this.props.location
    this.props.navigate({
      pathname: pathname,
      search: `?country=${value}`
    })
  }

  getSortedCountries = () => {
    const { countries, intl: { formatMessage } } = this.props
    const unsortedCountries = countries && countries.map(countryCode => (
      {
        countryCode,
        countryName: formatMessage(T('countryName' + countryCode))
      }
    ))
    const sortedCountries = _.orderBy(unsortedCountries, [country => country.countryName.toLowerCase()], ['asc'])
    return sortedCountries
  }

  render() {
    const { intl: { formatMessage }, legalCountry = '' } = this.props
    const sortedCountries = this.getSortedCountries()
    return (
      <div className="container">
        <Formik initialValues={{ legalCountry: legalCountry.toLowerCase() }}>
          <Form>
            <Field
              name="legalCountry"
              component={SelectField}
              label={formatMessage(T('common.country'))}
              labelClassName="required"
              onSelect={this.onCountryChange}
              options={sortedCountries.map(country => ({
                value: country.countryCode.toLowerCase(),
                label: country.countryName
              }))}
            />
          </Form>
        </Formik>
        <div
          style={{ marginTop: '20px' }}
          dangerouslySetInnerHTML={{ __html: this.state.contents }}
        />
      </div>
    )
  }
}

const mapProps = ({ data: { loading, Countries = [] }, ownProps: { location, intl } }) => {
  const countries = Countries.filter(item => item.enableHcpRegistration)
    .map(item => item.countryCode)
    .filter(countryCode => !!countryConstants[countryCode])

  return {
    loading,
    countries,
    legalCountry: location.query.country.toUpperCase(),
    queryCountry: location.query.country,
    intl
  }
}

const QUERY_DISCLAIMER_PAGE = gql`
  query {
    Countries {
      id
      countryCode
      enableHcpRegistration
      zipCodeValidation
    }
  }
`

const options = ({ location }) => ({
  notifyOnNetworkStatusChange: true,
  variables: { country: location.query.country }
})

const withData = compose(
  graphql(QUERY_DISCLAIMER_PAGE, {
    props: mapProps,
    options
  }),
  injectIntl
)

export default withRouter(withQuery(withData(DisclaimerPage)))
