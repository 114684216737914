import styled, { css } from "styled-components";
import { Button as BootstrapButton } from 'react-bootstrap'

const buttonPrimary = (props) => {
  const {
    theme: {
      colors: {
        button: {
          primary: {
            active: primaryButtonBackgroundActive,
            disabled: primaryButtonBackgroundDisabled,
            hover: primaryButtonBackgroundHover,
            color: primaryButtonFontColor
          },
        },
      },
    },
  } = props;

  const backgroundColor = props.disabled ? primaryButtonBackgroundDisabled : primaryButtonBackgroundActive

  return css`
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};
    color: ${primaryButtonFontColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${backgroundColor};
      background-color: ${backgroundColor};
    }

    &:hover {
      background-color: ${primaryButtonBackgroundHover};
      border-color: ${primaryButtonBackgroundHover};
    }

    &:active:hover {
      background-color: ${primaryButtonBackgroundHover};
      border-color: ${backgroundColor};
    }
  `;
};

const buttonSecondary = (props) => {
  const {
    theme: {
      colors: {
        button: {
          secondary: {
            active: secondaryButtonBackgroundActive,
            disabled: secondaryButtonBackgroundDisabled,
            hover: secondaryButtonBackgroundHover,
          },
        },
      },
    },
  } = props;

  const backgroundColor = props.disabled ? secondaryButtonBackgroundDisabled : secondaryButtonBackgroundActive

  return css`
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${backgroundColor};
      background-color: ${backgroundColor};
    }

    &:hover {
      background-color: ${secondaryButtonBackgroundHover};
      border-color: ${secondaryButtonBackgroundHover};
    }

    &:active:hover {
      background-color: ${secondaryButtonBackgroundHover};
      border-color: ${backgroundColor};
    }
  `;
};

const buttonTertiary = (props) => {
  const {
    theme: {
      colors: {
        button: {
          tertiary: {
            active: tertiaryButtonBackgroundActive,
            disabled: tertiaryButtonBackgroundDisabled,
            hover: tertiaryButtonBackgroundHover,
          },
        },
      },
    },
  } = props;

  const backgroundColor = props.disabled ? tertiaryButtonBackgroundDisabled : tertiaryButtonBackgroundActive

  return css`
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${backgroundColor};
      background-color: ${backgroundColor};
    }

    &:hover {
      background-color: ${tertiaryButtonBackgroundHover};
      border-color: ${tertiaryButtonBackgroundHover};
    }

    &:active:hover {
      background-color: ${tertiaryButtonBackgroundHover};
      border-color: ${backgroundColor};
    }
  `;
};

export const Button = styled.button`
  color: white;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "not-allowed !important" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  line-height: 1.4;
  user-select: none;
  font-weight: bold;
  border-radius: 20px;
  font-size: 16px;
  box-shadow: none;
  border: 1px solid;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

  && {
    outline: none;
  }

  &:nth-of-type(n + 2) {
    margin-left: 40px;
  }

  ${(props) => props.primary && buttonPrimary(props)};
  ${(props) => props.secondary && buttonSecondary(props)};
  ${(props) => props.tertiary && buttonTertiary(props)};

  > i {
    padding-right: 5px;
  }

  &.action-button {
    margin-left: 5px;
    margin-right: 5px;
  }

  &.btn--min-width {
    min-width: 150px;
  }
`;


export const LinkButton = styled.button((props) => {
  const {
    theme: {
      colors: {
        linkButton: {
          active: linkButtonBackgroundActive,
          hover: linkButtonBackgroundHover,
        },
      },
    },
  } = props;
  return css`
    color: ${linkButtonBackgroundActive};
    border-radius: 0;
    border-color: transparent;
    background-color: transparent;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: ${linkButtonBackgroundHover};
      cursor: pointer;
      text-decoration: none;
      outline: none;
    }

    &.overview {
      line-height: 32px;
      margin: 0;
      padding: 0;
      text-decoration: none;
      outline: none;
    }

    &.helpLink {
      font-weight: bold;
      text-decoration: ${props => (props.disabled ? 'none' : 'underline')};
      :hover {
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
      }
    }
  `;
});

export const RecallButton = styled.button`
  background-color: #3399ff;
  color: white;

  > i {
    margin-right: 5px;
  }

  &:hover {
    color: white;
    background-color: #517b21;
  }
`;

export const AcceptButtonPrefitingPatient = styled(Button)`
  width: 20%;
`;

export const AcceptButtonMarketingMessage = styled(Button)`
  margin: 30px auto;
  float: right;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 10%;
  top: 0;
  width: 200px;
`;

export const PresetButton = styled.button`
  text-align: left;
  border-radius: 0px;
  border: 1px solid black;
  width: 300px;
  background-color: ${(props) => (props.deprecated ? "palevioletred" : "grey")};
  margin-left: -10px;
  padding: 10px;
  display: block;
  color: white;

  &:hover {
    background-color: #222222;
  }

  &::after {
    content: ${(props) => (props.deprecated ? '"*deprecated"' : '"" ')};
  }
`;

export const StyledBootstrapButton = styled(BootstrapButton)((props) => {
  return css`
      color: white;
      ${props.primary && buttonPrimary(props)};
      ${props.secondary && buttonSecondary(props)};
      ${props.tertiary && buttonTertiary(props)};
    `;
});