import { EventEmitter, tracker } from 'core'
import liveswitch from 'fm.liveswitch'
import { getSupportId } from 'helper/ApplicationInsightSupportId'
import { CallTypeConstants, DataCollectionType, LiveswitchLib } from 'helper/constants'
import { CorrelationContext, videoCallStatus } from 'helper/constants'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { callData } from 'helper/DataCollection/PayloadData'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import connector from 'helper/HttpConnector'
import { saveCallState } from 'helper/VideoCallUtil'
import _ from 'lodash'
import moment from 'moment'
import { ILiveswitchConfig, ILiveSwitchTokenRequest, IState } from 'typescript/interfaces/IAudioVIdeoCallManager'
import { v4 as uuidv4 } from 'uuid'
import { CallConstants, CALL_STATES } from '../callStates'

export default class LiveswitchVideoCallManager extends EventEmitter {

  timeoutId = null
  callTimeout = 60 * 1000 // 60 sec
  public client: liveswitch.Client | null = null;
  public remoteClient: liveswitch.Client | null = null;
  public channels: liveswitch.Channel[] | null = null;
  public channel: liveswitch.Channel | null = null;
  public localMedia: liveswitch.LocalMedia | null = null;
  public remoteMedia: liveswitch.RemoteMedia | null = null;
  public userId: string | null = null;

  private reRegisterBackoff = 200;
  private readonly maxRegisterBackoff = 60000;
  private unregistering = false;

  config: ILiveswitchConfig = {
    applicationId: null,
    gatewayUrl: null,
    name: 'george',
    sessionId: '654321',
  }

  state: IState = {
    inited: false,
    callType: 'VIDEO',
    callState: CALL_STATES.CLOSED,
    error: false,
    videoMuted: false,
    audioMuted: false,
    remoteAudioMuted: false,
    remoteVideoMuted: false,
    remoteApplyingSettings: false,
    callStartTime: null,
    remoteCallState: '',
    supportId: getSupportId(),
  }

  setState(nstate: Object, callback?: Function) {
    this.state = {
      ...this.state,
      ...nstate
    }
    this.emit('change', this.state, nstate)
  }

  resetState() {
    this.setState({
      callType: 'VIDEO',
      callState: CALL_STATES.CLOSED,
      error: false,
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      callStartTime: null,
      remoteApplyingSettings: false,
      remoteCallState: ''
    })
  }

  constructor() {
    super()
  }

  init() {
    const { inited } = this.state
    if (inited) {
      return
    } else {
      liveswitch.Log.registerProvider(new liveswitch.ConsoleLogProvider(liveswitch.LogLevel.Info));
      this.setState({ inited: true })
    }
  }

  async getConfig() {
    const callEndpoint = `api/video-call/liveswitch-information`
    const correlationContextType = this.state.callType === videoCallStatus.audio ? 'AudioVideoCall_AUDIO' : 'AudioVideoCall_VIDEO'
    const response = await connector.getWithCorrelation(this.token, callEndpoint, CorrelationContext[correlationContextType] + this.state.supportId)
    const connectionDetails = {
      ...response,
      sessionId: uuidv4()
    }
    this.config = {
      ...connectionDetails, name: 'george'
    }
    return connectionDetails
  }

  async getLiveSwitchToken(): Promise<string> {
    const url = `api/video-call/liveswitch-generate-token`
    const correlationContextType = this.state.callType === videoCallStatus.audio ? 'AudioVideoCall_AUDIO' : 'AudioVideoCall_VIDEO'

    const tokenRequest: ILiveSwitchTokenRequest = {
      channelId: this.config.sessionId,
      clientId: this.client.getId(),
      deviceId: this.client.getDeviceId(),
      userId: this.client.getUserId()
    }

    const response = await connector.postWithCorrelation(this.token, url, CorrelationContext[correlationContextType] + this.state.supportId, tokenRequest);
    return response.registerToken;
  }

  async startVideoCall({ customer = null, token = null, me = null } = {}) {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, supportId: getSupportId() })

      if (customer) this.customer = customer
      if (token) this.token = token
      if (me) this.me = me

      const connectionDetails = await this.getConfig();
      await this.startCallAndSetLocalMedia(true, false, CallTypeConstants.video, connectionDetails.sessionId);
    } catch (error) {
      this.trackError(error)
      this.setState({ callState: CALL_STATES.ERROR, error: error })
      return error
    }
  }

  async recallVideo() {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, supportId: getSupportId() })
      const connectionDetails = await this.getConfig()
      this.setState({
        showCallEnded: false
      })

      return await this.startCallAndSetLocalMedia(true, false, CallTypeConstants.video, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async startAudioOnly({ customer = null, token = null, me = null } = {}) {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, callType: 'AUDIO', supportId: getSupportId() })
      if (customer) this.customer = customer
      if (token) this.token = token
      if (me) this.me = me

      const connectionDetails = await this.getConfig()

      return await this.startCallAndSetLocalMedia(false, true, CallTypeConstants.audio, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async recallAudio() {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, callType: 'AUDIO', supportId: getSupportId() })
      const connectionDetails = await this.getConfig()
      this.setState({
        showCallEnded: false
      })

      return await this.startCallAndSetLocalMedia(false, true, CallTypeConstants.audio, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  startCallAndSetLocalMedia = async (isVideo: boolean, isAudioOnly: boolean, callType: string, sessionId: string) => {
    this.track('PortalCallInit', {
      sessionId: sessionId,
      callType: callType
    });

    // startLocalMedia
    await this.startLocalMedia({ video: isVideo, audio: true });
    // Create and register the client.
    await this.joinAsync();
    this.setState({ callState: CALL_STATES.JOINED })

    await this.sendInvite(this.customer, this.token, { audioOnly: isAudioOnly });
    return true
  }

  public async joinAsync() {
    // Create a client.
    this.userId = `TC_Portal_User_${this.config.sessionId}`;
    this.client = new liveswitch.Client(this.config.gatewayUrl, this.config.applicationId,
      this.userId, `TC_Portal_${this.config.sessionId}`);

    //Get liveswitch token
    const liveswitchToken = await this.getLiveSwitchToken();

    try {
      this.channels = await this.client.register(liveswitchToken);

      this.setState({ callState: CALL_STATES.CONNECTED })
      this.onClientRegistered(this.channels);
    } catch (error) {
      liveswitch.Log.error("Failed to register with Gateway.");
      this.setState({ callState: CALL_STATES.ERROR, error: error })
      this.client.unregister()
      this.stopLocalMedia()
      this.leave('Client Failed To Connect', true)
    }
  }

  public async startLocalMedia(options: any): Promise<liveswitch.LocalMedia> {
    if (this.localMedia != null) {
      await this.stopLocalMedia();
    }
    // Create local media with audio and video enabled.
    const audioEnabled = true;
    const videoEnabled = options.video ? new liveswitch.VideoConfig(640, 480, 30) : false
    this.localMedia = new liveswitch.LocalMedia(audioEnabled, videoEnabled, false);

    // Start capturing local media.
    try {
      await this.localMedia.start();
      liveswitch.Log.debug("Media capture started.");
      return this.localMedia;
    } catch (ex) {
      liveswitch.Log.error(ex.toString());
    }
  }

  public async stopLocalMedia() {
    if (this.localMedia == null) {
      return null
    }
    // Stop capturing local media.
    try {
      await this.localMedia.stop();
      if (this.localMedia != null) {
        this.localMedia = null
      }
      liveswitch.Log.info("Media capture stopped.");
      return null;
    } catch (ex) {
      this.trackError(ex)
      liveswitch.Log.info('stop media failed');
      liveswitch.Log.error(ex.toString());
    }
  }

  private async onClientRegistered(channels: liveswitch.Channel[]): Promise<void> {
    this.channel = channels[0];

    if (!_.isEmpty(this.state.callType)) {
      await sendDataToDataCollection(DataCollectionType.PatientData, callData(this.customer, {
        callType: this.state.callType,
        status: 'Started',
        lib: LiveswitchLib
      }), EventType.Call)
    }

    // gets called when a channel-scope message is received from the server
    this.channel.addOnMessage((clientInfo, messageObj) => {
      this.handleMessage(clientInfo, messageObj);
    });

    this.channel.addOnRemoteUpstreamConnectionOpen(async remoteConnectionInfo => {
      liveswitch.Log.info("An upstream connection opened.");
      clearTimeout(this.timeoutId)
      await this.openSfuDownstreamConnection(remoteConnectionInfo);
    });

    this.upstreamConnection = await this.openSfuUpstreamConnection(this.localMedia);
    this.setState({ callState: CALL_STATES.JOINED })
    this.timeoutId = setTimeout(this.onTimeout, this.callTimeout)

    // in case multiple downstreams
    for (let remoteConnectionInfo of this.channel.getRemoteUpstreamConnectionInfos()) {
      await this.openSfuDownstreamConnection(remoteConnectionInfo);
    }
  }

  private upstreamConnection: liveswitch.SfuUpstreamConnection;

  private async openSfuUpstreamConnection(localMedia: liveswitch.LocalMedia): Promise<liveswitch.SfuUpstreamConnection | null> {
    // Create audio and video streams from local media.
    const audioStream = new liveswitch.AudioStream(localMedia);
    let videoStream: liveswitch.VideoStream | null = null;
    if (this.state.callType != 'AUDIO') {
      videoStream = new liveswitch.VideoStream(localMedia);
    }

    // Create a SFU upstream connection with local audio and video.
    const connection: liveswitch.SfuUpstreamConnection = this.state.callType != 'AUDIO' ?
      this.channel.createSfuUpstreamConnection(audioStream, videoStream) :
      this.channel.createSfuUpstreamConnection(audioStream);

    connection.addOnStateChange(conn => {
      liveswitch.Log.info(`Upstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`);
      if (conn.getState() === liveswitch.ConnectionState.Closing || conn.getState() === liveswitch.ConnectionState.Failing) {
        if (conn.getRemoteClosed()) {
          liveswitch.Log.info(`Upstream connection ${conn.getId()} was closed`);
        }
      } else if (conn.getState() === liveswitch.ConnectionState.Failed) {
        this.openSfuUpstreamConnection(localMedia);
      }
    });

    await connection.open();
    return connection;
  }

  private downstreamConnections: { [key: string]: liveswitch.SfuDownstreamConnection } = {};

  private async openSfuDownstreamConnection(remoteConnectionInfo: liveswitch.ConnectionInfo): Promise<liveswitch.SfuDownstreamConnection | null> {
    // Create remote media.
    const remoteMedia = new liveswitch.RemoteMedia();
    this.remoteMedia = remoteMedia;

    // If the other user has audio, lets create a audio stream for it.
    const audioStream: liveswitch.AudioStream | null = (remoteConnectionInfo.getHasAudio()) ? new liveswitch.AudioStream(remoteMedia) : null;
    // If the other user has video, lets create a video stream for it.
    let videoStream: liveswitch.VideoStream | null = null;
    if (this.state.callType != 'AUDIO') {
      videoStream = (remoteConnectionInfo.getHasVideo()) ? new liveswitch.VideoStream(remoteMedia) : null;
    }

    // Create a SFU downstream connection with remote audio and video.
    const connection: liveswitch.SfuDownstreamConnection = this.state.callType != 'AUDIO' ?
      this.channel.createSfuDownstreamConnection(remoteConnectionInfo, audioStream, videoStream) :
      this.channel.createSfuDownstreamConnection(remoteConnectionInfo, audioStream);

    // Store the downstream connection.
    this.downstreamConnections[connection.getId()] = connection;

    connection.addOnStateChange(async conn => {
      liveswitch.Log.info(`Downstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`);
      // Remove the remote media from the layout and destroy it if the remote is closed.
      if (conn.getRemoteClosed()) {
        this.downstreamConnections[connection.getId()]?.close();
        delete this.downstreamConnections[connection.getId()];
        await this.stopLocalMedia();
        await this.leave('client dropped', true)
        remoteMedia.destroy();
        this.remoteMedia.destroy();
      }

      // handle scenarios
      switch (conn.getState()) {
        case liveswitch.ConnectionState.Connected:
          this.track('PortalCallStarted', {
            callType: this.state.callType
          })
          this.setState({
            callState: CALL_STATES.REMOTE_JOINED,
            callStartTime: moment.now()
          })
          break;
        case liveswitch.ConnectionState.Failing:
          this.setState({
            callState: CALL_STATES.ERROR,
            error: {
              type: 'connection_lost',
              message: 'Connection lost'
            }
          })
          await conn.close();
          this.stopLocalMedia();
          this.leave('server failed', true);
          this.track('PortalCallEnded', {
            callType: this.state.callType,
            type: 'error'
          });
          break;
        case liveswitch.ConnectionState.Failed:
          this.setState({
            callState: CALL_STATES.ERROR,
            error: {
              type: 'stream_failure',
              message: 'Stream Failure'
            }
          })
          //try reconnection??
          break;
      }
    });

    await connection.open();
    liveswitch.Log.info('connection done')
    return connection;
  }

  private handleMessage(clientInfo: liveswitch.ClientInfo, messageObj: string): void {
    if (this.wasMessageSentFromPortal(clientInfo)) {
      return;
    }
    let messageData = JSON.parse(messageObj)
    let { audioMuted, videoMuted, applySettings, callState, key, value } = messageData

    if (key !== undefined && value !== undefined) {
      switch (key) {
        case CallConstants.CALL_AUDIO_MUTED: audioMuted = this.convertBoolIfString(value); break;
        case CallConstants.CALL_VIDEO_MUTED: videoMuted = this.convertBoolIfString(value); break;
        case CallConstants.CALL_APPLY_SETTINGS: applySettings = this.convertBoolIfString(value); break;
        case CallConstants.CALL_STATE: callState = value; break;
      }
    }

    if (audioMuted !== undefined) {
      this.setState({
        remoteAudioMuted: audioMuted
      })
    }

    if (videoMuted !== undefined) {
      this.setState({
        remoteVideoMuted: videoMuted
      })
    }

    if (applySettings !== undefined) {
      this.setState({
        remoteApplyingSettings: applySettings
      })
    }

    if (callState !== undefined) {
      this.setState({
        remoteCallState: callState
      })
      switch (callState?.toLowerCase()) {
        case CallConstants.CALL_JOIN:
          this.track('PortalCallInitConnection', {
            callType: this.state.callType
          })
          this.setState({
            callState: CALL_STATES.REMOTE_JOINED,
            callStartTime: moment.now()
          })
          break
        case CallConstants.CALL_LEAVE:
          this.track('PortalCallEnded', {
            callType: this.state.callType,
            type: 'remote'
          })
          this.leave(CallConstants.CALL_ENDED, true)
          break
        case CallConstants.CALL_DECLINED:
          this.leave(CallConstants.CALL_ABORTED, true)
          break
        case CallConstants.CALL_TIMEOUT:
          this.setState({ callState: CALL_STATES.TIMED_OUT })
          break
      }
    }
  }

  public async leave(callStatus: string, showCallEnded: boolean = false) {
    // call status will be used for data collection 
    // Disable re-register. this.unregistering = true;

    try {
      const callDuration = this.state.callStartTime != null ? moment.now() - this.state.callStartTime : 0
      clearTimeout(this.timeoutId)
      await this.leaveChannel();
      this.saveCallState();
      await this.stopLocalMedia();
      this.setState({
        callState: CALL_STATES.CLOSED,
        showCallEnded
      });
      sendDataToDataCollection(DataCollectionType.PatientData, callData(this.customer, {
        callType: this.state.callType,
        status: callStatus,
        duration: callDuration,
        lib: LiveswitchLib,
      }), EventType.Call)
    } catch (error) {
      this.saveCallState();;
      this.trackError(error);
      this.setState({
        callState: CALL_STATES.ERROR,
        error: error
      });
      liveswitch.Log.error("Unregistration failed.")
    }
  }

  public async leaveChannel() {
    try {
      this.sendMessage(this.config.sessionId, CallConstants.CALL_STATE, CallConstants.CALL_LEAVE);
      await this.client.leave(this.config.sessionId);
      await this.channel?.closeAll();
      await this.client.unregister();
    } catch (error) {
      liveswitch.Log.error("failed to leave the channel");
      this.trackError(error)
    }
  }

  public sendMessage = (channelId: string, key: string, value: string) => {
    const leaveMessage = JSON.stringify({
      "sessionId": channelId,
      "key": key,
      "value": value
    });
    this.channel?.sendMessage(leaveMessage);
  };

  public saveCallState = async () => {
    const status = this.checkCallState()
    await saveCallState(this.state.callType, this.customer, this.me.employee, status, this.state.supportId);
  }

  public async toggleAudioMute() {
    const config: liveswitch.ConnectionConfig = this.upstreamConnection.getConfig()
    config.setLocalAudioMuted(!config.getLocalAudioMuted())
    await this.upstreamConnection.update(config)

    this.setState({
      audioMuted: config.getLocalAudioMuted()
    })
  }

  public async toggleVideoMute() {
    const config: liveswitch.ConnectionConfig = this.upstreamConnection.getConfig()
    config.setLocalVideoMuted(!config.getLocalVideoMuted())
    await this.upstreamConnection.update(config)

    this.setState({
      videoMuted: config.getLocalVideoMuted()
    })
  }

  public isLocalAudioMuted(): boolean {
    if (!this.localMedia) {
      return true;
    }
    return this.localMedia?.getAudioMuted();
  }

  public isLocalVideoMuted(): boolean {
    if (!this.localMedia) {
      return true;
    }
    return this.localMedia?.getVideoMuted();
  }

  private checkCallState = () => {
    if (this.state.error || this.state.callState === (CALL_STATES.TIMED_OUT || CALL_STATES.STREAM_FAILURE || CALL_STATES.ERROR))
      return videoCallStatus.failureStatus
    return videoCallStatus.successStatus
  }

  private wasMessageSentFromPortal(clientInfo: liveswitch.ClientInfo): boolean {
    return clientInfo.getUserId() === this.userId
  }

  private convertBoolIfString = (value: string) => {
    if (value?.toLowerCase() === "true") return true;
    else if (value?.toLowerCase() === "false") return false;
    return value
  }

  async sendInvite(customer: any, token: any, options = { audioOnly: false }) {
    let callEndpoint = '';
    let correlationContextType = 'AudioVideoCall_VIDEO'
    const data = { callType: 'VIDEO' }
    data['patientId'] = customer.uuid
    callEndpoint = `/api/video-call`

    if (options.audioOnly) {
      data['callType'] = 'AUDIO'
      correlationContextType = 'AudioVideoCall_AUDIO'
    }

    this.setState({
      callType: data.callType
    })

    if (this.config.sessionId) {
      data['sessionId'] = this.config.sessionId
    }

    try {
      await connector.postWithCorrelation(token, callEndpoint, CorrelationContext[correlationContextType] + this.state.supportId, data)
      return true
    } catch (e) {
      this.trackError(e)
      return e
    }
  }

  isActive() {
    const { callState } = this.state
    return callState !== CALL_STATES.CLOSED
  }

  private onTimeout = async (): Promise<void> => {
    this.sendMessage(this.config.sessionId, CallConstants.CALL_STATE, CallConstants.CALL_TIMEOUT);
    await this.stopLocalMedia()
    this.setState({ callState: CALL_STATES.TIMED_OUT })
  }

  trackError(errorObj: any) {
    console.log('TRACK Error', errorObj)
    if (errorObj && errorObj.getException) {
      errorObj = errorObj.getException()
    }
    this.track('PortalCallError', {
      error: errorObj.message || errorObj.name || 'unknown'
    })
  }

  track(stateName: any, attributes: any) {
    console.log('tracking', stateName, attributes)
    const me = this.me || {}
    const customer = this.customer || {}
    const config = this.config
    tracker.track(stateName, {
      organizationId: me.organizationId,
      employeeId: me.employee.id,
      customerId: customer.id,
      sessionId: config.sessionId,
      ...attributes
    })
  }
}

