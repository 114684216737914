export const EventType = {
    PasswordPolicy: 'PasswordPolicy',
    AutoReplySetting: 'AutoReplySetting',
    BrandSelection: 'BrandSelection',
    Communication: 'Communication',
    LanguageChange: 'LanguageChange',
    LogOut: 'LogOut',
    Create: 'Create',
    Read: 'Read',
    Update: 'Update',
    Delete: 'Delete',
    Navigation: 'Navigation',
    MarketingMessage: 'MarketingMessage',
    RemoteTuning: 'RemoteTuning',
    SatisfactionIconClick: 'SatisfactionIconClick',
    FinishTrial: 'FinishTrial',
    SendWebCodeSms: 'SendWebCodeSms',
    Call: 'Call',
    HearingAidsChange: 'HearingAidsChange',
    HearingLessonsChange: 'HearingLessonsChange',
    BulkPatientMove: 'BulkPatientMove',
    Platform: 'Platform',
    NotificationLogin: 'Notification-OnLogin',
    NotificationClearAll: 'Notification-ClearAll',
    NotificationDelete: 'Notification-Delete',
    NotificationOpen: 'Notification-Open',
}

export const EntityCrudType = {
    Practice: 'Practice',
    Employee: 'Employee',
    LessonTemplate: 'LessonTemplate',
    Organization: 'Organization',
    Patient: 'Patient'
}