import { graphql } from '@apollo/client/react/hoc';
import React from 'react'
import { PortalWithState } from 'react-portal'
import { UPDATE_MODAL, UPDATE_OVERLAY } from 'graphql/queries/ShowPopUpQuery'
import { OPEN_MODAL, getOverlayUpdateData } from 'components/Popups/helper/OverlayUpdateData'
import { compose } from 'lodash/fp'

const OverlayWrap = ({ children, openPortal, closePortal }) =>
  <div>
    <div className="popup" onClick={closePortal} />
    <div className="popup-inner">
      {React.cloneElement(children, { closePortal, openPortal })}
    </div>
  </div>

export class Modal extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpened = false } = nextProps
    if (isOpened === true && this.props.isOpened === false) {
      const popup = getOverlayUpdateData(OPEN_MODAL)
      this.props.updateModal({ ...popup })
    }
  }
  componentWillUnmount() {
    this.props.onClose()
  }
    
  render() {
    const { openByClickOn, onOpen, onClose, children } = this.props       
    return (
      <PortalWithState closeOnOutsideClick closeOnEsc onOpen={onOpen} onClose={onClose}>
        {({ openPortal, closePortal, portal }) => {          
          return (
          <React.Fragment>
            {openByClickOn(openPortal)}   {/* creates a button that opens the portal (=the popup) */}
            {portal(       // actual popup
              <OverlayWrap closePortal={closePortal} openPortal={openPortal}>
                {children}                
              </OverlayWrap>
            )}
          </React.Fragment>
          )}
        }
      </PortalWithState>
    )
  }
}

const withData = compose(
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  graphql(UPDATE_MODAL, {
    props: ({ mutate }) => ({
      updateModal: (popup, isOpen) => mutate({ variables: { popup, isOpen } })
    })
  })
)

export default withData(Modal)
