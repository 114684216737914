import React, { Component } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { isNil, map } from 'lodash'

export const LoadNewScript = ({ url, attributes = {} }) => {
  const scriptsInDocument = map(document.querySelectorAll('script'), 'src')
  return new Promise((resolve, reject) => {
    if (scriptsInDocument.indexOf(url) > -1) {
      return
    }
    const script = document.createElement('script')
    if (attributes) {
      map(attributes, (value, attr) => script.setAttribute(attr, value))
    }
    //script.setAttribute("type", "text/jsx");
    script.setAttribute("type", "text/javascript");
    // load the script async if not specified otherwise
    if (isNil(attributes.async)) {
      script.setAttribute('async', true)
    }
    script.src = url
    script.onload = () => resolve(url)
    script.onerror = e => reject(e)
    document.head.appendChild(script)
  })
}

export const loadScripts = async scripts => {
  const myScripts = scripts.slice(0)
  const script = myScripts.shift()
  const { url, attributes } = script
  if (isNil(url)) return

  try {
    await LoadNewScript({
      url,
      attributes
    })
    await loadScripts(myScripts)
  } catch (e) {
    return e
  }
}

const LoadScripts = scripts => WrappedComponent => {
  class LoadScriptComponent extends Component {
    static displayName = 'LoadScripts'

    constructor(props, context) {
      super(props, context)
      this.state = {
        areScriptsLoadedSuccessfully: false,
        scriptLoadError: false
      }
    }

    attachScripts() {
      loadScripts(scripts)
        .then(() => {
          this.setState({
            areScriptsLoadedSuccessfully: true
          })
        })
        .catch(error => {
          this.setState({
            areScriptsLoadedSuccessfully: false,
            scriptLoadError: error
          })
        })
    }

    componentDidMount() {
      this.attachScripts()
    }

    render() {
      const passedProps = { ...this.props, ...this.state }
      return <WrappedComponent {...passedProps} />
    }
  }

  // used to facilitate the composition. LoadScripts HOC does not have static methods of WrappedComponent
  // https://facebook.github.io/react/docs/higher-order-components.html#static-methods-must-be-copied-over

  return hoistNonReactStatics(LoadScriptComponent, WrappedComponent)
}

export default LoadScripts
