import styled from 'styled-components'

export const Select = styled.select`
  height: 34px;
  font-size: 14px;
  color: ${props => props.theme.colors.gray};
  width: 100%;
  border: none;
  background: transparent;
  appearance: none;
  outline: none;
`