import React from 'react'
import { injectIntl } from 'react-intl'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage } from 'react-intl'
import PasswordComponent from 'formfields/Password'
import { T } from 'core'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../helper/withQuery'
import { Link } from 'react-router-dom'
import Loading from 'components/Loading'
import { resetPassword } from 'actions/authentication/Password'
import { get } from 'lodash'
import { Button } from 'styledComponents/Button'
import { shopTracking } from 'helper/shopTracking'

export class PasswordReset extends React.Component {
  state = {
    isLoading: false,
    resetLinkExpired: false,
    hasError: false
  }

  resetPassword = password => {
    const resetToken = get(this.props, 'query.key', '')
    this.setState({
      isLoading: true
    })
    return resetPassword({ password, resetToken })
      .then(() => {
        shopTracking({
          ...this.props.location,
          action: 'employeeResetPassword'
        })
        this.props.navigate('/')
      })
      .catch(error => {
        if (error.status === 403 && error.code === 'INVALID_OR_EXPIRED_PASSWORD_TOKEN') {
          this.setState({
            isLoading: false,
            resetLinkExpired: true
          })
        } else {
          this.setState({
            isLoading: false,
            hasError: true
          })
        }
      })
  }

  renderLinkExpired() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 offset-2">
            <div className="alert alert-danger">
              <FormattedMessage {...T('reset.linkExpired')} />
              <p>&nbsp;</p>
              <Link to="/password/request-reset" data-qa="forgot-password-link">
                <FormattedMessage {...T('reset.requestNewLink')} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  formatMessage = keyID => {
    return this.props.intl.formatMessage(T(keyID))
  }

  passwordResetValidationSchema = () =>
    Yup.object().shape({
      password: Yup.object().shape({
        passwordEnter: Yup.string()
          .isPasswordValid()
          .min(6, this.formatMessage('min.password'))
          .required(this.formatMessage('validator.required')),
        passwordReEnter: Yup.string()
          .required(this.formatMessage('validator.required'))
          .test('passwords-match', this.formatMessage('validator.pinNotEqual'), function (value) {
            return this.parent.passwordEnter == value
          })
      })
    })

  render() {
    const { resetLinkExpired, isLoading, hasError } = this.state
    const password = { passwordEnter: '', passwordReEnter: '' }
    return (
      <div>
        <Loading isLoading={isLoading} />
        {resetLinkExpired
          ? this.renderLinkExpired()
          : <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1">
                <Formik
                  onSubmit={(values, props) =>
                    this.resetPassword(values.password.passwordEnter, props.setFieldError)}
                  validationSchema={() => this.passwordResetValidationSchema()}
                  initialValues={{ password }}
                >
                  <Form>
                    <h2 data-qa="title">
                      <FormattedMessage {...T('reset.title')} />
                    </h2>
                    {!!hasError &&
                      <div className="alert alert-danger">
                        <FormattedMessage {...T('reset.failed')} />
                      </div>}
                    <Field name="password" component={PasswordComponent} required />
                    <div className="row">
                      <div className="col-sm-6 offset-sm-3">
                        <Button
                          primary
                          className="btn-block btn--min-width"
                          type="submit"
                          data-qa="update-button"
                        >
                          <FormattedMessage {...T('reset.update')} />
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

export default withRouter(withQuery(injectIntl(PasswordReset)))
