import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { get } from 'lodash'
import { getGuidForUserDataConsent } from 'helper/UserDataConsentUtils'
import { checkPermission } from 'helper/AuthHelper'
import Navigation from 'components/Navigation'
import { withRouter } from 'helper/withRouter'
import DataContext from "containers/DataContext"
import { OS, currentBrowser, Device } from 'helper/Platform.js';
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { PlatformPayloadData } from 'helper/DataCollection/PayloadData'
import { DataCollectionType } from 'helper/constants'
import { Outlet } from 'react-router-dom'

const PATIENTS_MENU_ITEM = {
  href: '/overview/patients',
  name: 'common.customers',
  dataqa: 'navigation-patients',
  featured: false
}

const ANALYTICS_MENU_ITEM = {
  href: '/overview/dashboard',
  name: 'Dashboard.analytics',
  dataqa: 'navigation-analytics',
  featured: false
}

const MARKETING_MESSAGE_MENU_ITEM = {
  href: '/overview/marketing/messages',
  name: 'ProfileNav.marketing.messages',
  dataqa: 'navigation-marketingMessages',
  featured: true
}

const MANAGEMENT_MENU_ITEM = {
  href: '/overview/orgManagement',
  name: 'common.manage',
  dataqa: 'navigation-management',
  featured: false
}

class Overview extends Component {
  static contextType = DataContext;

  componentDidMount() {
    const me = get(this.context, 'me');
    getGuidForUserDataConsent(me.employee.defaultPracticeId).then(x => {
      sendDataToDataCollection(DataCollectionType.PlatformData,
        PlatformPayloadData(OS(), currentBrowser(), Device()),
        EventType.Platform);
    })
  }

  render() {

    const { location } = this.props
    const Me = get(this.context, 'me', {});
    let menuItems = [PATIENTS_MENU_ITEM, MARKETING_MESSAGE_MENU_ITEM];
    if (checkPermission('ANALYTICS_READ', { Me }) === true) {
      menuItems = [...menuItems, ANALYTICS_MENU_ITEM]
    }
    menuItems = [...menuItems, MANAGEMENT_MENU_ITEM]

    return (
      <section>
        <div className="container overview">
          <h1 className="overview__page-title">
            <FormattedMessage {...T('navbar.overview')} />
          </h1>
        </div>
        <Navigation items={menuItems} location={location} />
        <article>
          <Outlet />
        </article>
      </section>
    )
  }
}

export default withRouter(Overview)
