import React from 'react'

class Form extends React.Component {
  static displayName = 'FormCreatePatient'

  static defaultProps = {
    onSubmit: null,
    children: null
  }

  onSubmit = e => {
    e.preventDefault()

    !!this.props.onSubmit && this.props.onSubmit(e)
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        {this.props.children}
      </form>
    )
  }
}

export default Form
