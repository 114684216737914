import PropTypes from 'prop-types'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const WarningComponent = ({ customer, warnings }) =>
  (warnings &&
    warnings.length &&
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id={`popover-${customer.id}`} className="customerOverview">
          {warnings}
        </Popover>
      }
    >
      <i className="fas fa-fw fa-exclamation-triangle text-primary" />
    </OverlayTrigger>) ||
  <div />

WarningComponent.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.object),
  customer: PropTypes.shape({})
}

export default WarningComponent
