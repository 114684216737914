
import { getAppVersion } from "./TokenUtil"
export const TRIAL_DURATIONS = [7, 5, 3]

export const STORAGE_KEY = `LIGHTCLOUD${getAppVersion()}`
export const SCRIPTS = {
  //https://my.small.chat/channel/-KuLNnElwWpIyCSTD7sa/embed
  supportChat: 'https://embed.small.chat/T79DV0UB1G795VNRT7.js'
}

export const AppInsightCloudRoleName = 'Telecare Portal'

export const weekdays = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]

export const publicPaths = [
  '/login',
  '/recent',
  '/register',
  '/register/success',
  '/register/test',
  '/password/request-reset',
  '/password/reset',
  '/password/expire',
  '/terms',
  '/privacy',
  '/disclaimer',
  '/imprint',
  '/devtools',
  '/help',
  '/help/faq',
  '/help/tutorials',
  '/help/faq/commonfaq',
  '/help/faq/orderedfaq',
  '/help/supportedDevices',
  '/employee/self-complete'
]

export const PasswordExpiryPath = '/password/expire'

export const MaintenancePublicPaths = ['/terms', '/privacy', '/disclaimer', '/imprint']

export const hearingPrograms = {
  Signia: [
    'UNIVERSAL',
    'NOISY_ENV',
    'TV',
    'REV_ROOM',
    'MUSIC_RECORD',
    'MUSIC_LIVE',
    'MUSIC_MUSICIAN',
    'PHONE_ACOUSTIC',
    'PHONE_MIDMIC_T',
    'PHONE_MIC_T',
    'PHONE_T',
    'PHONE_TWIN',
    'OUTDOOR_SPORTS',
    'STROLL',
    'PRIVACY',
    'TINNITUS',
    'INDUCTION_LOOP_MT',
    'INDUCTION_LOOP_T'
  ],

  Siemens: [
    'UNIVERSAL',
    'NOISY_ENV',
    'TV',
    'MUSIC',
    'PHONE_ACOUSTIC',
    'PHONE_MIDMIC_T',
    'PHONE_MIC_T',
    'PHONE_T',
    'OUTDOOR_SPORTS',
    'STROLL',
    'PRIVACY',
    'TINNITUS',
    'INDUCTION_LOOP_MT',
    'INDUCTION_LOOP_T'
  ],

  SpecSavers: [
    'REXTON_AUTOMATIC',
    'REXTON_NOISE_PARTY',
    'REXTON_TELEPHONE_ACOUSTIC_',
    'REXTON_TELECOIL_T',
    'REXTON_BLUETOOTH_PHONE_FOR_VOICE_ONLY',
    'REXTON_MUSIC',
    'REXTON_TELEVISION',
    'REXTON_OUTDOOR_TRAFFIC',
    'REXTON_TINNITUS_FUNCTION',
    'REXTON_EARPROTECTION',
    'REXTON_AUDIO_INPUT',
    'REXTON_FOCUS_360',
    'REXTON_STREAMING_AUDIO',
    'REXTON_SMART_CONNECT_FM',
    'REXTON_TELECOIL_MT',
    'REXTON_TELECOIL_MT_23',
    'REXTON_MUSIC_LISTENING_AT_A_LIVE_VENUE',
    'REXTON_MUSIC_PLAYING_AN_INSTRUMENT',
    'REXTON_MUSIC_PLUS_HD',
    'REXTON_MUSIC_LISTENING_WITH_SPEAKERS',
    'REXTON_XPHONE',
    'REXTON_ECHO_STOP'
  ]
}

export const countries = {
  DE: 'Deutschland',
  US: 'United States of America',
  FR: 'France',
  JP: '日本',
  GB: 'United Kingdom',
  AU: 'Australia',
  NL: 'Nederlands',
  NO: 'Norge',
  ZA: 'South Africa',
  SE: 'Sverige',
  AT: 'Österreich',
  CH: 'Schweiz',
  CA: 'Canada',
  SG: 'Singapore',
  NZ: 'New Zealand',
  NA: 'Namibia',
  CN: '中国',
  BR: 'Brasil',
  BS: 'Bahamas',
  DK: 'Danmark',
  IN: 'India',
  IT: 'Italia',
  TR: 'Türkiye',
  PL: 'Polska',
  PH: 'Philippines',
  KR: '한국',
  CZ: 'Česká republika',
  HU: 'Magyarország',
  FI: 'Finland',
  TH: 'เมืองไทย',
  TW: '中華民國',
  BE: 'Belgique',
  IE: 'Ireland',
  CO: 'Colombia',
  CR: 'Costa Rica',
  PE: 'Perú',
  ES: 'España',
  MY: 'Malaysia',
  IR: 'Iran',
  MM: 'Myanmar',
  MT: 'Malta',
  TZ: 'Tanzania',
  OM: 'Oman',
  AO: 'Angola',
  UY: 'Uruguay',
  TT: 'Trinidad And Tobago'
}

export function getLocales() {
  return {
    'cs-CZ': 'čeština (cs)',
    'da-DK': 'Dansk (ds)',
    'de-DE': 'Deutsch (de)',
    'en-US': 'English (en)',
    'es-ES': 'Español (es)',
    'fr-FR': 'Français (fr)',
    'it-IT': 'Italiano (it)',
    'hu-HU': 'Magyar (hu)',
    'nl-NL': 'Nederlands (nl)',
    'nb-NO': 'Norsk (nb)',
    'pl-PL': 'Polski (pl)',
    'pt-BR': 'Português (pt)',
    'ro-RO': 'Română (ro)',
    'sv-SE': 'Svenska (sv)',
    'fi-FI': 'suomi (fi)',
    'tr-TR': 'Türkçe (tr)',
    'ja-JP': '日本語 (ja)',
    'ko-KR': '한국어 (ko)',
    'th-TH': 'ภาษาไทย (th)',
    'zh-Hans': '中国 (zh-Hans)', // Chineese
    'zh-Hant': '繁體中文 (zh- Hant)' // Taiwan
  }
}

export const localeToCountry = {
  'cs-CZ': 'CZ',
  'da-DK': 'DK',
  'de-DE': 'DE',
  'en-US': 'US',
  'es-ES': 'ES',
  'fr-FR': 'FR',
  'it-IT': 'IT',
  'hu-HU': 'HU',
  'nl-NL': 'NL',
  'nb-NO': 'NO',
  'pl-PL': 'PL',
  'pt-BR': 'BR',
  'sv-SE': 'SE',
  'fi-FI': 'FI',
  'tr-TR': 'TR',
  'ja-JP': 'JP',
  'ko-KR': 'KR',
  'th-TH': 'TH',
  'zh-Hans': 'CN',
  'zh-Hant': 'TW',
  'en-MY': 'MY',
  'en-IR': 'IR',
  'en-MM': 'MM',
  'ro-RO': 'RO'
}

export const countryToLocale = {
  DE: 'de',
  US: 'en',
  FR: 'fr',
  JP: 'jp',
  AU: 'en',
  NL: 'nl',
  NO: 'no',
  ZA: 'en',
  SE: 'se',
  AT: 'de',
  CH: 'de',
  CA: 'en',
  SG: 'en',
  NZ: 'en',
  NA: 'en',
  CN: 'cn',
  BR: 'br',
  BS: 'en',
  DK: 'dk',
  IN: 'en',
  IT: 'it',
  TR: 'tr',
  PL: 'pl',
  KR: 'kr',
  CZ: 'cz',
  HU: 'hu',
  FI: 'fi',
  TH: 'th',
  TW: 'tw',
  BE: 'fr',
  IE: 'en',
  CO: 'es',
  CR: 'es',
  PE: 'es',
  ES: 'es',
  UY: 'es',
  MY: 'en',
  IR: 'en',
  MM: 'en',
  OM: 'en',
  TZ: 'en',
  TT: 'en',
  MT: 'mt',
  RO: 'ro'
}

export const privacyPolicyCountryToLocale = {
  DE: 'de',
  US: 'en',
  FR: 'fr',
  JP: 'jp',
  AU: 'en',
  NL: 'nl',
  NO: 'en',
  ZA: 'en',
  SE: 'se',
  AT: 'de',
  CH: 'de',
  CA: 'en',
  SG: 'en',
  NZ: 'en',
  NA: 'en',
  CN: 'cn',
  BR: 'br',
  BS: 'en',
  DK: 'dk',
  IN: 'in',
  IT: 'it',
  TR: 'en',
  PL: 'pl',
  KR: 'en',
  CZ: 'cz',
  HU: 'hu',
  FI: 'fi',
  TH: 'th',
  TW: 'tw',
  BE: 'fr',
  IE: 'en',
  CO: 'es',
  CR: 'es',
  PE: 'es',
  ES: 'es',
  UY: 'es',
  MY: 'en',
  IR: 'en',
  MM: 'en',
  OM: 'en',
  TZ: 'en',
  TT: 'en',
  MT: 'en',
  PH: 'en'
}

export const brandingColors = {
  '#d60925': 'red',
  '#78b928': 'green',
  '#00ace9': 'blue',
  '#ef7d00': 'orange',
  '#5c2483': 'purple',
  '#ffd500': 'yellow',
  '#e6007e': 'magenta',
  '#00cccc': 'cyan',
  '#6f6f6e': 'dark'
}

export const brandingTypes = [
  {
    name: 'SIEMENS',
    caption: 'Signia',
    hasCiColorSelector: false,
    iconClassName: 'signia-icon'
  }
]

export const paginationSize = 25

export const PortalContext = 'Portal'

/* define how strong or weak a password can be on a scale from 0 to 5*/
export const passwordStrongScore = 2
export const chatMessageMaxLength = 500
export const marketingMessageMaxLength = 500
export const customMessageMaxLength = 500

export const passwordMinLength = 6

export const journeyStatuses = Object.freeze([
  /*order and naming are important*/
  'in-trial',
  'closed-sold',
  'closed-cancelled'
])

export const videoCallStatus = {
  audio: 'AUDIO',
  successStatus: 'SUCCESSFUL',
  failureStatus: 'UNSUCCESSFUL',
  audioCall: 'CALL',
  videoCall: 'VIDEOCALL'
}

export const CallTypeConstants = {
  video: "VIDEO",
  audio: "AUDIO"
}

export const BluetoothSetting = {
  EnableBluetooth: 'enable',
  DisableBluetooth: 'disable'
}

export const ProductSource = 'TelecarePortal'

export const DataCollectionType = {
  ChatData: 'ChatData',
  OrgData: 'OrgData',
  LanguageData: 'LanguageData',
  LogOut: 'Logout',
  Crud: 'Crud',
  Navigation: 'Navigation',
  MarketingMessage: 'MarketingMessage',
  RemoteTuning: 'RemoteTuning',
  PatientData: 'PatientData',
  PlatformData: 'PlatformData',
  Notifications: 'Notifications',
}

export const ChatMessageDirection = {
  APP_TO_PORTAL: 'APP_TO_PORTAL',
  PORTAL_TO_APP: 'PORTAL_TO_APP'
}

export const phoneRegex = /[a-z]/i

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

export const AppType = {
  SIGNIA: 'RTAPP-SIGNIA',
  STRETTA: 'RTAPP-STRETTA',
  PRIVATELABEL: 'RTAPP-PRIVATELABEL',
  REXTON: 'RTAPP-REXTON',
  TRUHEARING: 'RTAPP-TRUHEARING',
  AUDIBENE: 'RTAPP-AUDIBENE',
  HEARDOTCOM: 'RTAPP-HEARDOTCOM',
  AUDIOSERVICE: 'RTAPP-AUDIOSERVICE',
  INTEGRATION: 'RTAPP-INTEGRATION',
  INTEGRATIONTEST: 'RTAPP-INTEGRATIONTEST'  //needed for local iOS Builds
}

export const CorrelationContext = {
  ConnectToTelecareApp_GetWebCode:
    'telecare-workflow-name=ConnectToTelecare,telecare-workflow-step=GetWebCode,telecare-workflow-supportId=',
  ConnectToTelecareApp_SendWebCode:
    'telecare-workflow-name=ConnectToTelecare,telecare-workflow-step=SendWebCode,telecare-workflow-supportId=',
  CreatePatient_SendWebCode:
    'telecare-workflow-name=CreatePatient,telecare-workflow-step=SendWebCode,telecare-workflow-supportId=',
  RemoteTuning_GetSettings:
    'telecare-workflow-name=RemoteTuning,telecare-workflow-step=GetSettings,telecare-workflow-supportId=',
  RemoteTuning_SaveSettings:
    'telecare-workflow-name=RemoteTuning,telecare-workflow-step=SaveSettings,telecare-workflow-supportId=',
  RemoteTuning_ResetSettings:
    'telecare-workflow-name=RemoteTuning,telecare-workflow-step=ResetSettings,telecare-workflow-supportId=',
  ChatMessage_GetChatMessage:
    'telecare-workflow-name=Message,telecare-workflow-step=GetChatMessage,telecare-workflow-supportId=',
  ChatMessage_SendChatMessage:
    'telecare-workflow-name=Message,telecare-workflow-step=SendChatMessage,telecare-workflow-supportId=',
  ChatMessage_MarkAsReadChatMessage:
    'telecare-workflow-name=Message,telecare-workflow-step=MarkAsReadChatMessage,telecare-workflow-supportId=',
  ChatMessage_ResetUnreadMessageCount:
    'telecare-workflow-name=Message,telecare-workflow-step=ResetUnreadMessageCount,telecare-workflow-supportId=',
  AudioVideoCall_VIDEO:
    'telecare-workflow-name=AudioVideoCall,telecare-workflow-step=VideoCall,telecare-workflow-supportId=',
  AudioVideoCall_AUDIO:
    'telecare-workflow-name=AudioVideoCall,telecare-workflow-step=AudioCall,telecare-workflow-supportId='
}

export const TIMEOUT_FOR_ANONYMIZED_GUID = 300 * 1000

export const STANDARD_LESSON_SET_NAME = 'Standard'

export const USER_ACTIVITY_THROTTLER_TIME = 30 * 1000

//User should be logged out after 90 minutes of inactivity
export const INACTIVE_USER_TIME_THRESHOLD = 90 * 60 * 1000

export const PasswordExpiryPeriod = {
  min: 30,
  max: 365,
}

export const DefaultBrandName = 'signia'

export const NoBrandName = 'neutral'

export const pathsWithProductTour = ["/overview/patients"]

export const OTP_LENGHT = 5;

export const OTP_RESEND_TIME_PHONE = 119000;
export const OTP_RESEND_TIME_EMAIL = 599000;

export const PhoneNumberLengthForValidaton = 7;

export const notificationOptionNameConst = {
  off: "OFF",
  email: "EMAIL",
  sms: "SMS"
};

export const StorageKeyHcpContactVerificationInfoPopup = "hcpContactVerificationInfoPopup"

export const CostcoErrorCodes = {
  PasswordPolicyError: "PASSWORD_POLICY_ERROR",
  GeneralInvalidInput: "GENERAL_INVALID_INPUT"
}

export const DateTimeZone = {
  UtcTimeZone: 'UTC'
}

export const DateFormatOfDatePicker = 'P'

export const IcelinkLib = "icelink"

export const LiveswitchLib = "liveswitch"

export const NotificationType = {
  DailySatisfaction: 0,
  Lesson: 1
}

export const signalRHubUrl = {
  ChatHub: "/chathub",
  NotificationHub: "/notificationhub"
}