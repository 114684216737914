import React from 'react'
import { Formik, Form } from 'formik'

const PatientCreatedEditForm = (props) => {
  const onSubmit = e => {
    e.preventDefault()

    !!props.onSubmit && props.onSubmit(e)
  }
  return (
    <Formik initialValues={{
      employee: props.employee,
      practice: props.practice
    }}>
      <Form onSubmit={onSubmit}>
        {props.children}
      </Form>
    </Formik>
  )
}

export default PatientCreatedEditForm