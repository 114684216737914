import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'
import { AppInsightCloudRoleName } from 'helper/constants'

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    // eslint-disable-next-line no-undef
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS || '6fe0078b-431e-43d4-b108-b1b04ec21e85',
    disableFetchTracking: false,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableCorrelationHeaders: false,
    enableCorsCorrelation: true,
    correlationHeaderExcludedDomains: [
      '*.launchdarkly.com',
      '*.websync.fm',
      '*.liveswitch.io',
      '*.googletagmanager.com',
      '*noembed.com'
    ],
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory }
    }
  }
})
ai.loadAppInsights()
const telemetryInitializer = envelope => {
  envelope.tags['ai.cloud.role'] = AppInsightCloudRoleName
  envelope.tags['ai.cloud.roleInstance'] = AppInsightCloudRoleName
}
ai.addTelemetryInitializer(telemetryInitializer)
export default Component => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
