import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { check, flags } from 'helper/customerCapabilities'
import Tooltip from 'components/Tooltip'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'
import fflags from 'helper/FFlags'

export const NavigationItem = ({
  active,
  href,
  className = '',
  label = '',
  disabled,
  tooltipText = '',
  dataqa = ''
}) => {
  return (
    <div data-tip data-for={`Navigation-${dataqa}`} data-qa={dataqa}>
      <StyledButtonLink
        $buttontertiarystyle
        onClick={(e) => disabled ? e.preventDefault() : null}
        to={href}
        className={`overview-navigation__handler  ${active == href ? 'active' : ''} ${className} ${disabled ? 'disabled' : ''}`}
      >
        {label}
      </StyledButtonLink>
      {tooltipText &&
        <Tooltip id={`Navigation-${dataqa}`} subtitle={tooltipText} />
      }
    </div>
  )
}

export const Navigation = ({ active, customerId = '', customer }) => {
  let disableExtendedDataButtons = false
  let tooltipText = false
  const isConnectDaToTelecareEnabled = fflags.variation(
    'enable-da-in-telecare',
    false
  )

  if (!check('bluetooth', customer, [flags.MOBILE_DEVICE_SUPPORTED])) {
    // so mobile device isn't supported
    disableExtendedDataButtons = true
    tooltipText = <FormattedMessage {...T('customerProfile.mobileDeviceNotSupported')} />
  }

  if (!check('bluetooth', customer, [flags.HEARING_DEVICE_SUPPORTED])) {
    // so no bluetooth device is selected
    disableExtendedDataButtons = true
    tooltipText = <FormattedMessage {...T('customerProfile.deviceNotSupported')} />
  }

  return (
    <div className="row">
      <div className="col-3">
        <NavigationItem
          dataqa="wearing-time"
          tooltipText={tooltipText}
          active={active}
          href={`/profile/${customerId}/overview`}
          label={<FormattedMessage {...T('customerProfile.wearingTime')} />}
          disabled={disableExtendedDataButtons}
        />
      </div>
      <div className="col-3">
        <NavigationItem
          dataqa="satisfaction"
          active={active}
          href={`/profile/${customerId}/overview/satisfaction`}
          label={<FormattedMessage {...T('customerProfile.satisfaction')} />}
        />
      </div>
      {isConnectDaToTelecareEnabled &&
        <div className="col-3">
          <NavigationItem
            dataqa="assistant-history"
            active={active}
            href={`/profile/${customerId}/overview/assistant-history`}
            label={<FormattedMessage {...T("customerProfile.assistantHistory")} />}
            disabled={!customer.digitalAssistantConsent}
            tooltipText={!customer.digitalAssistantConsent && <FormattedMessage {...T("customerProfile.noAssistantHistory")} />}
          />
        </div>
      }
    </div>
  )
}

export default Navigation
