import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from 'i18n/messages.json'

const NewMessagesBadge = () =>
  <div className="text-center">
    <div className="chat__system chat__system--note">
      <FormattedMessage {...messages.chatNewMessages} />
    </div>
  </div>

export default NewMessagesBadge
