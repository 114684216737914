import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

const Item = ({ name, href, dataqa, location }) => {
  const active = href === location.pathname
  const modifier = active ? 'active' : ''

  return (
    <StyledButtonLink
      $buttontertiarystyle
      className={modifier}
      to={href}
      data-qa={dataqa}
    >
      <FormattedMessage {...T(name)} />
    </StyledButtonLink>
  )
}

export default Item
