import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { authorize } from 'helper/fetch-token-intercept/src'
import { STORAGE_KEY } from 'helper/constants'
import { T } from 'core'
import _ from 'lodash'
import SigniaLogo from '../../assets/img/signia_logo.png'
import BrandNeutralLogo from '../../assets/img/brand-neutral-logo.svg'
import connector from '../../helper/HttpConnector'
import { shopTracking } from 'helper/shopTracking'
import LoginForm from './LoginForm'
import { LoginWrapper } from 'styledComponents/Div/DivContainer/DivLogin'
import { FlexRow } from 'styledComponents/Div/DivContainer/DivLandingPage'
import { withQuery } from '../../helper/withQuery';
import { checkPasswordExpiry } from '../../helper/PasswordExpiryCheck'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'
import fflags from 'helper/FFlags'
import { withRouter } from '../../helper/withRouter';
export class LoginPage extends Component {

  UNSAFE_componentWillMount() {
    if (localStorage.getItem('passwordExpired')) {
      this.props.navigate('/password/expire')
    }
    if (this.props.isAuthenticated) {
      this.props.navigate('/overview/patients')
    }
  }

  onLogin = async (data, setFieldError, setSubmitting) => {
    try {

      const authData = await connector.doLogin(data.username, data.password)
      FlushEventsToAppInsight('User Logged In')
      authorize(authData.refresh_token, authData.access_token)
      //await this.props.login({ ...authData })
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...authData }))
      const isPasswordExpired = await checkPasswordExpiry()
      if (isPasswordExpired)
        return this.props.navigate('/password/expire')
      shopTracking({
        ...this.props.location,
        action: 'employeeLogin'
      })
      const returnUrl = this.props.query.returnurl
      if (_.isNil(returnUrl) || returnUrl == '/') {
        this.props.navigate('/overview/patients')
      } else {
        this.props.navigate(returnUrl)
      }
    } catch (e) {
      console.log(e)
      this.evaluateHttpStatus(e.status, setFieldError, setSubmitting)
    }
  }

  evaluateHttpStatus(status, setFieldError, setSubmitting) {
    const { intl: { formatMessage } } = this.props

    if (status === 400) {
      setFieldError('error', formatMessage(T('login.error')))
    } else if (status === 403) {
      setFieldError('error', formatMessage(T('login.userLocked')))
    } else {
      setFieldError('error', formatMessage(T('generic_error')))
    }
    setSubmitting(false)
  }

  render() {
    const isBrandingEnabled = fflags.variation(
      'enable-portal-branding',
      false
    )
    return (
      <LoginWrapper>
        <FlexRow className="row">
          <div className="col-md-8 alignCenterHorizontal">
            <img className="imgResponsive" src={isBrandingEnabled ? BrandNeutralLogo : SigniaLogo} />
          </div>
        </FlexRow>
        <div className="row">
          <LoginForm
            doLogin={(values, setFieldError, setSubmitting) =>
              this.onLogin(values, setFieldError, setSubmitting)}
          />
        </div>
      </LoginWrapper>
    )
  }
}

LoginPage.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired
}

const loginQuery = gql`
  mutation updateAuthMutation($authentication: AuthenticationToken!) @client {
    login(authentication: $authentication) {
      authentication
    }
  }
`
const withData = compose(
  graphql(loginQuery, {
    props: ({ mutate }) => ({
      login: authentication => mutate({ variables: { authentication } })
    })
  }),
  injectIntl
)

export default withQuery(withRouter(withData(LoginPage)))