import React, { FC, ReactElement, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FormattedMessage } from 'react-intl'
import { NotificationType, DataCollectionType } from "helper/constants"
import { NotificationRecord } from './NotificationRecord'
import { T } from 'core'
import { Defer } from '../../helper/Utils'
import { IClearAllComponentProps, INotificationRecordListProps } from '../../typescript/interfaces/INotificationDropdown'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { MakeNotificationsPayload } from 'helper/DataCollection/PayloadData'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'

export const NotificationContainer: FC = (): ReactElement => {

  const { satisfactionNotifications, lessonNotifications, setSatisfactionNotifications, setLessonNotifications } = useNotificationsContext()

  const satisfactionAccordionStyle = {
    borderRadius: '0.5rem 0.5rem 0 0'
  }

  const lessonAccordionStyle = {
    borderRadius: '0 0 0.5rem 0.5rem'
  }

  const accordionToggleStyle = {
    fontSize: '1rem',
    color: '#454A55',
    padding: '1.25rem 1.25rem'
  }

  return (
    <>
      <Accordion defaultActiveKey='0'>
        <Card style={satisfactionAccordionStyle}>
          <Accordion.Toggle as={Card.Header} eventKey="0" style={accordionToggleStyle}>
            <div><FormattedMessage {...T('notifications.negativeSatisfaction.Header')} /></div>
            <ClearAll isNotificationsPresent={satisfactionNotifications.length > 0}
              notificationType={NotificationType.DailySatisfaction}
              setNotificationsState={setSatisfactionNotifications} />
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body bsPrefix={`accordion-body ${satisfactionNotifications.length > 0 ? '' : 'no-notifications-header'}`}>
              <NotificationRecordList
                notificationList={satisfactionNotifications}
                setNotificationsState={setSatisfactionNotifications} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey='1'>
        <Card style={lessonAccordionStyle}>
          <Accordion.Toggle as={Card.Header} eventKey="1" style={accordionToggleStyle}>
            <div><FormattedMessage {...T('notifications.negativeLesson.Header')} /></div>
            <ClearAll isNotificationsPresent={lessonNotifications.length > 0}
              notificationType={NotificationType.Lesson}
              setNotificationsState={setLessonNotifications} />
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body bsPrefix={`accordion-body ${lessonNotifications.length > 0 ? '' : 'no-notifications-header'}`}>
              <NotificationRecordList
                notificationList={lessonNotifications}
                setNotificationsState={setLessonNotifications}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion >
    </>
  )

}
export function ClearAll({ isNotificationsPresent, notificationType, setNotificationsState }: IClearAllComponentProps): ReactElement {
  const { clearAllNotifications, satisfactionNotifications, lessonNotifications, unreadNotificationCount } = useNotificationsContext()

  const [showLoader, setShowLoader] = useState(false)

  function performDataCollectionBeforeClearAll(): void {
    sendDataToDataCollection(DataCollectionType.Notifications,
      MakeNotificationsPayload(unreadNotificationCount, (satisfactionNotifications.length + lessonNotifications.length), notificationType),
      EventType.NotificationClearAll)
  }

  async function HandleClearAll(e: React.MouseEvent, notificationType: number): Promise<void> {
    e.stopPropagation()
    setShowLoader(true)

    setTimeout(() => {
      setShowLoader(false)
    }, 750)

    performDataCollectionBeforeClearAll()
    await clearAllNotifications({ notificationType })
    setNotificationsState([])
  }

  return <div>
    {isNotificationsPresent && !showLoader &&
      <span className='clear-all-button' onClick={(e) => HandleClearAll(e, notificationType)}>
        <FormattedMessage {...T('notifications.clearAll')} />
      </span>}
    {showLoader && <i className="fas fa-spinner fa-pulse"></i>}
  </div>
}

export function NotificationRecordList({ notificationList, setNotificationsState }: INotificationRecordListProps): React.ReactElement | React.ReactNode {
  return notificationList.length > 0
    ?
    <Defer chunkSize={10}>
      {notificationList.map((record) =>
        <NotificationRecord
          key={record.patientNotificationId}
          record={record}
          recordList={notificationList}
          setNotificationsState={setNotificationsState}
        />
      )}
    </Defer>
    :
    <div className='no-notifications-div'>
      <FormattedMessage {...T('notifications.noNotifications')} />
    </div>
}