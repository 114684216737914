import React, { useState } from 'react'
import { useRootClose } from 'react-overlays'
import moment from 'moment'
import { StyledFilters } from 'styledComponents/Div/DivComponent/DivDropdowns'

const RootClose = ({ children, onRootClose, ...props }) => {
  const [rootElement, attachRef] = useState(null);
  useRootClose(rootElement, onRootClose, props);
  return children(attachRef);
};

export class Filters extends React.Component {
  handleRootClose = () => {
    this.props.onClose()
  }
  render() {
    return (
      <RootClose disabled={!open} onRootClose={this.handleRootClose}>
          {(ref) => (
            <StyledFilters ref={ref}>
              {this.props.children}
            </StyledFilters>            
          )}
      </RootClose>
    )
  }
}

/* eslint-disable no-unused-vars */
export const getJourneyInterval = ({
  lastDays = false,
  lastMonth = false,
  allTime = true
}) => {
  if (!allTime) {
    const to = moment().format('YYYY-MM-DD')
    const from = lastDays
      ? moment().subtract(7, 'days')
      : moment().subtract(1, 'month')
    return { from: from.format('YYYY-MM-DD'), to }
  }
  return {}
}

export const defaultJorneyDateStates = {
  lastDays: false,
  lastMonth: false,
  custom: false,
  allTime: true
}
