import React from 'react'
import { FormattedMessage } from 'react-intl'
import Dropdown from 'react-bootstrap/Dropdown';
import { T } from 'core'
import _ from 'lodash'
import informationIcon from 'assets/img/icon_S_info.svg'
import newTab from 'assets/img/icons/arrow-up-right-from-square-solid.svg'

const InfoDropDown = ({ startTourGuide, isTourGuideSupported, locale }) => {
	const infoImage = informationIcon
	const informationMenu = { backgroundImage: `url('${infoImage}')` }
	const newTabIcon = newTab
	const newTabLink = { backgroundImage: `url('${newTabIcon}')` }
	return (
		<Dropdown className="dropdown--information" data-qa="info-dropdown">
			<Dropdown.Toggle variant="outline-light"
				id="dropdown--information" data-qa="dropdown-menu">
				<span className="information" style={informationMenu}></span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item href={`/help/faq?locale=${locale}`} target="_blank" data-qa="faq-section">
					<div className='row  justify-content-between pl-3'>
						<span>
							<FormattedMessage {...T("helpCenter.faq")} />
						</span>
						<span className="information" style={newTabLink}></span>
					</div>
				</Dropdown.Item>
				{isTourGuideSupported && (
					<>
						<Dropdown.Divider />
						<Dropdown.Item id="tourGuideStartButton" href={`#`}
							onClick={startTourGuide} data-qa="tour-guide">
							<FormattedMessage {...T("tourGuide.common.title")} />
						</Dropdown.Item>
					</>
				)}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default InfoDropDown

