import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Field } from 'formik'

import messages from 'i18n/messages.json'

import { weekdays } from 'helper/constants'
import OpeningHoursField from './OpeningHoursField'
import { Button } from 'styledComponents/Button'

export const OpeningHoursFieldArray = ({
  form: { values: { openingHours } },
  intl: { formatMessage },
  push,
  remove,
  isReadOnly
}) => {
  const newOpeningHoursElement = {
    days: weekdays.slice(0, 5),
    from: '09:00:00',
    to: '18:00:00',
    comment: ''
  }
  return (
    <div className="openingHoursGroup" data-qa="opening-hours">
      {openingHours.map((openingHourName, openingHourIndex) =>
        <Field
          isReadOnly={isReadOnly}
          key={openingHourIndex}
          name={`openingHours[${openingHourIndex}]`}
          component={OpeningHoursField}
          onDelete={() => remove(openingHourIndex)}
        />
      )}
      <div className="row">
        <div className="col-10 col-md-3">
          <Button
            secondary
            className="margin-top"
            onClick={() => push(newOpeningHoursElement)}
            disabled={isReadOnly}
            type="button"
            data-qa="add-opening-hours-entry"
          >
            {formatMessage(messages.hcpEditAddEntry)}
          </Button>
        </div>
      </div>
    </div>
  )
}

OpeningHoursFieldArray.propTypes = {
  intl: PropTypes.shape({}).isRequired,

  openingHours: PropTypes.shape({})
}

export default injectIntl(OpeningHoursFieldArray)
