import { tracker } from 'core'

// count number of deltas present in the kpis response
export const countDashboardDeltas = practices =>
  practices.reduce((acc, practice) => {
    return practice.kpis.reduce((accumulator, item) => {
      accumulator += !!item.delta ? 1 : 0
      return accumulator
    }, acc)
  }, 0)

export const trackDashboardAction = ({ action, practices = [], deviceFamilies = [] }) => {
  tracker.track('dashboardAction', {
    action,
    dashdevicecount: deviceFamilies.length || 0,
    dashkpicount: practices.length || 0,
    dashdeltacount: countDashboardDeltas(practices)
  })
}
