import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { T } from 'core'
import { DropdownButton } from 'react-bootstrap'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import moment from 'moment'
import { notEqual } from 'helper/Utils'
import { omit } from 'lodash'
import {
  getJourneyInterval,
  defaultJorneyDateStates
} from 'components/Dropdowns/MarketingFilters/shared'
import Checkbox from 'components/Checkbox'
import { Button } from 'styledComponents/Button'
import {
  FilterOnPatientClosedCreated,
  ActionButtonsDropdown,
  StyledDateRange,
  CheckboxDiv
} from 'styledComponents/Div/DivComponent/DivDropdowns'

class MarketingPatientCreatedFilter extends React.Component {
  static displayName = 'MarketingPatientCreatedFilter'

  static defaultProps = {
    onChange: Function,
    isFilterOpen: false,
    onOpenFilter: Function,
    patientCreatedDate: null
  }

  state = {
    ...defaultJorneyDateStates,
    isDateRangeOpen: false,
    ranges: null,
    selection: [{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }]
  }

  calendarColor = '#3399FF'

  UNSAFE_componentWillReceiveProps(nextProps) {    
    if (!nextProps.patientCreatedDate) {
      this.setState({
        ...defaultJorneyDateStates,
        isDateRangeOpen: false,
        ranges: null
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cleanNextProps = omit(nextProps, ['onChange', 'onOpenFilter'])
    const cleanProps = omit(this.props, ['onChange', 'onOpenFilter'])
    return (
      notEqual(cleanNextProps, cleanProps) || notEqual(nextState, this.state)
    )
  }

  onApplyFilter = () => {
    const { lastDays, lastMonth, custom, allTime, ranges } = this.state

    const { from = null, to = null } = custom
      ? ranges
      : getJourneyInterval({
          lastDays,
          lastMonth,
          allTime
        })

    this.props.onChange({
      patientCreatedDate: {
        ...(!!from ? { from, to } : {})
      }
    })

    this.onClose()
  }

  onOpenFilter = () => {
    this.props.onOpenFilter(!this.props.isFilterOpen)
  }

  isFilterActive = () => {
    return !this.state.allTime
  }

  onClose = () => {
    this.setState(
      {
        isFilterActive: this.isFilterActive()
      },
      () => {
        this.props.onOpenFilter(!this.props.isFilterOpen)
      }
    )
  }

  onCancel = () => {
    this.setState(
      {
        ...defaultJorneyDateStates,
        isDateRangeOpen: false,
        ranges: {}
      },
      () => {
        this.onClose()
      }
    )
  }

  onCheck = id => e => {
    e.preventDefault()
    this.setState({
      lastDays: false,
      lastMonth: false,
      custom: false,
      allTime: false,
      [id]: !this.state[id],
      isDateRangeOpen: id === 'custom'
    })
  }

  onOpenDatePicker = () => {
    this.setState({
      lastDays: false,
      lastMonth: false,
      allTime: false,
      custom: true,
      isDateRangeOpen: true
    })
  }

  getFilterTitle = () => {
    const { intl: { formatMessage } } = this.props
    const activeState = `( ${!!this.state.allTime
      ? formatMessage(T('common.off'))
      : formatMessage(T('common.on'))} )`

    return (
      <FormattedMessage
        {...T('marketing.messages.menu-created-off')}
        values={{ 0: activeState }}
      />
    )
  }

  onChangeDateRange = ({ selection }) => {    
    this.setState({
      selection: [selection],
      ranges: {
        from: moment(selection.startDate).format('YYYY-MM-DD'),
        to: moment(selection.endDate).format('YYYY-MM-DD')
      }
    })
  }

  render() {
    const { intl: { formatMessage }, isFilterOpen } = this.props
    const {
      isFilterActive,
      lastDays,
      lastMonth,
      custom,
      allTime,
      isDateRangeOpen,
      selection
    } = this.state
    const title = this.getFilterTitle()
    const isFilterActiveClassName = isFilterActive ? 'filterIsActive' : ''    
    return (
      <FilterOnPatientClosedCreated>
        <DropdownButton
          variant={'default'}
          title={title}
          id={`patientStatusFilter`}
          data-qa="providersFilter"
          show={isFilterOpen}
          onToggle={(isOpen) => {this.onOpenFilter(isOpen)}}
          className={isFilterActiveClassName}
        >
          {isDateRangeOpen &&
          <StyledDateRange>
              <DateRange
                ranges={selection}
                onChange={this.onChangeDateRange}
                weekStartsOn={1}
                rangeColors={[this.calendarColor]}
                maxDate={new Date()}
              />
            </StyledDateRange>
            }

          <CheckboxDiv
            onClick={this.onCheck('allTime')}
            defaultChecked={allTime}
          >
            <Checkbox
              defaultChecked={allTime}
              value="allTime"
              label={<FormattedMessage {...T('marketing.messages.all-time')} />}
            />
          </CheckboxDiv>
          <CheckboxDiv
            onClick={this.onCheck('lastDays')}
            defaultChecked={lastDays}
          >
            <Checkbox
              value="lastDays"
              defaultChecked={lastDays}
              label={
                <FormattedMessage {...T('marketing.messages.last-days')} />
              }
            />
          </CheckboxDiv>
          <CheckboxDiv
            onClick={this.onCheck('lastMonth')}
            defaultChecked={lastMonth}
          >
            <Checkbox
              defaultChecked={lastMonth}
              label={
                <FormattedMessage {...T('marketing.messages.last-month')} />
              }
            />
          </CheckboxDiv>
          <CheckboxDiv onClick={this.onOpenDatePicker} defaultChecked={custom}>
            <Checkbox
              value="custom"
              defaultChecked={custom}
              label={<FormattedMessage {...T('marketing.messages.custom')} />}
            />
          </CheckboxDiv>
          <ActionButtonsDropdown>
            <Button
              secondary
              onClick={this.onCancel}
              data-qa="cancel-button"
            >
              {formatMessage(T('common.cancel'))}
            </Button>
            <Button
              type="submit"
              primary
              data-qa="select-button"
              onClick={this.onApplyFilter}
            >
              {formatMessage(T('common.select'))}
            </Button>
          </ActionButtonsDropdown>
        </DropdownButton>
      </FilterOnPatientClosedCreated>
    )
  }
}

export default injectIntl(MarketingPatientCreatedFilter)
