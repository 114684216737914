import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { T } from "core";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "lodash/fp";
import { graphql } from "@apollo/client/react/hoc";
import OtpInput from "react-otp-input";
import { Button } from "styledComponents/Button";
import { OTP_LENGHT, OTP_RESEND_TIME_PHONE, OTP_RESEND_TIME_EMAIL } from "../../helper/constants";
import Timer from "../Timer";
import {
  MUTATION_GENERATE_OTP_FOR_PHONE_VERIFICATION,
  MUTATION_GENERATE_OTP_FOR_EMAIL_VERIFICATION,
  MUTATION_VERIFY_OTP_FOR_PHONE_VERIFICATION,
  MUTATION_VERIFY_OTP_FOR_EMAIL_VERIFICATION
} from "../../graphql/mutations/ContactVerificationMutation";

export const OtpVerificationModal = ({
  show,
  onHide,
  contactInfo,
  isPhoneRequest,
  locale,
  ...props
}) => {
  const otpPlaceholder = new Array(OTP_LENGHT + 1).join("-");
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isErrorSendingCode, setIsErrorSendingCode] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [counterTime, setCounterTime] = useState(Date.now() + (isPhoneRequest ? OTP_RESEND_TIME_PHONE : OTP_RESEND_TIME_EMAIL));

  let timerCountdownApi;

  const customInputStyle = {
    "border": "1px solid #979797",
    "borderRadius": "0.5rem",
    "width": "3.8rem",
    "height": "6.25rem",
    "fontSize": "1.125rem",
    "color": "#000",
    "fontWeight": "400",
    "caretColor": "blue",
    "margin": "0 0.25rem",
    "backgroundColor": "#D8D8D8"
  }

  const handleOtpInputChange = (otp) => {
    const isNumberOnly = /^\d+$/.test(otp);

    if (isNumberOnly)
      setOtp(otp);

    if (otp.length === OTP_LENGHT)
      setIsSubmitEnabled(true);
    else
      setIsSubmitEnabled(false);
  };

  const clearOtpInputFields = () => {
    setOtp("");
    setIsOtpValid(true);
    setIsSubmitEnabled(false);
    setIsErrorSendingCode(false);
  };

  const getTimerRef = (timerRef) => {
    if (timerRef) {
      timerCountdownApi = timerRef.getApi();
    }
  };

  const startTimer = () => {
    setIsTimerRunning(true);
    setCounterTime(Date.now() + (isPhoneRequest ? OTP_RESEND_TIME_PHONE : OTP_RESEND_TIME_EMAIL))
    timerCountdownApi.start();
  };

  const stopTimer = () => {
    timerCountdownApi.stop();
    setIsTimerRunning(false);
  };

  const onTimerComplete = () => {
    timerCountdownApi.stop();
    setIsTimerRunning(false);
  };

  const handlePostSendingCode = (result) => {
    if (result) {
      setIsErrorSendingCode(false);
      startTimer();
    }
    else {
      setIsErrorSendingCode(true);
    }
  }

  const generateAndSendOtp = useCallback(async () => {
    const response = isPhoneRequest
      ? await props.generateOtpForPhoneVerification({ contactPhoneEntity: { phone: contactInfo, locale: locale } })
      : await props.generateOtpForEmailVerification({ contactEmailEntity: { email: contactInfo, locale: locale } });

    const result = response.errors
      ? false
      : isPhoneRequest
        ? response.data.generateOtpForPhoneVerification
        : response.data.generateOtpForEmailVerification;

    return result;
  }, []);

  const verifyOtp = async () => {
    const response = isPhoneRequest
      ? await props.verifyOtpForPhoneVerification({ contactVerifyPhoneEntity: { phone: contactInfo, code: otp } })
      : await props.verifyOtpForEmailVerification({ contactVerifyEmailEntity: { email: contactInfo, code: otp } })

    return isPhoneRequest ? response.data.verifyOtpForPhoneVerification : response.data.verifyOtpForEmailVerification;
  }

  const handleResend = () => {
    clearOtpInputFields();
    generateAndSendOtp()
      .then(result => handlePostSendingCode(result));
  };

  const handleSubmit = async () => {
    const result = await verifyOtp();

    stopTimer();
    setIsVerified(result);
    setIsOtpValid(result);
  };

  useEffect(() => {
    generateAndSendOtp()
      .then(result => handlePostSendingCode(result));
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide({ isVerified, isPhoneRequest, contactInfo })}
        centered
        scrollable
        size="lg"
        backdrop="static"
        dialogClassName="otpVerificationModalSize"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "0 none", padding: "1rem 1rem 0 2rem" }}
        >
          {isVerified
            ? <Modal.Title id="verifiedTitle" style={{ marginLeft: "auto" }}>
              <FormattedMessage {...T("otp.verifiedSuccessfully")} />
            </Modal.Title>
            : <Modal.Title>
              <FormattedMessage
                {...(isPhoneRequest
                  ? { ...T("otp.mobileCode") }
                  : { ...T("otp.emailCode") })}
              />
            </Modal.Title>
          }
        </Modal.Header>

        <Modal.Body bsPrefix="otpVerificationModalBody">
          {isVerified
            ? <>
              <div id="verifiedBody" className="row flex-column align-items-center justify-content-center" >
                <div>
                  <i className="otpVerifiedIcon fa fa-check-circle" aria-hidden="true" />
                </div>
                <div className="otpVerificationModalText">
                  <FormattedMessage
                    {...(isPhoneRequest
                      ? { ...T("otp.verifiedMobileNumber") }
                      : { ...T("otp.verifiedEmail") })}
                  />
                </div>
              </div>
            </>
            : <>
              <div className="otpVerificationModalText">
                <FormattedMessage
                  {...T("otp.enterVerificationCode")}
                  values={{ 0: contactInfo }}
                />
              </div>
              <OtpInput
                value={otp}
                onChange={handleOtpInputChange}
                numInputs={OTP_LENGHT}
                placeholder={otpPlaceholder}
                isInputNum={true}
                shouldAutoFocus={true}
                isDisabled={isErrorSendingCode}
                inputStyle={customInputStyle}
                focusStyle={{ border: "1px solid #CFD3DB" }}
                disabledStyle={{ border: "1px solid #CFD3DB" }}
              />

              {isErrorSendingCode && (
                <div id="errorSendingOtpCode" className="wrongOtpCode">
                  <i className="fa fa-exclamation-triangle" style={{ marginRight: "1%" }} />
                  <FormattedMessage {...T("otp.errorSendingCodeMessage")} />
                </div>
              )}
              {!isOtpValid && (
                <div id="invalidOtpCode" className="wrongOtpCode">
                  <i className="fa fa-exclamation-triangle" style={{ marginRight: "1%" }} />
                  <FormattedMessage {...T("otp.wrongCodeEntered")} />
                </div>
              )}
              <div className="otpVerificationModalText">
                <FormattedMessage {...T("otp.didNotReceiveCode")} />
                <span onClick={isTimerRunning ? () => { } : handleResend} className={`${isTimerRunning ? "" : "otpResendEnabled"}`}>
                  <FormattedMessage {...T("otp.resendCode")} />
                </span>
                <span className={`${isTimerRunning ? "" : "displayNone"}`} style={{ marginLeft: "1%" }}>
                  <Timer
                    refCallback={getTimerRef}
                    durationInMillisecondsFromNow={counterTime}
                    autoStart={false}
                    onTimerComplete={onTimerComplete}
                  />
                </span>
              </div>
            </>
          }
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none", paddingTop: "0", paddingBottom: "1.2rem" }}>
          {isVerified ?
            <Button
              primary
              type="button"
              className="otpVerificationButtonWidth"
              onClick={() => onHide({ isVerified, isPhoneRequest, contactInfo })}
            >
              <FormattedMessage {...T("otp.gotoDashboard")} />
            </Button>
            :
            <>
              <Button
                secondary
                type="button"
                className="otpVerificationButtonWidth"
                onClick={() => onHide({ isVerified, isPhoneRequest, contactInfo })}
                data-qa="cancel-button"
              >
                <FormattedMessage {...T("common.cancel")} />
              </Button>
              <Button
                primary
                disabled={!isSubmitEnabled}
                type="button"
                className="otpVerificationButtonWidth"
                onClick={handleSubmit}
                data-qa="submit-button"
              >
                <FormattedMessage {...T("common.submit")} />
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

const withData = compose(
  graphql(MUTATION_GENERATE_OTP_FOR_PHONE_VERIFICATION, {
    props: ({ mutate }) => ({
      generateOtpForPhoneVerification: (variables) =>
        mutate({
          variables,
        }),
    }),
  }),

  graphql(MUTATION_GENERATE_OTP_FOR_EMAIL_VERIFICATION, {
    props: ({ mutate }) => ({
      generateOtpForEmailVerification: (variables) =>
        mutate({
          variables,
        }),
    }),
  }),

  graphql(MUTATION_VERIFY_OTP_FOR_PHONE_VERIFICATION, {
    props: ({ mutate }) => ({
      verifyOtpForPhoneVerification: (variables) =>
        mutate({
          variables,
        }),
    }),
  }),

  graphql(MUTATION_VERIFY_OTP_FOR_EMAIL_VERIFICATION, {
    props: ({ mutate }) => ({
      verifyOtpForEmailVerification: (variables) =>
        mutate({
          variables,
        }),
    }),
  }),
);

export default withData(injectIntl(OtpVerificationModal));