
import { graphql } from '@apollo/client/react/hoc';
import { IntlProvider } from 'react-intl'
import * as languages from 'i18n'
import { compose } from 'lodash/fp'
import { LOCALE_QUERY } from './../graphql/queries/LocaleQuery'

// This function will map the current apollo cache to the props for the component that it is "connected" to.
// When apollo cache locale data changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
export const mapCacheToProps = ({ data: { locale }}) => {
  const messages = languages[locale.locale.replace('-', '')]
  window.localStorage.setItem('locale', JSON.stringify(locale))

  return {
    locale: locale.locale,
    messages,
    key: locale.locale
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: mapCacheToProps
  })
)

export default withData(IntlProvider)
