import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

import Loading from '../../../components/Loading'
export default class LoadMessages extends Component {
  handleTimeOut = () => {
    this.props.onTimeout()
  }
  render() {
    const { isLoading, loadedAllData, loadError } = this.props
    return (
      <div>
        {isLoading &&
          !loadError &&
          <div className="chat-load-messages">
            <div className="chat-spinner">
              <Loading isLoading={isLoading} onTimeout={this.handleTimeOut} />
            </div>
            <div className="chat-load-text text-center">
              <FormattedMessage {...T(`${'chat.LoadingMessages'}`)} />
            </div>
          </div>}

        {!isLoading &&
          (loadError
            ? <div className="text-center">
                <FormattedMessage {...T(`${'chat.LoadMessagesError'}`)} />
              </div>
            : !loadedAllData
              ? <div className="text-center">
                  <FormattedMessage {...T(`${'chat.ScrollToLoad'}`)} />
                </div>
              : <div className="text-center">
                  <FormattedMessage {...T(`${'chat.NoMoreToLoad'}`)} />
                </div>)}
      </div>
    )
  }
}
