import { T } from 'core'
import React from 'react'
import { Popup, VideoInfoOverlay } from './../components/BrowserPopups/Popup'
import ErrorPopup from './../components/ErrorPopup'

import headsetIcon from 'assets/img/headset.png'
import DataContext from "containers/DataContext"
import liveswitch from 'fm.liveswitch'
import { RecallButton } from 'styledComponents/Button'
import {
  PopupBackground, VideoButtons, VideoCallWrapper,
  VideoContainer
} from 'styledComponents/Div/DivComponent/DivVideoCall'
import { PopupIcon } from 'styledComponents/Img'
import { ActionButton } from 'styledComponents/Span'
import { CALL_STATES } from './../callStates'


export default class AudioVideoCall extends React.Component {
  static contextType = DataContext;

  state = {
    callType: 'VIDEO',
    videoMuted: false,
    audioMuted: false,
    remoteAudioMuted: false,
    remoteVideoMuted: false,
    remoteApplyingSettings: false,
    callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
    maximized: true,
    showCallEnded: false,
    remoteCallState: ''
  }

  resetState() {
    this.setState({
      callType: 'VIDEO',
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      remoteApplyingSettings: false,
      callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
      maximized: true,
      showCallEnded: false,
      remoteCallState: ''
    })
  }

  UNSAFE_componentWillMount() {
    const { liveswitchVideoCallManager } = this.context
    window.overlay = this
    liveswitchVideoCallManager.on('change', this.onVideoManagerChange)
  }

  componentWillUnmount() {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.off('change', this.onVideoManagerChange)
  }

  onVideoManagerChange = newState => {
    const { liveswitchVideoCallManager } = this.context
    const { callState } = newState
    const oldCallState = this.state.callState

    this.setState(
      {
        ...newState
      },
      () => {
        //after state has changed and videocontainer is rendered
        if (
          callState === CALL_STATES.REMOTE_JOINED &&
          oldCallState !== CALL_STATES.REMOTE_JOINED
        ) {
          const { localMedia, remoteMedia } = liveswitchVideoCallManager
          if (localMedia && remoteMedia) {
            this.setupLayout()
          }
        }
      }
    )
  }

  toggleAudioMute = () => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.toggleAudioMute()
    this.setState({
      isAudioMuted: liveswitchVideoCallManager.isLocalAudioMuted()
    })
  }

  toggleVideoMute = () => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.toggleVideoMute()
    this.setState({
      isVideoMuted: liveswitchVideoCallManager.isLocalVideoMuted()
    })
  }

  toggleMaximized = () => {
    this.setState(
      {
        maximized: !this.state.maximized
      },
      () => {
        // after setting the state (rendering is going on)
        if (this.state.maximized) {
          this.updateLayoutMaximized()
        } else {
          this.updateLayoutMinimized()
        }
      }
    )
  }

  updateLayoutMinimized() {
    if (this.layoutManager) {
      const localView = this.layoutManager.getLocalView()
      const remoteViews = this.layoutManager.getRemoteViews()

      if (localView) {
        localView.style.display = 'none'
      }

      if (remoteViews && remoteViews.length) {
        const video = remoteViews[0].querySelector('video')
        video.style['object-fit'] = 'cover'
      }
    }
  }

  updateLayoutMaximized() {
    if (this.layoutManager) {
      const localView = this.layoutManager.getLocalView()
      const remoteViews = this.layoutManager.getRemoteViews()

      if (localView) {
        localView.style.display = 'block'
      }

      if (remoteViews && remoteViews.length) {
        const video = remoteViews[0].querySelector('video')
        video.style['object-fit'] = 'contain'
      }
    }
  }

  leave = async ({ offerReconnect = false } = {}) => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.leave('Ended', offerReconnect)
    await liveswitchVideoCallManager.stopLocalMedia()
    if (this.layoutManager != null) {
      this.layoutManager.removeRemoteViews()
      this.layoutManager.unsetLocalView()
      this.layoutManager = null
    }
    // Tear down the local media.
    if (liveswitchVideoCallManager.localMedia != null) {
      liveswitchVideoCallManager.localMedia = null
    }

    this.setState({
      showCallEnded: offerReconnect,
      maximized: true
    })
  }

  leaveLocalIntent = async () => {
    const { liveswitchVideoCallManager } = this.context

    liveswitchVideoCallManager.track('PortalCallEnded', {
      organizationId: liveswitchVideoCallManager.me.organizationId,
      employeeId: liveswitchVideoCallManager.me.employee.id,
      customerId: liveswitchVideoCallManager.customer.id,
      sessionId: liveswitchVideoCallManager.config.sessionId,
      callType: liveswitchVideoCallManager.state.callType,
      type: 'local'
    })
    return await this.leave()
  }

  onPopupClose = async () => {
    const { liveswitchVideoCallManager } = this.context

    liveswitchVideoCallManager.setState({
      callState: CALL_STATES.CLOSED,
      error: false
    })

    return await this.leave()
  }

  getVideoContainer() {
    if (this.videoContainer) {
      return this.videoContainer
    }
    return null
  }

  setupLayout() {
    const { liveswitchVideoCallManager } = this.context
    const { localMedia, remoteMedia } = liveswitchVideoCallManager

    const videoContainer = this.getVideoContainer()
    if (!videoContainer) return

    if (this.layoutManager != null) {
      this.layoutManager.removeRemoteViews()
      this.layoutManager.unsetLocalView()
      this.layoutManager = null
    }

    if (!this.layoutManager) {
      this.layoutManager = new liveswitch.DomLayoutManager(videoContainer)
      window.liveswitchLayoutManager = this.layoutManager
    }

    const localView = localMedia.getView()
    const remoteView = remoteMedia ? remoteMedia.getView() : null

    if (localView && !this.layoutManager.getLocalView()) {
      this.layoutManager.setLocalView(localView)
    }

    if (remoteView != null) {
      this.layoutManager.addRemoteView(remoteMedia.getId(), remoteView)
    }
  }

  closeCallEnded = () => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.setState({
      callType: 'VIDEO',
      callState: CALL_STATES.CLOSED,
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      remoteApplyingSettings: false,
      maximized: true,
      showCallEnded: false
    })
    this.resetState()
  }

  onRecallAudio = () => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.recallAudio()
  }

  onRecallVideo = () => {
    const { liveswitchVideoCallManager } = this.context
    liveswitchVideoCallManager.recallVideo()
  }

  render() {
    const {
      error,
      videoMuted,
      audioMuted,
      remoteAudioMuted,
      remoteVideoMuted,
      remoteApplyingSettings,
      maximized,
      showCallEnded,
      remoteCallState,
      callType,
      callState
    } = this.state
    const { liveswitchVideoCallManager, intl: { formatMessage } } = this.context
    const maximizedClass = !maximized ? 'minimized' : '';

    const CONNECTION_OK = this.isConnectionOk(callState)
    const CONNECTION_ERROR = this.isConnectionError(callState)
    const REMOTE_VIDEO_MUTED = this.isRemoteVideoMuted(CONNECTION_OK, remoteVideoMuted)
    const REMOTE_AUDIO_MUTED = this.isRemoteAudioMuted(CONNECTION_OK, remoteAudioMuted)
    const APPLYING_SETTINGS = this.isRemoteAppliedSetting(CONNECTION_OK, remoteApplyingSettings)
    const STREAM_FAILURE_RECONNECTING = this.isStreamFailureReconnecting(CONNECTION_ERROR, callState, error)
    const REMOTE_JOINED = this.hasRemoteJoined(callState)
    const ESTABLISHING_CONNECTION = this.checkConnectionEstablishing(callState)
    const CONNECTION_LOST = this.isConnectionLost(CONNECTION_ERROR, error)
    const CLOSING = this.isCallClosing(callState, error)
    const AUDIO_ONLY = this.isCallAudioOnly(callType)
    const SHOW_POPUP_BACK = this.showPopupBackground(CONNECTION_ERROR, STREAM_FAILURE_RECONNECTING,
      ESTABLISHING_CONNECTION, CLOSING, maximized)

    if (this.isActiveCallGoingOn(liveswitchVideoCallManager, callState, showCallEnded)) {
      return null
    }

    if (remoteCallState.toLowerCase() == 'leave') {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.left.title'))}
            message={formatMessage(T('videocall.patient.left'))}
          >
            <RecallButton onClick={this.leave} className="btn" data-qa="videocall-close">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (remoteCallState.toLowerCase() == 'declined') {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.declined.title'))}
            message={formatMessage(T(`${AUDIO_ONLY ? 'audiocall.patient.declined' : 'videocall.patient.declined'}`))}
          >
            <RecallButton onClick={this.leave} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (callState === CALL_STATES.TIMED_OUT) {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.na'))}
            message={formatMessage(T('popup_noconnection.message'))}
          >
            <RecallButton onClick={this.leave} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (showCallEnded || (CONNECTION_LOST && !error)) {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.ended'))}
            message={formatMessage(T('videocall.endmessage'))}
          >
            <RecallButton onClick={this.closeCallEnded} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    return (
      <VideoCallWrapper className={maximizedClass} data-qa="videocall-popup">
        {SHOW_POPUP_BACK && <PopupBackground />}
        {REMOTE_JOINED &&
          <VideoContainer
            data-qa="videocall"
            id="videocall"
            ref={x => (this.videoContainer = x)}
            className={maximizedClass}
          >
            {AUDIO_ONLY &&
              <VideoInfoOverlay
                className={maximizedClass}
                message={formatMessage(T('videocall.audioonly'))}
              />}
            {REMOTE_VIDEO_MUTED &&
              <VideoInfoOverlay
                className={maximizedClass}
                message={formatMessage(T('videocall.cam.off'))}
              />}
            {REMOTE_AUDIO_MUTED &&
              <VideoInfoOverlay
                className={maximizedClass}
                message={formatMessage(T('videocall.audio.off'))}
              />}
            {APPLYING_SETTINGS &&
              <VideoInfoOverlay
                className={maximizedClass}
                message={`Your patient currently applies a remote tuning and won't hear you`}
              />}
          </VideoContainer>}
        {REMOTE_JOINED &&
          <VideoButtonsWrapper
            audioMuted={audioMuted}
            videoMuted={videoMuted}
            maximized={maximized}
            maximizedClass={maximizedClass}
            audioOnly={AUDIO_ONLY}
            toggleMaximized={this.toggleMaximized}
            toggleVideoMute={this.toggleVideoMute}
            toggleAudioMute={this.toggleAudioMute}
            leaveLocalIntent={this.leaveLocalIntent}
          />}
        {STREAM_FAILURE_RECONNECTING && <Popup message={`StreamError. Reconnecting ...`} />}
        {ESTABLISHING_CONNECTION &&
          <Popup
            message={formatMessage(T('videocall.stabls'))}
            loading={true}
            title={
              <span>
                <PopupIcon src={headsetIcon} />
                {formatMessage(T('videocall.headset'))}
              </span>
            }
          >
            <ActionButton
              onClick={this.leaveLocalIntent}
              className={`red hangup fas fa-phone`}
              data-qa="videocall-hangup"
            />
          </Popup>}

        {error && <ErrorPopup onPopupClose={this.onPopupClose} error={error} />}
      </VideoCallWrapper>
    )
  }

  showPopupBackground(CONNECTION_ERROR, STREAM_FAILURE_RECONNECTING, ESTABLISHING_CONNECTION, CLOSING, maximized) {
    return (CONNECTION_ERROR ||
      STREAM_FAILURE_RECONNECTING ||
      ESTABLISHING_CONNECTION ||
      CLOSING) &&
      !maximized
  }

  isStreamFailureReconnecting(CONNECTION_ERROR, callState, error) {
    return (CONNECTION_ERROR || callState === CALL_STATES.RECONNECTING) &&
      error.type === 'stream_failure'
  }

  checkConnectionEstablishing(callState) {
    return [
      CALL_STATES.CONNECTING,
      CALL_STATES.CONNECTED,
      CALL_STATES.JOINED
    ].indexOf(callState) > -1
  }

  isCallAudioOnly(callType) {
    return callType == 'AUDIO'
  }

  isCallClosing(callState, error) {
    return callState === CALL_STATES.CLOSING && !error
  }

  isConnectionLost(CONNECTION_ERROR, error) {
    return CONNECTION_ERROR && error.type === 'connection_lost'
  }

  hasRemoteJoined(callState) {
    return callState === CALL_STATES.REMOTE_JOINED
  }

  isRemoteAppliedSetting(CONNECTION_OK, remoteApplyingSettings) {
    return CONNECTION_OK && remoteApplyingSettings
  }

  isRemoteAudioMuted(CONNECTION_OK, remoteAudioMuted) {
    return CONNECTION_OK && remoteAudioMuted
  }

  isRemoteVideoMuted(CONNECTION_OK, remoteVideoMuted) {
    return CONNECTION_OK && remoteVideoMuted
  }

  isConnectionError(callState) {
    return callState === CALL_STATES.ERROR
  }

  isConnectionOk(callState) {
    return [
      CALL_STATES.REMOTE_JOINED,
      CALL_STATES.CONNECTED,
      CALL_STATES.JOINED
    ].indexOf(callState) > -1 &&
      callState !== CALL_STATES.CLOSED &&
      callState !== CALL_STATES.CLOSING &&
      callState !== CALL_STATES.ERROR
  }

  isActiveCallGoingOn(liveswitchVideoCallManager, callState, showCallEnded) {
    return !liveswitchVideoCallManager.isActive() &&
      callState !== CALL_STATES.TIMED_OUT &&
      callState !== CALL_STATES.ERROR &&
      callState !== CALL_STATES.CONNECTED &&
      !showCallEnded
  }
}

export const IHATEIE = () => {
  return (
    <iframe
      src="about:blank"
      frameBorder="0"
      style={{
        border: '0px currentColor',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'none transparent',
        pointerEvents: 'none'
      }}
      allowtransparency="true"
    />
  )
}

export const VideoButtonsWrapper = ({
  videoMuted,
  audioMuted,
  maximized,
  maximizedClass,
  audioOnly,
  toggleMaximized,
  toggleVideoMute,
  toggleAudioMute,
  leaveLocalIntent
}) => {
  const audioMutedClass = audioMuted ? 'fas fa-microphone-slash red' : 'fas fa-microphone';
  const videoMutedClass = videoMuted ? 'fas fa-video-slash red' : 'fas fa-video';
  const buttonMaximizedClass = maximized ? 'fas fa-compress-alt' : 'fas fa-expand-alt';
  return (
    <VideoButtons className={maximizedClass}>
      <IHATEIE />
      <div>
        <ActionButton
          onClick={toggleMaximized}
          className={buttonMaximizedClass}
        />
        {maximized &&
          !audioOnly &&
          <ActionButton
            onClick={toggleVideoMute}
            className={videoMutedClass}
          />}
        {maximized &&
          <ActionButton
            onClick={toggleAudioMute}
            className={audioMutedClass}
          />}
        <ActionButton
          onClick={leaveLocalIntent}
          className={`red hangup fas fa-phone`}
          data-qa="videocall-hangup"
        />
      </div>
    </VideoButtons>
  )
}
