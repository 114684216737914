import React from 'react'
import { injectIntl } from 'react-intl'
import PopupSuccessComponent from 'components/Popups/SuccessPopup'

const ConfirmationPopup = ({ intl, showSuccessPopup, onClick }) => {
  return (
    showSuccessPopup &&
    <div className="popup">
      <div className="popup-inner">
        <PopupSuccessComponent intl={intl} shouldHide={onClick} />
      </div>
    </div>
  )
}

export default injectIntl(ConfirmationPopup)
