import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import _ from 'lodash'
import Form from './Form'
import { getLocales } from 'helper/constants'
import { LOCALE_QUERY } from './../../graphql/queries/LocaleQuery'
import { MUTATION_UPLOAD_AVATAR_EMPLOYEE } from './../../graphql/mutations/UploadEmployeeAvatarMutation'
import { MUTATION_UPDATE_EMPLOYEE, MUTATION_UPDATE_EMPLOYEE_PHONE_VERIFICATION_STATUS, MUTATION_UPDATE_EMPLOYEE_EMAIL_VERIFICATION_STATUS } from './../../graphql/mutations/UpdateEmployeeMutation'
import { QUERY_EMPLOYEE_EDIT } from './../../graphql/queries/EmployeeEditPageQuery'
import { FETCH_ALL_COUNTRY_CALLING_CODES_QUERY } from './../../graphql/queries/CountryCallingCodesQuery'
import { MUTATION_DELETE_AVATAR_EMPLOYEE } from './../../graphql/mutations/DeleteEmployeeAvatarMutation'
import { MUTATION_CHANGE_PASSWORD } from './../../graphql/mutations/ChangePasswordMutation'
import { withRouter } from "helper/withRouter";
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'

const mapProps = ({
  data: { refetch, loading, Me, Employee, AssignedPractices = [], NotificationTimeOptions },
  ownProps: { locale, params, intl }
}) => {
  const id = parseInt(params.id, 10)
  const sortedLocales = getLocales()
  const locales = Object.keys(sortedLocales).map(countryCode => ({
    countryCode,
    value: sortedLocales[countryCode]
  }))
  return {
    refetch,
    loading,
    practices: AssignedPractices,
    employeeId: id,
    employee: Employee,
    practicesOptions: AssignedPractices.map(item => ({
      label: item.name,
      value: item.id
    })),
    formTitle: 'employeeEdit.title',
    submitLabel: 'common.save',
    isManager: _.get(Me, `employee.manager`, false),
    isOrgManager: _.get(Me, `employee.orgManager`, false),
    isMe: _.get(Me, `employee.id`, '') === id,
    countryCode: _.get(Me, 'countryCode', ''),
    locale,
    locales,
    intl,
    NotificationTimeOptions,
    organizationId: _.get(Me, `organizationId`, false),
  }
}

export const options = ({ params }) => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    id: params && parseInt(params.id),
    orgId: parseInt(getOrganizationIdFromCache())
  },
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_EMPLOYEE_EDIT, {
    props: mapProps,
    options
  }),
  graphql(FETCH_ALL_COUNTRY_CALLING_CODES_QUERY, {
    props: ({ data: { CountryCallingCodes, loading } }) => {
      return {
        CountryCallingCodes: CountryCallingCodes,
        countryCodeLoading: loading
      }
    },
  }),
  graphql(MUTATION_UPDATE_EMPLOYEE, {
    props: ({ mutate }) => ({
      updateEmployee: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPDATE_EMPLOYEE_PHONE_VERIFICATION_STATUS, {
    props: ({ mutate }) => ({
      updateEmployeePhoneVerificationStatus: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPDATE_EMPLOYEE_EMAIL_VERIFICATION_STATUS, {
    props: ({ mutate }) => ({
      updateEmployeeEmailVerificationStatus: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_CHANGE_PASSWORD, {
    props: ({ mutate }) => ({
      changePassword: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPLOAD_AVATAR_EMPLOYEE, {
    props: ({ mutate }) => ({
      uploadEmployeeAvatar: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_DELETE_AVATAR_EMPLOYEE, {
    props: ({ mutate }) => ({
      deleteEmployeeAvatar: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(LOCALE_QUERY, {
    props: ({ data: { locale } }) => ({
      locale: locale.locale
    })
  }),
  injectIntl
)

const EmployeeEditPage = withRouter(withData(Form))

export default EmployeeEditPage
