import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { T } from 'core'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../helper/withQuery'
import { WrapSelector } from 'styledComponents/Div/DivComponent/DivComponent'
import { Select } from 'styledComponents/Select'
import { countries as countryConstants, countryToLocale } from 'helper/constants'

class TermsPage extends Component {
  state = {
    contents: '<div />'
  }

  componentDidMount() {
    this.fetchContents(this.props.legalCountry)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.legalCountry !== this.props.legalCountry) {
      this.fetchContents(nextProps.legalCountry)
    }
  }

  fetchContents(countryCode = 'us') {
    countryCode = countryCode.toLowerCase()

    if (countryCode !== this.props.queryCountry) {
      this.props.navigate({
        pathname: '/terms',
        search: `?country=${countryCode}`
      })
    }
    this.setState(
      {
        contents: '<div />' //reset contents before fetching new
      },
      () => {
        const locale = countryToLocale[countryCode.toUpperCase()] || 'en'
        fetch(`/assets/legal/terms/${locale}.html`).then(response => {
          if (response.status === 404) {
            return this.onChange({ target: { value: 'gb' } }) // emulate onChange event
          }
          response.text().then(data => {
            this.setState({
              contents: data
            })
          })
        })
      }
    )
  }

  onChange = e => {
    const { value } = e.target
    const { pathname } = this.props.location
    this.props.navigate({
      pathname: pathname,
      search: `?country=${value}`
    })
  }

  getSortedCountries = () => {
    const { countries, intl: { formatMessage } } = this.props
    const unsortedCountries = countries && countries.map(countryCode => (
      {
        countryCode,
        countryName: formatMessage(T('countryName' + countryCode))
      }
    ))
    const sortedCountries = _.orderBy(unsortedCountries, [country => country.countryName.toLowerCase()], ['asc'])
    return sortedCountries
  }

  render() {
    const { intl: { formatMessage }, legalCountry = '' } = this.props
    const sortedCountries = this.getSortedCountries()
    return (
      <div className="container">
        <p>
          {formatMessage(T('common.country'))}
        </p>
        <WrapSelector>
          <Select
            value={legalCountry}
            data-qa="legalCountry"
            onChange={this.onChange}
          >
            {sortedCountries.map(country =>
              <option key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </option>
            )}
          </Select>
        </WrapSelector>
        <div
          style={{ marginTop: '20px' }}
          dangerouslySetInnerHTML={{ __html: this.state.contents }}
        />
      </div>
    )
  }
}

const mapProps = ({ data: { loading, Countries = [] }, ownProps: { query, intl } }) => {
  const stateCountries = Countries
  const countries = stateCountries
    .filter(item => item.enableHcpRegistration)
    .map(item => item.countryCode)
    .filter(countryCode => !!countryConstants[countryCode])

  return {
    loading,
    countries,
    legalCountry: query.country.toUpperCase(),
    queryCountry: query.country,
    intl
  }
}

const QUERY_PRIVACY_PAGE = gql`
  query {
    Countries {
      id
      countryCode
      enableHcpRegistration
      zipCodeValidation
    }
  }
`

const options = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: { country: query.country }
})

const withData = compose(
  graphql(QUERY_PRIVACY_PAGE, {
    props: mapProps,
    options
  }),
  injectIntl
)

export default withRouter(withQuery(withData(TermsPage)))
