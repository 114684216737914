import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { withRouter } from "helper/withRouter";
import { Wrapper } from 'styledComponents/Div/DivComponent/DivHelpCenter'
import { withQuery } from '../../../helper/withQuery'
import DataContext from "containers/DataContext"
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'
import { Outlet } from 'react-router-dom';

export class Faq extends Component {
  static contextType = DataContext;

  render() {
    const { location } = this.props
    const { locale } = this.context
    return (
      <div className="container">
        <Wrapper className="row">
          <div>
            <StyledButtonLink
              $buttontertiarystyle
              to={`/help/faq/commonfaq?locale=${locale}`}
              className={location.pathname === '/help/faq/commonfaq'
                ? 'active'
                : ''}
            >
              <FormattedMessage {...T('helpCenter.commonlyAskedQuestions')} />
            </StyledButtonLink>
          </div>
          <div>
            <StyledButtonLink
              $buttontertiarystyle
              to={`/help/faq/orderedfaq?&locale=${locale}`}
              className={location.pathname === '/help/faq/orderedfaq'
                ? 'active'
                : ''}
            >
              <FormattedMessage {...T('helpCenter.questionsBycategory')} />
            </StyledButtonLink>
          </div>
        </Wrapper>
        <article className="faq__margin">
          <main>
            <Outlet />
          </main>
        </article>
      </div>
    )
  }
}

export default withRouter(withQuery(Faq))
