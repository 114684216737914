import messages from 'i18n/messages'

export const T = id => {
  const message = Object.keys(messages)
    .filter(key => messages[key].id == id)
    .map(key => {
      return {
        ...messages[key],
        key
      }
    })
    .pop()

  if (!message) return { defaultMessage: id, id }
  return message
}
