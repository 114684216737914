import { graphql } from '@apollo/client/react/hoc';
import React, { Component, lazy, Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import { T } from 'core'
import _ from 'lodash'
import * as Panel from 'components/_Profile/components/Panel'
import Loading from 'components/Loading'
import { isAuthenticated as isAuthenticatedHelper } from 'helper/AuthHelper'
import { getFlagSettings, updateFFlag } from 'helper/FFlags'
import TeCaImprovementProgramConsentModal from 'components/Popups/TeCaImprovementProgramConsentModal'
import { isUserOptedForDataConsent } from 'helper/UserDataConsentUtils'
import MarkdownPopupModal from '../../components/Popups/MarkdownPopupModal'
import { SHOW_POPUP_QUERY, UPDATE_OVERLAY } from 'graphql/queries/ShowPopUpQuery'
import { APP_QUERY } from 'graphql/queries/AppQuery'
import {
  CONFIRMATION_POPUP,
  SHOW_POPUP,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'
import queryString from 'querystring-es3'
import { withRouter } from 'helper/withRouter'
import { withQuery } from '../../helper/withQuery'
import PatientsPage from "containers/PatientsList/Patients"
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { patientsListProductTourSteps, withTourGuide } from 'tourGuide'
import HcpContactVerificationNotifcationModal from 'components/Popups/HcpContactVerificationNotifcationModal'
import * as hcpNotificationHelper from '../../helper/HcpContactVerificationNotifcationHelper'

const Filters = lazy(() => import('./Filters'))

/** Available filters
 * @practiceId,
 * @employeeId
 */
export class PatientsList extends Component {
  _defaultFilters = {
    practiceId: '',
    employeeId: '',
    patientType: ''
  }

  _filters = this._defaultFilters
  _fflags = null
  constructor() {
    super()

    this.state = {
      initialized: false,
      showMajorChangePopup: false,
      showDataConsentPopup: false,
      currentUser: null,
      isAuthenticated: isAuthenticatedHelper()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (localStorage.getItem('passwordExpired')) {
      this.props.navigate('/password/expire')
    }

    if (nextProps.data && nextProps.data.Me && nextProps.data.Me.employee) {
      if (nextProps.data.Me.employee.id) {
        const nextUser = nextProps.data.Me.employee
        if (!this.state.currentUser || nextUser.id !== this.state.currentUser.id) {
          this.setState({ currentUser: nextUser })
          getFlagSettings(nextUser.id).then(({ items }) => {
            if (!items.callError) {
              // only attempt to change state if there is no call error from LaunchDarkly.
              try {
                const isTeCaImprovementPopupShown = this.isTeCaImprovementPopupShown()
                this.setState({
                  showMajorChangePopup: items['major-change-popup']._value,
                  showDataConsentPopup: items['show-data-collection-popup']._value && !isTeCaImprovementPopupShown
                })
              } catch (e) { } // no problem if the items array is erroneus, then we can live with the default values.
            }
          })
        }
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { isAuthenticated, data } = nextProps
    const { initialized } = nextState
    const { loading, Me } = data
    if (!isAuthenticated) {
      this.props.navigate('/login')
    } else if (!loading && !initialized) {
      this.setState({ initialized: !!Me })
      this.init(nextProps)
    }
    this.setFiltersWithQuery(nextProps)
  }

  componentDidUpdate() {
    this.update()
  }

  init(nextProps) {
    const { query, data: { Me } } = nextProps
    const hasFilters = Object.keys(_.omit(query, 'sort')).length > 0

    if (!hasFilters) {
      this.initializeByEmployee(Me)
    }
  }

  initializeByEmployee(Me) {
    const { location } = this.props

    const practiceId = _.get(Me, 'employee.defaultPracticeId', '')
    const query = _.pickBy({ practiceId })
    location.search = queryString.stringify(query)
    this.props.navigate(location)
  }

  /**
   * Get query from URL and autofill the dropdown filters
   */
  update() {
    const { showMajorChangePopup } = this.state
    const { overlay } = this.props
    if (overlay && !overlay.popup && !overlay.isOpen && showMajorChangePopup) {
      const payload = {
        title: 'releasenotes-popup-title',
        message: 'releasenotes-popup-content'
      }
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: CONFIRMATION_POPUP,
        payload
      })
      this.props.updateOverlay(popup, null, true)
    }
  }

  setFiltersWithQuery(nextProps) {
    const { query } = nextProps

    this._filters = _.omit({ ...this._defaultFilters, ...query }, ['sort', 'page'])
  }

  onFilterChange = query => {
    const { location } = this.props
    location.search = queryString.stringify(_.pickBy(query))
    this.props.navigate(location)
  }

  clearFilters = () => {
    const { location } = this.props
    const query = this._defaultFilters
    location.search = queryString.stringify(query)
    this.props.navigate(location)
  }

  majorChangePopupClick = employeeId => () => {
    updateFFlag(employeeId, 'major-change-popup')
    this.setState({ showMajorChangePopup: false })
    this.props.updateOverlay(null, null, false)
  }

  updateIsTeCaImprovementPopupShown = () => {
    sessionStorage.setItem("isTeCaImprovementPopupShown", true)
  }

  isTeCaImprovementPopupShown = () => sessionStorage.getItem("isTeCaImprovementPopupShown") ?? false

  shouldShowContactVerificationNotificationPopup = (employee) => {
    const { id, email, emailVerified, phone, phoneVerified, username } = employee
    return hcpNotificationHelper.isUserEmailOrPhoneUnverified(id, email, emailVerified, phone, phoneVerified, username)
      && hcpNotificationHelper.getShowPopupInfoFromLocalStorage(id)
      && hcpNotificationHelper.getShowPopupInfoFromSessionStorage(id)
  }

  onHideContactVerificationNotificationPopup = (dontRemindAgain) => {
    const { id, emailVerified, phoneVerified } = this.props.data.Me.employee
    hcpNotificationHelper.setDontRemindAgainStatusInSessionStorage(id)
    dontRemindAgain && hcpNotificationHelper.setDontRemindAgainStatusInLocalStorage(id, emailVerified, phoneVerified)
  }

  render() {
    const { data, location, overlay, AppQueryloading, ShowPopUpQueryLoading, query } = this.props
    const { Me } = data
    const { initialized } = this.state
    const { s } = location.query || {}
    const searchQuery = s || ''
    const isFiltered = searchQuery || _.values(this._filters).join('').length > 0
    const defaultPracticeId = _.get(Me, 'employee.defaultPracticeId', null)

    const { showMajorChangePopup, showDataConsentPopup } = this.state
    if (AppQueryloading || ShowPopUpQueryLoading || !initialized || !defaultPracticeId) {

      return <Loading isLoading={AppQueryloading || ShowPopUpQueryLoading} />
    }
    return (
      <Panel.Base className="customer-overview-page" modifier="patients-overview">
        {overlay &&
          overlay.popup &&
          (showMajorChangePopup &&
            <MarkdownPopupModal
              icon={faInfo}
              onClick={this.majorChangePopupClick(Me.employee.id)}
            />)}
        <TeCaImprovementProgramConsentModal
          defaultPracticeId={defaultPracticeId}
          onClose={this.updateIsTeCaImprovementPopupShown}
          show={showDataConsentPopup && !isUserOptedForDataConsent()} />

        <HcpContactVerificationNotifcationModal
          show={this.shouldShowContactVerificationNotificationPopup(Me.employee)}
          onHide={this.onHideContactVerificationNotificationPopup}
        />

        <div className="container">
          {searchQuery &&
            <Panel.Item className="row">
              <h1 data-qa="search-title">
                <FormattedMessage {...T('customer_overview.searchingFor')} />: {searchQuery}
              </h1>
            </Panel.Item>}
          <h2>
            <FormattedMessage {...T('patient.lead-title')} />
          </h2>
          <Suspense fallback={<Loading />}>
            <Filters
              orgManager={Me.employee.orgManager}
              defaultPracticeId={defaultPracticeId}
              location={location}
              query={query}
              isFiltered={isFiltered}
              onChange={this.onFilterChange}
              clearFilters={this.clearFilters}
            />
          </Suspense>
          <PatientsPage me={Me} />
        </div>
      </Panel.Base>
    )
  }
}

const mapPropsOfPopUpQuery = ({ ShowPopUpQuery: { overlay, loading } }) => {
  return { overlay, ShowPopUpQueryLoading: loading, isAuthenticated: isAuthenticatedHelper() }
}

const mapPropsOfAppQuery = ({
  AppQuery: { loading },
  AppQuery,
}) => {
  return {
    data: AppQuery,
    AppQueryloading: loading
  }
}

const withData = compose(
  graphql(APP_QUERY, {
    name: 'AppQuery',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first'
    }),
    props: mapPropsOfAppQuery
  }),
  graphql(SHOW_POPUP_QUERY, {
    name: 'ShowPopUpQuery',
    props: mapPropsOfPopUpQuery
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  })
)

export default withRouter(withTourGuide(withQuery(withData(PatientsList)), patientsListProductTourSteps))
