import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { omit } from 'lodash'
import { T } from 'core'
import { DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import KnobWithTitle from 'components/KnobWithTitleComponent'
import { notEqual } from 'helper/Utils'
import { Button } from 'styledComponents/Button'
import {
  StyledSelect_MarketingMessages,
  FilterOnBlock,
  ActionButtonsDropdown
} from 'styledComponents/Div/DivComponent/DivDropdowns'

export class MarketingPracticeFilter extends React.Component {
  static displayName = 'MarketingPracticeFilter'

  static defaultProps = {
    availablePractices: [],
    selectedPractices: [],
    onChange: Function,
    onOpenFilter: Function,
    isFilterOpen: false
  }

  state = {
    knobActive: true,
    isSelectorDisabled: true,
    selectedPractices: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      notEqual(this.state.selectedPractices, nextProps.selectedPractices)
      // this.state.knobActive !== nextProps.selectedPractices.length < 1
    ) {
      this.setState({
        selectedPractices: nextProps.selectedPractices,
        knobActive: nextProps.selectedPractices.length < 1 // all practices are selected
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cleanNextProps = omit(nextProps, ['onChange', 'onOpenFilter'])
    const cleanProps = omit(this.props, ['onChange', 'onOpenFilter'])
    return notEqual(cleanNextProps, cleanProps) || notEqual(nextState, this.state)
  }

  onKnobChange = () => {
    this.setState({
      knobActive: !this.state.knobActive,
      isSelectorDisabled: !this.state.knobActive,
      selectedPractices: !this.state.knobActive ? [] : this.state.selectedPractices
    })
  }

  onApplyFilter = () => {
    const selectedPractices = this.state.selectedPractices
    this.props.onChange({ selectedPractices })
    this.onClose()
  }

  onOpenFilter = () => {
    this.props.onOpenFilter(!this.props.isFilterOpen)
  }

  onClose = () => {
    this.setState(
      {
        isFilterActive: this.state.selectedPractices.length > 0 && !this.state.knobActive
      },
      () => {
        this.props.onOpenFilter(false)
      }
    )
  }

  onCancel = () => {
    this.onClose()
  }

  getOptions() {
    const options = this.props.availablePractices.map(item => ({
      label: item.name,
      value: item.id
    }))
    const selectedIds = this.state.selectedPractices.reduce(
      (arr, item) => [...arr, parseInt(item.value, 10)],
      []
    )

    return options.filter(item => selectedIds.indexOf(item.value) === -1)
  }

  onSelectedPractices = practice => {
    const newPractices = [...(practice || [])]
    this.setState({
      selectedPractices: newPractices
    })
  }

  getFilterTitle = () => {
    const { intl: { formatMessage } } = this.props
    const { selectedPractices = [] } = this.state
    const value = `( ${selectedPractices.length > 0
      ? selectedPractices.length
      : formatMessage(T('common.all'))} )`
    return <FormattedMessage {...T('marketing.messages.menu-practice-all')} values={{ 0: value }} />
  }

  render() {
    const { intl: { formatMessage }, isFilterOpen } = this.props
    const { knobActive, isSelectorDisabled, selectedPractices, isFilterActive } = this.state
    const title = this.getFilterTitle()
    const knobTitle = <FormattedMessage {...T('marketing.messages.all-practices')} />
    const options = this.getOptions()
    const canApplyFilter = true
    const isFilterActiveClassName = isFilterActive ? 'filterIsActive' : ''

    return (
      <FilterOnBlock>
        <DropdownButton
          variant={'default'}
          rootCloseEvent={'click'}
          title={title}
          id={`practicesFilter`}
          data-qa="practicesFilter"
          show={isFilterOpen}
          onToggle={isOpen => {
            this.onOpenFilter(isOpen)
          }}
          className={isFilterActiveClassName}
        >
          <KnobWithTitle title={knobTitle} onToggle={this.onKnobChange} active={knobActive} />
          <StyledSelect_MarketingMessages disabled={isSelectorDisabled}>
            <Select
              closeMenuOnSelect={true}
              isDisabled={isSelectorDisabled}
              name="assignedPracticeIds"
              isMulti={true}
              placeholder={formatMessage(T('practices.select_placeholder'))}
              isSearchable={true}
              isClearable={true}
              options={options}
              value={selectedPractices}
              onChange={this.onSelectedPractices}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'neutral0'
                }
              })}
              maxMenuHeight={80}
              menuPlacement="auto"
            />
          </StyledSelect_MarketingMessages>
          <ActionButtonsDropdown>
            <Button secondary onClick={this.onCancel} data-qa="cancel-button">
              {formatMessage(T('common.cancel'))}
            </Button>
            <Button
              type="submit"
              primary
              disabled={!canApplyFilter}
              data-qa="select-button"
              onClick={this.onApplyFilter}
            >
              {formatMessage(T('common.select'))}
            </Button>
          </ActionButtonsDropdown>
        </DropdownButton>
      </FilterOnBlock>
    )
  }
}

export default injectIntl(MarketingPracticeFilter)
