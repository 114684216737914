import { getToken } from './TokenUtil'
import { connector } from './Connector'

export const loadChatMessages = (patientId, pageNumber, size) => {
  const params = { patientId, pageNumber, size }
  try {
    return connector
      .get(getToken(), `/api/messages`, params)
      .then(response => response)
  } catch (e) {
    console.log('error occured while loading ChatMessages', e)
    return null
  }
}
