import React from 'react'
import DetectRTC from 'detectrtc'
import { T } from 'core'
import { CallButtonBase } from 'styledComponents/Span'
import { CallControls } from 'styledComponents/Div/DivComponent/DivVideoCall'
import Tooltip from 'components/Tooltip'
import { isAppSupported } from '../shared'
import DataContext from "containers/DataContext"
import { IcelinkLib, LiveswitchLib } from 'helper/constants'

window.DetectRTC = DetectRTC

export class VideoCallButtons extends React.Component {
	static contextType = DataContext;

	state = {
		isWebRTCSupported: false,
		hasMicrophone: false,
		hasWebcam: false,
		hasSpeakers: false,
		browserName: ''
	}

	UNSAFE_componentWillMount() {
		this.detectRTC()
		const { videoCallManager, liveswitchVideoCallManager, customer: { compatibleVideoLib } } = this.context
		if (compatibleVideoLib === IcelinkLib) {
			videoCallManager.on('change', this.onVideoCallManagerUpdate)
		} else {
			liveswitchVideoCallManager.on('change', this.onVideoCallManagerUpdate)
		}
	}

	componentDidMount() {
		const { videoCallManager, liveswitchVideoCallManager, customer: { compatibleVideoLib } } = this.context
		if (compatibleVideoLib === IcelinkLib) {
			videoCallManager.init()
		} else {
			liveswitchVideoCallManager.init()
		}
	}

	componentWillUnmount() {
		const { videoCallManager, liveswitchVideoCallManager, customer: { compatibleVideoLib } } = this.context
		if (compatibleVideoLib === IcelinkLib) {
			videoCallManager.off('change', this.onVideoCallManagerUpdate)
		} else {
			liveswitchVideoCallManager.off('change', this.onVideoCallManagerUpdate)
		}
	}

	UNSAFE_componentWillReceiveProps() {
		this.detectRTC()
	}

	onVideoCallManagerUpdate = () => {
		this.setState({})
	}

	detectRTC() {
		DetectRTC.load(() => {
			this.setState({
				isWebRTCSupported: DetectRTC.isWebRTCSupported,
				hasWebcam: DetectRTC.hasWebcam,
				hasMicrophone: DetectRTC.hasMicrophone,
				hasSpeakers: DetectRTC.hasSpeakers,
				browserName: DetectRTC.browser.name
			})
		})
	}

	startVideoCall = async () => {
		if (!this.isVideoPossible()) return
		if (!this.state.hasWebcam) {
			const msg = this.getAlertErrorMessage()
			alert(msg)
			return
		}
		const { videoCallManager, liveswitchVideoCallManager, token, customer, me } = this.context
		if (customer.compatibleVideoLib === IcelinkLib) {
			await videoCallManager.startVideoCall({ customer, token, me })
		} else {
			await liveswitchVideoCallManager.startVideoCall({ customer, token, me })
		}
	}

	startAudioOnly = async () => {
		if (!this.isAudioPossible()) return
		if (
			!this.state.hasMicrophone ||
			(!this.state.hasSpeakers &&
				(this.state.browserName === 'Chrome' || this.state.browserName === 'Edge'))
		) {
			const msg = this.getAlertErrorMessage()
			alert(msg)
			return
		}
		const { videoCallManager, liveswitchVideoCallManager, token, customer, me } = this.context
		if (customer.compatibleVideoLib === IcelinkLib) {
			await videoCallManager.startAudioOnly({ customer, token, me })
		} else {
			await liveswitchVideoCallManager.startAudioOnly({ customer, token, me })
		}
	}

	isMobileOSSupported(type = '', version = '0') {
		if (type.toLowerCase() === 'ios' && (!version || parseInt(version) < 10)) {
			return false
		}
		if (type.toLowerCase() === 'android' && (!version || parseInt(version) < 5)) {
			return false
		}
		return true
	}

	isAudioPossible() {
		const { customer = {} } = this.context
		const { mobileType = '', osVersion } = customer

		if (!this.isMobileOSSupported(mobileType, osVersion)) {
			return false
		}

		return isAppSupported(customer)
	}

	isVideoPossible() {
		return this.isAudioPossible()
	}

	getAudioToolTip() {
		const { formatMessage } = this.context.intl
		const { customer = {} } = this.context
		const { hearingDevices = {}, mobileType = '', osVersion } = customer
		const { capabilities = false } = hearingDevices
		const { appConnected = false } = capabilities

		if (!appConnected) {
			return formatMessage(T('videocall.tooltip.app.notconnected'))
		}

		if (!this.isMobileOSSupported(mobileType, osVersion)) {
			return formatMessage(T('videocall.ios.version'))
		}

		if (!DetectRTC.isWebRTCSupported) {
			return formatMessage(T('videocall.tooltip.webrtc.not.supported'))
		}

		if (!DetectRTC.hasMicrophone) {
			return formatMessage(T('videocall.tooltip.audio.nomic'))
		}

		if (
			!DetectRTC.hasSpeakers &&
			(DetectRTC.browser.name === 'Chrome' || DetectRTC.browser.name === 'Edge')
		) {
			return formatMessage(T('videocall.tooltip.audio.nospeakers'))
		}

		if (this.isAudioPossible()) {
			return formatMessage(T('videocall.tooltip.audio.possible'))
		}

		return formatMessage(T('videocall.tooltip.notpossible'))
	}

	getVideoToolTip() {
		const { formatMessage } = this.context.intl
		const { customer = {} } = this.context
		const { hearingDevices = {}, mobileType = '', osVersion } = customer
		const { capabilities = false } = hearingDevices
		const { appConnected = false } = capabilities

		if (!appConnected) {
			return formatMessage(T('videocall.tooltip.app.notconnected'))
		}

		if (!this.isMobileOSSupported(mobileType, osVersion)) {
			return formatMessage(T('videocall.ios.version'))
		}

		if (!DetectRTC.isWebRTCSupported) {
			return formatMessage(T('videocall.tooltip.webrtc.not.supported'))
		}

		if (!DetectRTC.hasWebcam) {
			return formatMessage(T('videocall.tooltip.video.nocam'))
		}

		if (this.isVideoPossible()) {
			return formatMessage(T('videocall.tooltip.video.possible'))
		}

		return this.getAudioToolTip()
	}

	getAlertErrorMessage() {
		const { formatMessage } = this.context.intl
		return formatMessage(T('browser.no-media-access'))
	}

	render() {
		const { videoCallManager, liveswitchVideoCallManager, customer: { compatibleVideoLib } } = this.context

		if ((compatibleVideoLib === IcelinkLib && !videoCallManager.state.inited) || 
			(compatibleVideoLib === LiveswitchLib && !liveswitchVideoCallManager.state.inited)) {
			// do not render anything if the callmanager wasn't inited
			return null
		}

		const isAudioPossible = this.isAudioPossible()
		const isVideoPossible = this.isVideoPossible()

		const activeCall = (compatibleVideoLib === IcelinkLib && videoCallManager.isActive()) ||
						(compatibleVideoLib === LiveswitchLib && liveswitchVideoCallManager.isActive())
		return (
			<CallControls>
				<CallButtonBase
					className={`${!isAudioPossible || activeCall ? 'disabled' : ''} fas fa-phone`}
					onClick={this.startAudioOnly}
					data-tip
					data-for="AudioButton"
				>
					<Tooltip id="AudioButton" subtitle={this.getAudioToolTip()} place="top" />
				</CallButtonBase>
				<CallButtonBase
					className={`${!isVideoPossible || activeCall ? 'disabled' : ''} fas fa-video`}
					onClick={this.startVideoCall}
					data-tip
					data-for="VideoButton"
				>
					<Tooltip id="VideoButton" subtitle={this.getVideoToolTip()} place="top" />
				</CallButtonBase>
			</CallControls>
		)
	}
}

export default VideoCallButtons
