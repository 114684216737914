import moment from 'moment'

function parseDate(date_str) {
  if (date_str == null) return moment(new Date('1.1.1970'))
  return moment(date_str)
}

export const ConvertdDateTimeToLocalizeformatting = (utcTime, locale) => {
  let dateTime = new Date(utcTime);
  return new Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(dateTime)
    + "  " + new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(dateTime);
}

export const ConvertToFormattedDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

export const getTotalDaysBetweenTwoDate = (startDay, endOfDay) => {
  const start = moment(startDay, "YYYY-MM-DD");
  const end = moment(endOfDay, "YYYY-MM-DD");
  //Difference in number of days
  return end.diff(start, 'days')
}

export const getCurrentDateAsPerLocale = (locale) => {
  const currentDate = new Date().toLocaleDateString(locale)
  return currentDate
}

export const getDateTimeInfoFromNow = (utcTiime, locale) => {
  moment.locale(locale)
  return moment.utc(utcTiime).local().startOf('seconds').fromNow()
}

export { parseDate }