const isFunction = obj => typeof obj == 'function' || false

export class EventEmitter {
  constructor() {
    this.listeners = new Map()
  }
  addListener(label, callback) {
    this.listeners.has(label) || this.listeners.set(label, [])
    this.listeners.get(label).push(callback)
  }

  on(label, callback) {
    return this.addListener(label, callback)
  }

  removeListener(label, callback) {
    const listeners = this.listeners.get(label)
    let index = null

    if (listeners && listeners.length > 0) {
      index = listeners.reduce((i, listener, id) => {
        return isFunction(listener) && listener === callback ? (i = id) : i
      }, -1)

      if (index > -1) {
        listeners.splice(index, 1)
        this.listeners.set(label, listeners)
        return true
      }
    }
    return false
  }
  off(label, callback) {
    return this.removeListener(label, callback)
  }

  emit(label, ...args) {
    // console.log('emitting', label, ...args)
    const listeners = this.listeners.get(label)

    if (listeners && listeners.length) {
      listeners.forEach(listener => {
        listener(...args)
      })
      return true
    }
    return false
  }
  trigger(label, ...args) {
    return this.emit(label, ...args)
  }
}
