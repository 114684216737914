import React from 'react'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import { phoneRegex } from 'helper/constants'
import { isPhone } from 'helper/isValidByType'
import InputText from './InputText'

export class PhoneInput extends React.Component {
  static displayName = 'PhoneInput'

  static defaultProps = {
    countryCode: null,
    defaultValue: null
  }

  state = {
    phoneNumber: null,
    isPhoneValid: true
  }

  validatePhone = countryCode => value => {
    if (!value) {
      // validation is sync
      this.setState({
        isPhoneValid: true
      })
      this.props.onValidation(this.props.id, true)
      return true
    }
    if(!phoneRegex.test(value) && isPhone(value, countryCode)) {
      this.setState({
        isPhoneValid: true
      })
      this.props.onValidation(this.props.id, true)
      return true
    }
    this.props.onValidation(this.props.id, false)
    this.setState({
      isPhoneValid: false
    })
    return false
  }

  render() {
    const { countryCode,  intl: { formatMessage }, defaultValue = null, ...rest } = this.props
    const { phoneNumber = null, isPhoneValid } = this.state

    return (
      <InputText
        helpText={formatMessage(T('validator.phone'))}
        label={formatMessage(T('common.mobileNumber'))}
        defaultValue={phoneNumber || defaultValue}
        placeholder={formatMessage(T('common.mobileNumber'))}
        requirements={[this.validatePhone(countryCode)]}
        {...rest}
        isValid={isPhoneValid}
      />
    )
  }
}

export default injectIntl(PhoneInput)
