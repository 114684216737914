import { gql } from "@apollo/client";
export const QUERY_ASSISTANT_HISTORY_LIST = gql`
  query DigitalAssiatantHistoryQuery($digitalAssistantId:String, $timeZone: String, $brandId: Int, $language: String, $recordsCount: Int, $date: String) {
    AssistantHistoryList(
        digitalAssistantId: $digitalAssistantId
        timeZone: $timeZone
        brandId: $brandId
        language: $language
        recordsCount: $recordsCount
        date: $date
      ) {
        ownVoiceProcessingCount,
        dynamicSoundscapeProcessingCount,
        compressionCount,
        audioStreamingCount,
        numberOfModifications,
        ownVoiceProcessingName,
        dynamicSoundscapeProcessingName,
        compressionName,
        audioStreamingName,
        listOfModifications {
            date,
            soundSource,
            soundAttribute,
            change,
            cssClass
        }
    }
  }
`