import { InMemoryCache } from "@apollo/client";
import defaults from '../defaults/index'

export const apolloLocalCache = new InMemoryCache({
  typePolicies: {
    Me: {
      keyFields: ["employee", ["id"]]
    },
    EmployeeList: {
      keyFields: ["content", ["id"]]
    },
    Customer: {
      keyFields: ["id"],
      fields: {
        appInfo: {
          merge: true
        }
      }
    },
    HearingDevices: {
      keyFields: ["leftDevice", ["id"], "rightDevice", ["id"]],
      fields: {
        capabilities: {
          merge: true
        }
      }
    },
    PatientData: {
      keyFields: ["id"]
    },
    Query: {
      fields: {
        authentication: {
          read() {
            return authStateVar();
          },
        },
        defaultPractice: {
          read() {
            return defaultPracticeStateVar();
          },
        },
        locale: {
          read() {
            return localeStateVar();
          },
        },
        countries: {
          read() {
            return countriesStateVar();
          },
        },
        overlay: {
          read() {
            return overlayStateVar();
          },
        },
        location: {
          read() {
            return locationStateVar();
          },
        },
        UserDataConsent: {
          read() {
            return userDataConsentStateVar();
          },
        },
        login: {
          read() {
            return authStateVar();
          },
        },
        PatientData: {
          read() {
            return patientData();
          }
        }

      },
      tasks: {
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming]
        }
      }
    },
  },
});


export const authStateVar = apolloLocalCache.makeVar(defaults.authentication);
export const defaultPracticeStateVar = apolloLocalCache.makeVar(defaults.defaultPractice);
export const localeStateVar = apolloLocalCache.makeVar(defaults.locale);
export const countriesStateVar = apolloLocalCache.makeVar(defaults.countries);
export const overlayStateVar = apolloLocalCache.makeVar(defaults.overlay);
export const locationStateVar = apolloLocalCache.makeVar(defaults.location);
export const userDataConsentStateVar = apolloLocalCache.makeVar(defaults.UserDataConsent);
export const patientData = apolloLocalCache.makeVar(defaults.PatientData);

