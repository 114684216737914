import PropTypes from 'prop-types'
import React from 'react'

const Bar = ({ opacity }) => <span className="changer__block" style={{ opacity }} />

Bar.propTypes = {
  opacity: PropTypes.string
}

const Stepper = ({ dataQa, values, min, max, onChange, label }) => {
  const { value1, value2 } = values

  const increase = () => {
    onChange('increase')
  }

  const decrease = () => {
    onChange('decrease')
  }
  return (
    <div data-qa={dataQa || 'changer'} className="changer">
      <div className="changer__inner">
        <button
          type="button"
          data-qa="changer__button decrease"
          className="changer__button changer__button--left"
          disabled={value1 === min && value2 === min}
          onClick={() => decrease()}
        >
          <i className="fas fa-fw fa-minus" />
        </button>
        <h3 data-qa="changer__title" className="changer__title">
          {label}
        </h3>
        <div data-qa="changer__bar" className="changer__bar">
          <Bar opacity=".1" />
          <Bar opacity=".2" />
          <Bar opacity=".3" />
          <Bar opacity=".4" />
          <Bar opacity=".5" />
          <Bar opacity=".6" />
          <Bar opacity=".7" />
          <Bar opacity=".8" />
        </div>
        <button
          type="button"
          data-qa="changer__button increase"
          className="changer__button changer__button--right"
          disabled={value1 === max && value2 === max}
          onClick={() => increase()}
        >
          <i className="fas fa-fw fa-plus" />
        </button>
      </div>
    </div>
  )
}

export default Stepper
