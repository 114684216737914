import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'

const ReconnectCode = ({ webCode = '' }) => {
  const code = `${webCode.substring(0, 3)} - ${webCode.substring(3, 6)}`

  return (
    <div className="form-group">
      <label htmlFor="connection-code">
        <FormattedMessage {...messages.connectToAppConnectionCode} />
      </label>
      <input
        name="connection-code"
        className="form-control"
        type="text"
        value={code}
        data-qa="connection-code"
        disabled={true}
      />
    </div>
  )
}

export default ReconnectCode
