import React, { FC, ReactElement, useRef } from 'react'
import { useNavigate } from "react-router"
import { NotificationType, DataCollectionType } from 'helper/constants'
import { getDateTimeInfoFromNow } from "../../helper/DateUtils.js"
import { getLocaleFromCache } from "helper/LocaleHelper"
import { T } from 'core'
import { INotificationRecordProps } from '../../typescript/interfaces/INotificationDropdown.js'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { MakeNotificationsPayload } from 'helper/DataCollection/PayloadData'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'

export const NotificationRecord: FC<INotificationRecordProps> = ({
  record,
  recordList,
  setNotificationsState }): ReactElement => {

  const { setOpen, deleteNotification, updateNotificationReadDetail, refetch, formatMessage,
    unreadNotificationCount, satisfactionNotifications, lessonNotifications } = useNotificationsContext()

  let recordContainerRef = useRef<HTMLDivElement>()
  const navigate = useNavigate()
  const locale = getLocaleFromCache()
  const { patientNotificationId, patientFirstName, patientLastName, tcbeId, notificationType, modifiedDate, isRead } = record

  function performDataCollection(eventType: string): void {
    sendDataToDataCollection(DataCollectionType.Notifications,
      MakeNotificationsPayload(unreadNotificationCount, (satisfactionNotifications.length + lessonNotifications.length), notificationType),
      eventType)
  }

  async function HandleDeleteNotification(e: React.MouseEvent) {
    e.stopPropagation()
    performDataCollection(EventType.NotificationDelete)

    await deleteNotification({ patientNotificationId })

    recordContainerRef.current.classList.add('remove-record')
  }

  function HandleDeleteNotificationAfterAnimationEnd(): void {
    const updatedRecordListAfterDeletion = recordList.filter(x => x.patientNotificationId != patientNotificationId)
    setNotificationsState(updatedRecordListAfterDeletion)
  }

  async function HandleNotificationRecordClick(): Promise<void> {
    setOpen(false)
    performDataCollection(EventType.NotificationOpen)

    if (!isRead) {
      await updateNotificationReadDetail({ patientNotificationId })
      refetch() // don't await this call, we want it to happen in background
    }

    const url = notificationType == NotificationType.DailySatisfaction
      ? `/profile/${tcbeId}/overview/satisfaction`
      : `/profile/${tcbeId}/ratings`

    navigate(url);
  }

  return (
    <div ref={recordContainerRef} className={`notification-element-div ${!isRead ? 'active' : ''}`}
      onClick={HandleNotificationRecordClick} key={patientNotificationId} onAnimationEnd={HandleDeleteNotificationAfterAnimationEnd}>
      <div className='notification-record-container'>
        <div className='patient-name-container'>
          {`${patientFirstName} ${patientLastName}`}
        </div>

        <div className='patient-notification-meta'>
          <div className='notification-date'>
            {getDateTimeInfoFromNow(modifiedDate, locale)}
          </div>
          <div className='notification-clear'>
            <i className="fa fa-times" title={formatMessage(T('notifications.deleteNotification'))}
              aria-hidden="true" onClick={(e) => HandleDeleteNotification(e)} />
          </div>
        </div>
      </div >
    </div >
  )
};

