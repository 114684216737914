import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-pluralrules/polyfill'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { LOCALE_QUERY } from './../../graphql/queries/LocaleQuery'
import { compose } from 'lodash/fp'
import { DataCollectionType } from 'helper/constants'
import { LanguageChangeAnalyticsData } from '../../helper/DataCollection/PayloadData';
import { sendDataToDataCollection } from './../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from '../../helper/DataCollection/DataCollectionConstants'

export const prepareSetLocale = (newLocale, setLocale, currentLocale) => async () => {
  const newLanguage = newLocale.split('-')[0]
  const lang = await import(`@formatjs/intl-pluralrules/locale-data/${newLanguage}`)
  new IntlProvider(lang)
  setLocale(newLocale)

  if (currentLocale != newLocale) {
    await sendDataToDataCollection(DataCollectionType.LanguageData, LanguageChangeAnalyticsData(currentLocale, newLocale), EventType.LanguageChange)
  }  
}

const LanguageDropdown = ({ locales, active, setLocale, currentLocale, modifier }) => {
  const selectedlanguage = locales[active].match(/\(([^)]+)\)/)[1];
  return (
    <Dropdown className={`${modifier ? `dropdown--${modifier}` : ''}`}>
      <Dropdown.Toggle variant="outline-light" id="dropdown-languages">
        <span className={` ff-md`} >{selectedlanguage}</span>
        <StyledFontAwesomeIcon icon={faAngleDown} fixedWidth type="angleDown" className="dropdown-toggle-icon" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(locales).map(localeCode => {
          const locale = locales[localeCode]
          return (
            <Dropdown.Item
              data-id={localeCode}
              key={localeCode}
              id={localeCode}
              onClick={prepareSetLocale(localeCode, setLocale, currentLocale)}
            >
              <span className="name">
                {locale}
              </span>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

LanguageDropdown.propTypes = {
  locales: PropTypes.shape({}),
  active: PropTypes.string,
  setLocale: PropTypes.func,
  currentLocale: PropTypes.string
}

const mapCacheToProps = ({ data: { locale } }) => {
  const localeProp = locale ? locale.locale : 'en-US'

  return {
    currentLocale: localeProp
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: mapCacheToProps
  })
)

export default withData(LanguageDropdown)
