import React from 'react'
import { Popup, VideoInfoOverlay } from './../components/BrowserPopups/Popup'
import ErrorPopup from './../components/ErrorPopup'
import { T } from 'core'

import headsetIcon from 'assets/img/headset.png'
import { CALL_STATES } from './../callStates'
import { RecallButton } from 'styledComponents/Button'
import { ActionButton } from 'styledComponents/Span'
import { PopupIcon } from 'styledComponents/Img'
import {
  VideoCallWrapper,
  VideoContainer,
  VideoButtons,
  PopupBackground
} from 'styledComponents/Div/DivComponent/DivVideoCall'
import DataContext from "containers/DataContext"


export default class VideoOverlay extends React.Component {
  static contextType = DataContext;
  closing = false

  state = {
    callType: 'VIDEO',
    videoMuted: false,
    audioMuted: false,
    remoteAudioMuted: false,
    remoteVideoMuted: false,
    remoteApplyingSettings: false,
    callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
    maximized: true,
    showCallEnded: false,
    remoteCallState: false
  }

  resetState() {
    this.setState({
      callType: 'VIDEO',
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      remoteApplyingSettings: false,
      callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
      maximized: true,
      showCallEnded: false,
      remoteCallState: false
    })
  }

  UNSAFE_componentWillMount() {
    const { videoCallManager } = this.context
    window.overlay = this
    videoCallManager.on('change', this.onVideoManagerChange)
  }

  componentWillUnmount() {
    const { videoCallManager } = this.context
    videoCallManager.off('change', this.onVideoManagerChange)
  }

  onVideoManagerChange = newState => {
    const { videoCallManager } = this.context
    const { callState } = newState
    const oldCallState = this.state.callState

    // if (callState === CALL_STATES.REMOTE_JOINED && oldCallState !== CALL_STATES.REMOTE_JOINED) {
    //   const { localMedia, remoteMedia } = videoCallManager
    //   if (localMedia && remoteMedia) {
    //     this.setupLayout()
    //   }
    // }

    // if ( callState === CALL_STATES.CLOSING) {
    //   this.leave({ offerReconnect: true })
    //   this.closing = false
    // }

    this.setState(
      {
        ...newState
      },
      () => {
        //after state has changed and videocontainer is rendered
        if (
          callState === CALL_STATES.REMOTE_JOINED &&
          oldCallState !== CALL_STATES.REMOTE_JOINED
        ) {
          const { localMedia, remoteMedia } = videoCallManager
          if (localMedia && remoteMedia) {
            this.setupLayout()
          }
        }
      }
    )
  }

  toggleAudioMute = () => {
    const { videoCallManager } = this.context
    videoCallManager.toggleAudioMute()
    this.setState({
      isAudioMuted: videoCallManager.isLocalAudioMuted()
    })
  }

  toggleVideoMute = () => {
    const { videoCallManager } = this.context
    videoCallManager.toggleVideoMute()
    this.setState({
      isVideoMuted: videoCallManager.isLocalVideoMuted()
    })
  }

  toggleMaximized = () => {
    this.setState(
      {
        maximized: !this.state.maximized
      },
      () => {
        // after setting the state (rendering is going on)
        if (this.state.maximized) {
          this.updateLayoutMaximized()
        } else {
          this.updateLayoutMinimized()
        }
      }
    )
  }

  updateLayoutMinimized() {
    if (this.layoutManager) {
      const localView = this.layoutManager.getLocalView()
      const remoteViews = this.layoutManager.getRemoteViews()

      if (localView) {
        localView.style.display = 'none'
      }

      if (remoteViews && remoteViews.length) {
        const video = remoteViews[0].querySelector('video')
        video.style['object-fit'] = 'cover'
      }
    }
  }

  updateLayoutMaximized() {
    if (this.layoutManager) {
      const localView = this.layoutManager.getLocalView()
      const remoteViews = this.layoutManager.getRemoteViews()

      if (localView) {
        localView.style.display = 'block'
      }

      if (remoteViews && remoteViews.length) {
        const video = remoteViews[0].querySelector('video')
        video.style['object-fit'] = 'contain'
      }
    }
  }

  dismissApplySettings() {
    this.setState({
      remoteApplyingSettings: false
    })
  }

  leave = async ({ offerReconnect = false } = {}) => {
    this.closing = true
    const { videoCallManager } = this.context
    videoCallManager.leave('Ended', offerReconnect)
    await videoCallManager.stopLocalMedia()
    if (this.layoutManager != null) {
      this.layoutManager.removeRemoteViews()
      this.layoutManager.unsetLocalView()
      this.layoutManager = null
    }
    // Tear down the local media.
    if (videoCallManager.localMedia != null) {
      videoCallManager.localMedia = null
    }

    this.setState({
      showCallEnded: offerReconnect,
      maximized: true
    })
  }

  leaveLocalIntent = async () => {
    const { videoCallManager } = this.context

    videoCallManager.track('PortalCallEnded', {
      organizationId: videoCallManager.me.organizationId,
      employeeId: videoCallManager.me.employee.id,
      customerId: videoCallManager.customer.id,
      sessionId: videoCallManager.config.sessionId,
      callType: videoCallManager.state.callType,
      type: 'local'
    })
    return await this.leave()
  }

  onPopupClose = async () => {
    const { videoCallManager } = this.context

    videoCallManager.setState({
      callState: CALL_STATES.CLOSED,
      error: false
    })

    return await this.leave()
  }

  getVideoContainer() {
    if (this.videoContainer) {
      return this.videoContainer
    }
    return null
  }

  setupLayout() {
    const { videoCallManager } = this.context
    const { localMedia, remoteMedia } = videoCallManager

    const videoContainer = this.getVideoContainer()
    if (!videoContainer) return

    if (this.layoutManager != null) {
      this.layoutManager.removeRemoteViews()
      this.layoutManager.unsetLocalView()
      this.layoutManager = null
    }

    if (!this.layoutManager) {
      this.layoutManager = new fm.icelink.DomLayoutManager(videoContainer)
      window.fmLayoutManager = this.layoutManager
    }

    const localView = localMedia.getView()
    const remoteView = remoteMedia ? remoteMedia.getView() : null

    if (localView && !this.layoutManager.getLocalView()) {
      this.layoutManager.setLocalView(localView)
    }

    if (remoteView != null) {
      this.layoutManager.addRemoteView(remoteMedia.getId(), remoteView)
    }
  }

  closeCallEnded = () => {
    const { videoCallManager } = this.context
    videoCallManager.setState({
      callType: 'VIDEO',
      callState: CALL_STATES.CLOSED,
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      remoteApplyingSettings: false,
      maximized: true,
      showCallEnded: false
    })
    this.resetState()
  }

  onRecallAudio = () => {
    const { videoCallManager } = this.context
    videoCallManager.recallAudio()
  }

  onRecallVideo = () => {
    const { videoCallManager } = this.context
    videoCallManager.recallVideo()
  }

  render() {
    const {
      error,
      videoMuted,
      audioMuted,
      remoteAudioMuted,
      remoteVideoMuted,
      remoteApplyingSettings,
      maximized,
      showCallEnded,
      remoteCallState,
      callType,
      callState
    } = this.state
    const { videoCallManager, intl: { formatMessage } } = this.context

    const CONNECTION_OK =
      [
        CALL_STATES.REMOTE_JOINED,
        CALL_STATES.CONNECTED,
        CALL_STATES.JOINED
      ].indexOf(callState) > -1 &&
      callState !== CALL_STATES.CLOSED &&
      callState !== CALL_STATES.CLOSING &&
      callState !== CALL_STATES.ERROR
    const CONNECTION_ERROR = callState === CALL_STATES.ERROR
    const REMOTE_VIDEO_MUTED = CONNECTION_OK && remoteVideoMuted
    const REMOTE_AUDIO_MUTED = CONNECTION_OK && remoteAudioMuted
    const APPLYING_SETTINGS = CONNECTION_OK && remoteApplyingSettings
    // Felix: Refactored STREAM_FAILURE_RECONNECTING so that this expression does not always evaluate to false
    const STREAM_FAILURE_RECONNECTING =
      (CONNECTION_ERROR || callState === CALL_STATES.RECONNECTING) &&
      error.type === 'stream_failure'
    const REMOTE_JOINED = callState === CALL_STATES.REMOTE_JOINED
    const ESTABLISHING_CONNECTION =
      [
        CALL_STATES.CONNECTING,
        CALL_STATES.CONNECTED,
        CALL_STATES.JOINED
      ].indexOf(callState) > -1
    const CONNECTION_LOST = CONNECTION_ERROR && error.type === 'connection_lost'
    const CLOSING = callState === CALL_STATES.CLOSING && !error
    const AUDIO_ONLY = callType == 'AUDIO'

    const SHOW_POPUP_BACK =
      (CONNECTION_ERROR ||
        STREAM_FAILURE_RECONNECTING ||
        ESTABLISHING_CONNECTION ||
        // Felix: Commented this code so that this expression does not always evaluate to false
        // CONNECTION_LOST ||
        CLOSING) &&
      !maximized

    if (
      !videoCallManager.isActive() &&
      callState !== CALL_STATES.TIME_OUTED &&
      callState !== CALL_STATES.ERROR &&
      callState !== CALL_STATES.CONNECTED &&
      !showCallEnded
    ) {
      return null
    }

    if (remoteCallState === 'leave') {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.left.title'))}
            message={formatMessage(T('videocall.patient.left'))}
          >
            <RecallButton onClick={this.leave} className="btn" data-qa="videocall-close">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video-camera" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (remoteCallState === 'declined') {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.declined.title'))}
            message={formatMessage(T(`${AUDIO_ONLY ? 'audiocall.patient.declined' : 'videocall.patient.declined'}`))}
          >
            <RecallButton onClick={this.leave} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video-camera" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (callState === CALL_STATES.TIMED_OUT) {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.patient.na'))}
            message={formatMessage(T('popup_noconnection.message'))}
          >
            <RecallButton onClick={this.leave} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video-camera" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    if (showCallEnded || (CONNECTION_LOST && !error)) {
      return (
        <VideoCallWrapper maximized={maximized}>
          <Popup
            title={formatMessage(T('videocall.ended'))}
            message={formatMessage(T('videocall.endmessage'))}
          >
            <RecallButton onClick={this.closeCallEnded} className="btn">
              {formatMessage(T('common.close'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallAudio} className="btn">
              <i className="fas fa-phone" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
            <RecallButton onClick={this.onRecallVideo} className="btn">
              <i className="fas fa-video-camera" />
              {formatMessage(T('VideoCall.recall'))}
            </RecallButton>
          </Popup>
        </VideoCallWrapper>
      )
    }

    const IHATEIE = () => {
      return (
        <iframe
          src="about:blank"
          frameBorder="0"
          style={{
            border: '0px currentColor',
            left: '0px',
            top: '0px',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'none transparent',
            pointerEvents: 'none'
          }}
          allowTransparency="true"
        />
      )
    }

    const VideoButtonsWrapper = () => {
      return (
        <VideoButtons className={!maximized ? 'minimized' : ''}>
          <IHATEIE />
          <div>
            <ActionButton
              onClick={this.toggleMaximized}
              className={`${maximized ? 'fas fa-compress-alt' : 'fas fa-expand-alt'}`}
            />
            {maximized &&
              !AUDIO_ONLY &&
              <ActionButton
                onClick={this.toggleVideoMute}
                className={`${videoMuted ? 'fas fa-video-slash red' : 'fas fa-video'}`}
              />}
            {maximized &&
              <ActionButton
                onClick={this.toggleAudioMute}
                className={` ${audioMuted ? 'fas fa-microphone-slash red' : 'fas fa-microphone'} `}
              />}
            <ActionButton
              onClick={this.leaveLocalIntent}
              className={`red hangup fas fa-phone`}
              data-qa="videocall-hangup"
            />
          </div>
        </VideoButtons>
      )
    }

    return (
      <VideoCallWrapper className={!maximized ? 'minimized' : ''} data-qa="videocall-popup">
        {SHOW_POPUP_BACK && <PopupBackground />}
        {REMOTE_JOINED &&
          <VideoContainer
            data-qa="videocall"
            id="videocall"
            ref={x => (this.videoContainer = x)}
            className={!maximized ? 'minimized' : ''}
          >
            {AUDIO_ONLY &&
              <VideoInfoOverlay
                className={!maximized ? 'minimized' : ''}
                message={formatMessage(T('videocall.audioonly'))}
              />}
            {REMOTE_VIDEO_MUTED &&
              <VideoInfoOverlay
                className={!maximized ? 'minimized' : ''}
                message={formatMessage(T('videocall.cam.off'))}
              />}
            {REMOTE_AUDIO_MUTED &&
              <VideoInfoOverlay
                className={!maximized ? 'minimized' : ''}
                message={formatMessage(T('videocall.audio.off'))}
              />}
            {APPLYING_SETTINGS &&
              <VideoInfoOverlay
                className={!maximized ? 'minimized' : ''}
                message={`Your patient currently applies a remote tuning and won't hear you`}
              />}
          </VideoContainer>}
        {REMOTE_JOINED && <VideoButtonsWrapper />}
        {STREAM_FAILURE_RECONNECTING && <Popup message={`StreamError. Reconnecting ...`} />}
        {ESTABLISHING_CONNECTION &&
          <Popup
            message={formatMessage(T('videocall.stabls'))}
            loading={true}
            title={
              <span>
                <PopupIcon src={headsetIcon} />
                {formatMessage(T('videocall.headset'))}
              </span>
            }
          >
            <ActionButton
              onClick={this.leaveLocalIntent}
              className={`red hangup fas fa-phone`}
              data-qa="videocall-hangup"
            />
          </Popup>}

        {error && <ErrorPopup onPopupClose={this.onPopupClose} error={error} />}
      </VideoCallWrapper>
    )
  }
}
