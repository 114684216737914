import React, { useState } from "react";
import { T } from "core";
import { injectIntl, FormattedMessage } from "react-intl";
import SearchableSelectorField from 'formfields/SearchableSelectorField'
import { Button } from 'styledComponents/Button'
import { Formik, Form } from 'formik'

import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'lodash/fp'
import * as Yup from 'yup'
import { getOrganizationIdFromCache, getEmployeeFromCache } from 'helper/PracticeUtils'
import { QUERY_USER_LIST, QUERY_EMPLOYEE_INFO, MUTATION_MOVE_PATIENTS } from "../../graphql/queries/MovePatientsQuery";
import Loading from 'components/Loading'
import ToastAlert from "../../components/Notification/ToastAlert";

import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { DataCollectionType } from 'helper/constants'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { BulkPatientMove } from 'helper/DataCollection/PayloadData'

export function MovePatients(props) {
    const { usersList = [] } = props;
    const [toUser, setToUser] = useState({})
    const [fromUser, setFromUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const [migrationStatus, setMigrationStatus] = useState({ success: false, message: "" });


    const handleUserSelection = info => id => {
        fetchEmployee({
            info,
            id
        })
    }

    const fetchEmployee = async ({ info, id }) => {
        const result = await props.EmployeeInfoQuery.refetch({
            employeeId: id,
            hasSelectedEmployee: true
        })
        const response = result?.data?.Employee
        info === "fromUser" ? setFromUser(response) : setToUser(response)
    }

    const handleSubmission = ({ status, success }, setFieldError, values) => {
        const message = success ? formatMessage('orgManagement.movePatients.patientMigrationSuccess') : formatMessage('orgManagement.movePatients.generalError')
        if (status === 409) {
            setFieldError(
                "toUser",
                formatMessage('orgManagement.movePatients.patientMigrationAlreadyInProgressError')
            );
            setLoading(false)
        } else {
            setMigrationStatus({ success, message })
            setLoading(false)
            setShowStatus(true)
        }
        const dataObj = {
            orgId: parseInt(getOrganizationIdFromCache()),
            employeeId: getEmployeeFromCache().id,
            fromUserId: values.fromUser,
            toUserId: values.toUser
        }
        sendDataToDataCollection(DataCollectionType.Crud, BulkPatientMove(dataObj), EventType.BulkPatientMove)
    }

    const handleValidationOnSubmit = async (values, setFieldError) => {
        setShowStatus(false)
        setMigrationStatus({ success: false, message: "" })
        if (fromUser && toUser) {
            if (toUser.id === fromUser.id) {
                setFieldError('toUser', formatMessage('orgManagement.movePatients.sameUserError'))
            }
            else if (_.difference(fromUser.assignedPracticeIds, toUser.assignedPracticeIds).length !== 0) {
                setFieldError('fromUser', formatMessage('orgManagement.movePatients.differentAssignedPracticesError'))
            } else {
                const moveRequest = {
                    orgId: parseInt(getOrganizationIdFromCache()),
                    newEmployeeId: toUser.id,
                    oldEmployeeId: fromUser.id,
                    employeeFirstName: toUser.firstName,
                    employeeLastName: toUser.lastName,
                    employeeImageUrl: toUser?.avatar?.url
                }
                //// call backend
                try {
                    setLoading(true)
                    const response = await props.movePatients({ movePatientsRequest: moveRequest })
                    handleSubmission(response.data.movePatients, setFieldError, values)
                }
                catch (error) {
                    console.log(error)
                    handleSubmission({ status: 500, success: false, message: "ERROR" }, setFieldError, values)
                }
            }
        }
    }

    const validationSchema = () => Yup.object().shape({
        fromUser: Yup.string().required(formatMessage('validator.required')).nullable(),
        toUser: Yup.string().required(formatMessage('validator.required')).nullable()
    })

    const formatMessage = (keyID) => {
        return props.intl.formatMessage(T(keyID));
    };

    const getMovePatientsHeadingMarkup = () =>
        <div className="row margin-with-border">
            <div className="col-12 mb-3">
                <h2 data-qa="move-patients" className="d-inline">
                    <FormattedMessage {...T("orgManagement.movePatients.move")} />
                </h2>
                <p
                    className="help-block mt-3"
                    data-qa="move-patients-help"
                >
                    <FormattedMessage {...T("orgManagement.movePatients.moreInfo")} />
                </p>
            </div>
        </div>

    const getMovePatientsMarkup = () =>
        <>
            {getMovePatientsHeadingMarkup()}
            <div className="row">
                <div className="col-md-6">
                    <SearchableSelectorField
                        data-test="fromUserSearchableField"
                        name="fromUser"
                        onChange={handleUserSelection('fromUser')}
                        options={usersList}
                        toString={{ id: "id", value: "firstName,lastName" }}
                        placeholder={<FormattedMessage {...T('orgManagement.movePatients.fromUser')} />}
                    />
                </div>
                <div className="col-md-6">
                    <SearchableSelectorField
                        data-test="toUserSearchableField"
                        name="toUser"
                        onChange={handleUserSelection('toUser')}
                        options={usersList}
                        toString={{ id: "id", value: "firstName,lastName" }}
                        placeholder={<FormattedMessage {...T('orgManagement.movePatients.toUser')} />}
                    />
                </div>
            </div>
        </>

    const initialData = { fromUser: "", toUser: "" };

    return (
        <Formik
            onSubmit={(values, actions) => handleValidationOnSubmit(values, actions.setFieldError)}
            validationSchema={validationSchema}
            initialValues={initialData}
            enableReinitialize={true}
        >
            {({ dirty }) => {
                return (
                    <Form>
                        {loading && <Loading isLoading={loading} />}
                        <div>
                            <>
                                {getMovePatientsMarkup()}
                                <div className="row">
                                    <div className="col-lg-6">
                                        {showStatus &&
                                            <ToastAlert
                                                message={migrationStatus.message}
                                                success={migrationStatus.success}
                                                displayTime={3000}
                                            />

                                        }
                                    </div>
                                    <div className="offset-3" />
                                    <div className="col-lg-3">
                                        <Button
                                            primary
                                            disabled={!dirty}
                                            // type="button"
                                            type="submit"
                                            className="btn-block"
                                            data-qa="continue-button"
                                        // onClick={handleValidationOnSubmit}
                                        >
                                            <FormattedMessage {...T('orgManagement.movePatients.move')} />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const userPageOptions = () => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { practiceId: null, search: '', sort: 'firstName,asc', page: 0, accountState: 'ACTIVE', size: 10000 }
})

const mapPropsEmployeeInfo = ({
    EmployeeInfoQuery: { refetch, Employee = {} },
    EmployeeInfoQuery
}) => {
    return {
        EmployeeInfoQuery,
        refetch,
        employee: Employee
    }
}

const employeeInfoOptions = ({ employeeId }) => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: mapQueryToVariables({
        employeeId: employeeId
    })
})

export const mapQueryToVariables = ({
    employeeId = 0
}) => {
    return {
        employeeId: parseInt(employeeId),
        hasSelectedEmployee: !!employeeId && employeeId > 0
    }
}

const withData = compose(
    graphql(QUERY_USER_LIST, {
        props: ({ data: { EmployeeList: { content } = [] } }) => {
            return { usersList: content }
        },
        options: userPageOptions
    }),
    graphql(QUERY_EMPLOYEE_INFO, {
        name: 'EmployeeInfoQuery',
        props: mapPropsEmployeeInfo,
        options: employeeInfoOptions
    }),
    graphql(MUTATION_MOVE_PATIENTS, {
        props: ({ mutate }) => ({
            movePatients: variables =>
                mutate({
                    variables
                })
        })
    }),
    injectIntl
)

export default withData(MovePatients)