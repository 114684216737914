import React from 'react'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import { ErrorMessage, getIn } from 'formik'
import Select from 'react-select'
import { withTheme } from 'styled-components'

export const getLabelForGroupedOptions = (options, value) => {
  let a
  options.map(lessonGroup => {
    lessonGroup.options.some(option => {
      if (option.value === value) {
        a = option
        return true
      }
    })
  })
  return a.label
}

export const SelectField = ({
  field,
  label,
  labelClassName,
  hint,
  form: { touched, errors, setFieldValue, values, setFieldTouched },
  onSelect = () => {},
  disabled,
  options,
  centered,
  intl: { formatMessage },
  placeholder,
  clearable,
  searchable = true,
  grouped,
  theme:{ colors: { dropdowns: { searchableDropdown } } },
  ...props
}) => {
  const defaultPlaceholder = placeholder || `${formatMessage(T('common.select'))}...`
  const labelClasses = (labelClassName || '').split(' ')
  labelClasses.push('control-label')
  const selectStyles = {
    menu: styles => ({
        ...styles,
        zIndex: 100
      }),
      control: styles => ({
        ...styles,
        border: '1px solid #9d9c9c',
        boxShadow: 'none',
        cursor: 'pointer',
        backgroundColor: disabled ? '#f2f2f2' : 'white'
      }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const getBackgroundColor = () => {
        if (isDisabled) {
          return null;
        } else if (isSelected) {
          return "#cfcfcf";
        } else if (isFocused) {
          return searchableDropdown?.selected?.light;
        }

        return null;
      };
      return {
        ...styles,
        backgroundColor: getBackgroundColor(),
        color: isDisabled ? "#ccc" : searchableDropdown?.selected?.dark,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        textAlign: centered ? 'center' : 'left',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? '#cfcfcf' : '#e3e3e3')
        }
      }
    },
    valueContainer: styles => ({
        ...styles,
        alignItems: centered ? 'center' : 'default',
        justifyContent: centered ? 'center' : 'normal'
      }),
      groupHeading: styles => ({
        ...styles,
        backgroundColor: '#cfcfcf',
        color: 'black',
        fontSize: '14px',
        padding: '8px 12px'
      }),
    singleValue: (styles) => ({
      ...styles,
      color: searchableDropdown?.active,
    }),
    placeholder: (styles, { isFocused }) => {
      const getFontColor = () => {
        if (isFocused) {
          return searchableDropdown?.selected?.dark;
        }
        return searchableDropdown?.default;
      };
      return {
        ...styles,
        color: getFontColor(),
      };
    },
  };
  const getOptions = () => {
    if (options && options.length > 0 && field.value) {
      if (grouped) {
        return {
          value: field.value,
          label: getLabelForGroupedOptions(options, field.value),
        };
      }
      let searchedOption = options.find(
        (option) => option.value == field.value
      );
      return {
        value: field.value,
        label: searchedOption?.label ?? defaultPlaceholder,
      };
    }
    return "";
  };
  return (
    <div
      className={`form-group ${getIn(touched, field.name) && getIn(errors, field.name)
        ? 'has-error'
        : ''}`}
    >
      {label &&
        <label htmlFor={props.name} className={labelClasses.join(' ')}>
          {label}
        </label>}
      <Select
        {...props}
        id={field.name}
        name={field.name}
        value={getOptions()}
        onChange={e => {
          setFieldValue(field.name, e ? e.value : null)
          onSelect(e ? e.value : null, setFieldValue, values)
        }}
        onBlur={() => setFieldTouched(field.name)}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        options={options}
        placeholder={defaultPlaceholder}
        styles={selectStyles}
        menuPlacement="auto"
      />
      <ErrorMessage
        name={field.name}
        render={msg =>
          <div className="help-block">
            {msg}
          </div>}
      />
      {hint &&
        <span className="help-block">
          {hint}
        </span>}
    </div>
  )
}

export default withTheme(injectIntl(SelectField))
