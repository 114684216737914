import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { invert, omit, reduce, isEmpty } from 'lodash'
import { T } from 'core'
import { DropdownButton } from 'react-bootstrap'
import KnobWithTitle from 'components/KnobWithTitleComponent'
import { journeyStatuses } from 'helper/constants'
import { notEqual } from 'helper/Utils'
import { Button } from 'styledComponents/Button'
import {
  FilterOnBlock,
  ActionButtonsDropdown
} from 'styledComponents/Div/DivComponent/DivDropdowns'

const getDefaultActiveStatuses = statuses => {
  return statuses.reduce((acc, item) => {
    acc[item] = true
    return acc
  }, {})
}

class MarketingPatientStatusFilter extends React.Component {
  static displayName = 'MarketingPatientStatusFilter'

  static defaultProps = {
    onChange: Function,
    isFilterOpen: false,
    onOpenFilter: Function,
    patientStatuses: {}
  }

  state = {
    activeStatuses: {}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.patientStatuses)) {
      this.setState({
        activeStatuses: nextProps.patientStatuses
      })
    } else {
      this.setState({
        activeStatuses: { ...getDefaultActiveStatuses(journeyStatuses) }
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cleanNextProps = omit(nextProps, ['onChange', 'onOpenFilter'])
    const cleanProps = omit(this.props, ['onChange', 'onOpenFilter'])
    return (
      notEqual(cleanNextProps, cleanProps) || notEqual(nextState, this.state)
    )
  }

  onKnobChange = status => () => {
    this.setState({
      activeStatuses: {
        ...this.state.activeStatuses,
        [status]: !this.state.activeStatuses[status]
      }
    })
  }

  onApplyFilter = () => {
    this.props.onChange({ patientStatuses: { ...this.state.activeStatuses } })
    this.props.onSelect(this.getFilterCount())
    this.onClose()
  }

  onOpenFilter = () => {
    this.props.onOpenFilter(!this.props.isFilterOpen)
  }

  isFilterActive = () => {
    return !!invert(this.state.activeStatuses)[false] //default filters were changed
  }

  onClose = () => {
    this.setState(
      {
        isFilterActive: this.isFilterActive()
      },
      () => {
        this.props.onOpenFilter(!this.props.isFilterOpen)
      }
    )
  }

  onCancel = () => {
    this.setState(
      {
        activeStatuses: { ...this.props.patientStatuses }
      },
      () => {
        this.onClose()
      }
    )
  }

  getPatientStatuses() {
    const { activeStatuses } = this.state
    return journeyStatuses.map(status => {
      const knobTitle = (
        <FormattedMessage {...T(`marketing.messages.${status}`)} />
      )
      return (
        <KnobWithTitle
          title={knobTitle}
          onToggle={this.onKnobChange(status)}
          active={activeStatuses[status]}
          key={status}
        />
      )
    })
  }

  getFilterCount = () => {
    return reduce(
      this.state.activeStatuses,
      (count, value) => {
        value === true ? count++ : count
        return count
      },
      0
    )
  }
  getFilterTitle = () => {
    const { intl: { formatMessage } } = this.props
    const nrActiveStatuses = reduce(
      this.state.activeStatuses,
      (count, value) => {
        value === true ? count++ : count
        return count
      },
      0
    )

    const values = `( ${nrActiveStatuses !== journeyStatuses.length
      ? nrActiveStatuses
      : formatMessage(T('common.all'))} )`
    return (
      <FormattedMessage
        {...T('marketing.messages.menu-patient-status-all')}
        values={{ 0: values }}
      />
    )
  }

  render() {
    const { intl: { formatMessage }, isFilterOpen } = this.props
    const { isFilterActive } = this.state
    const title = this.getFilterTitle()
    const knobs = this.getPatientStatuses()
    const isFilterActiveClassName = isFilterActive ? 'filterIsActive' : ''
    const isSubmitEnabled = Object.values(this.state.activeStatuses).every(_ => _ === false)
    return (
      <FilterOnBlock>
        <DropdownButton
          variant={'default'}
          title={title}
          id={`patientStatusFilter`}
          data-qa="providersFilter"
          show={isFilterOpen}
          onToggle={(isOpen) => {this.onOpenFilter(isOpen)}}
          className={isFilterActiveClassName}
        >
          {knobs}

          <ActionButtonsDropdown>
            <Button
              secondary
              onClick={this.onCancel}
              data-qa="cancel-button"
            >
              {formatMessage(T('common.cancel'))}
            </Button>
            <Button
              type="submit"
              primary
              data-qa="select-button"
              onClick={this.onApplyFilter}
              disabled={isSubmitEnabled}
            >
              {formatMessage(T('common.select'))}
            </Button>
          </ActionButtonsDropdown>
        </DropdownButton>
      </FilterOnBlock>
    )
  }
}

export default injectIntl(MarketingPatientStatusFilter)
