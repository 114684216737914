import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import _get from 'lodash/get'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { getLocales, PasswordExpiryPeriod, DataCollectionType, DefaultBrandName, NoBrandName } from 'helper/constants'
import _ from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import { withRouter } from 'helper/withRouter'
import { T } from 'core'
import { compose } from 'lodash/fp'
import { getToken } from 'helper/AuthHelper'
import { connector } from 'helper/Connector'
import { getAutoReplyConfigurationForOrganization } from 'helper/AutoReplyConfigHelper'
import { LOCALE_QUERY } from '../../graphql/queries/LocaleQuery'
import { FETCH_ALL_COUNTRIES_QUERY, STORE_COUNTRIES_MUTATION } from 'graphql/queries/CountriesQuery'
import { FETCH_ALL_BRANDS } from 'graphql/queries/OrganizationBrandsQuery'
import { MUTATION_UPDATE_ORGANIZATION_DETAILS, ORGANIZATION_DETAILS_QUERY } from 'graphql/queries/OrganizationDetailsQuery'
import OrganizationPage from 'containers/Registration/OrganizationPage'
import Loading from 'components/Loading'
import { isUserOptedForDataConsent } from 'helper/UserDataConsentUtils'
import { dataService } from "helper/rxjsUtil"
import { Button } from 'styledComponents/Button'
import { sendDataToDataCollection } from '../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType, EntityCrudType } from '../../helper/DataCollection/DataCollectionConstants'
import { EntityCrudPayloadData, OrganizationUpdateData } from '../../helper/DataCollection/PayloadData'
import gql from 'graphql-tag'
import MovePatients from './MovePatientsComponent';

import fflags from 'helper/FFlags'


export class OrganizationEditPage extends Component {
  state = {
    busy: false
  }

  selectedOrgCountryCode = ""
  submitClicked = false;
  autoReplyMode = 'ENABLED'


  componentDidMount() {
    const { countries } = this.props
    if (!countries || !countries.length) {
      this.fetchAllCountries()
    }
    sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Organization), EventType.Read)
  }

  fetchAllCountries() {
    const token = getToken()
    const data = {
      page: 0,
      size: 1000
    }

    return connector
      .get(token, `/api/rest/countries`, data)
      .then((payload = {}) => {
        const isIterable = (object) =>
          object != null && typeof object[Symbol.iterator] === "function";
        const countriesData = payload._embedded && payload._embedded.countries;
        const countries = { data: [...((isIterable(countriesData) && countriesData) || [])] }
        this.props.setCountries({ variables: { ...countries } });
      });
  }

  formatMessage = keyID => {
    return this.props.intl.formatMessage(T(keyID))
  }

  handleValidationOnSubmit = () => {
    this.submitClicked = true
  }


  handlePasswordPolicyChanges = (isPasswordExpirationPolicyEnabled, daysToExpirePassword) => {
    let passwordExpiryPolicyUpdated = false, newPasswordPolicyState = false, newDaysToExpirePassword = null
    const { OrganizationDetails } = this.props

    if (isPasswordExpirationPolicyEnabled != OrganizationDetails.isPasswordExpirationPolicyEnabled ||
      daysToExpirePassword != OrganizationDetails.daysToExpirePassword) {

      passwordExpiryPolicyUpdated = true
      newPasswordPolicyState = isPasswordExpirationPolicyEnabled

      if (isPasswordExpirationPolicyEnabled) {
        newDaysToExpirePassword = daysToExpirePassword.toString()
      }
    }

    return { passwordExpiryPolicyUpdated, newPasswordPolicyState, newDaysToExpirePassword }
  }

  handleAutoReplyChanges = (autoReplyMode, autoReplyMessage) => {
    let autoReplyUpdated = false, newAutoReplyMode = null, newAutoReplyMessage = null
    const { OrganizationDetails } = this.props

    if (autoReplyMode != OrganizationDetails.autoReplyMode || autoReplyMessage != OrganizationDetails.autoReplyMessage) {
      autoReplyUpdated = true
      newAutoReplyMode = autoReplyMode
      newAutoReplyMessage = autoReplyMessage
    }

    return { autoReplyUpdated, newAutoReplyMode, newAutoReplyMessage }
  }

  handleBrandNameChanges = (brandId) => {
    let orgBrandUpdated = false, newBrandName = null
    const { OrganizationDetails, brands } = this.props

    if (brandId != OrganizationDetails.brandId) {
      orgBrandUpdated = true
      newBrandName = brands?.find(_ => _.id === brandId)?.brandName
    }
    return { orgBrandUpdated, newBrandName }
  }

  collectDataForAnalytics = (analyticsData) => {
    if (!isUserOptedForDataConsent()) {
      return
    }

    let updatedOrgData = {
      isPasswordExpirationPolicyEnabled: null,
      daysToExpirePassword: null,
      autoReplyMode: null,
      autoReplyMessage: null,
      brandName: null
    }

    let orgDataStatus = {
      isAutoReplyUpdated: false,
      isPasswordPolicyUpdated: false,
      isBrandChanged: false
    }

    const updatedPasswordPolicy = this.handlePasswordPolicyChanges(analyticsData.isPasswordExpirationPolicyEnabled, analyticsData.daysToExpirePassword)
    orgDataStatus.isPasswordPolicyUpdated = updatedPasswordPolicy.passwordExpiryPolicyUpdated
    updatedOrgData.isPasswordExpirationPolicyEnabled = updatedPasswordPolicy.newPasswordPolicyState
    updatedOrgData.daysToExpirePassword = updatedPasswordPolicy.newDaysToExpirePassword

    const updatedAutoReply = this.handleAutoReplyChanges(analyticsData.autoReplyMode, analyticsData.autoReplyMessage)
    orgDataStatus.isAutoReplyUpdated = updatedAutoReply.autoReplyUpdated
    updatedOrgData.autoReplyMode = updatedAutoReply.newAutoReplyMode
    updatedOrgData.autoReplyMessage = updatedAutoReply.newAutoReplyMessage

    const updatedBrandName = this.handleBrandNameChanges(analyticsData.brandId)
    orgDataStatus.isBrandChanged = updatedBrandName.orgBrandUpdated
    updatedOrgData.brandName = updatedBrandName.newBrandName

    const payloadData = {
      updatedOrgData,
      orgDataStatus
    }

    if (!orgDataStatus.isAutoReplyUpdated && !orgDataStatus.isPasswordPolicyUpdated && !orgDataStatus.isBrandChanged)
      return

    sendDataToDataCollection(DataCollectionType.OrgData, OrganizationUpdateData(payloadData))
  }

  validationSchema = () => Yup.object().shape({
    orgName: Yup.string().required(this.formatMessage('validator.required')),
    orgWebsite: Yup.string().isUrlValid(this.formatMessage('validator.url')),
    orgAddrStreet: Yup.string().required(this.formatMessage('validator.required')),
    orgAddrZipCode: Yup.string()
      .trim()
      .isValidZipCode(this.props.stateCountries, this.selectedOrgCountryCode, this.formatMessage('validator.regex'))
      .max(10, this.formatMessage('validator.zipCodeMaxLength'))
      .required(this.formatMessage('validator.required')),
    orgAddrCity: Yup.string().required(this.formatMessage('validator.required')),
    orgDefaultlang: Yup.string().required(this.formatMessage('validator.required')),
    orgDefaultTimeZone: Yup.string().required(this.formatMessage('validator.required')),
    orgAddrCountryCode: Yup.string().required(this.formatMessage('validator.required')),
    orgBrand: Yup.string().oneOf(...[(this.props?.brands?.map(({ brandName }) => (brandName)))], this.formatMessage('common.brandRegisterError')),
    orgPhoneNumber: Yup.string()
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict()
      .isPhoneNoValid(),
    orgEmail: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict()
      .email(this.formatMessage('validator.email'))
      .orgEmailIsUnique(this.submitClicked, this.props.OrganizationDetails.email),
    isPasswordExpirationPolicyEnabled: Yup.boolean(),
    daysToExpirePassword: Yup.number()
      .when('isPasswordExpirationPolicyEnabled',
        {
          is: true,
          then: Yup.number().typeError(this.formatMessage('validator.numeric'))
            .required(this.formatMessage('validator.required'))
            .integer(this.formatMessage('validator.regex'))
            .min(PasswordExpiryPeriod.min, this.formatMessage('orgManagement.passwordValidity.periodMinError'))
            .max(PasswordExpiryPeriod.max, this.formatMessage('orgManagement.passwordValidity.periodMaxError'))
        }),
    autoReplyMode: Yup.string(),
    autoReplyMessage: Yup.string()
      .when('autoReplyMode',
        {
          is: (autoReplyMode) => autoReplyMode == "ORG_MESSAGE_ONLY" || autoReplyMode == "ORG_MESSAGE_DEFAULT",
          then: Yup.string().trim().required(this.formatMessage('validator.required'))
        })
  })

  populateOrgDetails = () => {
    const { OrganizationDetails: organizationDetail, locale } = this.props
    return {
      orgName: organizationDetail.name || '',
      orgWebsite: organizationDetail.website || '',
      orgPhoneNumber: organizationDetail.phone || '',
      orgEmail: organizationDetail.email || '',
      orgAddrStreet: organizationDetail.street || '',
      orgAddrZipCode: organizationDetail.zipCode || '',
      orgAddrCity: organizationDetail.city || '',
      orgDefaultlang: organizationDetail.language || '',
      orgDefaultTimeZone: organizationDetail.timezone || moment.tz.guess(),
      orgAddrCountryCode: organizationDetail.countryCode || '',
      orgBrand: (organizationDetail?.brandName?.toLowerCase() !== 'neutral') && organizationDetail?.brandName || '',
      orgBrandDisabledTextbox: '',
      isNoBrandingChecked: organizationDetail?.brandName?.toLowerCase() === "neutral",
      locale: getMatchingLocale(organizationDetail.language) || locale,
      organizationId: parseInt(organizationDetail.organizationId) || '',
      isPasswordExpirationPolicyEnabled: organizationDetail.isPasswordExpirationPolicyEnabled || false,
      daysToExpirePassword: organizationDetail.daysToExpirePassword || '',
      autoReplyMode: organizationDetail.autoReplyMode,
      autoReplyEnabled: false,
      autoReplyMessage: organizationDetail.autoReplyMessage || '',
    }
  }

  handleCancel = () => {
    this.props.refetch()
  }

  getBrandId = (orgBrand, isNoBrandingChecked) => {
    if (!orgBrand) {
      orgBrand = DefaultBrandName
    }
    if (isNoBrandingChecked) {
      orgBrand = NoBrandName
    }
    return this.props?.brands?.find(brand => brand.brandName.toLowerCase() === orgBrand.toLowerCase())?.id
  }

  onSubmit = (values, setFieldError) => {
    const orgId = parseInt(this.props.OrganizationDetails.organizationId)
    const { brandName, brandLogoUrl, ...restOrgDetails } = this.props?.OrganizationDetails // brandName and brandLogoUrl are not needed to update organization
    const autoReplyMessage = this.autoReplyMode == "ENABLED" || this.autoReplyMode == "DISABLED"
      ? ''
      : values.autoReplyMessage
    const org = Object.assign({}, { ...restOrgDetails }, {
      organizationId: orgId,
      name: values.orgName,
      email: values.orgEmail,
      phone: values.orgPhoneNumber,
      website: values.orgWebsite,
      language: values.orgDefaultlang,
      timeZone: values.orgDefaultTimeZone,
      street: values.orgAddrStreet,
      city: values.orgAddrCity,
      zipCode: values.orgAddrZipCode.trim(),
      countryCode: values.orgAddrCountryCode,
      isPasswordExpirationPolicyEnabled: values.isPasswordExpirationPolicyEnabled,
      daysToExpirePassword: values.isPasswordExpirationPolicyEnabled ? parseInt(values.daysToExpirePassword) : 0,
      autoReplyMode: values.autoReplyMode,
      autoReplyMessage,
      brandId: this.getBrandId(values.orgBrand, values.isNoBrandingChecked)
    })
    this.setState({ busy: true })
    this.props.updateOrganization({ organizationId: orgId, orgDetail: org })
      .then((response) => {
        const analyticsData = {
          isPasswordExpirationPolicyEnabled: org.isPasswordExpirationPolicyEnabled,
          daysToExpirePassword: org.daysToExpirePassword,
          autoReplyMode: org.autoReplyMode,
          autoReplyMessage: org.autoReplyMessage,
          brandId: org.brandId
        }
        sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Organization), EventType.Update)

        this.collectDataForAnalytics(analyticsData);

        this.setState({ busy: false })
        if (response.data.updateOrganization) {
          this.props.refetch()
        }
        dataService.setData({ isNewBrandSaved: true })
      })

  }
  render() {
    const isMovePatientsEnabled = fflags.variation(
      'enable-bulk-patient-migration',
      false
    )
    const { intl: { formatMessage }, language, sortedCountryCodes, isReadOnly = false, loading } = this.props
    let { brands } = this.props;
    brands = brands?.filter((brand) => brand.brandName.toLowerCase() !== "neutral");
    const { busy } = this.state
    const unsortedCountryCodesWithCountryNames = sortedCountryCodes.map(countryCode => (
      {
        countryCode,
        countryName: formatMessage(T('countryName' + countryCode))
      }
    ))
    const sortedCountryCodesWithCountryNames = _.orderBy(unsortedCountryCodesWithCountryNames, [country => country.countryName.toLowerCase()], ['asc']);

    const initialData = !loading ? this.populateOrgDetails() : {
      orgName: '',
      orgWebsite: '',
      orgPhoneNumber: '',
      orgEmail: '',
      orgAddrStreet: '',
      orgAddrZipCode: '',
      orgAddrCity: '',
      orgDefaultlang: '',
      orgDefaultTimeZone: moment.tz.guess(),
      orgAddrCountryCode: '',
      orgBrand: '',
      locale: '',
      registrationDate: '',
      organizationId: '',
      isPasswordExpirationPolicyEnabled: false,
      daysToExpirePassword: '',
      autoReplyMode: 'ENABLED',
      autoReplyMessage: '',
      isNoBrandingChecked: true,
      orgBrandDisabledTextbox: '',
    }
    return (
      <section>
        <div className="registerPage" data-qa="registration-page">
          <div className="container" >
            <div className="row">
              <div className="col-10 offset-2">
                <div className="pull-left">
                  <h1 data-qa="registration-page-title">
                    <FormattedMessage {...T('register.orgDetailsTitle')} />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-2">
                <div className="pull-left">
                  <p data-qa="required-text">
                    <FormattedMessage
                      {...T('common.required_text')}
                      values={{ mark: <span className="text-primary">*</span> }}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8 offset-2">
                <Formik
                  onSubmit={(values, actions) => this.onSubmit(values, actions.setFieldError)}
                  validationSchema={this.validationSchema}
                  enableReinitialize={true}
                  initialValues={initialData}
                >
                  {({ values: { orgAddrCountryCode, isPasswordExpirationPolicyEnabled, autoReplyMode, isNoBrandingChecked, autoReplyMessage }, dirty, setFieldValue }) => {
                    this.selectedOrgCountryCode = orgAddrCountryCode
                    this.submitClicked = false
                    const autoReplyConfig = getAutoReplyConfigurationForOrganization(autoReplyMode)
                    this.autoReplyMode = autoReplyMode
                    return (
                      <Form>
                        {busy || loading &&
                          <Loading isLoading={busy || loading} />
                        }{!busy && !loading &&
                          <div className="container">
                            <OrganizationPage
                              language={language}
                              sortedCountryCodesWithCountryNames={sortedCountryCodesWithCountryNames}
                              formatMessage={this.formatMessage}
                              isReadOnly={isReadOnly}
                              showValidityPeriod={isPasswordExpirationPolicyEnabled}
                              autoReplyConfig={autoReplyConfig}
                              brands={brands}
                              setFieldValue={setFieldValue}
                              isNoBrandingChecked={isNoBrandingChecked}
                              autoReplyMessage={autoReplyMessage}
                            />
                            {!isReadOnly &&
                              <div className="row">
                                <div className="offset-6" />
                                <div className="col-lg-3">
                                  <Button
                                    secondary
                                    disabled={!dirty}
                                    type="button"
                                    onClick={this.handleCancel}
                                    className="btn-block"
                                    data-qa="cancel-button"
                                  >
                                    {formatMessage(T('common.cancel'))}
                                  </Button>
                                </div>
                                <div className="col-lg-3">
                                  <Button
                                    primary
                                    disabled={!dirty}
                                    type="submit"
                                    className="btn-block"
                                    data-qa="continue-button"
                                    onClick={() => this.handleValidationOnSubmit()}
                                  >
                                    <FormattedMessage {...T('common.save')} />
                                  </Button>
                                </div>
                              </div>
                            }
                          </div>
                        }
                      </Form>
                    )
                  }}
                </Formik>
                {isMovePatientsEnabled && !this.props.isRegisterPage && <MovePatients />}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapCountriesToProps = (ownProps, rawCountries) => {
  const locale = ownProps.locale
  const stateCountries = _get(rawCountries, 'data', [])
  const countriesWithEnabledHcpRegistration = Object.keys(stateCountries)
    .filter(key => stateCountries[key].enableHcpRegistration)
    .map(key => stateCountries[key].countryCode)

  const countries = Object.keys(countriesWithEnabledHcpRegistration).sort().map(label => countriesWithEnabledHcpRegistration[label])
  const sortedLocales = getLocales()
  const sortedCountryCodes = [...countriesWithEnabledHcpRegistration].sort()
  // NOTE: undefined for the diffing algorithm, so that we can determined what changed and what not against initial state (magic with-in Form)

  return {
    locale,
    countries,
    sortedCountryCodes,
    stateCountries,
    intl: ownProps.intl,
    language: sortedLocales

  }
}

const mapOrgToprops = (ownProps, data) => {

  return {
    OrganizationDetails: data.OrganizationDetails,
    loading: data.loading,
    refetch: data.refetch,
  }
}
const getMatchingLocale = (locale) => {
  if (!_.isNil(locale) && locale.length > 2) return locale
  const sortedLocales = getLocales()
  return _.findKey(sortedLocales, function (value, key) {
    if (key.includes(locale)) {
      return value
    }
  })
}
const options = ({ params }) => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only',
  variables: { organizationId: parseInt(params.id) }
})

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: ({ data }) => {
      return { locale: data.locale.locale }
    }
  }),
  graphql(ORGANIZATION_DETAILS_QUERY, {
    props: ({ data, ownProps }) => mapOrgToprops(ownProps, data),
    options
  }),
  graphql(MUTATION_UPDATE_ORGANIZATION_DETAILS, {
    props: ({ mutate }) => ({
      updateOrganization: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(FETCH_ALL_COUNTRIES_QUERY, {
    props: ({ data, ownProps }) => mapCountriesToProps(ownProps, data.countries)
  }),
  graphql(FETCH_ALL_BRANDS, {
    props: ({ data }) => {
      return { brands: data.OrganizationBrands }
    }
  }),
  graphql(STORE_COUNTRIES_MUTATION, {
    name: 'setCountries'
  }),
  injectIntl
)

export default withRouter(withData(OrganizationEditPage))