import _ from 'lodash'
import { ConvertToFormattedDate, getTotalDaysBetweenTwoDate } from "helper/DateUtils";
import { calculateAverageToTwoDecimalPlaces } from 'helper/Utils'

function getOldDateXDaysFromToday(numberOfDays) {
  let today = new Date()
  today.setDate(today.getDate() - numberOfDays)
  return ConvertToFormattedDate(today)
}

export const calculateAverageWearingTimePerDay = (days, wearingTimeDataGroupByDate) => {
  const yesterdayDate = getOldDateXDaysFromToday(1)
  const lastAverageDate = getOldDateXDaysFromToday(days)

  if (!wearingTimeDataGroupByDate[yesterdayDate] || !wearingTimeDataGroupByDate[lastAverageDate])
    return -1

  const latestTicks = wearingTimeDataGroupByDate[yesterdayDate][0].wearingTimeInHr
  const oldestTicks = wearingTimeDataGroupByDate[lastAverageDate][1] == null
    ? wearingTimeDataGroupByDate[lastAverageDate][0].wearingTimeInHr
    : wearingTimeDataGroupByDate[lastAverageDate][1].wearingTimeInHr

  return calculateAverageToTwoDecimalPlaces(latestTicks, oldestTicks, days)
}

export const calculateAverageWearingTimeSinceFirstDate = (wearingTimeDataGroupByDate) => {
  const yesterdayDate = getOldDateXDaysFromToday(1)

  if (!wearingTimeDataGroupByDate[yesterdayDate])
    return -1

  let groupArray = Object.keys(wearingTimeDataGroupByDate).map((date) => {
    return {
      date,
      entries: wearingTimeDataGroupByDate[date]
    }
  })

  const latestTicks = groupArray[0].entries[0].wearingTimeInHr
  const oldestTicks = groupArray[groupArray.length - 1].entries[1] == null
    ? groupArray[groupArray.length - 1].entries[0].wearingTimeInHr
    : groupArray[groupArray.length - 1].entries[1].wearingTimeInHr

  const oldestTicksDate = groupArray[groupArray.length - 1].date

  const totalDays = getTotalDaysBetweenTwoDate(oldestTicksDate, yesterdayDate) + 1

  return calculateAverageToTwoDecimalPlaces(latestTicks, oldestTicks, totalDays)
}