import styled, { css } from "styled-components";
import React from "react";

const CurrentProgress = styled.div((props) => {
  const {
    theme: {
      colors: {
        progressBar: { color: currentProgressBackgroundColor },
      },
    },
    currentProgress: currentProgressWidth,
  } = props;

  return css`
    box-shadow: none;
    height: 8px;
    width: ${currentProgressWidth}%;
    background-color: ${currentProgressBackgroundColor};
  `;
});

const ProgressBar = styled.div((props) => {
  const progressBarWidth = props.progressBarWidth ? props.progressBarWidth : 70;

  return css`
    margin-bottom: 0;
    height: 8px;
    display: inline-block;
    background-color: #b4b8bb;
    box-shadow: none;
    overflow: hidden;
    border-radius: 0.25rem;
    width: ${progressBarWidth}%;

    &.lessonRating-progressBar {
      margin-left: 1%;
    }
  `;
});

export const StyledProgressBar = (props) => {
  return (
    <ProgressBar {...props}>
      <CurrentProgress {...props} />
    </ProgressBar>
  );
};
