import { EntityCrudType } from "./DataCollectionConstants"
import { getEmployeeFromCache, getOrganizationIdFromCache, getDefaultPracticeId } from 'helper/PracticeUtils'
import { isUserOptedForDataConsent, getGuidForUserDataConsentFromCache, isCustomerConsent } from 'helper/UserDataConsentUtils'

const makePayloadData = (customerConsentRecord, noCustomerConsentRecord, legitimateInterestRecord, legacyRecord) => {
  let payload = {
    FullRecord: customerConsentRecord,
    NoCustomerConsentRecord: noCustomerConsentRecord,
    LegitimateInterestRecord: legitimateInterestRecord,
    LegacyRecord: legacyRecord
  }

  payload.Records = Object.values(payload).filter((o) => o !== null)

  const userRelatedIds = makeUserRelatedIds()

  payload.UserRelatedIds = {
    FullRecord: customerConsentRecord && userRelatedIds,
    NoCustomerConsentRecord: noCustomerConsentRecord && userRelatedIds,
    LegitimateInterestRecord: legitimateInterestRecord && userRelatedIds,
    LegacyRecord: legacyRecord && makeUserRelatedIdsForLegacy()
  }

  return payload
}

export const applyToPayloadData = (payload, func) => {
  let payloadData = makePayloadData(
    payload.FullRecord && func(payload.FullRecord, payload.UserRelatedIds.FullRecord),
    payload.NoCustomerConsentRecord && func(payload.NoCustomerConsentRecord, payload.UserRelatedIds.NoCustomerConsentRecord),
    payload.LegitimateInterestRecord && func(payload.LegitimateInterestRecord, payload.UserRelatedIds.LegitimateInterestRecord),
    payload.LegacyRecord && func(payload.LegacyRecord, payload.UserRelatedIds.LegacyRecord)
  )

  payloadData.upid = payload.upid
  return payloadData
}

const makeUserRelatedIds = () => {
  const orgId = getOrganizationIdFromCache()
  const emp = getEmployeeFromCache()
  const practiceId = getDefaultPracticeId()
  return {
    organizationId: orgId && orgId.toString(),
    employeeId: emp && emp.id && emp.id.toString(),
    practiceId: practiceId && practiceId.toString()
  }
}

const makeUserRelatedIdsForLegacy = () => {
  const { anonOrganizationId: orgId, anonPracticeId: practiceId, anonEmployeeId: empId } = getGuidForUserDataConsentFromCache()
  return {
    organizationId: orgId && orgId.toString(),
    employeeId: empId && empId.toString(),
    practiceId: practiceId && practiceId.toString()
  }
}

export const makePayloadDataForCustomerConsent = (customerConsentRecord, noCustomerConsentRecord, upid) => {
  let legacyRecord = null;
  if (isUserOptedForDataConsent()) {
    legacyRecord = customerConsentRecord
  }

  let payloadData = makePayloadData(customerConsentRecord, noCustomerConsentRecord, null, legacyRecord)
  payloadData.upid = upid
  return payloadData
}

export const makePayloadDataForLegitimateInterest = (record) => {
  let legacyRecord = null;
  if (isUserOptedForDataConsent()) {
    legacyRecord = record
  }

  return makePayloadData(null, null, record, legacyRecord)
}

export const makePayloadDataForNoCustomerConsent = (customerConsentRecord, noCustomerConsentRecord) => {
  let legacyRecord = null;
  if (isUserOptedForDataConsent()) {
    legacyRecord = customerConsentRecord
  }

  return makePayloadData(null, noCustomerConsentRecord, null, legacyRecord)
}

export const EqualizerPayload = (customer, payload) => {
  if (isCustomerConsent(customer)) {
    return makePayloadDataForCustomerConsent(
      EqualizerPayloadWithIds(payload),
      EqualizerPayloadWithoutIds(payload),
      customer.upid
    )
  } else {
    return makePayloadDataForNoCustomerConsent(
      EqualizerPayloadWithIds(payload),
      EqualizerPayloadWithoutIds(payload)
    )
  }
}

export const LanguageChangeAnalyticsData = (oldLang, newLang) => {
  return makePayloadDataForLegitimateInterest({
    OldLang: oldLang,
    NewLang: newLang
  })
}

export const LogoutData = (data) => {
  return makePayloadDataForLegitimateInterest(data);
}

export const MarketingMessageAnalyticsData = (messageLength, numberOfRecipients) => {
  return makePayloadDataForLegitimateInterest({
    textLength: messageLength,
    numberOfRecipients: numberOfRecipients
  })
}

export const OrganizationUpdateData = (data) => {
  return makePayloadDataForLegitimateInterest(data)
}

export const EntityCrudPayloadData = (entityType) => {
  return makePayloadDataForLegitimateInterest({
    EntityType: entityType
  })
}

export const RemoteTuningAnalyticsData = (equalizerValues, programName) => {
  return makePayloadDataForLegitimateInterest({
    Equalizer: equalizerValues,
    ProgramName: programName
  })
}

export const ChatAnalyticsData = (customer, practice, chatData) => {
  if (isCustomerConsent(customer)) {
    return makePayloadDataForCustomerConsent(
      ChatAnalyticsDataWithIds(customer, practice, chatData),
      ChatAnalyticsDataWithoutIds(customer, practice, chatData),
      customer.upid
    )
  } else {
    return makePayloadDataForNoCustomerConsent(
      ChatAnalyticsDataWithIds(customer, practice, chatData),
      ChatAnalyticsDataWithoutIds(customer, practice, chatData)
    )
  }
}

export const SatisfactionData = (customer, satisfactionObj) => {
  if (isCustomerConsent(customer)) {
    return makePayloadDataForCustomerConsent(
      SatisfactionDataWithIds(customer, satisfactionObj),
      SatisfactionDataWithoutIds(customer, satisfactionObj),
      customer.upid
    )
  } else {
    return makePayloadDataForNoCustomerConsent(
      SatisfactionDataWithIds(customer, satisfactionObj),
      SatisfactionDataWithoutIds(customer, satisfactionObj)
    )
  }
}

export const callData = (customer, callObj) => {
  if (isCustomerConsent(customer)) {
    return makePayloadDataForCustomerConsent(
      callDataWithIds(customer, callObj),
      callDataWithoutIds(customer, callObj),
      customer.upid
    )
  } else {
    return makePayloadDataForNoCustomerConsent(
      callDataWithIds(customer, callObj),
      callDataWithoutIds(customer, callObj)
    )
  }
}

export const HearingDevicesData = (hearingDevices) => {
  const { leftDevice, rightDevice } = hearingDevices
  return {
    HALeft: leftDevice && {
      name: leftDevice.manufacturer ? leftDevice.manufacturer + " " + leftDevice.model : leftDevice.model,
      hiTypeUri: leftDevice.hiTypeUri
    },
    HARight: rightDevice && {
      name: rightDevice.manufacturer ? rightDevice.manufacturer + " " + rightDevice.model : rightDevice.model,
      hiTypeUri: rightDevice.hiTypeUri
    }
  }
}

export const NavigationTrackingData = (data) => {
  return makePayloadDataForLegitimateInterest(data)
}

export const PlatformPayloadData = (operatingSystem, currentBrowser, deviceType) => {
  return makePayloadDataForLegitimateInterest(
    {
      OperatingSystem: operatingSystem,
      BrowserName: currentBrowser,
      DeviceType: deviceType
    })
}

export const PatientPayloadData = (customer, isPracticeChanged = false) => {
  if (isCustomerConsent(customer)) {
    return makePayloadDataForCustomerConsent(
      PatientPayloadDataWithIds(customer, isPracticeChanged),
      PatientPayloadDataWithoutIds(customer, isPracticeChanged),
      customer.upid
    )
  } else {
    return makePayloadDataForNoCustomerConsent(
      PatientPayloadDataWithIds(customer, isPracticeChanged),
      PatientPayloadDataWithoutIds(customer, isPracticeChanged)
    )
  }

}

export const BulkPatientMove = (obj) => {
  return makePayloadDataForLegitimateInterest(
    {
      EntityType: EntityCrudType.Organization,
      OrgId: obj.orgId,
      LoggedInEmployeeId: obj.employeeId,
      FromUserId: obj.fromUserId,
      ToUserId: obj.toUserId
    })
}

export const MakeNotificationsPayload = (unreadNotificationCount, totalNotificationCount, notificationType) => {
  const rawPayload = {
    UnreadNotificationCount: unreadNotificationCount,
    TotalNotificationCount: totalNotificationCount,
    NotificationType: GetNotificationTypeValue()
  }

  const payload = makePayloadDataForLegitimateInterest(rawPayload)
  return payload

  function GetNotificationTypeValue() {
    if (notificationType == null) {
      return 'NA';
    }
    return NotificationMapper[notificationType]
  }
}

const SatisfactionDataWithIds = (customer, satisfactionObj) => {
  const { hearingDevices } = customer
  return {
    uuid: customer.uuid,
    Date: satisfactionObj.date,
    Rating: satisfactionObj.rating,
    BrandId: customer.brandDisplayId,
    IsDaEnabled: customer.digitalAssistantConsent,
    HearingDevices: HearingDevicesData(hearingDevices)
  }
}

const SatisfactionDataWithoutIds = (customer, satisfactionObj) => {
  const { hearingDevices } = customer
  return {
    uuid: null,
    Date: satisfactionObj.date,
    Rating: satisfactionObj.rating,
    BrandId: customer.brandDisplayId,
    IsDaEnabled: customer.digitalAssistantConsent,
    HearingDevices: HearingDevicesData(hearingDevices)
  }
}

const callDataWithIds = (customer, callObj) => {
  const { hearingDevices } = customer
  return {
    CallType: callObj.callType,
    uuid: customer.uuid,
    Status: callObj.status,
    BrandId: customer.brandDisplayId,
    DurationInMiliSecond: callObj.duration,
    LibUsed: callObj.lib,
    HearingDevices: HearingDevicesData(hearingDevices)
  }
}

const callDataWithoutIds = (customer, callObj) => {
  const { hearingDevices } = customer
  return {
    CallType: callObj.callType,
    uuid: null,
    Status: callObj.status,
    BrandId: customer.brandDisplayId,
    DurationInMiliSecond: callObj.duration,
    HearingDevices: HearingDevicesData(hearingDevices)
  }
}

const PatientPayloadDataWithIds = (customer, isPracticeChanged = false) => {
  const { hearingDevices } = customer
  return {
    EntityType: EntityCrudType.Patient,
    uuid: customer.uuid,
    BrandId: customer.brandDisplayId,
    HearingDevices: HearingDevicesData(hearingDevices),
    ...(isPracticeChanged && { IsPracticeChanged: isPracticeChanged })
  }
}

const PatientPayloadDataWithoutIds = (customer, isPracticeChanged = false) => {
  const { hearingDevices } = customer
  return {
    EntityType: EntityCrudType.Patient,
    uuid: null,
    BrandId: customer.brandDisplayId,
    HearingDevices: HearingDevicesData(hearingDevices),
    ...(isPracticeChanged && { IsPracticeChanged: isPracticeChanged })
  }
}

const ChatAnalyticsDataWithIds = (customer, practice, chatData) => {
  return {
    Customer: customer,
    Practice: practice,
    ChatData: chatData
  }
}

const ChatAnalyticsDataWithoutIds = (customer, practice, chatData) => {
  return {
    Customer: {
      ...customer,
      uuid: null,
      id: null
    },
    Practice: practice,
    ChatData: chatData
  }
}

const EqualizerPayloadWithIds = (payload) => {
  return payload
}

const EqualizerPayloadWithoutIds = (payload) => {
  return {
    ...payload,
    uuid: null
  }
}

const NotificationMapper = ['DailySatisfaction', 'Lesson']