import React from 'react'
import { KnobWithTitle, Knob } from 'styledComponents/Div/DivComponent/DivComponent'

class KnobWithTitleComponent extends React.PureComponent {
  static defaultProps = {
    active: true,
    title: '',
    onToggle: Function,
    width: 0,
    height: 0
  }

  render() {
    const { title, width, height, onToggle, active } = this.props
    return (
      <KnobWithTitle width={width} height={height} active={active}>
        <div>
          <b>
            {title}
          </b>
        </div>
        <Knob onClick={onToggle} active={active} />
      </KnobWithTitle>
    )
  }
}

export default KnobWithTitleComponent
