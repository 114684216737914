import { gql } from "@apollo/client";

export const FETCH_ALL_BRANDS = gql`
  query OrganizationBrandsQuery {
    OrganizationBrands {
      id
      brandName
      brandLogoUrl
    }
  }
`;
