import React from 'react'
import { Field } from 'formik'
import { T } from 'core'
import { FormattedMessage } from 'react-intl'
import PasswordComponent from 'formfields/Password'
import InputField from 'formfields/InputField'
export default function AccountsPage(props) {
  const isDisabled = props.isReviewPage || props.busy
  return (
    <div>
      <h2 data-qa="title">
        {<FormattedMessage {...T('register.adminAccount')} />}
      </h2>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <Field
              name="firstName"
              component={InputField}
              label={props.formatMessage('common.firstName')}
              labelClassName="required"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="col-md-6">
          <Field
            name="lastName"
            component={InputField}
            label={props.formatMessage('common.lastName')}
            labelClassName="required"
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Field
            type="email"
            name="email"
            component={InputField}
            label={props.formatMessage('common.email')}
            labelClassName="required"
            disabled={isDisabled}
          />
        </div>
        <div className="col-md-6">
          <Field
            name="phone"
            component={InputField}
            label={props.formatMessage('common.mobileNumber')}
            labelClassName="required"
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Field
            name="password"
            component={PasswordComponent}
            oldPassword={false}
            required
            arrangedHorizontally
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  )
}