import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'

import { countries as countryConstants } from 'helper/constants'

import { REMOVE_QUERY_PARAM } from './../../../graphql/mutations/UpdateLocationMutation'
import EditForm from '../EditForm'
import { withRouter } from 'helper/withRouter'
import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'

import { ORGANIZATION_DETAILS_QUERY } from 'graphql/queries/OrganizationDetailsQuery'

const mapProps = ({ data: { refetch, loading, Countries = [], Me } }) => {
  const countries = Countries.filter(item => item.enableHcpRegistration)
  return {
    Me,
    refetch,
    loading,
    countries,
    openingHours: [],
    isNewPractice: true,
  }
}

const feedOrganizationDetailsToProps = ({ data: { loading, OrganizationDetails } }) => {
  return {
    OrganizationDetails,
    OrganizationDetailsLoading: loading
  }
}
const QUERY_PRACTICE_CREATE = gql`
  query PracticeCreateQuery{
    Me {
      employee {
        id
        locale
        email
      }
      organization {
        inactiveFeatures
      }
      countryCode
    }
    Countries {
      countryCode
      enableHcpRegistration
      zipCodeValidation
    }
  }
`
const MUTATION_CREATE_PRACTICE = gql`
  mutation createPracticeMutation($practice: PracticeInput!) {
    createPractice(practice: $practice) {
      id
    }
  }
`

const MUTATION_UPLOAD_LOGO_PRACTICE = gql`
  mutation uploadPracticeLogoMutation($file: String, $practiceId: Int) {
    uploadPracticeLogo(file: $file, practiceId: $practiceId)
  }
`
const options = () => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only',
  variables: { organizationId: parseInt(getOrganizationIdFromCache()) }
})

const withData = compose(
  graphql(QUERY_PRACTICE_CREATE, {
    props: mapProps
  }),
  graphql(MUTATION_CREATE_PRACTICE, {
    props: ({ mutate }) => ({
      createPractice: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPLOAD_LOGO_PRACTICE, {
    props: ({ mutate }) => ({
      uploadPracticeLogo: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(ORGANIZATION_DETAILS_QUERY, {
    props: feedOrganizationDetailsToProps,
    options
  }),
  graphql(REMOVE_QUERY_PARAM, {
    props: ({ mutate }) => ({
      updateLocation: (location, key) => mutate({ variables: { location, key } })
    })
  }),
  injectIntl
)

export default withRouter(withData(EditForm))
