import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { omit } from 'lodash'
import { T } from 'core'
import { DropdownButton } from 'react-bootstrap'
import KnobWithTitle from 'components/KnobWithTitleComponent'
import { notEqual } from 'helper/Utils'
import { Button } from 'styledComponents/Button'
import {
  ExtraInfo,
  FilterOnBlock,
  ActionButtonsDropdown
} from 'styledComponents/Div/DivComponent/DivDropdowns'

class MarketingProviderFilter extends React.Component {
  static displayName = 'MarketingProviderFilter'

  static defaultProps = {
    onChange: Function,
    isFilterOpen: false,
    onlyMyPatients: false,
    onOpenFilter: Function
  }

  state = {
    knobActive: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.onlyMyPatients !== nextProps.onlyMyPatients) {
      this.setState({
        knobActive: nextProps.onlyMyPatients
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cleanNextProps = omit(nextProps, ['onChange', 'onOpenFilter'])
    const cleanProps = omit(this.props, ['onChange', 'onOpenFilter'])
    return (
      notEqual(cleanNextProps, cleanProps) || notEqual(nextState, this.state)
    )
  }

  onKnobChange = () => {
    this.setState({
      knobActive: !this.state.knobActive
    })
  }

  onApplyFilter = () => {
    this.props.onChange({ onlyMyPatients: this.state.knobActive })
    this.onClose()
  }

  onOpenFilter = () => {
    this.props.onOpenFilter(!this.props.isFilterOpen)
  }

  onClose = () => {
    this.setState(
      {
        isFilterActive: this.state.knobActive
      },
      () => {
        this.props.onOpenFilter(!this.props.isFilterOpen)
      }
    )
  }

  onCancel = () => {
    this.onClose()
  }

  getFilterTitle = () => {
    const { intl: { formatMessage } } = this.props
    const values = `( ${this.state.knobActive
      ? formatMessage(T('common.me'))
      : formatMessage(T('common.all'))} )`
    return (
      <FormattedMessage
        {...T('marketing.messages.menu-provider-all')}
        values={{ 0: values }}
      />
    )
  }

  render() {
    const { intl: { formatMessage }, isFilterOpen } = this.props
    const { knobActive, isFilterActive } = this.state
    const title = this.getFilterTitle()
    const knobTitle = (
      <FormattedMessage {...T('marketing.messages.only-patients')} />
    )
    const extraInfo = (
      <FormattedMessage {...T('marketing.messages.only-patients-info')} />
    )
    const isFilterActiveClassName = isFilterActive ? 'filterIsActive' : ''

    return (
      <FilterOnBlock>
        <DropdownButton
          variant={'default'}
          title={title}
          id={`providersFilter`}
          data-qa="providersFilter"
          show={isFilterOpen}
          onToggle={(isOpen) => {this.onOpenFilter(isOpen)}}
          className={isFilterActiveClassName}
        > 
          <KnobWithTitle
            title={knobTitle}
            onToggle={this.onKnobChange}
            active={knobActive}
          />
          <ExtraInfo>
            {extraInfo}
          </ExtraInfo>
          <ActionButtonsDropdown>
            <Button
              secondary
              onClick={this.onCancel}
              data-qa="cancel-button"
            >
              {formatMessage(T('common.cancel'))}
            </Button>
            <Button
              type="submit"
              primary
              data-qa="select-button"
              onClick={this.onApplyFilter}
            >
              {formatMessage(T('common.select'))}
            </Button>
          </ActionButtonsDropdown>
        </DropdownButton>
      </FilterOnBlock>
    )
  }
}

export default injectIntl(MarketingProviderFilter)
