import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { compose } from 'lodash/fp'
import { T } from 'core'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../helper/withQuery'

import { requestPasswordReset } from 'actions/authentication/Password'

import Loading from 'components/Loading'
import InputField from 'formfields/InputField.js'

class PasswordResetRequest extends Component {
  state = {
    autoSubmitted: false
  }

  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  passwordResetRequestValidationSchema = Yup.object().shape({
    username: Yup.string()
      .email(this.formatMessage('validator.email'))
      .required(this.formatMessage('validator.required'))
  })

  //auto committing employee user type
  componentDidMount() {
    const { userType } = this.props
    if (userType === 'employee') {
      this.requestReset({ username: this.props.userName })

      this.setState({
        autoSubmitted: true
      })
    }
  }

  requestReset = (username, setStatus, setSubmitting, setFieldError) => {
    const { intl: { formatMessage } } = this.props
    requestPasswordReset(username)
      .then(() => {
        setStatus('submitted')
      })
      .catch(() => {
        setFieldError('error', formatMessage(T('reset.failed')))
      })
      .finally(setSubmitting(false))
  }

  showRootPage = () => {
    this.props.navigate('/')
  }

  renderMessage() {
    return (
      <div>
        <p>
          <FormattedMessage {...T('reset.text')} />
        </p>
        <button
          className="btn btn-primary col-lg-3"
          onClick={this.showRootPage}
          type="button"
          data-qa="go-to-login-button"
        >
          <FormattedMessage {...T('register.gotoLogin')} />
        </button>
      </div>
    )
  }

  renderContent() {
    const { intl: { formatMessage } } = this.props

    return (
      <div>
        <Field
          type="email"
          name="username"
          label={formatMessage(T('common.email'))}
          component={InputField}
          labelClassName="required"
        />
        <button className="btn btn-primary col-lg-3 col-6" type="submit" data-qa="send-button">
          <FormattedMessage {...T('common.send')} />
        </button>
      </div>
    )
  }

  render() {
    const { autoSubmitted } = this.state
    return (
      <Formik
        onSubmit={(values, props) =>
          this.requestReset(
            values.username,
            props.setStatus,
            props.setSubmitting,
            props.setFieldError
          )}
        validationSchema={this.passwordResetRequestValidationSchema}
        initialValues={{ username: '', error: '' }}
        isInitialValid={values => this.passwordResetRequestValidationSchema.isValidSync(values)}
      >
        {({ status, isSubmitting }) =>
          <Form>
            <div>
              <Loading isLoading={isSubmitting} />
              <div className="container">
                <div className="row">
                  <div className="col-8 offset-2">
                    <ErrorMessage
                      name="error"
                      render={msg =>
                        <div
                          className="alert alert-danger"
                          style={{ padding: '5%', marginBottom: '5%' }}
                        >
                          {msg}
                        </div>}
                    />
                    {status === 'submitted' || autoSubmitted
                      ? this.renderMessage()
                      : this.renderContent()}
                  </div>
                </div>
              </div>
            </div>
          </Form>}
      </Formik>
    )
  }
}

const options = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    userType: query.user,
    userName: query.userName
  }
})
const QUERY = gql`
  query {
    Me {
      employee {
        id
      }
    }
  }
`
const withData = compose(
  graphql(QUERY, {
    options
  }),
  injectIntl
)

export default withRouter(withQuery(withData(PasswordResetRequest)))
