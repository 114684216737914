import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
export const StyledLink = styled(Link)((props) => {
  const {
    theme: {
      colors: {
        tab: {
          active: tabBackgroundActive,
          hover: tabBackgroundHover,
          color: tabFontColorActive,
        },
      },
    },
  } = props;

  return css`
    display: block;
    padding: 10px;
    color: ${tabBackgroundActive};
    text-decoration: none;
    text-align: center;
    font-weight: bold;

    &:hover {
      color: ${tabBackgroundHover};
    }

    &.active {
      background-color: ${tabBackgroundActive};
      border-color: ${tabBackgroundActive};
      color: ${tabFontColorActive};
      &:hover {
        background-color: ${tabBackgroundHover};
        border-color: ${tabBackgroundActive};
        color: ${tabFontColorActive};
      }
    }

    .unread {
      color: #ff0000;
      position: absolute;
      font-size: 8px;
      top: 6px;
    }
  `;
});

StyledLink.defaultProps = {
  to: "#"
}
