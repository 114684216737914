import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import { StyledProgressBar } from "styledComponents/ProgressBar/StyledProgressBar" 

const CustomerProgress = ({ label, currentProgress = 0, barWidth = 40 }) => {
    return (
      <React.Fragment>
        <span className="percentage">
         <FormattedMessage {...T(`${label}`)} />: <b>{Math.round(currentProgress)}%</b>
        </span>{' '}
        <StyledProgressBar progressBarWidth={barWidth} currentProgress={currentProgress} className="lessonRating-progressBar"/>
      </React.Fragment>
    )
  }

  export default injectIntl(CustomerProgress)