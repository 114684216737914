import { InitializeSignalrConnection } from '../../signalr'
import { signalRHubUrl } from 'helper/constants'
import { ISignalRNotification } from '../../typescript/interfaces/INotificationDropdown'


export const invokeSignalRConnectionForNotification = (notification: ISignalRNotification): void => {
    notification.connection = InitializeSignalrConnection(signalRHubUrl.NotificationHub, notification.groupId)
    notification.connection.on('Notification', (data) => {
        notification.setNotificationFromSignalR(JSON.parse(data));
    })
}

export const stopSignalrConnectionForNotification = (notification: ISignalRNotification): void => {
    if (notification && notification.connection && notification.connection.connectionState == 'Connected') {
        notification.connection.invoke('LeaveGroup', notification?.groupId?.toString()).
            then(() => notification?.connection?.stop())
    }
}
