import { gql } from "@apollo/client";

export const MUTATION_UPDATE_PATIENT = gql`
  mutation updatePatient($patientId: Int!, $patient: PatientInput!) {
    updatePatient(patientId: $patientId, patient: $patient) {
      id
      firstName
      lastName
      phone
      email
      practiceId
      employeeId
      hearingDevices {
        leftDevice {
          id
        }
        rightDevice {
          id
        }
        hdPrograms {
          name
          type
          programNumber
        }
        persistentConfiguration
        teleCareVersion
      }
    }
  }
`