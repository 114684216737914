import styled from 'styled-components'

export const PracticeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const PracticeKpi = styled.div`
  border-right: 1px solid #ddd;
  display: inline-block;
  margin: 15px 0;
  padding: 5px 0;
  text-align: center;
  width: 25%;
  &:last-child {
    border-right: none;
  }
  .practice-kpi {
    &__icon {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 28px;
      line-height: 27px;
      margin-bottom: 5px;
    }

    &__icon--patientSatisfaction:before {
      content: '\f118';
    }

    &__icon--processDuration:before {
      content: '\f017';
    }

    &__icon--patientsGained:before {
      content: '\f234';
    }

    &__icon--successRate:before {
      content: '\f091';
    }

    &__value {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 3px;
    }

    &__delta {
      color: ${props => props.theme.colors.brandNeutral};
      font-size: 11px;
      font-weight: 700;

      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 12px;
        margin-left: 4px;
      }

      &--up:after {
        content: "\f0d8";
      }

      &--down:after {
        content: "\f0d7";
      }

      &--flat:after {
        content: "\f068";
      }
    }

    &__indicator--positive {
      .practice-kpi__icon,
      .practice-kpi__delta:after {
        color: ${props => props.theme.colors.brandSuccess};
      }
    }

    &__indicator--neutral {
      .practice-kpi__icon,
      .practice-kpi__delta:after {
        color: ${props => props.theme.colors.brandNeutral};
      }
    }

    &__indicator--negative {
      .practice-kpi__icon,
      .practice-kpi__delta:after {
        color: ${props => props.theme.colors.brandDanger};
      }
    }

    &__indicator--inactive {
      .practice-kpi__icon,
      .practice-kpi__delta:after {
        color: #ccc;
      }
    }
  }
`
export const Practice = styled.div`
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
  .practice {
    &__header {
      background-color: #e6e6e6;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: 700;
      font-size: 16px;
      height: 60px;
      line-height: 59px;
      padding: 0 20px;
      vertical-align: top;
      display: flex;
      > div {
        flex: 9;
      }
    }
    &__patients-overall {
      color: #808080;
      text-align: right;
      .fa {
        font-size: 24px;
        font-weight: normal;
        margin-left: 10px;
      }
      .custom-tooltip {
        margin-top: -5px;
      }
    }

    &__patients-overall-value {
      position: relative;
      top: -2px;
      margin-right: 5px;
      margin-left: 5px;
    }

    &__text--truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__body {
      border: 1px solid #ddd;
      border-top-color: #999;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &--bg-dark {
      .practice__header {
        background-color: #808080 !important;
        color: #fff;
      }
      .practice__patients-overall {
        color: #d9d9d9;
      }
    }

    &--bg-darker {
      .practice__header {
        background-color: #4d4d4d;
        color: #fff;
      }
      .practice__patients-overall {
        color: #a6a6a6;
      }
    }
  }
`
