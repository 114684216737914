import connector from './HttpConnector'
import { STORAGE_KEY } from 'helper/constants'
export const interceptorConfiguration = {  
  // specify if request should be intercepted 
  // here we intercept all requests going to telecare servers and to localhost (i.e. development)
  shouldIntercept: (request) => {            
    return request.url.startsWith(connector.getBaseUrl()) || request.url.includes('localhost')
  },
  // add authorization to request
  authorizeRequest: (request, accessToken) => {    
    request.headers.set('Authorization', `Bearer ${accessToken}`)    
    return request;
  },
  
  // create a request for renewing access token
  createAccessTokenRequest: (refreshToken) => {    
    return new Request(connector.url(`/oauth/token?grant_type=refresh_token&client_id=mobile-application&refresh_token=${refreshToken}`), {
      method: 'POST',
    });
  },
  // extract the new access and refresh tokens from response
  parseAccessToken: (response) => {        
    return response.clone().json().then(json => {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(json))
      return { accessToken: json.access_token, refreshToken: json.refresh_token }
    })
  },
}