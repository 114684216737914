import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { OrgManagementPage } from 'styledComponents/Div/DivComponent/DivOrgManagement'
import Loading from 'components/Loading'

import SectionSelector from 'components/OrgManagement/SectionSelector'
import * as Panel from 'components/_Profile/components/Panel'
import { checkPermission } from 'helper/AuthHelper'
import { withRouter } from "helper/withRouter";
import { Outlet } from 'react-router-dom';

export class OrgManagement extends React.Component {
  get headline() {
    return <FormattedMessage {...T('orgManagement.users')} />
  }

  createNewElementInfo = () => {
    const { Me } = this.props

    switch (this.props.location.pathname) {
      case '/overview/orgManagement/users':
        return {
          label: 'userselector.add_user',
          link: '/employee/invite',
          visible: checkPermission('ADD_EMPLOYEE', { Me })
        }
      case '/overview/orgManagement/practices':
        return {
          label: 'manage.add_new_practice',
          link: '/practice/create',
          visible: checkPermission('ADD_PRACTICE', { Me })
        }
      case '/overview/orgManagement/lessons':
        return {
          label: 'journeyConfigurator.manageLessonSets.CreateSet',
          link: '/lessontemplate/create',
          state: Me && Me.employee && Me.employee.id,
          visible: Me && Me.employee
        }
      default:
        return { label: '', link: '', visible: false, state: undefined }
    }
  }

  render() {
    const { location, loading, Me } = this.props
    if (loading) {
      return <Loading isLoading={loading} />
    }

    return (
      <Panel.Base className="HcpManagement" modifier="patients-overview">
        <div className="container">
          <h2>
            {this.headline}
          </h2>
          <Panel.Base modifier="patients-filters">
            <div className="toggler-wrapper">
              <Panel.Item>
                <SectionSelector
                  location={location}
                  createNewElementData={this.createNewElementInfo()}
                  me={Me}
                />
              </Panel.Item>
            </div>
          </Panel.Base>
          <OrgManagementPage>
            {!loading && <main>
              <Outlet context={Me} />
            </main>
            }
          </OrgManagementPage>
        </div>
      </Panel.Base>
    )
  }
}

const QUERY = gql`
  query ShortMeQuery {
    Me {
      employee {
        id
        orgManager
        manager
      }
      organizationId
    }
  }
`

const mapProps = ({ data: { refetch, loading, Me } }) => {
  return {
    refetch,
    loading,
    Me
  }
}

const withData = compose(
  graphql(QUERY, {
    props: mapProps
  }),
  injectIntl
)

export default withRouter(withData(OrgManagement))
