import LessonSetFormForSignia from '../LessonSetFormForSignia'
import { MUTATION_UPDATE_LESSON_SET } from '../../../graphql/mutations/LessonSetMutation'
import { QUERY_ALL_LESSONSET_FOR_SIGNIA } from '../../../graphql/queries/LessonSetQuery'
import { IMapProps } from '../../../typescript/interfaces/ILessonTemplate'

import { compose } from 'lodash/fp'
import { WatchQueryFetchPolicy } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl'

import { withQuery } from '../../../helper/withQuery'
import { withRouter } from 'helper/withRouter'
import { getEmployeeFromCache } from 'helper/PracticeUtils'

const mapProps = ({
  data: { refetch, loading, DefaultLessonSetForSignia, LessonSetForSignia },
  ownProps: { params, navigate }
}: IMapProps) => {
  return {
    refetch,
    loading,
    DefaultLessonSetForSignia,
    navigate,
    params,
    LessonSetForSignia
  }
}

const options = ({ employee }) => {
  return {
    variables: { hcpId: employee && employee.id ? employee.id : 0 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only' as WatchQueryFetchPolicy
  }
}

const withData = compose(
  graphql(QUERY_ALL_LESSONSET_FOR_SIGNIA, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_UPDATE_LESSON_SET, {
    props: ({ mutate }) => ({
      updateLessonSetForSignia: (variables: any) =>
        mutate({
          variables
        })
    })
  })
)

export default withRouter(withQuery(withData(injectIntl(LessonSetFormForSignia))))

