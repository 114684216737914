import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { T } from 'core'
import SortableTableHeader from 'components/SortableTableHeader'
import { injectIntl } from 'react-intl'
import { checkPermission } from 'helper/AuthHelper'
import { compose } from 'lodash/fp'
import { shopTracking } from 'helper/shopTracking'
import ConfirmDeletionPopup from 'components/Popups/ConfirmDeletionPopup'
import { UPDATE_OVERLAY, SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { EMPLOYEES_QUERY } from 'graphql/queries/EmployeesQuery'
import Tooltip from 'react-tooltip'
import {
  SHOW_POPUP,
  DELETE_PATIENT_POPUP,
  getOverlayUpdateData,
  P_DEFAULT_POPUP
} from 'components/Popups/helper/OverlayUpdateData'

import { StyledTable } from "styledComponents/Table/StyledTable"
import { faEdit, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'
import { sendDataToDataCollection } from '../../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from '../../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../../helper/DataCollection/DataCollectionConstants'
import { DataCollectionType } from 'helper/constants'

export class Table extends React.Component {
  constructor(props) {
    super(props)
  }

  setSort = sortField => () => {
    this.props.setSort(sortField)
  }

  closeConfirmationPopup = () => {
    this.props.updateOverlay(null, null, false)
  }

  handleDelete = employeeId => {
    this.closeConfirmationPopup()
    this.props
      .deleteEmployee({ employeeId })
      .then((result) => {
        if (result.errors) {
          const { message } = result.errors[0]
          const errorCode = message.match('code: (.*),')
          if (errorCode) {
            const popup = getOverlayUpdateData(SHOW_POPUP, {
              type: P_DEFAULT_POPUP,
              payload: {
                title: 'employeeEdit.errorDeleteFailed',
                message: errorCode[1].replace(/\"/g, "")
              }
            })
            this.props.updateOverlay({ ...popup }, true, true)
          }
        }
        else {
          shopTracking({
            ...this.props.location,
            action: 'userDeleted'
          })
          sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Employee), EventType.Delete)
          this.props.refetch()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  showConfirmationPopup = id => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: DELETE_PATIENT_POPUP + id,
        payload: {
          title: 'employeeEdit.confirmDeletionHeader',
          message: 'employeeEdit.confirmDelete'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }

  render() {
    const { sort, employees = [], Me, intl: { formatMessage } } = this.props
    return (
      <StyledTable className="table--users">
        <thead>
          <tr>
            <SortableTableHeader
              className="col-2"
              name="firstName"
              onClick={this.setSort('firstName')}
              value={formatMessage(T('common.firstName'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-2"
              name="lastName"
              onClick={this.setSort('lastName')}
              value={formatMessage(T('common.lastName'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-4"
              name="email"
              onClick={this.setSort('email')}
              value={formatMessage(T('common.email'))}
              sort={sort}
            />
            <SortableTableHeader
              className="col-2"
              name="phone"
              onClick={this.setSort('phone')}
              value={formatMessage(T('common.mobileNumber'))}
              sort={sort}
            />
            <th name="role" className="col-2">
              <FormattedMessage {...T('common.role')} />
            </th>
            <th className="text-center col-1">&nbsp;</th>
            <th className="text-center col-1">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((item, index) =>
            <tr key={`${index}__manage-row`}>
              <td>
                {item.firstName}
              </td>
              <td>
                {item.lastName}
              </td>
              <td>
                {item.email || item.username}
              </td>
              <td>
                {item.phone}
              </td>
              <td>
                {item.orgManager
                  ? <FormattedMessage {...T('form.org-manager')} />
                  : item.manager ? <FormattedMessage {...T('form.practice-manager')} /> : <FormattedMessage {...T('form.employee')} />}
              </td>
              {<td className="text-center">
                {checkPermission('EDIT_EMPLOYEE', { Me, employee: item }) ?
                  <Link
                    data-qa="manage__edit-button"
                    to={`/employee/${item.id}/edit`}
                    data-tip
                    data-for={`tooltip-edit-${item.id}`}
                  >
                    <StyledFontAwesomeIcon icon={faEdit} fixedWidth type="edit" />
                  </Link> : <Link
                    data-qa="manage__view-button"
                    to={`/employee/${item.id}/view`}
                    data-tip
                    data-for={`tooltip-view-${item.id}`}
                  >
                    <StyledFontAwesomeIcon icon={faInfoCircle} type="info" />
                  </Link>
                }
                <Tooltip id={checkPermission('EDIT_EMPLOYEE', { Me, employee: item }) ? `tooltip-edit-${item.id}` : `tooltip-view-${item.id}`}>
                  {checkPermission('EDIT_EMPLOYEE', { Me, employee: item }) ? `${formatMessage(T('common.edit'))} ${item.firstName ?? ''} ${item.lastName ?? ''}` :
                    `${formatMessage(T('journeyConfigurator.manageLessonSets.view'))} ${item.firstName}`}
                </Tooltip>
              </td>}
              <td className="text-center">
                {checkPermission('DELETE_EMPLOYEE', { Me, employee: item }) &&
                  <ConfirmDeletionPopup
                    openByClickOn={(onClick) =>
                      <StyledFontAwesomeIcon
                        icon={faTrash}
                        fixedWidth
                        type="delete"
                        data-qa="manage__delete-button"
                        data-tip
                        data-for={`tooltip-delete-${item.id}`}
                        onClick={onClick}
                      />
                    }
                    removePatient={() => this.handleDelete(item.id)}
                    onOpen={() => this.showConfirmationPopup(item.id)}
                    onClose={this.closeConfirmationPopup}
                    id={item.id}
                  />}
                <Tooltip id={`tooltip-delete-${item.id}`}>
                  {`${formatMessage(T('common.delete'))} ${item.firstName ?? ''} ${item.lastName ?? ''}`}
                </Tooltip>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    )
  }
}

const MUTATION_EMPLOYEE_DELETE = gql`
  mutation deleteEmployeeMutation($employeeId: Int!) {
    deleteEmployee(employeeId: $employeeId)
  }
`

const mapPopupProps = ({ data: { overlay } }) => {
  return { overlay }
}

const withData = compose(
  graphql(MUTATION_EMPLOYEE_DELETE, {
    props: ({ mutate }) => ({
      deleteEmployee: employeeId =>
        mutate({
          variables: employeeId,
          refetchQueries: [
            {
              query: EMPLOYEES_QUERY,
              variables: { practiceId: Number(employeeId), orgId: parseInt(getOrganizationIdFromCache()) }
            }
          ]
        })
    })
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: mapPopupProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  injectIntl
)

export default withData(Table)
