import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { StyledPopup } from 'styledComponents/Div/DivComponent/DivPatient'
import { Button } from 'styledComponents/Button'
import { getToken } from 'helper/TokenUtil'
import { connector } from 'helper/Connector'
import { getGuidForUserDataConsent } from 'helper/UserDataConsentUtils'

const ModalHeader = Modal.Header
const ModalTitle = Modal.Title
const ModalBody = Modal.Body
const ModalFooter = Modal.Footer

function TeCaImprovementProgramConsentModal({defaultPracticeId, onClose, show}) {
    const [showModal, setShowModal] = useState(show)
    
    useEffect(() => { setShowModal(show) }, [show])

    const close = () => { 
        setShowModal(false) 
        onClose()
    }
    
    const accept = () => {
        const payLoad = {
            userDataConsent: true,
            defaultPracticeId
        }
        connector.put(getToken(), `/api/employees/data-collection-consent`, payLoad).then(() => getGuidForUserDataConsent(defaultPracticeId))
        close()
    }

    const decline = () => {
        connector.put(getToken(), `/api/employees/decline-data-collection`)
        close()
    }

    return (        
        <Modal            
            show={showModal}
            backdrop={true}
            onHide={close}
        >
            <ModalHeader closeButton>
                <ModalTitle>
                    <FormattedMessage {...T('TCImprovementProgram.label')} />
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <StyledPopup>
                    <FormattedMessage {...T('TCImprovementProgram.desc')} />
                </StyledPopup>
            </ModalBody>
            <ModalFooter>
                <Button id="acceptBtn" className="btn--min-width" onClick={accept} primary data-qa="acceptConsent">
                    <FormattedMessage {...T('common.accept')} />
                </Button>
                <Button id="declineBtn" className="btn--min-width" onClick={decline} secondary data-qa="declineConsent">
                    <FormattedMessage {...T('common.decline')} />
                </Button>                            
            </ModalFooter>
        </Modal>        
    )
}

export default TeCaImprovementProgramConsentModal