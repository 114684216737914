import styled from 'styled-components'

export const Image = styled.img`
  display: block;
  margin: -5px 0 20px;
  width: 563px;
  height: 163px;
`

export const PopupIcon = styled.img`
  width: 20px;
  margin-right: 10px;
`