import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import Kpi from './components/Kpi'
import isNil from 'lodash/isNil'
import { trackDashboardAction } from 'helper/DashboardUtils'
import {
  RowKpi,
  DashboardKpisGrid
} from 'styledComponents/Div/DivComponent/DivDashboard'
import DashBoardContext from '../DashBoardContext'

const AVG_MK = 'marketAverage'
const AVG_ORG = 'organizationAverage'

const nintyPercent = (value, maxValue) =>
  value ? Math.floor(value / maxValue * 90) : value

const defaultKpis = [
  {
    "id": 0,
    "name": null,
    "type": "practice",
    "totalNumberOfPatients": 0,
    "kpis": [
      {
        "value": 0,
        "type": "patientsGained"
      },
      {
        "value": 0,
        "type": "processDuration"
      },
      {
        "value": 0,
        "type": "patientSatisfaction"
      },
      {
        "value": 0,
        "type": "successRate"
      },
      {
        "value": 0,
        "type": "patientsOverall"
      }
    ]
  },
  {
    "id": 0,
    "name": null,
    "type": "organizationAverage",
    "totalNumberOfPatients": 0,
    "kpis": [
      {
        "value": 0,
        "type": "patientsGained"
      },
      {
        "value": 0,
        "type": "processDuration"
      },
      {
        "value": 0,
        "type": "patientSatisfaction"
      },
      {
        "value": 0,
        "type": "successRate"
      },
      {
        "value": 0,
        "type": "patientsOverall"
      }
    ]
  }
];

const kpiList = [
  {
    name: <FormattedMessage {...T('Dashboard.patient.satisfaction')} />,
    type: 'patientSatisfaction',
    suffix: '',
    defaultValue: null,
    invert: false,
    sortOrder: 'asc',
    legend: { from: 0, to: 5 },
    tooltip: {
      title: <FormattedMessage {...T('Dashboard.patients.satisfaction')} />,
      subtitle: (
        <FormattedMessage {...T('Dashboard.patients.satisfaction.info')} />
      )
    },
    fnPercent: value => (value ? Math.floor(value / 5 * 100) : value)
  },
  {
    name: <FormattedMessage {...T('Dashboard.process.duration')} />,
    type: 'processDuration',
    suffix: <FormattedMessage {...T('common.days.d')} />,
    defaultValue: null,
    invert: true,
    sortOrder: 'desc',
    legend: <FormattedMessage {...T('common.days')} />,
    tooltip: {
      title: <FormattedMessage {...T('Dashboard.patients.process')} />,
      subtitle: <FormattedMessage {...T('Dashboard.patients.process.info')} />
    },
    fnPercent: nintyPercent,
    transformValue: value => {
      return Math.round(value)
    }
  },
  {
    name: <FormattedMessage {...T('Dashboard.patients.overall')} />,
    type: 'patientsOverall',
    suffix: '',
    defaultValue: null,
    invert: false,
    sortOrder: 'asc',
    legend: <FormattedMessage {...T('Dashboard.all.added.patients')} />,
    tooltip: {
      title: <FormattedMessage {...T('Dashboard.patients.overall')} />,
      subtitle: <FormattedMessage {...T('Dashboard.patients.overall.info')} />
    },
    fnPercent: nintyPercent
  },
  {
    name: <FormattedMessage {...T('Dashboard.new.patients')} />,
    type: 'patientsGained',
    suffix: '',
    defaultValue: null,
    invert: false,
    sortOrder: 'asc',
    legend: <FormattedMessage {...T('Dashboard.new.patients')} />,
    tooltip: {
      title: <FormattedMessage {...T('Dashboard.patients.added')} />,
      subtitle: <FormattedMessage {...T('Dashboard.patients.added.info')} />
    },
    fnPercent: nintyPercent
  },
  {
    name: <FormattedMessage {...T('Dashboard.sucess.rate')} />,
    type: 'successRate',
    suffix: '%',
    defaultValue: null,
    invert: false,
    sortOrder: 'asc',
    legend: <FormattedMessage {...T('Dashboard.closed.patients')} />,
    tooltip: {
      title: <FormattedMessage {...T('Dashboard.patients.success')} />,
      subtitle: <FormattedMessage {...T('Dashboard.patients.success.info')} />
    },
    fnPercent: value => {
      return value * 100
    },
    transformValue: value => {
      return Math.floor(value * 100)
    }
  }
]

const getByType = (data, type) => {
  const items = data
    .map(item => {
      const kpi = item.kpis.filter(element => element.type === type).shift()
      if (kpi) {
        return {
          name: item.name,
          type: item.type,
          value: kpi.value
        }
      }
    })
    .filter(item => item && (!isNil(item.value) || item.type === 'practice'))

  return {
    type,
    items: items.length > 0 ? items : null
  }
}

const getSortedItems = (items, order = 'asc') => {
  return items.slice(0).sort((a, b) => {
    if (order === 'asc') {
      return b.value - a.value || (a.name && a.name.localeCompare(b.name))
    }

    return a.value - b.value || (a.name && a.name.localeCompare(b.name))
  })
}

const getMaxValue = items => {
  const item = items.reduce((a, b) => {
    if (!a.value) {
      return b
    }

    if (!b.value) {
      return a
    }

    if (a.value && b.value && a.value > b.value) {
      return a
    }

    return b
  })

  return item.value ? item.value : 0
}

const getThreshold = items => {
  const item = items.reduce((a, b) => {
    if (a.type === AVG_MK) {
      return a
    }

    if (b.type === AVG_MK || (a.type !== AVG_MK && b.type === AVG_ORG)) {
      return b
    }

    return a
  })

  return item.type === AVG_MK || item.type === AVG_ORG
    ? item
    : { type: null, value: null }
}

const getOrganizationAverage = items => {
  const avg = items.filter(a => a.type === AVG_ORG).shift()

  return avg ? avg : { name: null, type: AVG_ORG, value: null }
}

const addOpacityClasses = items => {
  const threshold = getThreshold(items)
  let cxCounter = 0
  let nextValue = 0

  items = items
    .map((item, i) => {
      if (!item.value) {
        return item
      }

      if (i < items.length - 1) {
        nextValue = items[i + 1].value
      }

      if (item.value >= threshold.value) {
        item = { ...item, cx: cxCounter }
      }

      if (item.value !== nextValue && cxCounter < 10) {
        cxCounter++
      }

      return item
    })
    .reverse()

  cxCounter = 0
  nextValue = 0

  items = items
    .map((item, i) => {
      if (item.hasOwnProperty('cx') || !item.value) {
        return item
      }

      if (i < items.length - 1) {
        nextValue = items[i + 1].value
      }

      if (item.value < threshold.value) {
        item = { ...item, cx: cxCounter }
      }

      if (item.value !== nextValue && cxCounter < 10) {
        cxCounter++
      }
      nextValue = item.value

      return item
    })
    .reverse()

  return items
}

const renderDashboardKpi = (kpis, formatMessage) => {
  return kpiList.map((kpiListItem, i) => {
    const kpi = getByType(kpis, kpiListItem.type)

    if (kpi.items) {
      const organizationAverage = getOrganizationAverage(kpi.items)
      let items = getSortedItems(kpi.items)
      items = addOpacityClasses(items)

      return (
        <RowKpi key={`practice-item-${i}`}>
          <Kpi
            average={organizationAverage.value}
            threshold={getThreshold(items)}
            maxValue={getMaxValue(items)}
            items={getSortedItems(items, kpiListItem.sortOrder)}
            {...kpiListItem}
            formatMessage={formatMessage}
          />
        </RowKpi>
      )
    }

    return null
  })
}

export const DashboardKpis = ({ intl: { formatMessage } }) => {
  const { kpis } = useContext(DashBoardContext);
  trackDashboardAction({ ...kpis, action: 'dashboardKpiTab' })

  return (
    <DashboardKpisGrid>
      {renderDashboardKpi(kpis ?? defaultKpis, formatMessage)}
    </DashboardKpisGrid>
  )
}

export default injectIntl(DashboardKpis)
