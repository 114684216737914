import { connector } from "helper/Connector";
import { GraphQLScalarType } from "graphql";
import { dataURItoBlob } from "helper/Utils";
import FFlags from "helper/FFlags";
import _ from "lodash";
import { tracker } from "core";
import { paginationSize, BluetoothSetting } from "helper/constants";
import { getToken } from "helper/TokenUtil";
import FlushEventsToAppInsight from "helper/FlushEventsToAppInsight";
import queryString from "query-string";

export default {
  Date: new GraphQLScalarType({
    name: "Date",
    description: "Date custom Scalar",
    parseValue(value) {
      return new Date(value);
    },
    parseLiteral() {
      return null;
    },
    serialize(value) {
      return value.toString();
    },
  }),
  MeEmployee: {
    practice(root) {
      return connector.get(
        getToken(),
        `/api/practices/${root.defaultPracticeId}`
      );
    },
  },
  Customer: {
    hearingDevices(root) {
      return connector
        .get(getToken(), `/hcp/api/v1/patients/${root.id}/hearing-aids`)
        .then((response) => {
          tracker.track("hearingDevice", { customerHearingDevice: response });
          return response;
        });
    },
  },
  Query: {
    ActiveProgram(root, { customerId }) {
      return connector.get(
        getToken(),
        `/hcp/api/v1/statistics/active-program?patientId=${customerId}`
      );
    },
    Countries(params) {
      params = {
        ...params,
        size: 400,
      };
      return connector
        .get(getToken(), `/api/rest/countries`, params)
        .then((payload) => payload._embedded.countries);
    },
    CountryCallingCodes() {
      return connector.get(getToken(), `/api/CountryCallingCodes`)
        .then((response) => response);
    },
    OrganizationBrands() {
      return connector.get(getToken(), `/api/brands`);
    },
    Configuration(root, { orgId }) {
      if (_.isNil(orgId)) return;
      return connector.get(
        getToken(),
        `api/organization/${orgId}/configuration`
      );
    },
    Customer(root, { id }) {
      return connector
        .get(getToken(), `/hcp/api/v1/patients/${id}`)
        .then((response) => {
          tracker.track(
            "showCustomer",
            { activeCustomer: response },
            { store: true }
          );
          return response;
        });
    },
    Customers(root, params) {
      params = {
        ...params,
        size: 400,
      };
      return connector
        .get(getToken(), `/hcp/api/v1/patients`, params)
        .then((response) => response.content);
    },
    AssistantHistoryList(root, params) {
      const { digitalAssistantId } = params
      return connector
        .get(getToken(), `/hcp/api/v1/patients/${digitalAssistantId}/assistant-history`, params)
        .then((response) => response);
    },
    Employee(root, { id }) {
      return connector.get(getToken(), `/hcp/api/v1/employees/${id}`);
    },
    InvitedEmployee(root, data) {
      const queryStringParam = queryString.stringify(data);
      return connector.get(
        getToken(),
        `/hcp/api/v1/employees/invite/${data.employeeId}/self-complete?${queryStringParam}`
      );
    },
    Employees(root, params) {
      params = {
        ...params,
        search: "",
        size: 400,
        accountState: "ACTIVE",
      };
      return connector
        .get(getToken(), `/hcp/api/v1/employees`, params)
        .then((response) => response.content);
    },
    EmployeeList(root, params) {
      let parameters = Object.assign(
        {},
        { size: paginationSize },
        { ...params }
      );
      return connector
        .get(getToken(), `/hcp/api/v1/employees`, parameters)
        .then((response) => {
          return { pagination: response.cursor, content: response.content };
        });
    },
    Environment(root, { customerId }) {
      return connector.get(
        getToken(),
        `/hcp/api/v1/statistics/environment?patientId=${customerId}`
      );
    },
    EqualizerSettings(root, { customerId, correlationContext }) {
      FlushEventsToAppInsight("Get Remote Tuning");
      return connector.getWithCorrelation(
        getToken(),
        `/api/patients/${customerId}/equalizer-settings`,
        correlationContext,
        {}
      );
    },
    HDModelsFeatures(root, params) {
      return connector.get(getToken(), `/api/hearing-aids/features`, params);
    },
    HDModels(root, params) {
      return connector
        .get(getToken(), `/api/hearing-aids`, params);
    },
    HearingAidStatus(root, { customerId }) {
      return connector.get(
        getToken(),
        `/hcp/api/v1/statistics/hearing-aid-status?customerId=${customerId}`
      );
    },
    HearingDevices(root, { customerId }) {
      return connector.get(
        getToken(),
        `/hcp/api/v1/patients/${customerId}/hearing-aids`
      );
    },
    Journey(root, { journeyId }) {
      return connector.get(getToken(), `/api/rest/journeys/${journeyId}`);
    },
    Kpis() {
      return connector.get(getToken(), `/api/Practices/analytics`);
    },
    Me() {
      if (!getToken()) return undefined;
      return connector
        .requestUrl(getToken(), "/hcp/api/v1/me")
        .then((response) => {
          const { employee, countryCode = "", organizationId = "" } = response;
          FFlags.identify({
            key: employee.id,
            country: countryCode.toUpperCase(),
            custom: {
              practiceId: employee.defaultPracticeId,
              organizationId: organizationId,
            },
          });
          tracker.track(
            "getDataEmployee",
            { activeEmployee: response },
            { store: true }
          );
          return response;
        });
    },
    Messages(root, params) {
      FlushEventsToAppInsight("Get Messages");
      const { correlationContext, ...restParams } = params;
      params = {
        ...restParams,
      };
      return connector
        .getWithCorrelation(
          getToken(),
          `/api/messages`,
          correlationContext,
          params
        )
        .then((response) => {
          return response;
        });
    },
    PatientsList(root, params) {
      const { search, employeeId, practiceId, patientType, ...restParams } = params;
      const queryParams = !!search
        ? { search, employeeId, practiceId }
        : { employeeId, practiceId };
      params = {
        ...restParams,
        ...queryParams,
        journeyStatus: patientType,
        size: paginationSize,
      };
      return connector
        .get(getToken(), `/hcp/api/v1/patients`, params)
        .then((response) => {
          return { pagination: response.cursor, content: response.content };
        });
    },
    Practice(root, { id }) {
      return connector.get(getToken(), `/api/practices/${id}`);
    },
    AssignedPractices(root, { id }) {
      return connector.get(getToken(), `/api/Practices/orgId/${id}`)
    },
    PracticesList(root, params) {
      const { ...restParams } = params;

      params = {
        ...restParams,
        size: paginationSize,
      };
      return connector
        .get(getToken(), `/api/Practices`, params)
        .then((response) => {
          return { pagination: response.cursor, content: response.content };
        });
    },
    ReconnectPatient(root, { patientId, correlationContext }) {
      return connector.getWithCorrelation(
        getToken(),
        `/hcp/api/v1/patients/${patientId}/webcode`,
        correlationContext,
        {}
      );
    },
    SatisfactionRatings(root, param) {
      return connector
        .get(
          getToken(),
          `/api/DailySatisfaction/report?patientId=${param.customerId}&locale=${param.locale}`
        )
        .then((response) => response);
    },

    WearingTimeRawData(root, { customerId }) {
      return connector.get(getToken(), `api/patients/${customerId}/wearing-time/raw-data`)
        .then((response) => {
          return response;
        })
    },

    Brands(root, { id }) {
      return connector
        .get(getToken(), `/api/organization/${id}/ha-brands`)
        .then((response) =>
          response.map((brand) => ({
            id: brand.brandName,
            name: brand.brandName,
            pairable: [brand.brandName],
            haBrandId: brand.haBrandId,
          }))
        );
    },
    PhoneValidation(root, params) {
      if (!(params && params.phoneNumber)) return Promise.resolve();

      return connector
        .put(getToken(), `/hcp/api/v1/validations/phone`, params)
        .then((response) => response)
        .catch((e) => {
          if (e.status !== 200) {
            return Promise.reject({
              message: "INVALID_PHONE_NUMBER",
            });
          }
        });
    },
    PasswordValidation(root, params) {
      if (!(params && params.password)) return Promise.resolve();

      return connector
        .put(getToken(), `/hcp/api/v1/validations/password`, params)
        .then((response) => response)
        .catch((e) => {
          if (e.status !== 200) {
            return Promise.reject({
              message: "INVALID_PASSWORD",
            });
          }
        });
    },
    MarketingRecipientsCounter(root, { filters = "{}" }) {
      return connector
        .get(
          getToken(),
          `/api/marketing-messages/recipient-count`,
          JSON.parse(filters)
        )
        .then((response) => response)
        .catch((e) => {
          if (e.status !== 200) {
            return Promise.reject({
              message: "ERROR_RECEIVING_RECIPIENTS_COUNTER",
            });
          }
        });
    },
    Faq(root, params) {
      return connector
        .get(getToken(), `/api/faq`, params)
        .then((response) => response);
    },
    Category(root, params) {
      return connector
        .get(getToken(), `/api/faq/category`, params)
        .then((response) => response);
    },
    NotificationTimeOptions(root) {
      return connector
        .get(getToken(), `/hcp/api/v1/employees/notificationtime`)
        .then((response) => {
          const NotificationTimeOptions = { timeSet: response };
          return NotificationTimeOptions;
        });
    },
    HIDSetting(root, params) {
      return connector
        .get(
          getToken(),
          `/api/PatientSettings/patients/${params.patientId}/bluetooth-setting`
        )
        .then((response) => {
          return { bluetoothDisabled: !response };
        })
        .catch((e) => {
          return { bluetoothDisabled: false };
        });
    },
    PatientFromCxxId(root, params) {
      return connector
        .get(getToken(), `/api/patients/cxx/${params.cxxId}`)
        .then((response) => {
          return response;
        });
    },
    SigniaLessonRatings(root, params) {
      return connector
        .get(getToken(), `/api/telecarelessons/rating/${params.patientGuid}`)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          if (e.status === 404) return [];
        });
    },
    LessonSetForSignia(root, params) {
      return connector
        .get(getToken(), `/api/hcp/${params.hcpId}/lessonset`)
        .then((response) => response);
    },
    DefaultLessonSetForSignia() {
      return connector
        .get(getToken(), `/api/telecarelessons`)
        .then((response) => response);
    },
    VideoThumbnailDetail(root, params) {
      return connector
        .getRequest(null, "/embed", "https://noembed.com", {
          url: params.videoUrl,
        })
        .then((response) => response);
    },
    LessonsForSigniaPatient(root, params) {
      return connector
        .get(getToken(), `/api/telecarelessons/patient/${params.patientGuid}`)
        .then((response) => response)
        .catch((e) => {
          if (e.status === 404) return [];
        });
    },
    OrganizationDetails(root, params) {
      return connector
        .get(getToken(), `/api/organization/${params.orgId}`)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          if (e.status === 404) return [];
        });
    },
    GetNotifications() {
      return connector
        .get(getToken(), 'api/notifications')
        .then(response => {
          return response
        })
        .catch(e => { return [] })
    },
  },
  Mutation: {
    createPractice(root, { practice }) {
      return connector
        .post(getToken(), `/api/practices`, practice)
        .then((response) => {
          tracker.track(
            "practiceCreated",
            { practice: response },
            { store: true }
          );
          return response;
        });
    },

    updatePractice(root, { practiceId, practice }) {
      return connector
        .put(getToken(), `/api/practices/${practiceId}`, practice)
        .then((response) => {
          tracker.track(
            "practiceUpdate",
            { practice: response },
            { store: true }
          );
          return response;
        });
    },
    deletePractice(root, { practiceId }) {
      return connector.delete(
        getToken(),
        `/api/practices/${practiceId}`
      );
    },

    inviteEmployee(root, { employee, correlationContext }) {
      return connector
        .postWithCorrelation(
          getToken(),
          `/hcp/api/v1/employees/invite`,
          correlationContext,
          employee
        )
        .then((response) => {
          return { success: true };
        })
        .catch((error) => {
          return { success: false, ...error };
        });
    },
    resendInviteEmployee(root, { email, correlationContext }) {
      return connector
        .postWithCorrelation(
          getToken(),
          `/hcp/api/v1/employees/invite/resend?email=${email}`,
          correlationContext,
          {}
        )
        .then((response) => {
          return { success: true };
        })
        .catch((error) => {
          return { success: false, ...error };
        });
    },
    updateEmployee(root, { employeeId, employee }) {
      return connector.put(
        getToken(),
        `/api/employees/${employeeId}`,
        employee
      );
    },
    updateInvitedEmployee(root, { employeeId, orgId, key, tenantId, employee }) {
      let updateEmployeeQueryParam = queryString.stringify({
        employeeId: employeeId,
        orgId: orgId,
        key: key,
        tenantId: tenantId,
      })

      return connector.put(getToken(), `/api/employees/invite/${employeeId}/self-complete?${updateEmployeeQueryParam}`, employee)
    },
    deleteEmployee(root, { employeeId }) {
      return connector.delete(
        getToken(),
        `/hcp/api/v1/employees/${employeeId}`
      );
    },
    changePassword(root, { employeeId, passwords }) {
      return connector
        .put(
          getToken(),
          `/hcp/api/v1/employees/${employeeId}/password-change`,
          passwords
        )
        .then((response) => {
          return { success: response };
        })
        .catch((error) => {
          return { success: false, ...error };
        });
    },
    createPatient(root, { patient }) {
      return connector
        .post(getToken(), `/hcp/api/v1/patients`, patient)
        .then((response) => {
          tracker.track(
            "customerCreated",
            { activeCustomer: response },
            { store: true }
          );
          return response;
        });
    },
    createPrefitingPatient(root, { patient }) {
      return connector
        .post(getToken(), `/hcp/api/v1/prefitting-patients`, patient)
        .then((response) => {
          tracker.track(
            "prefitingPatientCreated",
            { activeCustomer: response },
            { store: true }
          );
          return response;
        });
    },
    updatePatient(root, { patientId, patient }) {
      return connector
        .put(getToken(), `/hcp/api/v1/patients/${patientId}`, patient)
        .then((response) => {
          tracker.track(
            "customerUpdated",
            { activeCustomer: response },
            { store: true }
          );
          return response;
        });
    },
    updatePrefitingPatient(root, { patientId, patient }) {
      return connector
        .put(
          getToken(),
          `/hcp/api/v1/prefitting-patients/${patientId}`,
          patient
        )
        .then((response) => {
          tracker.track(
            "customerPrefittingUpdated",
            { activeCustomer: response },
            { store: true }
          );
          return response;
        });
    },
    deletePatient(root, { patientId }) {
      return connector
        .delete(getToken(), `/hcp/api/v1/patients/${patientId}`)
        .then(() => {
          tracker.track("customerRemoved", { activeCustomer: null });
          return Promise.resolve();
        })
        .catch((e) => {
          tracker.track("customerRemoveError", { activeCustomer: patientId });
          if (e.status !== 200) {
            return Promise.reject({
              message: "CUSTOMER_NOT_DELETED",
            });
          }
        });
    },
    uploadEmployeeAvatar(root, { file, employeeId }) {
      file = dataURItoBlob(file);
      return connector.uploadAvatar(
        getToken(),
        `hcp/api/v1/employees/${employeeId}/avatar`,
        {
          file,
        }
      );
    },
    uploadSelfCompleteAvatar(root, { file, employeeId, orgId, key, tenantId }) {
      file = dataURItoBlob(file);
      let uploadAvatarParam = queryString.stringify({
        employeeId: employeeId,
        orgId: orgId,
        key: key,
        tenantId: tenantId,
      });

      return connector.uploadSelfCompleteAvatar(
        `hcp/api/v1/employees/invite/${employeeId}/self-complete/upload-avatar?${uploadAvatarParam}`,
        {
          file,
        }
      );
    },
    deleteEmployeeAvatar(root, { employeeId }) {
      return connector.delete(
        getToken(),
        `hcp/api/v1/employees/${employeeId}/avatar`
      );
    },
    uploadPracticeLogo(root, { file, practiceId }) {
      file = dataURItoBlob(file);
      return connector.uploadAvatar(
        getToken(),
        `api/practices/${practiceId}/logo`,
        {
          file,
        }
      );
    },
    deletePracticeLogo(root, { practiceId }) {
      return connector.delete(
        getToken(),
        `api/practices/${practiceId}/logo`
      );
    },
    markMessageAsRead(root, { ids, correlationContext }) {
      FlushEventsToAppInsight("Mark Messages as Read");
      return connector.putWithCorrelation(
        getToken(),
        `/api/messages/read`,
        correlationContext,
        ids
      );
    },
    resetUnreadMessageCount(root, { patientId, correlationContext }) {
      FlushEventsToAppInsight("Reset Unread message count");
      return connector.putWithCorrelation(
        getToken(),
        `/api/patients/${patientId}/resetunreadmessagecount`,
        correlationContext
      );
    },
    sendMessage(root, { data, correlationContext }) {
      FlushEventsToAppInsight("Send Messages");
      return connector
        .postWithCorrelation(
          getToken(),
          "/api/messages",
          correlationContext,
          data
        )
        .then(() => {
          tracker.track("customerMessaged");
          return true;
        });
    },
    patientStartTrial(root, { patientId, patient }) {
      const Patient = JSON.stringify(patient);
      return connector
        .put(
          getToken(),
          `/hcp/api/v1/prefitting-patients/${patientId}/trial`,
          patient
        )
        .then(() => {
          tracker.track(
            "customerTrialUpdated",
            { activeCustomer: Patient },
            { store: true }
          );
          return Promise.resolve();
        })
        .catch((e) => {
          tracker.track(
            "customerTrialNotUpdated",
            { activeCustomer: Patient },
            { store: true }
          );
          if (e.status !== 200) {
            return Promise.reject({
              message: "JOURNEY_NOT_UPDATED",
            });
          }
        });
    },

    sendMarketingMessage(
      root,
      { message = "", filters = "{}", employeeId = "" }
    ) {
      if (!(message && message.trim().length)) return Promise.resolve();
      return connector
        .post(getToken(), "/api/marketing-messages/send", {
          MarketingMessagesFilterRequest: JSON.parse(filters),
          employeeId,
          message,
        })
        .then(() => {
          return Promise.resolve();
        })
        .catch((error) => {
          tracker.track("marketingMessage not sent to customers", {
            message,
            filters: JSON.parse(filters),
            error: error,
          });
          return Promise.reject();
        });
    },
    updateHIDSetting(root, { hidSetting }) {
      const patientId = hidSetting.patientId;
      const bluetoothSetting =
        hidSetting.bluetoothDisabled === false
          ? BluetoothSetting.EnableBluetooth
          : BluetoothSetting.DisableBluetooth;
      return connector
        .post(
          getToken(),
          `/api/PatientSettings/patients/${patientId}/bluetooth-setting/${bluetoothSetting}`
        )
        .then((response) => {
          tracker.track("hidSettingUpdated");
          return true;
        })
        .catch((e) => {
          return false;
        });
    },
    sendToDataCollection(root, { dataCollectionModel }) {
      return connector
        .post(getToken(), `/api/datacollection`, dataCollectionModel)
        .catch((e) => {
          return false;
        });
    },
    restartLesson(root, { patientGuid }) {
      return connector
        .post(getToken(), `/api/telecarelessons/restart/${patientGuid}`)
        .then((response) => {
          tracker.track("lessonsRestarted");
          return response;
        })
        .catch((e) => {
          return false;
        });
    },
    createLessonSetForSignia(root, { hcpId = "", lessonSet = {} }) {
      return connector
        .post(getToken(), `/api/hcp/${hcpId}/lessonset`, lessonSet)
        .then((data) => {
          return data;
        });
    },
    updateLessonSetForSignia(
      root,
      { hcpId = "", lessonSetId = "", lessonSet = {} }
    ) {
      return connector
        .put(
          getToken(),
          `/api/hcp/${hcpId}/lessonset/${lessonSetId}`,
          lessonSet
        )
        .then((data) => {
          return data;
        });
    },
    updateLessonsForSigniaPatient(root, { patientGuid = "", lessonIds = [] }) {
      return connector
        .put(
          getToken(),
          `api/telecarelessons/patient/${patientGuid}`,
          lessonIds
        )
        .then((data) => {
          return data;
        })
        .catch(() => {
          return false;
        });
    },
    updateExpiredPassword(root, { password }) {
      return connector
        .put(
          getToken(),
          `hcp/api/v1/employees/update-expired-password`,
          password
        )
        .then((data) => {
          return data;
        });
    },
    updateOrganization(root, { orgId, organizationDetail }) {
      return connector
        .put(getToken(), `/api/organization/${orgId}`, organizationDetail)
        .then((response) => {
          tracker.track(
            "organizationUpdate",
            { organization: response },
            { store: true }
          );
          return Promise.resolve(true);
        })
        .catch((e) => {
          return Promise.resolve(false);
        });
    },
    movePatients(root, { movePatientsRequest }) {
      return connector
        .post(getToken(), `api/organization/move-patients`, movePatientsRequest)
        .then((response) => Promise.resolve({ success: true, status: 200, message: "SUCCESS" }))
        .catch((e) => {
          if (e.status == 403) {
            return Promise.resolve({ success: false, ...e, message: "PATIENT_MIGRATION_IN_PROGRESS", });
          } else {
            return Promise.resolve({ success: false, ...e, message: "BACKEND_ERROR", });
          }
        });
    },

    generateOtpForPhoneVerification(root, { contactPhoneEntity }) {
      return connector
        .post(getToken(), 'api/contact-verification/phone/generate-code', contactPhoneEntity)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    generateOtpForEmailVerification(root, { contactEmailEntity }) {
      return connector
        .post(getToken(), 'api/contact-verification/email/generate-code', contactEmailEntity)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    verifyOtpForPhoneVerification(root, { contactVerifyPhoneEntity }) {
      return connector
        .post(getToken(), 'api/contact-verification/phone/verify-code', contactVerifyPhoneEntity)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    verifyOtpForEmailVerification(root, { contactVerifyEmailEntity }) {
      return connector
        .post(getToken(), 'api/contact-verification/email/verify-code', contactVerifyEmailEntity)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    updateEmployeePhoneVerificationStatus(root, { employeeId, empPhoneUpdateRequest }) {
      return connector
        .put(getToken(), `/api/employees/${employeeId}/phone`, empPhoneUpdateRequest)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    updateEmployeeEmailVerificationStatus(root, { employeeId, empEmailUpdateRequest }) {
      return connector
        .put(getToken(), `/api/employees/${employeeId}/email`, empEmailUpdateRequest)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },

    clearAllNotifications(root, { notificationType }) {
      return connector.delete(
        getToken(),
        `/api/notifications/clear-all/${notificationType}`
      );
    },

    deleteNotification(root, { patientNotificationId }) {
      return connector.delete(
        getToken(),
        `/api/notifications/${patientNotificationId}`
      );
    },

    updateNotificationReadDetail(root, { patientNotificationId }) {
      return connector.put(
        getToken(),
        `/api/notifications/${patientNotificationId}`
      );
    },


    updateInvitedEmployeePhoneVerificationStatus(root, { employeeId, orgId, key, tenantId, empPhoneUpdateRequest }) {
      let updateEmployeeQueryParam = queryString.stringify({
        employeeId: employeeId,
        orgId: orgId,
        key: key,
        tenantId: tenantId,
      });
      return connector
        .put(getToken(), `/api/employees/invite/${employeeId}/self-complete/phone?${updateEmployeeQueryParam}`, empPhoneUpdateRequest)
        .then((response) => { return Promise.resolve(true) })
        .catch((error) => { return Promise.reject(false) });
    },
  }
};