
import { gql } from "@apollo/client";

export const MUTATION_INVITE_EMPLOYEE = gql`
  mutation inviteEmployeeMutation($employee: EmployeeInviteInput!, $correlationContext: String) {
    inviteEmployee(employee: $employee, correlationContext: $correlationContext){
        success
        message 
        code
        status
    }
  }
`


export const MUTATION_RESEND_INVITE_EMPLOYEE = gql`
  mutation resendInviteEmployee($email: String!, $correlationContext: String) {
    resendInviteEmployee(email: $email, correlationContext: $correlationContext){
        success
        message 
        code
        status
    }
  }
`