import React, { useState } from 'react';
import { withTheme } from 'styled-components';

export const ToastAlert = (props ) => {
    const { message, displayTime, theme } = props
    const { colors: { button : { primary }, fontAwesomeIcons : { failure, success }} } = theme
    const [ showAlert, setShowAlert ] = useState(true);
    setTimeout(() => {
        setShowAlert(false);
    }, displayTime)

    const closeToast = () => {
        setShowAlert(false);
    }
    const backgroundColor = props.success ? success.color : failure.color
    return (
        <div>
            {showAlert &&
            <div style = {{ backgroundColor , color: primary.color, padding: '0.5em', borderRadius:'5px', margin: '0px'}} className="row">
                <span 
                    className='col-lg-10' 
                    style={{ textAlign: 'start', fontSize: '12px'}}>
                    {message}
                </span>
                <i  className="col-lg-2 far fa-times-circle" 
                    style={{ textAlign: 'end', verticalAlign: 'middle', fontSize: '20px'}}
                    onClick={() => closeToast()}
                />
            </div>
            }

        </div>
    )
}

export default withTheme(ToastAlert);