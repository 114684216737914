import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { compose } from 'lodash/fp'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { Button } from 'styledComponents/Button'
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon" 
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export class DefaultPopup extends Component {
  render() {
    const icon = this.props.icon || faEnvelope;
    const iconType = this.props.iconType || "popupIcon";

    return (
      <div data-qa="popup">
        <StyledPopupFontAwesomeIcon icon={icon} type={iconType} fixedWidth size="2x" className="popup-icon" data-qa="popup__icon"/>
        <h1 data-qa="popup__title">
          <FormattedMessage {...T(this.props.title)} />
        </h1>
        <p data-qa="popup__message">
          <FormattedMessage {...T(this.props.message)} />
        </p>
        <div className="row">
          <div className="col-4 offset-4">
            <Button
              primary
              className="btn-block"
              onClick={this.props.shouldHide.bind(this)}
              type="submit"
              data-qa="popup__close-button"
            >
              <FormattedMessage {...T('common.ok')} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

DefaultPopup.propTypes = {
  intl: PropTypes.object,
  message: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  shouldHide: PropTypes.func
}

const mapProps = ({ data: { refetch, loading, overlay } }) => {
  const visible = overlay.popup !== null
  return { refetch, loading, visible, ...overlay.popup }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  }),
  injectIntl
)
export default withData(DefaultPopup)
