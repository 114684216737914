import React from 'react'
import { connector } from '../../helper/Connector'
import { Button, PresetButton } from 'styledComponents/Button'
import { InputUrl } from 'styledComponents/Input'

export default class DevTools extends React.Component {
  state = {
    baseUrl: connector.getBaseUrl()
  }

  onBaseUrlChange = e => {
    const newBaseUrl = e.target.value
    this.setState({
      baseUrl: newBaseUrl
    })
  }

  onPresetEnvironment = preset => {
    let baseUrl = 'https://hcpportalapi.cloudapi-dev.sivantos.com'
    switch (preset) {
      case 'local':
        baseUrl = 'http://localhost:8080'
        break
      case 'development':
        baseUrl = 'https://hcpportalapi.cloudapi-dev.sivantos.com'
        break
      case 'staging':
        baseUrl = 'https://hcpportalapi.cloudapi-staging.sivantos.com'
        break
      case 'production':
        baseUrl = 'https://hcpportalapi.cloudapi.sivantos.com'
        break
    }
    return () => {
      this.setState({
        baseUrl
      })
    }
  }

  changeEnv = () => {
    const { baseUrl } = this.state
    localStorage.setItem('baseUrl', baseUrl)
    window.location.replace('/')
  }

  resetEnv = () => {
    localStorage.removeItem('baseUrl')
    window.location.replace('/')
  }

  render() {
    const { baseUrl } = this.state
    return (
      <div className="container">
        <div className="row">
          <InputUrl
            data-qa="base-url"
            value={baseUrl}
            onChange={this.onBaseUrlChange}
            ref={el => {
              this.baseUrlInput = el
            }}
          />
          <Button
            primary
            data-qa="save"
            type="button"
            className="action-button"
            onClick={this.changeEnv}
          >
            commit
          </Button>
          <Button primary type="button" className="action-button" onClick={this.resetEnv}>
            reset
          </Button>
        </div>
        <PresetButton type="button" onClick={this.onPresetEnvironment('development')}>
          development
        </PresetButton>
        <PresetButton type="button" onClick={this.onPresetEnvironment('staging')}>
          staging
        </PresetButton>
        <PresetButton type="button" onClick={this.onPresetEnvironment('production')}>
          production
        </PresetButton>
        <PresetButton type="button" onClick={this.onPresetEnvironment('local')}>
          local
        </PresetButton>
      </div>
    )
  }
}
