import PropTypes from 'prop-types'
import React from 'react'
import { ErrorMessage, getIn } from 'formik'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import messages from 'i18n/messages.json'
import { Modal } from 'react-bootstrap'
import { StyledPopup } from 'styledComponents/Div/DivComponent/DivPatient'
import { Button, LinkButton } from 'styledComponents/Button'

const ModalHeader = Modal.Header
const ModalTitle = Modal.Title
const ModalBody = Modal.Body
const ModalFooter = Modal.Footer

class TeCaImprovementProgramInformationModal extends React.Component {
  state = {
    showModal: false
  }
  open = () => {
    this.setState({
      showModal: true
    })
    return false
  }

  close = () => {
    this.setState({
      showModal: false
    })
  }

  onMoreInfoClick = (e) => {this.open(); e.preventDefault(); }
  
  render = () => {
    const { options, field, children, className, form: { touched, errors } } = this.props
    const { description } = options || { description: null }
    const { value } = { ...field }
    return (
      <div
        className={`checkbox-group ${className || ''} ${getIn(errors, field.name) &&
        getIn(touched, field.name)
          ? 'has-error'
          : ''}`}
        data-qa="checkbox"
      >
        <label>
          <input
            {...field}
            type="checkbox"
            defaultChecked={value}
            onClick={e => {
              field.onChange(e)
              field.onBlur(e)
            }}
            className={`checkbox ${className || ''}        
                  ${getIn(errors, field.name) && getIn(touched, field.name) ? 'has-error' : ''}
                `}
          />

          {description != null
            ? <FormattedMessage {...messages[description.key]} values={description.values} />
            : children}
        </label>
        <span className={'margin-sides'}>
          <LinkButton onClick={this.onMoreInfoClick}>
            <FormattedMessage {...T('hearing_device.moreInfo')} />
          </LinkButton>
        </span>
        <Modal
          show={this.state.showModal}
          onHide={this.close}
          backdrop={true}
          ref={ref => (this.modal = ref)}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              <FormattedMessage {...T('TCImprovementProgram.label')} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <StyledPopup>
              <FormattedMessage {...T('TCImprovementProgram.desc')} />
            </StyledPopup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.close} primary className="btn--min-width" data-qa="closeModal">
              <FormattedMessage {...T('common.close')} />
            </Button>
          </ModalFooter>
        </Modal>
        <ErrorMessage
          name={field.name}
          render={msg =>
            <div className="help-block" data-qa="input-error">
              {msg}
            </div>}
        />
      </div>
    )
  }
}

TeCaImprovementProgramInformationModal.propTypes = {
  field: PropTypes.shape({}),
  children: PropTypes.shape({}),
  className: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.shape({})
}

export default TeCaImprovementProgramInformationModal
