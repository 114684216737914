import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { default as scrollTo } from 'scroll-to-element'
import _ from 'lodash'
import { T } from 'core'
import { TRIAL_DURATIONS, DataCollectionType, PhoneNumberLengthForValidaton } from 'helper/constants'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Loading from 'components/Loading'
import { shopTracking } from 'helper/shopTracking'
import { EmployeeFormContainer } from 'styledComponents/Div/DivContainer/DivEmployee'
import Tooltip from 'components/Tooltip'
import { withQuery } from 'helper/withQuery'
import { withRouter } from "helper/withRouter";
import InputField from 'formfields/InputField'
import SelectField from 'formfields/SelectField'
import PracticesSelectField from 'formfields/PracticesSelectField'
import PasswordComponent from 'formfields/Password'
import ImageDropField from 'formfields/FileUpload/ImageDropField'
import RadioButtonGroupField from 'formfields/EditUser/RadioButtonGroupField'
import TeCaImprovementProgramInformationModal from '../TeCaImprovementProgramInformationModal'
import { isUserOptedForDataConsent, getGuidForUserDataConsent } from 'helper/UserDataConsentUtils'
import { logout } from 'actions/authentication'
import { Button } from 'styledComponents/Button'
import { sendDataToDataCollection } from './../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from './../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../helper/DataCollection/DataCollectionConstants'
import { fileUploadService } from "helper/rxjsUtil"
import OtpVerificationModal from '../../components/Popups/OtpVerificationModal';
import InputWithIconAndTextField from 'formfields/InputWithIconAndTextField';
import { notificationOptionNameConst, CostcoErrorCodes } from '../../helper/constants'
import { HorizontalRow } from 'styledComponents/HorizontalRule'
import { checkUsername } from '../../actions/authentication'

const EMPLOYEE = { value: 'employee', label: <FormattedMessage {...T('form.employee')} /> }
const MANAGER = {
  value: 'manager',
  label: <FormattedMessage {...T('form.practice-manager')} />
}
const ORG_MANAGER = {
  value: 'orgManager',
  label: <FormattedMessage {...T('form.org-manager')} />
}

const ROLES_MAP = {
  orgManager: [EMPLOYEE, MANAGER, ORG_MANAGER],
  manager: [EMPLOYEE, MANAGER],
  employee: [EMPLOYEE]
}

const notificationsOptions = [notificationOptionNameConst.off, notificationOptionNameConst.email, notificationOptionNameConst.sms];
const notificationsLabels = [
  <FormattedMessage {...T('common.off')} />,
  <FormattedMessage {...T('common.messagesEmail')} />,
  <FormattedMessage {...T('common.messageText')} />
]
const userDataConsentOptions = {
  description: {
    key: 'tcImprovementProgramLabel'
  }
}

let disableNotificationOptionName = [];
let globalIsMe;

export class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.employeeFormRef = React.createRef();
    this.state = {
      initialized: false,
      usernameView: false,
      usernameAvailable: true,
      openOtpVerificationModal: false,
      isPhoneRequest: false,
      isCountryCodeBlocked: false,
    }
    globalIsMe = props.isMe;
  }

  componentDidUpdate() {
    const { loading, employee = {}, countryCodeLoading } = this.props
    const { initialized } = this.state
    globalIsMe = this.props.isMe;
    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', '')
    if (!initialized && !loading && !countryCodeLoading) {
      const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'

      // username all the time exist. It's the same as email in case it has email :(
      const useUsername = !!employee.username && !employee.email
      this.setState({
        initialized: true,
        usernameView: useUsername,
        usernameAvailable: role === 'employee',
        isCountryCodeBlocked: this.isCountryCodeBlocked(employee?.phone)
      })
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Employee), EventType.Read)
    }
    this.setDisableNotificationOptionName(employee);
  }

  setDisableNotificationOptionName(employee, values) {
    disableNotificationOptionName = [];
    if (!employee.emailVerified || (values !== undefined && employee.email !== values.email)) {
      disableNotificationOptionName.push(notificationOptionNameConst.email);
    }
    if (!employee.phoneVerified || (values !== undefined && employee.phone !== values.phone)) {
      disableNotificationOptionName.push(notificationOptionNameConst.sms);
    }
  }

  prepareDataFromEmployee(employee) {
    const { practices = [], locale, params } = this.props

    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', '')
    const defaultModuleLength = _.get(employee, 'defaultModuleLength') || TRIAL_DURATIONS[0]
    const notificationTime = _.get(employee, 'notificationTime') || '07:00:00'

    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'
    const { inactiveFeatures = [] } = employee
    const enableTraining = inactiveFeatures.indexOf('LESSON_ACTIVE_JOURNEY') < 0 ? 'YES' : 'NO'
    const assignedPracticeIds = practices
      .filter(item => _.get(employee, 'assignedPracticeIds', []).indexOf(item.id) != -1)
      .map(item => ({ label: item.name, value: item.id.toString() }))
    const defaultPracticeId = _.get(employee, 'defaultPracticeId', '')
    const practicesField = { assignedPracticeIds, defaultPracticeId }

    if (_.isNil(params.id)) {
      inactiveFeatures.push('NEGATIVE_LESSON_EMAIL_NOTIFICATION')
      inactiveFeatures.push('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION')
    }

    let enableNewMessage = 'OFF'
    if (inactiveFeatures.indexOf('DISABLE_SMS_CHAT_NOTIFICATION') !== -1) enableNewMessage = 'SMS'
    if (inactiveFeatures.indexOf('DISABLE_EMAIL_CHAT_NOTIFICATION') !== -1)
      enableNewMessage = 'EMAIL'

    let enableSatisfactionRating = 'OFF'
    if (inactiveFeatures.indexOf('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION') !== -1)
      enableSatisfactionRating = 'EMAIL'
    if (inactiveFeatures.indexOf('NEGATIVE_SATISFACTION_SMS_NOTIFICATION') !== -1)
      enableSatisfactionRating = 'SMS'

    let enableLessonRating = 'OFF'
    if (inactiveFeatures.indexOf('NEGATIVE_LESSON_EMAIL_NOTIFICATION') !== -1)
      enableLessonRating = 'EMAIL'
    if (inactiveFeatures.indexOf('NEGATIVE_LESSON_SMS_NOTIFICATION') !== -1)
      enableLessonRating = 'SMS'

    // username all the time exist. It's the same as email in case it has email :(
    const useUsername = !!employee.username && !employee.email

    const password = { oldPassword: '', passwordEnter: '', passwordReEnter: '' }

    return {
      avatar: _.get(employee, 'avatar', {}).url || '',
      firstName: employee.firstName || '',
      lastName: employee.lastName || '',
      email: employee.email || '',
      emailVerified: employee.emailVerified,
      username: useUsername ? employee.username : '',
      phone: employee.phone || '',
      phoneVerified: employee.phoneVerified,
      role,
      manager: Boolean(isManager),
      orgManager: Boolean(isOrgManager),
      enableNewMessage,
      enableSatisfactionRating,
      enableLessonRating,
      locale: employee.locale || locale,
      enableTraining,
      practices: practicesField,
      defaultModuleLength,
      notificationTime,
      password,
      userDataConsent: this.isUserDataConsentCheckboxEnabled()
    }
  }

  isUserDataConsentCheckboxEnabled = () => {
    return isUserOptedForDataConsent() || false
  }

  createDataQaEntry(name, options, index) {
    return name + '_' + options[index]
  }

  usernameViewToggle = () => {
    this.setState({
      usernameView: !this.state.usernameView
    })
  }

  onRoleChange = (e, setFieldValue, values) => {
    const role = e.currentTarget.value
    const isEmployee = role === 'employee'
    const isOrgManager = role === 'orgManager'
    const usernameView = isEmployee && !values.email

    setFieldValue('manager', isOrgManager || !isEmployee)
    setFieldValue('orgManager', isOrgManager)
    setFieldValue('practices.assignedPracticeIds', isOrgManager ? this.props.practicesOptions : [])
    setFieldValue(
      'practices.defaultPracticeId',
      isOrgManager ? values.practices.defaultPracticeId : ''
    )

    this.setState({ usernameView, usernameAvailable: isEmployee })
  }

  getInactiveFeatures(data) {
    const { enableTraining, enableNewMessage, enableSatisfactionRating, enableLessonRating } = data

    const inactiveFeatures = []

    //FIXME: magical strings are EVIL! please remove
    if (enableNewMessage === 'SMS') inactiveFeatures.push('DISABLE_SMS_CHAT_NOTIFICATION')
    if (enableNewMessage === 'EMAIL') inactiveFeatures.push('DISABLE_EMAIL_CHAT_NOTIFICATION')

    if (!enableTraining || enableTraining === 'NO') {
      inactiveFeatures.push('LESSON_ACTIVE_JOURNEY')
    }

    if (enableLessonRating === 'SMS') inactiveFeatures.push('NEGATIVE_LESSON_SMS_NOTIFICATION')
    if (enableLessonRating === 'EMAIL') inactiveFeatures.push('NEGATIVE_LESSON_EMAIL_NOTIFICATION')

    if (enableSatisfactionRating === 'SMS') inactiveFeatures.push('NEGATIVE_SATISFACTION_SMS_NOTIFICATION')
    if (enableSatisfactionRating === 'EMAIL') inactiveFeatures.push('NEGATIVE_SATISFACTION_EMAIL_NOTIFICATION')

    return inactiveFeatures
  }

  async prepareData(employeeData) {
    const { usernameView } = this.state
    const { organizationId } = this.props
    const omitFields = usernameView ? ['email'] : ['username']
    const isEmailVerified = this.props.employee.emailVerified ? this.props.employee.email === employeeData.email : false;
    const isPhoneVerified = this.props.employee.phoneVerified ? this.props.employee.phone === employeeData.phone : false;

    const data = _.omit(employeeData, [...omitFields, 'avatar', 'password', 'role', 'practices'])

    if (!this.props.isMe) {
      data.password = employeeData.password.passwordEnter
    }

    return {
      ...data,
      emailVerified: isEmailVerified,
      phoneVerified: isPhoneVerified,
      assignedPracticeIds: employeeData.practices.assignedPracticeIds.reduce(
        (arr, item) => [...arr, parseInt(item.value)],
        []
      ),
      defaultPracticeId: parseInt(employeeData.practices.defaultPracticeId),
      inactiveFeatures: this.getInactiveFeatures(employeeData),
      organizationId
    }
  }

  isCountryCodeBlocked(number) {
    if (!number) {
      return false;
    }
    const { CountryCallingCodes } = this.props;
    let validate = false;
    for (let element of CountryCallingCodes) {
      if (number.startsWith(element.countryCallingCode)) {
        validate = element.isCallingCodeBlocked;
        break;
      }
    }
    return validate;
  }

  employeeValidationSchema = () => Yup.object().shape(
    {
      firstName: Yup.string().trim().required(this.formatMessage('validator.required')),
      email: this.state.usernameView ?
        Yup.string().notRequired().email(this.formatMessage('validator.email')) :
        Yup.string()
          .required(this.formatMessage('validator.required'))
          .email(this.formatMessage('validator.email'))
          .usernameIsUnique(
            this.props.employee ? this.props.employee.email : '',
            this.formatMessage('error_messages.EMAIL_ALREADY_EXISTS_EMPLOYEE'), this.submitClicked),
      username: this.state.usernameView ?
        Yup.string()
          .required(this.formatMessage('validator.required'))
          .usernameIsUnique(this.props.employee ? this.props.employee.username : '', null, this.submitClicked) :
        Yup.string().notRequired(),
      phone: Yup.string().isPhoneNoValid(),
      practices: Yup.object().shape({
        assignedPracticeIds: Yup.array().nullable().required(this.formatMessage('validator.required')),
        defaultPracticeId: Yup.string().required(this.formatMessage('validator.required'))
      }),
      password:
        Yup.object().shape({
          passwordEnter: Yup.string()
            .isPasswordValid()
            .test('minPasswordLengthIfNotEmpty', this.formatMessage('min.password'), function (value) {
              if (!!value) {
                const schema = Yup.string().min(6)
                return schema.isValidSync(value)
              }
              return true
            }),
          passwordReEnter: Yup.string()
            .test('passwords-match', this.formatMessage('validator.pinNotEqual'), function (value) {
              return this.parent.passwordEnter == value
            }),
          oldPassword: Yup.string()
            .test('passwordsNotEmpty', this.formatMessage('validator.oldPasswordEmpty'), function (value) {
              if (!!this.parent.passwordEnter && !!this.parent.passwordReEnter) {
                return globalIsMe ? !!value : true;
              }
              return true;
            })
        }),
      enableNewMessage: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber')),
      enableLessonRating: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber')),
      enableSatisfactionRating: Yup.mixed().isMobileNumberEmpty(Yup.ref('phone'), this.formatMessage('popup_startcall.noPhoneNumber'))
    },
    ['email', 'username']
  )

  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  async uploadAvatar(employeeId, avatar) {
    try {
      await this.props.uploadEmployeeAvatar({ file: avatar, employeeId })
    } catch ({ message }) {
      console.error('Uploading avatar is failed', message)
    }
  }

  async deleteAvatar(employeeId) {
    try {
      await this.props.deleteEmployeeAvatar({ employeeId })
    } catch ({ message }) {
      console.error('Can not delete avatar', message)
    }
  }

  async changePassword({ oldPassword, passwordEnter }, setFieldError) {
    const { employeeId } = this.props
    const myPasswordUpdated = !!passwordEnter && !!oldPassword
    let success = true
    if (myPasswordUpdated) {
      await this.props
        .changePassword({ employeeId, passwords: { oldPassword, newPassword: passwordEnter } })
        .then(({ data }) => {
          const res = data.changePassword
          if (!res.success) {
            if (res.code === "PASSWORD_POLICY_ERROR") {
              setFieldError(
                "password.passwordEnter",
                this.formatMessage("password.notAcceptable")
              )
            } else {
              setFieldError(
                'password.oldPassword',
                <FormattedMessage {...T(`password.${res.code}`)} />
              )
            }

            success = false
          }
        })
    }
    return success
  }

  handleSubmit = async (employeeData, setFieldError, setSubmitting) => {
    const currentEmail = this.props.employee && this.props.employee.email ? this.props.employee.email : ""
    const newEmail = employeeData.email
    const { employee } = this.props
    const { navigate, employeeId, isMe } = this.props
    const { avatar } = employeeData
    const data = await this.prepareData(employeeData)
    const oldProfilePicture = _.get(employee, 'avatar.url', '')
    const doUploadAvatar = !!avatar && oldProfilePicture !== avatar
    const doDeleteAvatar = !avatar && !!oldProfilePicture
    try {
      const pwChangeRoutineSuccessful = await this.changePassword(_.get(employeeData, 'password', {}), setFieldError)
      if (!pwChangeRoutineSuccessful) {
        setSubmitting(false);
        return;
      }

      const result = await this.props.updateEmployee({ employeeId, employee: { ...data, id: employeeId } });
      if (result.errors) {
        throw result.errors
      }

      if (doUploadAvatar) {
        const { data: { updateEmployee } } = result
        await this.uploadAvatar(_.get(updateEmployee, 'id', employeeId), avatar)
        fileUploadService.setData({ isImageUploaded: true })
      }

      if (doDeleteAvatar) {
        await this.deleteAvatar(_.get(employee, ['id'], employeeId))
      }

      if (currentEmail !== newEmail && isMe) {
        logout()
        return
      }

      shopTracking({
        ...this.props.location,
        action: 'employeeUpdated'
      })

      await getGuidForUserDataConsent(employeeData.practices.defaultPracticeId)
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Employee), EventType.Update)

      navigate({ pathname: '/overview/orgManagement/users' })
    } catch (errors) {
      const { message } = errors[0]
      setSubmitting(false);
      if (message.includes(CostcoErrorCodes.GeneralInvalidInput)) {
        setFieldError("password.passwordEnter", this.formatMessage("password.notStrongEnough"))
      }
      if (message.includes(CostcoErrorCodes.PasswordPolicyError)) {
        setFieldError("password.passwordEnter", this.formatMessage("password.notAcceptable"))
      }
    }
  }

  onError() {
    if (window && window.document) {
      scrollTo('.has-error', {
        offset: -200,
        duration: 600
      })
    }
  }

  canChangeRoles() {
    const { isMe = false, isManager = false, isOrgManager = false } = this.props
    return !isMe && (isOrgManager || isManager) && !this.state.usernameView
  }

  submitClicked = false

  getExistingEmailCheckRequired(currentValue, newValue) {
    return currentValue !== newValue
  }

  handleShowOtpVerificationModal = async (e, isPhoneRequest, checkRequired, value = null, action = null, emailRef = null) => {
    if (isPhoneRequest || (emailRef && emailRef.current.checkValidity())) {
      e.preventDefault()
      if (!isPhoneRequest && checkRequired) {
        try {
          await checkUsername(value)
        } catch (error) {
          action('email', this.formatMessage('error_messages.EMAIL_ALREADY_EXISTS_EMPLOYEE'))
          return
        }
      }

      this.setState({ openOtpVerificationModal: true });
      this.setState({ isPhoneRequest });
    }
  }

  handleCloseOtpVerificationModal = async ({ isVerified, isPhoneRequest, contactInfo }) => {
    this.setState({ openOtpVerificationModal: false });

    if (isVerified) {
      const { employeeId } = this.props;
      isPhoneRequest
        ? await this.updatePhoneVerificationStatus(contactInfo, isVerified, employeeId)
        : await this.updateEmailVerificationStatus(contactInfo, isVerified, employeeId)
    }
  }

  updatePhoneVerificationStatus = async (contactInfo, isVerified, employeeId) => {
    const response = await this.props.updateEmployeePhoneVerificationStatus(
      { employeeId, empPhoneUpdateRequest: { phone: contactInfo, phoneVerified: isVerified } });

    response.data.updateEmployeePhoneVerificationStatus && this.props.refetch();
  }

  updateEmailVerificationStatus = async (contactInfo, isVerified, employeeId) => {
    const response = await this.props.updateEmployeeEmailVerificationStatus(
      { employeeId, empEmailUpdateRequest: { email: contactInfo, emailVerified: isVerified } });

    if (response.data.updateEmployeeEmailVerificationStatus) {
      const { employee, isMe } = this.props;
      const currentEmail = employee.email;
      const newEmail = contactInfo;

      if (currentEmail !== newEmail && isMe) {
        logout();
        return;
      }
      else {
        this.props.refetch();
      }
    }
  }

  isEmailOrPhoneNotVerified(employee, values) {
    return !employee.emailVerified || !employee.phoneVerified || employee.email !== values.email ||
      employee.phone !== values.phone
  }

  handlePhoneNoFieldKeyUp = (value) => {
    if (value?.length > PhoneNumberLengthForValidaton) {
      this.setState({
        isCountryCodeBlocked: this.isCountryCodeBlocked(value)
      })
    }
    else {
      this.setState({
        isCountryCodeBlocked: false
      })
    }
  }

  render() {
    const {
      loading,
      isMe,
      isManager,
      isOrgManager,
      practicesOptions,
      employee = {},
      locales,
      formTitle,
      intl: { formatMessage },
      NotificationTimeOptions,
      isReadOnly,
      countryCodeLoading
    } = this.props
    const practiceWidgetDisabled = !isManager

    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'
    const roles = ROLES_MAP[role].map(item => item.value)
    const rolesLabel = ROLES_MAP[role].map(item => item.label)
    const disabledRoleChange = !this.canChangeRoles()

    return (
      <EmployeeFormContainer>
        <Loading isLoading={loading} />
        {!loading && !countryCodeLoading &&
          <Formik
            onSubmit={(values, actions) => { this.handleSubmit(values, actions.setFieldError, actions.setSubmitting) }}
            validationSchema={this.employeeValidationSchema}
            initialValues={this.prepareDataFromEmployee(employee)}
            enableReinitialize={true}
            validateOnMount={true}
          >
            {({ values, isSubmitting, errors, dirty, setFieldError, }) => {
              this.submitClicked = false
              return (
                <Form ref={this.employeeFormRef}>
                  <Loading isLoading={isSubmitting} />
                  <div className="container">
                    <div className="col-lg-10 offset-lg-1">
                      <h1 data-qa="title">
                        <FormattedMessage {...T(formTitle)} />
                      </h1>
                      <p data-qa="required-text">
                        <FormattedMessage
                          {...T('common.required_text')}
                          values={{ mark: <span className="text-primary">*</span> }}
                        />
                      </p>
                      <fieldset disabled={!isMe}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group profilePicture" data-qa="avatar">
                              <Field name="avatar" isReadOnly={isReadOnly} component={ImageDropField} />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group" data-qa="employee">
                              <div className="row">
                                <div className="col-lg-12">
                                  <Field
                                    disabled={isReadOnly}
                                    name="firstName"
                                    component={InputField}
                                    label={formatMessage(T('common.firstName'))}
                                    labelClassName="required"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <Field
                                    disabled={isReadOnly}
                                    name="lastName"
                                    component={InputField}
                                    label={formatMessage(T('common.lastName'))}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            {this.state.usernameView
                              ? <Field
                                disabled={isReadOnly}
                                name="username"
                                component={InputField}
                                label={formatMessage(T('employee.username'))}
                                labelClassName="required"
                              />
                              : <Field
                                type="email"
                                disabled={isReadOnly}
                                name="email"
                                component={InputWithIconAndTextField}
                                label={formatMessage(T('common.email'))}
                                labelClassName="required"
                                isVerified={employee.emailVerified && employee.email === values.email}
                                onChange={this.setDisableNotificationOptionName(employee, values)}
                              />}
                            <div className='col-6 ml-auto p-0'>
                              {isMe && !this.state.usernameView && (!employee.emailVerified || employee.email !== values.email) &&
                                <Button
                                  secondary
                                  type="submit"
                                  className="btn-block"
                                  disabled={isReadOnly || !isMe || errors.email}
                                  data-qa="verify-email"
                                  onClick={(e) =>
                                    this.handleShowOtpVerificationModal(e, false,
                                      this.getExistingEmailCheckRequired(employee.email, values.email), values.email,
                                      setFieldError, this.employeeFormRef)}
                                >
                                  <FormattedMessage {...T('common.verifyEmail')} />
                                </Button>
                              }
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Field
                              disabled={isReadOnly}
                              name="phone"
                              component={InputWithIconAndTextField}
                              label={formatMessage(T('common.mobileNumber'))}
                              isVerified={employee.phoneVerified && employee.phone === values.phone}
                              hideIconAndText={_.isEmpty(values.phone)}
                              onChange={this.setDisableNotificationOptionName(employee, values)}
                              onKeyUp={(e) => {
                                this.handlePhoneNoFieldKeyUp(e.target.value)
                              }}
                            />
                            {this.state.isCountryCodeBlocked &&
                              <span className="help-block notification-warning-span" data-qa="notify-countrycode-blocked">
                                <i className="fa fa-exclamation-triangle"></i>
                                &nbsp;<FormattedMessage
                                  {...T('countryCallingCodeIsBlocked.note')}
                                />
                              </span>}
                            <div className='col-6 ml-auto p-0'>
                              {isMe && !this.state.isCountryCodeBlocked && (!employee.phoneVerified || employee.phone !== values.phone) &&
                                <Button
                                  secondary
                                  disabled={isReadOnly || !isMe || _.isEmpty(values.phone) || errors.phone}
                                  type="button"
                                  className="btn-block"
                                  data-qa="verify-number"
                                  onClick={(e) => this.handleShowOtpVerificationModal(e, true)} >
                                  <FormattedMessage {...T('common.verifyNumber')} />
                                </Button>
                              }
                            </div>
                          </div>
                        </div>

                        <>
                          {this.state.openOtpVerificationModal &&
                            <OtpVerificationModal
                              show={this.state.openOtpVerificationModal}
                              onHide={this.handleCloseOtpVerificationModal}
                              contactInfo={this.state.isPhoneRequest ? values.phone : values.email}
                              isPhoneRequest={this.state.isPhoneRequest}
                              locale={values.locale}
                            />
                          }
                        </>

                        <div className="row" style={{ "marginTop": "2%" }}>
                          <div className="col-lg-12">
                            <p className="help-block" data-qa="details-help-text">
                              <FormattedMessage {...T('employeeCreate.note')} />
                            </p>
                          </div>
                        </div>

                        {this.state.usernameAvailable &&
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group form-check" data-qa="use_username">
                                <label className="form-check-label font-weight-normal">
                                  <input
                                    disabled={isReadOnly}
                                    type="checkbox"
                                    className="form-check-input"
                                    data-qa="otherwayaround"
                                    checked={this.state.usernameView}
                                    onChange={() => { }}
                                    onClick={this.usernameViewToggle}
                                  />
                                  <FormattedMessage {...T('common.useUsername')} />
                                </label>
                              </div>
                            </div>
                          </div>}

                        {this.state.usernameView &&
                          <div className="row">
                            <div className="col-lg-12">
                              <p className="warn-block" data-qa="details-help-text">
                                <i className="fas fa-fw fa-exclamation-triangle exclamation-icon text-primary" />
                                <FormattedMessage {...T('common.usernameWarning')} />
                              </p>
                            </div>
                          </div>}

                        <div className="form-group" data-qa="alerts">
                          <div className='notification-header-div'>
                            <h2>
                              <FormattedMessage {...T('notifications.header')} />
                            </h2>
                            {this.isEmailOrPhoneNotVerified(employee, values) &&
                              <div>
                                <span className="help-block notification-warning-span" data-qa="notify-email-phoneNumber-notVerified">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  &nbsp;<FormattedMessage
                                    {...T('employeeEdit.notifyEmailPhoneNumberNotVerified')}
                                  />
                                </span>
                              </div>}
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="enableNewMessage"
                                component={RadioButtonGroupField}
                                header={<FormattedMessage {...T('notifications.newMessage')} />}
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly || !isMe}
                                name="locale"
                                component={SelectField}
                                label={formatMessage(T('employeeEdit.locale'))}
                                data-qa="locale"
                                options={locales.map(country => ({
                                  value: country.countryCode,
                                  label: formatMessage(T('languageName.' + country.countryCode))
                                }))}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <Field
                                disabled={isReadOnly}
                                name="enableSatisfactionRating"
                                component={RadioButtonGroupField}
                                header={
                                  <FormattedMessage
                                    {...T('notifications.negativeSatisfactionRating')}
                                  />
                                }
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>
                            <div className="col-lg-6">
                              {(values.enableSatisfactionRating !== 'OFF' ||
                                values.enableLessonRating !== 'OFF') &&
                                NotificationTimeOptions && <Field
                                  disabled={isReadOnly || !isMe}
                                  name="notificationTime"
                                  component={SelectField}
                                  label={formatMessage(T('notification_timestamp_label'))}
                                  data-qa="locale"
                                  value={values.notificationTime}
                                  options={NotificationTimeOptions.timeSet.map(time => ({
                                    value: `${time + ':00'}`,
                                    label: time
                                  }))}
                                />}
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-6 margin-child-0">
                              <Field
                                disabled={isReadOnly}
                                name="enableLessonRating"
                                component={RadioButtonGroupField}
                                header={
                                  <FormattedMessage {...T('notifications.negativeLessionRating')} />
                                }
                                options={notificationsOptions}
                                labelArray={notificationsLabels}
                                createDataQaEntry={this.createDataQaEntry}
                                disableNotificationOptionName={disableNotificationOptionName}
                              />
                            </div>

                          </div>
                        </div>
                        <HorizontalRow />
                        <div className="form-group" data-qa="alerts">
                          <div className="row">
                            <div className="col-lg-6 margin-child-0">
                              <Field
                                disabled={isReadOnly}
                                name="enableTraining"
                                component={RadioButtonGroupField}
                                header={<FormattedMessage {...T('common.hearingLessons')} />}
                                options={['YES', 'NO']}
                                labelArray={[
                                  <FormattedMessage {...T('common.yes')} />,
                                  <FormattedMessage {...T('common.no')} />
                                ]}
                                createDataQaEntry={this.createDataQaEntry}
                              />
                            </div>
                            {values.enableTraining === 'NO' &&
                              <div className="col-md-12">
                                <p className="help-block" data-qa="notify-disable-hearing-lessons">
                                  <FormattedMessage
                                    {...T('employeeEdit.disablingHearingLessonsNotification')}
                                  />
                                </p>
                              </div>}
                          </div>
                        </div>
                      </fieldset>
                      <HorizontalRow />
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group" data-qa="roles">
                            <div data-tip data-for="roles">
                              <Field
                                name="role"
                                component={RadioButtonGroupField}
                                header={<FormattedMessage {...T('employee.roles')} />}
                                options={roles}
                                labelArray={rolesLabel}
                                createDataQaEntry={this.createDataQaEntry}
                                onSelect={this.onRoleChange}
                                disabled={disabledRoleChange || isReadOnly}
                              />
                            </div>
                            {disabledRoleChange &&
                              <Tooltip
                                id="roles"
                                subtitle={formatMessage(T('multistore.error.role'))}
                                place="top"
                              />}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <Field
                            name="practices"
                            component={PracticesSelectField}
                            assignAllpractices={values.role === 'orgManager'}
                            loading={loading}
                            options={practicesOptions}
                            disabled={practiceWidgetDisabled || isReadOnly}
                            labelClassName="required"
                          />
                        </div>
                      </div>
                      {isMe &&
                        <div>
                          <HorizontalRow />
                          <div className="row" data-qa="">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <Field
                                  disabled={isReadOnly}
                                  name="userDataConsent"
                                  component={TeCaImprovementProgramInformationModal}
                                  label={formatMessage(T('TCImprovementProgram.label'))}
                                  options={userDataConsentOptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>}
                      {(this.state.usernameView || isMe) &&
                        <div>
                          <HorizontalRow />
                          <div className="row" data-qa="password">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <Field
                                  disabled={isReadOnly}
                                  name="password"
                                  component={PasswordComponent}
                                  oldPassword={isMe}
                                  required={false}
                                />
                              </div>
                            </div>
                          </div> </div>}
                      {!(this.state.usernameView || isMe) &&
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="help-block" data-qa="details-help-text">
                              <FormattedMessage {...T('MissingPasswordSettings')} />
                            </p>
                          </div>
                        </div>}
                      <div className="row">
                        <div className="col-lg-3 offset-lg-6">
                          <Button
                            secondary
                            type="button"
                            className="btn-block"
                            onClick={() => this.props.navigate(-1)}
                            data-qa="cancel-button"
                          >
                            <FormattedMessage {...T('common.cancel')} />
                          </Button>
                        </div>
                        <div className="col-lg-3">
                          <Button
                            primary
                            disabled={isReadOnly || !dirty}
                            type="submit"
                            onClick={() => this.submitClicked = true}
                            className="btn-block"
                            data-qa="continue-button"
                          >
                            <FormattedMessage {...T(this.props.submitLabel)} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )


            }}
          </Formik>}
      </EmployeeFormContainer>
    )
  }
}

export default withRouter(withQuery(injectIntl(EmployeeForm)))
