import { getOrganizationIdFromCache } from "../../helper/PracticeUtils";

export const mapQueryToVariables = ({ params, filters }) => {
  return {
    filters,
  };
};

export const options = ({ params, filters }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables({ params, filters }),
});

export const recipientsCounterQueryOptions = ({ params, filters }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables({ params, filters }),
  fetchPolicy: 'network-only',
});

export const assignedPracticesOptions = () => ({
  notifyOnNetworkStatusChange: true,
  variables: {orgId: parseInt(getOrganizationIdFromCache())},
  fetchPolicy: 'network-only'
})

export const mapProps = ({
  FiltersQuery: { loading, refetch, AssignedPractices = [] },
  ownProps: { intl },
}) => {
  return {
    refetch,
    loading,
    practices: AssignedPractices,
    intl,
  };
};

export const popupMapProps = ({ data: { overlay } }) => {
  return {
    overlay,
  };
};

export const mapRecipientsCounterProps = ({
  RecipientsCounterQuery: {
    loading,
    refetch,
    MarketingRecipientsCounter = {},
    Me: { employee: { id: employeeId } } = { employee: { id: {} } },
  },
  RecipientsCounterQuery,
}) => {
  const { recipients = 0, total = 0 } = MarketingRecipientsCounter;
  return {
    RecipientsCounterQuery,
    loadingRecipientsCounter: loading,
    initialMarketingRecipients: recipients,
    initialMarketingTotal: total,
    employeeId,
  };
};
