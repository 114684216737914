import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from "react"
import { ConvertToFormattedDate } from "helper/DateUtils";
import { Navigate } from "react-router-dom"
import { Underlined } from "styledComponents/Span"
import AssistantTable from "../AssistantTable"
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { QUERY_ASSISTANT_HISTORY_LIST } from 'graphql/queries/AssistantHistoryQuery'
import { getLocaleFromCache } from 'helper/LocaleHelper'
import { compose } from 'lodash/fp'
import Loading from 'components/Loading'

import fflags from 'helper/FFlags'

export function AssistantHistory(props) {
  const { loading, assistantHistory, customer } = props
  const isConnectDaToTelecareEnabled = fflags.variation(
    'enable-da-in-telecare',
    false
  )

  const isAudioStreamingEnabled = fflags.variation(
    'enable-audio-streaming-in-telecare',
    false
  )

  if (!isConnectDaToTelecareEnabled) {
    return <Navigate to={`/profile/${customer?.id}/overview/satisfaction`} />
  }

  if (loading) {
    return <Loading isLoading={loading} />
  }
  return (
    <Fragment>
      {!customer?.digitalAssistantConsent || !assistantHistory?.listOfModifications?.length ?
        <h2><FormattedMessage {...T("customerProfile.noAssistantHistory")} /> </h2>
        : <div>
          <div><FormattedMessage {...T("customer_overview.da_Title")} /> </div>
          <div><FormattedMessage {...T("customer_overview.da_NumberOfChanges")} /> : {assistantHistory.numberOfModifications}</div>
          <div style={{ marginTop: '30px' }}>
            <Underlined color="#ffb786">{assistantHistory.ownVoiceProcessingName} : {assistantHistory.ownVoiceProcessingCount}</Underlined>
            <Underlined color="#f16b6f" marginLeft="60px">{assistantHistory.dynamicSoundscapeProcessingName} : {assistantHistory.dynamicSoundscapeProcessingCount}</Underlined>
            <Underlined color="#383f78" marginLeft="60px">{assistantHistory.compressionName} : {assistantHistory.compressionCount}</Underlined>
            {isAudioStreamingEnabled && <Underlined color="#B8CEFD" marginLeft="60px">{assistantHistory.audioStreamingName} : {assistantHistory.audioStreamingCount}</Underlined>}
          </div>
          <AssistantTable assistantHistory={assistantHistory} />
        </div>
      }
    </Fragment>
  )
}

export const mapProps = ({
  data: { refetch, loading, AssistantHistoryList }
}) => {
  return {
    refetch,
    loading,
    assistantHistory: AssistantHistoryList
  }
}

export const options = (ownProps) => {
  const convertedDate = ownProps.ratingDate && ConvertToFormattedDate(ownProps.ratingDate) || null;
  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      digitalAssistantId: ownProps.customer?.digitalAssistantId,
      timeZone: ownProps.Practice?.timeZone,
      brandId: ownProps.customer?.brandDisplayId,
      language: getLocaleFromCache(),
      recordsCount: 50,
      date: convertedDate
    },
    fetchPolicy: 'network-only'
  }
}

const withData = compose(
  graphql(QUERY_ASSISTANT_HISTORY_LIST, {
    props: mapProps,
    options
  })
)

export default withData(AssistantHistory)
