import { VictoryTooltip } from 'victory'
import { Helpers } from 'victory-core'

const fallbackProps = {
  cornerRadius: 5,
  pointerLength: 10,
  pointerWidth: 10,
  dy: -8
}

export class CustomTooltip extends VictoryTooltip {
  // Overridden in victory-core-native
  render() {
    const { text, datum } = this.props
    if (typeof text === 'function') {
      const ret = text(datum)
      if (!ret) return null
    }
    const props = Helpers.modifyProps(this.props, fallbackProps, 'tooltip')
    const { active } = props
    const tooltip = active ? this.renderTooltip(props) : null
    return tooltip
  }
}
