import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { Divider } from 'styledComponents/Div/DivComponent/DivComponent'
import { BrandSettings, DeviceModelSettings, HearingPrograms } from 'components/_Patient/components'
import HIDBluetoothSetting from './HIDBluetoothSetting'

export class HearingInstrumentSettings extends Component {
  constructor(props) {
    super(props)
  }
  shouldShowBluetoothSettings = () => {
    if (this.props.enableBluetoothFeature) {
      const { bluetoothSupported } = this.props.HIDData.HDModelsFeatures || false
      return bluetoothSupported
    } else {
      return false
    }
  }

  render() {
    const {
      manufacturersLeft,
      manufacturersRight,
      deviceModelsLeft,
      deviceModelsRight,
      deviceModelLeftId,
      deviceModelRightId,
      hearingPrograms = [],
      selectedBrandRight,
      selectedBrandLeft,
      HDModelsFeatures,
      bluetoothDisabled,
      customPrograms = [[]],
      isD11
    } = this.props.HIDData
    const { intl: { formatMessage } } = this.props

    const availablePrograms = _.get(HDModelsFeatures, 'availablePrograms', [])
    const programSlots = _.get(HDModelsFeatures, 'programSlots', 0) || 0
    const defaultPrograms = _.get(HDModelsFeatures, 'defaultPrograms', []) || []
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <BrandSettings
              formatMessage={formatMessage}
              onValidationChange={this.props.handleFormBrandSettignsValidChange}
              onSelect={this.props.onBrandSelect}
              setFormRef={this.props.setFormRef}
              manufacturersLeft={manufacturersLeft}
              manufacturersRight={manufacturersRight}
              selectedBrandLeft={selectedBrandLeft}
              selectedBrandRight={selectedBrandRight}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <DeviceModelSettings
              formatMessage={formatMessage}
              onValidationChange={this.props.handleFormDeviceSettignsValidChange}
              onSelect={this.props.onSelectDeviceModel}
              setFormRef={this.props.setFormRef}
              deviceModelsLeft={deviceModelsLeft}
              deviceModelsRight={deviceModelsRight}
              deviceModelLeftId={deviceModelLeftId}
              deviceModelRightId={deviceModelRightId}
            />
            {this.shouldShowBluetoothSettings() &&
              <HIDBluetoothSetting
                onChange={this.props.onHIDSettingChange}
                checked={bluetoothDisabled}
              />}
            {programSlots > 0 && [
              <Divider key="divider-hearing-programs" />,
              <HearingPrograms
                key="hearing-programs"
                onChange={this.props.onHearingProgramChange}
                programSlots={programSlots}
                defaultPrograms={defaultPrograms}
                hearingPrograms={availablePrograms}
                selectedValues={hearingPrograms}
                customPrograms={customPrograms}
                isD11={isD11}
              />
            ]}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(HearingInstrumentSettings)
