import styled from 'styled-components'
import { ActionButtons } from '../'

export const StyledBrandList = styled.div`
  margin-bottom: 20px;
  .formGroup {
    padding-left: 20px;
    &:first-of-type {
      padding-left: 0;
      label {
        color: #D83831;
      }
    }
    &:last-of-type {
      label {
        color: #0072E3;
      }
    }
    .dropdown {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`

export const StyledDeviceModelList = styled.div`
  margin-bottom: 20px;

  .formGroup {
    padding-left: 20px;
    &:first-of-type {
      padding-left: 0;
    }
    .dropdown {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`

export const StyledDevicePersisting = styled.div`
  margin: 20px 0;

  .formGroup {
    flex: 1;
  }
`
export const PersistentInfo = styled.div`
  position: relative;
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: #f8f8f8;
  border: 1px solid #b4b8bb;
  margin-top: 20px;
  padding: 20px 0;
`
export const PointingArrow = styled.div`
  top: 0;
  right: 50px;
  left: auto;
`

export const Programs = styled.div`
  .dropdown {
    width: 100%;
    margin-bottom: 20px;
  }

  .dropdown-menu {
    width: 100%;
  }
`

export const StyledPatientPersonalInfo = styled.div`
  margin-bottom: 20px;
  .formGroup {
    padding-left: 20px;
    &:first-of-type {
      padding-left: 0;
    }
  }
`

export const StyledPraticeInfo = styled.div`
  margin-bottom: 20px;

  .formGroup {
    padding-left: 20px;
    &:first-of-type {
      padding-left: 0;
    }
    .dropdown {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  .modalImage {
    width: 100%;
    height: 100%;
    flex: 1;
  }
`

export const ActionButtonsCreate = styled(ActionButtons)`
  margin: 40px 0 20px 0;
`

export const ActionButtonsEdit = styled(ActionButtons)`
  margin-top: 30px;
  &.left {
    padding-left: 0px;
    justify-content: flex-start;
  }
  &.right {
    width: 100%;
    padding-right: 0px;
  }
`

export const StyledPopup = styled.div`
  padding: 10px 5px;
  border: 0;
  background-color: transparent;
  white-space: pre-wrap;
  font-family: Montserrat Regular, sans-serif, monospace;
  word-break: break-word;
`

export const DisableSection = styled.div`
pointer-events: none;
disabled: true;
opacity: 0.5;
`
