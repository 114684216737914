import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
/* eslint-disable key-spacing */
import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import { T } from 'core'
import _ from 'lodash'

import PatientDetails from 'components/_Profile/Details'
import { PatientNavigation, PatientNavigationItem } from 'components/_Profile/Navigation'
import { getLatestDevices } from 'selectors/HearingDevicesSelector'
import Loading from 'components/Loading'
import DataContext from "containers/DataContext"
import { check, flags } from 'helper/customerCapabilities'
import { customerTracking } from 'helper/customerTracking'
import { withRouter } from "helper/withRouter";
import { getVideoCallLibByAppVersion } from 'helper/VideoCallUtil'
import { setPatientEditInfoInCache } from 'helper/PatientSettingsUtils'
import { Outlet } from 'react-router-dom';

class Profile extends Component {
  static contextType = DataContext;

  state = {
    showSuccessPopup: false,
    disabled: false,
    hide: false
  }

  getContextValues() {
    return {
      ...this.context,
      customer: {
        ...this.props.customer,
        compatibleVideoLib: getVideoCallLibByAppVersion(this.props.customer.appInfo?.appVersion)
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loading, patientId, customer, location, navigate } = nextProps
    if (!loading) {
      const noBlueTooth =
        !check('bluetooth', customer, [flags.HEARING_DEVICE_SUPPORTED]) ||
        !check('bluetooth', customer, [flags.MOBILE_DEVICE_SUPPORTED])
      if (location.pathname == `/profile/${patientId}/overview` && noBlueTooth) {
        navigate(`/profile/${patientId}/overview/satisfaction`)
      }
    }
  }

  removePatient = () => {
    this.props
      .deletePatient({ patientId: this.props.customer.id })
      .then(data => {
        const { customer = {}, location = {} } = this.props
        customerTracking({
          ...location,
          activeCustomer: customer,
          action: 'customerDeleted'
        })
        return data
      })
      .then(() => {
        this.props.navigate('/')
      })
      .catch(e => {
        console.log('Error', e)
      })
  }

  popupClick = () => {
    this.setState({ showSuccessPopup: false })
  }

  componentDidUpdate() {
    const customer = this.props.customer;
    if (customer && customer.hearingDevices) {
      const patientInfoData = {
        practiceId: customer.practiceId,
        employeeId: customer.employeeId,
        leftModelId: customer.hearingDevices?.leftDevice?.id,
        rightModelId: customer.hearingDevices?.rightDevice?.id,
        id: customer.id
      }
      setPatientEditInfoInCache(patientInfoData);
    }
  }


  render() {
    const {
      Me,
      loading,
      patientId,
      devices,
      messages,
      chatMessagesLoading,
      ...rest
    } = this.props
    const { customer = {}, location = {} } = rest

    if (loading || chatMessagesLoading) {
      return (
        <div>
          <Loading isLoading={true} />
        </div>
      )
    }

    let overviewPath = `/profile/${patientId}/overview`

    return (
      <DataContext.Provider value={this.getContextValues()}>
        <section data-qa="profile">
          <PatientDetails {...customer} devices={devices} />
          <PatientNavigation
            Me={Me}
            customer={customer}
            active={location.pathname}
            location={location}
            removePatient={this.removePatient}
          >
            <PatientNavigationItem
              href={overviewPath}
              dataqa="Overview"
              label={<FormattedMessage {...T('ProfileNav.overview')} />}
              active={location.pathname}
              className="col-md-2"
            />
            <PatientNavigationItem
              href={`/profile/${patientId}/ratings`}
              dataqa="Ratings"
              label={<FormattedMessage {...T('ProfileNav.ratings')} />}
              active={location.pathname}
              className="col-md-2"
            />
            <PatientNavigationItem
              href={`/profile/${patientId}/messages`}
              dataqa="Messages"
              label={<FormattedMessage {...T('ProfileNav.messages')} />}
              active={location.pathname}
              className="col-md-2"
            />
            <PatientNavigationItem
              href={`/profile/${patientId}/tuning`}
              dataqa="RemoteTuning"
              label={<FormattedMessage {...T('ProfileNav.remote-fine-tuning')} />}
              active={location.pathname}
              className="col-md-3"
            />
            <PatientNavigationItem
              href={`/profile/${patientId}/settings`}
              dataqa="PatientSettings"
              label={<FormattedMessage {...T('ProfileNav.patient-settings')} />}
              active={location.pathname}
              className="col-md-3"
            />
          </PatientNavigation>
          {!loading && <main>
            <Outlet />
          </main>}
        </section>
      </DataContext.Provider>
    );
  }
}

const mapProps = ({
  data: { refetch, loading, Me, Customer, HearingDevices },
  ownProps: { params, intl }
}) => {
  const patientId = parseInt(params.id, 10)

  return {
    refetch,
    loading,
    Me,
    patientId,
    customer: Customer,
    devices: getLatestDevices(HearingDevices),
    intl
  }
}

const mapPropsChatMessages = ({
  ChatMessagesQuery: { loading, refetch, Messages = [] },
  ChatMessagesQuery
}) => {
  return {
    ChatMessagesQuery,
    messages: Messages,
    chatMessagesrefetch: refetch,
    chatMessagesLoading: loading
  }
}
const QUERY_CHAT_MESSAGES = gql`
  query ChatMessagesQuery($patientId: Int!, $pageNumber: Int, $size: Int) {
    Messages(patientId: $patientId, pageNumber: $pageNumber, size: $size) {
      id
      createDate
      readOnDate
      message
      recipient {
        id
      }
      sender {
        id
      }
    }
  }
`

const QUERY_PATIENT_DETAILS = gql`
  query getPatientDetails($patientId: Int!) {
    Me {
      employee {
        id
        manager
        orgManager
        defaultPracticeId
      }
    }
    HearingDevices(customerId: $patientId) {
      uuid
      hdPrograms {
        programNumber
        type
      }
      leftDevice {
        id
        model
        manufacturer
      }
      rightDevice {
        id
        model
        manufacturer
      }
      persistentConfiguration
      teleCareVersion
      capabilities {
        bluetooth
        appConnected
      }
    }
    Customer(id: $patientId) {
      id
      uuid
      upid
      firstName
      lastName
      employeeId
      practiceId
      journeyStatus
      digitalAssistantId
      digitalAssistantConsent
      brandDisplayId
      hearingDevices {
        leftDevice {
          id
          hiTypeUri
          manufacturer
          model
        }
        rightDevice {
          id
          hiTypeUri
          manufacturer
          model
        }        
        capabilities {
          bluetooth
          appConnected
        }
      }
      appInfo {
        appName
        appVersion
      }
    }
  }
`

const MUTATION_DELETE_PATIENT = gql`
  mutation DeleteatientMutation($patientId: Int!) {
    deletePatient(patientId: $patientId)
  }
`
const options = (data) => ({
  notifyOnNetworkStatusChange: true,
  variables: { patientId: parseInt(data?.params?.id) },
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_PATIENT_DETAILS, {
    props: mapProps,
    options
  }),
  graphql(QUERY_CHAT_MESSAGES, {
    name: 'ChatMessagesQuery',
    props: mapPropsChatMessages,
    options
  }),
  graphql(MUTATION_DELETE_PATIENT, {
    props: ({ mutate }) => ({
      deletePatient: variables =>
        mutate({
          variables
        })
    })
  }),
  injectIntl
)

export default withRouter(withData(Profile))
