import React, { Component } from "react";
import schema from "../styledComponents/Themes/schema";
import { ThemeProvider } from "styled-components";
import { getThemeWithMode } from "../styledComponents/Themes";
class ThemeSwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: schema["theme-signia"]["light"],
      mode: "light",
    };
  }

  setTheme = () => {
    const themeWithMode = getThemeWithMode(this.props.brandId);
    this.setState({ theme: themeWithMode[this.props.mode] });
  };

  componentDidMount() {
    this.setTheme();
  }

  componentDidUpdate(prevProps) {
    if (this.props.brandId !== prevProps.brandId) {
      this.setTheme();
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        {this?.props?.children}
      </ThemeProvider>
    );
  }
}

export { ThemeSwitcher };
