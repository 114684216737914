import { getToken } from 'helper/AuthHelper'
import { TIMEOUT_FOR_ANONYMIZED_GUID } from 'helper/constants'
import { connector } from './Connector'
import _ from 'lodash'

import { userDataConsentStateVar } from 'graphql/apollo/cache/apolloLocalCache'

let dataAnonymizationApiInterval = null

export const isCustomerConsent = (customer) => {
  if (customer.upid) {
		return true
  }

  if (customer.upid !== null) {
    //track error
  }

  return false  
}

export const isUserOptedForDataConsent = () => {
  try
  {
    const { UserDataConsent: { anonEmployeeId } } = userDataConsentStateVar()    
    return !_.isNil(anonEmployeeId)
  }
  catch {
    return false
  }  
}

export const getGuidForUserDataConsentFromCache = () => {
  const { UserDataConsent } = userDataConsentStateVar()
  return UserDataConsent
}

export const verifyGuid = guidToBeTested => {
  const guidPattern = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i'
  )
  return guidPattern.test(guidToBeTested)
}

export const getAnonymizationUri = defaultPracticeId => {
  const { organizationId, employeeId } = getToken(true)
  return `/api/anonymization?employee=${employeeId}&organization=${organizationId}&practice=${defaultPracticeId}`
}

const setUserDataConsentAnonymizationGuid = (userdataConsentObject, type, anonymizationGuid) => {
  switch (type) {
    case 'employee':
      userdataConsentObject.anonEmployeeId = anonymizationGuid
      break
    case 'practice':
      userdataConsentObject.anonPracticeId = anonymizationGuid
      break
    case 'organization':
      userdataConsentObject.anonOrganizationId = anonymizationGuid
      break
  }
}

export const getGuidForUserDataConsent = async defaultPracticeId => {
  const updatedData = {
    UserDataConsent: {
      __typename: 'userDataConsent',
      anonOrganizationId: null,
      anonPracticeId: null,
      anonEmployeeId: null
    }
  }
  try {
    const response = await connector.get(getToken(), getAnonymizationUri(defaultPracticeId))
    if (!_.isNil(response) && response.length > 0) {
      response.forEach(anonymization => {
        if (verifyGuid(anonymization.anonymizationGuid))
          setUserDataConsentAnonymizationGuid(
            updatedData.UserDataConsent,
            anonymization.type,
            anonymization.anonymizationGuid
          )
        else
          setUserDataConsentAnonymizationGuid(updatedData.UserDataConsent, anonymization.type, null)
      })
    }    
    userDataConsentStateVar(updatedData)
  } catch (e) {
    console.log('error occured while getGuidForUserDataConsent', e)
  }
}

export const setIntervalTogetGuidForUserDataConsent = async me => {
  if (!_.isNil(me)) {
    try {
      await getGuidForUserDataConsent(me.employee.defaultPracticeId)
      if (dataAnonymizationApiInterval) clearInterval(dataAnonymizationApiInterval)
      dataAnonymizationApiInterval = setInterval(() => {
        getGuidForUserDataConsent(me.employee.defaultPracticeId)
      }, TIMEOUT_FOR_ANONYMIZED_GUID)
    } catch (e) {
      console.log('error occured while setting Interval to getGuidForUserDataConsent', e)
    }
  }
}
