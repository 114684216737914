import { gql } from "@apollo/client";

export const MUTATION_CREATE_LESSON_SET = gql`
  mutation createLessonSetForSignia($hcpId: Int!, $lessonSet: SigniaLessonSetInput!) {
    createLessonSetForSignia(hcpId: $hcpId, lessonSet: $lessonSet) {
      lessonSetName
      comment
      isDefault
      createdDate
      lessonIds
    }
  }
`
export const MUTATION_UPDATE_LESSON_SET = gql`
  mutation updateLessonSetForSignia($hcpId: Int!, $lessonSetId: String!, $lessonSet: SigniaLessonSetInput!) {
    updateLessonSetForSignia(hcpId: $hcpId, lessonSetId: $lessonSetId, lessonSet: $lessonSet) {
      lessonSetName
      comment
      isDefault
      createdDate
      modifiedDate
      lessonIds
    }
  }
`
