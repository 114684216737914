import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { Modal } from 'react-bootstrap'
import { AcceptButtonPrefitingPatient, LinkButton } from 'styledComponents/Button'
import { StyledCheckboxLabel } from 'styledComponents/Label'
import { StyledCheckboxWrapper } from 'styledComponents/Div/DivComponent/DivComponent'
import { StyledPopup } from 'styledComponents/Div/DivComponent/DivPatient'

const ModalHeader = Modal.Header
const ModalTitle = Modal.Title
const ModalBody = Modal.Body
const ModalFooter = Modal.Footer

class HIDBluetoothSetting extends React.Component {
  state = {
    showModal: false
  }

  modal: ?Object = null

  open = (e) => {
    e.preventDefault();
    this.setState({
      showModal: !this.props.disabled
    })
    return false
  }

  close = () => {
    this.setState({
      showModal: false
    })
  }

  render() {
    const { onChange, checked, disabled } = this.props
    return (
      <div className="row">
        <StyledCheckboxLabel data-qa={`HIDsettingLabel`} disabled={disabled}>
          <StyledCheckboxWrapper onClick={onChange} defaultChecked={checked} disabled={disabled}>
            {checked && <i className="fas fa-check" aria-hidden="true" />}
          </StyledCheckboxWrapper>
          <span onClick={onChange}>
            <FormattedMessage {...T('DisableTC30')} />
          </span>
        </StyledCheckboxLabel>
        {!disabled && (
          <span className="additional-info-link">
            {" - "}
            <LinkButton
              className="helpLink"
              disabled={disabled}
              onClick={this.open}
            >
              <FormattedMessage {...T("DisableTC30.AdditionalInfo")} />
            </LinkButton>
          </span>
        )}
        <Modal
          show={this.state.showModal}
          onHide={this.close}
          backdrop={true}
          ref={ref => (this.modal = ref)}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              <FormattedMessage {...T('DisableTC30')} />
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <StyledPopup>
              <FormattedMessage {...T('DisableTC30.MoreInfoText')} />
            </StyledPopup>
          </ModalBody>
          <ModalFooter>
            <AcceptButtonPrefitingPatient className="btn--min-width" onClick={this.close} primary data-qa="closeModal">
              <FormattedMessage {...T('PreFitting.enable-pop-up-button')} />
            </AcceptButtonPrefitingPatient>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default HIDBluetoothSetting
