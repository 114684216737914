import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import { get, pickBy } from 'lodash'
import { StyledPagination } from 'styledComponents/Pagination/StyledPagination'

import { PRACTICES_QUERY_LIST } from 'graphql/queries/PracticesQuery'
import Loading from 'components/Loading'

import Table from './Table'
import SearchBar from 'components/SearchBar'
import { paginationSize } from 'helper/constants'
import { withQuery } from '../../../helper/withQuery'
import { withRouter } from "helper/withRouter";
import queryString from 'query-string'
import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'

const INITIAL_QUERY = {
  sort: 'name',
  order: 'desc',
  page: 0
}

export class ManagePracticesPage extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentProps = this.props
    const { location } = nextProps
    const currentSort = get(currentProps, 'query.sort', INITIAL_QUERY.sort)
    const nextSort = get(nextProps, 'query.sort', '')

    if (currentSort !== nextSort) {
      const query = pickBy({ ...INITIAL_QUERY, ...nextProps.query })
      location.search = queryString.stringify(query)
      this.props.navigate(location)
    }
  }

  setSort = sortField => {
    const { location } = this.props
    const prevOrder = get(location, 'query.order', '') || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'
    const sort = sortField
    const query = { ...location.query, sort, order }
    location.search = queryString.stringify(query)
    this.props.navigate(location)
  }

  setPage = pageNumber => {
    const { query } = this.props
    const page = pageNumber - 1
    const currentQuery = { ...query, page }
    location.search = queryString.stringify(currentQuery)
    this.props.navigate(location)
  }

  render() {
    const { loading, Me, practices = [], location, pagination = {}, query } = this.props
    const currentPage = get(pagination, 'pageNumber', 0) + 1
    const numberOfElements = get(pagination, 'numberOfElements', 0)
    const { sort } = query || {}
    return (
      <div className="manage--practices">
        <Loading isLoading={loading} />

        <div className="row">
          <div className="col-6 offset-6">
            <SearchBar query={query} />
          </div>
        </div>
        <Table
          refetch={this.props.refetch}
          sort={sort}
          setSort={this.setSort}
          Me={Me}
          practices={practices}
          data-qa="manage__table"
        />
        {numberOfElements > 0 &&
          <div className="d-flex justify-content-center">
            <StyledPagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage={paginationSize}
              totalItemsCount={get(pagination, 'totalElements', 0)}
              onChange={this.setPage}
            />
          </div>}
      </div>
    )
  }
}

const mapProps = ({
  data: { refetch, loading, Me, PracticesList: { content, pagination } = [] }
}) => ({
  practices: content,
  Me,
  refetch,
  loading,
  pagination
})

export const mapQueryToVariables = ({ sort, page = 0, search = '', order }) => ({
  sort: sort || INITIAL_QUERY.sort,
  search: search ? 'name:' + search : '',
  page: parseInt(page, 10) || INITIAL_QUERY.page,
  order: order || INITIAL_QUERY.order,
  orgId: parseInt(getOrganizationIdFromCache())
})

const withData = compose(
  graphql(PRACTICES_QUERY_LIST, {
    props: mapProps,
    options: ({ location, query }) => ({
      notifyOnNetworkStatusChange: true,
      variables: mapQueryToVariables(query),
      fetchPolicy: 'network-only'
    })
  })
)

export default withRouter(withQuery(withData(ManagePracticesPage)))
