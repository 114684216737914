import { getToken } from './TokenUtil'
import { connector } from './Connector'
import { PasswordExpiryPath } from 'helper/constants'


export const checkPasswordExpiry = async () => {
  try {
    return connector
      .get(getToken(), `api/checkpasswordexpiry`)
      .then(response => {
        if(response){
          localStorage.setItem('passwordExpired', true)
        }
        return response
      }
        )
  } catch (e) {
    console.log('error occured while checking password expiry', e)
    return null
  }
}

export const isPasswordExpiryPage = pathname => PasswordExpiryPath == pathname
