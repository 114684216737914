
import { isNil } from 'lodash'
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'
import { getPatientEditInfoFromCache } from 'helper/PatientSettingsUtils'
const mapQueryToVariables = ({
  params: { id = 0 },
  brand = '',
}) => {
  const patientId = parseInt(id)
  const { practiceId, employeeId, leftModelId: leftId = '0', rightModelId: rightId = '0' } = getPatientEditInfoFromCache(patientId);
  return {
    brand,
    patientId,
    hasBrand: !!brand,
    leftId: isNil(leftId) || leftId.trim() == '' ? '0' : leftId,
    rightId: isNil(rightId) || rightId.trim() == '' ? '0' : rightId,
    employeeId: parseInt(employeeId, 10),
    practiceId: parseInt(practiceId, 10),
    hasSelectedModels: !!rightId || !!leftId,
    orgId: parseInt(getOrganizationIdFromCache())
  }
}

export const options = ({ params, brand, location }) => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only',
  variables: mapQueryToVariables({ params, brand, location })
})

export const mapProps = ({
  PatientQuery: { loading, refetch, Me, Brands, AssignedPractices = [], Customer = {}, HIDSetting = {} },
  PatientQuery,
  ownProps: { location, intl }
}) => {
  return {
    me: Me,
    PatientQuery,
    refetch,
    loading,
    Customer,
    manufacturers: Brands,
    practices: AssignedPractices,
    location,
    HIDSetting,
    intl
  }
}

export const mapPropsDeviceModels = ({
  DeviceModelsQuery: { loading, refetch, presetSelected },
  DeviceModelsQuery,
  ownProps: { location, intl }
}) => {
  let presetName = 'STANDARD'

  if (typeof presetSelected !== 'undefined') {
    presetName = presetSelected == null ? 'CUSTOM' : presetSelected
  }

  return {
    DeviceModelsQuery,
    refetch,
    loading,
    presetName,
    location,
    intl
  }
}

export const mapPropsDeviceModelsFeatures = ({
  DeviceModelsFeaturesQuery: { loading, refetch, HDModelsFeatures },
  DeviceModelsFeaturesQuery
}) => {
  return {
    DeviceModelsFeaturesQuery,
    HDModelsFeatures,
    refetch,
    loading
  }
}

export const mapPropsPatientHIs = ({ PatientHIsQuery }) => {
  return {
    PatientHIsQuery
  }
}