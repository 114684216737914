import React, { FC, ReactElement, useEffect, useRef, useState, useMemo, useContext } from 'react'
import Badge from 'react-bootstrap/Badge'
import { QUERY_NOTIFICATIONS_FOR_LOGGED_IN_USER } from '../../graphql/queries/NotificationsQuery'
import {
  MUTATION_CLEAR_ALL_NOTIFICATIONS,
  MUTATION_DELETE_NOTIFICATION,
  MUTATION_UPDATE_NOTIFICATION_READ_DETAIL
} from '../../graphql/mutations/NotificationMutation'
import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import { NotificationContainer } from '../Notification/NotificationContainer';
import { NotificationType, DataCollectionType } from "helper/constants"
import {
  INotificationDropdownProps, NotificationRecordType,
  INotificationComponentMapProps, ISignalRNotification
} from '../../typescript/interfaces/INotificationDropdown'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { NotificationsContext } from '../../contexts/NotificationsContext'
import * as StorageUtils from '../../helper/StorageUtils'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { MakeNotificationsPayload } from 'helper/DataCollection/PayloadData'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { invokeSignalRConnectionForNotification, stopSignalrConnectionForNotification, } from './SignalRConnection'
import DataContext, { DataContextType } from "../../containers/DataContext"




const NotificationDropdown: FC<INotificationDropdownProps> = ({
  notifications, loading, refetch, deleteNotification, updateNotificationReadDetail, clearAllNotifications, intl: { formatMessage }
}): ReactElement => {

  const { me } = useContext(DataContext)

  const sessionStorageKey = 'TraceLoginForNotifications'
  let notificationContainerRef = useRef<HTMLDivElement>()

  const [open, setOpen] = useState(false)
  const [satisfactionNotifications, setSatisfactionNotifications] = useState<NotificationRecordType[]>([])
  const [lessonNotifications, setLessonNotifications] = useState<NotificationRecordType[]>([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)

  const memoizedProviderValue = useMemo(() => ({
    setOpen,
    setSatisfactionNotifications,
    setLessonNotifications,
    satisfactionNotifications,
    lessonNotifications,
    unreadNotificationCount,
    refetch,
    deleteNotification,
    updateNotificationReadDetail,
    clearAllNotifications,
    formatMessage
  }),
    [
      setOpen,
      setSatisfactionNotifications,
      setLessonNotifications,
      satisfactionNotifications,
      lessonNotifications,
      unreadNotificationCount,
      refetch,
      deleteNotification,
      updateNotificationReadDetail,
      clearAllNotifications,
      formatMessage
    ]
  )

  function getFilteredNotificationList(notifications,
    patientNotificationId: string) {
    return notifications.
      filter(x => x.patientNotificationId !== patientNotificationId);
  }

  function updateNotificationList(updateNotification: Function, notificationRecord) {
    updateNotification((prev) => {
      const filteredNotificationsList = getFilteredNotificationList(prev,
        notificationRecord.patientNotificationId)
      return [notificationRecord, ...filteredNotificationsList]
    })
  }

  const setNotificationFromSignalR = (notification) => {
    const { assignedPracticeIds } = me.employee
    if (!assignedPracticeIds.includes(notification.practiceId))
      return;
    const { practiceId, ...notificationRecord } = notification;
    notificationRecord.__typename = "Notification";//set typeName to make the notification record homogeneous
    if (notification.notificationType == NotificationType.DailySatisfaction) {
      updateNotificationList(setSatisfactionNotifications, notificationRecord)
    }
    else {
      updateNotificationList(setLessonNotifications, notificationRecord)
    }
  }

  const signalRObject: ISignalRNotification = {
    connection: null,
    groupId: me?.organizationId,
    setNotificationFromSignalR: setNotificationFromSignalR
  }

  function performDataCollectionOnFirstRender(): void {
    if (StorageUtils.GetItemFromSessionStorage(sessionStorageKey)) return
    StorageUtils.SetItemInSessionStorage(sessionStorageKey, true)

    sendDataToDataCollection(DataCollectionType.Notifications,
      MakeNotificationsPayload(unreadNotificationCount, (satisfactionNotifications.length + lessonNotifications.length)),
      EventType.NotificationLogin)
  }

  useEffect(() => {
    invokeSignalRConnectionForNotification(signalRObject);
    return () => {
      stopSignalrConnectionForNotification(signalRObject);
    }
  }, [])

  useEffect(() => {
    let clickHandler = (e) => {
      if (!notificationContainerRef.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', clickHandler)

    return () => {
      document.removeEventListener('mousedown', clickHandler)
    }
  }, [])

  useEffect(() => {
    if (loading) return

    const satisfactionList = notifications.filter(x => x.notificationType == NotificationType.DailySatisfaction)
    const lessonList = notifications.filter(x => x.notificationType == NotificationType.Lesson)
    const unreadNotifications = notifications.filter(x => x.isRead === false).length

    setSatisfactionNotifications(satisfactionList)
    setLessonNotifications(lessonList)
    setUnreadNotificationCount(unreadNotifications)
  }, [notifications])

  useEffect(() => {
    if (loading) return

    const unread = satisfactionNotifications.filter(x => x.isRead === false).length + lessonNotifications.filter(x => x.isRead === false).length
    setUnreadNotificationCount(unread)
    performDataCollectionOnFirstRender()
  }, [satisfactionNotifications, lessonNotifications])

  return (
    <div className="notification-container" ref={notificationContainerRef}>
      <span className="notification-trigger" onClick={() => { setOpen(!open) }}>
        <i className="fa fa-bell-o " aria-hidden="true">
          {unreadNotificationCount > 0 &&
            <Badge bsPrefix={`notification-badge ${unreadNotificationCount > 99 ? 'large-badge' : 'samll-badge'} `}>
              {unreadNotificationCount > 99 ? '99+' : unreadNotificationCount}</Badge>}
        </i>
      </span>
      <div className={`notification-menu ${open ? 'active' : 'inactive'}`}>
        <NotificationsContext.Provider value={memoizedProviderValue}>
          <NotificationContainer />
        </NotificationsContext.Provider>
      </div>
    </div>
  )
}

const mapProps = ({ data: { loading, refetch, GetNotifications }, ownProps: { intl } }: INotificationComponentMapProps) => {
  return {
    loading,
    refetch,
    notifications: GetNotifications ?? [],
    intl
  }
}

const options = () => {
  return {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only' as WatchQueryFetchPolicy
  }
}

const withData = compose(
  graphql(QUERY_NOTIFICATIONS_FOR_LOGGED_IN_USER, {
    props: mapProps,
    options
  }),
  graphql(MUTATION_CLEAR_ALL_NOTIFICATIONS, {
    props: ({ mutate }) => ({
      clearAllNotifications: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_DELETE_NOTIFICATION, {
    props: ({ mutate }) => ({
      deleteNotification: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_UPDATE_NOTIFICATION_READ_DETAIL, {
    props: ({ mutate }) => ({
      updateNotificationReadDetail: variables =>
        mutate({
          variables
        })
    })
  }),
)

export default withData(injectIntl(NotificationDropdown))


