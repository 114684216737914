import React from 'react'
import Navigation from 'components/_Profile/Settings/Navigation'
import * as Panel from 'components/_Profile/components/Panel'
import DataContext from '../../../containers/DataContext'
import { withRouter } from "helper/withRouter";
import { Outlet } from 'react-router-dom'

class ProfileSetting extends React.Component {
  static contextType = DataContext;
  render() {

    const { customer } = this.context
    const { location, params } = this.props

    return (
      <section className="ratings container">
        <Panel.Base modifier="ratings">
          <Panel.Item className="row">
            <div className="col-lg-12">
              <Navigation active={location.pathname} customerId={params.id} customer={customer} />
            </div>
          </Panel.Item>
          <Panel.Item className="row">
            <div className="col-lg-12">
              <main>
                <Outlet />
              </main>
            </div>
          </Panel.Item>
        </Panel.Base>
      </section>
    )
  }
}

export default withRouter(ProfileSetting)