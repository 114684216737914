import { EventType } from './DataCollectionConstants'
import moment from 'moment-timezone'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { countries, ChatMessageDirection } from 'helper/constants'
import { sha3_512 } from 'js-sha3';
import { isAccountNumberStartsWithT } from '../PracticeUtils'; 
import { makePayloadDataForLegitimateInterest, applyToPayloadData } from './PayloadData';

export const getOrgPayload = async (payloadStructure) => {
  const { updatedOrgData, orgDataStatus } = payloadStructure.LegitimateInterestRecord

  const isAccountNumberStartWithT = await isAccountNumberStartsWithT()
  const dataPayload = new Array()

  if (orgDataStatus.isPasswordPolicyUpdated) {
    const fieldsPasswordPolicy = {
      isEnabled: updatedOrgData.isPasswordExpirationPolicyEnabled,
      validityPeriodDays: updatedOrgData.daysToExpirePassword
    }

    payloadStructure = makePayloadDataForLegitimateInterest(fieldsPasswordPolicy)
    
    const composedPayload = composePayload(EventType.PasswordPolicy, isAccountNumberStartWithT, payloadStructure)
    dataPayload.push(composedPayload)
  }

  if (orgDataStatus.isAutoReplyUpdated) {
    const fieldsAutoReply = {
      autoReplyMode: updatedOrgData.autoReplyMode,
      autoReplyMessage: updatedOrgData.autoReplyMessage
    }

    payloadStructure = makePayloadDataForLegitimateInterest(fieldsAutoReply)
    const composedPayload = composePayload(EventType.AutoReplySetting, isAccountNumberStartWithT, payloadStructure)
    dataPayload.push(composedPayload)
  }

  if (orgDataStatus.isBrandChanged) {
    const fieldBrandName = {
      brandName: updatedOrgData.brandName      
    }
    payloadStructure = makePayloadDataForLegitimateInterest(fieldBrandName)
    const composedPayload = composePayload(EventType.BrandSelection, isAccountNumberStartWithT, payloadStructure)
    dataPayload.push(composedPayload)
  }

  return dataPayload;
}

export const getChatPayload = async (payloadStructure) => {
  const isTestAccount = payloadStructure.Records.some(data => {
    const { Practice: { accountNumber } } = data
    return accountNumber && accountNumber.charAt(0) === 'T'
  });

  const prepareFields = (data) => {
    const { ChatData, Practice: { city, countryCode }, Customer } = data
    const countryObject = _.pick(countries, [countryCode])       
    return getFieldsForChatPayLoad(ChatData, countryObject[countryCode], city, Customer)
  }
  
  payloadStructure = applyToPayloadData(payloadStructure, prepareFields)
  const dataPayload = composePayload(EventType.Communication, isTestAccount, payloadStructure)
  return [dataPayload]
}

const getFieldsForChatPayLoad = (ChatData, country, city, customer) => {
  const fieldsPayload = {
    CommunicationType: 'Chat',
    Direction: ChatData.messageDirection,
    Country: country,
    City: city,
    uuid: customer.uuid,    
    brandId: customer.brandId  ,
    MessageSentByPatientTime: ChatData.messageByPatientDate
  }

  if(ChatData.messageDirection === ChatMessageDirection.PORTAL_TO_APP){
    fieldsPayload["MessageResponseByEmployeeTime"] = new Date()
    fieldsPayload["TextLength"]= ChatData.textLength
  }
  if(ChatData.messageDirection === ChatMessageDirection.APP_TO_PORTAL){
    fieldsPayload["MessageReadByEmployeeTime"] = new Date()   
  }

  return fieldsPayload
}

export const getPayload = async (payloadStructure, eventType = '') => {
   const isAccountNumberStartWithT = await isAccountNumberStartsWithT()
   const dataPayload = composePayload(eventType, isAccountNumberStartWithT, payloadStructure)
   return [dataPayload]
}

const composePayload = (eventType, isAccountNumberStartWithT, payloadStructure) => {  
  const timestamp = moment.utc(new Date())
  
  const payload = applyToPayloadData(
    payloadStructure, 
    (record, userRelatedIds) => composePayloadForRecord(userRelatedIds, eventType, isAccountNumberStartWithT, timestamp, record))

  return payload;
}

const composePayloadForRecord = (userRelatedIds, eventType, isAccountNumberStartWithT, timestamp, fields) => {
  const anonPatientUuid = fields.uuid && sha3_512(fields.uuid)  
  delete fields.uuid
  const uuid = uuidv4()
  return {
    TimeStamp: timestamp,
    EventType: eventType,
    Meta: {
      Id: uuid,
      GroupID: null,
      DataCollectionPOSOrgID: userRelatedIds && userRelatedIds.organizationId,
      DataCollectionPOSOrgName: null,
      DataCollectionPOSID: userRelatedIds && userRelatedIds.practiceId,
      DataCollectionEmployeeID: userRelatedIds && userRelatedIds.employeeId,
      DataCollectionHIWID: anonPatientUuid || null,
      TestPOS: isAccountNumberStartWithT,
      TestAccountEmployee: isAccountNumberStartWithT,
      TestAccountHIW: isAccountNumberStartWithT,
      AnonHIIDLeftL: null,
      AnonHIIDRight: null
    },
    Fields: fields
  }
}