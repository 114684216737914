import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { FormattedDate } from 'react-intl'
import moment from 'moment'

const DateBadge = ({ date }) => {
  const badgeDate = moment(date)
  const days = moment(Date.now()).startOf('day').diff(moment(date).startOf('day'), 'days')
  const showDay =
    days == 0
      ? <FormattedMessage {...T(`${'chat.today'}`)} />
      : days == 1
        ? <FormattedMessage {...T(`${'chat.yesterday'}`)} />
        : <FormattedDate value={badgeDate} />

  return (
    <div className="text-center">
      <div className="chat__system chat__system--date">
        {showDay}
      </div>
    </div>
  )
}

DateBadge.propTypes = {
  date: PropTypes.string.isRequired
}

export default DateBadge
