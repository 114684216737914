export const mapProps = ({
  data: {
    refetch,
    loading,
    DefaultLessonSetForSignia,
    LessonsForSigniaPatient,
    LessonSetForSignia
  },
  ownProps: { params, navigate }
}) => {
  return {
    refetch,
    loading,
    DefaultLessonSetForSignia,
    navigate,
    params,
    LessonsForSigniaPatient,
    LessonSetForSignia
  }
}

export const options = ({ customer: { uuid }, Me: { employee } }) => {
  return {
    variables: { patientGuid: uuid, hcpId: employee && employee.id ? employee.id : 0 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }
}
