import React from 'react'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import { ErrorMessage } from 'formik'
import { PasswordFieldComponent } from 'styledComponents/Div/DivComponent/DivPasswordStrength'

class OldPasswordField extends React.Component {
  render() {
    const {
      field,
      className,
      label = this.props.intl.formatMessage(T('hcpEdit.oldPassword')),
      labelClassName,
      form: { touched, errors },
      required
    } = this.props
    const error = !!(errors.password && errors.password.oldPassword)
    const fieldTouched = !!(touched.password && touched.password.oldPassword)
    return (
      <PasswordFieldComponent className={`form-group ${error && fieldTouched ? 'has-error' : ''}`}>
        <label
          htmlFor={field.name}
          className={`control-label ${required ? 'required' : ''} ${labelClassName || ''}`}
        >
          {label}
        </label>
        <input
          type="password"
          className={`form-control ${className || ''} ${error && fieldTouched
            ? 'is-password-invalid'
            : ''}`}
          {...field}
        />
        <ErrorMessage
          name={field.name}
          render={msg =>
            <div className="help-block" data-qa="input-error">
              {msg}
            </div>}
        />
      </PasswordFieldComponent>
    )
  }
}

export default injectIntl(OldPasswordField)
