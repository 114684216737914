import { gql } from "@apollo/client";

export const EMPLOYEES_QUERY = gql`
    query EmployeeQuery( $practiceId: Int, $orgId: Int ) {
        Me {
            employee {
                id
                orgManager
                manager
            }
        }
        AssignedPractices(id: $orgId) {
            id
            name
        }
        Employees( practiceId: $practiceId ) {
            id
            firstName
            lastName
            email
            username
            phone
            manager
            orgManager
        }
    }
`

export const EMPLOYEES_LIST_QUERY = gql`
    query EmployeeListQuery( $practiceId: Int,  $sort: String, $page: Int, $search: String, $accountState: String, $size: Int, $orgId: Int ) {
        Me {
            employee {
                id
                orgManager
                manager
            }
        }
        AssignedPractices(id: $orgId) {
            id
            name
        }
        EmployeeList( 
            practiceId: $practiceId
            sort: $sort
            page: $page
            search: $search
            accountState:$accountState
            size: $size
        ) {
            content {
                id
                firstName
                lastName
                email
                username
                phone
                manager
                orgManager
                tokenValidUntil
            }
            pagination {
                pageNumber
                pageSize
                numberOfElements
                totalElements
                totalPages
                }
        }
    }
`