import React, { useContext } from 'react'
import DataContext from "containers/DataContext"
import ProfileTuning from "components/_Profile/Tuning"

function ProfileTunigWrapper() {
    const { customer } = useContext(DataContext);
    return (
        <ProfileTuning {...customer} />
    )
}

export default ProfileTunigWrapper