import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { withRouter } from "helper/withRouter";
import { injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import _ from 'lodash'

class CxxPatientProfile extends Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.PatientFromCxxId) {
      const patientId = nextProps.PatientFromCxxId.id
      const employeeId = nextProps.PatientFromCxxId.employeeId
      this.props.navigate(`/profile/${patientId}/settings/lessons?employeeId=${employeeId}`)
    } else {
      this.props.navigate(`/`)
    }
  }

  render() {
    return (<div></div>)
  }
}

const QUERY_CXX_PATIENT_DETAILS = gql`
  query getCxxPatientDetails($cxxId: String!) {
    PatientFromCxxId(cxxId: $cxxId) {
      id,
      employeeId
    }
  }
`
const mapProps = ({
  getCxxPatientDetails: { refetch, loading, PatientFromCxxId }
}) => {
  return {
    refetch,
    loading,
    PatientFromCxxId
  }
}

const options = ({ match: { params: { id } } }) => ({
  notifyOnNetworkStatusChange: true,
  variables: { cxxId: id },
  fetchPolicy: 'network-only'
})

const withData = compose(
  graphql(QUERY_CXX_PATIENT_DETAILS, {
    name: 'getCxxPatientDetails',
    props: mapProps,
    options
  }),
  injectIntl
)

export default withRouter(withData(CxxPatientProfile))