import React, { Component } from 'react'
import FourBandChannelGroup from './FourBandChannelGroup'
import GainStepper from './GainStepper'
import SimpleStepper from './SimpleStepper'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import _ from 'lodash'
import { customerTracking } from 'helper/customerTracking'

const DEFAULT_BAND_VALUES = {
  equalizer1: 0,
  equalizer2: 0,
  equalizer3: 0,
  equalizer4: 0,
  volume: 8
}

export default class FourBandEqualizer extends Component {
  //NOTE: Using the acoustical interface, we have seven steps per channel (Default, +3 steps, -3 steps).
  //NOTE: Every step means +/- 3dB. When it comes to the RF-devices, we will have 32 steps with +/- 1dB.
  //NOTE: When we reach a limit, the button will grey out.
  constructor(...args) {
    super(...args)

    this.state = {
      initialized: false,
      min: this.props.min || -12,
      max: this.props.max || 9,
      step: this.props.step || 3,

      clickedSharper: false,
      clickedSofter: false,
      clickedLoudnessSpeech: false,

      master: 0,
      hdPreset: {
        equalizer1: 0,
        equalizer2: 0,
        equalizer3: 0,
        equalizer4: 0,
        volume: 8
      }
    }
  }
  firstLoading = true
  UNSAFE_componentWillUpdate(nextProps, nextState) {    
    const { initialized } = nextState
    const { dirty, program = DEFAULT_BAND_VALUES } = nextProps
    const { equalizer1, equalizer2, equalizer3, equalizer4 } = program

    if (this.firstLoading || !initialized) {
      this.firstLoading = false      
      const master =
        [equalizer1 || 0, equalizer2 || 0, equalizer3 || 0, equalizer4 || 0].reduce(
          (prev, current) => prev + current,
          0
        ) / 4 || 0

      const state = {
        master,
        initialized: true,
        hdPreset: { ...program, ...this.getLatestSentValues() }
      }

      // programchange - reset
      if (!dirty) {
        state.clickedSharper = false
        state.clickedSofter = false
        state.clickedLoudnessSpeech = false
      }

      this.setState(state)
    }
  }

  hasChangesAfterSave() {
    return !_.isEqual(this.state.value, this.getLatestSentValues())
  }

  isPresetDefaultOne() {
    const program = this.getLatestSentValues()

    return _.isEqual(program, DEFAULT_BAND_VALUES)
  }

  getLatestSentValues() {
    const { program } = this.props
    const { equalizer1, equalizer2, equalizer3, equalizer4 } = program

    // fighting with "null" to render "0" value in EQlizer
    return {
      ...program,
      equalizer1: equalizer1 || 0,
      equalizer2: equalizer2 || 0,
      equalizer3: equalizer3 || 0,
      equalizer4: equalizer4 || 0,
      volume: 8
    }
  }

  onProgramResetSuccess() {
    this.setState({
      initialized: false,
      dirty: false
    })
  }

  increase(bands = [], step = this.state.step) {
    const newState = { ...this.state }
    const { max } = newState

    bands.forEach(equalizer => {
      if (typeof newState.hdPreset[equalizer] !== 'undefined') {
        newState.hdPreset[equalizer] = Math.min(newState.hdPreset[equalizer] + step, max)
      }
    })

    this.onChange(newState)
  }

  decrease(bands = [], step = this.state.step) {
    const newState = { ...this.state }
    const { min } = newState

    bands.forEach(equalizer => {
      if (typeof newState.hdPreset[equalizer] !== 'undefined') {
        newState.hdPreset[equalizer] = Math.max(newState.hdPreset[equalizer] - step, min)
      }
    })

    this.onChange(newState)
  }

  onChange(newState) {
    this.setState(
      {
        ...newState
      },
      () => this.props.setDirty(true)
    )
    customerTracking({
      action: 'equalizerBandChanged'
    })
  }

  onChangeMasterGain = (operation) => {
    if (typeof this[operation] == 'function') {
      this[operation](['equalizer1', 'equalizer2', 'equalizer3', 'equalizer4'])
      customerTracking({
        action: 'equalizerMasterGainAdjusted'
      })
    }
  }

  onChangeLoudnessSpeech = (operation) => {
    const { clickedLoudnessSpeech } = this.state
    if (operation == 'increase') {
      if (!clickedLoudnessSpeech) {
        this.setState(
          {
            clickedLoudnessSpeech: true
          },
          () => {
            this.increase(['equalizer3'])
          }
        )
      } else {
        this.increase(['equalizer2', 'equalizer3'])
      }
    } else {
      this.decrease(['equalizer2', 'equalizer3'])
    }
    customerTracking({
      action: 'equalizerSpeechVolumeAdjusted'
    })
  }

  onChangeLoudnessOwnVoice = (operation) => {
    if (typeof this[operation] == 'function') {
      this[operation](['equalizer1', 'equalizer2'])
      customerTracking({
        action: 'equalizerOwnVoiceVolumeAdjusted'
      })
    }
  }

  onClickSharper = () => {
    const { clickedSharper } = this.state
    if (!clickedSharper) {
      this.setState(
        {
          clickedSharper: true
        },
        () => {
          this.decrease(['equalizer1'])
          this.increase(['equalizer4'])
        }
      )
    } else {
      this.decrease(['equalizer1', 'equalizer2'])
      this.increase(['equalizer3', 'equalizer4'])
    }
    customerTracking({
      action: 'equalizerSoundQualityAdjusted'
    })
  }

  onClickSofter = () => {
    const { clickedSofter } = this.state

    if (!clickedSofter) {
      this.setState(
        {
          clickedSofter: true
        },
        () => {
          this.increase(['equalizer1'])
          this.decrease(['equalizer4'])
        }
      )
    } else {
      this.increase(['equalizer1', 'equalizer2'])
      this.decrease(['equalizer3', 'equalizer4'])
    }

    customerTracking({
      action: 'equalizerSoundQualityAdjusted'
    })
  }

  onClickFeedbackReduce = () => {
    this.decrease(['equalizer3'])
    customerTracking({
      action: 'equalizerFeedbackAdjusted'
    })
  }

  onChangeChannelGroup = ({ operation, channels, step }) => {
    if (typeof this[operation] == 'function') {
      this[operation](channels, step)
    }
  }

  reset() {
    this.setState(
      {
        clickedSharper: false,
        clickedSofter: false,
        clickedLoudnessSpeech: false,
        hdPreset: this.getLatestSentValues()
      },
      () => this.props.setDirty(false)
    )

    customerTracking({
      action: 'equalizerChangesDiscarded'
    })
  }

  render() {
    if(this.firstLoading) this.UNSAFE_componentWillUpdate(this.props, this.state)
    const { hdPreset = {}, master = 0, min, max, step } = this.state    
    const { equalizer1, equalizer2, equalizer3, equalizer4 } = hdPreset
    
    return (
      <div className="fourband-equalizer">
        <div className="row">
          <div className="col-lg-6">
            <FourBandChannelGroup
              onChange={this.onChangeChannelGroup}
              values={hdPreset}
              min={min}
              max={max}
              step={step}
            />
          </div>
          <div className="col-lg-6">
            <div className="row channel-stepper__group">
              <div className="col-lg-12">
                <h3
                  data-qa="basic-tuning"
                  className="channel-stepper__title channel-stepper__title--group-name"
                >
                  <FormattedMessage {...T('fourband.basicTuning')} />
                </h3>
              </div>
              <div className="col-lg-4">
                <h3 className="channel-stepper__title" data-qa="master-gain">
                  <FormattedMessage {...T('fourband.masterGain')} />
                </h3>
              </div>
              <div className="col-lg-8">
                <GainStepper
                  values={hdPreset}
                  max={max}
                  min={min}
                  master={master}
                  onChange={this.onChangeMasterGain}
                />
              </div>
            </div>

            <div className="row channel-stepper__group">
              <div className="col-lg-4">
                <h3 className="channel-stepper__title" data-qa="loudness_title">
                  <FormattedMessage {...T('fourband.loudness')} />
                </h3>
              </div>
              <div className="col-lg-8">
                <SimpleStepper
                  dataQa="changer--speech"
                  values={{ value1: equalizer2, value2: equalizer3 }}
                  max={max}
                  min={min}
                  label={<FormattedMessage {...T('fourband.speech')} />}
                  onChange={this.onChangeLoudnessSpeech}
                />
              </div>
            </div>

            <div className="row channel-stepper__group">
              <div className="col-lg-4" />
              <div className="col-lg-8">
                <SimpleStepper
                  dataQa="changer--voice"
                  label={<FormattedMessage {...T('fourband.ownVoice')} />}
                  onChange={this.onChangeLoudnessOwnVoice}
                  values={{ value1: equalizer1, value2: equalizer2 }}
                  max={max}
                  min={min}
                />
              </div>
            </div>

            <div className="row channel-stepper__group">
              <div className="col-lg-4">
                <h3 data-qa="channel-stepper__title" className="channel-stepper__title">
                  <FormattedMessage {...T('fourband.soundQuality')} />
                </h3>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="channel-stepper__handler channel-stepper__handler--button"
                      data-qa="softer-button"
                      onClick={this.onClickSofter}
                      disabled={
                        equalizer1 === max &&
                        equalizer2 == max &&
                        equalizer3 === min &&
                        equalizer4 === min
                      }
                    >
                      <FormattedMessage {...T('fourband.softer')} />
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="channel-stepper__handler channel-stepper__handler--button"
                      data-qa="sharper-button"
                      onClick={this.onClickSharper}
                      disabled={
                        equalizer1 === min &&
                        equalizer2 == min &&
                        equalizer3 === max &&
                        equalizer4 === max
                      }
                    >
                      <FormattedMessage {...T('fourband.sharper')} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <h3 data-qa="channel-stepper__title" className="channel-stepper__title">
                  <FormattedMessage {...T('fourband.feedback')} />
                </h3>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6" />
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="channel-stepper__handler channel-stepper__handler--button"
                      data-qa="reduce-button"
                      onClick={this.onClickFeedbackReduce}
                      disabled={equalizer3 === min}
                    >
                      <FormattedMessage {...T('fourband.reduce')} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
