import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

export const Loader = styled.div`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    animation: ${animation} 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`

export const CallControls = styled.div`
  display: inline-block;
  margin-left: 10px;
`

export const VideoCallWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  transition: opacity 0.5s;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.minimized {
    position: fixed;
    background: none;
    pointer-events: none;
  }
`

export const VideoContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  right: 20px;
  margin-left: auto;
  margin-right: auto;

  &.minimized {
    position: fixed;
    height: 200px;
    width: 260px;
    top: 100px;
    margin-right: 0;
  }
`

export const VideoButtons = styled.div`
  pointer-events: all;
  position: fixed;
  bottom: 20px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 72px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 10;
  &:hover {
    opacity: 1;
  }

  &.minimized {
    top: 230px;
    left: 0px;
    right: 0px;
    margin-right: 0px;
  }
`

export const BadgeWrapper = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0px;
  overflow: hidden;
  pointer-events: none;
`
export const Badge = styled.div`
  height: 50px;
  background: #3399FF;
  width: 200px;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  color: #fff;
  transform: rotate(-45deg);
  position: relative;
  top: 12px;
  left: -60px;
  box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.34);
`

export const BetaInfoBase = styled.div`
  margin-left: 5px;
  display: inline;
  color: #3399FF;
`

export const PopupBackground = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  transition: opacity 0.5s;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.5);
`
export const PopupBase = styled.div`
  position: absolute;
  background-color: white;
  top: 50vh;
  height: auto;
  width: 500px;
  left: 50vw;
  margin-left: -250px;
  border: 1px solid #d4d4d4;
  border-radius: 15px;
  padding: 25px 20px;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10000;
`

export const PopupHead = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  line-height: 2.5em;
  border-bottom: 1px solid #000000;
  margin-bottom: 1.3em;
`

export const PopupContent = styled.div`
  font-size: 1.2em;
  margin-top: -11px;
`

export const PopupActions = styled.div`
  button {
    margin: 5px;
  }
`

export const VideoInfoBase = styled.div`
  position: absolute;
  background-color: white;
  top: 84vh;
  height: 50px;
  width: 500px;
  left: 50vw;
  margin-left: -250px;
  border: 1px solid #d4d4d4;
  border-radius: 15px;
  padding: 25px 20px;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10000;

  &.minimized {
    position: fixed;
    left: 0px;
    right: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    width: 260px;
    top: 200px;
    margin-right: 0;
    z-index: 10;
    border-radius: 0px;
    border: 1px solid #000;
  }
`
