import React from "react";
import { Field } from "formik";
import { T } from "core";
import { FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import SelectField from "formfields/SelectField";
import AutoCompleteField from "formfields/AutoCompleteField";
import InputField from "formfields/InputField";
import RadioButtonGroupField from "formfields/EditUser/RadioButtonGroupField";
import TextAreaField from "formfields/TextAreaField";
import fflags from "helper/FFlags";
import { customMessageMaxLength } from 'helper/constants'

export default function OrganizationPage(props) {
  const isDisabled = props.isReviewPage || props.busy || props.isReadOnly;
  const isRegisterPage = props.isRegisterPage || false;
  const autoReplyOptions = [
    "ENABLED",
    "DISABLED",
    "ORG_MESSAGE_ONLY",
    "ORG_MESSAGE_DEFAULT",
  ];
  const autoReplyLabels = [
    props.formatMessage("orgManagement.autoReply.enabled"),
    props.formatMessage("orgManagement.autoReply.disabled"),
    props.formatMessage("orgManagement.autoReply.orgMessageOnly"),
    props.formatMessage("orgManagement.autoReply.orgMessageDefault"),
  ];

  const [remainingCharacter, setRemainingCharacter] = React.useState(0)
  React.useEffect(() => { setRemainingCharacter(props?.autoReplyMessage?.length) })

  const createDataQaEntry = (name, options, index) => {
    return name + "_" + options[index];
  };
  const getAutoReplyMessageClass = () =>
    !props.autoReplyConfig.autoReplyEnabled ? "" : "required";

  const handleBrandSelection = (item) => {
    props.setFieldValue("orgBrand", item.brandName);
  };

  const getBrandingField = () =>
    props.isNoBrandingChecked ? (
      <Field
        name="orgBrandDisabledTextbox"
        component={InputField}
        disabled={true}
      />
    ) : (
      <Field
        name="orgBrand"
        component={AutoCompleteField}
        items={props?.brands}
        disabled={isDisabled}
        placeholder="Signia"
        onItemSelect={handleBrandSelection}
        objectKey="brandName"
      />
    );
  const getBrandingHeadingMarkup = () =>
    isRegisterPage ? (
      <div className="row">
        <div className="col-12">
          <div style={{ fontWeight: "bold" }}>
            {props.formatMessage("common.brand")}
            <p
              className="help-block mt-3"
              data-qa="configure-password-validity-help"
            >
              <FormattedMessage {...T("common.brandMoreInfo")} />
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div className="row margin-with-border">
        <div className="col-12 mb-3">
          <h2 data-qa="configure-brand" className="d-inline">
            <FormattedMessage {...T("common.brand")} />
          </h2>
          <p
            className="help-block mt-3"
            data-qa="configure-password-validity-help"
          >
            <FormattedMessage {...T("common.brandMoreInfo")} />
          </p>
        </div>
      </div>
    );

  const getBrandingMarkup = () =>
    fflags.variation("enable-portal-branding", false) ? (
      <>
        {getBrandingHeadingMarkup()}
        <div className="row mt-1">
          <div className="col-md-6">{getBrandingField()}</div>
          <div className="col-md-6">
            <label>
              <Field
                name="isNoBrandingChecked"
                type="checkbox"
                className="checkbox"
                disabled={isDisabled}
              />
              {props.formatMessage("common.brandCheckboxLabel")}
            </label>
          </div>
        </div>
      </>
    ) : (
      <></>
    );


  return (
    <div>
      {isRegisterPage ? (
        <>
          <h2 data-qa="title">
            <FormattedMessage {...T("register.orgDetailsTitle")} />
          </h2>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgName"
                component={InputField}
                label={props.formatMessage("register.orgName")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgWebsite"
                component={InputField}
                label={props.formatMessage("common.url")}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                type="email"
                name="orgEmail"
                component={InputField}
                labelClassName="required"
                label={props.formatMessage("common.email")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgPhoneNumber"
                component={InputField}
                label={props.formatMessage("register.orgPhoneNumber")}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgAddrStreet"
                component={InputField}
                label={props.formatMessage("common.street")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgAddrZipCode"
                component={InputField}
                label={props.formatMessage("common.zip")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgAddrCity"
                component={InputField}
                label={props.formatMessage("common.city")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgAddrCountryCode"
                component={SelectField}
                label={props.formatMessage("common.country")}
                labelClassName="required"
                options={props.sortedCountryCodesWithCountryNames.map((x) => ({
                  value: x.countryCode,
                  label: x.countryName,
                }))}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgDefaultTimeZone"
                component={SelectField}
                label={props.formatMessage("common.timezone")}
                labelClassName="required"
                options={moment.tz.names().map((timezone) => ({
                  value: timezone,
                  label: timezone,
                }))}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgDefaultlang"
                component={SelectField}
                label={props.formatMessage("hcpEdit.locale")}
                labelClassName="required"
                options={Object.entries(props.language).map((x) => ({
                  value: x[0],
                  label: props.formatMessage("languageName." + x[0]),
                }))}
                disabled={isDisabled}
              />
            </div>
          </div>
          {getBrandingMarkup()}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgName"
                component={InputField}
                label={props.formatMessage("register.orgName")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="organizationId"
                component={InputField}
                label={props.formatMessage("organizationID")}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgWebsite"
                component={InputField}
                label={props.formatMessage("common.url")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                type="email"
                name="orgEmail"
                component={InputField}
                labelClassName="required"
                label={props.formatMessage("common.email")}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgPhoneNumber"
                component={InputField}
                label={props.formatMessage("register.orgPhoneNumber")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgAddrStreet"
                component={InputField}
                label={props.formatMessage("common.street")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgAddrZipCode"
                component={InputField}
                label={props.formatMessage("common.zip")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgAddrCity"
                component={InputField}
                label={props.formatMessage("common.city")}
                labelClassName="required"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgAddrCountryCode"
                component={SelectField}
                label={props.formatMessage("common.country")}
                labelClassName="required"
                options={props.sortedCountryCodesWithCountryNames.map((x) => ({
                  value: x.countryCode,
                  label: x.countryName,
                }))}
                disabled={isDisabled}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="orgDefaultTimeZone"
                component={SelectField}
                label={props.formatMessage("common.timezone")}
                labelClassName="required"
                options={moment.tz.names().map((timezone) => ({
                  value: timezone,
                  label: timezone,
                }))}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="orgDefaultlang"
                component={SelectField}
                label={props.formatMessage("hcpEdit.locale")}
                labelClassName="required"
                options={Object.entries(props.language).map((x) => ({
                  value: x[0],
                  label: props.formatMessage("languageName." + x[0]),
                }))}
                disabled={isDisabled}
              />
            </div>
          </div>
        </>
      )}
      {!isRegisterPage && (
        <>
          {getBrandingMarkup()}
          <div className="row margin-with-border">
            <div className="col-12">
              <h2 data-qa="configure-password-validity-title">
                <FormattedMessage
                  {...T("orgManagement.passwordValidity.header")}
                />
              </h2>
              <p
                className="help-block"
                data-qa="configure-password-validity-help"
              >
                <FormattedMessage
                  {...T("orgManagement.passwordValidity.info")}
                />
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                <Field
                  name="isPasswordExpirationPolicyEnabled"
                  type="checkbox"
                  className="checkbox"
                />
                <FormattedMessage
                  {...T("orgManagement.passwordValidity.checkbox")}
                />
              </label>
            </div>
            {props.showValidityPeriod && (
              <div className="col-md-6">
                <Field
                  name="daysToExpirePassword"
                  component={InputField}
                  label={props.formatMessage(
                    "orgManagement.passwordValidity.periodHeader"
                  )}
                  labelClassName="required"
                />
              </div>
            )}
          </div>
          <>
            <div className="row margin-with-border">
              <div className="col-12">
                <h2 data-qa="configure-password-validity-title">
                  <FormattedMessage {...T("orgManagement.autoReply.title")} />
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  name="autoReplyMode"
                  component={RadioButtonGroupField}
                  options={autoReplyOptions}
                  labelArray={autoReplyLabels}
                  disabled={isDisabled}
                  createDataQaEntry={createDataQaEntry}
                  radioLayoutClassName="vertical-form-inline"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="help-block" data-qa="auto-reply-config-help">
                  <FormattedMessage {...T(props.autoReplyConfig.helpText)} />
                </p>
              </div>
            </div>
            {props.autoReplyConfig.isMessageFieldVisible && (
              <div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name="autoReplyMessage"
                      id="autoReplyMessage"
                      maxLength={customMessageMaxLength}
                      handleChange={(e) => setRemainingCharacter(e.target.value.length)}
                      disabled={
                        isDisabled || !props.autoReplyConfig.autoReplyEnabled
                      }
                      component={TextAreaField}
                      label={props.formatMessage("hcp.chatAutoReplyMessage")}
                      labelClassName={getAutoReplyMessageClass()}
                      parentClassName="mb-0"
                    />
                    <div className="float-right mb-4">
                      {remainingCharacter}/{customMessageMaxLength}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
}
