import { gql } from "@apollo/client";

export const HEARING_DEVICES_QUERY = gql`
  query ($patientId: Int!) {
    HearingDevices(customerId: $patientId) {
      hdPrograms {        
        name
        programNumber
        type
      }
      leftDevice {
        id
        active
        bluetooth
        companyNo
        manufacturer
        model
        persistentConfigurationSupport
        platform
        remoteFittingSupport
        type
      }
      rightDevice {
        id
        active
        bluetooth
        companyNo
        manufacturer
        model
        persistentConfigurationSupport
        platform
        remoteFittingSupport
        type
      }
      persistentConfiguration
      teleCareVersion     
      capabilities {
        bluetooth
        appConnected
      }
    }
  }
`