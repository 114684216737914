import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import { T, tracker } from 'core'
import _ from 'lodash'
import { graphQLClient } from "../../graphql/client";
import Loading from 'components/Loading'
import { PatientPersonalInfo, PracticeInfo, PatientCreatedEditForm } from 'components/_Patient/components'
import { getProgramsPayload, areProgramsValid } from 'components/_Patient/shared'
import { validatePhoneNumber } from 'helper/ValidatePhoneNumber'
import { customerTracking } from 'helper/customerTracking'
import FinishTrialPopup from 'components/Popups/FinishTrialPopup'
import ConfirmDeletionPopup from 'components/Popups/ConfirmDeletionPopup'
import { checkPermission } from 'helper/AuthHelper'
import { Button } from 'styledComponents/Button'
import { ActionButtonsEdit } from 'styledComponents/Div/DivComponent/DivPatient'
import { UPDATE_OVERLAY, SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import {
  START_TRIAL_POPUP,
  SHOW_POPUP,
  DELETE_PATIENT_POPUP,
  getOverlayUpdateData
} from 'components/Popups/helper/OverlayUpdateData'
import { withQuery } from './../../helper/withQuery'
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'

import { sendDataToDataCollection } from "../../helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from 'helper/constants'
import { EventType } from '../../helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData } from '../../helper/DataCollection/PayloadData'
import { getPatientEditInfoFromCache } from 'helper/PatientSettingsUtils'
import { withRouter } from 'helper/withRouter'
import DataContext from '../../containers/DataContext';

export const FinishTrialButton = status => onClick => {
  return (
    <Button primary disabled={status !== 'OPEN'} className="btn--min-width" data-qa="finish-trial" onClick={onClick} type="button">
      <i className="fas fa-fw fa-flag-checkered fa-1x" data-qa="finish-trial-option" />
      <FormattedMessage {...T('customerDetail.finishTrial')} />
    </Button>
  )
}

export const CreateRemovePatientButton = disabled => onClick => {
  return (
    <Button primary disabled={disabled} className="btn--min-width" data-qa="delete-patient" onClick={onClick} type="button">
      <i className="fas fa-fw fa-trash" data-qa="delete-patient-option" />
      <FormattedMessage {...T('dropdown.delete-patient')} />
    </Button>
  )
}

export class PatientEditPage extends Component {

  static contextType = DataContext;

  state = {
    isPersonalFormValid: false,
    isPagePrefilled: false,
    showPersistentInfo: false,
    isDeviceSettingsFormValid: false,
    isBrandSettingsFormValid: false,
    isPracticeInfoFormValid: false, // defaults to the practice and employee
    isDevicePersistingSettingsFormValid: false,
    deviceModelsLeft: [],
    deviceModelsRight: [],
    selectedBrandLeft: null,
    selectedBrandRight: null,
    deviceModelRightId: null,
    deviceModelLeftId: null,
    manufacturers: [],
    teleCareVersion: null,
    practice: null,
    employee: null,
    hearingPrograms: [],
    HDModelsFeatures: null,
    isSaving: false,
    isPracticeChanged: false,
    employeesList: []
  }

  componentDidMount() {
    const { isPagePrefilled } = this.state
    if (!isPagePrefilled && !_.isEmpty(this.props.Customer)) {
      this.prefillEditPage(this.props)
    }

    customerTracking({
      ...this.props.location,
      action: 'customerProfileEditOpened'
    })
    if (!this.props.loading)
      sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(this.props.Customer), EventType.Read)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (
      this.props.loading !== nextProps.loading &&
      !nextProps.loading &&
      !nextState.isPagePrefilled &&
      !_.isEmpty(nextProps.Customer)
    ) {
      this.prefillEditPage(nextProps)
    }
  }

  async prefillEditPage(props) {
    const {
      manufacturers,
      Customer: {
        practiceId,
        employeeId,
        hearingDevices: { leftDevice = {}, rightDevice = {}, hdPrograms, teleCareVersion }
      },
      HDModelsFeatures
    } = props

    const hearingPrograms = hdPrograms?.reduce((acc, program) => (acc = [...acc, program.name]), [])

    const newState = {
      isPagePrefilled: true,
      practice: practiceId,
      employee: employeeId,
      deviceModelRightId: rightDevice ? rightDevice.id : '0',
      deviceModelLeftId: leftDevice ? leftDevice.id : '0',
      selectedBrandLeft: leftDevice ? leftDevice.manufacturer : '',
      selectedBrandRight: rightDevice ? rightDevice.manufacturer : '',
      manufacturers,
      HDModelsFeatures,
      hearingPrograms,
      teleCareVersion
    }

    this.setState(newState)
  }

  formPersonalInfo = {
    getValues: _.identity
  }

  formPracticeInfo = {
    getValues: _.identity
  }

  renderHeadLine() {
    const { error } = this.props
    return (
      <div className="row">
        {error &&
          <div className="alert alert-danger" role="alert">
            {error}
          </div>}
        <h1 className="col-12" data-qa="title">
          <FormattedMessage {...T(`${'customerEdit.title'}`)} />
        </h1>
        <p className="col-12" data-qa="required-text">
          <FormattedMessage
            {...T('common.required_text')}
            values={{ mark: <span className="text-primary">*</span> }}
          />
        </p>
      </div>
    )
  }

  setFormRef = ({ name, form }) => (this[name] = form)


  isPrefittingPatient = customer => {
    return customer.journeyStatus === 'PREFITTING'
  }

  canEditPatient = customer => {
    const { deviceModelRightId, deviceModelLeftId, employee, practice } = this.state

    const isPracticeInfoFormValid = practice !== 0 && employee !== 0;
    const isHearingFormProgramValid = areProgramsValid(
      getProgramsPayload(this.state),
      this.getRemoteFittingSupport(this.state)
    )

    return (
      (this.isPrefittingPatient(customer) && isPracticeInfoFormValid) ||
      (isHearingFormProgramValid &&
        isPracticeInfoFormValid &&
        (!!deviceModelRightId || !!deviceModelLeftId))
    )
  }

  getPersistentConfiguration() {
    const { teleCareVersion } = this.state

    if (_.isEmpty(teleCareVersion) || _.isNull(teleCareVersion)) {
      return teleCareVersion
    }

    const persistentSettings = {
      TELECARE_2_0: {
        persistentConfiguration: true,
        teleCareVersion: 'TELECARE_2_0'
      },
      TELECARE_1_0_PERSISTENT: {
        persistentConfiguration: true,
        teleCareVersion: 'TELECARE_1_0_PERSISTENT'
      },
      TELECARE_1_0_TEMPORARY: {
        persistentConfiguration: false,
        teleCareVersion: 'TELECARE_1_0_TEMPORARY'
      }
    }

    return persistentSettings[teleCareVersion]
  }

  getRemoteFittingSupport = state => {
    const { HDModelsFeatures = {} } = state
    return _.get(HDModelsFeatures, 'remoteFittingSupported', false)
  }

  editPatient = async () => {
    const { Customer } = this.props
    const valid = this.canEditPatient(Customer)
    if (valid) {
      const countryCode = _.get(this.props.me, 'countryCode', '')
      const practiceId = this.state.practice
      const employeeId = this.state.employee
      const { firstName, lastName } = this.formPersonalInfo.getValues()
      const persistentSettings = this.getPersistentConfiguration()
      const patientId = parseInt(Customer.id, 10)
      const leftDevice = _.isNil(Customer.hearingDevices.leftDevice)
        ? 0
        : parseInt(Customer.hearingDevices.leftDevice.id)
      const rightDevice = _.isNil(Customer.hearingDevices.rightDevice)
        ? 0
        : parseInt(Customer.hearingDevices.rightDevice.id)
      let phone = _.get(this.formPersonalInfo.getValues(), ['phone'], '')
      try {
        phone = await validatePhoneNumber({
          countryCode,
          phoneNumber: phone
        })
      } catch (e) {
        console.log('Invalid phone', e)
      }
      const editPatientPayload = {
        firstName: _.trim(firstName),
        lastName: _.trim(lastName),
        phone: _.trim(phone),
        leftDevice,
        rightDevice,
        ...persistentSettings,
        practiceId,
        employeeId,
        programs: getProgramsPayload(this.state)
      }
      this.setState(
        {
          isSaving: true
        },
        () => {
          this.props
            .updatePatient({ patientId, patient: editPatientPayload })
            .then(data => {
              const { location = {} } = this.props
              customerTracking({
                ...location,
                action: 'customerUpdated'
              })
              return data
            })
            .then(this.onPatientEdited)
            .catch(error => {
              tracker.track('ErrorUpdatingCustomer', {
                ...error
              })
            })
        }
      )
    }
  }

  onPatientEdited = () => {
    sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(this.props.Customer, this.state.isPracticeChanged), EventType.Update)
    this.setState({
      isSaving: false,
      isPracticeChanged: false
    })
    this.props.refetch()
  }

  onCancel = () => {
    customerTracking({
      ...this.props.location,
      action: 'customerProfileEditCanceled'
    })
    this.setState({
      isPracticeChanged: false
    })
    this.props.refetch()
  }

  onSelectPracticeInfo = async ({ info, id }) => {
    if (info === 'practice') {
      await this.props.EmployeesQuery.refetch({
        practiceId: id
      })
    }

    this.setState({
      isPracticeChanged: true,
      [info]: id,
      ...(info === 'practice' ? { employee: _.get(this.props.me, ['employee', 'id']) } : {}) // selecting practice should reset employee selection
    })
  }

  // provide a list of manufacturers
  getListOfManufacturers = defaults => {
    const { intl: { formatMessage } } = this.props
    const { manufacturersLeft = defaults, manufacturersRight = defaults } = this.state

    return {
      manufacturersLeft: [
        { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
        ...manufacturersLeft
      ],
      manufacturersRight: [
        { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
        ...manufacturersRight
      ]
    }
  }

  // provide a list of deviceModels
  getListOfDeviceModels = (defaults = []) => {
    const { deviceModelsLeft = defaults, deviceModelsRight = defaults } = this.state

    return {
      deviceModelsLeft,
      deviceModelsRight
    }
  }

  onHearingProgramChange = hearingPrograms => {
    this.setState({ hearingPrograms })
  }

  handleFormPersonalInfoValidChange = isFormValid => {
    this.setState({ isPersonalFormValid: isFormValid })
  }

  addDefaultEmployeeToEmployeeList(defaultEmployeeId, employees) {
    graphQLClient
      .query({
        query: QUERY_EMPLOYEE,
        variables: {
          id: defaultEmployeeId
        }
      })
      .then((result) => {
        if (result?.data?.Employee)
          this.setState({
            employeesList: [result.data.Employee, ...employees]
          })
      });
  }

  componentWillUpdate() {
    if (this.state.employeesList.length > 0)
      return;

    const { loading, me, employees } = this.props;
    if (loading)
      return;

    const { employee } = this.state;
    const defaultEmployeeId = employee || _.get(me, ['employee', 'id']);

    if (employees.filter(x => x.id == defaultEmployeeId)?.length > 0) {
      this.setState({
        employeesList: [...employees]
      })
    }
    else {
      this.addDefaultEmployeeToEmployeeList(defaultEmployeeId, employees);
    }
  }

  isPersonalInfoEdited = props => {
    if (!_.isNil(this.formPersonalInfo) && !_.isNil(this.formPersonalInfo.getValues())) {
      const { firstName, lastName, phone } = this.formPersonalInfo.getValues()
      if (_.isNil(firstName)) {
        return false
      }
      return !(
        props.firstName === firstName &&
        props.lastName === lastName &&
        props.phone === phone
      )
    }
    return false
  }
  popCloseHandler = () => {
    this.props.updateOverlay(null, null, true)
  }
  removePatient = () => {
    this.props
      .deletePatient({ patientId: this.props.Customer.id })
      .then(data => {
        const { location } = this.props
        customerTracking({
          ...location,
          activeCustomer: this.props.Customer,
          action: 'customerDeleted'
        })
        sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(this.props.Customer), EventType.Delete)
        return data
      })
      .then(() => {
        this.props.navigate('/')
      })
      .catch(e => {
        console.log('Error', e)
      })
  }
  popupCloseHandler = () => {
    this.props.updateOverlay(null, null, true)
  }
  finishTrialButtonHandler = () => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: START_TRIAL_POPUP,
        payload: {
          title: 'popup_finish_trial.title',
          message: 'popup_finish_trial.message'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }
  createRemovePatientButtonHandler = () => {
    const { overlay } = this.props
    if (overlay && !overlay.popup) {
      const popup = getOverlayUpdateData(SHOW_POPUP, {
        type: DELETE_PATIENT_POPUP,
        payload: {
          title: 'dropdown.delete-patient',
          message: 'customerDetail.confirmDelete'
        }
      })
      this.props.updateOverlay({ ...popup }, true, true)
    }
  }
  render() {
    const {
      loading,
      practices,
      me,
      intl: { formatMessage },
      Customer: { firstName = '', lastName = '', phone = '', journeyStatus = '', uuid = '' }
    } = this.props
    const { Customer } = this.props
    const hasRemovePatientPermissions = checkPermission('DELETE_PATIENT', {
      Me: me,
      patient: Customer
    })
    const isPersonalIfoEdited = this.isPersonalInfoEdited(this.props.Customer)
    if (loading || !this.state.isPagePrefilled || this.state.isSaving) {
      return <Loading isLoading={loading || !this.state.isPagePrefilled || this.state.isSaving} />
    }
    const countryCode = _.get(me, 'countryCode', '')
    const { employee, practice, isPracticeChanged, isPersonalFormValid, employeesList } = this.state

    const isEditing = isPracticeChanged || isPersonalIfoEdited
    const canEditPatient = this.canEditPatient(Customer)
    return (
      <section>
        <PatientCreatedEditForm onSubmit={this.editPatient}
          employee={employee || _.get(me, ['employee', 'id'])}
          practice={practice}>
          {this.renderHeadLine()}
          <PatientPersonalInfo
            countryCode={countryCode}
            formatMessage={formatMessage}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            onValidationChange={this.handleFormPersonalInfoValidChange}
            setFormRef={this.setFormRef}
          />
          <br />
          <PracticeInfo
            formatMessage={formatMessage}
            setFormRef={this.setFormRef}
            practices={practices}
            employees={employeesList}
            onSelect={this.onSelectPracticeInfo}
          />

          <div className="row">
            <div className="col-lg-6">
              <ActionButtonsEdit className="left">
                <FinishTrialPopup
                  customer={this.props.Customer}
                  openByClickOn={FinishTrialButton(journeyStatus)}
                  onOpen={this.finishTrialButtonHandler}
                  onClose={this.popupCloseHandler}
                />
                <ConfirmDeletionPopup
                  openByClickOn={CreateRemovePatientButton(!hasRemovePatientPermissions)}
                  removePatient={this.removePatient}
                  onOpen={this.createRemovePatientButtonHandler}
                  onClose={this.popupCloseHandler}
                />
              </ActionButtonsEdit>
            </div>
            <div className="col-lg-6">
              <ActionButtonsEdit className="right">
                <Button
                  secondary
                  onClick={this.onCancel}
                  disabled={!isEditing}
                  className="btn--min-width"
                  data-qa="cancel-button"
                >
                  {formatMessage(T('common.cancel'))}
                </Button>
                <Button
                  type="submit"
                  primary
                  disabled={!(isEditing && canEditPatient && isPersonalFormValid)}
                  className="btn--min-width"
                  data-qa="save-button"
                >
                  {formatMessage(T('common.save'))}
                </Button>
              </ActionButtonsEdit>
            </div>
          </div>
        </PatientCreatedEditForm>
      </section>
    )
  }
}

const QUERY = gql`
  query PatientQuery($patientId: Int!, $orgId: Int) {
    Me {
      roles
      state
      countryCode
      employee {
        id
        manager
        orgManager
        firstName
      }
    }
    AssignedPractices(id: $orgId) {
      id
      name
    }
    Brands(id: $orgId) {
      id
      name
      pairable
      haBrandId
    }
    Customer(id: $patientId) {
      uuid
      id
      upid
      firstName
      lastName
      phone
      practiceId
      employeeId
      journeyStatus
      journeyStartedOn
      hearingDevices {
        leftDevice {
          id
          model
          manufacturer
        }
        rightDevice {
          id
          model
          manufacturer
        }
        hdPrograms {
          name
          type
          programNumber
        }
        persistentConfiguration
        teleCareVersion
      }
    }
  }
`

const QUERY_EMPLOYEES = gql`
  query EmployeesQuery($practiceId: Int) {
    Employees(practiceId: $practiceId) {
      id
      firstName
      lastName
    }
  }
`
const QUERY_EMPLOYEE = gql`
  query EmployeeQuery($id: Int!) {
     Employee(id: $id) {
      id
      firstName
      lastName
    }
  }
`
const MUTATION_UPDATE_PATIENT = gql`
  mutation UpdatePatientMutation($patientId: Int!, $patient: PatientInput!) {
    updatePatient(patientId: $patientId, patient: $patient) {
      id
      firstName
      lastName
      phone
      email
      practiceId
      employeeId
      hearingDevices {
        leftDevice {
          id
        }
        rightDevice {
          id
        }
        hdPrograms {
          name
          type
          programNumber
        }
        persistentConfiguration
        teleCareVersion
      }
    }
  }
`

const MUTATION_DELETE_PATIENT = gql`
  mutation DeleteatientMutation($patientId: Int!) {
    deletePatient(patientId: $patientId)
  }
`

export const mapQueryToVariables = ({
  params: { id = 0 },
  brand = '',

}) => {
  const patientId = parseInt(id)
  const { practiceId, employeeId, leftModelId: leftId = '0', rightModelId: rightId = '0' } = getPatientEditInfoFromCache(patientId);
  return {
    brand,
    patientId,
    hasBrand: !!brand,
    leftId,
    rightId,
    employeeId: parseInt(employeeId, 10),
    practiceId: parseInt(practiceId, 10),
    hasSelectedModels: !!rightId || !!leftId,
    orgId: parseInt(getOrganizationIdFromCache())
  }
}

const options = ({ params, brand, location }) => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only', // we need because in start trial we edit the data, and should be taken from server not cache
  variables: mapQueryToVariables({ params, brand, location })
})

const mapProps = ({
  PatientQuery: { loading, refetch, Me, Brands, AssignedPractices = [], Customer = {} },
  PatientQuery,
  ownProps: { location, intl }
}) => {
  return {
    me: Me,
    PatientQuery,
    refetch,
    loading,
    Customer,
    manufacturers: Brands,
    practices: AssignedPractices,
    location,
    intl
  }
}

const mapPropsEmployees = ({
  EmployeesQuery: { loading, refetch, Employees = [] },
  EmployeesQuery,
  ownProps: { location, intl }
}) => {
  return {
    EmployeesQuery,
    refetch,
    loading,
    employees: Employees,
    location,
    intl
  }
}
const mapPopupProps = ({ data: { overlay } }) => {
  return { overlay }
}
const withData = compose(
  graphql(QUERY_EMPLOYEES, {
    name: 'EmployeesQuery',
    props: mapPropsEmployees,
    options
  }),
  graphql(QUERY, {
    name: 'PatientQuery',
    props: mapProps,
    options
  }),
  graphql(SHOW_POPUP_QUERY, {
    props: mapPopupProps
  }),
  graphql(UPDATE_OVERLAY, {
    props: ({ mutate }) => ({
      updateOverlay: (popup, modal, isOpen) => mutate({ variables: { popup, modal, isOpen } })
    })
  }),
  graphql(MUTATION_UPDATE_PATIENT, {
    props: ({ mutate }) => ({
      updatePatient: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_DELETE_PATIENT, {
    props: ({ mutate }) => ({
      deletePatient: variables =>
        mutate({
          variables
        })
    })
  }),
  injectIntl
)

export default withRouter(withQuery(withData(PatientEditPage)))
