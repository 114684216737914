import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import LanguageDropdown from 'components/Dropdowns/LanguageDropdown'
import { T } from 'core'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import {
  HeaderContainer,
  StyledContainer,
  FlexRow
} from 'styledComponents/Div/DivContainer/DivMaintenancePage'
import { getLocales } from 'helper/constants'
import MaintenanceFooter from 'components/MaintenanceFooter'
import { LOCALE_QUERY } from '../graphql/queries/LocaleQuery'
import { LOCALE_MUTATION } from '../graphql/mutations/LocaleMutation'

export class MaintenancePage extends Component {
  render() {
    const { locale, setLocale } = this.props

    return (
      <div>
        <section>
          <StyledContainer className="container-fluid containerFont background-size">
            <div className="row">
              <HeaderContainer className="headerrow">
                <div className="row">
                  <div className="col-lg offset-lg-1">
                    <LanguageDropdown
                      modifier="language"
                      active={locale}
                      setLocale={setLocale}
                      locales={getLocales()}
                      classname="toggle"
                    />
                  </div>
                </div>
                <FlexRow className="row">
                  <div className="col-md-6 offset-md-3">
                    <FormattedMessage {...T('maintenance.text')} />
                  </div>
                </FlexRow>
              </HeaderContainer>
            </div>
          </StyledContainer>
          <MaintenanceFooter />
        </section>
      </div>
    )
  }
}
export default compose(
  graphql(LOCALE_MUTATION, {
    props: ({ mutate }) => ({
      setLocale: locale => mutate({ variables: { locale } })
    })
  }),
  graphql(LOCALE_QUERY, {
    props: ({ data }) => ({
      locale: data.locale.locale
    })
  })
)(injectIntl(MaintenancePage))
