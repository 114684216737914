
export const getAppVersion = () => {
  const version = process.env.REACT_APP_BUILD_VERSION || ""
  const tfsNumber = process.env.REACT_APP_TFS_BUILDNUMBER || '';
  return version + (tfsNumber === '' ? '' : `.${tfsNumber}`)
}

export const getEnviroment = () => {
  const enviroment = process.env.NODE_ENV || 'development'
  return enviroment;
}
export const getToken = () => {
  const item = localStorage.getItem(`LIGHTCLOUD${getAppVersion()}`)

  if (!item) return false
  let data = {}
  try {
    data = JSON.parse(item)
    const { access_token } = data
    return access_token
  } catch (e) {
    console.log('error deserializing token item', item, e)
  }
  return false
}

export const getTenantId = () => {
  const item = localStorage.getItem(`LIGHTCLOUD${getAppVersion()}`)

  let data = {}
  try {
    data = JSON.parse(item)
    const { tenantId } = data
    return tenantId
  } catch (e) {
    console.log('error deserializing tenant item', item)
  }
  return false
}


