
import { gql } from "@apollo/client";

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation changePasswordMutation($employeeId: Int!, $passwords :PasswordInput!) {
    changePassword(employeeId: $employeeId, passwords: $passwords ) {
        success
        message 
        code
        status
    }
  }
`