import React from 'react'
import { injectIntl } from 'react-intl'
import { T } from 'core'

import headsetIcon from 'assets/img/headset.png'
import { Popup } from './Popup'
import { PopupIcon } from 'styledComponents/Img'
import { ActionButton } from 'styledComponents/Span'

const DefaultErrorPopup = ({ error, onPopupClose, intl: { formatMessage } }) => {
  const message = error.type
    ? formatMessage(T(`videocall.error.message.${error.type}`))
    : error.message

  return (
    <Popup
      message={message}
      title={
        <span>
          <PopupIcon src={headsetIcon} />
          {error.type ? formatMessage(T(`videocall.error.title.${error.type}`)) : error.name}{' '}
        </span>
      }
    >
      <ActionButton onClick={onPopupClose} className={`red hangup fas fa-phone`} />
    </Popup>
  )
}

export default injectIntl(DefaultErrorPopup)
