import { gql } from "@apollo/client";

export const QUERY_LESSONSET_FOR_SIGNIA = gql`
  query LessonSetForSigniaQuery($hcpId: Int) {
    LessonSetForSignia(hcpId: $hcpId) {
      id
      lessonSetName
      lessonIds
      comment
      isDefault
      createdDate
    }
  }
`
export const QUERY_DEFAULT_LESSONSET_FOR_SIGNIA = gql`
  query DefaultLessonSetForSigniaQuery{
    DefaultLessonSetForSignia {
      lessonId
      heading
      description
      howTo
      title
      lessonCategory {
        categoryName
        description
        orderId
      }
    }
  }
`
export const QUERY_ALL_LESSONSET_FOR_SIGNIA = gql`
  query LessonSetForSigniaQuery ($hcpId: Int) {
    LessonSetForSignia(hcpId: $hcpId) {
      id
      lessonSetName
      lessonIds
      comment
      isDefault
      createdDate
    }
    DefaultLessonSetForSignia {
      lessonId
      heading
      description
      howTo
      title
      lessonCategory {
        categoryName
        description
        orderId
      }
    }
  }
`