import React from 'react'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import messages from 'i18n/messages.json'
import zxcvbn from 'zxcvbn'
import { ErrorMessage, getIn } from 'formik'
import { passwordStrongScore, passwordMinLength } from 'helper/constants'
import { PasswordFieldComponent } from 'styledComponents/Div/DivComponent/DivPasswordStrength'

class PasswordField extends React.Component {
  static defaultProps = {
    className: '',
    minLength: passwordMinLength,
    minScore: passwordStrongScore,
    namespaceClassName: 'ReactPasswordStrength'
  }

  render() {
    const {
      field,
      className,
      label = this.props.intl.formatMessage(T('login.password')),
      labelClassName,
      form: { touched, errors },
      intl: { formatMessage },
      namespaceClassName,
      required,
      disabled = false
    } = this.props
    const password = field.value
    const score = zxcvbn(password).score

    const scoreWords = [
      formatMessage(messages.validatorPasswordStrength1),
      formatMessage(messages.validatorPasswordStrength2),
      formatMessage(messages.validatorPasswordStrength3),
      formatMessage(messages.validatorPasswordStrength4),
      formatMessage(messages.validatorPasswordStrength5)
    ]

    const error = getIn(errors, field.name)
    const fieldTouched = getIn(touched, field.name)

    const wrapperClasses = `${namespaceClassName} ${password.length > 0
      ? `is-strength-${score}`
      : ''} ${className || ''} ${error && fieldTouched ? 'has-error' : ''}`
    const inputClasses = [`${namespaceClassName}-input`, 'form-control']
    const strengthDesc = scoreWords[score]

    return (
      <PasswordFieldComponent className={`form-group ${error && fieldTouched ? 'has-error' : ''}`}>
        <label
          htmlFor={field.name}
          className={`control-label ${required ? 'required' : ''} ${labelClassName || ''}`}
        >
          {label}
        </label>
        <div className={wrapperClasses}>
          <input
            type="password"
            className={`${inputClasses.join(' ')} ${error && fieldTouched
              ? 'is-password-invalid'
              : ''}`}
            {...field}
            autoComplete={this.props.autoComplete}
            disabled={disabled}
          />
          <div className={`${namespaceClassName}-strength-bar`} />
          <span className={`${namespaceClassName}-strength-desc`}>
            {strengthDesc}
          </span>
        </div>
        <ErrorMessage
          name={field.name}
          render={msg =>
            <div className="help-block" data-qa="input-error">
              {msg}
            </div>}
        />
      </PasswordFieldComponent>
    )
  }
}

export default injectIntl(PasswordField)
