import React, { FC, ReactElement } from "react";
import * as Panel from "components/_Profile/components/Panel";
import { withRouter } from "helper/withRouter";
import { PracticeManagementPage } from "styledComponents/Div/DivComponent/DivPracticeManagement";
import Subnavigation from "../../../components/_Dashboard/Subnavigation";
import UsersInPractice from "./UsersInPractice";
import PatientsInPractice from "./PatientsInPractice";
import { FormattedMessage } from "react-intl";
import { T } from "core";

export const PracticeManagement: FC<any> = (props): ReactElement => {
  const getheadline = () => {
    return <FormattedMessage {...T("deletePracticeErrorDetails.Title")} />;
  };

  const {
    match: {
      params: { id: practiceId },
    },
    location,
  } = props;
  const items = [
    {
      name: "orgManagement.users",
      href: `/deletepractice/usersinpractice/${practiceId}/delete`,
    },
    {
      name: "navbar.view_customer_list",
      href: `/deletepractice/patientsinpractice/${practiceId}/delete`,
    },
  ];
  return (
    <Panel.Base className="HcpManagement" modifier="practice-canotbe-deleted">
      <div className="container">
        <h2>{getheadline()}</h2>
        <br />
        <Panel.Base modifier="practice-canotbe-deleted-filters">
          <div className="toggler-wrapper">
            <Panel.Item>
              <Subnavigation items={items} location={location} />
            </Panel.Item>
            <PracticeManagementPage>
              {location.pathname ===
                `/deletepractice/usersinpractice/${practiceId}/delete` ? (
                <UsersInPractice />
              ) : (
                <PatientsInPractice {...props} />
              )}
            </PracticeManagementPage>
          </div>
        </Panel.Base>
      </div>
    </Panel.Base>
  );
};

export default withRouter(PracticeManagement);
