import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { withRouter } from "helper/withRouter";
import Markdown from 'react-markdown'
import Loading from 'components/Loading'
import { closeCustomer } from 'api'

import { T } from 'core'
import { compose } from 'lodash/fp'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { START_TRIAL_POPUP } from 'components/Popups/helper/OverlayUpdateData'
import Modal from 'components/Popups'
import { Button } from 'styledComponents/Button'
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon"
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { sendDataToDataCollection } from "../../helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from 'helper/constants'
import { EventType } from '../../helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData, applyToPayloadData } from '../../helper/DataCollection/PayloadData'


export const Content = ({
  popup,
  intl: { formatMessage },
  sold,
  canceled,
  closePortal,
  error,
  loading
}) => {
  const payload = popup ? popup.payload : null
  return (
    payload &&
    <div data-qa="finish-trial" className="popup--finish-trial">
      <Loading isLoading={loading} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="icon">
              <StyledPopupFontAwesomeIcon icon={faFlagCheckered} type="popupIcon" fixedWidth size="2x" className="popup-icon" />
            </div>
            <h1 className="popup__title" data-qa="finish-trial-title">
              <FormattedMessage {...T(payload.title)} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div data-qa="finish-trial-message">
              <Markdown children={formatMessage(T(payload.message))} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {error &&
              <div className="alert alert-danger">
                <span className="glyphicon glyphicon-exclamation-sign" /> {error}
              </div>}
            <div className="row">
              <div className="col-4">
                <Button
                  data-qa="finish-trial-cancel-button"
                  className="btn-block"
                  onClick={closePortal}
                  type="button"
                  tertiary
                >
                  <FormattedMessage {...T('common.cancel')} />
                </Button>
              </div>
              <div className="col-4">
                <Button
                  data-qa="finish-trial-no-button"
                  className="btn-block"
                  onClick={canceled}
                  type="button"
                  secondary
                >
                  <FormattedMessage {...T('common.no')} />
                </Button>
              </div>
              <div className="col-4">
                <Button
                  data-qa="finish-trial-yes-button"
                  className="btn-block"
                  onClick={sold}
                  type="button"
                  primary
                >
                  <FormattedMessage {...T('common.yes')} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export class FinishTrialPopup extends Component {
  state = {
    loading: false,
    error: false
  }
  canceled = () => {
    this.handleClosing('CANCELED')
  }

  sold = () => {
    this.handleClosing('SOLD')
  }

  handleClosing = async state => {
    const { customer } = this.props
    this.setState({ loading: true })

    try {
      await closeCustomer(customer.id, state)
      this.setState({ loading: false })
      const payload = PatientPayloadData(this.props.customer)
      applyToPayloadData(payload, (p, _) => {
        p["Sale"] = state
        p["PatientCreatedDate"] = customer.journeyStartedOn
      })

      sendDataToDataCollection(DataCollectionType.Crud, payload, EventType.FinishTrial)
      this.props.navigate('/overview/patients')
    } catch (e) {
      this.setState({
        loading: false,
        error: 'Error on customer Update'
      })
    }
  }

  render() {
    const { openByClickOn, onClose, popup, isOpen, onOpen, ...restProps } = this.props
    const { error, loading } = this.state
    const isOpened = popup && popup.type === START_TRIAL_POPUP && isOpen
    return (
      <Modal openByClickOn={openByClickOn} onOpen={onOpen} isOpened={isOpened} onClose={onClose}>
        <Content
          {...restProps}
          loading={loading}
          error={error}
          popup={popup}
          sold={this.sold}
          canceled={this.canceled}
        />
      </Modal>
    )
  }
}

FinishTrialPopup.propTypes = {
  intl: PropTypes.object,
  message: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  closePortal: PropTypes.func,
  sold: PropTypes.func,
  canceled: PropTypes.func
}

const mapProps = ({ data: { overlay } }) => {
  return { popup: overlay.popup, isOpen: overlay.isOpen }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  })
)
export default withData(injectIntl(withRouter(FinishTrialPopup)))
