import { localeToCountry } from 'helper/constants'
import _ from 'lodash'

const getBrowserLocale = () => {
  if (window.localStorage && window.localStorage.locale) {
    const { locale } = JSON.parse(window.localStorage.locale)
    if (locale) return locale
  }
  let browserLocale =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
  browserLocale = browserLocale.indexOf('zh') > -1 ? 'zh-Hans' : browserLocale // added for simple chinese locale (zh-CH)
  const locale =
    _.findKey(localeToCountry, (value, key) => key.indexOf(browserLocale) > -1) || 'en-US'

  const { localStorage } = window
  localStorage.setItem('locale', JSON.stringify({ locale: locale }))
  return locale
}

export default {
  authentication: {
    __typename: 'authentication',
    auth: ''
  },
  defaultPractice: {
    __typename: 'defaultPractice',
    id: '',
    name: '',
    timeZone: null
  },
  locale: {
    __typename: 'locale',
    locale: getBrowserLocale()
  },
  countries: {
    __typename: 'countries',
    data: []
  },
  overlay: {
    __typename: 'overlay',
    popup: null,
    modal: null,
    isOpen: false
  },
  HearingDevices: {
    __typename: 'hearingDevices',
    uuid: 0,
    hdPrograms: {
      __typename: 'hdPrograms',
      programNumber: 0,
      type: ''
    },
    leftDevice: {
      __typename: 'leftDevice',
      id: '',
      model: '',
      manufacturer: '',
      persistentConfigurationSupport: false,
      remoteFittingSupport: false
    },
    rightDevice: {
      __typename: 'rightDevice',
      id: '',
      model: '',
      manufacturer: '',
      persistentConfigurationSupport: false,
      remoteFittingSupport: false
    },
    persistentConfiguration: false,
    teleCareVersion: '1.0.0'
  },
  location: {
    __typename: 'location',
    pathname: '',
    search: '',
    hash: null,
    action: '',
    key: null,
    isQueryActive: false,
    query: null
  },
  UserDataConsent: {
    __typename: 'userDataConsent',
    anonOrganizationId: null,
    anonPracticeId: null,
    anonEmployeeId: null
  },
  PatientData: {
    __typename: "PatientData",
    id: null,
    practiceId: null,
    employeeId: null,
    leftModelId: null,
    rightModelId: null
  }
}
