import React from "react";
export interface MeType {
    employee: any,
    organizationId: number
}
export interface DataContextType {
    me: MeType
}
const DataContext = React.createContext<DataContextType>(undefined);
export default DataContext;
