import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { T } from 'core'
import { Wrapper } from 'styledComponents/Div/DivComponent/DivHelpCenter'
import DataContext from 'containers/DataContext'
import styled, { css } from "styled-components";
import androidIcon from "../../../assets/img/android.svg";
import appleIcon from "../../../assets/img/apple.svg";

const AndroidIcon = styled.div`
background-image: url(${androidIcon});
background-position: center;
background-size: contain;
background-repeat: no-repeat;
height: 36px;
`;

const AppleIcon = styled.div`
background-image: url(${appleIcon});
background-position: center;
background-size: contain;
background-repeat: no-repeat;
height: 36px;
`;

export class SupportedDevices extends Component {
  static contextType = DataContext;

  render() {
    return (
      <div className="container">
        <Wrapper>
          <div className="helpCenter-supported-devices">
            <div className="helpCenter-supported-devices col-8">
              <FormattedMessage {...T('helpCenter.supportedDevicesInfo')} />
            </div>
            <div className="helpCenter-supported-devices helpCenter-operating-systems">
              <FormattedMessage {...T('helpCenter.supportedOperatingSystems')} />
            </div>
            <div className="row py-2">
              <div className="col-1"><AppleIcon /></div>
              <div className="col-7 pl-0 align-self-center"><FormattedMessage {...T('helpCenter.appleiOS')} /></div>
            </div>
            <div className="row py-2">
              <div className="col-1"><AndroidIcon /></div>
              <div className="col-7 pl-0 align-self-center"><FormattedMessage {...T('helpCenter.android')} /></div>
            </div>
            <div className="helpCenter-supported-devices col-8">
              <FormattedMessage {...T('helpCenter.supportedDevicesNote')} />
            </div>
            <div className="helpCenter-supported-devices col-8">
              <FormattedMessage {...T('helpCenter.noTablets')} />
            </div>
          </div>
        </Wrapper>
      </div>
    )
  }
}

export default injectIntl(SupportedDevices)
