import React from 'react'
import { T } from 'core'
import Loading from 'components/Loading'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Counter } from 'styledComponents/Div/DivComponent/DivDropdowns'

const CountRecipients = ({
  recipients,
  totalRecipients,
  loading: isLoading
}) => {
  return (
    <Counter>
      <Loading isLoading={isLoading} />
      <span>
        {recipients} / {totalRecipients}
      </span>
      <p>
        <FormattedMessage {...T('marketing.messages.recipients-selected')} />
      </p>
    </Counter>
  )
}

export default injectIntl(CountRecipients)
