import React from "react";
import { TooltipRenderProps } from 'react-joyride';
import { FormattedMessage, useIntl } from "react-intl";
import styled from 'styled-components';
import { T } from "core";
import { Button } from '../styledComponents/Button';


const Wrapper = styled.div`
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
`;

const TooltipCloseBtn = styled.button`
  background-color: transparent;
  margin-right: ${(props: { spacer?: boolean }) => (props.spacer ? 'auto' : 0)};
  padding-right: 11px;
  padding-top: 8px;
  border: 0;

  position: absolute;
  right: 0px;
  top: 0px;
`;

const TooltipBody = styled.div`
  min-width: 290px;
  max-width: 420px;
  position: relative;
  margin-left: 18px;
`;

const TooltipContent = styled.div`
  margin-top: 16px;
  margin-right: 6px;
`;

const TooltipTitle = styled.h2`
  padding-top: 17px;
  margin: 0;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16.5px;
  margin-bottom: 8.5px;
  margin-right: 6px;

  * + * {
    margin-left: 0.5rem;
  }
`;

const LeftFooterCol = styled.div`
  margin-right: auto;
  align-self: center;
`;

export const Tooltip = ({
  index,
  isLastStep,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  size,

}: TooltipRenderProps) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <TooltipBody {...tooltipProps}>
        <TooltipCloseBtn {...closeProps}>
          <svg width="12px" height="12px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><g><path d="M8.13911129,9.00268191 L0.171521827,17.0258467 C-0.0498027049,17.248715 -0.0498027049,17.6098394 0.171521827,17.8327545 C0.28204354,17.9443526 0.427188206,17.9998706 0.572051765,17.9998706 C0.71714958,17.9998706 0.862013139,17.9443526 0.972581703,17.8327545 L9.0000937,9.74924618 L17.0276057,17.8327545 C17.1384085,17.9443526 17.2832721,17.9998706 17.4281356,17.9998706 C17.5729992,17.9998706 17.718097,17.9443526 17.8286656,17.8327545 C18.0499901,17.6098862 18.0499901,17.2487618 17.8286656,17.0258467 L9.86135722,9.00268191 L17.8340066,0.973848225 C18.0553311,0.750979934 18.0553311,0.389855532 17.8340066,0.16694039 C17.6126821,-0.0556467968 17.254037,-0.0556467968 17.0329467,0.16694039 L9.00042166,8.25611765 L0.967006424,0.167268345 C0.745681892,-0.0553188426 0.387317931,-0.0553188426 0.165993399,0.167268345 C-0.0553311331,0.390136635 -0.0553311331,0.751261038 0.165993399,0.974176179 L8.13920499,9.00268191 L8.13911129,9.00268191 Z" fill="#333"></path></g></svg>
        </TooltipCloseBtn>

        {step.title &&
          <TooltipTitle data-testid="tourGuideStepToolTip-stepTitle">
            <FormattedMessage {...T(step.title)} />
          </TooltipTitle>}
        {step.content &&
          <TooltipContent dangerouslySetInnerHTML={{ __html: replaceAllNewLineByHtmlBreak(intl.formatMessage(T(step.content))) }} data-testid="tourGuideStepToolTip-stepContent" />
        }

        {index == 0 && (
          <TooltipFooter>
            <LeftFooterCol >
              <a href="/#/help" target="_blank" data-testid="tourGuideStepToolTip-StartStepHelpLink">
                <FormattedMessage {...T('tourGuide.patientsList.step.startTour.linkDescription')} />
              </a>
            </LeftFooterCol>
            <Button primary data-testid="tourGuideStepToolTip-StartTourButton" {...primaryProps}>
              <FormattedMessage {...T('tourGuide.common.step.startTour')} />
            </Button>
          </TooltipFooter>)}

        {index > 0 && (
          <TooltipFooter>
            <LeftFooterCol data-testid="tourGuideStepToolTip-StepCounter">{index} / {size - 1}</LeftFooterCol>
            {!isLastStep &&
              <Button primary {...primaryProps} data-testid="tourGuideStepToolTip-NextButton">
                <FormattedMessage {...T('tourGuide.common.step.next')} />
              </Button>
            }
            {isLastStep &&
              <Button primary {...primaryProps} data-testid="tourGuideStepToolTip-EndTourButton">
                <FormattedMessage {...T('tourGuide.common.step.last')} />
              </Button>
            }
          </TooltipFooter>
        )}
      </TooltipBody>
    </Wrapper>
  );
};

export function replaceAllNewLineByHtmlBreak(str) {
  return str.replace(new RegExp('\n', 'g'), '<br/>');
}