import { gql } from "@apollo/client";

export const FETCH_ALL_COUNTRIES_QUERY = gql`
  query countriesQuery {
    countries @client {
      data
    }
  }
`
export const STORE_COUNTRIES_MUTATION = gql`
  mutation setCountries($data: [String]) {
    setCountries(data: $data) @client {
      data
    }
  }
`
