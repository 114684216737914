import { gql } from "@apollo/client";

export const MUTATION_GENERATE_OTP_FOR_PHONE_VERIFICATION = gql`
  mutation generateOtpForPhoneVerification($contactPhoneEntity: ContactPhoneEntity!) {
    generateOtpForPhoneVerification(contactPhoneEntity: $contactPhoneEntity) {
    contactPhoneEntity
  }
}
`

export const MUTATION_GENERATE_OTP_FOR_EMAIL_VERIFICATION = gql`
  mutation generateOtpForEmailVerification($contactEmailEntity: ContactEmailEntity!) {
    generateOtpForEmailVerification(contactEmailEntity: $contactEmailEntity) {
      contactEmailEntity
    }
  }
`

export const MUTATION_VERIFY_OTP_FOR_PHONE_VERIFICATION = gql`
  mutation verifyOtpForPhoneVerification($contactVerifyPhoneEntity: ContactVerifyPhoneEntity!) {
    verifyOtpForPhoneVerification(contactVerifyPhoneEntity: $contactVerifyPhoneEntity) {
      contactVerifyPhoneEntity
    }
  }
`

export const MUTATION_VERIFY_OTP_FOR_EMAIL_VERIFICATION = gql`
  mutation verifyOtpForEmailVerification($contactVerifyEmailEntity: ContactVerifyEmailEntity!) {
    verifyOtpForEmailVerification(contactVerifyEmailEntity: $contactVerifyEmailEntity) {
      contactVerifyEmailEntity
    }
  }
`