import React, { FC, ReactElement } from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl, FormattedMessage } from "react-intl";
import { T } from "core";
import { compose } from "lodash/fp";
import { get } from "lodash";
import { withQuery } from "../../../helper/withQuery";
import { withRouter } from "helper/withRouter";
import { IMapProps } from "../../../typescript/interfaces/IPatientsInPractice";
import { QUERY_ALL_PATIENTS } from "graphql/queries/PatientsQuery";
import Table from "./PatientsTable";
import queryString from "query-string";
import { StyledPagination } from 'styledComponents/Pagination/StyledPagination'
import { Banner } from "styledComponents/Div/DivComponent/DivComponent";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

const PatientsInPractice: FC<any> = (props): ReactElement => {
  const { Me, sort, loading, PatientsList = [], pagination = {} } = props;
  const currentPage = get(pagination, "pageNumber", 0) + 1;
  const numberOfElements = get(pagination, "numberOfElements", 0);

  const setPage = (pageNumber) => {
    const { location } = props;
    const page = pageNumber - 1;
    const query = { ...location.query, page };
    location.search = queryString.stringify(query);
    props.history.push(location);
  };

  const setSort = (sortField) => {
    const { location } = props;
    const prevOrder = get(location, "query.sort", "").split(",")[1] || "desc";
    const order = prevOrder === "asc" ? "desc" : "asc";
    const sortingField = [sortField, order].join(",");
    const query = { ...location.query, sort: sortingField };
    location.search = queryString.stringify(query);
    props.history.push(location);
  };
  return (
    <div className="container mt-5">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-2 half-max-width">
            <h2><FormattedMessage {...T("deletePracticeErrorDetails.PatientListTitle")} /></h2>
          </div>
          <div className="col-2">
            <StyledButtonLink
              $buttonprimarystyle
              to="/overview/orgManagement/practices"
              data-qa="goto-practice"
            >
              <FormattedMessage {...T("common.back")} />
            </StyledButtonLink>
          </div>
        </div>
        <br />
        <Banner>
          <div className="text-style">
            <StyledFontAwesomeIcon icon={faInfoCircle} type="info" className="mr-1" />
            <FormattedMessage {...T("DELETE_NOT_EMPTY_PRACTICE")} />
          </div>
        </Banner>
        <br />
        <Table
          refetch={props.refetch}
          sort={sort}
          setSort={setSort}
          Me={Me}
          patients={PatientsList}
          data-qa="manage__table"
          isLoading={loading}
        />
        {numberOfElements > 0 && (
          <div className="d-flex justify-content-center">
            <StyledPagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage={25}
              totalItemsCount={get(pagination, "totalElements", 0)}
              onChange={setPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const feedVariablesToQuery = ({
  practiceId = null,
  sort = "firstName,asc",
  employeeId = null,
  page = 0,
  search = "",
}) => ({
  practiceId,
  employeeId,
  sort: sort,
  search,
  page: Number(page),
});

const options = (props) => {
  if (!props?.match?.params?.id || !props?.employee?.id) {
    return;
  }

  const {
    match: {
      params: { id: practiceId },
    },
    employee: { id: employeeId },
  } = props;

  let query = Object.assign({}, queryString.parse(props.location.search), {
    practiceId: parseInt(practiceId, 10),
    employeeId: Number(employeeId),
  });
  return {
    notifyOnNetworkStatusChange: true,
    variables: feedVariablesToQuery(query),
    fetchPolicy: "network-only" as WatchQueryFetchPolicy,
  };
};

const mapProps = ({ data: { loading, refetch, PatientsList } }: IMapProps) => {
  let { content, pagination } = { ...PatientsList };
  return {
    loading,
    PatientsList: content,
    pagination,
    refetch,
  };
};

const withData = compose(
  graphql(QUERY_ALL_PATIENTS, {
    props: mapProps,
    options,
  }),
  injectIntl
);

export default withRouter(withQuery(withData(PatientsInPractice)));
