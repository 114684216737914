/* global __ENV__, __REST_API_LAUNCHDARKLY_KEY__ */
import * as LDClient from 'launchdarkly-js-client-sdk'

const REST_API_LAUNCHDARKLY_KEY = process.env.REACT_APP_REST_API_LAUNCHDARKLY_KEY || 'api-3cefd6b7-20a4-4f3c-bd45-d0938341e782'// __REST_API_LAUNCHDARKLY_KEY__
const env = process.env.NODE_ENV || 'development';

let ldid = ''

switch (env) {
  case 'production':
    ldid = '587777692f61020941bf23cb'
    break
  case 'staging':
    ldid = '5877777cf58d9a091f0536dc'
    break
  case 'development':
  default:
    ldid = '598afdb88a25dc0bf694f497'
    break
}

const unAuthorizedUserName = 'unauthorizeduser'
const fflags = LDClient.initialize(ldid, { key: unAuthorizedUserName })
fflags.waitUntilReady()
export default fflags

export const isVariationFlagEnabled = (featureKey, defaultValue = false) => {
  const localFlag = (window.localStorage && localStorage.getItem(featureKey)) || false
  const variationFlag = fflags.variation(featureKey, defaultValue)
  return !localFlag && variationFlag
}

export const updateFFlag = async (userId, featureKey) => {
  const data = { setting: false }

  try {
    localStorage.setItem(featureKey, true)
    return await fetch(
      `https://app.launchdarkly.com/api/v2/users/hcp/${env}/${userId}/flags/${featureKey}`,
      {
        method: 'PUT',
        headers: new Headers({
          Authorization: REST_API_LAUNCHDARKLY_KEY,
          'Content-type': 'application/json'
        }),
        body: JSON.stringify(data)
      }
    )
  } catch (e) {
    localStorage.setItem(featureKey, true)
  }
}

export const getFlagSettings = async userId => {
  try {
    return await fetch(`https://app.launchdarkly.com/api/v2/users/hcp/${env}/${userId}/flags`, {
      method: 'GET',
      headers: new Headers({
        Authorization: REST_API_LAUNCHDARKLY_KEY,
        'Content-type': 'application/json'
      })
    })
      .then(response => {
        if (response.ok) return response.json()
      })
      .catch(error => {
        const response = {
          items: { callError: true }
        }
        console.log('LaunchDarkly is not accessible.', error)
        return response
      })
  } catch (e) {
    console.log('Error while getting flag settings for user.', e)
  }
}
