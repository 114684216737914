import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment-timezone'
import { T } from 'core'
import _ from 'lodash'

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryVoronoiContainer
} from 'victory'

import theme from '../theme'
import { CustomTooltip } from '../CustomTooltip'
import { SatisfactionPoint } from './SatisfactionPoint'

const dataStyles = {
  data: {
    strokeWidth: () => 0.6,
    stroke: () => 'rgba(0,0,0,1)',
    fill: x => (x.rating < 3 ? '#D70926' : '#78b929')
  }
}
const dataStyles2 = {
  data: {
    fill: x => {
      if (x.rating < 3) {
        return '#D70926'
      }
      return 'black'
    }
  }
}
const dataStyles3 = {
  data: {
    strokeWidth: () => 0.6,
    stroke: () => 'rgba(0,0,0,1)'
  }
}

const baseTooltipProps = {
  orientation: 'right',
  dy: 1,
  dx: 8,
  flyoutWidth: 150,
  style: Object.assign(
    {},
    {
      fill: 'white',
      fontSize: 10,
      fontFamily: 'Montserrat Bold'
    }
  ),
  flyoutStyle: {
    strokeWidth: 0,
    fill: '#D70926'
  }
}
class SatisfactionChart extends Component {
  constructor(props) {
    super(props)
    this._victoryChartRef = React.createRef()
  }

  getStartDate() {

    const startDateFromToday = moment(Date.now()).subtract(4, 'weeks')
    const globalStartDate = moment(startDateFromToday)
    return {
      globalStartDate
    }
  }

  render() {
    const noop = () => { }
    const {
      intl: { formatMessage, locale },
      tickLabels,
      onClickSatisfaction = noop
    } = this.props

    // clean inputdata
    let data = this.props.data || []

    data = data.map(item => {
      item.dailySatisfactions.forEach(element => element.date.setHours(0, 0, 0, 0))
      item.dailySatisfactions.sort((a, b) => {
        if (a.date < b.date) return -1
        if (a.date > b.date) return 1
        return 0
      })
      return item
    })

    const today = moment('00:00', 'HH:mm').toDate()
    // NOTE just of for the case that satisfaction and ratings are different
    const { globalStartDate } = this.getStartDate()

    const globalDuration = 5 // There are 4 weeks in a month

    const formatTextInTick = date => {
      return moment(date).locale(locale).format('l').toString()
    }

    const globalTickValues = Array(globalDuration).fill(true).map((v, x) => {
      const xdate = moment(globalStartDate.clone())
      xdate.add(x * 7, 'days')
      return xdate.toDate()
    })
    const globalTickFormat = globalTickValues.map(x => formatTextInTick(x))
    globalTickValues.push(today)
    globalTickFormat.push(formatMessage(T('SatisfactionChart.today')).toUpperCase())

    const styleOverrides = {
      axis: {
        stroke: 'black'
      },
      grid: {
        strokeWidth: ({ tick }) => (tick === today ? 2 : 0.5),
        stroke: ({ tick }) => (tick === today ? '#D70926' : 'rgba(0,0,0,0.2)')
      },
      tickLabels: {
        fontSize: 12,
        fontWeight: 'bold',
        padding: ({ tick }) => (tick === today ? -167 : 10),
        fill: ({ tick }) => (tick === today ? '#D70926' : 'black')
      }
    }

    const styleOverrides2 = {
      axis: {
        stroke: 'black'
      },
      grid: {
        strokeWidth: 0.5,
        stroke: ({ tick }) => (tick === today ? '#D70926' : 'rgba(0,0,0,0.2)')
      },
      tickLabels: {
        paddingRight: 10,
        fontSize: 12,
        fontWeight: 'bold',
        fill: ({ tickValue }) => {
          switch(tickValue)
          {
          case 0:
            return 'rgb(225,36,63)'
          case 1:
            return 'rgb(202,131,0)'
          case 2:
            return 'rgb(255,190,66)'
          case 3:
            return 'rgb(0,190,127)'
          case 4:
            return 'rgb(0,136,88)'
          }
        }
      }
    }

    const getTickLabelByRating = rating => tickLabels[Math.ceil(rating)]
    const satisfactionEvents = [{ target: 'data', eventHandlers: { onClick: onClickSatisfaction } }]    
    return (
      <div style={{ paddingTop: '50px' }} ref={this._victoryChartRef}>
        <VictoryChart
          domainPadding={20}
          width={980}
          height={250}
          theme={theme}
          padding={{ top: 50, bottom: 50, left: 150, right: 40 }}
          containerComponent={<VictoryVoronoiContainer radius={8} />}
        >
          <VictoryAxis
            scale="linear"
            style={styleOverrides}
            tickValues={globalTickValues}
            tickFormat={globalTickFormat}
          />
          <VictoryAxis
            dependentAxis={true}
            style={styleOverrides2}
            tickValues={[0, 1, 2, 3, 4]}
            tickFormat={getTickLabelByRating}
            fixLabelOverlap={true}
          />
          {data.map((dataEntry, index) => {
            return (
              <VictoryLine
                style={dataStyles3}
                key={`satisfaction${index}`}
                data={
                  dataEntry.dailySatisfactions.length
                    ? dataEntry.dailySatisfactions
                    : [{ date: dataEntry.startDate, rating: 0 }]
                }
                x="date"
                y="rating"
              />
            )
          })}

          {data.map((dataEntry, index) => {
            return (
              <VictoryScatter
                style={dataStyles}
                labels={d => {
                  if (d.rating >= 3) return
                  return formatMessage(T('SatisfactionChart.clickDetails'))
                }}
                labelComponent={<CustomTooltip {...baseTooltipProps} />}
                dataComponent={<SatisfactionPoint onClickPoint={onClickSatisfaction} size={20} />}
                events={satisfactionEvents}
                key={`satisfaction${index}`}
                data={
                  dataEntry.dailySatisfactions.length
                    ? dataEntry.dailySatisfactions
                    : [{ date: dataEntry.startDate, rating: null }]
                }
                x="date"
                y="rating"
              />
            )
          })}
        </VictoryChart>
      </div>
    )
  }
  /**
   * Problem:-Victory Chart is using inline CSS to set width and height of the chart along with
   *         'flex' property which is causing trouble while rendering chart in Internet Explorer 11
   * Solution:-Remove 'width' and 'height' using DOM manipulation by nullifying it with -1px which
   *           helps in rendering chart in Internet Explorer 11
   */
  componentDidMount() {
    this._victoryChartRef.current.children[0].children[0].style.removeProperty('width')
    this._victoryChartRef.current.children[0].children[0].style.removeProperty('height')  
  }
}

export default injectIntl(SatisfactionChart)
