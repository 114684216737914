import React, { Component } from 'react'
import { isNil, isEmpty } from 'lodash'
import LessonRatings from './LessonRatings'
import { injectIntl } from 'react-intl'
import LessonRatingsBanner from './LessonRatingsBanner'
import DataContext from "containers/DataContext"

export class LessonRatingsWrapper extends Component {
  static contextType = DataContext;
  render() {
    const { customer } = this.context
    const checkIfAppNotConnected = isNil(customer.appInfo) || isEmpty(customer.appInfo)

    return checkIfAppNotConnected
      ? <LessonRatingsBanner />
      : <LessonRatings customer={customer} />
  }
}

export default injectIntl(LessonRatingsWrapper)
