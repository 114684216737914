import React from "react";
import { ErrorMessage, getIn } from "formik";

const RadioButtonGroupField = ({
  header,
  field,
  field: { value, name },
  labelArray = [],
  options = [],
  createDataQaEntry,
  form: { setFieldValue, values, touched, errors },
  onSelect = () => {},
  disabled,
  radioLayoutClassName='',
  disableNotificationOptionName
}) => {
  return (
    <div
      className={`form-group ${
        getIn(errors, field.name) && getIn(touched, field.name)
          ? "has-error"
          : ""
      }`}
      data-qa={name}
    >
      <label htmlFor={field.name} className={`control-label`}>
        {header}
      </label>
      <div className={`form-inline ${radioLayoutClassName}`}>
        {labelArray &&
          labelArray.map((label, index) => {
            return (
              <div key={index} className="form-check mb-2 mr-sm-2">
                <input
                  id={index + name}
                  type="radio"
                  name={name}
                  data-qa={createDataQaEntry(name, options, index)}
                  value={options[index]}
                  onChange={(e) => {
                    field.onChange(e);
                    onSelect(e, setFieldValue, values);
                  }}
                  className={`form-check-input ${
                    getIn(errors, field.name) && getIn(touched, field.name)
                      ? "has-error"
                      : ""
                  }`}
                  disabled={disabled || disableNotificationOptionName?.includes(options[index]) }
                  checked={value == options[index]}
                />
                <label
                  htmlFor={index + name}
                  className={`form-check-label radio-group--custom ${
                    disabled ? "disabled" : ""
                  }`}
                  key={index}
                >
                  {label}
                </label>
              </div>
            );
          })}
      </div>
      <ErrorMessage
        name={field.name}
        render={(msg) => (
          <div className="help-block" data-qa="input-error">
            {msg}
          </div>
        )}
      />
    </div>
  );
};

export default RadioButtonGroupField;
