import themeSchema from "./schema";

export const getThemeWithMode = (brandId) => {
  switch (brandId) {
    case 1:
      return themeSchema["theme-signia"];
    case 2:
      return themeSchema["theme-neutral"];
    case 3:
      return themeSchema["theme-hear.com"];
    case 4:
      return themeSchema["theme-as"];
    case 5:
      return themeSchema["theme-hear.com"];
    case 6:
      return themeSchema["theme-rexton"];
    case 7:
      return themeSchema["theme-a&m"]
    case 8:
      return themeSchema["theme-neutral"];
    case 9:
      return themeSchema["theme-as"];
    case 10:
      return themeSchema["theme-viennatone"]
    case 11:
      return themeSchema["theme-hearinggo"]
    default:
      return themeSchema["theme-neutral"];
  }
};

export const brandNeutralTheme = {
  brandId: 2,
  schema: themeSchema["theme-neutral"],
};