import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { StyledChatMessageIcon } from 'styledComponents/Icon/StyledChatMessageIcon'

const MissedCallComponent = ({ count, customerId }) =>
  count
    ? <div className="chat-link-div">
        <Link to={`/profile/${customerId}/messages`} className="chat-link"
          onClick={e => e.stopPropagation()}>
            <StyledChatMessageIcon>
              {count}
            </StyledChatMessageIcon>
        </Link>
      </div>
    : <div />

MissedCallComponent.propTypes = { count: PropTypes.number }

export default MissedCallComponent
