const themeSchema = {
  "theme-signia": {
    light: {
      name: "signia",
      colors: {
        tab: {
          active: "#454A55",
          hover: "#696E7A",
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#ED0400",
            hover: "#B20500",
            disabled: "#ED0400",
            color: "#fff",
          },
          secondary: {
            active: "#696E7A",
            hover: "#454A55",
            disabled: "#696E7A",
            color: "#fff",
          },
          tertiary: {
            active: "#696E7A",
            hover: "#454A55",
            disabled: "#696E7A",
            hoverDisabled: "rgba(105, 110, 122, 0.25)", // #696E7A with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#ED0400",
          hover: "#B20500",
          color: "#fff",
        },
        pagination: {
          active: "#ED0400",
          default: "#fff",
          color: "#fff",
          defaultColor: "#ED0400",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#ED0400",
            hover: "#B20500",
          },
          delete: {
            color: "#ED0400",
            hover: "#B20500",
          },
          redo: {
            color: "#ED0400",
            hover: "#B20500",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#ED0400",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#B20500",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#F1F1F1",
              color: "#000000"
            },
          },
        },
        barChart: {
          color: "#ED0400"
        }
      },
    },
    dark: {
      name: "signia",
      colors: {
        tab: {
          active: "#454A55",
          hover: "#696E7A",
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#ED0400",
            hover: "#B20500",
            disabled: "#ED0400",
            color: "#fff",
          },
          secondary: {
            active: "#696E7A",
            hover: "#454A55",
            disabled: "#696E7A",
            color: "#fff",
          },
          tertiary: {
            active: "#696E7A",
            hover: "#454A55",
            disabled: "#696E7A",
            hoverDisabled: "rgba(105, 110, 122, 0.25)", // #696E7A with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#ED0400",
          hover: "#B20500",
          color: "#fff",
        },
        pagination: {
          active: "#ED0400",
          default: "#fff",
          color: "#fff",
          defaultColor: "#ED0400",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#ED0400",
            hover: "#B20500",
          },
          delete: {
            color: "#ED0400",
            hover: "#B20500",
          },
          redo: {
            color: "#ED0400",
            hover: "#B20500",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#ED0400",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#B20500",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#F1F1F1",
              color: "#000000"
            },
          },
        },
        barChart: {
          color: "#ED0400"
        }
      },
    },
  },
  "theme-neutral": {
    light: {
      name: "neutral",
      colors: {
        tab: {
          active: "#515065",
          hover: "#7B7E8F",
          disabled: "#EBEBEB",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#7000FF",
            hover: "#250067",
            disabled: "#7000FF",
            color: "#fff",
          },
          secondary: {
            active: "#7B7E8F",
            hover: "#515065",
            disabled: "#7B7E8F",
            color: "#fff",
          },
          tertiary: {
            active: "#7B7E8F",
            hover: "#515065",
            disabled: "#7B7E8F",
            hoverDisabled: "rgba(123, 126, 143, 0.25)", // #7B7E8F with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#7000FF",
          hover: "#250067",
          color: "#fff",
        },
        pagination: {
          active: "#7000FF",
          default: "#fff",
          color: "#fff",
          defaultColor: "#7000FF",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#7000FF",
            hover: "#250067",
          },
          delete: {
            color: "#7000FF",
            hover: "#250067",
          },
          redo: {
            color: "#7000FF",
            hover: "#250067",
          },
          home: {
            color: "#414141",
          },
          search: {
            color: "#414141",
          },
          info: {
            color: "#7000FF",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#250067",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#7000FF"
        }
      },
    },
    dark: {
      name: "neutral",
      colors: {
        tab: {
          active: "#515065",
          hover: "#7B7E8F",
          disabled: "#EBEBEB",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#7000FF",
            hover: "#250067",
            disabled: "#7000FF",
            color: "#fff",
          },
          secondary: {
            active: "#7B7E8F",
            hover: "#515065",
            disabled: "#7B7E8F",
            color: "#fff",
          },
          tertiary: {
            active: "#7B7E8F",
            hover: "#515065",
            disabled: "#7B7E8F",
            hoverDisabled: "rgba(123, 126, 143, 0.25)", // #7B7E8F with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#7000FF",
          hover: "#250067",
          color: "#fff",
        },
        pagination: {
          active: "#7000FF",
          default: "#fff",
          color: "#fff",
          defaultColor: "#7000FF",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#7000FF",
            hover: "#250067",
          },
          delete: {
            color: "#7000FF",
            hover: "#250067",
          },
          redo: {
            color: "#7000FF",
            hover: "#250067",
          },
          home: {
            color: "#414141",
          },
          search: {
            color: "#414141",
          },
          info: {
            color: "#7000FF",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#250067",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#7000FF"
        }
      },
    },
  },
  "theme-as": {
    light: {
      name: "as",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#001789",
            hover: "#000A3B",
            disabled: "#001789",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#001789",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#001789",
          default: "#fff",
          color: "#fff",
          defaultColor: "#001789",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#001789",
            hover: "#000A3B",
          },
          delete: {
            color: "#001789",
            hover: "#000A3B",
          },
          redo: {
            color: "#001789",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#001789",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#001789"
        }
      },
    },
    dark: {
      name: "as",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#001789",
            hover: "#000A3B",
            disabled: "#001789",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#001789",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#001789",
          default: "#fff",
          color: "#fff",
          defaultColor: "#001789",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#001789",
            hover: "#000A3B",
          },
          delete: {
            color: "#001789",
            hover: "#000A3B",
          },
          redo: {
            color: "#001789",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#001789",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#001789"
        }
      },
    },
  },
  "theme-hear.com": {
    light: {
      name: "hear.com",
      colors: {
        tab: {
          active: "#15181D",
          hover: "rgba(57, 58, 59, 0.75)", // #393A3B with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#025C9D",
            hover: "#0F2244",
            disabled: "#6BB1E1",
            color: "#fff",
          },
          secondary: {
            active: "#393A3B",
            hover: "#15181D",
            disabled: "#393A3B",
            color: "#fff",
          },
          tertiary: {
            active: "#393A3B",
            hover: "#15181D",
            disabled: "#393A3B",
            hoverDisabled: "rgba(57, 58, 59, 0.25)", // #393A3B with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#025C9D",
          hover: "#0F2244",
          color: "#fff",
        },
        pagination: {
          active: "#025C9D",
          default: "#fff",
          color: "#fff",
          defaultColor: "#025C9D",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          delete: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          redo: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#025C9D",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#0F2244",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#025C9D"
        }
      },
    },
    dark: {
      name: "hear.com",
      colors: {
        tab: {
          active: "#15181D",
          hover: "rgba(57, 58, 59, 0.75)", // #393A3B with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#025C9D",
            hover: "#0F2244",
            disabled: "#6BB1E1",
            color: "#fff",
          },
          secondary: {
            active: "#393A3B",
            hover: "#15181D",
            disabled: "#393A3B",
            color: "#fff",
          },
          tertiary: {
            active: "#393A3B",
            hover: "#15181D",
            disabled: "#393A3B",
            hoverDisabled: "rgba(57, 58, 59, 0.25)", // #393A3B with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#025C9D",
          hover: "#0F2244",
          color: "#fff",
        },
        pagination: {
          active: "#025C9D",
          default: "#fff",
          color: "#fff",
          defaultColor: "#025C9D",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          delete: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          redo: {
            color: "#025C9D",
            hover: "#0F2244",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#025C9D",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#0F2244",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#025C9D"
        }
      },
    },
  },
  "theme-rexton": {
    light: {
      name: "rexton",
      colors: {
        tab: {
          active: "#15181D",
          hover: "rgba(57, 58, 59, 0.75)", // #393A3B with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#F8E600",
            hover: "#C5B702",
            disabled: "#F8E600",
            color: "#000000",
          },
          secondary: {
            active: "#454A55",
            hover: "#000000",
            disabled: "#454A55",
            color: "#fff",
          },
          tertiary: {
            active: "#454A55",
            hover: "#000000",
            disabled: "#393A3B",
            hoverDisabled: "rgba(69, 74, 85, 0.25)", // #454A55 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#454A55",
          hover: "#000000",
          color: "#fff",
        },
        pagination: {
          active: "#F8E600",
          default: "#FFF",
          color: "#000000",
          defaultColor: "#000000",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#454A55",
            hover: "#000000",
          },
          delete: {
            color: "#454A55",
            hover: "#000000",
          },
          redo: {
            color: "#454A55",
            hover: "#000000",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#454A55",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000000",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#F8E600"
        }
      },
    },
    dark: {
      name: "rexton",
      colors: {
        tab: {
          active: "#15181D",
          hover: "rgba(57, 58, 59, 0.75)", // #393A3B with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#F8E600",
            hover: "#C5B702",
            disabled: "#F8E600",
            color: "#000000",
          },
          secondary: {
            active: "#454A55",
            hover: "#000000",
            disabled: "#454A55",
            color: "#fff",
          },
          tertiary: {
            active: "#454A55",
            hover: "#000000",
            disabled: "#393A3B",
            hoverDisabled: "rgba(69, 74, 85, 0.25)", // #454A55 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#454A55",
          hover: "#000000",
          color: "#fff",
        },
        pagination: {
          active: "#F8E600",
          default: "#FFF",
          color: "#000000",
          defaultColor: "#000000",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#454A55",
            hover: "#000000",
          },
          delete: {
            color: "#454A55",
            hover: "#000000",
          },
          redo: {
            color: "#454A55",
            hover: "#000000",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#454A55",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000000",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#F8E600"
        }
      },
    },
  },
  "theme-a&m": {
    light: {
      name: "a&m",
      colors: {
        tab: {
          active: "#6B6760",
          hover: "#9D978F",
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#FF7500",
            hover: "#A54C00",
            disabled: "#E09759",
            color: "#fff",
          },
          secondary: {
            active: "#9D978F",
            hover: "#6B6760",
            disabled: "#E6E5E3",
            color: "#fff",
          },
          tertiary: {
            active: "#9D978F",
            hover: "#6B6760",
            disabled: "#696E7A",
            hoverDisabled: "rgba(157, 151, 143, 25)", // #9D978F with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#FF7500",
          hover: "#A54C00",
          color: "#fff",
        },
        pagination: {
          active: "#FF7500",
          default: "#fff",
          color: "#fff",
          defaultColor: "#FF7500",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          delete: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          redo: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          home: {
            color: "#414141",
          },
          search: {
            color: "#414141",
          },
          info: {
            color: "#FF7500",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#A54C00",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#FF7500"
        }
      },
    },
    dark: {
      name: "a&m",
      colors: {
        tab: {
          active: "#6B6760",
          hover: "#9D978F",
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#FF7500",
            hover: "#A54C00",
            disabled: "#E09759",
            color: "#fff",
          },
          secondary: {
            active: "#9D978F",
            hover: "#6B6760",
            disabled: "#E6E5E3",
            color: "#fff",
          },
          tertiary: {
            active: "#9D978F",
            hover: "#6B6760",
            disabled: "#696E7A",
            hoverDisabled: "rgba(157, 151, 143, 25)", // #9D978F with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#FF7500",
          hover: "#A54C00",
          color: "#fff",
        },
        pagination: {
          active: "#FF7500",
          default: "#fff",
          color: "#fff",
          defaultColor: "#FF7500",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          delete: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          redo: {
            color: "#FF7500",
            hover: "#A54C00",
          },
          home: {
            color: "#414141",
          },
          search: {
            color: "#414141",
          },
          info: {
            color: "#FF7500",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#A54C00",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#FF7500"
        }
      },
    }
  },
  "theme-viennatone": {
    light: {
      name: "viennatone",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#0e1856",
            hover: "#000A3B",
            disabled: "#0e1856",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#0e1856",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#0e1856",
          default: "#fff",
          color: "#fff",
          defaultColor: "#0e1856",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          delete: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          redo: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#0e1856",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#0e1856"
        }
      },
    },
    dark: {
      name: "viennatone",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#0e1856",
            hover: "#000A3B",
            disabled: "#0e1856",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#0e1856",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#0e1856",
          default: "#fff",
          color: "#fff",
          defaultColor: "#0e1856",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          delete: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          redo: {
            color: "#0e1856",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#0e1856",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#0e1856"
        }
      },
    },
  },
  "theme-hearinggo": {
    light: {
      name: "hearinggo",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#00B3CD",
            hover: "#000A3B",
            disabled: "#00B3CD",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#00B3CD",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#00B3CD",
          default: "#fff",
          color: "#fff",
          defaultColor: "#00B3CD",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          delete: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          redo: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#00B3CD",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#00B3CD"
        }
      },
    },
    dark: {
      name: "hearinggo",
      colors: {
        tab: {
          active: "#26242E",
          hover: "rgba(83, 83, 83, 0.75)", // #535353 with 75% opacity
          disabled: "#F1F1F1",
          color: "#fff",
        },
        button: {
          primary: {
            active: "#00B3CD",
            hover: "#000A3B",
            disabled: "#00B3CD",
            color: "#fff",
          },
          secondary: {
            active: "#535353",
            hover: "#26242E",
            disabled: "#535353",
            color: "#fff",
          },
          tertiary: {
            active: "#565656",
            hover: "#515151",
            disabled: "#565656",
            hoverDisabled: "rgba(200, 200, 200, 0.25)", // #C8C8C8 with 25% opacity
            color: "#fff",
          },
        },
        linkButton: {
          active: "#00B3CD",
          hover: "#000A3B",
          color: "#fff",
        },
        pagination: {
          active: "#00B3CD",
          default: "#fff",
          color: "#fff",
          defaultColor: "#00B3CD",
        },
        table: {
          header: {
            backgroundColor: "#292929",
          },
        },
        dropdowns: {
          searchableDropdown: {
            active: "#515151",
            default: "#7F7F7F",
            selected: {
              dark: "#515151",
              light: "#D5D5D5",
            },
          },
          singleSelectDropdown: {
            caret: {
              active: "#515151",
              disabled: "#7F7F7F",
              selected: "#515151",
              arrow: "#fff",
            },
            active: "#fff",
            hoverActive: "#D5D5D5",
            disabled: "#f0f0f0",
            hoverDisabled: "#F1F1F1",
            borderColor: "#9d9c9c",
            itemTextColor: "#454545",
          },
        },
        fontAwesomeIcons: {
          edit: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          delete: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          redo: {
            color: "#00B3CD",
            hover: "#000A3B",
          },
          home: {
            color: "#292929",
          },
          search: {
            color: "#292929",
          },
          info: {
            color: "#00B3CD",
          },
          success: {
            color: "#008858",
          },
          failure: {
            color: "#E1243F",
          },
          angleDown: {
            color: "#515151",
          },
          popupIcon: {
            backgroundColor: "#000A3B",
            color: "#FFF",
          },
        },
        icons: {
          chatMessageCount: {
            color: "#E1243F",
          },
          neutralRating: {
            color: "rgb(255, 190, 66)",
          },
          positiveRating: {
            color: "rgb(0, 190, 127)",
          },
          veryPositiveRating: {
            color: "rgb(0, 136, 88)",
          },
          negativeRating: {
            color: "rgb(202, 131, 0)",
          },
          veryNegativeRating: {
            color: "rgb(225, 36, 63)",
          },
          thumbsUp: {
            color: "#008858",
          },
          thumbsDown: {
            color: "#E1243F",
          },
        },
        progressBar: {
          color: "#E1243F",
        },
        chatMessage: {
          messageOut: {
            backgroundColor: "#D5D5D5",
          },
          messageIn: {
            backgroundColor: "#7F7F7F",
            digitalAssistant: {
              backgroundColor: "#7F7F7F",
              color: "#E1E1E1"
            },
          },
        },
        barChart: {
          color: "#00B3CD"
        }
      },
    },
  }
};

export default themeSchema;
