import {
  useLocation,
} from "react-router-dom";
import React from 'react';
import queryString from 'query-string'

export function withQuery(Component) {
  function ComponentWithQweryProp(props) {
    let location = useLocation();
    let query = queryString.parse(location.search)
    return (
      <Component
        {...props}
        query={query}
      />
    );
  }

  return ComponentWithQweryProp;
}