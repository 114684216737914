import styled, { css } from "styled-components";

export const StyledMenu = styled.ul`
  margin-bottom: 0;
  padding: 0;

  &.profilePage {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StyledMenuItem = styled.li((props) => {
  const {
    theme: {
      colors: {
        tab: {
          active: tabBackgroundActive,
          hover: tabBackgroundHover,
          color: tabFontColorActive
        },
      },
    },
  } = props;
  return css`
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
      background: ${tabBackgroundActive};
      &:hover {
        background-color: ${tabBackgroundHover};
      }
      a {
        color: ${tabFontColorActive};
      }
    }
  `;
});
