import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { HelpCenterNavigation, HelpCenterNavigationItem } from 'components/HelpCenter/Navigation'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../../helper/withQuery'
import { ThemeSwitcher } from '../../containers/ThemeSwitcher'
import { brandNeutralTheme } from "styledComponents/Themes";
import { Outlet } from 'react-router-dom'

export class HelpCenter extends Component {

  render() {
    const { location } = this.props

    return (
      <section>
        <div className="container">
          <h2 data-qa="title">
            <FormattedMessage {...T(`${'helpCenter.header'}`)} />
          </h2>
        </div>
        <section>
          <ThemeSwitcher brandId={brandNeutralTheme.brandId} mode="light">
            <HelpCenterNavigation active={location.pathname} location={location}>
              <HelpCenterNavigationItem
                href={`/help/faq`}
                dataqa="faq"
                label={<FormattedMessage {...T('helpCenter.faq')} />}
                active={location.pathname}
                className="col-md-2 active-navigation-bar"
              />
              <HelpCenterNavigationItem
                href={`/help/supportedDevices`}
                dataqa="supportedDevices"
                label={<FormattedMessage {...T('helpCenter.supportedDevices')} />}
                active={location.pathname}
                className="col-md-3 active-navigation-bar"
              />
              <HelpCenterNavigationItem
                href={`/help/tutorials`}
                dataqa="tutorials"
                label={<FormattedMessage {...T('helpCenter.tutorials')} />}
                active={location.pathname}
                className="col-md-2 active-navigation-bar"
              />
            </HelpCenterNavigation>
            <main>
              <Outlet />
            </main>
          </ThemeSwitcher>
        </section>
      </section>
    )
  }
}

export default withRouter(withQuery(HelpCenter))
