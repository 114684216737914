import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import RegisterNow from './RegisterNow'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import LoginPage from '../Login'
import IconCloserCustomerContact from '../../assets/img/icon_closer_customer_contact.png'
import IconHigherConversion from '../../assets/img/icon_higher_conversion.png'
import IconHigherCustomerSatisfaction from '../../assets/img/icon_higher_customer_satisfaction.png'
import { FormattedMessage } from 'react-intl'
import Markdown from 'react-markdown'
import LandingFaq from './LandingFaq'
import LanguageDropdown from 'components/Dropdowns/LanguageDropdown'
import { getLocales } from 'helper/constants'
import { compose } from 'lodash/fp'
import Footer from 'components/Footer.js'
import { LOCALE_QUERY } from '../../graphql/queries/LocaleQuery'
import { LOCALE_MUTATION } from './../../graphql/mutations/LocaleMutation'
import Video from './Video'
import {
  HeaderContainer,
  StyledContainer,
  FlexRow,
  TeleCareDescription,
} from 'styledComponents/Div/DivContainer/DivLandingPage'

export class LandingPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { intl: { formatMessage }, locale, setLocale } = this.props
    return (
      <section>
        <StyledContainer className="container-fluid containerFont background-size">
          <div className="row">
            <HeaderContainer className="headerrow">
              <div className="row">
                <div className="col-lg offset-lg-1">
                  <LanguageDropdown
                    modifier="language"
                    active={locale}
                    setLocale={setLocale}
                    locales={getLocales()}
                    classname="toggle"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1" />
                <div className="col-lg-10 tuv-login-align">
                  <FlexRow className="row">
                    <div className="col-lg-2" />
                    <div className="col-lg-6 col-md-6 alignVertical">
                      <div className="headerquote text-center">
                        <FormattedMessage {...T('landingpage.header.quote')} />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <LoginPage {...this.props} />
                    </div>
                  </FlexRow>
                </div>
              </div>
            </HeaderContainer>
          </div>

          <div className="iconContainer">
            <div className='header'>
              <FormattedMessage {...T('landingpage.abouttelecare')} />
            </div>
            <div className="row">
              <div className="col-lg-11 tuv-login-align">
                <div className="row col-lg-12 tuv-login-align" style={{ marginLeft: "-25px" }}>
                  <div className="col-lg-4">
                    <div className="row iconBox">
                      <div className="iconBoxElement">
                        <img src={IconCloserCustomerContact} className="iconImgDiv" />
                      </div>
                      <div className="iconBoxHeaderText">
                        <FormattedMessage {...T('landingpage.benefits.leftbox.header')} /></div>
                      <div className="iconBoxText">
                        <FormattedMessage {...T('landingpage.benefits.leftbox.content')} />
                      </div>
                      <div className='col-lg-12 iconBoxFooterText'>
                        <FormattedMessage {...T('landingpage.benefits.leftbox.footer')} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row iconBox">
                      <div className="iconBoxElement">
                        <img src={IconHigherCustomerSatisfaction} className="iconImgDiv" />
                      </div>
                      <div className="iconBoxHeaderText">
                        <FormattedMessage {...T('landingpage.benefits.middlebox.header')} />
                      </div>
                      <div className="iconBoxText">
                        <FormattedMessage {...T('landingpage.benefits.middlebox.content')} />
                      </div>
                      <div className='col-lg-12 iconBoxFooterText'>
                        <FormattedMessage {...T('landingpage.benefits.middlebox.footer')} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row iconBox">
                      <div className="iconBoxElement">
                        <img src={IconHigherConversion} className="iconImgDiv" />
                      </div>
                      <div className="iconBoxHeaderText">
                        <FormattedMessage {...T('landingpage.benefits.rightbox.header')} />
                      </div>
                      <div className="iconBoxText">
                        <FormattedMessage {...T('landingpage.benefits.rightbox.content')} />
                      </div>
                      <div className='col-lg-12 iconBoxFooterText'>
                        <FormattedMessage {...T('landingpage.benefits.middlebox.footer')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledContainer>

        <TeleCareDescription>
          <div className="container" style={{ overflowX: 'hidden' }}>
            <div className='telecareDescriptionMargin'>
              <div className="telecareDescriptionTitle">
                <FormattedMessage {...T('landingpage.patientsatisfaction.title')} />
              </div>
              <div className="telecareDescriptionContent">
                <Markdown children={formatMessage(T('landingpage.patientsatisfaction.content'))} />
              </div>
            </div>

            <div className='telecareDescriptionMargin'>
              <div className="telecareDescriptionTitle">
                <FormattedMessage {...T('landingpage.trialsuccess.title')} />
              </div>
              <div className="telecareDescriptionContent">
                <Markdown children={formatMessage(T('landingpage.trialsuccess.content'))} />
              </div>
            </div>

            <div className='telecareDescriptionMargin'>
              <div className="telecareDescriptionTitle">
                <FormattedMessage {...T('landingpage.dataprotection.title')} />
              </div>
              <div className="telecareDescriptionContent">
                <Markdown children={formatMessage(T('landingpage.dataprotection.content'))} />
              </div>
            </div>
          </div>
        </TeleCareDescription>

        {/* <div className="container containerFont" style={{ overflowX: 'hidden' }}>
          Removed until we have some brand neutral videos to be shown on starting page
          <Video
            title={T('landingpage.video.title')}
            source={formatMessage(T('landingpage.video.source'))}
          />
          </div> */}
        <div className="row faqContainer">
          <div className="col-lg-8">
            <div className="faq-header">
              <FormattedMessage {...T('landingpage.faq.title')} />
            </div>
            <LandingFaq locale={locale} />
          </div>
        </div>

        <div className="row regOrgContainer">
          <div className="col-lg-10">
            <RegisterNow />
          </div>
        </div>

        <div className="footerContainer">
          <Footer />
        </div>
      </section>
    )
  }
}

export default compose(
  graphql(LOCALE_MUTATION, {
    props: ({ mutate }) => ({
      setLocale: locale => mutate({ variables: { locale } })
    })
  }),
  graphql(LOCALE_QUERY, {
    props: ({ data }) => ({
      locale: data.locale.locale
    })
  })
)(injectIntl(LandingPage))
