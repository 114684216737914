import styled, { css } from "styled-components"

export const StyledTable = styled.table((props) => {
    const {
        theme: {
            colors: {
                table: {
                    header: { backgroundColor: tableHeaderBackgroundColor },
                },
            },
        },
    } = props;

    return css`
    font-size: 14px;
    margin-bottom: 40px;
    td {
      word-wrap: break-word;
      word-break: break-word;
    }

    > thead > tr > th {
      background-color: ${tableHeaderBackgroundColor};
      color: white;
      border: 0;
      padding: 4px 3px 3px;

      & i {
        color: #828282;
      }
    }

    > tbody > tr {
      > td {
        border: 0;
        border-bottom: 1px solid #b4b8bb;
        padding: 4px 3px 3px;
      }

      &:hover {
        background-color: rgba(180, 184, 187, 0.2);
      }
    }
  `;
});
