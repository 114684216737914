import React from 'react'
import FormGroup from 'components/FormGroup'
import Checkbox from 'components/Checkbox'

const DevicePersistingType = props => {
  const {
    onSelect,
    label,
    required = false,
    defaultChecked,
    defaultValue,
    id,
    isDisabled,
    ...rest
  } = props

  return (
    <FormGroup id={id} required={required} isDisabled={isDisabled} {...rest}>
      <Checkbox
        defaultChecked={defaultChecked}
        id={id}
        onClick={onSelect}
        label={label}
        dataQA={`primax__configuration--${defaultValue}`}
        required={required}
        {...rest}
      />
    </FormGroup>
  )
}
export default DevicePersistingType
