import { map, compose, filter, isEmpty } from 'lodash/fp'
import isValidByType from 'helper/isValidByType'

export default (value, requirements = []) => {
  return compose(
    isEmpty,
    filter(state => !state),
    map(requirementItem => {
      if (typeof requirementItem === 'string') {
        return isValidByType[requirementItem](value)
      } else {
        return requirementItem(value)
      }
    })
  )(requirements)
}
