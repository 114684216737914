import React, { Component } from 'react'
import Slider from '../Slider'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

import ChannelStepper from './ChannelStepper'
import FourBandLegend from './FourBandLegend'

const ChannelsMode4 = ({
  values: { equalizer1, equalizer2, equalizer3, equalizer4 },
  onChange,
  min,
  max,
  step
}) => {
  if (!onChange) {
    onChange = () => () => {}
  }

  const onStepperChange = channels => operation => {
    onChange({
      operation,
      channels
    })
  }

  const onSliderChange = channels => (value, oldValue) => {
    const diff = value - oldValue
    onChange({
      operation: diff < 0 ? 'decrease' : 'increase',
      channels: channels,
      step: Math.abs(diff)
    })
  }

  return (
    <div className="channels-group">
      <div className="row">
        <div className="col-3">
          <ChannelStepper values={[equalizer1]} onClick={onStepperChange(['equalizer1'])} />
        </div>
        <div className="col-3">
          <ChannelStepper values={[equalizer2]} onClick={onStepperChange(['equalizer2'])} />
        </div>
        <div className="col-3">
          <ChannelStepper values={[equalizer3]} onClick={onStepperChange(['equalizer3'])} />
        </div>
        <div className="col-3">
          <ChannelStepper values={[equalizer4]} onClick={onStepperChange(['equalizer4'])} />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Slider
            value={equalizer1}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(['equalizer1'])}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer2}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(['equalizer2'])}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer3}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(['equalizer3'])}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer4}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(['equalizer4'])}
          />
        </div>
      </div>

      <FourBandLegend />
    </div>
  )
}

const ChannelsMode2 = ({
  values: { equalizer1, equalizer2, equalizer3, equalizer4 },
  onChange,
  min,
  max,
  step
}) => {
  const bandChangeSet1 = ['equalizer1', 'equalizer2']
  const bandChangeSet2 = ['equalizer3', 'equalizer4']

  if (!onChange) {
    onChange = channels => operation => {
      console.log('onnoonono change', operation, channels)
    }
  }

  const onStepperChange = channels => operation => {
    onChange({
      operation,
      channels
    })
  }

  const onSliderChange = channels => (value, oldValue) => {
    const diff = value - oldValue
    onChange({
      operation: diff < 0 ? 'decrease' : 'increase',
      channels: channels,
      step: Math.abs(diff)
    })
  }

  return (
    <div className="channels-group">
      <div className="row">
        <div className="col-6">
          <ChannelStepper
            values={[equalizer1, equalizer2]}
            onClick={onStepperChange(bandChangeSet1)}
          />
        </div>
        <div className="col-6">
          <ChannelStepper
            values={[equalizer3, equalizer4]}
            onClick={onStepperChange(bandChangeSet2)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Slider
            value={equalizer1}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet1)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer2}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet1)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer3}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet2)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer4}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet2)}
          />
        </div>
      </div>
      <FourBandLegend />
    </div>
  )
}

const ChannelsMode1 = ({
  values: { equalizer1, equalizer2, equalizer3, equalizer4 },
  onChange,
  min,
  max,
  step
}) => {
  const bandChangeSet = ['equalizer1', 'equalizer2', 'equalizer3', 'equalizer4']
  if (!onChange) {
    onChange = channels => operation => {
      console.log('onnoonono change', operation, channels)
    }
  }

  const onStepperChange = channels => operation => {
    onChange({
      operation,
      channels
    })
  }

  const onSliderChange = channels => (value, oldValue) => {
    const diff = value - oldValue
    onChange({
      operation: diff < 0 ? 'decrease' : 'increase',
      channels: channels,
      step: Math.abs(diff)
    })
  }

  return (
    <div className="channels-group">
      <div className="row">
        <div className="col-12">
          <ChannelStepper
            values={[equalizer1, equalizer2, equalizer3, equalizer4]}
            onClick={onStepperChange(bandChangeSet)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Slider
            value={equalizer1}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer2}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer3}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet)}
          />
        </div>
        <div className="col-3">
          <Slider
            value={equalizer4}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange(bandChangeSet)}
          />
        </div>
      </div>
      <FourBandLegend />
    </div>
  )
}

class FourBandChannelGroup extends Component {
  static defaultProps = {
    values: {
      equalizer1: 0,
      equalizer2: 0,
      equalizer3: 0,
      equalizer4: 0
    },
    min: -3,
    max: 3,
    step: 3
  }

  constructor(...args) {
    super(...args)

    this.state = {
      mode: this.props.mode || 4
    }
  }

  toggleMode = mode => () => {
    this.setState({
      mode
    })
  }

  onChange = (evt) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(evt)
    }
  }

  render() {
    const values = this.props.values || this.defaultProps
    const mode = this.state.mode || 4
    const { min, max, step } = this.props

    let FourBandChannelGroupComponent = null
    switch (mode) {
      case 4:
        FourBandChannelGroupComponent = ChannelsMode4
        break
      case 2:
        FourBandChannelGroupComponent = ChannelsMode2
        break
      case 1:
        FourBandChannelGroupComponent = ChannelsMode1
        break
    }

    return (
      <section className="channels">
        <div className="row">
          <div className="col-5">
            <h3 className="channel-stepper__title" data-qa="equalizer_in_db">
              <FormattedMessage {...T('fourband.equalizer')} />
            </h3>
          </div>
          <div className="col-7" className="band-mode">
            <span className="band-mode__title">
              <FormattedMessage {...T('fourband.handles')} />
            </span>
            <button
              type="button"
              data-qa="first_handle"
              className={`band-mode__handler ${mode === 1 ? 'active' : ''}`}
              onClick={this.toggleMode(1)}
            >
              1
            </button>
            <button
              type="button"
              data-qa="second _handle"
              className={`band-mode__handler ${mode === 2 ? 'active' : ''}`}
              onClick={this.toggleMode(2)}
            >
              2
            </button>
            <button
              type="button"
              data-qa="third_handle"
              className={`band-mode__handler ${mode === 4 ? 'active' : ''}`}
              onClick={this.toggleMode(4)}
            >
              4
            </button>
          </div>
        </div>
        <FourBandChannelGroupComponent
          values={values}
          min={min}
          max={max}
          step={step}
          onChange={this.onChange}
        />
      </section>
    )
  }
}

export default FourBandChannelGroup
