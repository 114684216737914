import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import { getLocales, publicPaths, MaintenancePublicPaths, pathsWithProductTour } from 'helper/constants'
import LanguageDropdown from 'components/Dropdowns/LanguageDropdown'
import UserMenuDropdown from 'components/Dropdowns/UserMenuDropdown'
import InfoDropDown from 'components/Dropdowns/InfoDropDown'
import NotificationDropdown from '../components/Dropdowns/NotificationDropdown'
import { NavigationBarItem } from 'styledComponents/ListItem'
import IconWarning from '../assets/img/icons/icon-warning.svg'
import { isPasswordExpiryPage } from 'helper/PasswordExpiryCheck'
import fflags from 'helper/FFlags'
import DataContext from './DataContext'
import { StyledBrandLogo } from "styledComponents/Icon/StyledBrandLogo"
import BrandNeutralLogo from '../assets/img/navbar-brand-neutral-logo.svg';
import BrandSigniaLogo from '../assets/img/brand-signia-logo.svg';

const validatePath = '/help/'

export class NavigationBar extends Component {
  static contextType = DataContext;
  constructor(props) {
    super(props);

    if (
      this.isBrowserUnSupported() &&
      localStorage.getItem("displayBrowserSupportedbanner") == null
    ) {
      localStorage.setItem("displayBrowserSupportedbanner", "true");
    }
    this.state = {
      showBanner:
        localStorage.getItem("displayBrowserSupportedbanner") == "true",
    };
  }

  renderDropdownMenu(match, component) {
    const { location, isAuthenticated } = this.props;

    let isComponentToBeRendered = isAuthenticated &&
      !match &&
      !isPasswordExpiryPage(location.pathname)

    if (isComponentToBeRendered) {
      return component
    }
  }

  userMenuDropdown() {
    return (<UserMenuDropdown
      router={this.props.router}
      me={this.props.Me}
      logout={this.props.logout} />)
  }

  notificationDropdown() {
    return <NotificationDropdown />
  }

  infoDropDown() {
    return <InfoDropDown
      router={this.props.router}
      startTourGuide={(this.startTourGuide)}
      isTourGuideSupported={this.isTourGuideSupported()}
      locale={this.props.locale}
    />
  }

  getBrandLogoUrlOrInlineImage() {
    const isBrandingEnabled = fflags.variation(
      "enable-portal-branding",
      false
    );
    const brandNeutralPaths = [
      ...publicPaths,
      ...MaintenancePublicPaths,
    ];
    const {
      location: { pathname },
    } = this.props;
    if (isBrandingEnabled) {
      if (
        brandNeutralPaths.some((path) => {
          const pattern = new RegExp(path);
          return pattern.test(pathname);
        })
      ) {
        return BrandNeutralLogo;
      } else {
        return this?.props?.Configuration?.brandLogoUrl;
      }
    } else {
      return BrandSigniaLogo;
    }
  }

  render() {
    const { locale, location, isAuthenticated, loading } = this.props;
    const locales = getLocales();
    var pattern = new RegExp(validatePath);
    const match = pattern.test(location.pathname);
    const displayBrowserSupportedbanner = isAuthenticated
      ? this.state.showBanner
      : false;
    return (
      <div className="navigation-bar fixed-top" role="navigation">
        {displayBrowserSupportedbanner ? (
          <div className="panel-browser-detection">
            <div className="container">
              <div className="row">
                <img
                  src={IconWarning}
                  className="icon-warning-browser-detection"
                />
                <div
                  className="col-md-9"
                  dangerouslySetInnerHTML={{
                    __html: this.props.intl.formatMessage(
                      T("browser_recommend")
                    ),
                  }}
                />
                <span
                  className="panel-browser-cursor"
                  onClick={this.hideBrowserSupportedBanner}
                >
                  <i className="fas fa-times" />
                </span>
              </div>
            </div>
          </div>
        ) : null}
        <div className="navigation-bar__navbar">
          <div className="container">
            <div className='row justify-content-between pb-1'>
              <div className='row align-items-center' style={{ 'gap': '1rem' }}>
                <div>
                  <Link to="/">
                    <StyledBrandLogo
                      data-qa={`${this.props?.Configuration?.brandName}-brandlogo`}
                      url={this.getBrandLogoUrlOrInlineImage()}
                      className={`${isPasswordExpiryPage(location.pathname)
                        ? "disabled-div"
                        : ""
                        }`}
                    />
                  </Link>
                </div>
                <div className=''>
                  <span className="header-claim">TeleCare Portal</span>
                </div>
              </div>
              {loading
                ?
                <></>
                :
                <div className='row header-menu-right'>
                  <nav className="navbar navbar-expand-md navbar-default">
                    <ul className="navbar-nav mr-auto mb-auto">
                      <NavigationBarItem>
                        {this.renderDropdownMenu(match, this.notificationDropdown())}
                      </NavigationBarItem>
                      <NavigationBarItem>
                        {this.renderDropdownMenu(match, this.infoDropDown())}
                      </NavigationBarItem>
                      <NavigationBarItem>
                        <LanguageDropdown
                          modifier="language"
                          active={locale}
                          setLocale={this.props.setLocale}
                          locales={locales}
                        />
                      </NavigationBarItem>
                      <NavigationBarItem>
                        {this.renderDropdownMenu(match, this.userMenuDropdown())}
                      </NavigationBarItem>
                    </ul>
                  </nav>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
  isBrowserUnSupported() {
    if (
      navigator.userAgent.match(/Edge\//) ||
      navigator.userAgent.match(/Opera|OPR\//)
    ) {
      return 1;
    } else if (
      navigator.userAgent.search("Chrome") >= 0 ||
      navigator.userAgent.search("Firefox") >= 0
    ) {
      return 0;
    }
    return 1;
  }

  hideBrowserSupportedBanner = () => {
    localStorage.setItem("displayBrowserSupportedbanner", "false");
    this.setState({ showBanner: false });
  };

  isTourGuideSupported() {
    return pathsWithProductTour.includes(this.props.location.pathname);
  };

  startTourGuide = (e) => {
    e.preventDefault();
    this.context.toggleDisplayTourGuide();
  };
}

export default injectIntl(NavigationBar)