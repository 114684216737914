import React, { FC, ReactElement } from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl, FormattedMessage } from "react-intl";
import { T } from "core";
import { compose } from "lodash/fp";
import _, { get } from "lodash";
import { withQuery } from "../../../helper/withQuery";
import { getOrganizationIdFromCache } from "helper/PracticeUtils";
import { withRouter } from "helper/withRouter";
import { IMapProps } from "../../../typescript/interfaces/IUsersInPractice";
import { EMPLOYEES_LIST_QUERY } from "graphql/queries/EmployeesQuery";
import Table from "./Table";
import queryString from "query-string";
import { Banner } from "styledComponents/Div/DivComponent/DivComponent";
import RadioField from "formfields/RadioField";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

const UsersInPractice: FC<any> = (props): ReactElement => {
  const { Me, sort, loading, employees = [] } = props;
  const setSort = (sortField) => {
    const { location } = props;
    const prevOrder = get(location, "query.sort", "").split(",")[1] || "desc";
    const order = prevOrder === "asc" ? "desc" : "asc";
    const sortingField = [sortField, order].join(",");
    const query = { ...location.query, sort: sortingField };
    location.search = queryString.stringify(query);
    props.history.push(location);
  };

  const USER_STATES = [
    {
      value: "ACTIVE",
      label: <FormattedMessage {...T("common.active")} />,
    },
    {
      value: "PENDING",
      label: <FormattedMessage {...T("common.pending")} />,
    },
  ];
  const onChangeHandler = (e) => {
    const { location } = props;
    const selectedValue = e.target.value;
    const currentQuery = { ...location.query, accountState: selectedValue };
    location.search = queryString.stringify(currentQuery);
    props.history.push(location);
  };

  return (
    <div className="container mt-5">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-2 half-max-width">
            <h2><FormattedMessage {...T("deletePracticeErrorDetails.UserListTitle")} /></h2>
          </div>
          <div className="col-2">
            <StyledButtonLink
              $buttonprimarystyle
              to="/overview/orgManagement/practices"
              data-qa="goto-practice"
            >
              <FormattedMessage {...T("common.back")} />
            </StyledButtonLink>
          </div>
        </div>
        <br />
        <Banner>
          <div className="text-style">
            <StyledFontAwesomeIcon icon={faInfoCircle} type="info" className="mr-1" />
            <FormattedMessage {...T("DELETE_NOT_EMPTY_PRACTICE")} />
          </div>
        </Banner>
        <br />
        <div className="form-inline">
          {USER_STATES.map((userState, index) => (
            <RadioField
              onChange={onChangeHandler}
              key={`state-${index}`}
              defaultChecked={index == 0}
              disabled={false}
              className={`mb-2 mr-sm-2`}
              name="accountState"
              value={userState.value}
              radioClassName={"radio-margin"}
            >
              {userState.label}
            </RadioField>
          ))}
        </div>
        <Table
          refetch={props.refetch}
          sort={sort}
          setSort={setSort}
          Me={Me}
          employees={employees.filter((_) => !_.orgManager)}
          data-qa="manage__table"
          isLoading={loading}
        />
      </div>
    </div>
  );
};

const feedVariablesToQuery = ({
  practiceId = null,
  sort = "email,asc",
  page = 0,
  search = "",
  accountState = "ACTIVE",
  size = 10000,
}) => ({
  practiceId,
  sort: sort,
  search,
  page: Number(page),
  accountState,
  size: Number(size),
  orgId: parseInt(getOrganizationIdFromCache())
});

const options = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  let query = Object.assign({}, queryString.parse(props.location.search), {
    practiceId: parseInt(id, 10),
  });
  return {
    notifyOnNetworkStatusChange: true,
    variables: feedVariablesToQuery(query),
    fetchPolicy: "network-only" as WatchQueryFetchPolicy,
  };
};

const mapProps = ({
  data: { loading, refetch, Me, EmployeeList },
}: IMapProps) => {
  let { content, pagination } = { ...EmployeeList };
  return {
    loading,
    refetch,
    Me,
    employees: content,
    pagination,
  };
};

const withData = compose(
  graphql(EMPLOYEES_LIST_QUERY, {
    props: mapProps,
    options,
  }),
  injectIntl
);

export default withRouter(withQuery(withData(UsersInPractice)));
