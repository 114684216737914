import React from "react";
import Countdown, { zeroPad } from "react-countdown";

function Timer({ durationInMillisecondsFromNow, autoStart, refCallback, onTimerComplete }) {
  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <Countdown
      date={durationInMillisecondsFromNow}
      autoStart={autoStart}
      ref={refCallback}
      renderer={renderer}
      onComplete={onTimerComplete}
    />
  );
}

export default Timer;
