import { gql } from "@apollo/client";

export const QUERY_EMPLOYEE_EDIT = gql`
  query EmployeeDetailsQuery($id: Int!, $orgId: Int) {
    Me {
      employee {
        id
        manager
        orgManager
      }
      countryCode
      organizationId
    }
    Employee(id: $id) {
      id
      avatar {
        id
        url
      }
      inactiveFeatures
      firstName
      lastName
      locale
      phone
      phoneVerified
      email
      emailVerified
      username
      manager
      orgManager
      assignedPracticeIds
      defaultPracticeId
      defaultModuleLength
      notificationTime
    }
    AssignedPractices(id: $orgId) {
      id
      name
    }
    NotificationTimeOptions {
      timeSet
    }
  }
`