export const flags = {
  DATA_AVAILABLE: 1 << 2,
  HEARING_DEVICE_SUPPORTED: 1 << 3,
  MOBILE_DEVICE_SUPPORTED: 1 << 4
}

export const check = (category, customer = {}, checkflags = 0) => {
  const { hearingDevices = {} } = customer
  const { capabilities } = hearingDevices
  if (!capabilities || !capabilities[category]) return false

  const capability = capabilities[category].reduce((prev, cur) => {
    return prev | flags[cur]
  }, 0)

  for (var len = checkflags.length, i = 0; i < len; i++) {
    if (!(capability & checkflags[i])) {
      return false
    }
  }
  return true
}
