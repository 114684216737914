import React from 'react'
import { Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import PasswordField from './PasswordField'
import PasswordReEnterField from './PasswordReEnterField'
import OldPasswordField from './OldPasswordField'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import { passwordStrongScore, passwordMinLength } from 'helper/constants'

class PasswordComponent extends React.Component {
  static defaultProps = {
    minLength: passwordMinLength,
    minScore: passwordStrongScore
  }

  render() {
    const { required, oldPassword, arrangedHorizontally, disabled } = this.props
    const passwordClassName = arrangedHorizontally ? 'col-md-6' : 'col-md-12'
    return (
      <div className="row">
        {oldPassword &&
          <div className="col-md-12">
            <Field name="password.oldPassword" component={OldPasswordField} required={required} autoComplete="current-password"/>
          </div>}
        <div data-qa="password" className={passwordClassName}>
          <Field name="password.passwordEnter" component={PasswordField} required={required} autoComplete="new-password" disabled={disabled} />
        </div>
        <div data-qa="confirm_password" className={passwordClassName}>
          <Field
            name="password.passwordReEnter"
            component={PasswordReEnterField}
            required={required}
            autoComplete="new-password"
            disabled={disabled}
          />
        </div>
        <div className="col-md-12">
          <p className="help-block">
            <FormattedMessage {...T('min.password.hint')} />
          </p>
        </div>
      </div>
    )
  }
}

export default injectIntl(PasswordComponent)
