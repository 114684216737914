import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import _, { get, pickBy } from 'lodash'
import { StyledPagination } from 'styledComponents/Pagination/StyledPagination'
import { paginationSize } from 'helper/constants'
import { Formik, Form, Field } from 'formik'
import { EMPLOYEES_LIST_QUERY } from 'graphql/queries/EmployeesQuery'
import Loading from 'components/Loading'
import SearchableSelectorField from 'formfields/SearchableSelectorField'
import ActiveTable from './Table'
import InvitationTable from './InvitationTable'
import SearchBar from 'components/SearchBar'
import { withQuery } from '../../../helper/withQuery'
import { withRouter } from 'helper/withRouter'
import queryString from 'query-string'
import { T } from 'core'
import { getOrganizationIdFromCache } from '../../../helper/PracticeUtils'


const ACTIVE = { value: 'ACTIVE', label: <FormattedMessage {...T('common.active')} /> }
const PENDING = { value: 'PENDING', label: <FormattedMessage {...T('common.pending')} /> }


const INITIAL_QUERY = {
  accountState: ACTIVE.value,
  sort: 'email,asc',
  page: 0
}

const INITIAL_PENDING_QUERY = {
  accountState: PENDING.value,
  sort: 'email,asc',
  page: 0
}

const USER_STATES = [ACTIVE, PENDING]

const RadioButtonGroup = ({
  field,
  field: { value, name },
  labelArray = [],
  options = [],
  createDataQaEntry,
  form: { setFieldValue, values },
  onSelect = () => { },
  disabled,
  selectedRadio
}) => {
  return (
    <div className="form-inline" style={{ paddingBottom: '10px' }}>
      {labelArray &&
        labelArray.map((label, index) => {
          return (
            <div key={index} className="form-check mb-2 mr-sm-2">
              <input
                id={index + name}
                checked={selectedRadio == options[index]}
                type="radio"
                name={name}
                value={options[index]}
                data-qa={createDataQaEntry(name, options, index)}
                onChange={e => {
                  field.onChange(e)
                  onSelect(e, setFieldValue, values)
                }}
                className="form-check-input"
                disabled={disabled}
              />
              <label
                htmlFor={index + name}
                className={`form-check-label radio-group--custom ${disabled ? 'disabled' : ''}`}
                key={index}
              >
                {label}
              </label>
            </div>
          )
        }
        )}
    </div>)
}

export class ManageUsersPage extends Component {
  state = {
    currentTable: ACTIVE.value
  }
  componentDidMount() {
    // set the pagination & query to initial values
    const currentLocation = this.props.location
    const currentQuery = this.props.query
    const practiceId = get(currentQuery, ['practiceId'], null)
    const query = {
      ...INITIAL_QUERY,
      ...(!!practiceId ? { practiceId } : {})
    }
    currentLocation.search = queryString.stringify(query)
    this.props.navigate(currentLocation)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentLocation = this.props.location
    const currentQuery = this.props.query
    const { location, query } = nextProps
    const currentSort = get(currentQuery, 'sort', INITIAL_QUERY.sort)
    const nextSort = get(query, 'sort', '')

    location.search = queryString.stringify(query)
    if (currentSort !== nextSort) {
      let newQuery = pickBy({ ...INITIAL_QUERY, ...query, practiceId: '' })
      if (currentQuery.accountState == PENDING.value) {
        newQuery = pickBy({ ...INITIAL_PENDING_QUERY, ...query, practiceId: '' })
      }
      location.search = queryString.stringify(newQuery)
      if (currentLocation.pathname != location.pathname || currentLocation.search != location.search)
        this.props.navigate(location)
    }
  }

  onPracticeChange = practiceId => {
    const { location, query } = this.props
    const currentQuery = _.pickBy({ ...query, page: '0', practiceId })
    location.search = queryString.stringify(currentQuery)
    location.pathname = `/overview/orgManagement/users`
    this.props.navigate(location)
  }

  setSort = sortField => {
    const { query } = this.props
    const prevOrder = get(query, 'sort', '').split(',')[1] || 'desc'
    const order = prevOrder === 'asc' ? 'desc' : 'asc'
    const sort = [sortField, order].join(',')
    const currentQuery = { ...query, sort }
    location.search = queryString.stringify(currentQuery)
    this.props.navigate(location)
  }

  setPage = pageNumber => {
    const { query } = this.props
    const page = pageNumber - 1
    const currentQuery = { ...query, page }
    location.search = queryString.stringify(currentQuery)
    this.props.navigate(location)
  }

  createDataQaEntry = (name, options, index) => {
    return name + '_' + options[index]
  }

  onRadioSelect = (e) => {
    this.setState({ currentTable: e.target.value })
    const { location, query } = this.props
    const currentQueryuery = _.pickBy({ ...query, page: '0', accountState: e.target.value, sort: 'email,asc' })
    location.search = queryString.stringify(currentQueryuery)
    location.pathname = `/overview/orgManagement/users`
    this.props.navigate(location)

  }

  render() {
    const {
      loading,
      Me,
      employees = [],
      isManager,
      practices = [],
      location,
      query,
      query: { practiceId = '' },
      pagination = {}
    } = this.props
    const currentPage = get(pagination, 'pageNumber', 0) + 1
    const numberOfElements = get(pagination, 'numberOfElements', 0)
    const { sort } = query || {}
    return (
      <div className="manage manage--users">
        <Loading isLoading={loading} />
        <div className="row">
          <div className="col-6">
            {isManager &&
              <Formik
                initialValues={{
                  practice: practiceId
                }}
                enableReinitialize={true}
              >
                {({ values }) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-6">
                          <SearchableSelectorField name="practice" onChange={this.onPracticeChange} options={practices} toString={{ id: "id", value: "name" }} placeholder={<FormattedMessage {...T('common.all_practices')} />} />
                        </div>
                        <div className="col-6 vertical-align-state">
                          <Field
                            name="userState"
                            options={USER_STATES.map(item => item.value)}
                            component={RadioButtonGroup}
                            labelArray={USER_STATES.map(item => item.label)}
                            createDataQaEntry={this.createDataQaEntry}
                            onSelect={this.onRadioSelect}
                            selectedRadio={this.state.currentTable}
                          /></div>
                      </div>
                    </Form>)
                }}
              </Formik>}
          </div>
          <div className="col-6">
            <SearchBar location={location} query={query} />
          </div>
        </div>
        {
          this.state.currentTable == PENDING.value ?
            <InvitationTable
              refetch={this.props.refetch}
              sort={sort}
              setSort={this.setSort}
              Me={Me}
              employees={employees}
              data-qa="manage__table"
            />
            :
            <ActiveTable
              refetch={this.props.refetch}
              sort={sort}
              setSort={this.setSort}
              Me={Me}
              employees={employees}
              data-qa="manage__table"
            />
        }
        {numberOfElements > 0 &&
          <div className="d-flex justify-content-center">
            <StyledPagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage={paginationSize}
              totalItemsCount={get(pagination, 'totalElements', 0)}
              onChange={this.setPage}
            />
          </div>}
      </div>
    )
  }
}

export const mapQueryToVariables = ({
  practiceId = null,
  sort,
  page = 0,
  search = '',
  accountState = ACTIVE.value
}) => ({
  practiceId: parseInt(practiceId),
  sort: sort || INITIAL_QUERY.sort,
  search,
  page: parseInt(page, 10) || INITIAL_QUERY.page,
  accountState,
  orgId: parseInt(getOrganizationIdFromCache())
})

export const userPageOptions = ({ query }) => ({
  notifyOnNetworkStatusChange: true,
  variables: mapQueryToVariables(query),
  fetchPolicy: 'network-only'
})

const mapProps = ({
  data: {
    loading,
    refetch,
    Me,
    EmployeeList: { content, pagination } = [],
    AssignedPractices = []
  }
}) => ({
  refetch,
  loading,
  Me,
  employees: content,
  practices: AssignedPractices,
  pagination,
  isOrgManager: _.get(Me, `employee.orgManager`, false),
  isManager: _.get(Me, `employee.manager`, false),
})

const withData = compose(
  graphql(EMPLOYEES_LIST_QUERY, {
    props: mapProps,
    options: userPageOptions
  }),
  injectIntl
)

export default withRouter(withQuery(withData(ManageUsersPage)))
