import React, { useContext } from "react";
import JoyRide, { ACTIONS } from "react-joyride";
import { withTheme } from "styled-components";
import DataContext from "../containers/DataContext"
import { FormattedMessage } from "react-intl";
import { T } from "core";
import { Tooltip } from "./tourGuideStepToolTip"


const TourGuide = ({ theme, steps }) => {
  const {toggleDisplayTourGuide} = useContext(DataContext);

  const {
    colors: {
      button: {
        primary: {
          active: primaryButtonBackgroundActive, 
          color: primaryButtonFontColor 
        },
        secondary: {
          active: secondaryButtonBackgroundActive,
          color: secondaryButtonFontColor
        },
        tertiary: {
          active: tertiaryButtonBackgroundActive,
          color: tertiaryButtonFontColor,
        },
      },
    },
  } = theme;

  const joyRideStyles = {
    tooltipContainer: {
      textAlign: "left",
    },
    buttonNext: {
      backgroundColor: primaryButtonBackgroundActive,
      color: primaryButtonFontColor,
      borderRadius: 20,
    },
    buttonBack: {
      marginRight: 10,
      backgroundColor: secondaryButtonBackgroundActive,
      color: secondaryButtonFontColor,
      borderRadius: 20,
    },
    buttonSkip: {
      backgroundColor: tertiaryButtonBackgroundActive,
      color: tertiaryButtonFontColor,
    },
    options: {
          primaryColor: primaryButtonBackgroundActive,
          zIndex: 1000,
        }
  }
  
  const handleJoyrideCallback = (data) => {
    const { action } = data;

    if(action === ACTIONS.RESET || action === ACTIONS.CLOSE) {
      toggleDisplayTourGuide();
    }
  }

  return (
      <JoyRide
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={false}
        hideBackButton={true}
        disableScrolling={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        callback = {handleJoyrideCallback}
        styles={joyRideStyles}
        tooltipComponent={Tooltip}
        locale={{
          last: <FormattedMessage {...T('tourGuide.common.step.last')} />,
          skip: <FormattedMessage {...T('tourGuide.common.step.skip')} />,
          next: <FormattedMessage {...T('tourGuide.common.step.next')} />,
          back: <FormattedMessage {...T('tourGuide.common.step.back')} />,
        }}
      />
  );
};

export default withTheme(TourGuide);
