import { gql } from "@apollo/client";

export const ORGANIZATION_DETAILS_QUERY = gql`
  query OrganizationDetailsQuery($organizationId: Int!) {
    OrganizationDetails(orgId: $organizationId) {
      organizationId
      name
      email
      phone
      website
      language
      timeZone
      street 
      city
      state
      zipCode
      countryCode
      isPasswordExpirationPolicyEnabled
      daysToExpirePassword
      autoReplyMode
      autoReplyMessage
      brandId
      brandName
      brandLogoUrl
    }
  }`

  export const MUTATION_UPDATE_ORGANIZATION_DETAILS = gql `
  mutation updateOrganization($organizationId: Int!, $orgDetail: OrgnizationDetailsInput!) {
    updateOrganization(orgId: $organizationId, organizationDetail: $orgDetail)
  } 
  `