import { gql } from "@apollo/client";

export const QUERY_RECIPIENTS_COUNTER = gql`
query RecipientsCounterQuery($filters: String) {
  MarketingRecipientsCounter(filters: $filters) {
    recipients
    total
  }
  Me {
    organizationId
    employee {
      id
      firstName
      defaultPracticeId
    }
  }
}
`;
