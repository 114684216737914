import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { compose } from 'lodash/fp'
import NewBrandSettings from 'components/_Patient/components/NewBrandSettings'
import DeviceModelSettings from 'components/_Patient/components/DeviceModelSettings'
import HearingPrograms from 'components/_Patient/components/HearingPrograms'
import Loading from 'components/Loading'
import { T, tracker } from 'core'
import _ from 'lodash'
import { PATIENT_QUERY } from 'graphql/queries/PatientQuery'
import { QUERY_PATIENT_HIS } from 'graphql/queries/HearingInstrumentQuery'
import { QUERY_HDMODELS } from 'graphql/queries/HdModelsQuery'
import { QUERY_HDMODELS_FEATURES } from 'graphql/queries/DeviceModelsFeaturesQuery'
import { MUTATION_UPDATE_PATIENT } from '../../graphql/queries/UpdatePatientQuery'
import { HearingInstrumentHeadLine } from './components/HearingInstrumentHeadLine'
import { getLeftRightDevice } from 'helper/deviceSelectionRules'
import { getHdDeviceModelsFeatures } from './../../helper/DeviceFeaturesUtils'
import { MUTATION_ADD_HID_SETTING } from '../../graphql/queries/UpdateHIBluetoothSettingQuery'
import {
  options,
  mapProps,
  mapPropsDeviceModels,
  mapPropsDeviceModelsFeatures,
  mapPropsPatientHIs
} from 'graphql/mapProps/HearingInstrumentMapProps'
import HIDBluetoothSetting from './components/HIDBluetoothSetting'
import { customerTracking } from 'helper/customerTracking'
import { Button } from 'styledComponents/Button'
import { ActionButtonsEdit } from 'styledComponents/Div/DivComponent/DivPatient'
import { Form } from 'components/_Patient/components'
import {
  getListOfManufacturers,
  canEditPatient,
  getHearingPrograms,
  getPersistentConfiguration,
  getResetDeviceSettings,
  getBrandByName,
  getCustomPrograms
} from 'components/_Patient/shared'
import { Divider } from 'styledComponents/Div/DivComponent/DivComponent'
import { getFlagSettings } from './../../helper/FFlags'
import { withQuery } from '../../helper/withQuery'

import { sendDataToDataCollection } from "../../helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from 'helper/constants'
import { EventType } from '../../helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData } from '../../helper/DataCollection/PayloadData'
import Tooltip from 'react-tooltip'
import { withRouter } from 'helper/withRouter'
import { injectIntl } from 'react-intl'

export class HearingInstrument extends Component {
  constructor() {
    super()
    this.state = {
      selectedBrandLeft: null,
      selectedBrandRight: null,
      deviceModelsLeft: [],
      deviceModelsRight: [],
      deviceModelRightId: null,
      deviceModelLeftId: null,
      isPagePrefilled: false,
      bluetoothDisabled: false,
      HDModelsFeatures: null,
      manufacturers: [],
      teleCareVersion: null,
      practice: null,
      employee: null,
      customPrograms: [[]],
      isSaving: false,
      isEditing: false,
      canceled: false,
      isDeviceSettingsFormValid: false,
      isBrandSettingsFormValid: false,
      isPracticeInfoFormValid: false, // defaults to the practice and employee
      isDevicePersistingSettingsFormValid: false,
      hearingPrograms: [],
      bluetoothSupported: false,
      shouldShowBluetooth: false,
      isD11: false
    }
  }
  componentDidMount() {
    const { isPagePrefilled } = this.state
    if (!isPagePrefilled && !_.isEmpty(this.props.Customer)) {
      this.prefillEditPage(this.props)
    }

    customerTracking({
      ...this.props.location,
      action: 'customerProfileEditOpened'
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (
      !this.state.isPagePrefilled &&
      !nextProps.PatientQuery.loading &&
      !nextProps.DeviceModelsQuery.loading &&
      !nextProps.DeviceModelsFeaturesQuery.loading &&
      !_.isEmpty(nextProps.Customer)
    ) {
      this.prefillEditPage(nextProps)
    }
    this.fetchShouldShowBluetoothCheckbox(nextProps)
  }

  fetchShouldShowBluetoothCheckbox(nextProps) {
    if (!_.isNil(nextProps.me) && !_.isNil(nextProps.me.employee)) {
      getFlagSettings(nextProps.me.organizationId).then(({ items }) => {
        if (!items.callError) {
          try {
            this.setState({
              shouldShowBluetooth: items['disable-tele-care-3-0']._value
            })
          } catch (e) { }
        }
      })
    }
  }
  async prefillEditPage(props) {
    const {
      manufacturers,
      Customer: {
        practiceId,
        employeeId,
        hearingDevices: { leftDevice = {}, rightDevice = {}, hdPrograms, teleCareVersion }
      },
      HDModelsFeatures,
      HIDSetting = {}
    } = props
    if (hdPrograms && HDModelsFeatures) {
      this.fetchDefaultListOfHDModels({
        brandRight: _.get(rightDevice, ['manufacturer'], ''),
        brandLeft: _.get(leftDevice, ['manufacturer'], '')
      }).then(response => {
        const { deviceModelsLeft, deviceModelsRight } = response
        const { leftModelId, rightModelId } = this.props.query
        if (
          (rightDevice && rightModelId !== rightDevice.id) ||
          (leftDevice && leftDevice.id !== leftModelId)
        ) {
          this.fetchFeaturesForDevices({
            leftModelId: leftDevice,
            rightModelId: rightDevice
          })
        }

        const hearingPrograms = hdPrograms.reduce(
          (acc, program) => (acc = [...acc, program.name]),
          []
        )
        const leastD11PlatformNumber = 21
        const isD11 =
          (leftDevice && Number(leftDevice.platform) >= leastD11PlatformNumber) ||
          (rightDevice && Number(rightDevice.platform) >= leastD11PlatformNumber)
        const customPrograms = getCustomPrograms(hdPrograms, HDModelsFeatures)
        const newState = {
          isPagePrefilled: true,
          practice: practiceId,
          employee: employeeId,
          deviceModelRightId: rightDevice ? rightDevice.id : 0,
          deviceModelLeftId: leftDevice ? leftDevice.id : 0,
          selectedBrandLeft: leftDevice ? leftDevice.manufacturer : '',
          selectedBrandRight: rightDevice ? rightDevice.manufacturer : '',
          manufacturers,
          HDModelsFeatures,
          deviceModelsLeft,
          deviceModelsRight,
          customPrograms,
          hearingPrograms,
          teleCareVersion,
          bluetoothDisabled: HIDSetting.bluetoothDisabled || this.state.bluetoothDisabled,
          canceled: false,
          bluetoothSupported: HDModelsFeatures.bluetoothSupported || this.state.bluetoothSupported,
          isD11
        }

        this.setState(newState)
      })
    }
  }

  fetchDefaultListOfHDModels = async ({ brandRight = '', brandLeft = '' }) => {
    let deviceModelsRight = [],
      deviceModelsLeft = []
    const { data: dataRight } = await this.props.DeviceModelsQuery.refetch({
      brand: brandRight,
      hasBrand: !!brandRight
    })
    const { data: dataLeft } = await this.props.DeviceModelsQuery.refetch({
      brand: brandLeft,
      hasBrand: !!brandLeft
    })
    deviceModelsRight = _.isNil(dataRight) ? [] : dataRight.HDModels
    deviceModelsLeft = _.isNil(dataLeft) ? [] : dataLeft.HDModels
    return {
      deviceModelsLeft,
      deviceModelsRight
    }
  }

  onSelectDeviceModel = ({ deviceType, deviceModelId }) => {
    // prepopulate the left device when right is selected
    const updateDevices = getLeftRightDevice(deviceType, deviceModelId, this.state)
    const newState =
      this.state.deviceModelsLeft && this.state.deviceModelsLeft.length > 0
        ? {
          isEditing: true,
          deviceModelsLeft: this.state.deviceModelsLeft,
          deviceModelsRight: this.state.deviceModelsRight,
          ...updateDevices
        }
        : {
          isEditing: true,
          deviceModelsLeft: this.state.deviceModelsLeft,
          deviceModelsRight: this.state.deviceModelsRight,
          deviceModelRightId: updateDevices.deviceModelRightId,
          deviceModelLeftId: '0'
        }
    this.setState({ ...newState }, () => {
      this.fetchFeaturesForDevices({
        leftModelId: this.state.deviceModelLeftId,
        rightModelId: this.state.deviceModelRightId
      })
    })
  }

  fetchHdDevices = async ({ selectedBrandPosition, brand }) => {
    const { data } = await this.props.DeviceModelsQuery.refetch({
      brand,
      hasBrand: !!brand
    })
    if (data && data.HDModels) {
      const modifiedHDModels = data.HDModels
      // when changing the right brand the left brand and models should inherit this settings.
      // when changing left brand only the left brand and model should be updated
      const selectedDeviceModels = {
        ...(selectedBrandPosition === 'BrandRight'
          ? {
            deviceModelsRight: modifiedHDModels,
            deviceModelsLeft: modifiedHDModels,
            // preselect first device model
            deviceModelLeftId: _.get(modifiedHDModels, [0, 'id'], null),
            deviceModelRightId: _.get(modifiedHDModels, [0, 'id'], null)
          }
          : {}),
        ...(selectedBrandPosition === 'BrandLeft'
          ? {
            deviceModelsLeft: modifiedHDModels,
            // preselect first device model
            deviceModelLeftId: _.get(modifiedHDModels, [0, 'id'], null),
            deviceModelRightId: this.state.selectedBrandRight
              ? this.state.deviceModelRightId
              : null,
            deviceModelsRight: this.state.selectedBrandRight ? this.state.deviceModelsRight : []
          }
          : {})
      }
      this.setState(
        {
          ...selectedDeviceModels
        },
        () => {
          this.fetchFeaturesForDevices({
            leftModelId: this.state.deviceModelLeftId,
            rightModelId: this.state.deviceModelRightId
          })
        }
      )
    }
  }
  fetchFeaturesForDevices = ({ leftModelId = '0', rightModelId = '0' }) => {
    try {
      const { Customer: { hearingDevices: { hdPrograms } } } = this.props
      getHdDeviceModelsFeatures(leftModelId, rightModelId).then(HDModelsFeatures => {
        const customPrograms = getCustomPrograms(hdPrograms, HDModelsFeatures)
        this.setState({
          hearingPrograms: [],
          HDModelsFeatures,
          customPrograms,
          bluetoothSupported: HDModelsFeatures.bluetoothSupported,
          teleCareVersion: _.get(HDModelsFeatures, ['defaultAvailableTeleCareVersion'], null)
        })
      })
    } catch (e) {
      tracker.track('AppError', { error: e })
    }
  }

  setFormRef = ({ name, form }) => (this[name] = form)

  onHIDSettingChange = () => {
    const { bluetoothDisabled } = this.state
    this.setState({
      bluetoothDisabled: !bluetoothDisabled,
      isEditing: true
    })
  }

  getListOfDeviceModels = (defaults = []) => {
    const { deviceModelsLeft = defaults, deviceModelsRight = defaults } = this.state

    return {
      deviceModelsLeft,
      deviceModelsRight
    }
  }
  onCancel = () => {
    this.setState({ isEditing: false, isPagePrefilled: false, canceled: true })
    this.props.refetch()
  }
  onPatientEdited = () => {
    this.props.PatientQuery.refetch().then(() => {
      this.setState({
        isSaving: false,
        isEditing: false
      })
    })
    const dataObj = {
      uuid: this.props.Customer.uuid,
      brandDisplayId: this.props.Customer.brandDisplayId,
      hearingDevices: {
        leftDevice: {
          ...(this.state.deviceModelLeftId && this.state.deviceModelsLeft.find(a => a.id === this.state.deviceModelLeftId))
        },
        rightDevice: {
          ...(this.state.deviceModelRightId && this.state.deviceModelsRight.find(a => a.id === this.state.deviceModelRightId))
        }
      }
    }
    sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(dataObj), EventType.HearingAidsChange)
  }
  onHearingProgramChange = hearingPrograms => {
    this.setState({ hearingPrograms, isEditing: true })
  }
  handleFormBrandSettignsValidChange = isFormValid => {
    this.setState({ isBrandSettingsFormValid: isFormValid })
  }

  handleFormDeviceSettignsValidChange = isFormValid => {
    this.setState({
      isDeviceSettingsFormValid: isFormValid
    })
  }
  addHidSettingInfo = id => {
    const patientId = parseInt(id)
    const { bluetoothDisabled } = this.state
    const hidSettingData = {
      patientId,
      bluetoothDisabled
    }
    this.props.updateHIDSetting({ hidSetting: hidSettingData })
  }
  editPatient = async () => {
    const valid = canEditPatient(this.state)
    if (valid) {
      const {
        Customer: { firstName, lastName, phone, id, practiceId, employeeId },
      } = this.props
      //check if we need persistent configuration
      const persistentSettings = getPersistentConfiguration(this.state.teleCareVersion)
      const patientId = parseInt(id, 10)

      const editPatientPayload = {
        firstName,
        lastName,
        phone,
        leftDevice: parseInt(this.state.deviceModelLeftId) || 0,
        rightDevice: parseInt(this.state.deviceModelRightId) || 0,
        ...persistentSettings,
        practiceId: parseInt(practiceId),
        employeeId: parseInt(employeeId),
        programs: getHearingPrograms(this.state)
      }
      this.setState(
        {
          isSaving: true
        },
        () => {
          this.addHidSettingInfo(patientId)
          this.props
            .updatePatient({ patientId, patient: editPatientPayload })
            .then(data => {
              const { location = {} } = this.props
              this.props.PatientHIsQuery.refetch()
              customerTracking({
                ...location,
                action: 'customerUpdated'
              })
              return data
            })
            .then(this.onPatientEdited)
            .catch(error => {
              tracker.track('ErrorUpdatingCustomer', {
                ...error
              })
            })
        }
      )
    }
  }
  onBrandSelect = ({ brand = '', brandPosition, selectedBrandPosition }) => {
    const selectedManufacturer = getBrandByName(brand, this.props.manufacturers)
    const { intl: { formatMessage } } = this.props
    const defaultMessage = formatMessage(T('hearing_device.manufacturer'))
    const selectedBrands = {
      ...(selectedBrandPosition === 'BrandRight'
        ? {
          selectedBrandRight: brand || null,
          selectedBrandLeft: brand || null
        }
        : {}),
      ...(selectedBrandPosition === 'BrandLeft'
        ? {
          selectedBrandLeft: brand || null,
          selectedBrandRight:
            _.isNil(this.state.selectedBrandRight) && defaultMessage != brand
              ? defaultMessage
              : this.state.selectedBrandRight == defaultMessage ||
                (selectedManufacturer.pairable &&
                  selectedManufacturer.pairable.indexOf(this.state.selectedBrandRight) < 0)
                ? null
                : this.state.selectedBrandRight
        }
        : {})
    }
    if (_.isEmpty(selectedManufacturer)) {
      // reset device and brand selection for nonexisting brand
      const resetDeviceSettings = getResetDeviceSettings(selectedBrandPosition)
      return this.setState({
        isEditing: true,
        [brandPosition]: this.props.manufacturers,
        ['manufacturersRight']:
          selectedBrandPosition === 'BrandRight'
            ? this.props.manufacturers
            : this.state.manufacturersRight,
        ...selectedBrands,
        ...resetDeviceSettings,
        hearingPrograms: []
      })
    }
    const disabledManufacturers = _.map(this.props.manufacturers, item => {
      return {
        disabled:
          selectedManufacturer.name !== item.name &&
          selectedManufacturer.pairable.indexOf(item.name) < 0,
        ...item
      }
    })
    this.setState({
      [brandPosition]: disabledManufacturers,
      ...selectedBrands,
      isBrandSettingsFormValid: false,
      isEditing: true
    })
    this.fetchHdDevices({ selectedBrandPosition, brand })
  }

  render() {
    const { intl: { formatMessage }, manufacturers, deviceModels, loading, Customer: { cxxId } } = this.props
    if (loading || !this.state.isPagePrefilled || this.state.isSaving || this.state.canceled) {
      return (
        <Loading
          isLoading={
            loading || !this.state.isPagePrefilled || this.state.isSaving || this.state.canceled
          }
        />
      )
    }
    const {
      deviceModelsLeft: defaultModelsLeft,
      deviceModelsRight: defaultModelsRight
    } = this.getListOfDeviceModels(deviceModels)

    const {
      deviceModelsLeft = defaultModelsLeft,
      deviceModelsRight = defaultModelsRight,
      deviceModelLeftId,
      deviceModelRightId,
      bluetoothDisabled,
      HDModelsFeatures,
      isEditing,
      selectedBrandLeft,
      selectedBrandRight,
      hearingPrograms = [],
      customPrograms,
      isD11
    } = this.state

    const { manufacturersLeft, manufacturersRight } = getListOfManufacturers(
      manufacturers,
      formatMessage,
      this.state
    )
    const availablePrograms = _.get(HDModelsFeatures, 'availablePrograms', [])
    const programSlots = _.get(HDModelsFeatures, 'programSlots', 0) || 0
    const defaultPrograms = _.get(HDModelsFeatures, 'defaultPrograms', []) || []
    const { shouldShowBluetooth, bluetoothSupported } = this.state
    const isPatientCreatedByConnexx = !!cxxId
    return (
      <section data-tip data-for="section">
        <div className={`${isPatientCreatedByConnexx ? "disabled" : ""}`}>
          <Form onSubmit={this.editPatient}>
            <div className="row">
              <div className="col-lg-12">
                <HearingInstrumentHeadLine error={this.props.error} />
                <NewBrandSettings
                  formatMessage={formatMessage}
                  setFormRef={this.setFormRef}
                  onValidationChange={this.handleFormBrandSettignsValidChange}
                  onSelect={this.onBrandSelect}
                  manufacturersLeft={manufacturersLeft}
                  manufacturersRight={manufacturersRight}
                  selectedBrandLeft={selectedBrandLeft}
                  selectedBrandRight={selectedBrandRight}
                />
                <DeviceModelSettings
                  formatMessage={formatMessage}
                  onValidationChange={this.handleFormDeviceSettignsValidChange}
                  onSelect={this.onSelectDeviceModel}
                  setFormRef={this.setFormRef}
                  deviceModelsLeft={deviceModelsLeft}
                  deviceModelsRight={deviceModelsRight}
                  deviceModelLeftId={deviceModelLeftId}
                  deviceModelRightId={deviceModelRightId}
                />
                {shouldShowBluetooth &&
                  bluetoothSupported &&
                  <HIDBluetoothSetting
                    onChange={this.onHIDSettingChange}
                    checked={bluetoothDisabled}
                  />}
                {programSlots > 0 &&
                  availablePrograms.length > 0 && [
                    <Divider key="divider-hearing-programs" />,
                    <HearingPrograms
                      key="hearing-programs"
                      onChange={this.onHearingProgramChange}
                      programSlots={programSlots}
                      defaultPrograms={defaultPrograms}
                      hearingPrograms={availablePrograms}
                      selectedValues={hearingPrograms}
                      customPrograms={customPrograms}
                      isD11={isD11}
                    />
                  ]}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ActionButtonsEdit>
                  <Button
                    secondary
                    onClick={this.onCancel}
                    className="btn--min-width"
                    data-qa="cancel-button"
                    disabled={!isEditing}
                  >
                    {formatMessage(T('common.cancel'))}
                  </Button>
                  <Button
                    type="submit"
                    primary
                    disabled={!(isEditing && canEditPatient(this.state))}
                    className="btn--min-width"
                    data-qa="save-button"
                  >
                    {formatMessage(T('common.save'))}
                  </Button>
                </ActionButtonsEdit>
              </div>
            </div>
          </Form>
        </div>
        {isPatientCreatedByConnexx && (
          <Tooltip id="section">
            {formatMessage(T('hearing_device.note_mouseover'))}
          </Tooltip>
        )}
      </section>
    )
  }
}

const withData = compose(
  graphql(PATIENT_QUERY, {
    name: 'PatientQuery',
    props: mapProps,
    options
  }),
  graphql(QUERY_PATIENT_HIS, {
    name: 'PatientHIsQuery',
    props: mapPropsPatientHIs,
    options
  }),
  graphql(QUERY_HDMODELS, {
    name: 'DeviceModelsQuery',
    props: mapPropsDeviceModels,
    options
  }),
  graphql(QUERY_HDMODELS_FEATURES, {
    name: 'DeviceModelsFeaturesQuery',
    props: mapPropsDeviceModelsFeatures,
    options
  }),
  graphql(MUTATION_UPDATE_PATIENT, {
    props: ({ mutate }) => ({
      updatePatient: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_ADD_HID_SETTING, {
    props: ({ mutate }) => ({
      updateHIDSetting: variables =>
        mutate({
          variables
        })
    })
  })
)

export default withRouter(withQuery(withData(injectIntl((HearingInstrument)))))
