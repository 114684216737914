// @flow
import { tracker } from 'core'

type Track = {
  action: string,
  url: string,
  language: string,
  countryCode: string
}
export const customerTracking = ({
  locale = '',
  countryCode = '',
  action = '',
  ...rest
}: {
  pathname?: string,
  search?: string,
  locale?: string,
  countryCode?: string,
  action: string,
  rest?: Object
}) => {
  const language = locale.split('-')[0]
  const url = window.location.href
  try {
    tracker.track(
      'customerAction',
      ({
        action,
        url,
        language,
        countryCode,
        ...rest
      }: Track)
    )
  }
  catch (e) { console.log("Could not track: ", action) }
}
