import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { T } from 'core'

import Modal from 'components/Popups'
import { compose } from 'lodash/fp'
import { SHOW_POPUP_QUERY } from 'graphql/queries/ShowPopUpQuery'
import { DELETE_PATIENT_POPUP } from 'components/Popups/helper/OverlayUpdateData'
import { Button } from 'styledComponents/Button'
import { StyledPopupFontAwesomeIcon } from "styledComponents/Icon/StyledPopupFontAwesomeIcon" 
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export const Content = ({ closePortal, confirm, popup }) => {
  const payload = popup ? popup.payload : null
  return (
    payload &&
    <div data-qa="confirm-delete" className="popup--confirm-delete">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <StyledPopupFontAwesomeIcon icon={faTrash} type="popupIcon" fixedWidth size="2x" className="popup-icon"/>
            <h1 className="popup_title" data-qa="confirm-delete-title">
              <FormattedMessage {...T(payload.title)} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div data-qa="confirm-delete-message">
              <FormattedMessage {...T(payload.message)} />
            </div>
          </div>
        </div>
      </div>
      <div className="row button-area">
        <div className="col-6">
          <Button
            secondary
            type="button"
            data-qa="confirm-delete-cancel-button"
            className="btn-block"
            onClick={closePortal}
          >
            <FormattedMessage {...T('common.cancel')} />
          </Button>
        </div>
        <div className="col-6">
          <Button
            primary
            type="button"
            data-qa="confirm-delete-ok-button"
            className="btn-block"
            onClick={confirm}
          >
            <FormattedMessage {...T('common.ok')} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export class ConfirmDeletionPopup extends Component {
  render() {
    const {
      openByClickOn,
      onClose,
      popup,
      isOpen,
      onOpen,
      removePatient,
      id,
      ...restProps
    } = this.props
    const isOpened =
      popup &&
      (id ? popup.type === DELETE_PATIENT_POPUP + id : popup.type === DELETE_PATIENT_POPUP) &&
      isOpen
    return (
      <Modal openByClickOn={openByClickOn} onOpen={onOpen} isOpened={isOpened} onClose={onClose}>
        <Content {...restProps} popup={popup} confirm={removePatient} />
      </Modal>
    )
  }
}
export const mapProps = ({ data: { overlay } }) => {
  return { popup: overlay?.popup, isOpen: overlay?.isOpen }
}
const withData = compose(
  graphql(SHOW_POPUP_QUERY, {
    props: mapProps
  })
)

export default withData(injectIntl(ConfirmDeletionPopup))
