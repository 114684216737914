import _ from 'lodash'

import { parsePhoneNumber } from 'helper/Utils'

/**
 * Parsing phone number by countryCode
 * @param phone (e.g. 01523 7130000)
 * @param countryCode (e.g. "DE")
 * @returns {string} (e.g. +4915237130000)
 */
export const parsePhone = (phone, countryCode = '') => {
  if (!phone) return ''

  return countryCode ? parsePhoneNumber(_.trim(phone), countryCode.toLowerCase()) : phone
}
