import { gql } from "@apollo/client";

export const APP_QUERY = gql`
  query AppQuery {
    Me {
      employee {
        id
        email
        emailVerified
        phone
        phoneVerified
        avatar {
          id
          url
        }
        firstName
        lastName
        manager
        orgManager
        defaultPracticeId
        username
        assignedPracticeIds
      }
      organizationId
      countryCode
    }
  }
`