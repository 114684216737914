// @flow
import { get, trim, isEmpty, isNull, pick, isUndefined } from 'lodash'
import { getFlagSettings } from 'helper/FFlags'
import { T } from 'core'

type FormPersonalInfoType = {
  getValues: Function,
  getFieldsValidationState: Function
}

export const isPersonalFormValid = (formPersonalInfo: FormPersonalInfoType): boolean => {
  // control personalForm validation in HOC
  const personalInfoStates = formPersonalInfo.getFieldsValidationState
    ? formPersonalInfo.getFieldsValidationState()
    : {}
  const isFirstnameValid = get(personalInfoStates, ['firstName', 'isValid'], false)
  const isPhoneValid = get(personalInfoStates, ['phone', 'isValid'], false)
  const firstName = get(formPersonalInfo.getValues(), 'firstName', '')
  return isFirstnameValid && !isEmpty(trim(firstName)) && isPhoneValid
}

export const areProgramsValid = (
  programs: Array<string> = [],
  remoteFitting: boolean = true
): boolean => programs.length > 0 || remoteFitting === false

export const getProgramsPayload = (state: {
  hearingPrograms: Array<string>,
  HDModelsFeatures: {
    defaultPrograms?: ?Array<string>
  },
  selectedBrandLeft: string,
  selectedBrandRight: string
}) => {
  if (!state) {
    return []
  }
  const {
    hearingPrograms = [],
    HDModelsFeatures = {},
    selectedBrandRight,
    selectedBrandLeft
  } = state
  if ([selectedBrandLeft, selectedBrandRight].includes('Other')) {
    /* Other manufactures do not have programs*/
    return []
  }
  const selectedPrograms = hearingPrograms.length > 0 ? hearingPrograms : null
  const defaultProgram = get(HDModelsFeatures, ['defaultPrograms', '0'], null)
  return selectedPrograms || (!!defaultProgram ? [defaultProgram] : [])
}

export const getBluetoothSettings = employeeId =>
  getFlagSettings(employeeId).then(result => {
    return result.items['disable-tele-care-3-0']._value
  })

export const getRemoteFittingSupport = state => {
  const { HDModelsFeatures = {} } = state
  return get(HDModelsFeatures, 'remoteFittingSupported', false)
}

export const canEditPatient = state => {
  const { isDeviceSettingsFormValid = false, deviceModelRightId = 0, deviceModelLeftId = 0 } = state
  const isHearingFormProgramValid = areProgramsValid(
    getProgramsPayload(state),
    getRemoteFittingSupport(state)
  )
  return (
    isDeviceSettingsFormValid &&
    isHearingFormProgramValid &&
    (!!deviceModelRightId || !!deviceModelLeftId)
  )
}

export const shouldShowBluetoothSettings = async (employeeId = 0, HDModelsFeatures = {}) => {
  const bluetoothStatusFlag = await getBluetoothSettings(employeeId)
  const { bluetoothSupported } = HDModelsFeatures
  if (bluetoothStatusFlag) {
    return isUndefined(bluetoothSupported) || isNull(bluetoothSupported)
      ? false
      : bluetoothSupported
  } else {
    return false
  }
}

export const getListOfManufacturers = (defaults, formatMessage, state) => {
  const { manufacturersLeft = defaults, manufacturersRight = defaults } = state
  return {
    manufacturersLeft: [
      { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
      ...manufacturersLeft
    ],
    manufacturersRight: [
      { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
      ...manufacturersRight
    ]
  }
}

export const getHearingPrograms = state => {
  const { customPrograms, HDModelsFeatures } = state
  const availablePrograms = get(HDModelsFeatures, 'availablePrograms', [])
  var programsType = getProgramsPayload(state)
  const programsData =
    customPrograms[0].length !== 0
      ? programsType.map(item => {
          var programIndex = availablePrograms.findIndex(name => name === item)
          if (programIndex > -1) {
            return item
          } else {
            var hdPrograms = customPrograms.find(
              programs => programs && programs.find(data => data && data.name === item)
            )
            var program = hdPrograms && hdPrograms.find(data => data && data.name === item)
            return program.type
          }
        })
      : state.hearingPrograms
  return programsData
}

export const getPersistentConfiguration = teleCareVersion => {
  if (isEmpty(teleCareVersion) || isNull(teleCareVersion)) {
    return teleCareVersion
  }

  const persistentSettings = {
    TELECARE_2_0: {
      persistentConfiguration: true,
      teleCareVersion: 'TELECARE_2_0'
    },
    TELECARE_1_0_PERSISTENT: {
      persistentConfiguration: true,
      teleCareVersion: 'TELECARE_1_0_PERSISTENT'
    },
    TELECARE_1_0_TEMPORARY: {
      persistentConfiguration: false,
      teleCareVersion: 'TELECARE_1_0_TEMPORARY'
    }
  }

  return persistentSettings[teleCareVersion]
}

export const getResetDeviceSettings = selectedBrandPosition => {
  return {
    ...(selectedBrandPosition === 'BrandRight'
      ? {
          deviceModelRightId: null,
          deviceModelLeftId: null,
          deviceModelsLeft: [],
          deviceModelsRight: []
        }
      : {}),
    ...(selectedBrandPosition === 'BrandLeft'
      ? {
          deviceModelLeftId: null,
          deviceModelsLeft: []
        }
      : {})
  }
}

export const getBrandByName = (name, manufacturers = {}) => {
  const brand = manufacturers.find(item => item.name === name)
  return pick(brand, ['name', 'pairable'])
}

export const getCustomPrograms = (hdPrograms, HDModelsFeatures) => {
  const customPrograms = hdPrograms.reduce(
    (acc, program) => {
      if (HDModelsFeatures) {
        var programIndex = HDModelsFeatures.availablePrograms.findIndex(
          name => name === program.type
        )
        var customProgram = []
        if (programIndex > -1) {
          customProgram.name = program.name
          customProgram.type = program.type
          acc[program.programNumber] = []
          acc[program.programNumber][programIndex] = customProgram
        }
      }
      return acc
    },
    [[]]
  )
  return customPrograms
}
