import styled from 'styled-components'
import Colors from 'themes/default/colors'

export const Subnavigation = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`

const getBarColor = props => {
  switch (props.indicator) {
    case 'average':
    case 'neutral':
      return Colors.brandNeutral
    case 'positive':
      return Colors.brandSuccess
    case 'negative':
      return Colors.brandDanger
  }
}

export const RowKpi = styled.div`
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`

export const DashboardKpisGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Kpi = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  .kpi {
    &__header {
      background-color: ${props => props.theme.colors.kpiHeader};
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: 700;
      font-size: 16px;
      height: 60px;
      line-height: 59px;
      padding: 0 20px;
      vertical-align: top;
      display: flex;
      > div {
        flex: 3;
      }
      > div:nth-child(2) {
        flex: 1;
      }
    }

    &__stats {
      color: ${props => props.theme.colors.brandNeutral};
      height: 60px;
      text-align: right;
      position: relative;
    }

    &__stats-value {
      position: absolute;
      right: 50px;
      width: 50px;
    }

    &__stats-delta {
      font-size: 12px;
      position: absolute;
      top: 1px;
      right: 0px;
      width: 40px;

      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 12px;
        margin-left: 4px;
      }

      &--up:after {
        content: '\f0d8';
      }

      &--down:after {
        content: '\f0d7';
      }

      &--positive:after {
        color: ${props => props.theme.colors.brandSuccess};
      }

      &--negative:after {
        color: ${props => props.theme.colors.brandDanger};
      }
    }

    &__text--truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__body {
      border: 1px solid #ddd;
      border-top-color: #999;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 15px;
    }

    &--patientSatisfaction &__stats-value:before {
      content: '\f118';
    }
    &--processDuration &__stats-value:before {
      content: '\f017';
    }

    &--patientsGained &__stats-value:before {
      content: '\f234';
    }

    &--patientsOverall &__stats-value:before {
      content: '\f2be';
    }

    &--successRate &__stats-value:before {
      content: '\f091';
    }
  }

  .custom-tooltip {
    margin-top: -5px;
  }
`

export const DashboardTooltip = styled.div`
  font-size: 14px;
  padding: 10px;

  &__headline {
    font-size: 16px;
    font-weight: 700;
  }

  &__subheadline {
    color: ${props => props.theme.colors.brandNeutral};
    margin-bottom: 10px;
  }

  &__title {
    color: #ccc;
  }

  &__subtitle {
    color: #808080;
  }
`

export const KpiBarLegend = styled.div`
  color: ${props => props.theme.colors.brandNeutral};
  display: flex;
  > div {
    flex: 1;
  }
  > div:nth-child(2) {
    flex: 3;
    display: flex;
    justify-content: space-between;
    &.align-right {
      justify-content: flex-end;
    }
  }
  > div:nth-child(1) {
    flex: 2;
  }

  .text--truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
`

export const KpiBarLabel = styled.div`
  font-weight: 700;
  height: 24px;
  line-height: 23px;
  color: ${props => (props.indicator === 'average' ? props.theme.colors.brandNeutral : 'inherit')};
`

export const KpiBarValue = styled.div`
  color: ${props => props.theme.colors.brandNeutral};
  font-weight: 700;
  height: 24px;
  line-height: 23px;
`

export const KpiBarBar = styled.div`
  border: none;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  padding: 4px 0;
  position: relative;
  .outer {
    background-color: #f0f0f0;
    &: ${props => (props.indicator === 'average' ? 'after' : '')} {
      border-top: 1px dashed #d60925;
      content: "";
      height: 8px;
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
      z-index: 100;
    }
  }
  .inner {
    height: 16px;
    background-color: ${props => getBarColor(props)};
  }
`

export const KpiBar = styled.div`
  > div:nth-child(1) {
    display: flex;
    > div {
      flex: 1;
      text-align: center;
    }
    > div:nth-child(2) {
      flex: 3;
    }
    > div:nth-child(1) {
      flex: 2;
      text-align: left;
    }
  }

  .kpi-bar__text--truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .kpi-bar {
    &--positive &__inner--opacity-0,
    &--negative &__inner--opacity-0 {
      opacity: 1;
    }
    &--positive &__inner--opacity-1,
    &--negative &__inner--opacity-1 {
      opacity: .8;
    }
    &--positive &__inner--opacity-2,
    &--negative &__inner--opacity-2 {
      opacity: .7;
    }
    &--positive &__inner--opacity-3,
    &--negative &__inner--opacity-3 {
      opacity: .65;
    }
    &--positive &__inner--opacity-4,
    &--negative &__inner--opacity-4 {
      opacity: .6;
    }
    &--positive &__inner--opacity-5,
    &--negative &__inner--opacity-5 {
      opacity: .55;
    }
    &--positive &__inner--opacity-6,
    &--negative &__inner--opacity-6 {
      opacity: .5;
    }
    &--positive &__inner--opacity-7,
    &--negative &__inner--opacity-7 {
      opacity: .45;
    }
    &--positive &__inner--opacity-8,
    &--negative &__inner--opacity-8 {
      opacity: .4;
    }
    &--positive &__inner--opacity-9,
    &--negative &__inner--opacity-9 {
      opacity: .35;
    }
    &--positive &__inner--opacity-10,
    &--negative &__inner--opacity-10 {
      opacity: .3;
    }
  }
`
