import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { notEqual } from 'helper/Utils'
import { range, get } from 'lodash'
import { T } from 'core'
import _ from 'lodash'

import { d10_d11_conversion } from '../../../components/_Profile/components/ProgramSelector/ProgramMap'

import Dropdown from 'components/Dropdown'
import FormGroup from 'components/FormGroup'
import { Programs } from 'styledComponents/Div/DivComponent/DivPatient'

const DefaultHearingProgramsItem = ({ defaultDropdownTitle, helpText = '', index = 0 }) => {
  const isDisabled = true
  return (
    <div className="col-4 select-dropdown">
      <FormGroup
        id={`hearingPrograms-${index}`}
        helpText={helpText}
        label={<FormattedMessage {...T('common.program')} values={{ name: index + 1 }} />}
      >
        <Dropdown
          defaultDropdownTitle={defaultDropdownTitle}
          isDisabled={isDisabled}
          height={200}
          className={`${isDisabled ? 'disabled' : ''}`}
          data-qa={`program-${index}`}
        />
      </FormGroup>
    </div>
  )
}
export const HearingProgramsItem = ({
  index,
  hearingPrograms,
  onSelect,
  helpText,
  defaultDropdownTitle,
  selectedValues = [],
  customPrograms = [],
  isD11
}) => {
  // unblock only the next after selected one
  // check if selected array contains value else check if hearing programs are not null else return null
  var item = _.isNil(selectedValues[index])
    ? _.isNil(hearingPrograms[0]) ? null : hearingPrograms[0].value
    : selectedValues[index]
  item = isD11 ? d10_d11_conversion.get(item) || item : item
  var isDisabled = index > selectedValues.length

  var customHearingPrograms = hearingPrograms.map((program, key) => {
    var value = customPrograms[index]
      ? customPrograms[index][key] ? customPrograms[index][key].name : program
      : program
    value = isD11 ? d10_d11_conversion.get(value) || value : value
    const locale = { ...T(`hearing_programs.${value.toLowerCase()}`) }
    return {
      value: value,
      text: locale.key
        ? <FormattedMessage {...T(`hearing_programs.${value.toLowerCase()}`)} />
        : value
    }
  })
  var customHearingProgramSet = isD11
    ? _.uniqBy(customHearingPrograms, 'value')
    : customHearingPrograms
  customHearingProgramSet.unshift({
    value: '',
    text: <FormattedMessage {...T(`common.select`)} />
  })
  return (
    <div className="col-4 select-dropdown">
      <FormGroup
        id={`hearingPrograms-${index}`}
        helpText={helpText}
        label={<FormattedMessage {...T('common.program')} values={{ name: index + 1 }} />}
      >
        <Dropdown
          defaultDropdownTitle={defaultDropdownTitle}
          isDisabled={isDisabled}
          height={200}
          className={`${isDisabled ? 'disabled' : ''}`}
          onSelect={onSelect}
          options={customHearingProgramSet}
          data-qa={`program-${index}`}
          selected={item || ''}
        />
      </FormGroup>
    </div>
  )
}

export class HearingPrograms extends Component {
  state = {
    programs: undefined
  }

  shouldComponentUpdate(nextProps, nextState) {
    return notEqual(nextProps, this.props) || notEqual(nextState, this.state)
  }

  getProgramById(id) {
    return this.props.hearingPrograms.find(item => item.value === id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hearingPrograms.length > 0) {
      this.setState({ programs: nextProps.selectedValues })
    }
  }

  getCurrentPrograms = ({ prevState, props }) => {
    // there are 6 channels in total for which we can selected programs
    const programsFromState = get(prevState, ['programs'], []) || [] // contains component internal previous state before updating to a new state ( filled in with selectedValues in edit )
    const selectedValues = get(props, ['selectedValues', []]) || [] // contains selected values for channel 2 to 6 ( this is prefilled when editing a patient )
    const programsForPreselectedChannels = get(props, ['defaultPrograms'], []) || [] // contains business decided values for preselected channel ( currently channel 1 )

    let programs = programsFromState.length > 0 ? programsFromState : selectedValues
    programs = programs.length > 0 ? programs : programsForPreselectedChannels // allways populated
    return [...programs]
  }
  /**
   * Returns values until first empty string value
   * E.g. [1,2,3, '', 5, 6] returns [1,2,3]
   * @param index
   * @param value
   */
  onChange = index => value => {
    const { onChange } = this.props
    this.setState(
      (prevState, props) => {
        const programs = this.getCurrentPrograms({ prevState, props })
        programs[index] = value
        return { programs: value === '' ? programs.slice(0, index) : programs }
      },
      () => {
        if (onChange) {
          onChange(this.state.programs)
        }
      }
    )
  }

  render() {
    const {
      intl: { formatMessage },
      programSlots = 0,
      defaultPrograms = [],
      selectedValues = [],
      isD11
    } = this.props
    const preFillPrograms = selectedValues.length > 0 ? selectedValues : defaultPrograms

    const { programs = [...preFillPrograms] } = this.state
    const defaultSelectedSlots = defaultPrograms.length
    const availableSlots = programSlots - defaultSelectedSlots
    const defaultProgram = get(defaultPrograms, 0, '')
    const { customPrograms, hearingPrograms } = this.props
    return (
      <article className="hearingPrograms" data-qa="hearing-programs">
        <div className="row">
          <div className="col-12">
            <h2 data-qa="programs-title">
              <FormattedMessage {...T('hearing_programs.title')} />
            </h2>
          </div>
        </div>
        <Programs className="row">
          {defaultSelectedSlots > 0 &&
            <DefaultHearingProgramsItem
              defaultDropdownTitle={formatMessage(
                T(`hearing_programs.${defaultProgram.toLowerCase()}`)
              )}
              helpText={formatMessage(T('validator.required'))}
            />}
          {range(defaultSelectedSlots, programSlots).map(index => {
            return (
              (_.size(programs) >= 0 || _.size(customPrograms) >= 0) &&
              <HearingProgramsItem
                index={index}
                selectedValues={programs}
                onSelect={this.onChange(index)}
                hearingPrograms={hearingPrograms}
                customPrograms={customPrograms}
                defaultDropdownTitle={formatMessage(T('common.select'))}
                key={index}
                availableSlots={availableSlots}
                helpText={formatMessage(T('validator.required'))}
                isD11={isD11}
              />
            )
          })}
        </Programs>
      </article>
    )
  }
}

export default injectIntl(HearingPrograms)
