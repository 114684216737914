
import { EventEmitter, tracker } from 'core'
import connector from 'helper/HttpConnector'
import { v4 as uuidv4 } from 'uuid'
import { CALL_STATES, CallConstants } from '../callStates'
import { loadScripts } from 'components/LoadScript'
import { getToken } from 'helper/AuthHelper'
import { videoCallStatus, CorrelationContext } from 'helper/constants'
import { IConfig, IState } from 'typescript/interfaces/IAudioVIdeoCallManager'
import { getSupportId } from 'helper/ApplicationInsightSupportId'
import { DataCollectionType, CallTypeConstants, IcelinkLib } from 'helper/constants'
import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { callData } from 'helper/DataCollection/PayloadData'
import _ from 'lodash'
import moment from 'moment'

export default class AudioVideoCallManager extends EventEmitter {
  timeoutId = null
  callTimeout = 60 * 1000 // 45sec
  client = null
  remoteClient = null
  channels = null
  localMedia = null
  remoteMedia = null
  config: IConfig = {
    domainName: null,
    domainKey: null,
    websyncUrl: '',
    iceServers: [],
    sessionId: '123456',
    name: 'george'
  }

  state: IState = {
    inited: false,
    callType: 'VIDEO',
    callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
    error: false,
    videoMuted: false,
    audioMuted: false,
    remoteAudioMuted: false,
    remoteVideoMuted: false,
    remoteApplyingSettings: false,
    callStartTime: null,
    remoteCallState: '',
    supportId: getSupportId(),
  }

  setState(nstate: Object, callback?: Function) {
    this.state = {
      ...this.state,
      ...nstate
    }
    this.emit('change', this.state, nstate)
  }

  resetState() {
    this.setState({
      callType: 'VIDEO',
      callState: CALL_STATES.CLOSED, //connected, joined, remotejoined, closed  // reconnecting, streamFailure, conncectivityProblem, timedout
      error: false,
      videoMuted: false,
      audioMuted: false,
      remoteAudioMuted: false,
      remoteVideoMuted: false,
      callStartTime: null,
      remoteApplyingSettings: false,
      remoteCallState: ''
    })
  }

  constructor() {
    super()
    //load scripts
  }

  init() {
    const { inited } = this.state
    return new Promise<void>(resolve => {
      // if already initialized do nothing
      if (inited) {
        return resolve()
      }

      loadScripts([
        { url: '/assets/libs/icelink/fm.min.js' },
        { url: '/assets/libs/icelink/fm.websync.min.js' },
        { url: '/assets/libs/icelink/fm.websync.subscribers.min.js' },
        { url: '/assets/libs/icelink/fm.websync.chat.min.js' },
        { url: '/assets/libs/icelink/fm.icelink.js' },
        { url: '/assets/libs/icelink/fm.icelink.websync4.js' }
      ]).then(() => {
        this.channels = new fm.icelink.DataChannelCollection()
        fm.icelink.LocalMedia.setChromeExtensionId('nidjnlpklmpflfmfflalpddmadlgjckn')
        fm.icelink.Log.setProvider(new fm.icelink.ConsoleLogProvider(fm.icelink.LogLevel.Debug))
        window.fm.icelink.Log.registerProvider(new window.fm.icelink.ConsoleLogProvider(window.fm.icelink.LogLevel.Debug));
        this.setState({ inited: true }, () => {
          resolve()
        }
        )
      })
    })
  }

  track(stateName, attributes) {
    console.log('tracking', stateName, attributes)
    const me = this.me || {}
    const customer = this.customer || {}
    const config = this.config
    tracker.track(stateName, {
      organizationId: me.organizationId,
      employeeId: me.employee.id,
      customerId: customer.id,
      sessionId: config.sessionId,
      ...attributes
    })
  }

  trackError(errorObj) {
    console.log('TRACK Error', errorObj)
    if (errorObj && errorObj.getException) {
      errorObj = errorObj.getException()
    }
    this.track('PortalCallError', {
      error: errorObj.message || errorObj.name || 'unknown'
    })
  }

  startCallAndSetLocalMedia = async (isVideo: boolean, isAudioOnly: boolean, callType: string, sessionId: string) => {
    this.track('PortalCallInit', {
      sessionId: sessionId,
      callType: callType
    });

    await this.startLocalMedia({ video: isVideo, audio: true })
    await this.join()
    this.setState({ callState: CALL_STATES.JOINED })
    await this.sendInvite(this.customer, this.token, { audioOnly: isAudioOnly });
    return true
  }

  async startVideoCall({ customer = null, token = null, me = null } = {}) {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, supportId: getSupportId() })
      if (customer) this.customer = customer
      if (token) this.token = token
      if (me) this.me = me

      const connectionDetails = await this.getConfig();

      return await this.startCallAndSetLocalMedia(true, false, CallTypeConstants.video, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async recallVideo() {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, supportId: getSupportId() })
      const connectionDetails = await this.getConfig()
      this.setState({
        showCallEnded: false
      })

      return await this.startCallAndSetLocalMedia(true, false, CallTypeConstants.video, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async startAudioOnly({ customer = null, token = null, me = null } = {}) {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, callType: 'AUDIO', supportId: getSupportId() })
      if (customer) this.customer = customer
      if (token) this.token = token
      if (me) this.me = me

      const connectionDetails = await this.getConfig()

      return await this.startCallAndSetLocalMedia(false, true, CallTypeConstants.audio, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async recallAudio() {
    try {
      this.resetState()
      this.setState({ callState: CALL_STATES.CONNECTING, callType: 'AUDIO', supportId: getSupportId() })
      const connectionDetails = await this.getConfig()
      this.setState({
        showCallEnded: false
      })

      return await this.startCallAndSetLocalMedia(false, true, CallTypeConstants.audio, connectionDetails.sessionId);
    } catch (e) {
      this.trackError(e)
      this.setState({ callState: CALL_STATES.ERROR, error: e })
      return e
    }
  }

  async getConfig() {
    const token = this.token
    const callEndpoint = `/hcp/api/v1/config/video-call`
    const correlationContextType = this.state.callType === videoCallStatus.audio ? 'AudioVideoCall_AUDIO' : 'AudioVideoCall_VIDEO'
    const response = await connector.getWithCorrelation(token, callEndpoint, CorrelationContext[correlationContextType] + this.state.supportId)
    const iceServers = response.iceServers.map(server => {
      if (server.password && server.username) {
        return new fm.icelink.IceServer(server.url, server.username, server.password)
      }
      return new fm.icelink.IceServer(server.url)
    })
    const connectionDetails = {
      ...response,
      iceServers,
      sessionId: uuidv4()
    }
    this.config = {
      ...connectionDetails, name: 'george'
    }
    return connectionDetails
  }

  async sendInvite(customer, token, options = { audioOnly: false }) {
    let callEndpoint = '';
    let correlationContextType = 'AudioVideoCall_VIDEO'
    const data = { callType: 'VIDEO' }
    data['patientId'] = customer.uuid
    callEndpoint = `/api/video-call`

    if (options.audioOnly) {
      data['callType'] = 'AUDIO'
      correlationContextType = 'AudioVideoCall_AUDIO'
    }

    this.setState({
      callType: data.callType
    })

    if (this.config.sessionId) {
      data['sessionId'] = this.config.sessionId
    }

    try {
      await connector.postWithCorrelation(token, callEndpoint, CorrelationContext[correlationContextType] + this.state.supportId, data)
      return true
    } catch (e) {
      this.trackError(e)
      return e
    }
  }

  async startLocalMedia(options) {
    if (this.localMedia != null) {
      await this.stopLocalMedia()
    }

    try {
      const pluginConfig = new fm.icelink.PluginConfig()
      pluginConfig.setActiveXPath('https://v3.icelink.fm/FM.IceLink.ActiveX.cab')
      await fm.icelink.Plugin.install(pluginConfig)
      const audio = true ///new fm.icelink.AudioConfig(8000, 1)
      const video = options.video ? new fm.icelink.VideoConfig(640, 480, 30) : false
      this.localMedia = new fm.icelink.LocalMedia(audio, video, false)
      await this.localMedia.start()
      return this.localMedia
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async stopLocalMedia() {
    try {
      if (this.localMedia == null) {
        return null
      }
      await this.localMedia.stop()
      if (this.localMedia != null) {
        this.localMedia = null
      }
      return null
    } catch (e) {
      this.trackError(e)
      return e
    }
  }

  async connect() {
    return new Promise((resolve, reject) => {
      const { domainName, domainKey, websyncUrl, name, sessionId } = this.config
      this.sessionChannel = '/manual-signalling/' + sessionId
      this.metadataChannel = this.sessionChannel + '/metadata'
      if (!this.client) {
        this.client = new fm.websync.client(websyncUrl)
        this.client.setAutoDisconnect({ synchronous: true })
      }

      //TODO: unsubscribe
      this.client.subscribe({
        channel: this.metadataChannel,
        onReceive: e => {
          if (!e.getWasSentByMe()) {
            let jsonData = e.getData()
            this.onMessage('patient', jsonData)
          }
        },
        onFailure: e => {
          this.trackError(e)
        }
      })

      this.client.setDisableWebSockets(true)
      this.client.bind({
        record: {
          key: 'name',
          value: name
        }
      })

      if (domainName && domainName != '') {
        this.client.setDomainName(domainName)
      }

      if (domainKey && domainKey != '') {
        this.client.setDomainKeyString(domainKey)
      }

      if (!this.client.getIsConnected()) {
        this.client.connect({
          onSuccess: () => {
            this.setState({ callState: CALL_STATES.CONNECTED })
            resolve(true)
          },
          onFailure: e => {
            this.trackError(e)
            this.setState({ callState: CALL_STATES.ERROR, error: e })
            this.client.disconnect()
            this.stopLocalMedia()
            this.leave('Client Failed To Connect', true).catch(_ => { })
            reject(e)
          },
          onStreamFailure: e => {
            this.trackError(e)
            fm.icelink.Log.info('stream error')
            this.setState({ callState: CALL_STATES.STREAM_FAILURE })
            reject(e)
          }
        })
      } else {
        this.setState({ callState: CALL_STATES.CONNECTED })
        resolve(true)
      }
    })
  }

  async join() {
    try {
      await this.connect()
      if (!_.isEmpty(this.state.callType)) {
        await sendDataToDataCollection(DataCollectionType.PatientData, callData(this.customer, {
          callType: this.state.callType,
          lib: IcelinkLib,
          status: 'Started'
        }), EventType.Call)
      }
      const join = new fm.icelink.websync4.JoinConferenceArgs('/' + this.config.sessionId)
      fm.icelink.Log.info('join start')
      join.setRequestTimeout(60000)
      join.setOnSuccess(() => {
        fm.icelink.Log.info('join success start')
        this.setState({ callState: CALL_STATES.JOINED })
        this.timeoutId = setTimeout(this.onTimeout, this.callTimeout)
        this.track('PortalCallInitSignaling', {
          callType: this.state.callType
        })
        return Promise.resolve(true)
      })
      join.setOnFailure(e => {
        this.trackError(e)
        this.setState({ callState: CALL_STATES.ERROR, error: e })
        return Promise.reject(e.getException())
      })
      join.setOnRemoteClient(remoteClient => {
        fm.icelink.Log.info('setOnRemoteClient start')
        clearTimeout(this.timeoutId)
        const connection = this.createRemoteConnection(remoteClient)
        return connection
      })
      fm.icelink.websync4.ClientExtensions.joinConference(this.client, join)
      fm.icelink.Log.info('join end')
    } catch (error) {
      this.trackError(error)
      this.setState({ callState: CALL_STATES.ERROR, error })
      return Promise.reject(error)
    }
  }

  unsubscribeFromChannel = (channel: string, sessionId: string) => {
    return new Promise((resolve, reject) => {
      const leave = new fm.icelink.websync4.LeaveConferenceArgs(channel)
      leave.setOnSuccess(() => resolve(null))
      leave.setOnFailure(e => {
        this.trackError(e)
        reject(e.getException())
      })

      this.publishMessage(channel, sessionId, CallConstants.CALL_STATE, CallConstants.CALL_LEAVE);
      fm.icelink.websync4.ClientExtensions.leaveConference(this.client, leave);
    })
  }

  publishMessage = (sessionChannel: string, sessionId: string, key: string, value: string) => {
    const leaveSignal = JSON.stringify({
      "sessionId": sessionId,
      "key": key,
      "value": value
    });

    const leaveSignalArgs = new fm.websync.publishArgs(sessionChannel, leaveSignal);
    this.client.publish(leaveSignalArgs);
  };

  checkCallState = () => {
    if (this.state.error || this.state.callState === (CALL_STATES.TIMED_OUT || CALL_STATES.STREAM_FAILURE || CALL_STATES.ERROR))
      return videoCallStatus.failureStatus
    return videoCallStatus.successStatus
  }

  saveCallState = async () => {
    const callType = this.state.callType === videoCallStatus.audio ? videoCallStatus.audioCall : videoCallStatus.videoCall
    const recipientId = this.customer.id
    const senderId = this.me.employee && this.me.employee.id
    const status = this.checkCallState()
    const callStatus = {
      callType,
      recipientId,
      senderId,
      status
    }

    const correlationContextType = this.state.callType === videoCallStatus.audio ? 'AudioVideoCall_AUDIO' : 'AudioVideoCall_VIDEO'
    await connector.postWithCorrelation(getToken(), `hcp/api/v1/patient/${recipientId}/video-call/status`, CorrelationContext[correlationContextType] + this.state.supportId, callStatus)
  }

  createRemoteConnection = (remoteClient: fm.icelink.websync4.PeerClient) => {
    const remoteMedia = new fm.icelink.RemoteMedia()
    fm.icelink.Log.info('RemoteMedia done')
    this.remoteMedia = remoteMedia
    let videoStream = null
    const audioStream = new fm.icelink.AudioStream(this.localMedia, remoteMedia)
    if (this.state.callType != 'AUDIO') {
      videoStream = new fm.icelink.VideoStream(this.localMedia, remoteMedia)
    }
    const connection = new fm.icelink.Connection(this.state.callType != 'AUDIO' ? [audioStream, videoStream] : [audioStream])
    connection.setIceServers(this.config.iceServers)
    connection.addOnStateChange(c => {
      this.trigger('connectionStateChanged', c)
      const error = connection.getError()
      fm.icelink.Log.info('Connection state is ' +
        new fm.icelink.ConnectionStateWrapper(connection.getState()).toString() +
        '.', error ? error.getException() : undefined)
      switch (connection.getState()) {
        case fm.icelink.ConnectionState.Connected:
          const usedIceServer = connection.getIceServer()
          const transport = `UDP:${usedIceServer.getIsUdp()} TCP:${usedIceServer.getIsTcp()} STUN:${usedIceServer.getIsStun()} TURN:${usedIceServer.getIsTurn()}`
          this.track('PortalCallStarted', {
            callType: this.state.callType,
            transport: transport
          })
          break
        case fm.icelink.ConnectionState.Failing:
          this.setState({
            callState: CALL_STATES.ERROR,
            error: {
              type: 'connection_lost',
              message: 'Connection lost'
            }
          })
          connection.close()
          this.stopLocalMedia()
          this.leave('Server Failed', true).catch(_ => { })
          this.track('PortalCallEnded', {
            callType: this.state.callType,
            type: 'error'
          })
          break
        case fm.icelink.ConnectionState.Failed:
          this.setState({
            callState: CALL_STATES.ERROR,
            error: {
              type: 'stream_failure',
              message: 'Stream Failure'
            }
          })
          fm.icelink.websync4.ClientExtensions.reconnectRemoteClient(this.client, remoteClient, connection)
          break
      }
    })
    fm.icelink.Log.info('connection done')
    return connection
  }

  async leave(callStatus: string, showCallEnded: boolean = false) {
    try {
      const sessionChannel = '/' + this.config.sessionId
      const callDuration = this.state.callStartTime != null ? moment.now() - this.state.callStartTime : 0
      clearTimeout(this.timeoutId)
      await this.unsubscribeFromChannel(sessionChannel, this.config.sessionId)
      if (this.metadataChannel) {
        await this.unsubscribeFromChannel(this.metadataChannel, this.config.sessionId)
      }
      this.saveCallState()
      this.stopLocalMedia()
      this.setState({
        callState: CALL_STATES.CLOSED,
        showCallEnded
      })
      sendDataToDataCollection(DataCollectionType.PatientData, callData(this.customer, {
        callType: this.state.callType,
        status: callStatus,
        lib: IcelinkLib,
        duration: callDuration,
      }), EventType.Call)
    } catch (e) {
      this.saveCallState()
      this.trackError(e)
      this.setState({
        callState: CALL_STATES.ERROR,
        error: e
      })
    }
  }

  toggleAudioMute = async () => {
    const audioTrack = this.localMedia.getAudioTrack()
    audioTrack.setMuted(!audioTrack.getMuted())
    this.setState({
      audioMuted: audioTrack.getMuted()
    })
    return audioTrack.getMuted()
  }

  toggleVideoMute = async () => {
    const videoTrack = this.localMedia.getVideoTrack()
    videoTrack.setMuted(!videoTrack.getMuted())
    this.setState({
      videoMuted: videoTrack.getMuted()
    })
    return videoTrack.getMuted()
  }

  isLocalAudioMuted() {
    if (!this.localMedia) return true
    return this.localMedia.getAudioTrack().getMuted()
  }

  isLocalVideoMuted() {
    if (!this.localMedia) return true
    if (!this.localMedia._internal._video) return true
    return false
  }


  onMessage(peerName, messageData) {
    let { audioMuted, videoMuted, applySettings, callState, key, value } = messageData

    if (key !== undefined && value !== undefined) {
      switch (key) {
        case 'audioMuted': audioMuted = this.convertBoolIfString(value); break;
        case 'videoMuted': videoMuted = this.convertBoolIfString(value); break;
        case 'applySettings': applySettings = this.convertBoolIfString(value); break;
        case 'callState': callState = value; break;
      }
    }

    if (audioMuted !== undefined) {
      this.setState({
        remoteAudioMuted: audioMuted
      })
    }

    if (videoMuted !== undefined) {
      this.setState({
        remoteVideoMuted: videoMuted
      })
    }

    if (applySettings !== undefined) {
      this.setState({
        remoteApplyingSettings: applySettings
      })
    }

    if (callState !== undefined) {
      this.setState({
        remoteCallState: callState
      })
      switch (callState) {
        case 'join':
          this.track('PortalCallInitConnection', {
            callType: this.state.callType
          })
          this.setState({
            callState: CALL_STATES.REMOTE_JOINED,
            callStartTime: moment.now()
          })
          break
        case 'leave':
          this.track('PortalCallEnded', {
            callType: this.state.callType,
            type: 'remote'
          })
          this.leave('Ended', true).catch(_ => { })
          break
        case 'declined':
          this.leave('Aborted', true).catch(_ => { })
          break
      }
    }
  }

  isActive() {
    const { callState } = this.state
    return callState !== CALL_STATES.CLOSED
  }

  onTimeout = async () => {
    await this.stopLocalMedia()
    this.setState({ callState: CALL_STATES.TIMED_OUT })
  }

  convertBoolIfString = (value) => {
    if (value === "true") return true;
    else if (value === "false") return false;
    return value
  }
}