import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

const FourBandLegend = () => {
  return (
    <div className="channels-group__levels">
      <div className="row">
        <div className="col-6">
          <h5 className="channels-group__levels-title text-left">
            <FormattedMessage {...T('fourband.lowPitches')} />
          </h5>
        </div>
        <div className="col-6">
          <h5 className="channels-group__levels-title text-right">
            <FormattedMessage {...T('fourband.highPitches')} />
          </h5>
        </div>
      </div>
    </div>
  )
}

export default FourBandLegend
