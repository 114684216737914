import React from 'react'
import isNil from 'lodash/isNil'
import neutral from '../../../../assets/img/icons/ico_info_neutral.svg'
import satisfied from '../../../../assets/img/icons/ico_info_satisfied.svg'
import unsatisfied from '../../../../assets/img/icons/ico_info_unsatisfied.svg'
export class SatisfactionPoint extends React.Component {
  render() {
    return !isNil(this.props.data[0].rating) ? <DataPointCircle {...this.props} /> : null
  }
}

class DataPointCircle extends React.Component {
  render() {
    const { x, y, datum, onClickPoint, size } = this.props // VictoryScatter supplies x, y and datum
    return (
      <svg
        x={x - 10}
        y={y - 10}
        width={size}
        height={size}
        onClick={() => onClickPoint(null, datum)}
      >
        <defs>
          <clipPath id="circleView">
            <circle cx="10" cy="10" r="10" fill="#FFFFFF" />
          </clipPath>
        </defs>
        <image
          width="20"
          height="20"
          xlinkHref={datum.rating >= 3 ? satisfied : datum.rating == 2 ? neutral : unsatisfied}
          clipPath="url(#circleView)"
        />
      </svg>
    )
  }
}
