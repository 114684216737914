import React from 'react'
import { pickBy } from 'lodash'
import { StyledFormGroup, FormGroupWrapper } from 'styledComponents/Div/DivComponent/DivComponent'

const FormGroup = props => {
  const {
    children,
    label,
    id,
    helpText,
    onRegister,
    onValidation,
    onDirty,
    isValid = true,
    isDirty = false,
    isBlurred = true,
    showRight = false,
    onBlurred,
    width,
    required,
    theme = 'vertical' // vertical =  show children on a column; horizontal = show children on a row
  } = props

  // pick only the props which are defined to be passed to the children
  const passedProps = pickBy(
    { onRegister, onValidation, onDirty, id, onBlurred, isValid, required },
    prop => prop
  )
  return (
    <StyledFormGroup
      width={width}
      required={required}
      isValid={isValid}
      isDirty={isDirty}
      className="formGroup"
    >
      {!!label &&
        <label className="formGroup--label font-weight-bold" htmlFor={id} data-qa={`${id}-label`}>
          {label}
        </label>}
      <FormGroupWrapper theme={theme}>
        <div className={showRight ? 'right' : 'left'}>
          {React.Children.map(children, child => {
            return React.cloneElement(child, { ...passedProps })
          })}
        </div>
      </FormGroupWrapper>
      {!!helpText &&
        (isDirty && !isValid && isBlurred) &&
        <span data-qa="form-group--error" className="help-block">
          {helpText}
        </span>}
    </StyledFormGroup>
  )
}

export default FormGroup
