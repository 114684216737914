import React, { Component } from 'react'
import moment from 'moment-timezone'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import PositiveRating from 'assets/img/icons/thumbsup_positive.svg'
import NegativeRating from 'assets/img/icons/thumbsdown_negative.svg'
import NoRating from 'assets/img/icon_lessons_unrated.png'
import { StyledSvg } from "styledComponents/Icon/StyledSvgIcon" 

export class LessonRatingDetails extends Component {

  renderFeedbackIcon = (rating) => {
    return (
      <div>
        {rating ? (
          <StyledSvg src={PositiveRating} type="thumbsUp" className="ratings__feedback-icon" />
        ) : rating == 0 ? (
          <StyledSvg src={NegativeRating} type="thumbsDown" className="ratings__feedback-icon" />
        ) : (
          <img className="ratings__feedback-icon" src={NoRating} />
        )}
      </div>
    );
  }

  render() {
    const {
      description,
      ratingDetail,
      title,
      howTo,
      leftHearingDevice,
      rightHearingDevice
    } = this.props
    const date = moment(ratingDetail && ratingDetail.ratingDate).tz(moment.tz.guess()).format('L')
    const time = moment(ratingDetail && ratingDetail.ratingDate)
      .tz(moment.tz.guess())
      .format('hh:mm')
    const rating = ratingDetail && ratingDetail.rating

    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className="row">
              <span
                className={
                  rating
                    ? 'ratings__accordion-positive'
                    : rating == 0 ? 'ratings__accordion-negative' : 'ratings__accordion-noRating'
                }
              />
              <div className="ratings__accordion-title col-md-6 col-sm-6 col-xs-6">
                {title}
              </div>
              <div className="ratings__accordion-time col-md-3 col-sm-2 col-xs-3">
                <FormattedMessage {...T('lessonRating.dateOfRating')} />:{' '}
                {ratingDetail && ratingDetail.ratingDate
                  ? date + ', ' + time
                  : <FormattedMessage {...T('lessonRating.ratingState')} />}
              </div>
              <div className="ratings__accordion-icon col-md-2 col-sm-2 col-xs-2">
              {this.renderFeedbackIcon(rating)}
              <div className="accordion__arrow ratings__arrow" />
              </div>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage {...T('educational_lesson.lesson')} />
            </div>
            <div className="col-md-12">
              {description}
            </div>
            <div className="col-md-12">
              {howTo}
            </div>
          </div>
          <div className="row ratings__heading-text">
            <div className="col-md-6">
              <FormattedMessage {...T('lessonRating.feedback')} />
            </div>
            <div className="col-md-6 ">
              <FormattedMessage {...T('lessonRating.devices')} />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-md-6 ratings__accordion-text">
              {ratingDetail && ratingDetail.answerText}
            </div>
            <div className="col-md-6 ratings__accordion-text">
              {leftHearingDevice}
            </div>
            <div className="col-md-6 ratings__accordion-text">
              {rightHearingDevice}
            </div>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
}
