import React, { Component } from 'react'
import Dropdown from 'components/Dropdown'
import FormGroup from 'components/FormGroup'
import Fieldset from 'components/Fieldset'
import messages from 'i18n/messages.json'
import { T } from 'core'
import _ from 'lodash'
import { StyledBrandList } from 'styledComponents/Div/DivComponent/DivPatient'

export class NewBrandSettings extends Component {

  onSelectBrand = brandPosition => brand => {
    this.props.onSelect({
      // when position (left or right) is changed than the opposite position should be filtered
      brandPosition:
        brandPosition === 'manufacturersRight' ? 'manufacturersLeft' : 'manufacturersRight',
      brand,
      selectedBrandPosition: brandPosition === 'manufacturersRight' ? 'BrandRight' : 'BrandLeft'
    })
  }

  render() {
    const {
      formatMessage,
      onValidationChange,
      setFormRef,
      manufacturersRight,
      manufacturersLeft,
      selectedBrandLeft,
      selectedBrandRight
    } = this.props
    const required = !(!!selectedBrandLeft || !!selectedBrandRight)

    // Right and Left position of the brand selector is indented.
    // This is the view that HCP sees when he looks at the patient
    // Colors  for labels are predifined based on the conexx functionality
    return (
      <div>
        <h2 data-qa="devices-title">
          {formatMessage(T('hearing_device.title'))}
        </h2>
        <p>
          {formatMessage(T('hearing_device.note'))}
        </p>
        <StyledBrandList>
          <Fieldset
            ref={form => {
              setFormRef({ name: 'formBrandSettings', form })
            }}
            alignment="horizontal"
            onValidationChange={onValidationChange}
          >
            <FormGroup
              id="manufacturersRight"
              helpText={formatMessage(T('validator.manufacturerRequired'))}
              label={formatMessage(messages.hearingDeviceRightDevice)}
              required={required}
            >
              <Dropdown
                id="RightDropdown"
                defaultDropdownTitle={formatMessage(messages.hearingDeviceManufacturer)}
                onSelect={this.onSelectBrand('manufacturersRight')}
                options={manufacturersRight.map(opt => ({
                  value: opt.id,
                  text: opt.name,
                  disabled: opt.disabled || false
                }))}
                selected={selectedBrandRight}
                required={required}
              />
            </FormGroup>

            <FormGroup
              id="manufacturersLeft"
              helpText={formatMessage(T('validator.manufacturerRequired'))}
              label={formatMessage(messages.hearingDeviceLeftDevice)}
              required={required}
            >
              <Dropdown
                data-qa="LeftDropdown"
                defaultDropdownTitle={formatMessage(messages.hearingDeviceManufacturer)}
                onSelect={this.onSelectBrand('manufacturersLeft')}
                options={manufacturersLeft.map(opt => ({
                  value: opt.name,
                  text: opt.name,
                  disabled: opt.disabled
                }))}
                required={required}
                selected={selectedBrandLeft}
              />
            </FormGroup>
          </Fieldset>
        </StyledBrandList>
      </div>
    )
  }
}
export default NewBrandSettings
