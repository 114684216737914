import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { T } from 'core'

import InputField from 'formfields/InputField.js'

class LoginForm extends Component {
  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  loginValidationSchema = Yup.object().shape({
    username: Yup.string().required(this.formatMessage('validator.required')),
    password: Yup.string().required(this.formatMessage('validator.required'))
  })
  render() {
    const { doLogin, intl: { formatMessage } } = this.props

    return (
      <Formik
        onSubmit={(values, props) => doLogin(values, props.setFieldError, props.setSubmitting)}
        validationSchema={this.loginValidationSchema}
        initialValues={{ username: '', password: '', error: '' }}
        isInitialValid={values => this.loginValidationSchema.isValid(values)}
      >
        {({ isSubmitting }) =>
          <Form className="login__form form-wrapper errorConatinerFont">
            <ErrorMessage
              name="error"
              render={msg =>
                <div
                  className="alert alert-danger"
                  data-qa="login-error"
                  style={{ padding: '5%', marginBottom: '5%' }}
                >
                  {msg}
                </div>}
            />
            <Field
              name="username"
              type="text"
              className="input-wrapper"
              component={InputField}
              placeholder={formatMessage(T('login.email'))}
              autoComplete="username"
            />
            <Field
              name="password"
              type="password"
              className="input-wrapper"
              component={InputField}
              placeholder={formatMessage(T('login.password'))}
              autoComplete="current-password"
            />
            <div className="row ">
              <div className="col-md-12 ">
                <button
                  disabled={isSubmitting}
                  data-qa="login-button"
                  className="btn btn-block btn-primary controlLogin"
                  type="submit"
                >
                  <FormattedMessage {...T('common.login')} />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  text-center controlLink">
                <div>
                  <Link to="/password/request-reset" data-qa="forgot-password-link">
                    <FormattedMessage {...T('login.forgot_password')} />
                  </Link>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '6%' }}>
              <div className="col-md-12 text-center controlLink">
                <FormattedMessage {...T('landingpage.register.title')} />
                <Link
                  to="/register"
                  className="btn btn-block btn-secondary controlRegister"
                  data-qa="registration-link"
                >
                  <FormattedMessage {...T('login.register.organization')} />
                </Link>
              </div>
            </div>
          </Form>}
      </Formik>
    )
  }
}

LoginForm.propTypes = {
  intl: PropTypes.shape({}).isRequired
}

export default injectIntl(LoginForm)
