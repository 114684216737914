import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

export const StyledSvg = styled(SVG)((props) => {
  if (!props.type) return;

  const {
    theme: {
      colors: {
        icons: { [props.type]: icon },
      },
    },
  } = props;

  const { color } = icon;

  return css`
    .cls-1 {
      fill: ${color};
    }

    &&.ratings-emoji-icon {
      font-size: 20px;
      float: left;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  `;
});
