import React, { Component } from 'react'
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import { T } from "core"
import { Props, State } from "typescript/interfaces/ILessonSetFormForSignia"
import { withRouter } from 'helper/withRouter'
import * as Yup from 'yup'
import { Banner } from "styledComponents/Div/DivComponent/DivComponent"
import { AccordionWithCheckbox } from "components/AccordionWithCheckbox"
import Loading from "components/Loading"
import InputField from 'formfields/InputField'
import CheckboxField from 'formfields/CheckboxField'
import { Formik, Form, Field } from "formik"
import { getLessonSetModules } from "helper/LessonsUtils"
import { getEmployeeFromCache } from 'helper/PracticeUtils'
import { STANDARD_LESSON_SET_NAME } from 'helper/constants'
import { Button } from 'styledComponents/Button'
import { sendDataToDataCollection } from '../../helper/DataCollection/SendDataToDataCollectionUtil';
import { EventType, EntityCrudType } from '../../helper/DataCollection/DataCollectionConstants'
import { DataCollectionType } from 'helper/constants'
import { EntityCrudPayloadData } from '../../helper/DataCollection/PayloadData'

export class LessonSetFormForSignia extends Component<Props, State> {
  options = {
    description: {
      key: 'journeyConfiguratorManageLessonSetsDefaultCheckbox'
    }
  }

  state: State = {
    lessonModules: [],
    lessonSetById: {},
    DefaultLessonSetForSignia: [],
    isStandardLessonSet: false
  }

  ManageLessonTemplateEnityType = 'LessonTemplate'

  static getDerivedStateFromProps(props: Props, state: State) {
    const { lessonModules } = state
    const { DefaultLessonSetForSignia, LessonSetForSignia, params: { id } } = props
    if (!_.isNil(DefaultLessonSetForSignia) && (lessonModules.length == 0 || (DefaultLessonSetForSignia != state.DefaultLessonSetForSignia))
    ) {
      const modules = getLessonSetModules(DefaultLessonSetForSignia)
      let lessonSetById = [], isStandardLessonSet = false
      if (id) {
        lessonSetById = LessonSetForSignia.filter(lessonset => lessonset.id == id)
        if (lessonSetById[0] && lessonSetById[0].lessonSetName == STANDARD_LESSON_SET_NAME)
          isStandardLessonSet = true
      }
      return {
        lessonModules: modules,
        lessonSetById: lessonSetById[0] || {},
        isStandardLessonSet,
        DefaultLessonSetForSignia
      }
    }
    return null
  }

  getLessonIds = values => {
    const { lessonModules = [] } = this.state
    const keys = Object.keys(values).filter(key => values[key] === true)
    const lessonIds = lessonModules.map(module => {
      return module.lessons.map(lesson => {
        if (keys.includes(lesson.id.toString()))
          return lesson.id
      })
    })
    return lessonIds
  }

  prepareDataOnSubmit = (values) => {
    const lessonIdsArray = this.getLessonIds(values)
    const lessonIds = _.compact(_.flatten(lessonIdsArray))
    return {
      lessonSetName: values.lessonSetName || '',
      comment: values.comment || '',
      isDefault: values.isDefault || false,
      lessonIds: lessonIds || []
    }
  }

  formatMessage(keyID) {
    return this.props.intl.formatMessage(T(keyID))
  }

  lessonSetValidationSchema = () =>
    Yup.object().shape({
      lessonSetName: Yup.string()
        .required(this.formatMessage('validator.required'))
    })

  handleSubmit = async values => {
    const { id } = this.props.params
    const formData = this.prepareDataOnSubmit(values)
    try {
      if (id) {
        await this.props.updateLessonSetForSignia({ hcpId: getEmployeeFromCache().id, lessonSetId: id, lessonSet: formData })
        sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.LessonTemplate), EventType.Update)
      } else {
        await this.props.createLessonSetForSignia({ hcpId: getEmployeeFromCache().id, lessonSet: formData })
        sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.LessonTemplate), EventType.Create)

      }
    } catch (e) {
      console.log(e)
    }
    this.props.navigate('/overview/orgManagement/lessons')
  }

  getIsDefault = value => {
    if (value == "true")
      return true
    return false
  }

  getFieldName = () => {
    const { lessonSetById, lessonModules = [] } = this.state
    const fieldName = {}
    lessonModules.forEach(module => {
      fieldName[module.categoryName] = true
      module.lessons.forEach(lesson => {
        if (!_.isEmpty(lessonSetById) && lessonSetById.lessonIds.includes(lesson.id))
          fieldName[lesson.id] = true
        else {
          fieldName[lesson.id] = false
          fieldName[module.categoryName] = false
        }
      })
    })

    return fieldName
  }

  prepareDataOnInitialization = () => {
    const { lessonSetById } = this.state
    const fieldName = this.getFieldName()
    if (!_.isEmpty(lessonSetById)) {
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.LessonTemplate), EventType.Read)
    }
    return {
      lessonSetName: lessonSetById.lessonSetName || '',
      comment: lessonSetById.comment || '',
      isDefault: this.getIsDefault(lessonSetById.isDefault) || false,
      ...fieldName
    }
  }

  showLessonInfo = message => {
    return <Banner className="lesson-info text-style">
      {message}
    </Banner>
  }

  render = () => {
    const { lessonModules = [], isStandardLessonSet } = this.state
    const { loading, intl: { formatMessage }, params: { id } } = this.props
    return (
      <section className="container">
        <Loading isLoading={loading} />
        {!loading && (
          <Formik
            onSubmit={(values, actions) => this.handleSubmit(values)}
            initialValues={this.prepareDataOnInitialization()}
            validationSchema={() => this.lessonSetValidationSchema()}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className="col-lg-10 offset-lg-1">
                  <h1>
                    {id ? formatMessage(T('journeyConfigurator.manageLessonSets.EditSet.Header')) : formatMessage(T('journeyConfigurator.manageLessonSets.CreateSet'))}
                  </h1>
                  {this.showLessonInfo(formatMessage(T('journeyConfigurator.manageLessonSets.CreateSet.info')))}
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="lessonSetName"
                        component={InputField}
                        disabled={isStandardLessonSet}
                        label={formatMessage(T('journeyConfigurator.manageLessonSets.LessonSetName'))}
                        labelClassName="required"
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="comment"
                        component={InputField}
                        disabled={isStandardLessonSet}
                        label={formatMessage(T('journeyConfigurator.manageLessonSets.Comments'))}
                      />
                    </div>
                  </div>
                  {!id &&
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          {this.showLessonInfo(formatMessage(T('journeyConfigurator.manageLessonSets.defaultInfo')))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="isDefault"
                            component={CheckboxField}
                            label={formatMessage(T('journeyConfigurator.manageLessonSets.defaultCheckbox'))}
                            options={this.options}
                          />
                        </div>
                      </div>
                    </React.Fragment>}
                  <div className="row lesson-element-margin">
                    <div className="col-md-12">
                      <h3>{formatMessage(T('journeyConfigurator.manageLessonSets.elementsHeader'))}</h3>
                      {this.showLessonInfo(formatMessage(T('journeyConfigurator.manageLessonSets.elementsInfo')))}
                    </div>
                  </div>
                  <div className="row">
                    {lessonModules && lessonModules.map((module, index) => {
                      return <div className="col-md-6" key={index}>
                        <AccordionWithCheckbox lessonModule={module} isDisabled={isStandardLessonSet} formikProps={formikProps} />
                      </div>
                    })
                    }
                  </div>
                  <div className="row display-to-right">
                    {!isStandardLessonSet && <div className="col-md-3">
                      <Button
                        primary
                        type="submit"
                        className="btn-block"
                      >
                        <FormattedMessage {...T('journeyConfigurator.manageLessonSets.saveTemplate')} />
                      </Button>
                    </div>}
                    {!!id && !isStandardLessonSet && <div className="col-md-3">
                      <Button
                        primary
                        type="button"
                        onClick={() => formikProps.resetForm()}
                        className="btn-block"
                      >
                        <FormattedMessage {...T('common.reset')} />
                      </Button>
                    </div>}
                    <div className="col-md-3">
                      <Button
                        secondary
                        type="button"
                        className="btn-block"
                        onClick={() => this.props.navigate(-1)}
                        data-qa="cancel-button"
                      >
                        <FormattedMessage {...T('common.cancel')} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </section>
    );
  };
}

export default withRouter(LessonSetFormForSignia);
