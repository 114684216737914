import styled from 'styled-components'

export const TextArea = styled.textarea`
  border-radius: 6px;
  border: 1px solid grey;
  resize: unset;
  min-height: 160px;
  width: 100%;
  padding: 5px 10px;
`
