import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {        
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {      
      return <h2 style={{marginLeft: "100px", marginTop:"50px"}}><FormattedMessage {...T('generic_error2')} /></h2>
    }
    else return this.props.children;
  }
}