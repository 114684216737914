import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { injectIntl } from 'react-intl'
import { Banner } from 'styledComponents/Div/DivComponent/DivComponent'

const LessonRatingsBanner = () => {
  return (
    <section className="ratings container">
      <Banner className="row ratings__appNotConnected-message">
        <FormattedMessage {...T('lessonRating.appNotConnected.message')} />
      </Banner>
    </section>
  )
}

export default injectIntl(LessonRatingsBanner)
