import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from 'react-bootstrap'
import { T } from 'core'
import _ from 'lodash'
import avatarPlaceholder from 'assets/img/avatar_placeholder.svg'
const UserMenuDropdown = ({ me = {}, logout }) => {
	const employee = _.get(me, 'employee', {})
	const avatarPath = _.get(employee, `avatar.url`, '') || avatarPlaceholder
	const avatar = { backgroundImage: `url('${avatarPath}')` }
	return (
		<Dropdown className="dropdown--user-profile">
			<Dropdown.Toggle variant="outline-light" id="dropdown--user-profile" data-qa="dropdown-menu">
				<span className="avatar" style={avatar}></span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item data-qa="customer-overview-link">
					<span className="name">
						{employee.firstName} {employee.lastName}
					</span>
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item href={`/employee/${employee.id}`} data-qa="active-user-profile-link">
					<FormattedMessage {...T('navbar.profile')} />
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item onClick={logout} data-qa="user-logout">
					<FormattedMessage {...T('navbar.logout')} />
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default UserMenuDropdown
