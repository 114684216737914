import React, { Component } from 'react'

import FormGroup from 'components/FormGroup'
import Fieldset from 'components/Fieldset'
import { pick } from 'lodash'
import { notEqual } from 'helper/Utils'
import { T } from 'core'
import { StyledPraticeInfo } from 'styledComponents/Div/DivComponent/DivPatient'
import { withRouter } from "helper/withRouter";
import SearchableSelectorField from 'formfields/SearchableSelectorField'
import { FormattedMessage } from 'react-intl'

export class FormikPracticeInfo extends Component {
  onSelectPracticeInfo = info => id => {
    this.props.onSelect({
      info,
      id
    })
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.practice !== this.props.practice ||
      nextProps.employee !== this.props.employee ||
      notEqual(
        pick(nextProps, ['employees', 'practices']),
        pick(this.props, ['employees', 'practices'])
      )
    )
  }

  render() {
    const {
      formatMessage,
      practices = [],
      employees = [],
    } = this.props

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <SearchableSelectorField
                name="practice"
                options={practices}
                l toString={{ id: "id", value: "name" }}
                onChange={this.onSelectPracticeInfo('practice')}
                label={formatMessage(T('label.practice'))}
                labelClassName="required"
                placeholder={<FormattedMessage {...T('common.all_practices')} />}
              />
            </div>
            <div className="col-lg-6">
              <SearchableSelectorField
                name="employee"
                options={employees}
                toString={{ id: "id", value: "firstName,lastName" }}
                onChange={this.onSelectPracticeInfo('employee')}

                label={formatMessage(T('label.employee'))}
                placeholder={<FormattedMessage {...T('common.all_employees')} />}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class PracticeInfo extends Component {
  onSelectPracticeInfo = info => id => {
    if (!id) {
      id = 0;
    }
    this.props.onSelect({
      info,
      id
    })
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.practice !== this.props.practice ||
      nextProps.employee !== this.props.employee ||
      notEqual(
        pick(nextProps, ['employees', 'practices']),
        pick(this.props, ['employees', 'practices'])
      )
    )
  }

  render() {
    const {
      formatMessage,
      onValidationChange,
      setFormRef,
      practices = [],
      employees = [],
    } = this.props

    return (
      <div className="row">
        <div className="col-lg-12">
          <StyledPraticeInfo>
            <Fieldset
              ref={form => {
                setFormRef({ name: 'formPracticeInfo', form })
              }}
              alignment="horizontal"
            >
              <FormGroup>
                <SearchableSelectorField
                  name="practice"
                  id="practice"
                  options={practices}
                  toString={{ id: "id", value: "name" }}
                  onChange={this.onSelectPracticeInfo('practice')}
                  label={formatMessage(T('label.practice'))}
                  labelClassName="required"
                  placeholder={<FormattedMessage {...T('common.all_practices')} />}
                />
              </FormGroup>
              <FormGroup>
                <SearchableSelectorField
                  name="employee"
                  id="employee"
                  options={employees}
                  toString={{ id: "id", value: "firstName,lastName" }}
                  onChange={this.onSelectPracticeInfo('employee')}
                  labelClassName="required"
                  label={formatMessage(T('label.employee'))}
                  placeholder={<FormattedMessage {...T('common.all_employees')} />}
                />
              </FormGroup>
            </Fieldset>
          </StyledPraticeInfo>
        </div>
      </div>
    )
  }
}

export default withRouter(PracticeInfo)
