import React from 'react'
import { DevicePersistingType } from 'components/_Patient/components'
import Fieldset from 'components/Fieldset'
import { notEqual } from 'helper/Utils'
import { pick, keys } from 'lodash'
import { T } from 'core'
import { Button } from 'styledComponents/Button'
import {
  StyledDevicePersisting,
  PersistentInfo,
  PointingArrow
} from 'styledComponents/Div/DivComponent/DivPatient'

const LABELS = {
  TELECARE_2_0: 'primax.new.title',
  TELECARE_1_0_PERSISTENT: 'primax.permanent.title',
  TELECARE_1_0_TEMPORARY: 'primax.temporary.title'
}
class DevicePersistingSettings extends React.Component {
  displayName = 'DevicePersistingSettings'
  static defaultProps = {
    persistentOptions: [],
    defaultValue: '',
    showInfo: false
  }

  shouldComponentUpdate(nextProps) {
    return notEqual(
      pick(nextProps, ['persistentOptions', 'defaultValue', 'showInfo']),
      pick(this.props, ['persistentOptions', 'defaultValue', 'showInfo'])
    )
  }

  onSelectPersistingType = id => {
    this.props.onSelect({
      id
    })
  }

  isDisabled = id => this.props.persistentOptions.indexOf(id) < 0
  // defaultChecked is the first item in the persistentOptions or the passed defaultValue
  isChecked = id => this.props.defaultValue === id

  render() {
    const {
      formatMessage,
      onClickShowInfo,
      showInfo,
      setFormRef,
      onValidationChange
    } = this.props

    return (
      <div className="row" data-qa="persistent-settings">
        <StyledDevicePersisting>
          <Fieldset
            ref={form => {
              setFormRef({ name: 'formDevicePersistingSettings', form })
            }}
            alignment="horizontal"
            onValidationChange={onValidationChange}
          >
            {keys(LABELS).map(item =>
              <DevicePersistingType
                onSelect={this.onSelectPersistingType}
                id={item}
                key={`devicePersistent-${item}`}
                defaultValue={item}
                defaultChecked={this.isChecked(item)}
                label={formatMessage(T(LABELS[item]))}
                disabled={this.isDisabled(item)}
                required
              />
            )}
            <Button
              type="button"
              className="btn--min-width"
              secondary
              id="showMoreOfPersistent"
              onClick={onClickShowInfo}
            >
              {formatMessage(T('hearing_device.moreInfo'))}
            </Button>
          </Fieldset>
          <PersistentInfo show={showInfo}>
            <PointingArrow show={showInfo} className="arrow-expand" />
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <h3>
                    {formatMessage(T('primax.new.title'))}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(T('primax.new.text'))
                    }}
                  />
                </div>
                <div className="col-4">
                  <h3>
                    {formatMessage(T('primax.permanent.title'))}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(T('primax.permanent.text'))
                    }}
                  />
                </div>
                <div className="col-4">
                  <h3>
                    {formatMessage(T('primax.temporary.title'))}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(T('primax.temporary.text'))
                    }}
                  />
                </div>
              </div>
            </div>
          </PersistentInfo>
        </StyledDevicePersisting>
      </div>
    )
  }
}
export default DevicePersistingSettings
