import { createSelector } from 'reselect'
import _ from 'lodash'

export const getAllHDPresets = createSelector((state, props) => {
  const devices = getHearingDevices(state, props)

  return _.get(devices, `hdPrograms`, []).map(program => program.hdPresets).reduce((arr, item) => {
    return item.length > 0 ? [...arr, ...item] : arr
  }, [])
}, hdPresets => hdPresets)

export const getHearingDevices = createSelector(state => {
  return _.get(state, `profile.hearingDevices.data`, {})
}, hearingDevices => hearingDevices)

export const getHDModels = createSelector(
  state => _.get(state, `hdModels.data`, {}),
  hdModels => (hdModels && hdModels.content) || []
)

export const getPrograms = hearingDevices => {
  const programs = _.get(hearingDevices, `hdPrograms`, [])
  return _.sortBy(programs, 'programNumber')
}

export const getVolumeScale = createSelector((state, props) => {
  const hearingDevices = getHearingDevices(state, props)
  const rightModel = _.get(hearingDevices, 'rightDevice.model', '')
  const leftModel = _.get(hearingDevices, 'leftDevice.model', '')

  return rightModel === 'Sprint' || leftModel === 'Sprint' ? 8 : 4
}, volumeScale => volumeScale)

export const getLatestDevices = (hearingDevices = {}) => {
  const { leftDevice, rightDevice } = hearingDevices
  const devices = []

  if (leftDevice) {
    devices.push({ ...leftDevice, side: 'L' })
  }

  if (rightDevice) {
    devices.push({ ...rightDevice, side: 'R' })
  }

  return devices
}
