import React, { Component } from 'react'
import OnBoardingPage, { getVideoListForHelpCenter } from '../../../containers/PatientsList/OnBoarding'
import { Props } from 'typescript/interfaces/IOnBoardingPage'
import { injectIntl } from 'react-intl'

export class Tutorials extends Component<Props, {}> {
  render() {
    return (
      <div className="container">
        <div className="row  justify-content-center">
          <div className="col-lg-12">
            <OnBoardingPage
              videosLink={getVideoListForHelpCenter(this.props)}
              isHelpVideos={true}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Tutorials)
