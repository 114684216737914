import styled from 'styled-components'

export const LoginWrapper = styled.div`
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 10%;
  padding: 10px;
  background-color: white;
`
