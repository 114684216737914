import { gql } from "@apollo/client";

export const EDIT_QUERY_PARAM = gql`
  mutation setLocation($location: LocationInput, $key: String, $value: String) @client {
    setLocation(location: $location, key: $key, value: $value) {
      isQueryActive
    }
  }
`

export const REMOVE_QUERY_PARAM = gql`
  mutation updateLocation($location: LocationInput, $key: String) @client {
    updateLocation(location: $location, key: $key) {
      isQueryActive
    }
  }
`
