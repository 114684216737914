export function SetItemInSessionStorage(key: string, rawValue: any): boolean {
  if (key == null || key == undefined || key.trim().length == 0) return false

  if (rawValue == null || rawValue == undefined) return false

  window.sessionStorage.setItem(key, JSON.stringify(rawValue))
  return true
}

export function GetItemFromSessionStorage(key: string): any {
  if (key == null || key == undefined || key.trim().length == 0)
    return null

  return JSON.parse(sessionStorage.getItem(key))
}

export function ClearSessionStorage(): void {
  sessionStorage.clear()
}