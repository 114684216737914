import { gql } from "@apollo/client";

export const QUERY_LESSON_RATINGS_FOR_SIGNIA = gql`
  query SigniaLessonRatingsQuery($patientGuid: String!) {
    SigniaLessonRatings(patientGuid: $patientGuid) {
      lessonCategory {
        categoryName
        description
        orderId
      }
      lessons {
        description
        howTo
        title
        leftHearingDevice
        rightHearingDevice
        ratingDetail {
          rating
          ratingDate
          answerText
        }
      }
    }
  }
`
