import { graphql } from '@apollo/client/react/hoc';
import React, { Component } from 'react'
import _get from 'lodash/get'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { getLocales } from 'helper/constants'
import _ from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import messages from 'i18n/messages.json'
import 'moment-timezone/data/packed/latest.json'
import moment from 'moment-timezone'
import { withRouter } from "helper/withRouter";
import { registerUserVersionV2 } from 'components/_Common'
import { T } from 'core'
import { shopTracking } from 'helper/shopTracking'
import { getToken } from 'helper/AuthHelper'
import { connector } from 'helper/Connector'
import { FETCH_ALL_COUNTRIES_QUERY, STORE_COUNTRIES_MUTATION } from 'graphql/queries/CountriesQuery'
import { FETCH_ALL_BRANDS } from 'graphql/queries/OrganizationBrandsQuery'
import { compose } from 'lodash/fp'
import { LOCALE_QUERY } from '../../graphql/queries/LocaleQuery'
import AccountsPage from "./AccountsPage"
import OrganizationPage from "./OrganizationPage"
import PracticePage from "./PracticePage"
import Stepper from "./Stepper"
import StepControl from "./StepControl"
import Loading from 'components/Loading'
export class HcpRegistrationPage extends Component {

  state = {
    busy: false,
    usernameTaken: false,
    insecurePassword: false,
    currentStep: 0,
    copyOrgDetails: false,
    formData: {}
  }

  registrationSteps = [
    <FormattedMessage {...T('register.adminAccountStep')} />,
    <FormattedMessage {...T('register.orgDetailsStep')} />,
    <FormattedMessage {...T('register.practiceDetailsStep')} />,
    <FormattedMessage {...T('register.reviewRegistrationDetails')} />];

  selectedOrgCountryCode = ""
  selectedPracCountryCode = ""
  submitClicked = false;

  componentDidMount() {
    const { countries } = this.props
    if (!countries || !countries.length) {
      this.fetchAllCountries()
    }
  }

  fetchAllCountries() {
    const token = getToken()
    const data = {
      page: 0,
      size: 1000
    }

    return connector.get(token, `/api/rest/countries`, data).then((payload = {}) => {
      const isIterable = (object) =>
        object != null && typeof object[Symbol.iterator] === "function";
      const countriesData = payload._embedded && payload._embedded.countries;
      const countries = { data: [...((isIterable(countriesData) && countriesData) || [])] }
      this.props.setCountries({ variables: { ...countries } });
    })
  }

  handleValidationOnSubmit = () => {
    this.submitClicked = true
  }

  onSubmit = (values, setFieldError) => {
    const brandId = (this.props.brands.find(_ => _.brandName === values.orgBrand)?.id) ?? 1
    const { currentStep } = this.state
    this.setState({ formData: values })
    if (currentStep < this.registrationSteps.length - 1) {
      return this.gotoNextStep(currentStep)
    }
    const employee = {}, shop = {}, registerData = {}, org = {}
    employee['firstName'] = values.firstName
    employee['lastName'] = values.lastName
    employee['email'] = values.email
    employee['password'] = values.password.passwordEnter
    employee['phone'] = values.phone
    org['name'] = values.orgName
    org['website'] = values.orgWebsite
    org['phone'] = values.orgPhoneNumber
    org['email'] = values.orgEmail
    org['street'] = values.orgAddrStreet
    org['city'] = values.orgAddrCity
    org['zipCode'] = values.orgAddrZipCode.trim()
    org['countryCode'] = values.orgAddrCountryCode
    org['language'] = values.orgDefaultlang
    org['timeZone'] = values.orgDefaultTimeZone
    org['brandId'] = values.isNoBrandingChecked ? (this.props?.brands?.find(_ => _.brandName.toLowerCase() === "neutral")?.id ?? 2) : brandId// brand id of neutral is 2
    shop['accountNumber'] = values.accountNumber.toUpperCase()
    shop['city'] = values.pracAddrCity
    shop['countryCode'] = values.pracAddrCountryCode
    shop['language'] = values.pracDefaultLanguage
    shop['name'] = values.pracName
    shop['phone'] = values.pracPhoneNumber
    shop['timeZone'] = values.pracDefaultTimeZone
    shop['zipCode'] = values.pracAddrZipCode.trim()
    shop['street1'] = values.pracAddrStreet
    registerData.employee = employee
    registerData.organization = org
    registerData.shop = shop
    registerData.agreeToTerms = values.agreeToTerms
    registerData.locale = values.orgDefaultlang
    registerData.userDataConsent = values.userDataConsent
    this.setState({ busy: true })
    return registerUserVersionV2(registerData)
      .then(response => {
        const pending = response.Content.status === 'PENDING'
        shopTracking({
          ...this.props.location,
          action: 'accountRegistration'
        })
        this.props.navigate({ pathname: `/register/success`, search: `?pending=${pending}` })
      })
      .catch(response => {
        this.setState({ busy: false })
      })
  }

  formatMessage = keyID => {
    return this.props.intl.formatMessage(T(keyID))
  }

  registrationValidationSchema = [() => Yup.object().shape({
    firstName: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict(),
    lastName: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict(),
    email: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .email(this.formatMessage('validator.email'))
      .usernameIsUnique('', null, this.submitClicked),
    phone: Yup.string().required(this.formatMessage('validator.required')).isPhoneNoValid(),
    password: Yup.object().shape({
      passwordEnter: Yup.string()
        .isPasswordValid()
        .min(6, this.formatMessage('min.password'))
        .required(this.formatMessage('validator.required')),
      passwordReEnter: Yup.string()
        .required(this.formatMessage('validator.required'))
        .test('passwords-match', this.formatMessage('validator.pinNotEqual'), function (value) {
          return this.parent.passwordEnter == value
        })
    })
  }),
  () => Yup.object().shape({
    orgName: Yup.string().required(this.formatMessage('validator.required')),
    orgWebsite: Yup.string().isUrlValid(this.formatMessage('validator.url')),
    orgAddrStreet: Yup.string().required(this.formatMessage('validator.required')),
    orgAddrZipCode: Yup.string()
      .trim()
      .isValidZipCode(this.props.stateCountries, this.selectedOrgCountryCode, this.formatMessage('validator.regex'))
      .max(10, this.formatMessage('validator.zipCodeMaxLength'))
      .required(this.formatMessage('validator.required')),
    orgAddrCity: Yup.string().required(this.formatMessage('validator.required')),
    orgDefaultlang: Yup.string().required(this.formatMessage('validator.required')),
    orgDefaultTimeZone: Yup.string().required(this.formatMessage('validator.required')),
    orgAddrCountryCode: Yup.string().required(this.formatMessage('validator.required')),
    orgBrand: Yup.string().oneOf(...[(this.props?.brands?.map(({ brandName }) => (brandName)))], this.formatMessage('common.brandRegisterError')),
    orgPhoneNumber: Yup.string()
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict()
      .isPhoneNoValid(),
    orgEmail: Yup.string()
      .required(this.formatMessage('validator.required'))
      .trim(this.formatMessage('validator.wrappingWhitespace'))
      .strict()
      .email(this.formatMessage('validator.email'))
      .orgEmailIsUnique(this.submitClicked)
  }),
  () => Yup.object().shape({
    accountNumber: Yup.string()
      .required(this.formatMessage('validator.required'))
      .isValidSivantosId()
      .isSivantosIdUnique(this.submitClicked),
    pracName: Yup.string().required(this.formatMessage('validator.required')),
    pracAddrStreet: Yup.string().required(this.formatMessage('validator.required')),
    pracAddrZipCode: Yup.string()
      .trim()
      .isValidZipCode(this.props.stateCountries, this.selectedPracCountryCode, this.formatMessage('validator.regex'))
      .max(10, this.formatMessage('validator.zipCodeMaxLength'))
      .required(this.formatMessage('validator.required')),
    pracAddrCity: Yup.string().required(this.formatMessage('validator.required')),
    pracDefaultLanguage: Yup.string().required(this.formatMessage('validator.required')),
    pracDefaultTimeZone: Yup.string().required(this.formatMessage('validator.required')),
    pracAddrCountryCode: Yup.string()
      .required(this.formatMessage('validator.required')),
    pracPhoneNumber: Yup.string()
      .required(this.formatMessage('validator.required'))
      .isPhoneNoValid(),
  }),
  Yup.object().shape({
    agreeToTerms: Yup.boolean().oneOf([true], this.formatMessage('validator.notChecked'))
  })
  ]

  gotoNextStep = (currentStep) => {
    this.setState({ currentStep: currentStep + 1 })
    window.scrollTo({
      top: -100,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleStepChangeByStepper = (index, isActive) => {
    if (!isActive || index == this.state.currentStep) {
      return;
    }
    this.setState({ currentStep: index })
  }

  handlePrevStep = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep - 1 })
    window.scrollTo({
      top: -100,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleCopyDetails = () => {
    const { copyOrgDetails } = this.state
    this.setState({ copyOrgDetails: !copyOrgDetails })
  }

  render() {
    const { intl: { formatMessage }, language, sortedCountryCodes } = this.props
    let { brands } = this.props
    brands = brands?.filter(_ => _.brandName.toLowerCase() !== "neutral")
    const { busy, currentStep, formData, copyOrgDetails } = this.state
    const unsortedCountryCodesWithCountryNames = sortedCountryCodes.map(countryCode => (
      {
        countryCode,
        countryName: formatMessage(T('countryName' + countryCode))
      }
    ))
    const sortedCountryCodesWithCountryNames = _.orderBy(unsortedCountryCodesWithCountryNames, [country => country.countryName.toLowerCase()], ['asc']);
    const isReviewPage = currentStep == this.registrationSteps.length - 1
    const currentValidationSchema = this.registrationValidationSchema[currentStep];
    const pracDetails = copyOrgDetails && !isReviewPage ? {
      pracName: formData.orgName,
      pracAddrStreet: formData.orgAddrStreet,
      pracAddrZipCode: formData.orgAddrZipCode,
      pracAddrCity: formData.orgAddrCity,
      pracDefaultLanguage: formData.orgDefaultlang,
      pracDefaultTimeZone: formData.orgDefaultTimeZone,
      pracAddrCountryCode: formData.orgAddrCountryCode,
      pracPhoneNumber: formData.orgPhoneNumber
    } : {}
    const registrationFormData = { ...this.props.initialData, ...formData, ...pracDetails }

    if (busy) {
      return <Loading isLoading={busy} />
    }
    return (
      <section>
        <div className="registerPage" data-qa="registration-page">
          <div className="container" >
            <div className="row">
              <div className="col-10 offset-2">
                <div className="pull-left">
                  <h1 data-qa="registration-page-title">
                    <FormattedMessage {...messages.registerTitle} />
                  </h1>
                  {currentStep == 0 &&
                    <h6 data-qa="registration-page-hint">
                      <p>
                        <FormattedMessage {...T('register.info')} />
                      </p>
                      <p>
                        <FormattedMessage {...T('register.infoDetail')} />
                      </p>
                    </h6>
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-8 offset-2">
                <Stepper
                  steps={this.registrationSteps}
                  currentStep={currentStep}
                  handleStepChange={this.handleStepChangeByStepper} />
              </div>
            </div>
            <div className="row">
              <div className="col-8 offset-2">
                <Formik
                  onSubmit={(values, actions) => this.onSubmit(values, actions.setFieldError)}
                  validationSchema={currentValidationSchema}
                  enableReinitialize={true}
                  initialValues={registrationFormData}
                >
                  {({ values: { orgAddrCountryCode, pracAddrCountryCode, isNoBrandingChecked }, setFieldValue }) => {
                    this.selectedOrgCountryCode = orgAddrCountryCode
                    this.selectedPracCountryCode = pracAddrCountryCode
                    this.submitClicked = false
                    return (
                      <Form>
                        <div className="container">
                          <p data-qa="required-text">
                            <FormattedMessage
                              {...T('common.required_text')}
                              values={{ mark: <span className="text-primary">*</span> }}
                            />
                          </p>
                          {(currentStep == 0 || isReviewPage) &&
                            <AccountsPage
                              busy={busy}
                              formatMessage={this.formatMessage}
                              isReviewPage={isReviewPage}
                            />}
                          {(currentStep == 1 || isReviewPage) &&
                            <OrganizationPage
                              language={language}
                              sortedCountryCodesWithCountryNames={sortedCountryCodesWithCountryNames}
                              busy={busy}
                              formatMessage={this.formatMessage}
                              isReviewPage={isReviewPage}
                              isRegisterPage={true}
                              brands={brands}
                              setFieldValue={setFieldValue}
                              isNoBrandingChecked={isNoBrandingChecked}
                            />}
                          {(currentStep == 2 || isReviewPage) &&
                            <PracticePage
                              language={language}
                              sortedCountryCodesWithCountryNames={sortedCountryCodesWithCountryNames}
                              busy={busy}
                              initialCountryCode={pracAddrCountryCode}
                              formatMessage={this.formatMessage}
                              handleCopyDetails={this.handleCopyDetails}
                              copyDetails={copyOrgDetails}
                              isReviewPage={isReviewPage}
                            />}
                          <StepControl
                            handlePrevPage={this.handlePrevStep}
                            currentStep={currentStep}
                            isReviewPage={isReviewPage}
                            busy={busy}
                            handleValidationOnSubmit={this.handleValidationOnSubmit}
                          />
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = (ownProps, rawCountries) => {
  const locale = ownProps.locale
  const stateCountries = _get(rawCountries, 'data', [])
  const countriesWithEnabledHcpRegistration = Object.keys(stateCountries)
    .filter(key => stateCountries[key].enableHcpRegistration)
    .map(key => stateCountries[key].countryCode)

  const countries = Object.keys(countriesWithEnabledHcpRegistration).sort().map(label => countriesWithEnabledHcpRegistration[label])
  const sortedLocales = getLocales()
  const sortedCountryCodes = [...countriesWithEnabledHcpRegistration].sort()

  // NOTE: undefined for the diffing algorithm, so that we can determined what changed and what not against initial state (magic with-in Form)
  const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: { passwordEnter: '', passwordReEnter: '' },
    orgName: '',
    orgWebsite: '',
    orgPhoneNumber: '',
    orgEmail: '',
    orgAddrStreet: '',
    orgAddrZipCode: '',
    orgAddrCity: '',
    orgDefaultlang: '',
    orgDefaultTimeZone: moment.tz.guess(),
    orgBrand: '',
    orgAddrCountryCode: '',
    accountNumber: '',
    pracAddrStreet: '',
    pracAddrCountryCode: '',
    pracDefaultLanguage: '',
    pracName: '',
    pracAddrCity: '',
    pracDefaultTimeZone: moment.tz.guess(),
    pracAddrZipCode: '',
    agreeToTerms: false,
    locale: locale,
    pracPhoneNumber: '',
    userDataConsent: false,
    isNoBrandingChecked: false,
    orgBrandDisabledTextbox: '',
  }

  return {
    locale,
    countries,
    sortedCountryCodes,
    stateCountries,
    intl: ownProps.intl,
    language: sortedLocales,
    initialData
  }
}

const withData = compose(
  graphql(LOCALE_QUERY, {
    props: ({ data }) => {
      return { locale: data.locale.locale }
    }
  }),
  graphql(FETCH_ALL_BRANDS, {
    props: ({ data }) => {
      return { brands: data.OrganizationBrands }
    }
  }),
  graphql(FETCH_ALL_COUNTRIES_QUERY, {
    props: ({ data, ownProps }) => mapStateToProps(ownProps, data.countries)
  }),
  graphql(STORE_COUNTRIES_MUTATION, {
    name: 'setCountries'
  }),
  injectIntl
)

export default withRouter(withData(HcpRegistrationPage))
