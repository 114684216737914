import { AppType } from 'helper/constants'

export const isAppSupported = (customer) => {
    if (!customer.hearingDevices?.capabilities?.appConnected || !customer.appInfo || !customer.appInfo.appName) return false
    switch (customer.appInfo.appName.toUpperCase()) {
        case AppType.SIGNIA:
        case AppType.PRIVATELABEL:
        case AppType.REXTON:
        case AppType.TRUHEARING:
        case AppType.AUDIBENE:
        case AppType.HEARDOTCOM:
        case AppType.AUDIOSERVICE:
        case AppType.INTEGRATION:
        case AppType.INTEGRATIONTEST:   //needed for local iOS Builds
            return true
        default:
            return false
    }
}