// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import VideoRTCButtons from '../../_VideoCall/Buttons'
import fflags from 'helper/FFlags'
import { PatientDetailsContainer } from 'styledComponents/Div/DivComponent/DivProfile'
import { AppType } from 'helper/constants'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"

const showVideoButtons = ({ customerId }) => {
  const shouldShow = fflags.variation('video-call', false)

  return shouldShow ? <VideoRTCButtons customerId={customerId} /> : null
}

const getAppStatus = (appInfo, appConnected) => {
  if (!appConnected) return 'profile.details.connect-status-no'
  else {
    switch (appInfo.appName && appInfo.appName.toUpperCase()) {
      case AppType.SIGNIA:
      case AppType.STRETTA:
        return 'profile.details.connect-status-signia'
      case AppType.PRIVATELABEL:
        return 'profile.details.connect-status-privatelabel'
      case AppType.REXTON:
        return 'profile.details.connect-status-rexton'
      case AppType.TRUHEARING:
        return 'profile.details.connect-status-truhearing'
      case AppType.AUDIBENE:
        return 'profile.details.connect-status-audibene'
      case AppType.HEARDOTCOM:
        return 'profile.details.connect-status-heardotcom'
      case AppType.AUDIOSERVICE:
        return 'profile.details.connect-status-audioservice'
      case AppType.INTEGRATION:
      case AppType.INTEGRATIONTEST: ////needed for local iOS Builds
        return 'profile.details.connect-status-integration'
      default:
        return 'profile.details.connect-status-unknown'
    }
  }
}

const PatientDetails = ({
  firstName,
  lastName,
  devices = [],
  hearingDevices = {},
  id: customerId,
  children = null,
  appInfo = {}
}) => {
  const { capabilities = {} } = hearingDevices
  const { appConnected = false } = capabilities
  const appConnectionStatus = getAppStatus(appInfo, appConnected)
  return (
    <article className="container">
      <PatientDetailsContainer>
        <h2 data-qa="patient-details__title">
          {`${firstName} ${lastName}`}
        </h2>
        {showVideoButtons({ customerId })}
        <div className="row">
          <div className="col-6">
            <div className="row">
              {devices.map(item =>
                <div
                  key={`${item.side}-${item.model}`}
                  data-qa="patient-details__device"
                  className="col-lg-6"
                >
                  {`${item.side}: ${item.manufacturer ? item.manufacturer + " " : ""}${item.model}`}
                </div>
              )}
            </div>
          </div>
          <div
            data-qa={`patient-details__status ${appConnected ? 'active' : ''}`}
            className="col-4"
          >
            {appConnected
              ? <StyledFontAwesomeIcon icon={faCheckCircle} fixedWidth type="success" className="app-connected" />
              : <StyledFontAwesomeIcon icon={faTimesCircle} fixedWidth type="failure" className="app-connected" />}
            <FormattedMessage {...T(appConnectionStatus)} />
          </div>
        </div>
        {children}
      </PatientDetailsContainer>
    </article>
  )
}

export default PatientDetails
