import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import _ from 'lodash'
import { T, tracker } from 'core'
import Loading from 'components/Loading'
import { Button } from 'styledComponents/Button'
import { ActionButtonsCreate } from 'styledComponents/Div/DivComponent/DivPatient'
import { Divider } from 'styledComponents/Div/DivComponent/DivComponent'
import {
  PatientPersonalInfo,
  PracticeInfo,
  PatientCreatedEditForm
} from 'components/_Patient/components'
import {
  getProgramsPayload,
  isPersonalFormValid,
  areProgramsValid
} from 'components/_Patient/shared'
import { validatePhoneNumber } from 'helper/ValidatePhoneNumber'
import { getLeftRightDevice } from 'helper/deviceSelectionRules'

import { customerTracking } from 'helper/customerTracking'
import HearingInstrumentSettings from './components/HearingInstrumentSettings'
import { withRouter } from "helper/withRouter";
import { withQuery } from '../../helper/withQuery'
import { getSupportId } from 'helper/ApplicationInsightSupportId'
import { CorrelationContext } from 'helper/constants'
import { connector } from 'helper/Connector'
import { getToken } from 'helper/AuthHelper'
import { getOrganizationIdFromCache } from '../../helper/PracticeUtils'

import { sendDataToDataCollection } from "helper/DataCollection/SendDataToDataCollectionUtil"
import { DataCollectionType } from 'helper/constants'
import { EventType } from 'helper/DataCollection/DataCollectionConstants'
import { PatientPayloadData } from 'helper/DataCollection/PayloadData'

let SupportId = getSupportId()

export class PatientCreatePage extends React.Component {
  state = {
    isPersonalFormValid: false,
    showPersistentInfo: false,
    isDeviceSettingsFormValid: false,
    isBrandSettingsFormValid: false,
    isDevicePersistingSettingsFormValid: false,
    selectedBrandLeft: null,
    selectedBrandRight: null,
    deviceModelRightId: '0',
    deviceModelLeftId: '0',
    manufacturers: [],
    teleCareVersion: null,
    practice: null,
    employee: null,
    hearingPrograms: [],
    HDModelsFeatures: null,
    bluetoothDisabled: false,
    isSaving: false,
    employeesList: [],
  }

  formPersonalInfo = {
    getValues: _.identity,
    getFieldsValidationState: _.identify
  }

  formBrandSettings = {
    getValues: _.identity
  }

  formPracticeInfo = {
    getValues: _.identity
  }

  formDeviceSettings = {
    getValues: _.identity
  }

  formDevicePersistingSettings = {
    getValues: _.identify
  }

  renderHeadLine() {
    const { error } = this.props
    return (
      <div>
        {error &&
          <div className="alert alert-danger" role="alert">
            {error}
          </div>}
        <h1 data-qa="title">
          <FormattedMessage {...T(`${'customer_create.new_customer'}`)} />
        </h1>
        <p data-qa="required-text">
          <FormattedMessage
            {...T('common.required_text')}
            values={{ mark: <span className="text-primary">*</span> }}
          />
        </p>
      </div>
    )
  }

  setFormRef = ({ name, form }) => (this[name] = form)

  handleFormBrandSettignsValidChange = isFormValid => {
    this.setState({ isBrandSettingsFormValid: isFormValid })
  }

  handleFormDeviceSettignsValidChange = isFormValid => {
    this.setState({
      isDeviceSettingsFormValid: isFormValid
    })
  }

  canCreatePatient = () => {
    const {
      isDeviceSettingsFormValid,
      practice,
      employee,
      deviceModelRightId,
      deviceModelLeftId,
      isPrefitingPatient
    } = this.state

    const isPersonalFormValidated = isPersonalFormValid(this.formPersonalInfo);
    const isPracticeInfoFormValid = practice != 0 && employee !== 0
    const isHearingFormProgramValid = areProgramsValid(
      getProgramsPayload(this.state),
      this.getRemoteFittingSupport(this.state)
    )

    if (isPrefitingPatient) {
      return isPersonalFormValidated
    }

    return (
      isHearingFormProgramValid &&
      isPersonalFormValidated &&
      isPracticeInfoFormValid &&
      isDeviceSettingsFormValid &&
      ((deviceModelRightId == '0' ? false : true) || (deviceModelLeftId == '0' ? false : true))
    )
  }

  getPersistentConfiguration() {
    const { teleCareVersion } = this.state

    const persistentSettings = {
      TELECARE_2_0: {
        persistentConfiguration: true,
        teleCareVersion: 'TELECARE_2_0'
      },
      TELECARE_1_0_PERSISTENT: {
        persistentConfiguration: true,
        teleCareVersion: 'TELECARE_1_0_PERSISTENT'
      },
      TELECARE_1_0_TEMPORARY: {
        persistentConfiguration: false,
        teleCareVersion: 'TELECARE_1_0_TEMPORARY'
      }
    }

    return persistentSettings[teleCareVersion]
  }

  getRemoteFittingSupport = state => {
    const { HDModelsFeatures = {} } = state
    return _.get(HDModelsFeatures, 'remoteFittingSupported', false)
  }

  createPatient = async () => {
    const valid = this.canCreatePatient()

    if (valid) {
      const countryCode = _.get(this.props.me, 'countryCode', '')
      const defaultModuleLength = _.get(this.props.me.employee, 'defaultModuleLength', '')
      const practiceId = this.state.practice || this.props.defaultPracticeId
      const employeeId = this.state.employee || _.get(this.props.me, ['employee', 'id'])
      const { firstName, lastName } = this.formPersonalInfo.getValues()
      const deviceModelLeft = this.state.deviceModelLeftId
      const deviceModelRight = this.state.deviceModelRightId
      const persistentSettings = this.getPersistentConfiguration()

      let phone = _.get(this.formPersonalInfo.getValues(), 'phone', '')
      if (!_.isNil(phone) && !_.isEmpty(phone)) {
        try {
          phone = await validatePhoneNumber({
            countryCode,
            phoneNumber: phone
          })
        } catch (e) {
          console.log('Invalid phone', e)
        }
      }

      const createPatientPayload = {
        firstName: _.trim(firstName),
        lastName: _.trim(lastName),
        phone: _.trim(phone),
        leftDevice: parseInt(deviceModelLeft) || null,
        rightDevice: parseInt(deviceModelRight) || null,
        ...persistentSettings,
        practiceId,
        employeeId,
        programs: getProgramsPayload(this.state)
      }

      this.setState(
        {
          isSaving: true
        },
        () => {
          this.props
            .createPatient({ patient: createPatientPayload })
            .then(data => {
              customerTracking({
                ...this.props.location,
                action: 'customerCreated'
              })
              return data
            })
            .then(this.onPatientCreated)
            .catch(error => tracker.track('AppError in onPatientCreated', { error }))
        }
      )
    }
  }

  addHidSettingInfo = id => {
    const patientId = parseInt(id)
    const { bluetoothDisabled } = this.state
    const hidSettingData = {
      patientId,
      bluetoothDisabled
    }
    this.props.updateHIDSetting({ hidSetting: hidSettingData })
  }
  onPatientCreated = ({ data: { createPatient: { id, phone } } }) => {
    if (!_.isEmpty(phone)) {
      this.sendInvitation(id, phone)
    }
    this.addHidSettingInfo(id)
    const dataObj = {
      hearingDevices: {
        leftDevice: {
          ...(this.state.deviceModelLeftId && this.state.deviceModelsLeft?.find(a => a.id === this.state.deviceModelLeftId))
        },
        rightDevice: {
          ...(this.state.deviceModelRightId && this.state.deviceModelsRight?.find(a => a.id === this.state.deviceModelRightId))
        }
      }
    }
    sendDataToDataCollection(DataCollectionType.Crud, PatientPayloadData(dataObj), EventType.Create)
    const pathname = `/profile/${id}/settings/setup`
    this.props.navigate(pathname)
  }

  sendInvitation(patientId, phone) {
    const token = getToken()
    return connector.postWithCorrelation(
      token,
      `/hcp/api/v1/patients/${patientId}/webcode/send-sms`,
      CorrelationContext['CreatePatient_SendWebCode'] + SupportId,
      { phone }
    )
  }

  onCancel = () => {
    this.props.navigate('/')
  }

  getBrandByName(name) {
    const brand = this.props.manufacturers.find(item => item.name === name)
    return _.pick(brand, ['name', 'availablePrograms', 'pairable'])
  }

  /**
   * On Brand Change
   * @param brand
   * @param devicePosition ('manufacturersLeft' || 'manufacturersRight')
   * @param selectedBrandPosition ('BrandRight' || 'BrandLeft')
   */
  onBrandSelect = ({ brand = '', brandPosition, selectedBrandPosition }) => {
    const selectedManufacturer = this.getBrandByName(brand)
    const { intl: { formatMessage } } = this.props
    const defaultMessage = formatMessage(T('hearing_device.manufacturer'))
    const selectedBrands = {
      ...(selectedBrandPosition === 'BrandRight'
        ? {
          selectedBrandRight: brand || null,
          selectedBrandLeft: brand || null
        }
        : {}),
      ...(selectedBrandPosition === 'BrandLeft'
        ? {
          selectedBrandLeft: brand || null,
          selectedBrandRight:
            _.isNil(this.state.selectedBrandRight) && defaultMessage != brand
              ? defaultMessage
              : this.state.selectedBrandRight == defaultMessage
                ? null
                : this.state.selectedBrandRight
        }
        : {})
    }
    if (_.isEmpty(selectedManufacturer)) {
      // reset device and brand selection for nonexisting brand
      const resetDeviceSettings = this.getResetDeviceSettings(selectedBrandPosition)
      return this.setState({
        [brandPosition]: this.props.manufacturers,
        ...selectedBrands,
        ...resetDeviceSettings
      })
    }

    const disabledManufacturers = _.map(this.props.manufacturers, item => {
      return {
        disabled: selectedManufacturer.pairable.indexOf(item.name) < 0,
        ...item
      }
    })

    this.setState({
      [brandPosition]: disabledManufacturers,
      ...selectedBrands,
      isBrandSettingsFormValid: false
    })
    this.fetchHdDevices({ selectedBrandPosition, brand })
  }

  fetchHdDevices = async ({ selectedBrandPosition, brand }) => {
    const response = await this.props.DeviceModelsQuery.refetch({
      brand,
      hasBrand: !!brand
    })
    const { data: { HDModels } } = response
    const modifiedHDModels = HDModels

    // when changing the right brand the left brand and models should inherit this settings.
    // when changing left brand only the left brand and model should be updated
    const selectedDeviceModels = {
      ...(selectedBrandPosition === 'BrandRight'
        ? {
          deviceModelsRight: modifiedHDModels,
          deviceModelsLeft: modifiedHDModels,
          // preselect first device model
          deviceModelLeftId: _.get(modifiedHDModels, [0, 'id'], null),
          deviceModelRightId: _.get(modifiedHDModels, [0, 'id'], null)
        }
        : {}),
      ...(selectedBrandPosition === 'BrandLeft'
        ? {
          deviceModelsLeft: modifiedHDModels,
          // preselect first device model
          deviceModelLeftId: _.get(modifiedHDModels, [0, 'id'], null)
        }
        : {})
    }

    this.setState(
      {
        ...selectedDeviceModels
      },
      () => {
        this.fetchFeaturesForDevices({
          leftModelId: this.state.deviceModelLeftId,
          rightModelId: this.state.deviceModelRightId
        })
      }
    )
  }

  fetchFeaturesForDevices = async ({ leftModelId = '0', rightModelId = '0' }) => {
    try {
      const { data } = await this.props.DeviceModelsFeaturesQuery.refetch({
        leftModelId: _.isNull(leftModelId) ? '0' : leftModelId,
        rightModelId: _.isNull(rightModelId) ? '0' : rightModelId,
        hasSelectedModels: !!leftModelId || !!rightModelId
      })
      const { HDModelsFeatures } = data
      this.setState({
        HDModelsFeatures,
        teleCareVersion: _.get(HDModelsFeatures, ['defaultAvailableTeleCareVersion'], null)
      })
    } catch (e) {
      tracker.track('AppError in fetchFeaturesForDevices', { error: e })
    }
  }

  onSelectDeviceModel = ({ deviceType, deviceModelId }) => {
    // prepopulate the left device when right is selected
    const updateDevices = getLeftRightDevice(deviceType, deviceModelId, this.state)

    this.setState(
      {
        ...updateDevices,
        deviceModelsLeft: this.state.deviceModelsLeft,
        deviceModelsRight: this.state.deviceModelsRight
      },
      () => {
        this.fetchFeaturesForDevices({
          leftModelId: this.state.deviceModelLeftId,
          rightModelId: this.state.deviceModelRightId
        })
      }
    )
  }

  onSelectPracticeInfo = async ({ info, id }) => {
    if (info === 'practice') {
      await this.props.EmployeesQuery.refetch({
        practiceId: id
      })
    }

    if (info === 'employee')
      await this.props.EmployeeInfoQuery.refetch({
        employeeId: id,
        hasSelectedEmployee: true
      })

    this.setState({
      [info]: id,
      ...(info === 'practice' ? { employee: _.get(this.props.me, ['employee', 'id']) } : {}) // selecting practice should reset employee selection
    })
  }

  getResetDeviceSettings = selectedBrandPosition => {
    return {
      ...(selectedBrandPosition === 'BrandRight'
        ? {
          deviceModelRightId: null,
          deviceModelLeftId: null,
          deviceModelsLeft: [],
          deviceModelsRight: []
        }
        : {}),
      ...(selectedBrandPosition === 'BrandLeft'
        ? {
          deviceModelLeftId: null,
          deviceModelsLeft: []
        }
        : {})
    }
  }

  // provide a list of manufactures
  getListOfManufacturers = defaults => {
    const { intl: { formatMessage } } = this.props
    const { manufacturersLeft = defaults, manufacturersRight = defaults } = this.state

    return {
      manufacturersLeft: [
        { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
        ...(manufacturersLeft || [])
      ],
      manufacturersRight: [
        { name: formatMessage(T('hearing_device.manufacturer')), id: '' },
        ...(manufacturersRight || [])
      ]
    }
  }

  // provide a list of deviceModels
  getListOfDeviceModels = (defaults = []) => {
    const { deviceModelsLeft = defaults, deviceModelsRight = defaults } = this.state

    return {
      deviceModelsLeft,
      deviceModelsRight
    }
  }

  onHearingProgramChange = hearingPrograms => {
    this.setState({ hearingPrograms })
  }

  handleFormPersonalInfoValidChange = isFormValid => {
    this.setState({ isPersonalFormValid: isFormValid })
  }

  onHIDSettingChange = () => {
    const { bluetoothDisabled } = this.state
    this.setState({
      bluetoothDisabled: !bluetoothDisabled
    })
  }

  addDefaultEmployeeToEmployeeList(employees) {
    const { employee } = this.props.me;
    const defaultEmployeeToPushEmployeList = {
      firstName: employee.firstName,
      id: employee.id,
      lastName: employee.lastName,
      __typename: "Employee",
    }

    this.setState({
      employeesList: [defaultEmployeeToPushEmployeList, ...employees]
    })
  }

  componentWillUpdate() {
    if (this.state.employeesList.length > 0)
      return;

    const { loading, me, employees } = this.props;
    if (loading)
      return;

    const { employee } = this.state;
    const defaultEmployeeId = employee || _.get(me, ['employee', 'id']);

    if (employees.filter(x => x.id == defaultEmployeeId)?.length > 0) {
      this.setState({
        employeesList: [...employees]
      })
    }
    else {
      this.addDefaultEmployeeToEmployeeList(employees);
    }
  }

  componentWillUnmount() {
    SupportId = getSupportId()
  }
  render() {
    const {
      loading,
      practices,
      defaultPracticeId,
      manufacturers,
      deviceModels,
      me,
      EmployeeInfoQuery,
      intl: { formatMessage }
    } = this.props

    if (loading || this.state.isSaving) {
      return <Loading isLoading={loading || this.state.isSaving} />
    }

    const countryCode = _.get(me, 'countryCode', '')
    const { manufacturersLeft, manufacturersRight } = this.getListOfManufacturers(manufacturers)
    const {
      deviceModelsLeft: defaultModelsLeft,
      deviceModelsRight: defaultModelsRight
    } = this.getListOfDeviceModels(deviceModels)

    const {
      deviceModelsLeft = defaultModelsLeft,
      deviceModelsRight = defaultModelsRight,
      deviceModelLeftId,
      deviceModelRightId,
      hearingPrograms = [],
      selectedBrandRight,
      selectedBrandLeft,
      employee,
      practice,
      HDModelsFeatures,
      bluetoothDisabled,
      customPrograms = [[]],
      employeesList
    } = this.state

    const canCreatePatient = this.canCreatePatient()
    const defaultModuleLength = _.get(
      EmployeeInfoQuery,
      ['Employee', 'defaultModuleLength'],
      _.get(me, ['employee', 'defaultModuleLength'])
    )

    const submitAction = this.createPatient

    const HIDData = {
      manufacturersLeft,
      manufacturersRight,
      deviceModelsLeft,
      deviceModelsRight,
      deviceModelLeftId,
      deviceModelRightId,
      hearingPrograms,
      selectedBrandRight,
      selectedBrandLeft,
      HDModelsFeatures,
      bluetoothDisabled,
      customPrograms
    }
    return (
      <section className="container">
        <PatientCreatedEditForm onSubmit={submitAction}
          employee={employee || _.get(me, ['employee', 'id'])}
          practice={practice || defaultPracticeId}>
          {this.renderHeadLine()}
          <PatientPersonalInfo
            countryCode={countryCode}
            formatMessage={formatMessage}
            onValidationChange={this.handleFormPersonalInfoValidChange}
            setFormRef={this.setFormRef}
          />

          <div>
            <Divider />
            <HearingInstrumentSettings
              HIDData={HIDData}
              handleFormBrandSettignsValidChange={this.handleFormBrandSettignsValidChange}
              handleFormDeviceSettignsValidChange={this.handleFormDeviceSettignsValidChange}
              onBrandSelect={this.onBrandSelect}
              setFormRef={this.setFormRef}
              onSelectDeviceModel={this.onSelectDeviceModel}
              onHIDSettingChange={this.onHIDSettingChange}
              onHearingProgramChange={this.onHearingProgramChange}
            />
          </div>
          <Divider />
          <PracticeInfo
            formatMessage={formatMessage}
            setFormRef={this.setFormRef}
            practices={practices}
            employees={employeesList}
            onSelect={this.onSelectPracticeInfo}
          />
          <ActionButtonsCreate>
            <Button secondary onClick={this.onCancel} className="btn--min-width" data-qa="cancel-button">
              {formatMessage(T('common.cancel'))}
            </Button>
            <Button type="submit" primary disabled={!canCreatePatient} className="btn--min-width" data-qa="save-button">
              {formatMessage(T('common.create'))}
            </Button>
          </ActionButtonsCreate>
        </PatientCreatedEditForm>
      </section>
    )
  }
}

const QUERY = gql`
  query UserQuery($orgId: Int) {
    Me {
      roles
      state
      countryCode
      employee {
        id
        firstName
        lastName
        defaultPracticeId
        defaultModuleLength
      }
    }
    AssignedPractices(id: $orgId) {
      id
      name
    }
    Brands(id: $orgId) {
      id
      name
      pairable
      haBrandId
    }
  }
`

const QUERY_EMPLOYEES = gql`
  query EmployeesQuery($practiceId: Int) {
    Employees(practiceId: $practiceId) {
      id
      firstName
      lastName
    }
  }
`
const QUERY_EMPLOYEE_INFO = gql`
  query EmployeeInfoQuery($employeeId: Int!, $hasSelectedEmployee: Boolean!) {
    Employee(id: $employeeId) @include(if: $hasSelectedEmployee) {
      id
      defaultModuleLength
    }
  }
`

const QUERY_HDMODELS_FEATURES = gql`
  query DeviceModelsFeaturesQuery(
    $leftModelId: String!
    $rightModelId: String!
    $hasSelectedModels: Boolean!
  ) {
    HDModelsFeatures(leftModelId: $leftModelId, rightModelId: $rightModelId)
      @include(if: $hasSelectedModels) {
      availablePrograms
      bluetoothSupported
      defaultPrograms
      availableTeleCareVersions
      programSlots
      remoteFittingSupported
      defaultAvailableTeleCareVersion
    }
  }
`
const QUERY_HDMODELS = gql`
  query ModelsQuery($brand: String!, $hasBrand: Boolean!) {
    HDModels(brand: $brand, sort: "model") @include(if: $hasBrand) {
      id
      manufacturer
      model
    }
  }
`
const MUTATION_CREATE_PATIENT = gql`
  mutation CreatePatientMutation($patient: PatientInput!) {
    createPatient(patient: $patient) {
      id
      firstName
      lastName
      phone
      email
      practiceId
      employeeId
      hearingDevices {
        leftDevice {
          id
        }
        rightDevice {
          id
        }
        hdPrograms {
          name
          type
          programNumber
        }
        persistentConfiguration
        teleCareVersion
      }
    }
  }
`
const MUTATION_ADD_HID_SETTING = gql`
  mutation UpdateHIDSettingtMutation($hidSetting: HIDSettingInput!) {
    updateHIDSetting(hidSetting: $hidSetting)
  }
`

export const mapQueryToVariables = ({
  params: { defaultPracticeId: practiceId = null },
  brand = '',
  leftModelId = '',
  rightModelId = '',
  employeeId = 0
}) => {
  return {
    practiceId: parseInt(practiceId),
    brand,
    hasBrand: !!brand,
    leftModelId,
    rightModelId,
    hasSelectedModels: !!rightModelId || !!leftModelId,
    employeeId: parseInt(employeeId),
    hasSelectedEmployee: !!employeeId && employeeId > 0,
    orgId: parseInt(getOrganizationIdFromCache())
  }
}

const options = ({ params, brand, leftModelId, rightModelId, employeeId }) => ({
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only',
  variables: mapQueryToVariables({
    params: params,
    brand,
    leftModelId,
    rightModelId,
    employeeId: employeeId
  })
})

const mapProps = ({
  PatientQuery: { loading, refetch, Me, Brands, AssignedPractices = [] },
  PatientQuery,
  ownProps: { location, intl }
}) => {
  const inactiveFeatures = _.get(Me, `inactiveFeatures`, [])
  const enableHearingTraining = inactiveFeatures.indexOf('LESSON_ACTIVE_JOURNEY') === -1
  const defaultPracticeId = _.get(Me, ['employee', 'defaultPracticeId'], '')

  return {
    me: Me,
    PatientQuery,
    refetch,
    loading,
    manufacturers: Brands,
    enableHearingTraining,
    practices: AssignedPractices,
    defaultPracticeId: parseInt(defaultPracticeId, 10),
    location,
    intl
  }
}

const mapPropsEmployees = ({
  EmployeesQuery: { loading, refetch, Employees = [] },
  EmployeesQuery,
  ownProps: { location, intl }
}) => {
  return {
    EmployeesQuery,
    refetch,
    loading,
    employees: Employees,
    location,
    intl
  }
}

const mapPropsEmployeeInfo = ({
  EmployeeInfoQuery: { loading, refetch, Employee = {} },
  EmployeeInfoQuery
}) => {
  return {
    EmployeeInfoQuery,
    refetch,
    loading,
    employee: Employee
  }
}

const mapPropsDeviceModels = ({
  DeviceModelsQuery: { loading, refetch, presetSelected },
  DeviceModelsQuery,
  ownProps: { location, intl }
}) => {
  let presetName = 'STANDARD'

  if (typeof presetSelected !== 'undefined') {
    presetName = presetSelected == null ? 'CUSTOM' : presetSelected
  }
  return {
    DeviceModelsQuery,
    deviceModels: [],
    refetch,
    loading,
    presetName,
    location,
    intl
  }
}

const mapPropsDeviceModelsFeatures = ({
  DeviceModelsFeaturesQuery: { loading, refetch },
  DeviceModelsFeaturesQuery
}) => {
  return {
    DeviceModelsFeaturesQuery,
    refetch,
    loading
  }
}

const withData = compose(
  graphql(QUERY_HDMODELS, {
    name: 'DeviceModelsQuery',
    props: mapPropsDeviceModels,
    options
  }),
  graphql(QUERY_HDMODELS_FEATURES, {
    name: 'DeviceModelsFeaturesQuery',
    props: mapPropsDeviceModelsFeatures,
    options
  }),
  graphql(QUERY_EMPLOYEES, {
    name: 'EmployeesQuery',
    props: mapPropsEmployees,
    options
  }),
  graphql(QUERY_EMPLOYEE_INFO, {
    name: 'EmployeeInfoQuery',
    props: mapPropsEmployeeInfo,
    options
  }),
  graphql(QUERY, {
    name: 'PatientQuery',
    props: mapProps,
    options
  }),
  graphql(MUTATION_CREATE_PATIENT, {
    props: ({ mutate }) => ({
      createPatient: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_ADD_HID_SETTING, {
    props: ({ mutate }) => ({
      updateHIDSetting: variables =>
        mutate({
          variables
        })
    })
  }),
  injectIntl
)

export default withRouter(withQuery(withData(PatientCreatePage)))
