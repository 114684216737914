import { gql } from "@apollo/client";

export const MUTATION_UPDATE_INVITED_EMPLOYEE = gql`
  mutation updateInvitedEmployee($employeeId: Int!, $orgId: Int!, $key: String, $tenantId: Int!, $employee: EmployeeInputWithUserDataConsent!) {
    updateInvitedEmployee(employeeId: $employeeId,orgId:$orgId, key: $key, tenantId: $tenantId, employee: $employee) {
      id
      inactiveFeatures
      firstName
      lastName
      locale
      phone
      phoneVerified
      email
      username
      manager
      orgManager
      assignedPracticeIds
      defaultPracticeId
      notificationTime
      userDataConsent
    }
  }
`

export const MUTATION_UPDATE_INVITED_EMPLOYEE_PHONE_VERIFICATION_STATUS = gql`
  mutation updateInvitedEmployeePhoneVerificationStatus($employeeId: Int!, $orgId: Int!, $key: String, $tenantId: Int!, $empPhoneUpdateRequest: EmpPhoneUpdateRequest!) {
    updateInvitedEmployeePhoneVerificationStatus(employeeId: $employeeId, orgId: $orgId, key: $key, tenantId: $tenantId, empPhoneUpdateRequest: $empPhoneUpdateRequest) {
      phone
      phoneVerified
    }
  }
`