import React, { Component } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { injectIntl, FormattedMessage } from 'react-intl'
import { T } from 'core'
import { ImageSizeInformation } from 'styledComponents/Div/DivComponent/DivForm'
import StyledDropzone from 'styledComponents/StyledDropzone'
import { generateRandomFileName } from 'helper/Utils'

class ImageDropField extends Component {
  static defaultProps = {
    multiple: false
  }

  constructor(props) {
    super(props)
    this.state = {
      imageRejected: false,
      editing: false,
      scale: 1.0,
      fileInfo: null
    }
  }

  onDrop = files => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      this.props.form.setFieldValue('avatar', reader.result)
    })
    const renamedAcceptedFiles = files.map((file) => (
      new File([file], `${generateRandomFileName(file?.name, 5)}`, { type: file.type })
    ))
    if (renamedAcceptedFiles.length > 0) {
      reader.readAsDataURL(renamedAcceptedFiles[0])
      this.setState({ imageRejected: false })
    }
  }

  onDropRejected = files => {
    if (files.length > 0) {
      this.setState({ imageRejected: true })
    }
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  startEdit = () => {
    this.setState({
      editing: true
    })
  }

  cancelEdit = () => {
    this.setState({
      editing: false
    })
  }

  applyEdit = () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL()
    this.props.form.setFieldValue('avatar', img)
    const state = {
      editing: false
    }
    this.setState(state)
  }

  deleteImage = () => {
    const state = {
      editing: false
    }
    this.setState(state)
    this.props.form.setFieldValue('avatar', '')
  }

  renderPreview() {
    const image = this.props.field.value
    return (
      <div className="image-upload__widget">
        <img src={`${image}`} width={200} height={200} />
        <div className="hover-options__container">
          <div className="buttons">
            <a className="btn fas fa-trash delete pull-left" onClick={this.deleteImage} />
            <a className="btn fas fa-edit edit pull-right" onClick={this.startEdit} />
          </div>
        </div>
      </div>
    )
  }

  renderEditing() {
    const { scale } = this.state
    const image = this.props.field.value
    return (
      <div className="image-upload__widget">
        <AvatarEditor
          ref={this.setEditorRef}
          image={`${image}`}
          width={200}
          height={200}
          border={0}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          rotate={0}
          crossOrigin="use-credentials"
        />
        <div className="hover-options__container editing">
          <input
            className="range"
            name="scale"
            type="range"
            onChange={this.handleScale}
            min="1"
            max="2"
            step="0.01"
            defaultValue="1"
          />
          <div className="buttons">
            <a className="btn fas fa-ban cancel pull-left" onClick={this.cancelEdit} />
            <a className="btn fas fa-check apply pull-right" onClick={this.applyEdit} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { editing } = this.state
    const { isReadOnly } = this.props
    const image = this.props.field.value
    const MAX_FILE_SIZE = 2000000
    if (image && !editing) return this.renderPreview()
    if (image) return this.renderEditing()
    // empty state
    return (
      <div>
        <StyledDropzone
          disabled={isReadOnly}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          accept="image/*"
          maxSize={MAX_FILE_SIZE}
        />
        <ImageSizeInformation isRejected={this.state.imageRejected}>
          <FormattedMessage {...T('ImageUpload.max_allowed_size')} />
        </ImageSizeInformation>
      </div>
    )
  }
}

export default injectIntl(ImageDropField)
