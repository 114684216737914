import { STORAGE_KEY } from 'helper/constants'

import * as cacheStateVariables from '../cache/apolloLocalCache'

export default {
  Mutation: {
    login: (_, { authentication }, { cache }) => {
      
      localStorage.setItem(STORAGE_KEY, JSON.stringify(authentication))
      const data = {
          ...cacheStateVariables.authStateVar(),
          auth: authentication.access_token
      }      
      cacheStateVariables.authStateVar({ ...data })
      return null
    },
    setDefaultPractice: (_, { defaultPractice }, { cache }) => {
      const data = {
          ...cacheStateVariables.defaultPracticeStateVar(),
          id: defaultPractice.defaultPractice.id,
          name: defaultPractice.defaultPractice.name,
          timeZone: defaultPractice.defaultPractice.timeZone
      }      
      cacheStateVariables.defaultPracticeStateVar({ ...data })
      return null
    },
    setLocale: (_, locale, { cache }) => {
      const { localStorage } = window
      if (localStorage) {
        localStorage.setItem('locale', JSON.stringify(locale))
      }
      const data = {
          ...cacheStateVariables.localeStateVar(),
          locale: locale.locale
        }        
      cacheStateVariables.localeStateVar({ ...data })
    },
    setCountries: (_, { data }, { cache }) => {      
      const updatedData = {
          __typename: 'countries',
          data: data
      }
      cacheStateVariables.countriesStateVar({ ...updatedData })
      return null
    },
    updatePopup: (_, data, { cache }) => {
      const updatedData = {
          ...cacheStateVariables.overlayStateVar(),
          __typename: 'overlay',
          popup: data.popup,
          isOpen: data.popup !== null ? true : false
      }      
      cacheStateVariables.overlayStateVar({ ...updatedData })
      return null
    },
    updateModal: (_, data, { cache }) => {
      const updatedData = {
          ...cacheStateVariables.overlayStateVar(),
          __typename: 'overlay',
          modal: data.modal || prevState.overlay.modal,
          isOpen: data.isOpen || prevState.overlay.isOpen
      }      
      cacheStateVariables.overlayStateVar({ ...updatedData })
      return null
    },
    updateOverlay: (_, { popup, modal, isOpen }, { cache }) => {
      const updatedData = {
        ...cacheStateVariables.overlayStateVar(),
          __typename: 'overlay',
          popup,
          modal,
          isOpen
      }      
      cacheStateVariables.overlayStateVar({ ...updatedData })
      return null
    },
    setLocation: (_, { location, key, value }, { cache }) => {
      const updatedData = {
        ...cacheStateVariables.locationStateVar(),
          __typename: 'location',
          ...location,
          query: {
            ...location.query,
            [key]: value
          },
          search: '?' + key + '=' + value,
          isQueryActive: true
      }      
      cacheStateVariables.locationStateVar({ ...updatedData })
      return { isQueryActive: true }
    },
    updateLocation: (_, { location, key }, { cache }) => {
      const updatedData = {
        ...cacheStateVariables.locationStateVar(),
          __typename: 'location',
          ...location,
          query: {},
          search: '',
          key,
          isQueryActive: false
      }      
      cacheStateVariables.locationStateVar({ ...updatedData })
      return { isQueryActive: false }
    }
  }
}
