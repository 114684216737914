import React, { useContext } from "react"
import  TourGuide from 'tourGuide'
import DataContext from '../containers/DataContext'
// TODO: find better way to mock filter function.
// Current implementation done like described here: https://stackoverflow.com/questions/51756316/jest-enzyme-mock-function-in-functional-component
import * as comp from './withTourGuide'; 

export default function withTourGuide(WrappedComponent, steps) {

    const GuidedComponentWrapper = (props) => {
      const { displayTourGuide } = useContext(DataContext);
      
      const stepsWithTargetInDOM = comp.filterByTargetInDOM(steps);

      return (
      <>
      { displayTourGuide &&
        <TourGuide steps={stepsWithTargetInDOM} />  
      }
        <WrappedComponent {...props} />
      </>
      );
    }

    GuidedComponentWrapper.displayName = `withTourGuide(${getDisplayName(WrappedComponent)})`
    return GuidedComponentWrapper
  }

export const filterByTargetInDOM = (stepsToFilter) => stepsToFilter?.filter(item => { return (document.querySelector(item.target) != null); });

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}