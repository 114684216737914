import PropTypes from 'prop-types'
import React from 'react'

const SortableTableHeader = ({ name, value, onClick, className, sort = '' }) => {
  const byField = sort.split(',')[0]
  const order = sort.split(',')[1]
  const sortIndicatorClass = `fas fa-fw fa-sort${byField === name
    ? `-${order === 'asc' ? 'up' : 'down'}`
    : ''}`

  return (
    <th className={className} onClick={() => onClick(name)}>
      {value} <i className={sortIndicatorClass} />
    </th>
  )
}

SortableTableHeader.propTypes = {
  attribute: PropTypes.string,
  value: PropTypes.string,
  activeAttribute: PropTypes.string,
  className: PropTypes.string,
  order: PropTypes.string,
  onClick: PropTypes.func
}

export default SortableTableHeader
