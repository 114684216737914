import { gql } from "@apollo/client";

export const PRACTICES_QUERY = gql`
    query PracticesQuery($orgId: Int!) {
        Me {
            employee {
                id
                orgManager
                manager
            }
        }
        AssignedPractices(id: $orgId) {
          id
          name
        }
    }
`

export const PRACTICES_QUERY_LIST = gql`
  query PracticesListQuery( $sort: String, $page: Int, $search: String, $order: String, $orgId: Int) {
    PracticesList(
      sort: $sort
      page: $page
      search: $search
      order: $order
      orgId: $orgId
    ) {
        content {
            id
            name
            street
            city
            phone
        }
        pagination {
        pageNumber
        pageSize
        numberOfElements
        totalElements
        totalPages
        }
        
    }
    Me {
        employee {
            id
            orgManager
            manager
        }
    }
  }
`

export const DEFAULT_PRACTICE_QUERY = gql`
query DefaultPracticeQuery{
    defaultPractice @client {
        id
        name
        timeZone
  }
}
`
export const DEFAULT_PRACTICE_WITH_ACCOUNT_TYPE_QUERY = gql`
  query defaultPracticeWithAccountTypeQuery {
    defaultPracticeWithAccountType @client {
      defaultPracticeId,
      isAccountNumberStartsWithT
    }
  }
`

export const QUERY_PRACTICE_DETAILS = gql`
  query PracticeQuery($practiceId: Int!) {
    Practice(id: $practiceId) {
      id
      name
      timeZone
      city
      countryCode
      accountNumber
    }
  }
`
export const QUERY_ASSIGNED_PRACTICES = gql`
  query AssignedPracticesQuery($orgId: Int!) {
    AssignedPractices(id: $orgId) {
      id
      name
    }
  }
`
