import { gql } from "@apollo/client";

export const QUERY_EMPLOYEE_SELF_INVITE = gql`
  query InviteEmployeeQuery($email: String!, $key: String, $orgId: String, $tenantId: String, $employeeId: String ) {
    InvitedEmployee(email: $email, key:$key, orgId:$orgId, tenantId:$tenantId, employeeId:$employeeId) {
      id
      avatar {
        id
        url
      }
      inactiveFeatures
      firstName
      lastName
      locale
      phone
      phoneVerified
      email
      emailVerified
      username
      manager
      orgManager
      assignedPracticeIds
      defaultPracticeId
      defaultModuleLength
      notificationTime
    }
  }
`