import React from 'react'

const ProgressBar = ({ percent }) => {
  return (
    <span
      data-qa="changer__block changer__block--gain"
      className="changer__block changer__block--gain"
      style={{ opacity: 90 }}
    >
      <span
        data-qa="changer__block changer__block--progress"
        className="changer__block changer__block--progress"
        style={{ width: `${percent}%` }}
      />
    </span>
  )
}

const GainStepper = ({ min = -12, max = 9, master = 0, values = {}, onChange }) => {
  const checkDisabled = topValue =>
    [values.band1, values.band2, values.band3, values.band4].every(band => band === topValue)

  const range = Math.abs(min) + Math.abs(max)
  const progress = master > 0 ? range - max + master : range - max - Math.abs(master)
  const percent = Math.ceil(progress / range * 100)

  const increase = () => {
    onChange('increase')
  }
  const decrease = () => {
    onChange('decrease')
  }

  return (
    <div data-qa="changer--master" className="changer">
      <div className="changer__inner">
        <button
          data-qa="changer__button decrease"
          type="button"
          className="changer__button changer__button--left"
          onClick={decrease}
          disabled={checkDisabled(min)}
        >
          <i className="fas fa-fw fa-minus" />
        </button>
        <div data-qa="changer__bar" className="changer__bar">
          <ProgressBar percent={percent} />
        </div>
        <button
          type="button"
          data-qa="changer__button increase"
          className="changer__button changer__button--right"
          onClick={increase}
          disabled={checkDisabled(max)}
        >
          <i className="fas fa-fw fa-plus" />
        </button>
      </div>
    </div>
  )
}

export default GainStepper
