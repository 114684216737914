import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { StyledSearchInput } from 'styledComponents/Input'
import { SearchWrapper, ActionIcon } from 'styledComponents/Div/DivContainer/DivPatientList'
import { T } from 'core'
import { omit } from 'lodash'
import queryString from 'query-string';
import { withRouter } from "helper/withRouter";


export class SearchBar extends Component {
  componentDidMount() {
    if (this.input) {
      this.input.value = `${unescape(this.props.query.search || '')}`
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.input) {
      this.input.value = `${unescape(nextProps.query.search || '')}`
    }
  }

  onClickActionItem = isSearchActive => () => {
    isSearchActive ? this.clearSearchTerm() : this.fireSearch(this.input.value.trim())
  }

  /* new search should start from first page / 0 */
  getPageNumber = search => {
    const { query: { search: currentSearch = '', page = 0 } } = this.props

    return !currentSearch || currentSearch !== search ? 0 : parseInt(page, 10)
  }

  fireSearch = (search = '') => {
    const query = {
      ...this.props.query,
      search,
      page: this.getPageNumber(search)
    }
    this.props.location.search = queryString.stringify(query)
    this.props.navigate(this.props.location)
  }

  onKeyPressInputSearch = e => {
    if (e.key === 'Enter') {
      this.fireSearch(e.target.value.trim())
    }
  }

  clearSearchTerm = () => {
    const query = omit(this.props.query, 'search')
    this.props.navigate({
      pathname: this.props.location.pathname,
      query
    })
  }

  render() {
    const { intl: { formatMessage }, query } = this.props
    const isSearchActive = !!query.search

    return (
      <SearchWrapper>
        <StyledSearchInput
          type="text"
          placeholder={formatMessage(T('searchBar.placeholder'))}
          ref={ref => (this.input = ref)}
          onKeyPress={this.onKeyPressInputSearch}
        />
        <ActionIcon isActive={isSearchActive} onClick={this.onClickActionItem(isSearchActive)} />
      </SearchWrapper>
    )
  }
}

export default withRouter(injectIntl(SearchBar))
