import _ from 'lodash'
import { StorageKeyHcpContactVerificationInfoPopup } from 'helper/constants'

export const isUserEmailOrPhoneUnverified = (employeeId: number, email: string, emailVerified: boolean, phone: string, phoneVerified: boolean, username: string) => {
  const isUserUsingUsername = !!username && !email
  const isContactVerfied = (isUserUsingUsername ? true : emailVerified) && (_.isEmpty(phone) ? true : phoneVerified)
  if (isContactVerfied)
    resetDontRemindAgainIfContactVerified(employeeId, emailVerified, phoneVerified)
  return !isContactVerfied
}

export const getShowPopupInfoFromLocalStorage = (employeeId: number) => {
  const user = getUserFromLocalStorage(employeeId)
  return !!!_.get(user, "dontRemindAgain")
}

export const getShowPopupInfoFromSessionStorage = (employeeId: number) => {
  const user = JSON.parse(sessionStorage.getItem(StorageKeyHcpContactVerificationInfoPopup))
  if (user?.employeeId == employeeId) return !user.shownInCurrentSession
  else return true
}

export const setDontRemindAgainStatusInSessionStorage = (employeeId: number) => {
  const popupInfo = { employeeId: employeeId, shownInCurrentSession: true }
  sessionStorage.setItem(StorageKeyHcpContactVerificationInfoPopup, JSON.stringify(popupInfo))
}

export const setDontRemindAgainStatusInLocalStorage = (employeeId: number, emailVerified: boolean, phoneVerified: boolean) => {
  const user = createLocalStorageUser(employeeId, true, emailVerified, phoneVerified)
  setUserInLocalStorage(user)
}

const resetDontRemindAgainIfContactVerified = (employeeId: number, emailVerified: boolean, phoneVerified: boolean) => {
  const user = getUserFromLocalStorage(employeeId)
  if (!!_.get(user, "dontRemindAgain")) {
    const updatedUser = createLocalStorageUser(employeeId, false, emailVerified, phoneVerified)
    setUserInLocalStorage(updatedUser)
  }
}

const getUserFromLocalStorage = (employeeId: number) => {
  const items = JSON.parse(getAllUsersListFromLocalStorage()) ?? []
  const userInfo = _.find(items, function (user) { return user.employeeId == employeeId })
  return userInfo
}

const setUserInLocalStorage = (user) => {
  let allItems = JSON.parse(getAllUsersListFromLocalStorage()) ?? []
  const indexOfExistingUser = _.findIndex(allItems, function (existingUser) { return existingUser.employeeId == user.employeeId })
  if (indexOfExistingUser >= 0)
    allItems.splice(indexOfExistingUser, 1, user)
  else
    allItems.push(user)

  localStorage.setItem(StorageKeyHcpContactVerificationInfoPopup, JSON.stringify(allItems))
}

const getAllUsersListFromLocalStorage = () => {
  const users = localStorage.getItem(StorageKeyHcpContactVerificationInfoPopup)
  return users
}

const createLocalStorageUser = (employeeId: number, dontRemindAgain: boolean = false, emailVerified: boolean = false, phoneVerified: boolean = false) => {
  return {
    employeeId: employeeId,
    dontRemindAgain: dontRemindAgain,
    emailVerified: emailVerified,
    phoneVerified: phoneVerified
  }
}
