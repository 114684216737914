import React from 'react'
import DataContext from "containers/DataContext"
import connector from 'helper/HttpConnector'
import VideoCallManager from 'components/_VideoCall/Icelink/VideoCallManager'

export default class VideoDebugView extends React.Component {
  static contextType = DataContext;

  state = {
    sessionId: '',
    connected: false,
    joined: false,
    streamFailure: false,
    videoMuted: false,
    audioMuted: false,
    reconnecting: false,
    remoteJoined: false,
    closed: true
  }

  UNSAFE_componentWillMount() {
    this.videoCallManager = new VideoCallManager()
    window.videoCallManager = this.videoCallManager
    this.videoCallManager.on('change', this.onVideoManagerChange)
  }

  componentWillUnmount() {
    this.videoCallManager.off('change', this.onVideoManagerChange)
  }

  onVideoManagerChange = (newState) => {
    const { remoteJoined, closing, closed } = newState
    if (remoteJoined && !this.state.remoteJoined) {
      this.setupLayout()
    }

    if (closing && !closed && !this.closing) {
      console.log('cloosing.....')
      this.leave({ offerReconnect: true })
    }

    this.setState({
      ...newState
    })
  }

  async initiateCall(customerId, token, options = { audioOnly: false, sessionId: null }) {
    const callEndpoint = `/hcp/api/v1/patient/${customerId}/video-call`
    const sessionId = options.sessionId || undefined

    const data = {}
    if (options.audioOnly) {
      data['callType'] = 'AUDIO'
    }

    try {
      const response = await connector.postUrl(token, callEndpoint, data)
      return {
        ...response,
        iceServers: [
          new fm.icelink.IceServer(response.iceLinkStunUrl),
          new fm.icelink.IceServer(
            response.iceLinkTurnUrl,
            response.turnServerUsername,
            response.turnServerPassword
          )
        ],
        sessionId: sessionId || response.sessionId // make sessionId setable from outside
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  async setupLayout() {
    const { localMedia, remoteMedia } = this.videoCallManager

    if (!this.layoutManager) {
      this.layoutManager = new fm.icelink.DomLayoutManager(this.videoContainer)
      window.fmLayoutManager = this.layoutManager
    }

    const localView = localMedia.getView()
    const remoteView = remoteMedia ? remoteMedia.getView() : null

    if (localView && !this.layoutManager.getLocalView()) {
      this.layoutManager.setLocalView(localView)
    }

    if (remoteView != null) {
      this.layoutManager.addRemoteView(remoteMedia.getId(), remoteView)
    }
  }

  leave = async() => {
    this.videoCallManager.leave()
    await this.videoCallManager.stopLocalMedia()
    if (this.layoutManager != null) {
      this.layoutManager.removeRemoteViews()
      this.layoutManager.unsetLocalView()
      this.layoutManager = null
    }
    // Tear down the local media.
    if (this.videoCallManager.localMedia != null) {
      this.videoCallManager.localMedia = null
    }

    //back to default state
    this.setState({})
  }

  startVideoCall = async() => {
    const { customerId, sessionId } = this.state
    const { token } = this.context
    const connectionDetails = await this.initiateCall(customerId, token, { sessionId })
    this.videoCallManager.configure({ ...connectionDetails, name: 'george' })
    await this.videoCallManager.startLocalMedia({ video: true, audio: true })
    await this.videoCallManager.join()
  }

  startAudioOnly = async() => {
    const { customerId, sessionId } = this.state
    const { token } = this.context
    const connectionDetails = await this.initiateCall(customerId, token, {
      audioOnly: true,
      sessionId
    })
    this.videoCallManager.configure({ ...connectionDetails, name: 'george' })
    await this.videoCallManager.startLocalMedia({ video: false, audio: true })
    await this.videoCallManager.join()
  }

  sendMessage(msg) {
    this.videoCallManager.sendMessage(msg)
  }

  onApplySettings = () => {
    setTimeout(() => {
      this.sendMessage({ applySettings: false })
    }, 5000)

    this.sendMessage({ applySettings: true })
  }

  // async toggleAudioMute() {
  //   if (!this.videoCallManager.localMedia) return
  //   const audioTrack = this.videoCallManager.localMedia.getAudioTrack()
  //   audioTrack.setMuted(!audioTrack.getMuted())
  //   this.setState({
  //     audioMuted: audioTrack.getMuted()
  //   })
  //   this.sendMessage({
  //     audioMuted: audioTrack.getMuted()
  //   })
  //   return audioTrack.getMuted()
  // }

  // async toggleVideoMute() {
  //   if (!this.videoCallManager.localMedia) return
  //   const videoTrack = this.videoCallManager.localMedia.getVideoTrack()
  //   videoTrack.setMuted(!videoTrack.getMuted())
  //   this.setState({
  //     videoMuted: videoTrack.getMuted()
  //   })
  //   this.sendMessage({
  //     videoMuted: videoTrack.getMuted()
  //   })
  //   return videoTrack.getMuted()
  // }

  // isLocalAudioMuted() {
  //   if (!this.videoCallManager.localMedia) return true
  //   return this.videoCallManager.localMedia.getAudioTrack().getMuted()
  // }

  // isLocalVideoMuted() {
  //   if (!this.videoCallManager.localMedia) return true
  //   if (!this.videoCallManager.localMedia._internal._video) return true
  //   return false
  // }

  handleChange = () => {
    this.setState({
      sessionId: this.sessionTextField.value,
      customerId: this.customerIdTextField.value
    })
  }

  render() {
    const { sessionId, customerId, videoMuted, audioMuted } = this.state

    return (
      <div>
        <label>
          sessionId:<input
            type="text"
            onChange={this.handleChange}
            value={sessionId}
            ref={ref => (this.sessionTextField = ref)}
          />
        </label>
        <label>
          customerId<input
            type="text"
            onChange={this.handleChange}
            value={customerId}
            ref={ref => (this.customerIdTextField = ref)}
          />
        </label>
        <button onClick={this.startVideoCall}>join videocall</button>
        <button onClick={this.startAudioOnly}>join audiocall</button>
        <button onClick={this.leave}>leave call</button>

        <button onClick={this.videoCallManager.toggleAudioMute}>
          {audioMuted ? `enable audio` : `mute audio`}
        </button>
        <button onClick={this.videoCallManager.toggleVideoMute}>
          {videoMuted ? `enable video` : `mute video`}
        </button>
        <button onClick={this.onApplySettings}>apply settings</button>
        <div ref={ref => (this.videoContainer = ref)} style={{ width: 500, height: 500 }} />
      </div>
    )
  }
}
