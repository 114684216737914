import styled from 'styled-components'
import { ActionButtons } from '../'

export const ActionButtonsRestartSection = styled(ActionButtons)`
	margin-top: 30px;
	justify-content: space-between;
	> button {
		white-space: nowrap;
		min-width: 200px;
		margin-left: 15px;
	}
`

export const ActionButtonsManageLessons = styled(ActionButtons)`
	margin-top: 30px;
	> button {
		width: 200px;
	}
`

export const ActionButtonsStartTrial = styled(ActionButtons)`
	margin: 40px -15px 20px -15px;
	> button {
		min-width: 200px;
	}
`

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`

export const PatientNavigationItem = styled.div`min-width: 180px;`

export const PatientDetailsContainer = styled.div`
	font-size: 12px;
	color: #000;

	> h2 {
		text-transform: uppercase;
		font-size: 32px;
		margin-bottom: 5px;
		display: inline-block;
		vertical-align: middle;
	}

	.fa-circle {
		margin-right: 5px;
		color: #d70f28;

		&--active {
			color:#3399FF;
		}
	}
`

export const SatisfactionDetails = styled.div`
	margin-top: 40px;
	padding-top: 20px;
	border-top: 1px solid #cacaca;

	p:first-of-type {
		font-weight: bold;
	}
`

export const NavigationCol = styled.div`
  padding-right: 0;
  @media (min-width: 992px) {
		&:last-of-type {
		  padding-right: 15px;
		} 
	}
`
