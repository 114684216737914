import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { default as scrollTo } from 'scroll-to-element'
import _ from 'lodash'
import { T } from 'core'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Loading from 'components/Loading'
import { EmployeeFormContainer } from 'styledComponents/Div/DivContainer/DivEmployee'
import { withQuery } from 'helper/withQuery'
import { withRouter } from "helper/withRouter";
import InputField from 'formfields/InputField'
import PracticesSelectField from 'formfields/PracticesSelectField'
import PasswordComponent from 'formfields/Password'
import { Button } from 'styledComponents/Button'
import { DataCollectionType, CostcoErrorCodes } from 'helper/constants'
import { sendDataToDataCollection } from './../../helper/DataCollection/SendDataToDataCollectionUtil'
import { EntityCrudPayloadData } from './../../helper/DataCollection/PayloadData'
import { EventType, EntityCrudType } from '../../helper/DataCollection/DataCollectionConstants'

const EMPLOYEE = { value: 'employee', label: <FormattedMessage {...T('form.employee')} /> }
const MANAGER = {
  value: 'manager',
  label: <FormattedMessage {...T('form.practice-manager')} />
}
const ORG_MANAGER = {
  value: 'orgManager',
  label: <FormattedMessage {...T('form.org-manager')} />
}

const ROLES_MAP = {
  orgManager: [EMPLOYEE, MANAGER, ORG_MANAGER],
  manager: [EMPLOYEE, MANAGER],
  employee: [EMPLOYEE]
}
export const RadioButtonGroup = ({
  header,
  field,
  field: { value, name },
  labelArray = [],
  options = [],
  createDataQaEntry,
  form: { setFieldValue, values },
  onSelect = () => { },
  disabled
}) =>
  <div className="form-group" data-qa={name}>
    <label>
      {header}
    </label>
    <div className="form-inline">
      {labelArray &&
        labelArray.map((label, index) => {
          return (
            <div key={index} className="form-check mb-2 mr-sm-2">
              <input
                id={index + name}
                checked={value == options[index]}
                type="radio"
                name={name}
                data-qa={createDataQaEntry(name, options, index)}
                value={options[index]}
                onChange={e => {
                  field.onChange(e)
                  onSelect(e, setFieldValue, values)
                }}
                className="form-check-input"
                disabled={disabled}
              />
              <label
                htmlFor={index + name}
                className={`form-check-label radio-group--custom ${disabled ? 'disabled' : ''}`}
                key={index}
              >
                {label}
              </label>
            </div>
          )
        })}
    </div>
  </div>

export class EmployeeInviteForm extends Component {
  state = {
    initialized: false,
    usernameView: false,
    usernameAvailable: true
  }

  componentDidUpdate() {
    const { loading, employee = {} } = this.props
    const { initialized } = this.state

    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', '')
    if (!initialized && !loading) {
      const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'

      const useUsername = !!employee.username && !employee.email
      this.setState({
        initialized: true,
        usernameView: useUsername,
        usernameAvailable: role === 'employee'
      })
    }
  }

  prepareDataFromEmployee = (employee) => {
    const { practices = [], locale } = this.props

    const isOrgManager = _.get(employee, 'orgManager', false)
    const isManager = _.get(employee, 'manager', false)

    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'
    const assignedPracticeIds = practices
      .filter(item => _.get(employee, 'assignedPracticeIds', []).indexOf(item.id) != -1)
      .map(item => ({ label: item.name, value: item.id.toString() }))
    const defaultPracticeId = _.get(employee, 'defaultPracticeId', '')
    const practicesField = { assignedPracticeIds, defaultPracticeId }

    const useUsername = !!employee.username && !employee.email
    const password = { oldPassword: '', passwordEnter: '', passwordReEnter: '' }

    return {
      email: employee.email || '',
      username: useUsername ? employee.username : '',
      role,
      manager: isManager,
      orgManager: isOrgManager,
      locale: employee.locale || locale,
      practices: practicesField,
      password
    }
  }

  createDataQaEntry = (name, options, index) => {
    return name + '_' + options[index]
  }

  onRoleChange = (e, setFieldValue, values) => {
    const role = e.currentTarget.value
    const isEmployee = role === 'employee'
    const isOrgManager = role === 'orgManager'
    setFieldValue('manager', isOrgManager || !isEmployee)
    setFieldValue('orgManager', isOrgManager)
    setFieldValue('practices.assignedPracticeIds', isOrgManager ? this.props.practicesOptions : [])
    setFieldValue(
      'practices.defaultPracticeId',
      isOrgManager ? values.practices.defaultPracticeId : ''
    )

    this.setState({ usernameAvailable: isEmployee })
  }

  async prepareData(employeeData) {
    const { usernameView } = this.state
    const { organizationId } = this.props
    const data = _.omit(employeeData, ['avatar', 'password', 'role', 'practices', 'userDataConsent'])
    data.password = employeeData.password.passwordEnter
    if (usernameView) {
      data.email = null
    }
    else {
      data.password = null
      data.username = null
    }
    return {
      ...data,
      assignedPracticeIds: employeeData.practices.assignedPracticeIds.reduce(
        (arr, item) => [...arr, item.value],
        []
      ),
      defaultPracticeId: employeeData.practices.defaultPracticeId,
      organizationId
    }
  }

  employeeValidationSchema = () =>
    Yup.object().shape(
      {
        email: Yup.string().when('username', {
          is: username => !username,
          then: Yup.string()
            .required(this.formatMessage('validator.required'))
            .email(this.formatMessage('validator.email'))
        }),
        username: Yup.string().when('email', {
          is: email => !email,
          then: Yup.string().trim()
            .required(this.formatMessage('validator.required'))
        }),
        practices: Yup.object().shape({
          assignedPracticeIds: Yup.array().nullable().required(this.formatMessage('validator.required')),
          defaultPracticeId: Yup.string().required(this.formatMessage('validator.required'))
        }),
        password: this.state.usernameView ? Yup.object().shape({
          passwordEnter: Yup.string()
            .isPasswordValid()
            .min(6, this.formatMessage('min.password'))
            .required(this.formatMessage('validator.required')),
          passwordReEnter: Yup.string()
            .required(this.formatMessage('validator.required'))
            .test('passwords-match', this.formatMessage('validator.pinNotEqual'), function (
              value
            ) {
              return this.parent.passwordEnter == value
            })
        }) : Yup.string()

      },
      ['email', 'username']
    )

  formatMessage = (keyID) => {
    return this.props.intl.formatMessage(T(keyID))
  }
  onResendInvite = (email, setSubmitting) => {
    setSubmitting(true)
    const { history } = this.props
    this.props.resendInviteEmployee({ email: email }).then(result => {
      const { data: { resendInviteEmployee: { success } } } = result
      if (success) {
        setSubmitting(false)
        history.goBack()
      }
    })
  }

  handleSubmitResult = (result, setFieldError, setSubmitting, { email }) => {
    const { data: { inviteEmployee: { success, code } } } = result
    if (success) {
      this.props.navigate({ pathname: '/overview/orgManagement/users' })
    }
    if (code === 'EMPLOYEE_USERNAME_ALREADY_EXISTS') {
      if (this.state.usernameView) {
        setFieldError('username', this.formatMessage('error_messages.USERNAME_ALREADY_EXISTS'))
      }
      else {
        setFieldError('email',
          <a onClick={() => this.onResendInvite(email, setSubmitting)}>{this.formatMessage('employeeInvitation.emailAlreadyInvited')}</a>
        )
      }
    }
    if (code === 'NO_RIGHTS_FOR_CHANGE_EMPLOYEE_DETAILS') {
      if (this.state.usernameView) {
        setFieldError('username', this.formatMessage('employeeInvitation.emailNotAllowed'))
      }
      else {
        setFieldError('email', this.formatMessage('employeeInvitation.emailNotAllowed'))
      }
    }
    if (code === 'EMPLOYEE_ALREADY_ACTIVATED') {
      if (this.state.usernameView) {
        setFieldError('username', this.formatMessage('error_messages.USERNAME_ALREADY_EXISTS'))
      }
      else {
        setFieldError('email', this.formatMessage('error_messages.EMAIL_ALREADY_EXISTS_EMPLOYEE'))
      }
    }
    if (code === CostcoErrorCodes.PasswordPolicyError) {
      setFieldError(
        "password.passwordEnter",
        this.formatMessage("password.notAcceptable")
      )
    }
    if (code === CostcoErrorCodes.GeneralInvalidInput) {
      setFieldError(
        "password.passwordEnter",
        this.formatMessage("password.notStrongEnough")
      )
    }
    setSubmitting(false)
  }

  handleSubmit = async (employeeData, { setFieldError, setSubmitting }) => {
    const data = await this.prepareData(employeeData)
    try {
      const result = await this.props.inviteEmployee({ employee: data });
      this.handleSubmitResult(result, setFieldError, setSubmitting, data)
      sendDataToDataCollection(DataCollectionType.Crud, EntityCrudPayloadData(EntityCrudType.Employee), EventType.Create)
    }
    catch (error) {
      setSubmitting(false)
    }
  }

  onError() {
    if (window && window.document) {
      scrollTo('.has-error', {
        offset: -200,
        duration: 600
      })
    }
  }

  canChangeRoles = () => {
    const { isManager = false, isOrgManager = false } = this.props
    return (isOrgManager || isManager)
  }

  onToggle = setFieldValue => {
    this.setState({
      usernameView: !this.state.usernameView
    })
    setFieldValue('username', '')
    setFieldValue('email', '')
  }

  render() {
    const {
      loading,
      isManager,
      isOrgManager,
      practicesOptions,
      employee = {},
      formTitle,
      intl: { formatMessage },
      isReadOnly,
    } = this.props
    const practiceWidgetDisabled = !isManager

    const role = isOrgManager ? 'orgManager' : isManager ? 'manager' : 'employee'
    const roles = ROLES_MAP[role].map(item => item.value)
    const rolesLabel = ROLES_MAP[role].map(item => item.label)
    const disabledRoleChange = !this.canChangeRoles() || this.state.usernameView
    return (
      <EmployeeFormContainer>
        <Loading isLoading={loading} />
        {!loading &&
          <Formik
            onSubmit={this.handleSubmit}
            validationSchema={() => this.employeeValidationSchema()}
            initialValues={this.prepareDataFromEmployee(employee)}
            enableReinitialize={true}
          >
            {({ values, isSubmitting, setFieldValue }) => {

              const usernameViewToggle = () => {
                this.onToggle(setFieldValue)
              }
              return (
                <Form>
                  <Loading isLoading={isSubmitting} />
                  <div className="container">
                    <div className="col-lg-10 offset-lg-1">
                      <h1 data-qa="title">
                        <FormattedMessage {...T(formTitle)} />
                      </h1>
                      <p data-qa="required-text">
                        <FormattedMessage
                          {...T('common.required_text')}
                          values={{ mark: <span className="text-primary">*</span> }}
                        />
                      </p>

                      <div className="row">
                        <div className="col-lg-6">
                          {this.state.usernameView
                            ? <Field
                              disabled={isReadOnly}
                              name="username"
                              component={InputField}
                              label={formatMessage(T('employee.username'))}
                              labelClassName="required"
                            />
                            : <Field
                              type="email"
                              disabled={isReadOnly}
                              name="email"
                              component={InputField}
                              label={formatMessage(T('common.email'))}
                              labelClassName="required"
                            />}
                        </div>
                      </div>
                      {this.state.usernameView &&
                        <div className="row" data-qa="password">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                disabled={isReadOnly}
                                name="password"
                                component={PasswordComponent}
                                oldPassword={false}
                                required={this.state.usernameView}
                              />
                            </div>
                          </div>
                        </div>
                      }
                      {this.state.usernameAvailable &&
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group form-check" data-qa="use_username">
                              <label className="form-check-label font-weight-normal">
                                <input
                                  disabled={isReadOnly}
                                  type="checkbox"
                                  className="form-check-input"
                                  data-qa="otherwayaround"
                                  defaultChecked={this.state.usernameView}
                                  onClick={usernameViewToggle}
                                />
                                <FormattedMessage {...T('common.useUsername')} />
                              </label>
                            </div>
                          </div>
                        </div>}

                      {this.state.usernameView &&
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="warn-block" data-qa="details-help-text">
                              <i className="fas fa-fw fa-exclamation-triangle exclamation-icon text-primary" />
                              <FormattedMessage {...T('common.usernameWarning')} />
                            </p>
                          </div>
                        </div>}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group" data-qa="roles">
                            <div data-tip data-for="roles">
                              <Field
                                name="role"
                                component={RadioButtonGroup}
                                header={<FormattedMessage {...T('employee.roles')} />}
                                options={roles}
                                labelArray={rolesLabel}
                                createDataQaEntry={this.createDataQaEntry}
                                onSelect={this.onRoleChange}
                                disabled={disabledRoleChange || isReadOnly}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Field
                            name="practices"
                            component={PracticesSelectField}
                            assignAllpractices={values.role === 'orgManager'}
                            loading={loading}
                            options={practicesOptions}
                            disabled={practiceWidgetDisabled || isReadOnly}
                            labelClassName="required"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 offset-lg-6">
                          <Button
                            secondary
                            type="button"
                            className="btn-block"
                            onClick={() => this.props.navigate(-1)}
                            data-qa="cancel-button"
                          >
                            <FormattedMessage {...T('common.cancel')} />
                          </Button>
                        </div>
                        <div className="col-lg-3">
                          <Button
                            primary
                            disabled={isReadOnly}
                            type="submit"
                            className="btn-block"
                            data-qa="continue-button"
                          >
                            {this.state.usernameView ? <FormattedMessage {...T('common.save')} /> : <FormattedMessage {...T(this.props.submitLabel)} />}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>}
      </EmployeeFormContainer>
    )
  }
}

export default withRouter(withQuery(injectIntl(EmployeeInviteForm)))
