import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { T } from 'core'
import SortableTableHeader from 'components/SortableTableHeader'
import { Props, State } from 'typescript/interfaces/ISigniaLessonsTable'
import { Link } from 'react-router-dom'
import { STANDARD_LESSON_SET_NAME } from 'helper/constants'
import Tooltip from 'react-tooltip'
import { StyledTable } from "styledComponents/Table/StyledTable";
import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIcon } from "styledComponents/Icon/StyledFontAwesomeIcon"
import { LinkButton } from 'styledComponents/Button'
import DataContext from "containers/DataContext"
export class SigniaLessonsTable extends Component<Props, State> {
    static contextType = DataContext;
    constructor(props) {
        super(props)
        this.state = { sortData: this.props.lessonSet, sort: '', order: '' }
    }

    setSort = sortWith => {
        const { order } = this.state
        const sortData = [...this.state.sortData]
        const standardLessonSet = sortData.find(data => data.lessonSetName == STANDARD_LESSON_SET_NAME)
        const sortDataWithoutStandardLessonSet = sortData.filter(_ => _.lessonSetName != STANDARD_LESSON_SET_NAME)
        const reverseSortData = [...sortDataWithoutStandardLessonSet].reverse()
        sortDataWithoutStandardLessonSet.sort((a, b) => {
            const nameA = sortWith != 'createdDate' ? a[sortWith].toLowerCase() : new Date(a[sortWith])
            const nameB = sortWith != 'createdDate' ? b[sortWith].toLowerCase() : new Date(b[sortWith])
            if (
                nameA < nameB //sort string ascending
            )
                return -1
            if (nameA > nameB) return 1
            return 0
        })
        sortDataWithoutStandardLessonSet.unshift(standardLessonSet)
        reverseSortData.unshift(standardLessonSet)
        if (order == 'asc' || order == '')
            this.setState({ sortData: reverseSortData, sort: `${sortWith},desc`, order: 'desc' })
        if (order === 'desc') this.setState({ sortData: sortDataWithoutStandardLessonSet, sort: `${sortWith},asc`, order: 'asc' })
    }

    renderViewOrEdit(lessonSetName, item, formatMessage) {
        return lessonSetName == STANDARD_LESSON_SET_NAME ?
            <td className="text-center">
                <Link
                    data-qa="manage__view-button"
                    to={`/lessontemplate/${item.id}/edit`}
                    data-tip
                    data-for={`tooltip-view-${item.id}`}
                >
                    <StyledFontAwesomeIcon icon={faInfoCircle} type="info" />
                </Link>
                <Tooltip id={`tooltip-view-${item.id}`}>
                    {`${formatMessage(T('journeyConfigurator.manageLessonSets.view'))} ${lessonSetName}`}
                </Tooltip>
            </td>
            :
            <td className="text-center">
                <Link
                    data-qa="manage__edit-button"
                    to={`/lessontemplate/${item.id}/edit`}
                    data-tip
                    data-for={`tooltip-edit-${item.id}`}
                >
                    <StyledFontAwesomeIcon icon={faEdit} fixedWidth type="edit" />
                </Link>
                <Tooltip id={`tooltip-edit-${item.id}`}>
                    {`${formatMessage(T('common.edit'))} ${lessonSetName}`}
                </Tooltip>
            </td>
    }

    updateLessonSet = async (lessonSetId, lessonSet) => {
        await this.props.saveAsDefaultLessonSet(lessonSetId, lessonSet)
    }

    getTheLinkToSaveAsDefaultTemplate = ({ id: lessonSetId = 1, createdDate, ...rest }) => {
        return <span>
            {rest.isDefault == 'true' ? <FormattedMessage {...T('journeyConfigurator.manageLessonSets.defaultTemplate')} /> :
                <LinkButton onClick={async (e) => {
                    this.updateLessonSet(lessonSetId, rest)
                    e.preventDefault()
                }}>
                    <FormattedMessage {...T('journeyConfigurator.manageLessonSets.setAsDefault')} />
                </LinkButton>
            }
        </span>
    }

    render() {
        const { sortData, sort } = this.state
        const { intl: { formatMessage }, formatDate } = this.props
        return (
            <StyledTable className="table--lessons">
                <thead>
                    <tr>
                        <SortableTableHeader
                            className="col-3"
                            name="lessonSetName"
                            onClick={() => this.setSort('lessonSetName')}
                            value={formatMessage(T('journeyConfigurator.manageLessonSets.LessonSetName'))}
                            sort={sort}
                        />
                        <SortableTableHeader
                            className="col-3"
                            name="comment"
                            onClick={() => this.setSort('comment')}
                            value={formatMessage(T('journeyConfigurator.manageLessonSets.Comments'))}
                            sort={sort}
                        />
                        <SortableTableHeader
                            className="col-2"
                            name="createdDate"
                            onClick={() => this.setSort('createdDate')}
                            value={formatMessage(T('journeyConfigurator.manageLessonSets.Created'))}
                            sort={sort}
                        />
                        <th className="col-2">&nbsp;</th>
                        <th className="col-1">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {sortData &&
                        sortData.map((item, index) => {
                            const { lessonSetName, comment, createdDate, id } = item
                            return (
                                <tr key={`${id}-${index}`}>
                                    <td>
                                        {lessonSetName}
                                    </td>
                                    <td>
                                        {comment}
                                    </td>
                                    <td>
                                        {formatDate(createdDate)}
                                    </td>
                                    <td>
                                        {this.getTheLinkToSaveAsDefaultTemplate(item)}
                                    </td>
                                    {this.renderViewOrEdit(lessonSetName, item, formatMessage)}
                                </tr>
                            )
                        })}
                </tbody>
            </StyledTable>
        )
    }
}

export default injectIntl(SigniaLessonsTable)
