import styled from 'styled-components'

export const EmployeeFormContainer = styled.div`
  .has-error {
    color: red;
  }

  .error-list {
    margin-top: 5px;
  }
`
