import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap"
import { T } from 'core'
import { FormattedMessage, injectIntl } from 'react-intl'
import { StyledPopup } from 'styledComponents/Div/DivComponent/DivPatient'
import { Button } from 'styledComponents/Button'

interface IProps {
  show: boolean,
  onHide: Function
}

const HcpContactVerificationNotifcationModal = ({ show, onHide }: IProps) => {
  const [showModal, setShowModal] = useState(show)
  const [dontRemindMe, setDontRemindMe] = useState(false)

  const closeModal = () => {
    setShowModal(false)
    onHide(dontRemindMe)
  }

  const toggleDontRemindMe = () => {
    setDontRemindMe(!dontRemindMe)
  }

  useEffect(() => {
    setShowModal(show)
  }, [show])

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      centered
      data-qa="HcpContactVerificationNotifcationModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...T('hcpNotification.label')} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledPopup>
          <FormattedMessage {...T('hcpNotification.Information')} />
        </StyledPopup>

        <div style={{ "padding": "1rem 1.2rem 1.4rem 1.6rem" }}>
          <label className="form-check-label font-weight-normal">
            <input
              type="checkbox"
              className="form-check-input mt-0.25rem"
              data-qa="dont-remind-again-checkbox"
              defaultChecked={false}
              onClick={toggleDontRemindMe}
            />
            <FormattedMessage {...T('common.dontRemindAgain')} />
          </label>
        </div>
        <div className='row justify-content-center'>
          <Button className="btn--min-width text-center"
            onClick={closeModal}
            primary
            data-qa="close-button">
            <FormattedMessage {...T('otp.gotoDashboard')} />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default injectIntl(HcpContactVerificationNotifcationModal)