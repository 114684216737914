/* eslint-disable no-unused-vars, no-empty, no-console,  no-unused-expressions */

import { getAppVersion } from '../helper/TokenUtil';
import { EventEmitter } from './base'
import { get } from 'lodash'

/* global __VERSION__, __ENV__ */

const ANALYTICS_ACTIONS = [
  'pageViewChanged',
  'shopAccountAction',
  'customerAction',
  'videoCallAction',
  'dashboardAction'
]

export class Tracker {
  constructor() {
    Object.defineProperty(Array.prototype, "last", {
      enumerable: false,
      writable: true,
      value: function () {
        return this[this.length - 1];
      }
    });

    Object.defineProperty(Array.prototype, "reduceOneRight", {
      enumerable: false,
      writable: true,
      value: function () {
        return this.slice(0, -1);
      }
    });
  }

  use(fn) {
    this.go = (stack => (...args) =>
      stack(...args.reduceOneRight(), () => {
        const _next = args.last()
        fn.apply(this, [
          ...args.reduceOneRight(),
          _next.bind.apply(_next, [null, ...args.reduceOneRight()])
        ])
      }))(this.go)
  }

  go(...args) {
    const _next = args.last()
    _next.apply(this, args.reduceOneRight())
  }

  track = (name, data, meta) => {
    this.go(name, data, meta, () => {
      // console.log('tracking', name, data, meta)
    })
  }
}

export const tracker = new Tracker()

/**
 * Store is enabled by default,
 * To disable provide { store: false } in meta
 */
export class TrackingStore extends EventEmitter {
  constructor() {
    super()

    this.store = {}
  }

  track = (name, data, meta, next) => {
    const oldState = this.getState()

    if (!meta || meta.store !== false) {
      this.add(data)
    }

    this.trigger('change', this.getState(), oldState, { name, data, meta })

    next()
  }

  getState() {
    return this.store
  }

  reset() {
    this.store = {}
  }

  add(data) {
    this.store = {
      ...this.store,
      ...data
    }
  }
}

export class AnalyticsTrackerStore extends TrackingStore {
  constructor() {
    super()

    this.store = {
      language: !!window.localStorage.locale
        ? JSON.parse(window.localStorage.locale).locale.split('-')[0] // locale is filled by LocaleReducer as initial store
        : 'en',
      url: window.location.href,
      portalversion: `TeleCare Portal v${getAppVersion()}`
    }

    this.on('change', this.sync)
  }

  sync = (store, oldStore, { name }) => {
    const trackingEvents = {
      pageViewChanged: this.trackPageview,
      shopAccountAction: this.trackShopAccountAction,
      customerAction: this.trackCustomerAction,
      dashboardAction: this.trackDashboardAction
    }

    // parse data for push events only
    if (trackingEvents.hasOwnProperty(name)) {
      const commons = this.getCommonDataFromStore(store)

      // const fn = trackingEvents[name]
      // fn.apply(this, [store, commons])

      // call the mapped function and apply the arguments
      trackingEvents[name](store, commons)
    }
  }

  trackWithGoogleTagManager = (action, data) => {
    window.dataLayer = window.dataLayer || []
    const track = {}
    track[action] = data
    window.dataLayer.push(track)
  }

  trackPageview = (
    { screen: { name } = { name: '' }, language = '', portalversion = '' },
    { page = '', country = '', url = '' }
  ) => {
    this.trackWithGoogleTagManager('pageView', { page, portalversion, country, language })
  }

  trackShopAccountAction = ({ action }, commonData) => {
    this.trackWithGoogleTagManager('shopAccountAction', { ...commonData })
  }

  trackCustomerAction = ({ action = '', ...restOfStore }, commonData) => {
    this.trackWithGoogleTagManager('customerAction', {
      ...this.getCustomerDataFromStore(restOfStore),
      ...commonData
    })
  }

  trackDashboardAction = (
    { language = '', action = '', dashkpicount = 0, dashdeltacount = 0 },
    { country, ...restOfCommons } = { country: '' }
  ) => {
    const page = `webapp:${country}:${language}/${window.location.hash}`
    this.trackWithGoogleTagManager('dashboardAction', {
      ...restOfCommons,
      action,
      page,
      dashkpicount,
      dashdeltacount
    })
  }

  getCommonDataFromStore = (
    {
      pathname,
      search,
      url = '',
      activeEmployee: { employee, countryCode, organizationId } = {
        employee: {
          id: 0,
          manager: false,
          videoCallsEnables: false,
          weeklyMailSummary: false
        },
        countryCode: '',
        organizationId: 0
      },
      portalversion = ''
    } = { pathname: '', search: '', url: '' }
  ) => {
    const country = countryCode.toString().toLowerCase()
    const language = this.getLanguage().toString().toLowerCase()
    const page = `webapp:${country}:${language}/${pathname}${search}`

    return {
      page,
      url,
      portalversion,
      country,
      language,
      orgactiveorgid: organizationId,
      hcpemployeeid: get(employee, 'id', 0),
      hcpemployeeismanager: Boolean(get(employee, 'manager', false)),
      hcpemployeevideocallsenables: Boolean(get(employee, 'videoCallsEnables', false)),
      hcpemployeeweeklymailsummary: Boolean(get(employee, 'weeklyMailSummary', false))
    }
  }

  getLanguage = () => {
    return !!window.localStorage.locale
      ? JSON.parse(window.localStorage.locale).locale // locale is filled by LocaleReducer as initial store
      : 'en-GB'
  }

  getCustomerDataFromStore = ({
    activeCustomer = { id: 0, journeyStatus: '' },
    customerHearingDevice: { leftDevice, rightDevice } = {
      leftDevice: { model: '', manufacturer: '' },
      rightDevice: { model: '', manufacturer: '' }
    }
  }) => {
    return {
      customerid: get(activeCustomer, 'id', 0),
      hcpcustomerjourneystatus: get(activeCustomer, 'journeyStatus', ''),
      hcpcustomerleftdevicemanufacturer: get(leftDevice, 'manufacturer', ''),
      hcpcustomerleftdevicemodel: get(leftDevice, 'model', ''),
      hcpcustomerrightdevicemanufacturer: get(rightDevice, 'manufacturer', ''),
      hcpcustomerrightdevicemodel: get(rightDevice, 'model', '')
    }
  }
}

export const trackingStore = new TrackingStore()
