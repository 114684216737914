import styled, { css } from "styled-components";

export const StyledBrandLogo = styled.div((props) => {
  if (!props.url) return;

  const { url } = props;

  return css`
    top: 0;
    right: 40px;
    display: block;
    width: 172px;
    background: url("${url}");
    background-repeat: no-repeat;
    background-size: contain;
    height: 69px;
    max-height: 100%;
  `;
});
