import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import _ from 'lodash'
import { PatientNavigationItem, NavigationCol } from 'styledComponents/Div/DivComponent/DivProfile'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

const NavigationItem = ({ active, href, query, label = '', dataqa = '' }) => {
  const link = (
    <PatientNavigationItem>
      <StyledButtonLink
        $buttontertiarystyle
        to={_.isNil(query) ? href : href + query}
        data-qa={dataqa}
        className={`overview-navigation__handler  ${active == href
          ? 'active'
          : ''} `}
      >
        {label}
      </StyledButtonLink>
    </PatientNavigationItem>
  )
  return link
}

export const Navigation = ({ active, customerId = '', customer }) => {
  const patientId = customerId
  const leftModelId = _.isNil(customer.hearingDevices.leftDevice)
    ? 0
    : customer.hearingDevices.leftDevice.id
  const rightModelId = _.isNil(customer.hearingDevices.rightDevice)
    ? 0
    : customer.hearingDevices.rightDevice.id

  return (
    <div className="row">
      <NavigationCol className="col-lg-3">
        <NavigationItem
          dataqa="editPatient"
          active={active}
          href={`/profile/${patientId}/settings`}
          label={<FormattedMessage {...T('customerEdit.title')} />}
        />
      </NavigationCol>

      <NavigationCol className="col-lg-3">
        <NavigationItem
          dataqa="hearingInstrument"
          active={active}
          href={`/profile/${patientId}/settings/hearing`}
          label={<FormattedMessage {...T('patientData.hearingInstrument')} />}
        />
      </NavigationCol>
      <NavigationCol className="col-lg-3">
        <NavigationItem
          dataqa="lessons"
          active={active}
          href={`/profile/${patientId}/settings/lessons`}
          label={<FormattedMessage {...T('dropdown.manage-lessons')} />}
        />
      </NavigationCol>
      <NavigationCol className="col-lg-3">
        <NavigationItem
          dataqa="connectToTelecareApp"
          active={active}
          href={`/profile/${patientId}/settings/setup`}
          label={<FormattedMessage {...T('customerDetail.reconnect')} />}
        />
      </NavigationCol>
    </div>
  )
}

export default Navigation
