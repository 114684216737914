import { gql } from "@apollo/client";

export const SHOW_POPUP_QUERY = gql`
  query popupquery {
    overlay @client {
      popup
      modal
      isOpen
    }
  }
`
export const UPDATE_POPUP = gql`
  mutation updatePopup($popup: popup, $isOpen: Boolean) {
    updatePopup(popup: $popup, isOpen: $isOpen) @client {
      popup
      isOpen
    }
  }
`
export const UPDATE_MODAL = gql`
  mutation updateModal($modal: Boolean, $isOpen: Boolean) {
    updateModal(modal: $modal, isOpen: $isOpen) @client {
      modal
      isOpen
    }
  }
`
export const UPDATE_OVERLAY = gql`
  mutation updateOverlay($popup: String, $modal: Boolean, $isOpen: Boolean) {
    updateOverlay(popup: $popup, modal: $modal, isOpen: $isOpen) @client {
      popup
      modal
      isOpen
    }
  }
`
