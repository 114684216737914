import React from 'react'
import { FormattedMessage } from 'react-intl'
import { T } from 'core'
import { d10_d11_conversion } from './ProgramMap'
import { StyledButtonLink } from 'styledComponents/Navigation/StyledButtonLink'

const ProgramSelector = ({ programs = [], activeProgramNumber, onChange, isD11 = false}) => {
  return (
    <article>
      <h2>
        <FormattedMessage {...T('profile.program.title')} />
      </h2>
      <div className="row">
        {programs.map((item, index) => {
          const orderNumber = index + 1
          const { equalizer1, equalizer2, equalizer3, equalizer4 } = item
          const isDefaultSettings = equalizer1 + equalizer2 + equalizer3 + equalizer4 === 0 
          var displayedNameKey = item.name
          if(item.name === item.type)
            displayedNameKey = item.type

          if(isD11)
            displayedNameKey = d10_d11_conversion.get(displayedNameKey) && d10_d11_conversion.get(displayedNameKey).toLowerCase()
          else
            displayedNameKey = displayedNameKey && displayedNameKey.toLowerCase()
          
          return (
            <div key={item.programNumber} className="col-lg-3">
              <StyledButtonLink
                $buttontertiarystyle
                type="button"
                data-qa={item.name}
                onClick={() => onChange(item)}
                className={`program-selector ${activeProgramNumber === item.programNumber ? 'active' : ''}`}
              >
                {`${orderNumber}. `}
                
                {displayedNameKey && T(`hearing_programs.${displayedNameKey}`).defaultMessage != T(`hearing_programs.${displayedNameKey}`).id ? <FormattedMessage {...T(`hearing_programs.${displayedNameKey}`)} /> : item.name}
                {!isDefaultSettings && ` *`}
              </StyledButtonLink>
            </div>
          )
        })}
      </div>
    </article>
  )
}

export default ProgramSelector
