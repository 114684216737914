import { gql } from "@apollo/client";

export const QUERY_INVITE_EMPLOYEE = gql`
  query ($orgId: Int) {
    Me {
      employee {
        id
        manager
        orgManager
      }
      countryCode
    }
    AssignedPractices(id: $orgId) {
      id
      name
    }
  }
`
