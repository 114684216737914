import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { compose } from 'lodash/fp'
import { T } from 'core'
import { graphQLClient } from 'graphql/client'

import {
  getChatMessages,
  getUnreadMessages,
  getUnreadMessageIds,
  getLastMessageTimeByPatient
} from 'selectors/interactionSelector'
import MessageGroup from './MessageGroup'
import { customerTracking } from 'helper/customerTracking'
import LoadMessages from './LoadMessages'
import { getDefaultPracticeId } from 'helper/PracticeUtils'
import { loadChatMessages } from 'helper/LoadMessagesUtil'
import _ from 'lodash'
import moment from 'moment'
import { withQuery } from 'helper/withQuery'
import { withRouter } from "helper/withRouter";
import { DataCollectionType, ChatMessageDirection, CorrelationContext, chatMessageMaxLength } from 'helper/constants'
import { QUERY_PRACTICE_DETAILS } from 'graphql/queries/PracticesQuery'
import { isAppSupported } from '../../shared'
import { Button } from 'styledComponents/Button'

import { sendDataToDataCollection } from 'helper/DataCollection/SendDataToDataCollectionUtil'
import { ChatAnalyticsData } from '../../../helper/DataCollection/PayloadData'

import { getSupportId } from '../../../helper/ApplicationInsightSupportId'
import { HEARING_DEVICES_QUERY } from '../../../graphql/queries/HearingDevicesQuery'
import FlushEventsToAppInsight from 'helper/FlushEventsToAppInsight'
import { d10_d11_conversion } from '../components/ProgramSelector/ProgramMap'
import { invokeSignalRConnection, stopSignalrConnection } from '../../../containers/PatientsList/SignalRConnection'

const TIMEOUT_NEW_MESSAGES = 1000 * 7
export const SEND_TIMEOUT = 5000

export const CHAT_PAGESIZE = 10
export const DEFAULT_SCROLL_SIZE = 200
const sendErrorStyle = {
  padding: '2% 5% 2% 5%',
  marginTop: '5%',
  fontSize: '12px'
}


let SupportId = getSupportId()

export class Chat extends Component {

  connection = null
  groupId = ''
  state = {
    isLoading: false,
    sendError: false,
    isScrolling: false,
    messages: {},
    pageNumber: 1,
    loadedAllData: false,
    loadError: false,
    lastScrollHeight: 0,
    isMessageLoading: false,
    remainingCharacter: 0
  }

  componentDidUpdate() {
    if (!this.state.isScrolling) this.scrollToBottom()
  }

  UNSAFE_componentWillMount() {
    const { params } = this.props
    FlushEventsToAppInsight('Navigated to Messages')
    this.groupId = params.id
    this.update(params.id).then(() => this.scrollToBottom())
    this.props.resetUnreadMessageCount({ patientId: parseInt(params.id), correlationContext: CorrelationContext['ChatMessage_ResetUnreadMessageCount'] + SupportId })
    invokeSignalRConnection(this)
  }

  componentWillUnmount() {
    FlushEventsToAppInsight('Navigated away from Messages')
    clearTimeout(this.markAsReadTimer)
    SupportId = getSupportId()
    stopSignalrConnection(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { messages } = this.state
    if (!_.isEmpty(nextProps.messages)) {
      const mergedMessages = !_.isEmpty(messages)
        ? this.mergeChatMessages(nextProps.messages)
        : nextProps.messages
      this.setState({ messages: { ...messages, ...mergedMessages } })
    }
  }
  scrollToBottom() {
    if (this.container) {
      this.container.scrollTop = this.container.scrollHeight
    }
  }

  scrollToLastReadMessage(scrollSize) {
    if (this.container) {
      this.container.scrollTop = scrollSize
    }
  }

  fetchMessages() {
    return this.props.refetch()
  }

  update = () => {
    return this.fetchMessages().then(() => {
      if (this.props.unreadMessages.length > 0) {
        this.scrollToBottom()
      }
      this.markInteractionsAsRead(this.props.unreadMessages)
    })
  }

  markInteractionsAsRead(unreadMessages) {
    clearTimeout(this.markAsReadTimer)
    const { customer } = this.props
    if (this.container && unreadMessages && unreadMessages.length > 0) {
      const unreadMessageIds = getUnreadMessageIds(customer, unreadMessages)
      this.props.markMessageAsRead({
        ids: unreadMessageIds,
        correlationContext: CorrelationContext['ChatMessage_MarkAsReadChatMessage'] + SupportId
      })
      this.markAsReadTimer = setTimeout(async () => {
        this.props.refetch()
      }, TIMEOUT_NEW_MESSAGES)
      unreadMessages.forEach(message => {
        this.trackChatData(message.createDate, ChatMessageDirection.APP_TO_PORTAL)
      })
    }
  }

  onMessageSent = e => {
    return this.submit(e).then(data => {
      if (data != null) {
        const { location = {} } = this.props
        customerTracking({
          ...location,
          action: 'customerMessaged'
        })
      }

      return data
    })
  }
  submit(e) {

    e.preventDefault()
    const { customer, Me: { employee }, lastMessageByPatientTime } = this.props
    const { isLoading } = this.state
    if (!isLoading && this._input.value.trim()) {
      this.setState({ isLoading: true, sendError: false })
      const data = { patientId: customer.id, message: this._input.value, employeeId: employee.id }
      this.sendTimeout = setTimeout(() => {
        this.setSendFailureState(true)
      }, SEND_TIMEOUT)
      return this.props
        .sendMessage({
          data: data,
          correlationContext: CorrelationContext['ChatMessage_SendChatMessage'] + SupportId
        })
        .then(() => {
          this._input.value = ''
          this.setState({ remainingCharacter: 0 })
          this._input.focus()
          this.update()
            .then(() => this.setSendFailureState(false))
            .then(() => clearTimeout(this.sendTimeout))
            .then(() => this.scrollToBottom())
            .then(() => this.trackChatData(lastMessageByPatientTime, ChatMessageDirection.PORTAL_TO_APP, data.message.length))
          this.props.refetch()
          return data
        })
    } else {
      return Promise.resolve()
    }
  }

  setSendFailureState(isError) {
    this.setState({ isLoading: false, sendError: isError })
  }
  scrollTop = () => this.container && this.container.scrollTop
  showLoadingMessages = () => {
    const { loadedAllData, isScrolling } = this.state
    if (!loadedAllData && !isScrolling) {
      this.setState(
        { isScrolling: true, loadError: false, lastScrollHeight: this.container.scrollHeight },
        () => this.loadMessages()
      )
    }
  }

  handleLoadingTimeout = () => {
    this.setState({ isScrolling: false, loadError: true })
  }

  loadMessages = async () => {
    const { customer } = this.props
    const { pageNumber } = this.state
    this.setState({ isMessageLoading: true })
    const oldMessagesData = await loadChatMessages(customer.id, pageNumber, CHAT_PAGESIZE)
    if (oldMessagesData == null) {
      this.updateMessageLoadStatus(false)
    } else if (_.isEmpty(oldMessagesData)) {
      this.updateMessageLoadStatus(true)
    } else if (!_.isEmpty(oldMessagesData)) {
      this.parseOldChatMessages(oldMessagesData)
    }
  }

  updateMessageLoadStatus = loadedAllData => {
    this.setState({ isScrolling: false, loadedAllData, loadError: false })
  }

  parseOldChatMessages = oldMessagesData => {
    const { customer } = this.props
    const { pageNumber, messages, lastScrollHeight } = this.state
    var loadedAllData = _.size(oldMessagesData) < CHAT_PAGESIZE ? true : false
    var oldMessages = getChatMessages(oldMessagesData, this.props.customer)
    const mergedMessages = this.mergeChatMessages(oldMessages)
    this.markInteractionsAsRead(getUnreadMessages(customer, oldMessagesData))
    var scrollSize = loadedAllData
      ? DEFAULT_SCROLL_SIZE
      : this.container.scrollHeight - lastScrollHeight + DEFAULT_SCROLL_SIZE
    this.setState(
      {
        messages: { ...messages, ...oldMessages, ...mergedMessages },
        isScrolling: false,
        pageNumber: pageNumber + 1,
        loadedAllData,
        loadError: false
      },
      () => this.scrollToLastReadMessage(scrollSize)
    )
  }

  mergeChatMessages = newMessages => {
    const { messages } = this.state
    const mergedMessages = this.mergeMessagesWithExistingGroup(newMessages, messages)
    this.addNewGroupMessages(newMessages, mergedMessages)
    return mergedMessages
  }

  mergeMessagesWithExistingGroup = (newMessages, messages) => {
    var mergedMessages = {}
    Object.keys(messages).forEach(date => {
      if (_.has(newMessages, date)) {
        mergedMessages[date] = [...messages[date]]
        Object.keys(newMessages[date]).forEach(index => {
          mergedMessages[date].push(newMessages[date][index])
        })
        const uniqueMessages = mergedMessages[date].reduce(
          (x, y) => (x.findIndex(msg => msg.id === y.id) < 0 ? [...x, y] : x),
          []
        )
        mergedMessages[date] = uniqueMessages
        Object.keys(mergedMessages).forEach(element => {
          const group = mergedMessages[element]
          mergedMessages[element] = group.sort(
            (a, b) => new Date(moment(a.createDate)) - new Date(moment(b.createDate))
          )
        })
      }
    })
    return mergedMessages
  }

  addNewGroupMessages = (newMessages, mergedMessages) => {
    Object.keys(newMessages).forEach(date => {
      if (!_.has(this.state.messages, date)) {
        mergedMessages[date] = [...newMessages[date]]
      }
    })
  }

  trackChatData = (messageDate, direction, textLength = '') => {
    const { customer, Practice } = this.props
    const ChatData = {
      messageDirection: direction,
      messageByPatientDate: messageDate,
      textLength: textLength
    }
    sendDataToDataCollection(DataCollectionType.ChatData, ChatAnalyticsData(customer, Practice, ChatData))
  }

  handleSpecialMessageTypes = chatMessage => {
    const { intl } = this.props
    const leastD11PlatformNumber = 21
    if (!this.props.HearingDevices) return

    const isD11 =
      (this.props.HearingDevices &&
        this.props.HearingDevices.leftDevice &&
        Number(this.props.HearingDevices.leftDevice.platform) >= leastD11PlatformNumber) ||
      (this.props.HearingDevices &&
        this.props.HearingDevices.rightDevice &&
        Number(this.props.HearingDevices.rightDevice.platform) >= leastD11PlatformNumber)

    if (chatMessage.messageType === 'RemoteTuning') {
      const hdPrograms = this.props.HearingDevices.hdPrograms
      if (!chatMessage.payload || chatMessage.payload.programSlot === undefined) return
      const programSlot = chatMessage.payload.programSlot
      if (!hdPrograms[programSlot]) return

      const programName = isD11
        ? d10_d11_conversion.get(hdPrograms[programSlot].name) && d10_d11_conversion.get(hdPrograms[programSlot].name).toLowerCase()
        : hdPrograms[programSlot].name

      const programNameLocalization = programName && T(`hearing_programs.${programName.toLowerCase()}`)
      const localizedProgramName = programNameLocalization && programNameLocalization.key
        ? intl.formatMessage(programNameLocalization)
        : programName
      const displayedProgramName = localizedProgramName ? localizedProgramName : hdPrograms[programSlot].name

      chatMessage.message = intl.formatMessage(T('chat.ProgramUpdateSent'), { 0: displayedProgramName })
    }
  }

  render() {
    const { unreadMessages, intl, customer } = this.props

    const isSendButtonEnabled = isAppSupported(customer)

    const { isLoading, sendError, isScrolling, loadedAllData, loadError, messages, remainingCharacter } = this.state
    const firstUnreadMessage = unreadMessages[0]

    const chatMessageGroups = Object.values(messages)
    chatMessageGroups.forEach(group => group.forEach(this.handleSpecialMessageTypes))
    return (
      <div data-qa="chat" className="chat">
        <section className="container">
          <div className="row">
            <div
              className="col-10 offset-1 chat__inner"
              ref={e => {
                this.container = e
              }}
              onScroll={() =>
                this.container && this.container.scrollTop === 0 && this.showLoadingMessages()}
            >
              <LoadMessages
                isLoading={isScrolling}
                loadedAllData={
                  this.props.recievedMessageSize < CHAT_PAGESIZE ? true : loadedAllData
                }
                loadError={loadError}
                onTimeout={this.handleLoadingTimeout}
              />
              {Object.keys(messages).sort((a, b) => new Date(a) - new Date(b)).map(date => {
                const group = messages[date]

                return (
                  <MessageGroup key={date} firstUnreadMessage={firstUnreadMessage} group={group} />
                )
              })}
            </div>
          </div>
        </section>
        <div className="chat__controls">
          <form className="container" onSubmit={this.onMessageSent}>
            <div className="row">
              <div className="col-8 offset-1">
                <textarea
                  ref={i => (this._input = i)}
                  type="text"
                  name="message"
                  maxLength={chatMessageMaxLength}
                  onChange={(e) => this.setState({ remainingCharacter: e.target.value.length })}
                  data-qa="chat__message"
                  placeholder={intl.formatMessage(T('common.typeMessage'))}
                  className="form-control"
                  style={{
                    resize: 'vertical',
                    minHeight: '100px'
                  }}
                />
              </div>
              <div className="col-2">
                <Button
                  primary
                  type="submit"
                  data-qa="send-chat-button"
                  className="btn-block chat__button"
                //disabled = {!isSendButtonEnabled}
                >
                  {isLoading && <i className="fa fw fa-circle-o-notch fa-spin" />}
                  <FormattedMessage {...T('common.send')} />
                </Button>
                {sendError &&
                  <div
                    className="alert alert-danger"
                    data-qa="message-send-error"
                    style={sendErrorStyle}
                  >
                    <FormattedMessage {...T('chat.error')} />
                  </div>}
              </div>
            </div>
            <div className="row">
              <div className="col-8 offset-1">
                <div className="float-right">
                  {remainingCharacter}/{chatMessageMaxLength}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapProps = ({
  data: { refetch, loading, Messages = [], Me, Customer = {} },
  ownProps: { intl }
}) => {
  const messages = getChatMessages(Messages, Customer)
  const unreadMessages = getUnreadMessages(Customer, Messages)
  const lastMessageByPatientTime = getLastMessageTimeByPatient(Customer, Messages, unreadMessages)
  return {
    refetch,
    loading,
    customer: Customer,
    recievedMessageSize: Messages.length,
    messages,
    unreadMessages,
    lastMessageByPatientTime,
    intl,
    Me
  }
}

const QUERY_CHAT = gql`
  query ChatListQuery($id: Int!, $pageNumber: Int, $size: Int, $correlationContext: String!) {
    Me {
      employee {
        id
      }
      organizationId
    }
    Customer(id: $id) {
      id
      employeeId
      practiceId
      uuid
      upid
      brandDisplayId
      hearingDevices {
        leftDevice {
          id
        }
        rightDevice {
          id
        }
        capabilities {
          appConnected
        }
      }
      appInfo {
        appName
      }      
    }
    Messages(
      patientId: $id
      pageNumber: $pageNumber
      size: $size
      correlationContext: $correlationContext
    ) {
      id
      createDate
      readOnDate
      message
      messageType
      payload {
        settingUuid
        programSlot
      }
      recipient {
        id
      }
      sender {
        id
      }
    }
  }
`

const MUTATION_SEND_MESSAGE = gql`
  mutation SendMessageMutation($data: MessageInput!, $correlationContext: String!) {
    sendMessage(data: $data, correlationContext: $correlationContext)
  }
`
const MUTATION_MARK_MESSAGE_READ = gql`
  mutation markMessageAsReadMutation($ids: [String!], $correlationContext: String!) {
    markMessageAsRead(ids: $ids, correlationContext: $correlationContext)
  }
`
const MUTATION_RESET_UNREAD_MESSAGE_COUNT = gql`
  mutation resetUnreadMessageCountMutation($patientId: Int!, $correlationContext: String) {
    resetUnreadMessageCount(patientId: $patientId, correlationContext: $correlationContext)
  }
`
const options = (data) => ({
  notifyOnNetworkStatusChange: true,
  variables: {
    id: parseInt(data?.params?.id),
    patientId: parseInt(data?.params?.id),
    pageNumber: parseInt(data?.params?.pageNumber) || 0,
    size: parseInt(data?.params?.size) || 10,
    correlationContext: CorrelationContext['ChatMessage_GetChatMessage'] + SupportId,
    practiceId: parseInt(getDefaultPracticeId())
  }
})

const withData = compose(
  graphql(QUERY_CHAT, {
    props: mapProps,
    options
  }),
  graphql(HEARING_DEVICES_QUERY, {
    props: ({ data }) => {
      return {
        HearingDevices: data.HearingDevices
      }
    },
    options
  }),
  graphql(QUERY_PRACTICE_DETAILS, {
    props: ({ data }) => {
      return {
        Practice: data.Practice
      }
    },
    options
  }),
  graphql(MUTATION_SEND_MESSAGE, {
    props: ({ mutate }) => ({
      sendMessage: variables =>
        mutate({
          variables
        })
    })
  }),
  graphql(MUTATION_MARK_MESSAGE_READ, {
    props: ({ mutate }) => ({
      markMessageAsRead: variables => mutate({ variables })
    })
  }),
  graphql(MUTATION_RESET_UNREAD_MESSAGE_COUNT, {
    props: ({ mutate }) => ({
      resetUnreadMessageCount: variables => mutate({ variables })
    })
  }),
  injectIntl
)

export default withRouter(withQuery(withData(Chat)))
