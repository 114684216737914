export const patientsListProductTourSteps = [
  {
    target: '[data-qa="info-dropdown"]',
    title: 'tourGuide.patientsList.step.startTour.title', 
    content: 'tourGuide.patientsList.step.startTour.content',
    disableBeacon: true,
  },
  {
    target: '[data-qa="navigation-patients"]',
    title: 'tourGuide.patientsList.step.navigation.patients.title', 
    content: 'tourGuide.patientsList.step.navigation.patients.content',
  },
  {
    target: '[data-qa="navigation-marketingMessages"]',
    title: 'tourGuide.patientsList.step.navigation.marketingMessages.title', 
    content: 'tourGuide.patientsList.step.navigation.marketingMessages.content',
  },
  {
    target: '[data-qa="navigation-analytics"]',
    title: 'tourGuide.patientsList.step.navigation.analytics.title', 
    content: 'tourGuide.patientsList.step.navigation.analytics.content',
  },
  {
    target: '[data-qa="navigation-management"]',
    title: 'tourGuide.patientsList.step.navigation.management.title', 
    content: 'tourGuide.patientsList.step.navigation.management.content',
  },
  {
    target: ".filter-patients",
    title: 'tourGuide.patientsList.step.filterPatients.title', 
    content: 'tourGuide.patientsList.step.filterPatients.content',
  },
  {
    target: '[data-qa="add-customer-button"]',
    title: 'tourGuide.patientsList.step.addPatient.title', 
    content: 'tourGuide.patientsList.step.addPatient.content',
  },
   {
    target: '.table--patients',
    title: 'tourGuide.patientsList.step.patientsTable.title', 
    content: 'tourGuide.patientsList.step.patientsTable.content',
  },
  {
    target: 'a.chat-link',
    title: 'tourGuide.patientsList.step.viewMessage.title', 
    content: 'tourGuide.patientsList.step.viewMessage.content',
  },
  {
    target: '.app-connected',
    title: 'tourGuide.patientsList.step.viewAppStatus.title', 
    content: 'tourGuide.patientsList.step.viewAppStatus.content',
  },
];
