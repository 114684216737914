import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import Practice from './components/Practice'
import isNil from 'lodash/isNil'
import { trackDashboardAction } from 'helper/DashboardUtils'
import { PracticeList } from 'styledComponents/Div/DivContainer/DivPractice'
import { HorizontalRow } from 'styledComponents/HorizontalRule'
import { Defer } from 'helper/Utils'
import DashBoardContext from '../DashBoardContext'

const defaultKpis = [
  {
    "id": 0,
    "name": null,
    "type": "practice",
    "totalNumberOfPatients": 0,
    "kpis": [
      {
        "value": 0,
        "type": "patientsGained"
      },
      {
        "value": 0,
        "type": "processDuration"
      },
      {
        "value": 0,
        "type": "patientSatisfaction"
      },
      {
        "value": 0,
        "type": "successRate"
      },
      {
        "value": 0,
        "type": "patientsOverall"
      }
    ]
  },
  {
    "id": 0,
    "name": null,
    "type": "organizationAverage",
    "totalNumberOfPatients": 0,
    "kpis": [
      {
        "value": 0,
        "type": "patientsGained"
      },
      {
        "value": 0,
        "type": "processDuration"
      },
      {
        "value": 0,
        "type": "patientSatisfaction"
      },
      {
        "value": 0,
        "type": "successRate"
      },
      {
        "value": 0,
        "type": "patientsOverall"
      }
    ]
  }
];

const kpiList = {
  patientsGained: {
    title: <FormattedMessage {...T('Dashboard.patients.added')} />,
    subtitle: <FormattedMessage {...T('Dashboard.patients.added.info')} />
  },
  patientSatisfaction: {
    title: <FormattedMessage {...T('Dashboard.patients.satisfaction')} />,
    subtitle: (
      <FormattedMessage {...T('Dashboard.patients.satisfaction.info')} />
    )
  },
  processDuration: {
    title: <FormattedMessage {...T('Dashboard.patients.process')} />,
    subtitle: <FormattedMessage {...T('Dashboard.patients.process.info')} />
  },
  successRate: {
    title: <FormattedMessage {...T('Dashboard.patients.success')} />,
    subtitle: <FormattedMessage {...T('Dashboard.patients.success.info')} />
  }
}

const getByType = (type, data) => {
  return data.filter(a => a.type === type)
}

const getKpiValue = (kpi, defaultValue = 0) => {
  switch (kpi.type) {
    case 'processDuration':
      return isNil(kpi.value) ? defaultValue : Math.round(kpi.value)
    case 'successRate':
      return isNil(kpi.value) ? defaultValue : Math.floor(kpi.value * 100)
    default:
      return isNil(kpi.value) ? defaultValue : kpi.value
  }
}

const prepareData = (data, defaultValue = 0) =>
  data
    .map(kpi => {
      return {
        invert: kpi.type === 'processDuration',
        order: Object.keys(kpiList).indexOf(kpi.type),
        tooltip: kpiList[kpi.type],
        type: kpi.type,
        value: getKpiValue(kpi, defaultValue)
      }
    })
    .filter(kpi => kpi.type !== 'patientsOverall')
    .slice(0)
    .sort((a, b) => a.order - b.order)

const shoultDisplayKpis = ({ kpis = [] } = {}) => {
  return kpis.filter(item => !isNil(item.value)).length > 0
}

export const DashboardPracticesComponent = ({ intl: { formatMessage } }) => {
  const { kpis } = useContext(DashBoardContext)
  const practices = getByType('practice', kpis ?? defaultKpis)
  const organizationAverage = getByType('organizationAverage', kpis ?? defaultKpis).shift()
  const marketAverage = getByType('marketAverage', kpis ?? defaultKpis).shift()


  trackDashboardAction({ ...kpis, action: 'dashboardPracticesTab' })

  return (
    <div>
      <PracticeList>
        <Defer chunkSize={10}>
          {practices.map((practice, i) => {
            return (
              <Practice
                key={`practice-${i}`}
                name={practice.name}
                patients={practice.totalNumberOfPatients}
                items={prepareData(practice.kpis, null)}
                formatMessage={formatMessage}
              />
            )
          })}
        </Defer>
      </PracticeList>
      {(organizationAverage || marketAverage) && <HorizontalRow />}
      <PracticeList>
        {organizationAverage &&
          <Practice
            name={<FormattedMessage {...T('Dashboard.org.avg')} />}
            patients={organizationAverage.totalNumberOfPatients}
            items={prepareData(organizationAverage.kpis, null)}
            modifier="bg-dark"
            formatMessage={formatMessage}
          />}
        {shoultDisplayKpis(marketAverage) &&
          <Practice
            name={<FormattedMessage {...T('Dashboard.mk.avg')} />}
            patients={marketAverage.totalNumberOfPatients}
            items={prepareData(marketAverage.kpis, null)}
            modifier="bg-darker"
            isMarketAverage={true}
            formatMessage={formatMessage}
          />}
      </PracticeList>
    </div>
  )
}

export default injectIntl(DashboardPracticesComponent)
