import React from 'react'
import { injectIntl } from 'react-intl'
import { T } from 'core'
import { TextArea } from 'styledComponents/Textarea'
import { marketingMessageMaxLength } from 'helper/constants'

export class MarketingMessagesComponent extends React.PureComponent {
  constructor(...args) {
    super(...args)

    this.state = {  
      remainingCharacter: 0
    }
  }

  static displayName = 'MarketingMessageComponent'

  static defaultProps = {
    onMessageChange: Function,
    message: ''
  }

  onMessageChange = e => {
    this.setState({ remainingCharacter: e.target.value.length})   
    const message = e.target.value.trim()             
    this.props.onMessageChange({
      message
    })
  }

  render() {
    const { intl: { formatMessage }, message } = this.props
    const { remainingCharacter } = this.state
    
    return (
      <div>
      <div id="MarketingMessages">
        <TextArea
          componentClass="textarea"
          maxLength={marketingMessageMaxLength}
          placeholder={formatMessage(T('marketing.messages.message'))}
          onChange={this.onMessageChange}
          defaultValue={message}
        />
      </div>
       <div className="text-right">
          {remainingCharacter}/{marketingMessageMaxLength}
        </div>
      </div>
     
    )
  }
}

export default injectIntl(MarketingMessagesComponent)
