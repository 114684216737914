import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ButtonSelectField extends Component {
  handleOnClick = buttonValue => {
    const { isReadOnly, field, form: { setFieldValue } } = this.props
    const selectedButtons = field.value || []

    if (isReadOnly) {
      return
    }

    if (!field.value.includes(buttonValue)) {
      setFieldValue(field.name, [...selectedButtons, buttonValue])
    } else {
      setFieldValue(field.name, selectedButtons.filter(val => val !== buttonValue))
    }
  }

  render() {
    const {
      field,
      className,
      buttons,
      buttonsClassName,
      buttonsActiveClassName,
      buttonsInactiveClassName,
      isReadOnly
    } = this.props

    const classes = ['btn-group', ...(className || '').split(' ')]
    return (
      <div className={classes.join(' ')}>
        {Object.keys(buttons).map(buttonValue => {
          const buttonLabel = buttons[buttonValue]
          const buttonClasses = [
            'btn',
            ...(buttonsClassName || '').split(' '),
            isReadOnly ? 'disabled' : ''
          ]

          if (field.value && field.value.includes(buttonValue)) {
            buttonClasses.push(
              buttonsActiveClassName == null ? 'btn-secondary' : buttonsActiveClassName
            )
          } else {
            buttonClasses.push(
              buttonsInactiveClassName == null ? 'btn-default' : buttonsInactiveClassName
            )
          }

          return (
            <div
              key={buttonValue}
              className={buttonClasses.join(' ')}
              onClick={() => this.handleOnClick(buttonValue)}
              data-qa={buttonValue.toLowerCase() + '-button'}
            >
              {buttonLabel}
            </div>
          )
        })}
      </div>
    )
  }
}

ButtonSelectField.propTypes = {
  className: PropTypes.string,
  buttonsActiveClassName: PropTypes.string,
  buttonsInactiveClassName: PropTypes.string,
  buttons: PropTypes.shape({}),
  field: PropTypes.shape({})
}

export default ButtonSelectField
