import React from 'react'
import { ErrorMessage, getIn } from 'formik'

const TextAreaField = ({
  field,
  className,
  label,
  handleChange,
  labelClassName,
  form: { touched, errors },
  parentClassName,
  placeholder,
  ...props
}) => {
  return (
    <div
      className={`form-group ${parentClassName} ${getIn(errors, field.name) && getIn(touched, field.name)
        ? 'has-error'
        : ''}`}
    >
      {label &&
        <label htmlFor={field.name} className={`control-label ${labelClassName || ''}`}>
          {label}
        </label>}
      <textarea
        {...field}
        {...props}
        onChange={e => {
          field.onChange(e);
          handleChange && handleChange(e)
        }}
        className={`form-control ${className || ''}        
          ${getIn(errors, field.name) && getIn(touched, field.name) ? 'has-error' : ''}
        `}
        autoComplete={props.autoComplete}
        placeholder={placeholder}
      />
      <ErrorMessage
        name={field.name}
        render={msg =>
          <div className="help-block" data-qa="input-error">
            {msg}
          </div>}
      />
    </div>
  )
}

export default TextAreaField
