import { gql } from "@apollo/client";

export const FETCH_CONFIGURATION = gql`
  query ConfigurationQuery ( $orgId: Int ) {
    Configuration( orgId: $orgId ) {
      brandId
      brandName
      brandLogoUrl
    }
  }
`;
