import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
      return 'green'
  }
  if (props.isDragReject) {
      return '#ff1744'
  }
  if (props.isDragActive) {
      return '#2196f3'
  }
  return '#b1b1b1'
}

const getBorderWith = (props) => {
  if (props.isDragAccept) {
      return '5px'
  }
  return '2px'
}

const Container = styled.div`  
  margin-left: -15px;
  border-width: ${props => getBorderWith(props)};  
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #f5f5f5;
  color: #bdbdbd;  
  height: 180px;
  width: 200px;
  i {
    margin-top: 75px;
    margin-left: 80px;
  }
`;

export default function StyledDropzone(props) {  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({...props});  
  return (
    <div className="container">
      <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <i className="far fa-fw fa-2x fa-image"></i>
      </Container>
    </div>
  );
}