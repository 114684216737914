import { createSelector } from 'reselect'
import moment from 'moment'
import _ from 'lodash'

const sentByCustomer = (interaction, customer = {}) => {
  const value = parseInt(_.get(interaction, `sender.id`, ''), 10)
  return value === customer.id
}

const receivedByCustomer = (interaction, customer = {}) => {
  const value = parseInt(_.get(interaction, `recipient.id`, ''), 10)
  return value === customer.id
}

const isUnreadMessage = (customer, interaction) =>
  !interaction.readOnDate && sentByCustomer(interaction, customer)

export const getChatMessages = (allMessages, patient) => {
  allMessages = allMessages
    .filter(item => sentByCustomer(item, patient) || receivedByCustomer(item, patient))
    .reduce((result, interaction) => {
      const date = moment(interaction.createDate).startOf('day').toDate()
      const prevGroup = result[date] || []
      const groupItem = {
        ...interaction,
        isFromCustomer: sentByCustomer(interaction, patient),
        isUnread: isUnreadMessage(patient, interaction)
      }

      return {
        ...result,
        [date]: [...prevGroup, groupItem]
      }
    }, {})

  Object.keys(allMessages).forEach(date => {
    const group = allMessages[date]

    allMessages[date] = group.sort(
      (a, b) => new Date(moment(a.createDate)) - new Date(moment(b.createDate))
    )
  })

  return allMessages
}

export const getUnreadMessages = createSelector((customer, messages = []) => {
  return messages
    .filter(item => isUnreadMessage(customer, item))
    .sort((a, b) => new Date(a.createDate) - new Date(b.createDate))
}, items => items)

export const getUnreadMessageIds = createSelector((customer, messages = []) => {
  var interactionIds = messages
    .filter(item => isUnreadMessage(customer, item))
    .sort((a, b) => new Date(a.createDate) - new Date(b.createDate))
    .reduce((result, interaction) => {
      return [...result, interaction.id]
    }, [])
  return interactionIds
}, items => items)

export const getLastMessageTimeByPatient = (customer = {},totalMessages = [], unreadMessages = []) => { 
  if(unreadMessages.length > 0){
    return unreadMessages[0].createDate
  }else if( totalMessages.length > 0){
    if(receivedByCustomer(totalMessages[0],customer)) return null
    const customerMessages = totalMessages.filter( msg => sentByCustomer(msg, customer))
    if(customerMessages.length > 0){
      return customerMessages[0].createDate
    }   
  }
  return null
}