import React from 'react'
import { StyledLink } from 'styledComponents/Navigation/StyledLink'

export const HelpCenterNavigationItem = ({ active, href, label, className = '', dataqa = '' }) => {
  className = className || 'col-md-2'
  className = `${className} patient-navigation__menu-item`

  const lastIndex = href.indexOf('?') > -1 ? href.indexOf('?') : href.length
  const isActive = active.search(href.substr(0, lastIndex)) > -1 ? true : false
  return (
    <li key={href} className={className}>
      <StyledLink
        data-qa={dataqa}
        className={isActive ? 'active' : ''}
        to={href}
      >
        {label}
      </StyledLink>
    </li>
  )
}

export const HelpCenterNavigation = ({ children }) => {
  return (
    <article className="patient-navigation">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <ul data-qa="helpCenter-navigation_links" className="row patient-navigation__menu">
              {children}
            </ul>
          </div>
        </div>
      </div>
    </article>
  )
}

export default HelpCenterNavigation
